import styled from "styled-components";

import PriceBreakdown from "./PriceBreakdown";
import breakpoints from "../../theme/breakpoints";

const StyledPriceBreakdown = styled(PriceBreakdown)`
  font-family: ${props => props.theme.FONTS.families.lato};
  font-size: ${props => props.theme.FONTS.sizes[40]};
  line-height: ${props => props.theme.FONTS.lineHeights[100]};
  color: ${props => props.theme.COLOURS.PRIMARY.base};

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    font-size: ${props => props.theme.FONTS.sizes[20]};
  }
`;

export default StyledPriceBreakdown;
