import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Car4x4 = ({ width, height, strokeColour, strokeWidth }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 29"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(1 1)"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9.006 18.759l3.132 4.611-4.414 3.113M21.204 3.31h4.337c1.172.128 2.058 1.173 2.045 2.412v9.781c0 1.189-.915 2.152-2.045 2.152H6.46c-1.13 0-2.045-.963-2.045-2.152V5.722c-.013-1.24.873-2.284 2.045-2.412h4.337M11.034 3.31l2.62-2.75A2 2 0 0114.91 0h3.286a2 2 0 011.254.56l2.62 2.75M30.36 15.448A3.586 3.586 0 0132 18.164c0 3.196-6.309 5.84-14.345 6.112" />
        <path d="M12.138 24.276C5.14 23.556 0 21.117 0 18.219a3.652 3.652 0 011.686-2.77" />
        <circle cx="17.655" cy="9.931" r="3.31" />
        <path d="M24.552 7.724a.276.276 0 110 .552.276.276 0 010-.552" />
      </g>
    </svg>
  );
};

Car4x4.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

Car4x4.defaultProps = {
  width: "34",
  height: "29",
  strokeWidth: "2"
};

export default Car4x4;
