import { createContext } from "react";

/**
 * ExperimentalContext - used to pass flags wrapping in-development features
 * @type {React.Context<{}>}
 */
export const ExperimentalContext = createContext({});

/**
 * MessageContext - used to pass the 'parameter' prop to MessageBody insert components
 * @type {React.Context<{}>}
 */
export const MessageContext = createContext({});

/**
 * PurchaseContext - used to pass various buyingOption props to VehicleDetailsBanner child components
 * @type {React.Context<{}>}
 */
export const PurchaseContext = createContext({});

/**
 * PopoverFilterContext - used to pass labels, selected counts to Label child component
 * @type {React.Context<{}>}
 */
export const PopoverFilterContext = createContext({});
