export const paragraphText = {
  HEADER_TEXT: "Claims Activity Log",
  CASE_REF_TEXT: "Case Reference",
  SUB_HEADER_TEXT:
    "Review all events and responses relating to this claim below.",
  NOTES: "Notes: ",
};

export const tableHeaderText = {
  HEADER_DATE: "Date",
  HEADER_USER: "User",
  HEADER_STATUS: "Status",
  HEADER_RESPONSE: "Response",
};
