import { useSelector } from "react-redux";

const useIsLoggedInUserType = (userType) => {
  const credentials = useSelector((state) => state.authentication.credentials);
  const isExpectedUserType = useSelector(
    (state) => state.authentication.meta.userType === userType
  );

  return !!(credentials && isExpectedUserType);
};

export default useIsLoggedInUserType;
