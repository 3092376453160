import React from "react";
import PropTypes from "prop-types";

const ProgressBar = (props) => {
  const { className, value, maxValue } = props;

  return (
    <div className={className}>
      <progress value={value} max={maxValue} />
      <span>{Math.round(value)} %</span>
    </div>
  );
};

ProgressBar.defaultProps = {
  maxValue: 100,
};

ProgressBar.propTypes = {
  className: PropTypes.string,
  maxValue: PropTypes.number,
  value: PropTypes.number,
};

export default ProgressBar;
