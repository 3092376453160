export const HEADER_TEXT = "Keyboard Shortcuts";

export const KEYBOARD_SHORTCUTS = {
    OPEN_SHORTCUTS: "Open Keyboard Shortcuts",
    FIND_VEHICLE: "Go to Find Vehicle",
    MESSAGES: "Go to Messages",
    SHORTLIST: "Go to Shortlist",
    BIDS_REQUESTS: "Go to Bids and Requests",
    PURCHASES: "Go to Purchases",
    CLAIMS: "Go to Claims",
    ACCOUNT_ADMIN: "Go to Account Administration",
    NOTIFICATION_PREFERENCES: "Go to Notification Preferences",
    STOCKLIST_DOWNLOAD: "Open Stocklist Download",
    INVOICES_CREDITS: "Go to Invoices & Credits",
    ACTIVITY_LOG: "Go to Activity Log",
    HELP_CENTER: "Go to Help Centre",
    ABOUT: "Go to About",
    CHANGE_PASSWORD: "Change Password",
    LOGOUT: "Logout",
};

export const IN_GALLERY = "Within the image gallery:";
export const PREVIOUS_IMAGE = "Previous Image";
export const NEXT_IMAGE = "Next Image";