import React from "react";
import { useSelector, useDispatch } from "react-redux";
import InputSearch from "../../components/InputSearch";
import { regSearch, clearRegRequest } from "../../../state/reducers/claims";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../theme/breakpoints";

const NewClaimRegistrationSearch = ({ onRegSearch }) => {
  const dispatch = useDispatch();
  const isFetching = useSelector((state) => state.claims.regSearch.isFetching);
  const hasValue = useSelector((state) => state.claims.regSearch.value);

  const onRegLookup = (reg) => {
    dispatch(regSearch(reg));
  };
  const onInputClear = () => {
    dispatch(clearRegRequest());
  };
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.SMALL_DESKTOP})`,
  });

  return (
    <InputSearch
      title=""
      isFetching={isFetching}
      onTermLookup={onRegLookup}
      onInputClear={onInputClear}
      clearInput={!isTabletOrMobile && !hasValue}
      defaultValue={isTabletOrMobile && hasValue ? hasValue : null}
      onRegSearch={isTabletOrMobile ? onRegSearch : null}
      withBottomBorder
    />
  );
};

export default NewClaimRegistrationSearch;
