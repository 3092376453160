import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import classnames from "classnames";
import InputText from "../InputText";
import BidInputError from "./child-components/BidInputError";
import BidInputButton from "./child-components/BidInputButton";
import { getMaxBidValue } from "../../../shared/utility";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../theme/breakpoints";

const BidInput = props => {
  const {
    className,
    initialValue = 0,
    increments = [50, 100, 250],
    getValue
  } = props;

  const [value, setValue] = useState(initialValue);
  const [validValue, setValidValue] = useState(true);
  const [hasFocus, setHasFocus] = useState(false);
  const treatValue = val => parseInt(val.replace(/\D/g, ""));
  // TODO: is this typecheck necessary any more?
  const formatCurrency = val =>
    typeof val === "number"
      ? `£${val.toLocaleString()}`
      : `£${initialValue.toLocaleString()}`;
  const incrementValue = increment => {
    setValue(value + increment);
  };
  const maxValueRef = useRef(getMaxBidValue());
  const initialValueRef = useRef(initialValue);
  const getValueRef = useRef(getValue);
  const isLegalValue =
    value >= initialValueRef.current &&
    value <= maxValueRef.current &&
    value > 0;
  const bidTooHighMessage = `Please bid <span>£${maxValueRef.current.toLocaleString()}</span> or lower`;
  const bidTooLowMessage = `Please bid <span>£${initialValueRef.current.toLocaleString()}</span> or higher`;

  const getErrorMessage = () => {
    if (value > maxValueRef.current) {
      return bidTooHighMessage;
    }
    return bidTooLowMessage;
  };

  useEffect(() => {
    if (getValueRef.current) {
      getValueRef.current(value, isLegalValue);
    }
    if (isNaN(value)) setValue(0);
  }, [value, isLegalValue]);
  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.SMALL_DESKTOP})`
  });

  return (
    <div className={classnames(className, { error: !validValue && !hasFocus })}>
      <div className="bid-input__fields">
        <div className="bid-input__input">
          <InputText
            type="text"
            value={formatCurrency(value)}
            onChange={e => setValue(treatValue(e.target.value))}
            onFocus={e => {
              e.target.select();
              setHasFocus(true);
            }}
            onBlur={e => {
              if (isDesktop) {
                e.target.select();
              }
              setHasFocus(false);
              setValidValue(isLegalValue);
            }}
            validValue={validValue || hasFocus}
          />

          {!validValue && !hasFocus && (
            <BidInputError errorMessage={getErrorMessage()} />
          )}
        </div>
        {increments.map(increment => (
          <BidInputButton
            key={`increment-${increment}`}
            increment={increment}
            incrementValue={incrementValue}
          />
        ))}
      </div>
    </div>
  );
};

BidInput.propTypes = {
  className: propTypes.string,
  initialValue: propTypes.number,
  minValue: propTypes.number,
  increments: propTypes.arrayOf(propTypes.number),
  getValue: propTypes.func,
  errorMessage: propTypes.string
};

export default BidInput;
