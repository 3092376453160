import { useEffect, useState } from "react";

const useTextOverflow = textRef => {
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);

  useEffect(() => {
    if (textRef.current?.offsetWidth < textRef.current?.scrollWidth) {
      setIsTextOverflowing(true);
    }
  }, [textRef]);

  return isTextOverflowing;
};

export default useTextOverflow;
