import styled, { css } from "styled-components";

import AccordionFilter from "./AccordionFilter";
import Datepicker from "../Datepicker";
import InputLabel from "../InputLabel";

const StyledAccordionFilter = styled(AccordionFilter)`
  border-bottom: 0.1rem solid ${props => props.theme.COLOURS.PRIMARY.shades[10]};
  text-align: left;
  .accordion__header {
    padding: ${props => props.theme.SPACING.REGULAR} 0;
  }
  .accordion__content {
    padding-bottom: ${props => props.theme.SPACING.SMALL};
    .checkbox {
      margin-bottom: ${props => props.theme.SPACING.SMALL};
    }
    .dropdown {
      margin-bottom: ${props => props.theme.SPACING.SMALL};
    }
  }
  .dropdown__option-count {
    margin-left: 0.5rem;
    color: ${props => props.theme.COLOURS.PRIMARY.shades[60]};
  }
  ${Datepicker}:nth-of-type (2) {
    margin-top: ${props => props.theme.SPACING.SMALL};
  }

  ${props =>
    props.inverted &&
    css`
      border-bottom: 0.1rem solid ${props => props.theme.COLOURS.PRIMARY.base};

      ${InputLabel} {
        color: ${props => props.theme.COLOURS.WHITE};
      }
    `}
`;

export default StyledAccordionFilter;
