import React from "react";
import PropTypes from "prop-types";
import COLOURS from "../../../theme/base/colours";

const Dot = ({ width, height, strokeColour, strokeWidth }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
  cx="47"
  cy="16"
  r="5"
  fill={strokeColour}
  fillRule="evenodd"
  stroke="none"
  strokeWidth={strokeWidth}
  transform="translate(-168 -387) translate(126 310) translate(0 66)"
  />
  </svg>
);

Dot.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

Dot.defaultProps = {
  width: "10",
  height: "10",
  strokeColour: COLOURS.BLUE,
  strokeWidth: "2"
};

export default Dot;
