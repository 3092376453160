/**
 * We are using px values here because @media queries will not respect the
 * fact that we have set the root value to 10px, and our breakpoints would
 * be off
 */
const TABLET = "769px";
const PHONE = "480px";
const LARGE_PHONE ="610px";
const SMALL_DESKTOP = "1026px";
const LARGE_DESKTOP = "1190px";

const obj = {
  TABLET,
  PHONE,
  LARGE_PHONE,
  SMALL_DESKTOP,
  LARGE_DESKTOP,
};

export default obj;
