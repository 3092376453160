import styled from "styled-components";
import spacing from "../../../../views/theme/base/spacing";
import colours from "../../../../views/theme/base/colours";
import ClaimCallbackForm from "./ClaimCallbackForm";
import ParagraphText from "../../../../views/components/ParagraphText";

const StyledClaimCallbackForm = styled(ClaimCallbackForm)`
  .callback__form {
    margin-top: ${(props) => props.theme.SPACING.MEDIUM};
    display: flex;
    justify-content: space-evenly;
    width: 87rem;
  }
  
    .callback__detail-list {
      width: 100%;
      display: inline-block;
      flex-direction: column;
      justify-content: space-evenly;

      .callback-detail-list__row {
        width: 100%;
        display: flex;
        margin: 0 0 ${(props) => props.theme.SPACING.SMALL} 0;

        ${ParagraphText} {
          width: 100%;
        }

        &__col-one {
          flex: 1;
        }

        &__col-two {
          flex: 2;
        }
      }
    }

    .callback-detail-request-notes {
      margin: ${(props) => props.theme.SPACING.REGULAR} 0;
    }

    .text-area__input {
      background-color: ${colours.WHITE};
      border-radius: 0.5rem;
      border: 0.2rem solid ${colours.PRIMARY.shades[20]};
      box-sizing: border-box;
      color: ${colours.PRIMARY.base};
      padding: ${spacing.SMALL};
      display: flex;
      justify-content: flex-end;
      width: 100%
      resize: initial;
      ${(props) => props.theme.FONTS.Text["paragraph"]}
    }
`;

export default StyledClaimCallbackForm;
