import React, { useState, useContext } from "react";
import { useSwipeable } from "react-swipeable";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";
import Loading from "./child-components/Loading";
import Footer from "./child-components/Footer";
import NavButton from "./child-components/NavButton";
import { gADataLayerPushMotability } from "../../../shared/utilityGA";

const ModalGallery = (props) => {
  const theme = useContext(ThemeContext);
  const {
    className,
    galleryItems = [],
    galleryTexts = [],
    initialIndex = 0,
    closeAction,
  } = props;
  const [galleryIndex, setGalleryIndex] = useState(initialIndex);
  const [loading, setLoading] = useState(true);
  const galleryLength = galleryItems.length;
  const showPrevButton = galleryIndex !== 0;
  const showNextButton = galleryIndex !== galleryLength - 1;
  const images = galleryItems.map((item, index) => (
    <img
      key={index}
      className={loading ? "loading" : null}
      onLoad={() => setLoading(false)}
      src={item}
      alt=""
    />
  ));
  const nextSlide = () => {
    setGalleryIndex(galleryIndex + 1);
    setLoading(true);

    gADataLayerPushMotability(theme.name, {
      event: "gaEvents",
      eventCategory: "Carousel",
      eventAction: "Images",
      eventLabel: "Next",
    });
  };
  const previousSlide = () => {
    setGalleryIndex(galleryIndex - 1);
    setLoading(true);

    gADataLayerPushMotability(theme.name, {
      event: "gaEvents",
      eventCategory: "Carousel",
      eventAction: "Images",
      eventLabel: "Previous",
    });
  };
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (showNextButton) nextSlide();
    },
    onSwipedRight: () => {
      if (showPrevButton) previousSlide();
    },
  });

  const changeSlideWithArrowKeys = (e) => {
    if (e.key === "ArrowRight" && showNextButton) {
      if (!loading) {
        nextSlide();
      }
    } else if (e.key === "ArrowLeft" && showPrevButton) {
      if (!loading) {
        previousSlide();
      }
    }
  };
  window.addEventListener("keydown", changeSlideWithArrowKeys);

  const primaryText = galleryTexts && galleryTexts[galleryIndex]?.primary;
  const secondaryText = galleryTexts && galleryTexts[galleryIndex]?.secondary;

  return (
    <div className={className}>
      <div className="modal-gallery__body" {...swipeHandlers}>
        {images[galleryIndex]}
        {showPrevButton && (
          <NavButton direction="left" onClick={previousSlide} />
        )}
        {showNextButton && <NavButton direction="right" onClick={nextSlide} />}
      </div>

      {loading && <Loading />}

      <Footer
        galleryIndex={galleryIndex}
        galleryLength={galleryLength}
        primaryText={primaryText}
        secondaryText={secondaryText}
        closeAction={closeAction}
      />
    </div>
  );
};

ModalGallery.propTypes = {
  className: PropTypes.string,
  galleryItems: PropTypes.array,
  galleryTexts: PropTypes.array,
  initialIndex: PropTypes.number,
  closeAction: PropTypes.func,
};

export default ModalGallery;
