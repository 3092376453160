import React from "react";
import PropTypes from "prop-types";
import { Background } from "react-imgix";
import { getDefaultImage } from "../../../shared/utility";
import VehiclePillDetails from "../VehiclePillDetails";
import VehicleCardKeyInfo from "../VehicleCardKeyInfo";
import VehicleCardTitles from "../VehicleCardTitles";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../theme/breakpoints";

const VehicleOverviewCard = ({
  className,
  vehicleId,
  thumbnail,
  titles,
  details,
  keyInfo,
  showDamagePercentage
}) => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`
  });

  return (
    <div className={className}>
      {/* TODO: we need to get these images sorted out */}
      <div className="vehicle-overview-card__image">
        <Background
          src={thumbnail || getDefaultImage()}
          imgixParams={{ fit: "clamp" }}
        />
      </div>
      <div className="vehicle-overview-card__content">
        <VehicleCardTitles
          tooltipOptions={{
            title: { direction: "top", tooltipMaxWidth: 350 },
            subtitle: { direction: "bottom", tooltipMaxWidth: 400 }
          }}
          {...titles}
        />
        <VehiclePillDetails
          {...details}
          vehicleId={vehicleId}
          trimPills
          pillContentLimit={isDesktop ? 40 : 25}
          showRemainingCount={!isDesktop}
        />
        <VehicleCardKeyInfo
          {...keyInfo}
          showDamagePercentage={showDamagePercentage}
          column
          small
        />
      </div>
    </div>
  );
};

VehicleOverviewCard.propTypes = {
  className: PropTypes.string,
  thumbnail: PropTypes.string,
  titles: PropTypes.shape(VehicleCardTitles.propTypes),
  details: PropTypes.shape(VehiclePillDetails.propTypes),
  keyInfo: PropTypes.shape(VehicleCardKeyInfo.propTypes)
};

export default VehicleOverviewCard;
