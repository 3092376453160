import styled from "styled-components";

import SkeletonRows from "./SkeletonRows";

const StyledSkeletonRows = styled(SkeletonRows)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: ${(props) => (props.paddingNone ? "0" : "4rem")};

  span {
    width: ${(props) => props.spanWidth || '100%'};    
    height: 2rem;
    background-color: ${(props) => props.theme.COLOURS.PRIMARY.shades[10]};
    ${(props) => props.darkerShimmer ? props.theme.ANIMATIONS["shimmerDarker"] : props.theme.ANIMATIONS["shimmer"]};
    display: block;
    margin-bottom: 0.3rem;
  }
`;

export default StyledSkeletonRows;
