import { GetCapValuation } from "../../api/CapValuation";

// Action constants
export const FETCH_CAP = "@cap/FETCH_CAP";
export const FETCH_CAP_SUCCESS = "@cap/FETCH_CAP_SUCCESS";
export const FETCH_CAP_FAILURE = "@cap/FETCH_CAP_FAILURE";

// Action creators
const fetchCapSuccess = (data, vehicleId) => {
  return {
    type: FETCH_CAP_SUCCESS,
    payload: {
      data,
      vehicleId,
    },
  };
};
const fetchCapFailure = (error) => {
  return {
    type: FETCH_CAP_FAILURE,
    payload: error,
  };
};

export const fetchCap = (vehicleId) => (dispatch, getState) => {
  dispatch({ type: FETCH_CAP, payload: vehicleId });

  const capInstance = new GetCapValuation({
    params: { id: vehicleId },
    credentials: getState().authentication.credentials,
  });
  capInstance.call().then(
    (response) => {
      dispatch(fetchCapSuccess(response.data, vehicleId));
    },
    (err) => {
      return dispatch(fetchCapFailure(err));
    }
  );
};

const initialState = {
  error: false,
  isFetchingCapMake: false,
  isFetchingCapRange: false,
  isFetchingCapTrim: false,
  fetchingCap: {
    byVehicleId: {},
  },
  capData: {
    byVehicleId: {},
  },
  data: {
    capMake: [],
    capRange: [],
    capTrim: [],
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CAP:
      return {
        ...state,
        fetchingCap: {
          ...state.fetchingCap,
          byVehicleId: {
            ...state.fetchingCap.byVehicleId,
            [action.payload]: {
              isFetching: true,
            },
          },
        },
      };
    case FETCH_CAP_SUCCESS:
      return {
        ...state,
        fetchingCap: {
          ...state.fetchingCap,
          byVehicleId: {
            ...state.fetchingCap.byVehicleId,
            [action.payload.vehicleId]: {
              isFetching: false,
            },
          },
        },
        capData: {
          ...state.capData,
          byVehicleId: {
            ...state.capData.byVehicleId,
            [action.payload.vehicleId]: {
              ...action.payload.data,
            },
          },
        },
      };
    case FETCH_CAP_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
