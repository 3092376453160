import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";
 
const Gearbox = ({ width, height, strokeColour, fillColour }) => {
  const theme = useContext(ThemeContext);
  const fill = fillColour ? fillColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 9L2 4H4L4 9H2ZM11 11L11 4H13L13 11L20 11V4L22 4V13L13 13V20H11V13L4 13L4 20H2L2 11L11 11Z" />
    </svg>
  );
};
 
Gearbox.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  fillColour: PropTypes.string,
};
 
Gearbox.defaultProps = {
  width: "24",
  height: "24",
};
 
export default Gearbox;