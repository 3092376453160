import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddressPicker from "../../components/AddressPicker";
import { closeModal } from "../../../state/reducers/global";
import { setDeliveryOption } from "../../../state/reducers/purchase";
import {
  find,
  capture,
  clearSelectedAddress
} from "../../../state/reducers/addressLookup";
import { addNewDeliveryOption } from "../../../state/reducers/purchase";
import { getDeliveryOptionFromLocation } from "../../../shared/utility";

const AddDeliveryAddressModalInsert = () => {
  const addressLookup = useSelector(state => state.addressLookup);
  const dispatch = useDispatch();
  const onFind = (text, container) => dispatch(find(text, container));
  const onCapture = id => dispatch(capture(id));
  const onCancel = () => dispatch(closeModal());

  const onConfirmLocation = location => {
    const deliveryOption = getDeliveryOptionFromLocation(location);
    dispatch(addNewDeliveryOption(deliveryOption));
    dispatch(setDeliveryOption(deliveryOption));
    dispatch(closeModal());
  };

  useEffect(() => {
    dispatch(clearSelectedAddress());
  }, [dispatch]);

  return (
    <AddressPicker
      onFind={onFind}
      onCapture={onCapture}
      foundLocations={
        addressLookup.searchResult && addressLookup.searchResult.Items
      }
      selectedLocation={
        addressLookup.selectedAddress && addressLookup.selectedAddress.Items
      }
      onCancel={onCancel}
      onConfirmLocation={onConfirmLocation}
    />
  );
};

export default AddDeliveryAddressModalInsert;
