import React, { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ThemeContext } from "styled-components";
import { useNavigate } from "react-router";
import { postOrderConfirmationDetails } from "../../../state/reducers/purchase";
import { closeModal } from "../../../state/reducers/global";
import ConfirmOrder from "../../components/ConfirmOrder";
import { pushCheckoutEventToGAMotability } from "./../../../shared/utilityGA";

const ConfirmOrderModalInsert = () => {
  const theme = useContext(ThemeContext);
  const purchase = useSelector((state) => state.purchase);
  const pricingDetails = useSelector((state) => state.pricing.pricingDetails);
  const showDamagePercentage = useSelector(
    (state) => state.authentication.meta.themeOptions.showDamagePercentage,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.authentication.credentials);
  const vendorId = useSelector(
    (state) => state.authentication.meta.userDefaults.vendorId,
  );
  const isFetching = useSelector((state) => state.purchase.isFetching);
  const purchaseDetailData = useSelector(
    (state) => state.purchase.purchaseDetails,
  );

  const onConfirmClick = () => dispatch(postOrderConfirmationDetails(navigate));
  const onCloseModalClick = () => dispatch(closeModal());

  useEffect(() => {
    if (!isFetching && purchaseDetailData) {
      pushCheckoutEventToGAMotability(
        theme.name,
        purchaseDetailData,
        token,
        vendorId,
        2,
      );
    }
  }, [theme.name, token, isFetching, vendorId, purchaseDetailData]);

  return (
    purchase.purchaseDetails &&
    pricingDetails && (
      /**
       * TODO: this props need to be grouped, in line with the work being done
       * on the Vehicle Card component
       */
      <ConfirmOrder
        vehicleId={purchase.purchaseDetails.vehicleId}
        regNo={purchase.purchaseDetails.regNo}
        dateOfRegistration={purchase.purchaseDetails.dateOfRegistration}
        make={purchase.purchaseDetails.make}
        range={purchase.purchaseDetails.range}
        model={purchase.purchaseDetails.model}
        derivative={purchase.purchaseDetails.derivative}
        fuelType={purchase.purchaseDetails.fuelType}
        transmission={purchase.purchaseDetails.transmission}
        colour={purchase.purchaseDetails.colour}
        mileage={purchase.purchaseDetails.mileage}
        odometer={purchase.purchaseDetails.odometer}
        odometerType={purchase.purchaseDetails.odometerType}
        serviceHistory={purchase.purchaseDetails.serviceHistory}
        v5={purchase.purchaseDetails.v5}
        mot={purchase.purchaseDetails.mot}
        motExpiryDate={purchase.purchaseDetails.motExpiryDate}
        auctionGrade={purchase.purchaseDetails.auctionGrade}
        condition={purchase.purchaseDetails.condition}
        paymentMethod={
          purchase.paymentOptions.selectedOption && {
            title: purchase.paymentOptions.selectedOption.title,
            bankAccountNo: purchase.paymentOptions.selectedOption.bankAccountNo,
            bankSortCode: purchase.paymentOptions.selectedOption.bankSortCode,
            subtitle: purchase.paymentOptions.selectedOption.subtitle,
            image: purchase.paymentOptions.selectedOption.image,
            value: purchase.paymentOptions.selectedOption.value,
            code: purchase.paymentOptions.selectedOption.code,
            defaultOption: purchase.paymentOptions.selectedOption.defaultOption,
          }
        }
        deliveryAddress={
          purchase.deliveryOptions.selectedOption && {
            title: purchase.deliveryOptions.selectedOption.title,
            text: purchase.deliveryOptions.selectedOption.subtitle,
            type: purchase.deliveryOptions.selectedOption.value,
          }
        }
        pricingDetails={pricingDetails}
        finalPrice={pricingDetails.total && pricingDetails.total.gross}
        thumbnail={purchase.purchaseDetails.vehicleBlobUrl}
        onConfirm={onConfirmClick}
        onGoBack={onCloseModalClick}
        showDamagePercentage={showDamagePercentage}
        damagePercentage={purchase.purchaseDetails.damagePercentage}
      />
    )
  );
};

export default ConfirmOrderModalInsert;
