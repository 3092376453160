import DetailsApi from "../../api/VehicleDetails";
import { GetStandardEquipment } from "../../api/StandardEquipment";
import ServiceHistoryApi from "../../api/ServiceHistory";
import { GetDocuments } from "../../api/Documents";
import { openModal } from "./global";
import { addShortlistFromPageLoad } from "./shortlist";
import { GetTechnicalSpecification } from "../../api/TechnicalSpecification";
import { IndexedDetails } from "../../api/VehicleIndex";
import { updateObject } from "../../shared/utility";
import { clearBidData } from "./bid";
import {
  GetPromotionDetailsForBuyer,
  FetchSupportingDocumentsForBuyer,
} from "../../api/BuyerInformation";
import { USER_TYPE_MAP } from "../../shared/utility";
// Action constants

export const FETCH_VEHICLE_DETAILS = "@vehicle/FETCH_VEHICLE_DETAILS";
export const FETCH_VEHICLE_DETAILS_SUCCESS =
  "@vehicle/FETCH_VEHICLE_DETAILS_SUCCESS";
export const FETCH_VEHICLE_DETAILS_FAILURE =
  "@vehicle/FETCH_VEHICLE_DETAILS_FAILURE";
export const CLEAR_VEHICLE_DETAILS = "@vehicle/CLEAR_VEHICLE_DETAILS";
export const LAUNCH_MODAL_GALLERY = "@vehicle/LAUNCH_MODAL_GALLERY";
export const GET_STANDARD_EQUIPMENT = "@vehicle/GET_STANDARD_EQUIPMENT";
export const GET_STANDARD_EQUIPMENT_SUCCESS =
  "@vehicle/GET_STANDARD_EQUIPMENT_SUCCESS";
export const GET_STANDARD_EQUIPMENT_ERROR =
  "@vehicle/GET_STANDARD_EQUIPMENT_ERROR";
export const GET_TECHNICAL_SPECIFICATION =
  "@vehicle/GET_TECHNICAL_SPECIFICATION";
export const GET_TECHNICAL_SPECIFICATION_SUCCESS =
  "@vehicle/GET_TECHNICAL_SPECIFICATION_SUCCESS";
export const GET_TECHNICAL_SPECIFICATION_ERROR =
  "@vehicle/GET_TECHNICAL_SPECIFICATION_ERROR";
export const FETCH_SERVICE_HISTORY = "@serviceHistory/FETCH_SERVICE_HISTORY";
export const FETCH_SERVICE_HISTORY_SUCCESS =
  "@serviceHistory/FETCH_SERVICE_HISTORY_SUCCESS";
export const FETCH_SERVICE_HISTORY_FAILURE =
  "@serviceHistory/FETCH_SERVICE_HISTORY_FAILURE";
export const FETCH_DOCUMENTS = "@documents/FETCH_DOCUMENTS";
export const FETCH_DOCUMENTS_SUCCESS = "@documents/FETCH_DOCUMENTS_SUCCESS";
export const FETCH_DOCUMENTS_FAILURE = "@documents/FETCH_DOCUMENTS_FAILURE";

export const FETCH_PROMOTION_DETAILS = "@vehicle/FETCH_PROMOTION_DETAILS";
export const FETCH_PROMOTION_DETAILS_SUCCESS =
  "@vehicle/FETCH_PROMOTION_DETAILS_SUCCESS";
export const FETCH_PROMOTION_DETAILS_FAILURE =
  "@vehicle/FETCH_PROMOTION_DETAILS_FAILURE";

export const FETCH_PROMOTION_SUPPORTING_DOCUMENT =
  "@vehicle/FETCH_PROMOTION_SUPPORTING_DOCUMENT";
export const FETCH_PROMOTION_SUPPORTING_DOCUMENT_SUCCESS =
  "@vehicle/FETCH_PROMOTION_SUPPORTING_DOCUMENT_SUCCESS";
export const FETCH_PROMOTION_SUPPORTING_DOCUMENT_FAILURE =
  "@vehicle/FETCH_PROMOTION_SUPPORTING_DOCUMENT_FAILURE";
export const SAVE_PROMOTION_SUPPORTING_DOCUMENT =
  "@vehicle/SAVE_PROMOTION_SUPPORTING_DOCUMENT";

  export const GET_INDEXED_DETAILS =
  "@vehicle/GET_INDEXED_DETAILS";
export const GET_INDEXED_DETAILS_SUCCESS =
  "@vehicle/GET_INDEXED_DETAILS_SUCCESS";
export const GET_INDEXED_DETAILS_FAILURE =
  "@vehicle/GET_INDEXED_DETAILS_FAILURE";

  export const SAVE_VEHICLE_INDEX =
  "@vehicle/SAVE_VEHICLE_INDEX";
export const SAVE_VEHICLE_INDEX_SUCCESS =
  "@vehicle/SAVE_VEHICLE_INDEX_SUCCESS";
export const SAVE_VEHICLE_INDEX_FAILURE =
  "@vehicle/SAVE_VEHICLE_INDEX_FAILURE";

export const CLEAR_SAVED_VEHICLE_INDEX = "@vehicle/CLEAR_SAVED_VEHICLE_INDEX";

export const UPDATE_VEHICLE_BUYING_OPTIONS = "@vehicle/UPDATE_VEHICLE_BUYING_OPTIONS";
// Action creators

const fetchVehicleDetailsFailure = (error) => {
  return {
    type: FETCH_VEHICLE_DETAILS_FAILURE,
    payload: error,
  };
};

const fetchVehicleDetailsSuccess = (response) => {
  return {
    type: FETCH_VEHICLE_DETAILS_SUCCESS,
    payload: response,
  };
};

export const fetchVehicleDetails = (pathname) => (dispatch, getState) => {
  dispatch({ type: FETCH_VEHICLE_DETAILS });

  const detailsInstance = new DetailsApi({
    params: { pathname },
    credentials: getState().authentication.credentials,
  });
  return detailsInstance.call().then(
    (response) => {
      const { shortlistedVehicleIds } = response;
      if (shortlistedVehicleIds) {
        dispatch(addShortlistFromPageLoad(shortlistedVehicleIds));
        delete response.shortlistedVehicleIds;
      }
      return dispatch(fetchVehicleDetailsSuccess(response));
    },
    (err) => {
      dispatch(openModal("vehicle-unavailable"));
      return dispatch(fetchVehicleDetailsFailure(err.message));
    }
  );
};

export const clearVehicleDetails = () => {
  return {
    type: CLEAR_VEHICLE_DETAILS,
  };
};

export const launchModalGallery = (galleryIndex) => (dispatch) => {
  dispatch({
    type: LAUNCH_MODAL_GALLERY,
    payload: galleryIndex,
  });
  return dispatch(openModal("gallery"));
};

export const getStandardEquipment = (vehicleId) => (dispatch, getState) => {
  dispatch({ type: GET_STANDARD_EQUIPMENT });
  const params = {
    id: vehicleId,
  };
  const standardEquipmentInstance = new GetStandardEquipment({
    params,
    credentials: getState().authentication.credentials,
  });

  standardEquipmentInstance.call().then(
    (response) => dispatch(getStandardEquipmentSuccess(response)),
    (err) => dispatch(getStandardEquipmentError())
  );
};

export const getStandardEquipmentSuccess = (response) => {
  return {
    type: GET_STANDARD_EQUIPMENT_SUCCESS,
    payload: response,
  };
};

export const getStandardEquipmentError = () => {
  return {
    type: GET_STANDARD_EQUIPMENT_ERROR,
  };
};

export const getTechnicalSpecification = (vehicleId) => (
  dispatch,
  getState
) => {
  dispatch({ type: GET_TECHNICAL_SPECIFICATION });
  const params = {
    id: vehicleId,
  };
  const technicalSpecificationInstance = new GetTechnicalSpecification({
    params,
    credentials: getState().authentication.credentials,
  });

  technicalSpecificationInstance.call().then(
    (response) => dispatch(getTechnicalSpecificationSuccess(response)),
    (err) => dispatch(getTechnicalSpecificationError())
  );
};

export const getTechnicalSpecificationSuccess = (response) => {
  return {
    type: GET_TECHNICAL_SPECIFICATION_SUCCESS,
    payload: response,
  };
};

export const getTechnicalSpecificationError = () => {
  return {
    type: GET_TECHNICAL_SPECIFICATION_ERROR,
  };
};

export const fetchServiceHistorySuccess = (data) => {
  return {
    type: FETCH_SERVICE_HISTORY_SUCCESS,
    payload: data,
  };
};

export const fetchServiceHistoryFailure = (error) => {
  return {
    type: FETCH_SERVICE_HISTORY_FAILURE,
    payload: error,
  };
};

export const fetchServiceHistory = (vehicleId) => (dispatch, getState) => {
  dispatch({ type: FETCH_SERVICE_HISTORY });

  const params = {
    id: vehicleId,
  };

  const serviceHistoryInstance = new ServiceHistoryApi({
    params,
    credentials: getState().authentication.credentials,
  });

  serviceHistoryInstance.call().then(
    (response) => {
      dispatch(fetchServiceHistorySuccess(response.data));
    },
    (error) => {
      return dispatch(fetchServiceHistoryFailure(error));
    }
  );
};

const fetchDocumentsSuccess = (response) => {
  return {
    type: FETCH_DOCUMENTS_SUCCESS,
    payload: response,
  };
};
const fetchDocumentsFailure = (error) => {
  return {
    type: FETCH_DOCUMENTS_FAILURE,
    payload: error,
  };
};

export const fetchDocuments = (vehicleId) => (dispatch, getState) => {
  dispatch({ type: FETCH_DOCUMENTS, payload: vehicleId });

  const documentsInstance = new GetDocuments({
    params: { id: vehicleId },
    credentials: getState().authentication.credentials,
  });
  documentsInstance.call().then(
    (response) => {
      dispatch(fetchDocumentsSuccess(response.data));
    },
    (err) => {
      return dispatch(fetchDocumentsFailure(err));
    }
  );
};

export const getPromotionDetails = (vehicleId, buyingOptions) => (
  dispatch,
  getState
) => {
  dispatch({ type: FETCH_PROMOTION_DETAILS });
  const params = {
    vehicleId: vehicleId,
    campaignId: buyingOptions?.campaignId,
    vehiclePurchaseId: buyingOptions?.vehiclePurchaseId,
  };

  const livePromotionInstance = new GetPromotionDetailsForBuyer({
    params,
    credentials: getState().authentication.credentials,
  });

  dispatch(openModal("promotion"));

  livePromotionInstance.call().then(
    (response) => {
      dispatch(getPromotionDetailsSuccess(response?.data));
      dispatch(
        fetchPromotionSupportingDocuments(
          response?.data?.campaignId,
          USER_TYPE_MAP.Buyer,
          "Campaign",
          vehicleId,
          response?.data?.versionNo
        )
      );
    },
    (err) => dispatch(getPromotionDetailsError(err))
  );
};

export const getPromotionDetailsSuccess = (response) => {
  return {
    type: FETCH_PROMOTION_DETAILS_SUCCESS,
    payload: response,
  };
};

export const getPromotionDetailsError = (err) => {
  return {
    type: FETCH_PROMOTION_DETAILS_FAILURE,
    payload: err,
  };
};

export const fetchPromotionSupportingDocuments = (
  Id,
  userType,
  type,
  vehicleId,
  sourceFolder
) => (dispatch, getState) => {
  dispatch({ type: FETCH_PROMOTION_SUPPORTING_DOCUMENT });

  const promotionDocInstance = new FetchSupportingDocumentsForBuyer({
    params: {
      Id,
      userType,
      type,
      vehicleId,
      sourceFolder,
    },
    credentials: getState().authentication.credentials,
  });

  promotionDocInstance.call().then(
    (response) => {
      response.data.forEach((keyValuePair) => {
        dispatch(savePromotionSupportingDocument(null, keyValuePair));
      });
      dispatch({ type: FETCH_PROMOTION_SUPPORTING_DOCUMENT_SUCCESS });
    },
    (err) => {
      dispatch(fetchPromotionSupportingDocumentFailure(err));
    }
  );
};

export const fetchPromotionSupportingDocumentFailure = (error) => {
  return {
    type: FETCH_PROMOTION_SUPPORTING_DOCUMENT_FAILURE,
    payload: error,
  };
};

export const savePromotionSupportingDocument = (file, keyValue) => {
  const extendedData = extendPromotionSupportingDocument(file, keyValue);
  return {
    type: SAVE_PROMOTION_SUPPORTING_DOCUMENT,
    payload: extendedData,
  };
};

const getIndexedDetailsSuccess = (data) => {
  return {
    type: GET_INDEXED_DETAILS_SUCCESS,
    payload: data,
  };
};
const getIndexedDetailsFailure = (error) => {
  return {
    type: GET_INDEXED_DETAILS_FAILURE,
    payload: error,
  };
};

export const getIndexedDetails = (pageNumberNext, pageNumberPrevious) => (dispatch, getState) => {
  dispatch({ type: GET_INDEXED_DETAILS });

  const bidState = getState().bid.bids.byVehicleId;
  if (Object.keys(bidState).length) {
    dispatch(clearBidData());
  }

  const filters = getState().filter.filters;
  const toolbar = getState().toolbar;
  const pageNumber = pageNumberNext || pageNumberPrevious;
  const pageSize = 1;


  const { sort, bucketType } = toolbar;

  const params = {
    filters,
    pageSize,
    sort,
    purchaseSession: bucketType,
    pageNumber,
  };
  const searchInstance = new IndexedDetails({
    params,
    credentials: getState().authentication.credentials,
  });

  searchInstance.call().then(
    (response) => {
      dispatch(getIndexedDetailsSuccess(response));
    },
    (err) => {
      return dispatch(getIndexedDetailsFailure(err));
    }
  );
};

export const saveVehicleIndex = (vehicleInfo) => {
  return {
    type: SAVE_VEHICLE_INDEX,
    payload: vehicleInfo,
  };
};

export const clearSavedVehicleIndex = (clearIndex) => {
  return {
    type: CLEAR_SAVED_VEHICLE_INDEX,
    payload: clearIndex,
  };
};

export const updateVehicleBuyingOptions = (allowBid) => {
  return {
    type: UPDATE_VEHICLE_BUYING_OPTIONS,
    payload: allowBid,
  };
};


const extendPromotionSupportingDocument = (file, keyValue) => {
  const blockSize = 1048576 * 4; //it's 4MB
  const addForRemainder = file?.size % blockSize === 0 ? 0 : 1;
  const noOfBlocks = Math.floor(file?.size / blockSize) + addForRemainder;

  return {
    file: file,
    fileName: file?.name ?? keyValue?.key,
    progress: 0,
    beginingOfTheBlock: 0,
    endOfTheBlock: blockSize,
    noOfBlocks: noOfBlocks || 1,
    blockNo: 1,
    uploaded: !!keyValue?.value,
    url: keyValue?.value,
  };
};

const initialState = {
  isFetching: false,
  error: null,
  data: null,
  savedVehicleIndex: null,
  indexedDetailsResults:{},
  documentsData: {
    isFetching: false,
    documents: null,
    error: null,
  },
  standardEquipmentData: {
    isFetching: false,
    standardEquipment: null,
    error: null,
  },
  serviceHistoryData: {
    isFetching: false,
    serviceHistory: null,
    error: null,
  },
  technicalSpecificationData: {
    isFetching: false,
    data: null,
    error: null,
  },
  promotionDetails: {
    isFetching: false,
    isFetchingSupportingDocument: false,
    promotionDetails: null,
    error: null,
    options: {
      promotionDocuments: [],
    },
  },
  tabs: [
    "vehicleDetails",
    "standardEquipment",
    "serviceHistory",
    "inspectionReport",
    "technicalSpecification",
  ],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VEHICLE_DETAILS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_VEHICLE_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };
    case FETCH_VEHICLE_DETAILS_FAILURE:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.payload,
      };
    case CLEAR_VEHICLE_DETAILS:
      return {
        ...initialState,
      };
    case LAUNCH_MODAL_GALLERY:
      return {
        ...state,
        galleryInitialIndex: action.payload,
      };
    case GET_STANDARD_EQUIPMENT:
      return {
        ...state,
        standardEquipmentData: {
          ...state.standardEquipmentData,
          isFetching: true,
        },
      };
    case GET_STANDARD_EQUIPMENT_SUCCESS:
      return {
        ...state,
        standardEquipmentData: {
          ...state.standardEquipmentData,
          isFetching: false,
          standardEquipment: action.payload,
        },
      };
    case GET_STANDARD_EQUIPMENT_ERROR:
      return {
        ...state,
        standardEquipmentData: {
          ...state.standardEquipmentData,
          isFetching: false,
          error: true,
        },
      };
    case GET_TECHNICAL_SPECIFICATION:
      return {
        ...state,
        technicalSpecificationData: {
          ...state.technicalSpecificationData,
          isFetching: true,
        },
      };
    case GET_TECHNICAL_SPECIFICATION_SUCCESS:
      return {
        ...state,
        technicalSpecificationData: {
          ...state.technicalSpecificationData,
          isFetching: false,
          data: action.payload,
        },
      };
    case GET_TECHNICAL_SPECIFICATION_ERROR:
      return {
        ...state,
        technicalSpecificationData: {
          ...state.technicalSpecificationData,
          isFetching: false,
          error: true,
        },
      };

    case FETCH_SERVICE_HISTORY:
      return {
        ...state,
        serviceHistoryData: {
          ...state.serviceHistoryData,
          isFetching: true,
        },
      };
    case FETCH_SERVICE_HISTORY_FAILURE:
      return {
        ...state,
        serviceHistoryData: {
          ...state.serviceHistoryData,
          isFetching: false,
          error: true,
        },
      };
    case FETCH_SERVICE_HISTORY_SUCCESS:
      return {
        ...state,
        serviceHistoryData: {
          ...state.serviceHistoryData,
          isFetching: false,
          serviceHistory: action.payload,
        },
      };
    case FETCH_DOCUMENTS:
      return {
        ...state,
        documentsData: {
          ...state.documentsData,
          isFetching: true,
        },
      };
    case FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documentsData: {
          ...state.documentsData,
          isFetching: false,
          documents: action.payload,
        },
      };
    case FETCH_DOCUMENTS_FAILURE:
      return {
        ...state,
        documentsData: {
          ...state.documentsData,
          isFetching: false,
          error: true,
        },
      };
    case FETCH_PROMOTION_DETAILS:
      return {
        ...state,
        promotionDetails: {
          ...state.promotionDetails,
          isFetching: true,
        },
      };
    case FETCH_PROMOTION_DETAILS_SUCCESS:
      return {
        ...state,
        promotionDetails: {
          ...state.promotionDetails,
          isFetching: false,
          promotionDetails: action.payload,
        },
      };
    case FETCH_PROMOTION_DETAILS_FAILURE:
      return {
        ...state,
        promotionDetails: {
          ...state.promotionDetails,
          isFetching: false,
          error: action.payload,
        },
      };
    case FETCH_PROMOTION_SUPPORTING_DOCUMENT:
      return {
        ...state,
        promotionDetails: {
          ...state.promotionDetails,
          options: {
            ...state.promotionDetails?.options,
            promotionDocuments: [],
          },
          isFetchingSupportingDocument: true,
        },
      };
    case FETCH_PROMOTION_SUPPORTING_DOCUMENT_FAILURE:
      return {
        ...state,
        promotionDetails: {
          ...state.promotionDetails,
          isFetchingSupportingDocument: false,
        },
      };
    case FETCH_PROMOTION_SUPPORTING_DOCUMENT_SUCCESS:
      return {
        ...state,
        promotionDetails: {
          ...state.promotionDetails,
          isFetchingSupportingDocument: false,
        },
      };
    case SAVE_PROMOTION_SUPPORTING_DOCUMENT:
      return {
        ...state,
        promotionDetails: {
          ...state.promotionDetails,
          options: {
            ...state.promotionDetails?.options,
            promotionDocuments: [
              ...(state.promotionDetails?.options?.promotionDocuments || []),
              action.payload,
            ],
          },
        },
      };
      case GET_INDEXED_DETAILS:
        return updateObject(state, { isFetching: true });
      case GET_INDEXED_DETAILS_SUCCESS:
        return updateObject(state, {
          isFetching: false,
          indexedDetailsResults: action.payload,
        });
      case GET_INDEXED_DETAILS_FAILURE:
        return updateObject(state, {
          isFetching: false,
          indexedDetailsResults: {},
          error: true,
        });
        case SAVE_VEHICLE_INDEX:
          return {
            ...state,
            savedVehicleIndex: action.payload,
          };
          case CLEAR_SAVED_VEHICLE_INDEX:
            return {
              ...state,
              savedVehicleIndex: action.payload,
            };
          case UPDATE_VEHICLE_BUYING_OPTIONS:
            return {
              ...state,
              data: {
                ...state.data,
                buyingOptions: {
                  ...state.data?.buyingOptions,
                  allowBid: action.payload,
                },
              },
            };
    default:
      return state;
  }
};

export default reducer;
