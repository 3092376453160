import get from "lodash.get";
import BaseApi from "./Base";
import clientSettings from "../clientappsettings.json";
import {
  formatPricingDetailData,
  getResponseObjectProperties,
} from "../shared/utility";

class OrdersApi extends BaseApi {
  baseUrl = clientSettings.API_URL;
  requestUrl = `/Vehicle/Purchase/${this.getParams().id}`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatResponseData(response = {}) {
    const data = get(response, "data", {});

    // Get applicable and default fields
    const vehicleFields = getResponseObjectProperties(
      data,
      [
        "vehicleId",
        "make",
        "range",
        "model",
        "derivative",
        "regNo",
        "saleType",
        "v5DocumentReferenceNumber"
      ],
      true
    );
    const paymentFields = getResponseObjectProperties(
      data,
      [
        "paymentMethod",
        "toBankAccountNo",
        "toBankAccountSortCode",
        "paymentMethodImage",
        "fundingProviderName",
      ],
      true
    );
    const deliveryFields = getResponseObjectProperties(
      get(data, "deliveryLocation", {}),
      ["locationName", "contactAddress"],
      true
    );
    const collectionFields = getResponseObjectProperties(
      get(data, "collectionLocation", {}),
      ["locationName", "contactAddress"],
      true
    );

    // Shape state objects
    const vehicleDetails = {
      label: "Vehicle",
      ...vehicleFields,
    };

    const v5DocumentReferenceNumber = {
      label: "V5 Reference",
      refNum: vehicleFields.v5DocumentReferenceNumber,
    }

    const paymentMethod = {
      label: "Payment Method",
      accountLabel: "Account",
      sortcodeLabel: "Sort Code",
      title: paymentFields.paymentMethod,
      account: paymentFields.toBankAccountNo,
      sortcode: paymentFields.toBankAccountSortCode,
      image: paymentFields.paymentMethodImage,
      fundingProviderName: paymentFields.fundingProviderName,
    };

    const priceBreakdown = {
      data: formatPricingDetailData(get(data, "pricingDetails", {})),
      labels: {
        priceHeading: "Price breakdown",
        priceLabel: "Vehicle Price",
        adminChargeLabel: "Admin Charge",
        deliveryCostLabel: "Delivery Cost",
        netLabel: "Net",
        totalLabel: "Total",
        vatLabel: "VAT",
        totalCostLabel: "Total cost",
      },
    };

    const deliveryDetails = {
      label: "Delivery Address",
      title: deliveryFields.locationName,
      text: [...deliveryFields.contactAddress],
      deliveryTbc: data.deliveryTbc,
    };

    const collectionDetails = {
      label: "Collection Address",
      title: collectionFields.locationName,
      text: [...collectionFields.contactAddress],
    };

    const vendorAddress = {
      label: "Vendor Address",
      title: get(data, "vendorName", ""),
      text: get(data, "vendorPostalAddress", ""),
    };

    const contactDetails = {
      label: "Contact Details",
      title: get(data, "mainContactName", ""),
      text: get(data, "mainContactTelNo", ""),
    };

    // Final shape
    return {
      vehiclePurchaseId: parseInt(this.getParams().id, 10),
      saleStatus: data.saleStatus,
      priceBreakdown,
      paymentMethod,
      vehicleDetails,
      v5DocumentReferenceNumber,
      deliveryMethod: get(data, "deliveryMethod", ""),
      showLogisticsTimeline: get(data, "showLogisticsTimeline", ""),
      deliveryDetails,
      collectionDetails,
      vendorAddress,
      contactDetails,
      invoiceHeaderId: get(data, "invoiceHeaderId", 0),
      invoiceHeaderLabel: get(data, "invoiceHeaderLabel", ""),
      downloadInvoiceLabel: get(data, "downloadInvoiceLabel", ""),
    };
  }
}

export class DeliveryAddressApi extends BaseApi {
  baseUrl = clientSettings.TRADE_BUYER_URL;
  requestUrl = `/VehicleInfo/confirmDelivery`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  method = "POST";
}

export class LogisticsStatusApi extends BaseApi {
  baseUrl = clientSettings.API_URL;
  requestUrl = `/Vehicle/LogisticsStatus/${this.getParams().id}`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatResponseData(response) {
    if (response.data) return response.data;
    return response;
  }
}

export default OrdersApi;
