import React, {useContext} from "react";
import HeaderText from "../../HeaderText";
import ParagraphText from "../../ParagraphText";
import { reviewCompleteLabel } from "./const";
import TextArea from "../../TextArea";
import { ReviewCompleteReasonContext } from './context/context';

const VendorReviewCompleteMessage = () => {
  const { reviewCompleteReason, setReviewCompleteReason } = useContext(
    ReviewCompleteReasonContext
  );

  function onChangeInput(e) {
    const { value } = e.target;
    setReviewCompleteReason(value);
  }

  return (
    <div style={{width: "90rem"}}>
      <HeaderText>{reviewCompleteLabel.HEADER_TEXT}</HeaderText>
      <ParagraphText>
        {reviewCompleteLabel.SUB_HEADER_TEXT}
      </ParagraphText>
      <TextArea
        value={reviewCompleteReason}
        className="text-area__input"
        onChange={onChangeInput}
        resize="none"
        maxLength="1000"
        rows="7"
        cols="50"
        validValue={true}
      ></TextArea>
    </div>
  );
};

export default VendorReviewCompleteMessage;
