import styled from "styled-components";
import TariffInformation from "./TariffInformation";
import breakpoints from "../../theme/breakpoints";

export const StyledTariffInformation = styled(TariffInformation)`
  background-color: ${(props) => props.theme.COLOURS.WHITE};
  height: 100%;
  width: 100%;

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    box-sizing: border-box;
    border: 0.2rem solid ${(props) => props.theme.COLOURS.ACCENT.base};
    padding: ${(props) => props.theme.SPACING.MEDIUM};
    border-radius: 0.9rem;
    height: auto;
  }

  .modal-header {
    > * {
      margin-bottom: ${(props) => props.theme.SPACING.REGULAR};
    }
  }

  .tariff-fees__three-col-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      flex: 1;

      &:first-of-type {
        flex: 2;
      }
    }
  }
`;

export default StyledTariffInformation;
