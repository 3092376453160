import styled from "styled-components";
import DealerFundingRequest from "./DealerFundingRequest";
import breakpoints from "../../theme/breakpoints";

export const StyledDealerFundingRequest = styled(DealerFundingRequest)`
  background-color: ${(props) => props.theme.COLOURS.WHITE};
  height: 100%;

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    box-sizing: border-box;
    border: 0.2rem solid ${(props) => props.theme.COLOURS.ACCENT.base};
    padding: ${(props) => props.theme.SPACING.MEDIUM};
    border-radius: 0.9rem;
    height: auto;
  }

  .modal-header {
    > * {
      margin-bottom: ${(props) => props.theme.SPACING.REGULAR};
    }

    > *:last-child {
      margin-bottom: 0;
    }

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      > *:last-child {
        margin-bottom: ${(props) => props.theme.SPACING.REGULAR};
      }
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;

    .summary-price-breakdown {
      > div {
        padding: 0;
      }
      margin-bottom: ${(props) => props.theme.SPACING.REGULAR};
    }

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      flex-direction: row;
      .summary-price-breakdown {
        flex: 45%;
      }

      .funding-provider__list {
        flex: 55%;
      }
    }

    .funding-provider__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      padding-bottom: ${(props) => props.theme.SPACING.SMALL};
      margin-bottom: ${(props) => props.theme.SPACING.SMALL};
      border-bottom: 0.2rem solid
        ${(props) => props.theme.COLOURS.PRIMARY.shades[5]};

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }

      @media (min-width: ${breakpoints.PHONE}) {
        flex-direction: row;
        padding-bottom: ${(props) => props.theme.SPACING.REGULAR};
        .funding-provider__actions {
          justify-content: space-around;
        }
      }

      .funding-provider__actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @media (min-width: ${breakpoints.PHONE}) {
          flex: 66%;
          padding-bottom: 0;
          border-bottom: none;
        }
      }

      .funding-provider__check-balance {
        margin: 0 ${(props) => props.theme.SPACING.SMALL} 0
          ${(props) => props.theme.SPACING.SMALL};
      }

      .funding-provider__logo {
        margin-bottom: ${(props) => props.theme.SPACING.SMALL};

        @media (min-width: ${breakpoints.PHONE}) {
          margin-bottom: 0;
          flex: 33%;
        }

        > img {
          height: 7rem;
          width: 18rem;
          object-fit: contain;
        }
      }
    }
  }
`;

export default StyledDealerFundingRequest;
