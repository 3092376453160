import ClaimItem from "./ClaimItem";
import breakpoints from "../../theme/breakpoints";
import styled from "styled-components";
import ParagraphText from "../ParagraphText";
import spacing from "../../theme/base/spacing";
import ButtonLink from "../ButtonLink";
import BidInputError from "./../BidInput/child-components/BidInputError";
import colours from "../../theme/base/colours";
import Button from "../Button";

export const MainContainer = styled.div`
  padding: 3rem 6rem 6rem 4rem;

  ${ButtonLink} {
    text-align: left;
    word-break: break-all;
  }

  @media (max-width: ${breakpoints.LARGE_DESKTOP}) {
    padding-right: ${spacing.REGULAR};
  }

  @media (max-width: ${breakpoints.PHONE}) {
    padding: ${spacing.REGULAR} ${spacing.SMALL};
  }

  .activity-log_reference-container {
    width: 100%;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  margin-top: ${props => props.marginTop || props.theme.SPACING.LARGE};
  margin-bottom: ${props => props.marginBottom};

  @media (max-width: ${breakpoints.TABLET}) {
    margin-top: ${props => props.marginTop || props.theme.SPACING.MEDIUM};
    margin-bottom: ${props => props.marginTabletBottom || 0};
    flex-direction: column;
    align-items: center;
  }
  
  .trashcan-container {
    cursor: pointer;
  }
`;

export const ItemLabel = styled.div`
  width: 40%;
  text-align: left;
  align-items: ${props => props.alignItems};
  display: ${props => props.display};

  p {
    margin-top: 0;
  }

  @media (max-width: ${breakpoints.TABLET}) {
    width: 100%;
  }
`;

export const ItemContent = styled.div`
  width: 60%;
  text-align: left;
  p {
    margin-top: 0;
  }

  @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
    width: 55%;
  }

  @media (max-width: ${breakpoints.TABLET}) {
    width: 100%;
    flex-direction: column;
  }

  @media (max-width: ${breakpoints.PHONE}) {
    margin: 1rem 2rem;
  }

  .document-links-lines-container {
    display: flex;
    gap: 2rem;

    .document-link-text-box{
          max-width: 83.5%
        }
  }

  .link-item {
    display: flex;
    gap: 2rem;
    margin: 0.75rem;
  }
}

  .document-link-controls-group {
    display: flex;
    justify-content: center;
    align-content: center;
    
    ${Button} {
      display: flex;
      padding: 1rem;
      height: 4.9rem;
      align-items: center;
      margin: auto;
    }
  }
`;

export const ItemContentTwoColumn = styled.div`
  width: 100%;
  text-align: left;
  align-items: center;
  align-items: center p {
    margin-top: 0;
  }
  display: flex;
  margin-bottom: ${props => props.theme.SPACING.SMALL};
  @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    flex-direction: column;
  }
`;

export const ItemContentTwoColumnHeader = styled.div`
  width: 32%;
  display: flex;
  align-items: center;
  @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
    width: 100%;
  }
`;

export const ItemContentTwoColumnInput = styled.div`
  width: 68%;
  @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
    width: 100%;
  }
`;

export const ContactInputHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const MakeModelRegistrationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin-top: 0;
    margin-right: ${props => props.theme.SPACING.SMALL};
  }
  label {
    background-color: ${colours.YELLOW.base};
    color: ${({ labelColor }) => labelColor};
    font-weight: ${({ labelFontWeight }) => labelFontWeight};
    padding: 0.4rem;
    border-radius: 0.2rem;
  }

  @media (max-width: ${breakpoints.PHONE}) {
    align-items: center;
  }
`;

export const DerivativeRow = styled.div`
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${ParagraphText} {
    text-transform: uppercase;
    line-height: 2.6rem;
  }
`;

export const HeaderContainer = styled.div`
  border-bottom: 0.2rem solid ${({ borderBottomColor }) => borderBottomColor};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media (max-width: ${breakpoints.TABLET}) {
    padding-bottom: 2rem;
  }
`;

export const HeaderPrimaryRow = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: ${breakpoints.TABLET}) {
    padding-bottom: 2rem;
  }
`;

export const HeaderSecondaryRow = styled.div`
  display: flex;
  width: 100%;

  ${ParagraphText} {
    font-size: 1.8rem;
    padding-left: 1rem;
  }

  @media (max-width: ${breakpoints.TABLET}) {
    flex-direction: column;
  }
`;

export const HeaderItem = styled.div`
  width: ${({ width }) => width || "50%"};
  text-align: left;
  display: flex;
  align-items: center;
  padding-top: 2rem;
  ${ParagraphText} {
    font-size: 2.1rem;
  }

  @media (min-width: ${breakpoints.TABLET}) {
    justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  }

  @media (max-width: ${breakpoints.TABLET}) {
    width: 100%;
  }
`;

export const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  margin-top: 8rem;
  justify-content: flex-end;

  & > * {
    width: 15%;
    margin-left: 1rem;
    text-align: center;

    @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
      width: 21%;
    }
  }

  @media (max-width: ${breakpoints.TABLET}) {
    justify-content: space-between;
    margin-top: 5rem;
    margin-left: 0;
    display: flex;
    flex-wrap: wrap;

    & > * {
      margin-left: 0;
    }

    & > button:nth-of-type(1) {
      flex: 0 1 49%;
      margin-bottom: ${spacing.SMALL};
    }
    & > button:nth-of-type(2) {
      flex: 0 1 49%;
      margin-bottom: ${spacing.SMALL};
    }
    & > button:nth-of-type(3) {
      width: 100%;
    }
  }
`;

export const ParagraphTextContainer = styled.div`
  width: 80%;

  ${ParagraphText} {
    text-align: justify;
  }
`;

export const DragDropItemContent = styled.div`
  div {
    margin: 0.5rem 0;
    display: flex;
    height: min-content;

    > div {
      margin-right: 1rem;
    }
  }

  .failedItems {
    margin: 0;
    pointer-events: none;
    ${ButtonLink} {
      text-align: left;
      word-break: break-all;
      color: ${colours.RED.base};
    }
    .failedFile {
      width: 45rem;
      margin-right: 1rem;
    }
    ${BidInputError} {
      line-height: 2rem;
      height: 5rem;
      margin-right: 0rem;
    }
  }
`;

export const TrashIconContainer = styled.div`
  cursor: pointer;
  margin-left: 0.25rem;
  align-content: center;
`;

export default ClaimItem;
