import Search from "./Search";
import { formatPurchasedVehicleResultsResponse } from "../shared/utility";
import { formatFilterOptions } from "../shared/filter";

export class PurchasesArchiveApi extends Search {
  requestUrl = "/Purchases";

  formatRequestData(params) {
    const { pageSize, pageNumber, filters } = params;
    return {
      pageSize,
      pageNumber,
      ...formatFilterOptions({
        filterObject: filters,
        pageType: "purchases",
      }),
    };
  }

  formatResponseData(response) {
    return formatPurchasedVehicleResultsResponse(response);
  }
}

export class PurchasesArchiveReportApi extends Search {
  requestUrl = `/purchases/report`;

  formatRequestData(params) {
    const { filters, registration } = params;

    if (registration) {
      return { registration };
    }

    return {
      ...formatFilterOptions({
        filterObject: filters,
        pageType: "purchases",
      }),
    };
  }

  formatResponseData(response) {
    if (response.data) {
      const blob = new Blob([response.data], { type: "application/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "Purchases.csv";
      link.click();
    }

    return response;
  }
}
