import styled from "styled-components";

import Dropdown from "./Dropdown";
import { StyledParagraphText } from "../ParagraphText";
import breakpoints from "../../theme/breakpoints";

const StyledDropdown = styled(Dropdown)`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .dropdown__label {
    margin-bottom: ${(props) => props.theme.SPACING.SMALL};
  }
  .dropdown__trigger {
    position: relative;
    width: 100%;
    height: 5.2rem;
    color: ${(props) => props.theme.COLOURS.PRIMARY.shades[50]};
    box-sizing: border-box;
    border: 0.2rem solid ${(props) => props.theme.COLOURS.SECONDARY.shades[20]};
    border-radius: 0.5rem;
    background-color: ${(props) => props.theme.COLOURS.WHITE};
    display: flex;
    padding-right: ${(props) => props.theme.SPACING.REGULAR};
    padding-left: ${(props) => props.theme.SPACING.REGULAR};
    cursor: pointer;
    align-items: center;
    ${(props) => props.theme.FONTS.Text["paragraphCompact"]}
  }
  .dropdown__trigger_error {
    border-color: ${(props) => props.theme.COLOURS.RED.base};
  }

  .dropdown__trigger-icon {
    display: block;
    top: 1.5rem;
    position: absolute;
    right: 2rem;
    &.open {
      transform: rotate(-180deg);
    }
  }
  .dropdown__wrapper {
    position: relative;
  }
  .dropdown__container {
    box-sizing: border-box;
    border: 0.2rem solid ${(props) => props.theme.COLOURS.SECONDARY.shades[20]};
    border-radius: 0.5rem;
    background-color: ${(props) => props.theme.COLOURS.WHITE};
    position: relative;
    top: 0.8rem;
    width: ${props => props.allowOverflow ? "" : "100%"};
    z-index: 2;
    overflow: auto;
    max-height: 29rem;

    @media (min-width: ${breakpoints.SMALL_DESKTOP}) {
      position: absolute;
    }

    ${StyledParagraphText} {
      padding: ${(props) => props.theme.SPACING.SMALL};
    }
  }
  .arrow {
    height: 0;
    width: 0;
    position: absolute;
    transform: translateX(100%);
    z-index: 3;
    pointer-events: none;
    &:after,
    &:before {
      bottom: 100%;
      left: 3rem;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: transparent;
      border-bottom-color: ${(props) => props.theme.COLOURS.WHITE};
      border-width: 0.8rem;
      margin-left: -0.8rem;
      top: -0.6rem;
    }
    &::before {
      border-color: transparent;
      border-bottom-color: ${(props) =>
        props.theme.COLOURS.SECONDARY.shades[20]};
      border-width: 1rem;
      margin-left: -1rem;
      top: -1.1rem;
    }
  }
  .dropdown__option {
    padding: ${(props) => props.theme.SPACING.SMALL};
    cursor: pointer;
    ${(props) => props.theme.FONTS.Text["paragraphCompact"]}
    &:hover {
      color: ${(props) => props.theme.COLOURS.WHITE};
      background-color: ${(props) => props.theme.COLOURS.PRIMARY.base};
    }
    &:first-of-type {
      border-radius: 0.5rem 0.5rem 0 0;
    }
    &:last-of-type {
      border-radius: 0 0 0.5rem 0.5rem;
    }
    &.selected {
      background-color: ${(props) => props.theme.COLOURS.PRIMARY.base};
      font-weight: ${(props) => props.theme.FONTS.weights[60]};
      color: ${(props) => props.theme.COLOURS.WHITE};
    }
    &.disabled {
      pointer-events: none;
      color: ${(props) => props.theme.COLOURS.PRIMARY.shades[40]};
    }
  }
`;

export default StyledDropdown;
