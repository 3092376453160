import React from "react";
import ModalInsert from "../../components/ModalInsert";
import Panel from "../../components/Panel";
import AlertInsert from "../../components/AlertInsert";

const VehicleUnavailableNewTab = () => {
  const onCloseTab = () => {
    window.open("about:blank", "_self");
    window.close();
  };

  return (
    <ModalInsert>
      <Panel padding="NONE">
        <AlertInsert
          action={onCloseTab}
          headerText="Sorry, this vehicle is unavailable"
          bodyText="The vehicle you were looking at is no longer available for purchase"
          buttonText="Close Window"
        />
      </Panel>
    </ModalInsert>
  );
};

export default VehicleUnavailableNewTab;
