import React from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import { fetchStockList } from "../../../state/reducers/stocklist";
import ModalInsert from "../../components/ModalInsert";
import StocklistDownload from "./../../components/StocklistDownload";

const StocklistDownloadModalInsert = props => {
  const dispatch = useDispatch();

  const onCancel = () => {
    dispatch(closeModal());
  };

  const onDownload = () => {
    dispatch(closeModal());
    dispatch(fetchStockList());
  };
  return (
    <ModalInsert layout="flex" width="55rem">
      <StocklistDownload
        onCancel={onCancel}
        onDownload={onDownload}
      ></StocklistDownload>
    </ModalInsert>
  );
};

export default StocklistDownloadModalInsert;
