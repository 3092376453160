import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../../views/components/ModalInsert";
import AcceptSaleDecision from "../AcceptSaleDecision";
import AcceptSaleForm from "../../components/AcceptSaleForm/AcceptSaleForm";
import { formatPrice } from "../../../shared/utility";

const AcceptSaleModalInsert = () => {
  const dispatch = useDispatch();
  const activeClaimId = useSelector(
    (state) => state.global.claims.activeClaimId
  );

  const claimCardData = useSelector((state) =>
    state.claims.data.claims.find((obj) => {
      return obj.claimId === activeClaimId;
    })
  );

  const canMakeDecision =
    claimCardData.offerSaleCancellation && claimCardData.vendorOffer > 0;
  const monetaryOffer = `£${formatPrice(claimCardData.vendorOffer)}`;

  const radioOptions = [
    {
      value: "AmountAccepted",
      optionName: `${monetaryOffer}`,
    },
    { value: "SaleCancellation", optionName: "Sale Cancellation" },
  ];
  const offerChoice =
    claimCardData.offerSaleCancellation && !claimCardData.vendorOffer
      ? "SaleCancellation"
      : "AmountAccepted";
  const [radioOptionChoice, setRadioOptionChoice] = useState(offerChoice);

  const formData = {
    claimId: claimCardData?.claimId,
    type: radioOptionChoice,
    amount: claimCardData.vendorOffer,
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  return (
    <Fragment>
      <ModalInsert>
        {canMakeDecision ? (
          <AcceptSaleForm
            formData={formData}
            radioOptions={radioOptions}
            monetaryOffer={monetaryOffer}
            radioOptionChoice={radioOptionChoice}
            setRadioOptionChoice={setRadioOptionChoice}
            vendorName={claimCardData.vendorName}
            onCancel={onCancel}
          />
        ) : (
          <AcceptSaleDecision
            formData={formData}
            radioOptions={radioOptions}
            radioOptionChoice={radioOptionChoice}
            vendorName={claimCardData.vendorName}
          />
        )}
      </ModalInsert>
    </Fragment>
  );
};

export default AcceptSaleModalInsert;
