import React from "react";
import PropTypes from "prop-types";
import VehicleKeyInformation from "../VehicleKeyInformation";
import VehicleGrade from "../VehicleGrade";

const VehicleCardKeyInfo = ({
  className,
  serviceHistory,
  v5,
  mot,
  motExpiryDate,
  auctionGrade,
  condition,
  column,
  showDamagePercentage,
  damagePercentage,
  small
}) => {
  return (
    <div className={className}>
      <div>
        <VehicleKeyInformation
          serviceHistory={serviceHistory}
          v5={v5}
          mot={mot}
          motExpiryDate={motExpiryDate}
          column={column}
          small={small}
        />
      </div>
      <div>
        <VehicleGrade
          grade={auctionGrade}
          condition={condition}
          showDamagePercentage={showDamagePercentage}
          damagePercentage={damagePercentage}
          small={small}
        />
      </div>
    </div>
  );
};

VehicleCardKeyInfo.propTypes = {
  serviceHistory: PropTypes.oneOf([
    "NotApplicable",
    "FullDealerServiceHistory",
    "FullServiceHistory",
    "None",
    "Partial",
    "Unknown"
  ]),
  v5: PropTypes.oneOf(["Yes", "AppliedFor", "PassedToTrade", "No", "Unknown", "DispatchedToAuction"]),
  mot: PropTypes.oneOf(["Unknown", "NotApplicable", "No", "Yes"]),
  motExpiryDate: PropTypes.string,
  grade: PropTypes.string,
  condition: PropTypes.oneOf(["Unknown", "Average", "Clean", "BelowAverage"]),
  column: PropTypes.bool,
  small: PropTypes.bool
};

export default VehicleCardKeyInfo;
