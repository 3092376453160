import Api from "./Base";
import settings from "../clientappsettings.json";
import { formatPurchaseDetailData } from "../shared/utility";
import get from "lodash.get";

export class GetPurchaseDetails extends Api {
  baseUrl = settings.API_URL;
  requestUrl = `/Vehicle/Purchasing`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };

  formatResponseData(data) {
    const purchaseData = get(data, "data", {});
    const groupSites = get(purchaseData, "groupSites", []);

    const {
      paymentOptions,
      deliveryOptions,
      selectedGroupSiteId,
      ...rest
    } = purchaseData;
    const formattedDeliveryOptions = deliveryOptions.map(el => {
      el.title = el.title !== "Delivery" ? el.title : "Default";
      return el;
    });
    return {
      data: {
        paymentOptions,
        deliveryOptions: formattedDeliveryOptions,
        isGroupBuyer: groupSites && !!groupSites.length,
        selectedGroupSiteId,
        groupBuyerOptions: groupSites,
        purchaseDetails: formatPurchaseDetailData(rest)
      }
    };
  }
}

export class PostPurchaseConfirmation extends GetPurchaseDetails {
  requestUrl = `/Vehicle/Purchase`;
  method = "POST";

  formatResponseData(data) {
    return data;
  }
}
