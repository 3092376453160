const PRIMARY = {
  base: "#0E305F",
  shades: {
    5: "#F4F5F7",
    10: "#E6EAEF",
    20: "#CFD6DF",
    30: "#B6C0CF",
    40: "#9FACBF",
    50: "#8697AF",
    60: "#6E839F",
    70: "#566E8F",
    80: "#3E597F",
    90: "#26446F",
  },
};

const ACCENT = {
  base: "#31A1E0",
  shades: {
    10: "#EAF5FB",
    20: "#D6ECF9",
    30: "#C1E2F5",
    40: "#ADD9F3",
    50: "#98D0EF",
    60: "#83C7EC",
    70: "#6EBDE9",
    80: "#5AB4E6",
    90: "#45AAE3",
  },
};

const SECONDARY = {
  base: "#0a2243",
  shades: {
    5: "#F4F5F7",
    10: "#e7e9ec",
    20: "#ced3d9",
    30: "#b6bdc7",
    40: "#9da7b4",
    50: "#8591a1",
    60: "#6c7a8e",
    70: "#54647b",
    80: "#3b4e69",
    90: "#233856",
  },
};

const SECONDARY_ACCENT = {
  base: "#2781b3",
  shades: {
    5: "#F4F5F7",
    10: "#e9f2f7",
    20: "#d4e6f0",
    30: "#bed9e8",
    40: "#a9cde1",
    50: "#93c0d9",
    60: "#7db3d1",
    70: "#68a7ca",
    80: "#529ac2",
    90: "#3d8ebb",
  },
};

const GREEN = {
  base: "#429D16",
  shades: {
    10: "#ECF5E7",
    20: "#D9EBD0",
    30: "#C6E1B9",
    40: "#B2D8A2",
    50: "#A0CE8A",
    60: "#8EC473",
    70: "#7ABA5B",
    80: "#68B145",
    90: "#54A62D",
  },
};

const RED = {
  base: "#F22B2B",
  shades: {
    10: "#FDE9E9",
    20: "#FCD5D5",
    30: "#FBBFBF",
    40: "#FAAAAA",
    50: "#F89595",
    60: "#F78080",
    70: "#F56A6A",
    80: "#F55555",
    90: "#F34040",
  },
};

const ORANGE = {
  base: "#FE7214",
  shades: {
    10: "#FEF0E7",
    20: "#FFE3D0",
    30: "#FED4B8",
    40: "#FFC7A1",
    50: "#FEB889",
    60: "#FEAA72",
    70: "#FE9C5A",
    80: "#FE8E43",
    90: "#FE802B",
  },
};

const TRANSPARENCIES = {
  backgroundImage: "rgba(14,48,95,0.7)",
  primary: "rgba(14,48,95,0.3)",
  primaryDark: "rgba(14,48,95,0.9)",
  green: "rgba(66,157,22,0.05)",
  white: "rgba(27, 34, 59, 0.1)",
};

const COLOURS = {
  PRIMARY,
  SECONDARY,
  ACCENT,
  SECONDARY_ACCENT,
  RED,
  GREEN,
  ORANGE,
  BLACK: "#000000",
  BLUE: "#2183CD",
  BROWN: "#964B00",
  WHITE: "#FFFFFF",
  YELLOW: "#feb40c",
  SILVER: "#C0C0C0",
  GREY: "#808080",
  CREAM: "#FFFDD0",
  GOLD: "#FFD700",
  PINK: "#FFC0CB",
  PURPLE: "#A020F0",
  BEIGE: "#F5F5DC",
  BRONZE: "#CD7F32",
  MAROON: "#800000",
  TURQUOISE: "#40e0d0",
  ACTION_GREEN:"#4D9152",
  ALERT_RED:"#C33939",
  TRANSPARENCIES,
};

export default COLOURS;
