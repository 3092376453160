import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Header = props => {
  const { backgroundImage, navIsOpen = false } = props;
  const theme = useContext(ThemeContext);

  return (
    <header className={props.className} data-open={navIsOpen}>
      <h1
        style={
          backgroundImage && {
            backgroundColor: theme.COLOURS.TRANSPARENCIES.backgroundImage
          }
        }
      >
        {props.children}
      </h1>
    </header>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default Header;
