import Api from "./Base";
import settings from "../clientappsettings.json";

class RecentSearchesApi extends Api {
  baseUrl = settings.API_URL;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class RecentSearches extends RecentSearchesApi {
  requestUrl = `/Search/RecentSearches`;
  method = "GET";
}

export class FavouriteRecentSearch extends RecentSearchesApi {
  requestUrl = `/Search/RecentSearches/Favourite`;
  method = "POST";
}
