import {
  FetchClaim,
  DeclineClaim,
  InformationRequest,
  ReviewComplete,
  FetchVendorClaimActivityLog,
  FetchPurchaseInformation,
  MakeOffer,
  CancelSale,
  FetchBuyerClaimHistory,
  FetchBuyerClaimHistoryBreakdown,
  FetchSupportingDocuments,
  AddNote,
} from "../../api/ClaimDetailsVendor";
import { openModal, closeModal } from "./global";
import { fetchVendorClaims } from "./claimsVendor";
import { USER_TYPE_MAP } from "../../shared/utility";

export const FETCH_CLAIM = "@claimDetailsVendor/FETCH_CLAIM";
export const FETCH_CLAIM_SUCCESS = "@claimDetailsVendor/FETCH_CLAIM_SUCCESS";
export const FETCH_CLAIM_ERROR = "@claimDetailsVendor/FETCH_CLAIM_ERROR";
export const CLEAR_ACTIVE_CLAIM = "@claimDetailsVendor/CLEAR_ACTIVE_CLAIM";

export const FETCH_VENDOR_CLAIMS_ACTIVITY_LOG =
  "@claimDetailsVendor/FETCH_VENDOR_CLAIMS_ACTIVITY_LOG";
export const FETCH_VENDOR_CLAIMS_ACTIVITY_LOG_SUCCESS =
  "@claimDetailsVendor/FETCH_VENDOR_CLAIMS_ACTIVITY_LOG_SUCCESS_SUCCESS";
export const FETCH_VENDOR_CLAIMS_ACTIVITY_LOG_FAILURE =
  "@claimDetailsVendor/FETCH_VENDOR_CLAIMS_ACTIVITY_LOG_FAILURE";

export const DECLINE_CLAIM = "@claimDetailsVendor/DECLINE_CLAIM";
export const DECLINE_CLAIM_SUCCESS =
  "@claimDetailsVendor/DECLINE_CLAIM_SUCCESS";
export const DECLINE_CLAIM_FAILURE =
  "@claimDetailsVendor/DECLINE_CLAIM_FAILURE";
export const INFORMATION_REQUEST = "@claimDetailsVendor/INFORMATION_REQUEST";
export const INFORMATION_REQUEST_SUCCESS =
  "@claimDetailsVendor/INFORMATION_REQUEST_SUCCESS";
export const INFORMATION_REQUEST_FAILURE =
  "@claimDetailsVendorINFORMATION_REQUEST_FAILURE";
export const REVIEW_COMPLETE = "@claimDetailsVendor/REVIEW_COMPLETE";
export const REVIEW_COMPLETE_FAILURE =
  "@claimDetailsVendor/REVIEW_COMPLETE_FAILURE";
export const REVIEW_COMPLETE_SUCCESS =
  "@claimDetailsVendor/REVIEW_COMPLETE_SUCCESS";
export const FETCH_PURCHASE_INFORMATION =
  "@claimDetailsVendor/FETCH_PURCHASE_INFORMATION";
export const FETCH_PURCHASE_INFORMATION_FAILURE =
  "@claimDetailsVendor/FETCH_PURCHASE_INFORMATION_FAILURE";
export const FETCH_PURCHASE_INFORMATION_SUCCESS =
  "@claimDetailsVendor/FETCH_PURCHASE_INFORMATION_SUCCESS";
export const MAKE_OFFER = "@claimDetailsVendor/MAKE_OFFER";
export const MAKE_OFFER_FAILURE = "@claimDetailsVendor/MAKE_OFFER_FAILURE";
export const MAKE_OFFER_SUCCESS = "@claimDetailsVendor/MAKE_OFFER_SUCCESS";
export const CANCEL_SALE = "@claimDetailsVendor/CANCEL_SALE";
export const CANCEL_SALE_FAILURE = "@claimDetailsVendor/CANCEL_SALE";
export const CANCEL_SALE_SUCCESS = "@claimDetailsVendor/CANCEL_SALE";
export const FETCH_BUYER_CLAIM_HISTORY =
  "@claimDetailsVendor/FETCH_BUYER_CLAIM_HISTORY";
export const FETCH_BUYER_CLAIM_HISTORY_SUCCESS =
  "@claimDetailsVendor/FETCH_BUYER_CLAIM_HISTORY_SUCCESS";
export const FETCH_BUYER_CLAIM_HISTORY_FAILURE =
  "@claimDetailsVendor/FETCH_BUYER_CLAIM_HISTORY_FAILURE";
export const FETCH_BUYER_CLAIM_HISTORY_BREAKDOWN =
  "@claimDetailsVendor/FETCH_BUYER_CLAIM_HISTORY_BREAKDOWN";
export const FETCH_BUYER_CLAIM_HISTORY_BREAKDOWN_SUCCESS =
  "@claimDetailsVendor/FETCH_BUYER_CLAIM_HISTORY_BREAKDOWN_SUCCESS";
export const FETCH_BUYER_CLAIM_HISTORY_BREAKDOWN_FAILURE =
  "@claimDetailsVendor/FETCH_BUYER_CLAIM_HISTORY_BREAKDOWN_FAILURE";
export const FETCH_SUPPORTING_DOCUMENTS = "@claim/FETCH_SUPPORTING_DOCUMENTS";
export const FETCH_SUPPORTING_DOCUMENTS_SUCCESS =
  "@claim/FETCH_SUPPORTING_DOCUMENTS_SUCCESS";
export const FETCH_SUPPORTING_DOCUMENTS_FAILURE =
  "@claim/FETCH_SUPPORTING_DOCUMENT_FAILURE";
export const VENDOR_ADD_NOTE = 
"@claimDetailsVendor/VENDOR_ADD_NOTE";
export const VENDOR_ADD_NOTE_SUCCESS = 
"@claimDetailsVendor/VENDOR_ADD_NOTE_SUCCESS";
export const VENDOR_ADD_NOTE_FAILURE = 
"@claimDetailsVendor/VENDOR_ADD_NOTE_FAILURE";

export const fetchClaim = (claimId) => (dispatch, getState) => {
  dispatch({ type: FETCH_CLAIM });

  const claimsInstance = new FetchClaim({
    params: {
      claimId: claimId,
    },
    credentials: getState().authentication.credentials,
  });

  claimsInstance.call().then(
    (response) => {
      dispatch(fetchClaimSuccess(response));
      dispatch(fetchSupportingDocuments(claimId, USER_TYPE_MAP.Vendor));
    },
    (err) => {
      dispatch(fetchClaimError(err));
    }
  );
};

export const fetchClaimSuccess = (data) => {
  return {
    type: FETCH_CLAIM_SUCCESS,
    payload: data,
  };
};

export const fetchClaimError = (error) => {
  return {
    type: FETCH_CLAIM_ERROR,
    payload: error,
  };
};

export const clearClaim = () => {
  return {
    type: CLEAR_ACTIVE_CLAIM,
  };
};

export const fetchVendorClaimActivityLog = (claimId) => (
  dispatch,
  getState
) => {
  dispatch({ type: FETCH_VENDOR_CLAIMS_ACTIVITY_LOG });

  const claimsInstance = new FetchVendorClaimActivityLog({
    params: {
      claimId: claimId,
    },
    credentials: getState().authentication.credentials,
  });

  claimsInstance.call().then(
    (response) => {
      dispatch(fetchVendorClaimActivityLogSuccess(response));
    },
    (err) => {
      dispatch(fetchVendorClaimActivityLogError(err));
    }
  );
};

export const fetchVendorClaimActivityLogSuccess = (data) => {
  return {
    type: FETCH_VENDOR_CLAIMS_ACTIVITY_LOG_SUCCESS,
    payload: data,
  };
};

export const fetchVendorClaimActivityLogError = (error) => {
  return {
    type: FETCH_VENDOR_CLAIMS_ACTIVITY_LOG_FAILURE,
    payload: error,
  };
};

export const declineClaim = (claimData) => (dispatch, getState) => {
  dispatch({ type: DECLINE_CLAIM });

  const claimInstance = new DeclineClaim({
    params: claimData,
    credentials: getState().authentication.credentials,
  });

  claimInstance.call().then(
    (response) => {
      dispatch(declineClaimSuccess(response.data));
    },
    (err) => {
      dispatch(declineClaimFailure(err));
      dispatch(openModal("generic-error"));
    }
  );
};

export const declineClaimSuccess = (data) => {
  return {
    type: DECLINE_CLAIM_SUCCESS,
    payload: data,
  };
};

export const declineClaimFailure = (error) => {
  return {
    type: DECLINE_CLAIM_FAILURE,
    payload: error,
  };
};

export const informationRequest = (claimData) => (dispatch, getState) => {
  dispatch({ type: INFORMATION_REQUEST });

  const claimInstance = new InformationRequest({
    params: claimData,
    credentials: getState().authentication.credentials,
  });

  claimInstance.call().then(
    (response) => {
      dispatch(informationRequestSuccess(response.data));
    },
    (err) => {
      dispatch(informationRequestFailure(err));
      dispatch(openModal("generic-error"));
    }
  );
};

export const informationRequestSuccess = (data) => {
  return {
    type: INFORMATION_REQUEST_SUCCESS,
    payload: data,
  };
};

export const informationRequestFailure = (error) => {
  return {
    type: INFORMATION_REQUEST_FAILURE,
    payload: error,
  };
};

export const vendorAddNote = (claimData) => (dispatch, getState) => {
  dispatch({ type: VENDOR_ADD_NOTE });

  const claimInstance = new AddNote({
    params: claimData,
    credentials: getState().authentication.credentials,
  });

  claimInstance.call().then(
    (response) => {
      dispatch(closeModal());
      dispatch(fetchVendorClaimActivityLog(claimData.ClaimId));
      dispatch(vendorAddNoteSuccess(response.data));
    },
    (err) => {
      dispatch(vendorAddNoteFailure(err));
      dispatch(openModal("generic-error"));
    }
  );
};

export const vendorAddNoteSuccess = (data) => {
  return {
    type: VENDOR_ADD_NOTE_SUCCESS,
    payload: data,
  };
};

export const vendorAddNoteFailure = (error) => {
  return {
    type: VENDOR_ADD_NOTE_FAILURE,
    payload: error,
  };
};

export const reviewComplete = (claimData) => (dispatch, getState) => {
  dispatch({ type: REVIEW_COMPLETE });

  const claimInstance = new ReviewComplete({
    params: claimData,
    credentials: getState().authentication.credentials,
  });

  claimInstance.call().then(
    (response) => {
      dispatch(reviewCompleteSuccess(response.data));
    },
    (err) => {
      dispatch(reviewCompleteFailure(err));
      dispatch(openModal("generic-error"));
    }
  );
};

export const reviewCompleteSuccess = (data) => {
  return {
    type: REVIEW_COMPLETE_SUCCESS,
    payload: data,
  };
};

export const reviewCompleteFailure = (error) => {
  return {
    type: REVIEW_COMPLETE_FAILURE,
    payload: error,
  };
};

export const fetchPurchaseInformation = (claimId) => (dispatch, getState) => {
  dispatch({ type: FETCH_PURCHASE_INFORMATION });

  const claimsInstance = new FetchPurchaseInformation({
    params: {
      claimId: claimId,
    },
    credentials: getState().authentication.credentials,
  });

  claimsInstance.call().then(
    (response) => {
      dispatch(fetchPurchaseInformationSuccess(response));
    },
    (err) => {
      dispatch(fetchPurchaseInformationError(err));
    }
  );
};

export const fetchPurchaseInformationSuccess = (data) => {
  return {
    type: FETCH_PURCHASE_INFORMATION_SUCCESS,
    payload: data,
  };
};

export const fetchPurchaseInformationError = (error) => {
  return {
    type: FETCH_PURCHASE_INFORMATION_FAILURE,
    payload: error,
  };
};

export const makeOffer = (offerData) => (dispatch, getState) => {
  dispatch({ type: MAKE_OFFER });

  const claimInstance = new MakeOffer({
    params: offerData,
    credentials: getState().authentication.credentials,
  });

  claimInstance.call().then(
    (response) => {
      dispatch(makeOfferSuccess(response.data));
    },
    (err) => {
      dispatch(makeOfferFailure(err));
      dispatch(openModal("generic-error"));
    }
  );
};

export const makeOfferSuccess = (data) => {
  return {
    type: MAKE_OFFER_SUCCESS,
    payload: data,
  };
};

export const makeOfferFailure = (error) => {
  return {
    type: MAKE_OFFER_FAILURE,
    payload: error,
  };
};

export const cancelSale = (formData, refreshResults) => (
  dispatch,
  getState
) => {
  dispatch({ type: CANCEL_SALE });

  const claimInstance = new CancelSale({
    params: formData,
    credentials: getState().authentication.credentials,
  });

  claimInstance.call().then(
    (response) => {
      dispatch(cancelSaleSuccess(response.data));
      if (refreshResults) {
        dispatch(fetchVendorClaims());
      }
    },
    (err) => {
      dispatch(cancelSaleFailure(err));
      dispatch(openModal("generic-error"));
    }
  );
};

export const cancelSaleSuccess = (data) => {
  return {
    type: CANCEL_SALE_SUCCESS,
    payload: data,
  };
};

export const cancelSaleFailure = (error) => {
  return {
    type: CANCEL_SALE_FAILURE,
    payload: error,
  };
};

export const fetchBuyerClaimHistory = (claimId) => (dispatch, getState) => {
  dispatch({ type: FETCH_BUYER_CLAIM_HISTORY });

  const claimsInstance = new FetchBuyerClaimHistory({
    params: {
      claimId: claimId,
    },
    credentials: getState().authentication.credentials,
  });

  claimsInstance.call().then(
    (response) => {
      dispatch(fetchBuyerClaimHistorySuccess(response));
    },
    (err) => {
      dispatch(fetchBuyerClaimHistoryError(err));
    }
  );
};

export const fetchBuyerClaimHistorySuccess = (data) => {
  return {
    type: FETCH_BUYER_CLAIM_HISTORY_SUCCESS,
    payload: data,
  };
};

export const fetchBuyerClaimHistoryError = (error) => {
  return {
    type: FETCH_BUYER_CLAIM_HISTORY_FAILURE,
    payload: error,
  };
};

export const fetchBuyerClaimHistoryBreakdown = (reqObj, breakdownType) => (
  dispatch,
  getState
) => {
  dispatch({
    type: FETCH_BUYER_CLAIM_HISTORY_BREAKDOWN,
    payload: {
      statType: reqObj.statType,
      breakdownType: breakdownType,
    },
  });

  const claimsInstance = new FetchBuyerClaimHistoryBreakdown({
    params: reqObj,
    credentials: getState().authentication.credentials,
  });

  claimsInstance.call().then(
    (response) => {
      dispatch(
        fetchBuyerClaimHistoryBreakdownSuccess(
          response,
          reqObj.statType,
          breakdownType
        )
      );
    },
    (err) => {
      dispatch(fetchBuyerClaimHistoryBreakdownError(err, reqObj.statType));
    }
  );
};

export const fetchBuyerClaimHistoryBreakdownSuccess = (
  data,
  statType,
  breakdownType
) => {
  return {
    type: FETCH_BUYER_CLAIM_HISTORY_BREAKDOWN_SUCCESS,
    payload: { statType, data, breakdownType },
  };
};

export const fetchBuyerClaimHistoryBreakdownError = (error, statType) => {
  return {
    type: FETCH_BUYER_CLAIM_HISTORY_BREAKDOWN_FAILURE,
    payload: { error, statType },
  };
};

export const fetchSupportingDocumentsSuccess = (data) => {
  return {
    type: FETCH_SUPPORTING_DOCUMENTS_SUCCESS,
    payload: data,
  };
};

export const fetchSupportingDocumentsFailure = (error) => {
  return {
    type: FETCH_SUPPORTING_DOCUMENTS_FAILURE,
    payload: error,
  };
};

export const fetchSupportingDocuments = (claimId, userType) => (
  dispatch,
  getState
) => {
  dispatch({ type: FETCH_SUPPORTING_DOCUMENTS });

  const claimInstance = new FetchSupportingDocuments({
    params: {
      claimId,
      userType,
    },
    credentials: getState().authentication.credentials,
  });

  claimInstance.call().then(
    (response) => {
      dispatch(fetchSupportingDocumentsSuccess(response.data));
    },
    (err) => {
      dispatch(fetchSupportingDocumentsFailure(err));
    }
  );
};

const initialState = {
  isFetching: false,
  isUpdating: false,
  error: false,
  data: null,
  purchaseInformation: {
    isFetching: false,
    data: null,
    error: null,
  },
  claimActivityLog: {
    isFetching: false,
    data: null,
    error: null,
  },
  buyerClaimHistory: {
    isFetching: false,
    data: [],
    error: null,
  },
  supportingDocuments: {
    isFetching: false,
    data: null,
    error: null,
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLAIM:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_CLAIM_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };
    case FETCH_CLAIM_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case CLEAR_ACTIVE_CLAIM:
      return {
        ...state,
        data: null,
      };
    case DECLINE_CLAIM:
      return {
        ...state,
        isUpdating: true,
      };
    case DECLINE_CLAIM_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        data: action.payload.status,
      };
    case DECLINE_CLAIM_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.payload,
      };
    case FETCH_VENDOR_CLAIMS_ACTIVITY_LOG:
      return {
        ...state,
        claimActivityLog: {
          isFetching: true,
        },
      };
    case FETCH_VENDOR_CLAIMS_ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        claimActivityLog: {
          ...state.claimActivityLog,
          isFetching: false,
          data: action.payload,
        },
      };
    case FETCH_VENDOR_CLAIMS_ACTIVITY_LOG_FAILURE:
      return {
        ...state,
        claimActivityLog: {
          ...state.claimActivityLog,
          isFetching: false,
          error: action.payload,
        },
      };
    case REVIEW_COMPLETE:
      return {
        ...state,
        isUpdating: true,
      };
    case REVIEW_COMPLETE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        data: action.payload.status,
      };
    case REVIEW_COMPLETE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.payload,
      };
    case FETCH_PURCHASE_INFORMATION:
      return {
        ...state,
        purchaseInformation: {
          ...state.purchaseInformation,
          isFetching: true,
        },
      };
    case FETCH_PURCHASE_INFORMATION_SUCCESS:
      return {
        ...state,
        purchaseInformation: {
          ...state.purchaseInformation,
          isFetching: false,
          data: action.payload,
        },
      };
    case FETCH_PURCHASE_INFORMATION_FAILURE:
      return {
        ...state,
        purchaseInformation: {
          ...state.purchaseInformation,
          isFetching: false,
          error: true,
        },
      };
    case MAKE_OFFER:
      return {
        ...state,
        isUpdating: true,
      };
    case MAKE_OFFER_SUCCESS:
      return {
        ...state,
        isUpdating: false,
      };
    case MAKE_OFFER_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.payload,
      };
    case CANCEL_SALE:
      return {
        ...state,
        isUpdating: true,
      };
    case CANCEL_SALE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        data: action.payload.status,
      };
    case CANCEL_SALE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.payload,
      };
    case FETCH_BUYER_CLAIM_HISTORY:
      return {
        ...state,
        buyerClaimHistory: {
          isFetching: true,
        },
      };
    case FETCH_BUYER_CLAIM_HISTORY_SUCCESS:
      return {
        ...state,
        buyerClaimHistory: {
          ...state.buyerClaimHistory,
          isFetching: false,
          data: action.payload,
        },
      };
    case FETCH_BUYER_CLAIM_HISTORY_FAILURE:
      return {
        ...state,
        buyerClaimHistory: {
          ...state.buyerClaimHistory,
          isFetching: false,
          error: action.payload,
        },
      };
    case FETCH_SUPPORTING_DOCUMENTS:
      return {
        ...state,
        supportingDocuments: {
          isFetching: true,
        },
      };
    case FETCH_SUPPORTING_DOCUMENTS_SUCCESS:
      return {
        ...state,
        supportingDocuments: {
          ...state.supportingDocuments,
          isFetching: false,
          data: action.payload,
        },
      };
    case FETCH_SUPPORTING_DOCUMENTS_FAILURE:
      return {
        ...state,
        supportingDocuments: {
          ...state.supportingDocuments,
          isFetching: false,
          data: action.payload,
        },
      };

    case FETCH_BUYER_CLAIM_HISTORY_BREAKDOWN:
      return {
        ...state,
        buyerClaimHistory: {
          ...state.buyerClaimHistory,
          data: state.buyerClaimHistory?.data?.map((stat) =>
            stat.statType === action.payload.statType
              ? {
                  ...stat,
                  isFetchingCategoryStats:
                    action.payload.breakdownType === "categoryBreakdown",
                  isFetchingResolutionStats:
                    action.payload.breakdownType === "resolutionBreakdown",
                  isFetchingVehicleStats:
                    action.payload.breakdownType === "vehicleBreakdown",
                }
              : stat
          ),
        },
      };
    case FETCH_BUYER_CLAIM_HISTORY_BREAKDOWN_SUCCESS:
      return {
        ...state,
        buyerClaimHistory: {
          ...state.buyerClaimHistory,
          isFetchingBreakdown: false,
          data: state.buyerClaimHistory.data.map((stat) =>
            stat.statType === action.payload.statType
              ? {
                  ...stat,
                  isFetchingCategoryStats: false,
                  isFetchingResolutionStats: false,
                  isFetchingVehicleStats: false,
                  [action.payload.breakdownType]: action.payload.data,
                }
              : stat
          ),
        },
      };
    case FETCH_BUYER_CLAIM_HISTORY_BREAKDOWN_FAILURE:
      return {
        ...state,
        buyerClaimHistory: {
          ...state.buyerClaimHistory,
          data: state.buyerClaimHistory?.data?.map((stat) =>
            stat.statType === action.payload.statType
              ? {
                  ...stat,
                  isFetchingCategoryStats: false,
                  isFetchingResolutionStats: false,
                  isFetchingVehicleStats: false,
                }
              : stat
          ),
        },
      };

    default:
      return state;
  }
};

export default reducer;
