import React from "react";
import PropTypes from "prop-types";

const ChevronLeft = ({ width, height, strokeColour, strokeWidth }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 12 19"
  >
    <g
      stroke="none"
      strokeWidth={strokeWidth}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <g
        transform="translate(-1237.000000, -563.000000)"
        stroke={strokeColour}
        strokeWidth={strokeWidth}
      >
        <g transform="translate(1238.000000, 564.000000)">
          <g>
            <polyline
              transform="translate(5.000000, 8.500000) rotate(90.000000) translate(-5.000000, -8.500000) "
              points="-3 4 5.07880597 13 13 4"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

ChevronLeft.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

ChevronLeft.defaultProps = {
  width: "12",
  height: "19",
  strokeColour: "#A1A6B7",
  strokeWidth: "2"
};

export default ChevronLeft;
