import styled, { css } from "styled-components";

import Notification from "./Notification.jsx";
import breakpoints from "../../theme/breakpoints.js";

export const StyledNotification = styled(Notification)`
  position: relative;
  display: flex;
  padding: ${props => props.theme.SPACING.REGULAR};

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    border-radius: 0.5rem;
  }

  ${props =>
    props.size &&
    props.size === "small" &&
    css`
      padding: ${props.theme.SPACING.SMALL};
      justify-content: center;
    `}
  &.notification {
    background-color: ${props => props.theme.COLOURS.WHITE};
    border: 0.2rem solid ${props => props.theme.COLOURS.ACCENT.base};
    ${props =>
      props.type &&
      props.type === "success" &&
      css`
        border-color: ${props.theme.COLOURS.GREEN.base};
      `}
    ${props =>
      props.type &&
      props.type === "caution" &&
      css`
        border-color: ${props.theme.COLOURS.ORANGE.base};
      `}
    ${props =>
      props.type &&
      props.type === "error" &&
      css`
        border-color: ${props.theme.COLOURS.RED.base};
      `}
      ${props =>
        props.type &&
        props.type === "stopwatch" &&
        css`
          border-color: ${props.theme.COLOURS.ACCENT.base};
        `}
  }
  
  &.alert {
    align-items: center;
    background-color: ${props => props.theme.COLOURS.PRIMARY.base};
    padding: 2.5rem ${props => props.theme.SPACING.LARGE};
    ${props =>
      props.size &&
      props.size === "small" &&
      css`
        padding: ${props.theme.SPACING.SMALL};
        justify-content: flex-start;
      `}
    ${props =>
      props.type &&
      props.type === "success" &&
      css`
        background-color: ${props.theme.COLOURS.GREEN.base};
      `}
    ${props =>
      props.type &&
      props.type === "caution" &&
      css`
        background-color: ${props.theme.COLOURS.ORANGE.base};
      `}
    ${props =>
      props.type &&
      props.type === "error" &&
      css`
        background-color: ${props.theme.COLOURS.RED.base};
      `}
      ${props =>
        props.type &&
        props.type === "stopwatch" &&
        css`
          background-color: ${props.theme.COLOURS.ACCENT.base};
        `}
    .notification__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      ${props =>
        props.size &&
        props.size === "small" &&
        css`
          display: block;
          width: auto;
        `}
      h3,
      p {
        color: ${props => props.theme.COLOURS.WHITE};
      }
      h3 {
        width: 40%;
        margin-bottom: 0;
        text-align: left;
        ${props => props.theme.FONTS.Text["title"]}
        ${props =>
          props.size &&
          props.size === "small" &&
          css`
            width: 100%;
            ${props.theme.FONTS.Text["paragraphSemiBold"]}
          `}
      }
      p {
        font-size: ${props => props.theme.FONTS.sizes[30]};
        line-height: ${props => props.theme.FONTS.lineHeights[40]};
        text-align: left;

        @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
          font-size: ${props => props.theme.FONTS.sizes[70]};
          line-height: ${props => props.theme.FONTS.lineHeights[80]};
          width: 60%;
        }
      }
    }
  }
  
  .notification__close {
    position: absolute;
    right: -1.7rem;
    top: -1.7rem;
    width: 3.2rem;
    height: 3.2rem;
    background-color: ${props => props.theme.COLOURS.PRIMARY.base};
    border-radius: 50%;
    cursor: pointer;
    &--success {
      background-color: ${props => props.theme.COLOURS.GREEN.base};
    }
    &--caution {
      background-color: ${props => props.theme.COLOURS.ORANGE.base};
    }
    &--error {
      background-color: ${props => props.theme.COLOURS.RED.base};
    }
    &:before {
      background-color: ${props => props.theme.COLOURS.WHITE};
      content: "";
      position: absolute;
      margin-left: 1.5rem;
      margin-top: 0.8rem;
      width: 0.2rem;
      height: 1.5rem;
      transform: rotate(45deg);
    }
    &:after {
      background-color: ${props => props.theme.COLOURS.WHITE};
      content: "";
      position: absolute;
      margin-left: 1.5rem;
      margin-top: 0.8rem;
      width: 0.2rem;
      height: 1.5rem;
      transform: rotate(-45deg);
    }
  }

  .notification__content {
    margin-left: ${props => props.theme.SPACING.SMALL};
    h3 {
      margin-top: 0;
      margin-bottom: 0.9rem;
      color: ${props => props.theme.COLOURS.ACCENT.base};
      ${props =>
        props.size &&
        props.size === "medium" &&
        css`
          font-size: ${props.theme.FONTS.sizes[60]};
        `}
    }
    p {
      margin-top: 0;
      color: ${props => props.theme.COLOURS.PRIMARY.base};
    }
    ${props =>
      props.type &&
      props.type === "success" &&
      css`
        h3,
        p {
          border-color: ${props.theme.COLOURS.GREEN.base};
          color: ${props.theme.COLOURS.GREEN.base};
        }
      `}
    ${props =>
      props.type &&
      props.type === "caution" &&
      css`
        h3,
        p {
          border-color: ${props.theme.COLOURS.ORANGE.base};
          color: ${props.theme.COLOURS.ORANGE.base};
        }
      `}
    ${props =>
      props.type &&
      props.type === "error" &&
      css`
        h3,
        p {
          border-color: ${props.theme.COLOURS.RED.base};
          color: ${props.theme.COLOURS.RED.base};
        }
      `}
  }
  .notification__actions {
    margin-top: ${props => props.theme.SPACING.REGULAR};
    & > *:not(:last-child)  {
      margin-right: ${props => props.theme.SPACING.SMALL};
    }
  }

  .notification__multi-paragraph {
    p {
      margin-bottom: ${props => props.theme.SPACING.SMALL};

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export default StyledNotification;
