import PricingApi from "../../api/Pricing";

// Action constants
export const FETCH_PRICING_DETAILS = "@pricing/FETCH_PRICING_DETAILS";
export const FETCH_PRICING_DETAILS_SUCCESS =
  "@pricing/FETCH_PRICING_DETAILS_SUCCESS";
export const FETCH_PRICING_DETAILS_FAILURE =
  "@pricing/FETCH_PRICING_DETAILS_FAILURE";
export const CLEAR_PRICING_DETAILS = "@pricing/CLEAR_PRICING_DETAILS";

// Action creators

export const clearPricingDetails = () => {
  return {
    type: CLEAR_PRICING_DETAILS
  };
};
export const fetchPricingDetailsSuccess = response => {
  return {
    type: FETCH_PRICING_DETAILS_SUCCESS,
    payload: response
  };
};

export const fetchPricingDetailsFailure = error => {
  return {
    type: FETCH_PRICING_DETAILS_FAILURE,
    payload: error
  };
};

export const fetchPricingDetails = (
  vehicleId,
  deliveryMethod,
  selectedGroupSiteId
) => (dispatch, getState) => {
  dispatch({ type: FETCH_PRICING_DETAILS });

  const pricingInstance = new PricingApi({
    params: { vehicleId, deliveryMethod, selectedGroupSiteId },
    credentials: getState().authentication.credentials
  });

  pricingInstance.call().then(
    response => dispatch(fetchPricingDetailsSuccess(response)),
    err => {
      return dispatch(fetchPricingDetailsFailure(err));
    }
  );
};

const initialState = {
  isFetching: false,
  error: false,
  pricingDetails: null
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRICING_DETAILS:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_PRICING_DETAILS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case FETCH_PRICING_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        pricingDetails: action.payload
      };
    case CLEAR_PRICING_DETAILS:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default reducer;
