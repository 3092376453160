import styled from "styled-components";

import AcceptOfferConfirmation from "./AcceptOfferConfirmation";

export const StyledAcceptOfferConfirmation = styled(AcceptOfferConfirmation)`
  .confirmation-title {
    margin-bottom: 1.5rem;
  }

  .confirmation-text {
    margin-bottom: 1rem;
  }
`;

export default StyledAcceptOfferConfirmation;
