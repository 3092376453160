import React from "react";
import PropTypes from "prop-types";

import Row from "../../Row";
import Header from "../../Header";
import Navigation from "../../Navigation";
import Breadcrumb from "../../Breadcrumb";

const SkeletonVehicleDetailsPage = props => {
  return (
    <div className={props.className}>
      <Header>
        <Navigation />
        <Row tight>
          <div className="skeleton__breadcrumb-row">
            <Breadcrumb />
          </div>
        </Row>
      </Header>
      <div className="skeleton__page-content" />
    </div>
  );
};

SkeletonVehicleDetailsPage.propTypes = {
  className: PropTypes.string
};

export default SkeletonVehicleDetailsPage;
