import styled from "styled-components";
import { StyledHeader } from "../../Header";

import SkeletonShowroomPage from "./SkeletonShowroomPage.jsx";

const StyledSkeletonShowroomPage = styled(SkeletonShowroomPage)`
  padding-bottom: ${props => props.theme.SPACING.XLARGE};
  ${StyledHeader} {
    background-color: ${props => props.theme.COLOURS.PRIMARY.shades[10]};
  }
  .skeleton {
    &__breadcrumb-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__page-content {
      height: 100vh;
      background-color: ${props => props.theme.COLOURS.WHITE};
    }
  }
`;

export default StyledSkeletonShowroomPage;
