const PRIMARY = {
  base: "#213E8F",
  shades: {
    5: "#F4F5F7",
    10: "#E8EBF3",
    20: "#D3D8E9",
    30: "#BCC5DD",
    40: "#A6B2D2",
    50: "#909EC7",
    60: "#7A8BBC",
    70: "#6377B0",
    80: "#4D65A5",
    90: "#37519A",
  },
};

const ACCENT = {
  base: "#03A4AC",
  shades: {
    10: "#E5F5F6",
    20: "#CDEDEE",
    30: "#B3E3E6",
    40: "#9ADBDE",
    50: "#81D1D5",
    60: "#68C8CD",
    70: "#4EBFC4",
    80: "#35B6BD",
    90: "#1CADB4",
  },
};

const SECONDARY = {
  base: "#172b64",
  shades: {
    10: "#e8eaf0",
    20: "#d1d5e0",
    30: "#b9bfd1",
    40: "#a2aac1",
    50: "#8b95b2",
    60: "#7480a2",
    70: "#5d6b93",
    80: "#455583",
    90: "#2e4074",
  },
};

const SECONDARY_ACCENT = {
  base: "#027076",
  shades: {
    10: "#E5F0F1",
    20: "#CCE2E4",
    30: "#B3D4D5",
    40: "#9AC6C8",
    50: "#80B7BA",
    60: "#67A9AD",
    70: "#4D9A9F",
    80: "#358D91",
    90: "#1B7E83",
  },
};

const GREEN = {
  base: "#429D16",
  shades: {
    10: "#ECF5E7",
    20: "#D9EBD0",
    30: "#C6E1B9",
    40: "#B2D8A2",
    50: "#A0CE8A",
    60: "#8EC473",
    70: "#7ABA5B",
    80: "#68B145",
    90: "#54A62D",
  },
};

const RED = {
  base: "#F22B2B",
  shades: {
    10: "#FDE9E9",
    20: "#FCD5D5",
    30: "#FBBFBF",
    40: "#FAAAAA",
    50: "#F89595",
    60: "#F78080",
    70: "#F56A6A",
    80: "#F55555",
    90: "#F34040",
  },
};

const ORANGE = {
  base: "#FE7214",
  shades: {
    10: "#FEF0E7",
    20: "#FFE3D0",
    30: "#FED4B8",
    40: "#FFC7A1",
    50: "#FEB889",
    60: "#FEAA72",
    70: "#FE9C5A",
    80: "#FE8E43",
    90: "#FE802B",
  },
};

const TRANSPARENCIES = {
  backgroundImage: "rgba(0,0,0,0)",
  primary: "rgba(33,62,143,0.3)",
  primaryDark: "rgba(33,62,143,0.9)",
  green: "rgba(66,157,22,0.05)",
  white: "rgba(27, 34, 59, 0.1)",
};

const COLOURS = {
  PRIMARY,
  SECONDARY,
  ACCENT,
  SECONDARY_ACCENT,
  RED,
  GREEN,
  ORANGE,
  BLACK: "#000000",
  BLUE: "#2183CD",
  BROWN: "#964B00",
  WHITE: "#FFFFFF",
  YELLOW: "#feb40c",
  SILVER: "#C0C0C0",
  GREY: "#808080",
  CREAM: "#FFFDD0",
  GOLD: "#FFD700",
  PINK: "#FFC0CB",
  PURPLE: "#A020F0",
  BEIGE: "#F5F5DC",
  BRONZE: "#CD7F32",
  MAROON: "#800000",
  TURQUOISE: "#40e0d0",
  ACTION_GREEN:"#4D9152",
  ALERT_RED:"#C33939",
  TRANSPARENCIES,
};

export default COLOURS;
