import Api from "./Base";
import settings from "../clientappsettings.json";

class Claim extends Api {
  baseUrl = settings.BUYERS_URL;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class FetchClaim extends Claim {
  requestUrl = `/Claims/${this.getParams().claimId}`;
}

export class CreateClaim extends Claim {
  requestUrl = `/Claims/CreateClaim/${this.getParams().vehiclePurchaseId}`;
}

export class UpdateClaim extends Claim {
  requestUrl = `/Claims/Update`;
  method = "POST";
}

export class SubmitClaim extends Claim {
  requestUrl = `/Claims/Submit`;
  method = "POST";
}

export class DeleteClaim extends Claim {
  requestUrl = `/Claims/Delete/${this.getParams().claimId}`;
}

export class AcceptOffer extends Claim {
  requestUrl = `/Claims/AcceptOffer`;
  method = "POST";
}

export class FetchSubCategories extends Claim {
  requestUrl = `/Claims/SubCategories/${this.getParams().claimCategoryId}`;
}

export class FetchClaims extends Claim {
  requestUrl = `/Claims`;
  method = "POST";

  formatResponseData(response) {
    if (response.data.claims) {
      return response.data.claims;
    }
    return response;
  }
}

export class RegSearch extends Claim {
  requestUrl = `/Claims/RegSearch/${this.getParams().regNo}`;
}

export class RequestCallBack extends Claim {
  requestUrl = `/Claims/CallBacks/Create`;
  method = "POST";
}

export class CloseClaim extends Claim {
  requestUrl = `/Claims/Close/${this.getParams().claimId}`;
}

export class FetchCategories extends Claim {
  requestUrl = `/Claims/Categories/${this.getParams().claimId}`;

  formatResponseData(response) {
    if (response.data.categories) {
      return response.data.categories;
    }
    return response;
  }
}

export class FetchClaimsReport extends Claim {
  requestUrl = `/Claims/download`;

  formatResponseData(response) {
    if (response.data) {
      const blob = new Blob([response.data], { type: "application/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "Claims.csv";
      link.click();
    }

    return response;
  }
}

export default Claim;
