import React, { useContext } from "react";
import classnames from "classnames";
import HeaderText from "../HeaderText";
import ParagraphText from "../ParagraphText";
import Icon from "../Icon";
import { ThemeContext } from "styled-components";

const GenericTransitionModal = ({
  headerText,
  bodyText,
  additionalText,
  qaClassName,
  className,
}) => {
  const theme = useContext(ThemeContext);
  return (
    <div className={classnames(className, qaClassName)}>
      <div className="text-area">
        <Icon
          type="check-circle"
          strokeColour={theme.COLOURS.GREEN.shades[90]}
          height="60px"
          width="60px"
        />
        <HeaderText>{headerText}</HeaderText>
        <ParagraphText>{bodyText}</ParagraphText>
        {additionalText && additionalText}
      </div>
    </div>
  );
};

export default GenericTransitionModal;
