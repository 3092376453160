import React from "react";
import classnames from "classnames";
import HeaderText from "../HeaderText";
import ParagraphText from "../ParagraphText";
import Icon from "../Icon";

const Loader = ({ title, content, qaClassName, className }) => {
  return (
    <div tabIndex="0" className={classnames(className, qaClassName)}>
      <div className="rotation">
        <Icon type="loading" width="62" height="54" />
      </div>
      <HeaderText>{title}</HeaderText>
      <ParagraphText>{content}</ParagraphText>
    </div>
  );
};

export default Loader;
