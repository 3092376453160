import styled, { css } from "styled-components";
import PricingBlock from "./PricingBlock";
import { StyledPriceText } from "./../PriceText";
import breakpoints from "../../theme/breakpoints";

export const StyledPricingBlock = styled(PricingBlock)`
  p {
    margin: 0;
  }

  ${StyledPriceText} {
    margin: 0 ${props => props.theme.SPACING.SMALL} 0 0;
  }

  .pricing-block__header {
    white-space: nowrap;
  }

  .pricing-block__content__top {
    display: flex;
    align-items: baseline;
  }
  .pricing-block__content__bottom {
    margin: -0.5rem 0 0 0;
  }
  .pricing-block__content__bottom__additional-text {
    color: ${props => props.theme.COLOURS.PRIMARY.base};
  }

  .pricing-block__content__bottom {
    font-size: ${props => props.theme.FONTS.sizes[10]};
    line-height: ${props => props.theme.FONTS.lineHeights[30]};

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      /* 2.6rem is the inherited default, unavailable from theme */
      font-size: ${props => props.theme.FONTS.sizes[30]};
      line-height: 2.6rem;
    }
  }

  .pricing-block__content__side-text {
    font-size: ${props => props.theme.FONTS.sizes[10]};
    line-height: ${props => props.theme.FONTS.lineHeights[30]};

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      /* 2.6rem is the inherited default, unavailable from theme */
      font-size: ${props => props.theme.FONTS.sizes[40]};
      line-height: 2.6rem;
    }
  }

  ${props =>
    props.large &&
    css`
      .pricing-block__content__bottom {
        margin-top: 0;
      }
      .pricing-block__content__top {
        @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
          margin-top: 0.5rem;
        }
      }
    `}

  ${props =>
    props.inline &&
    props.headerText &&
    css`
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      > div {
        flex-basis: 50%;
      }
    `}
`;

export default StyledPricingBlock;
