import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import {
  closeModal,
  logOut,
  logOutSuccess,
} from "../../../state/reducers/global";
import { clearAuthentication } from "../../../state/reducers/authentication";
import ModalInsert from "../../components/ModalInsert";
import Logout from "./../../components/Logout";
import { useMediaQuery } from "react-responsive";

const LogoutModalInsert = () => {
  const dispatch = useDispatch();
  const logoutUrl = useSelector(
    (state) =>
      state.authentication.meta.userDefaults &&
      state.authentication.meta.userDefaults.logoutUrl,
  );
  const navigate = useNavigate();

  const onCancel = () => {
    dispatch(closeModal());
  };

  const urlParams = new URLSearchParams(useLocation().search);

  const onLogout = () => {
    urlParams.delete("JWT");
    dispatch(closeModal());
    dispatch(logOut());

    if (logoutUrl) {
      window.location.href = logoutUrl;
    } else {
      navigate("/login");
      dispatch(logOutSuccess());
    }
    dispatch(clearAuthentication());
  };

  const isSmallScreen = useMediaQuery({
    query: `(max-width: 570px)`,
  });

  return (
    <ModalInsert layout="flex" width={isSmallScreen ? "auto" : "55rem"}>
      <Logout onCancel={onCancel} onLogout={onLogout} />
    </ModalInsert>
  );
};

export default LogoutModalInsert;
