import React, { useContext } from "react";
import {
  HeaderContainer,
  HeaderPrimaryRow,
  HeaderSecondaryRow,
  HeaderItem,
} from "./../index";
import ParagraphText from "../../ParagraphText";
import HeaderText from "../../HeaderText";
import SubHeaderText from "../../SubHeaderText";
import { ThemeContext } from "styled-components";
import { headerText, activityButtonText } from "./../consts";
import { getClaimStatusText } from "../../../../shared/utility";
import Button from "../../Button";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../theme/breakpoints";

export const Header = ({ claimId, status, onClickOpenActivityLog }) => {
  const viewButton =
    status === "Submitted" ||
    status === "Declined" ||
    status === "VendorOfferMade" ||
    status === "InformationRequested" ||
    status === "ReviewInProgress" ||
    status === "Closed" ||
    status === "Expired" ||
    status === "Completed" ||
    status === "VendorOfferAccepted";

  const theme = useContext(ThemeContext);
  
  const isMobileOrTablet = useMediaQuery({
    query: `(max-width: ${breakpoints.LARGE_DESKTOP})`,
  });
  return (
    <HeaderContainer
      borderBottomColor={theme.COLOURS.PRIMARY.shades[5]}
      paddingBottom={theme.SPACING.REGULAR}
    >
      <HeaderPrimaryRow>
        <HeaderText>{headerText.CLAIM_DETAILS}</HeaderText>
      </HeaderPrimaryRow>
      <HeaderSecondaryRow>
        <div className="activity-log_reference-container">
          <HeaderItem>
            <SubHeaderText>{headerText.CASE_REFERENCE}:</SubHeaderText>
            <ParagraphText large>{claimId}</ParagraphText>
          </HeaderItem>
          <HeaderItem>
            <SubHeaderText>{headerText.STATUS}:</SubHeaderText>
            <ParagraphText large> {getClaimStatusText(status)}</ParagraphText>
          </HeaderItem>
        </div>
          <HeaderItem>
        {!isMobileOrTablet && (
            viewButton && (
              <Button
                content={activityButtonText.PLACEHOLDER}
                secondary
                action={() => onClickOpenActivityLog()}
              />
            ))}
          </HeaderItem>
      </HeaderSecondaryRow>
    </HeaderContainer>
  );
};

export default Header;