import styled from "styled-components";
import PromotionModalComponent from "./PromotionModalComponent";
import breakpoints from "../../theme/breakpoints";
import ButtonLink from "../../components/ButtonLink";
import BidInputError from "../../components/BidInput/child-components/BidInputError";
import colours from "../../theme/base/colours";
import HeaderText from "../HeaderText";
import ParagraphText from "../ParagraphText";

const StyledPromotionModalComponent = styled(PromotionModalComponent)`
  display: block;

  .promotion-details-header {
    background-color: ${(props) => props.theme.COLOURS.PRIMARY.base};
    ${HeaderText} {
      color: ${(props) => props.theme.COLOURS.WHITE};
    }
    ${ParagraphText} {
      color: ${(props) => props.theme.COLOURS.WHITE};
    }
    width: 100%;
    padding: 2%;
    @media (max-width: ${breakpoints.LARGE_DESKTOP}) {
      height: 8%;
    }
  }

  .promotion-description {
    width: 60%;
    word-wrap: break-word;

    ${ParagraphText} {
      font: inherit;
    }
  }

  .promotion-content {
    display: flex;
    gap: 3rem;
    height: 40rem;
    padding: 2%;
    @media (max-width: ${breakpoints.LARGE_DESKTOP}) {
      height: 80%;
    }
  }

  .promotion-additional-details {
    width: 40%;
  }

  .promotion-details-drag-drop {
    border: 2px solid ${(props) => props.theme.COLOURS.SECONDARY.shades[30]};
    border-radius: 10px;
    padding: 1rem;
    overflow: auto;
    max-height: 275px;
  }

  .promotion-details-drag-drop-header {
    display: flex;
    gap: 1rem;
    ${ParagraphText} {
      color: ${(props) => props.theme.COLOURS.PRIMARY.base};
    }
  }

  .promotion-expiry-date {
    display: flex;
    gap: 1rem;
    padding-bottom: 1rem;
    ${ParagraphText} {
      color: ${(props) => props.theme.COLOURS.PRIMARY.base};
    }
  }

  .promotion-vendor-logo {
    height: 5rem;
    width: 10.7rem;
    padding-bottom: 1rem;
    > div {
      display: inline-block;
      width: 100%;
      height: 100%;
      background-position: left center;
      background-size: contain !important;
      background-repeat: no-repeat;
    }
  }

  .actions-area {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
    @media (max-width: ${breakpoints.TABLET}) {
      flex-direction: column;
      padding-right: 1rem;
      padding-top: 1rem;
      height: 12%;
      padding-bottom: 2%;
      bottom: 0;
      left: 0;
    }
  }
`;

export const DragDropItemContent = styled.div`
  div {
    margin: 0.5rem 0;
    display: flex;
    height: min-content;

    ${ButtonLink} {
      color: ${(props) => props.theme.COLOURS.SECONDARY.shades[80]};
      font-size: 1.4rem;
    }
    > div {
      margin-right: 1rem;
    }
  }

  .failedItems {
    margin: 0;
    pointer-events: none;
    ${ButtonLink} {
      text-align: left;
      word-break: break-all;
      color: ${colours.RED.base};
    }
    .failedFile {
      width: 45rem;
      margin-right: 1rem;
    }
    ${BidInputError} {
      line-height: 2rem;
      height: 5rem;
      margin-right: 0rem;
    }
  }
`;

export default StyledPromotionModalComponent;
