import ActivityLogs from "../../api/ActivityLogs";
import { updateObject } from "../../shared/utility";

// Action Constants
export const FETCH_ACTIVITY_LOGS = "@activityLogs/FETCH_ACTIVITY_LOGS";
export const FETCH_ACTIVITY_LOGS_SUCCESS =
  "@activityLogs/FETCH_ACTIVITY_LOGS_SUCCESS";
export const FETCH_ACTIVITY_LOGS_FAILURE =
  "@activityLogs/FETCH_ACTIVITY_LOGS_FAILURE";
export const CLEAR_ACTIVITY_LOGS = "@activityLogs/CLEAR_ACTIVITY_LOGS";

// Action Creators
export const fetchActivityLogsSuccess = (data) => {
  return {
    type: FETCH_ACTIVITY_LOGS_SUCCESS,
    payload: data,
  };
};

export const fetchActivityLogsFailure = (error) => {
  return {
    type: FETCH_ACTIVITY_LOGS_FAILURE,
    payload: error,
  };
};

export const fetchActivityLogs = () => (dispatch, getState) => {
  dispatch({ type: FETCH_ACTIVITY_LOGS });
  const toolbar = getState().toolbar;

  const { pageSize, sort } = toolbar;
  const pageNumber = getState().pagination.pageNumber;
  const filters = getState().filter.filters;
  // TODO: The endpoint doesn't work if we send an empty sort object
  let params = { pageSize, pageNumber, filters };
  if (Object.keys(sort).length) {
    params = {
      ...params,
      sort,
    };
  }

  const instance = new ActivityLogs({
    params,
    credentials: getState().authentication.credentials,
  });
  instance.call().then(
    (response) => {
      dispatch(fetchActivityLogsSuccess(response));
    },
    (err) => {
      return dispatch(fetchActivityLogsFailure(err));
    }
  );
};

export const clearActivityLogs = () => {
  return {
    type: CLEAR_ACTIVITY_LOGS,
  };
};

export const initialState = {
  isFetching: false,
  error: false,
  results: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTIVITY_LOGS:
      return updateObject(state, { isFetching: true });
    case FETCH_ACTIVITY_LOGS_FAILURE:
      return updateObject(state, { isFetching: false, error: action.payload });
    case FETCH_ACTIVITY_LOGS_SUCCESS:
      return updateObject(state, {
        isFetching: false,
        totalCount: action.payload.totalCount,
        pageSize: action.payload.pageSize,
        pageCount: action.payload.pageCount,
        results: action.payload.activities,
      });
    case CLEAR_ACTIVITY_LOGS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
