import styled from "styled-components";
import breakpoints from "../../theme/breakpoints";
import PromotionModal from "./PromotionModal";

const StyledPromotionModal = styled(PromotionModal)`
  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
  }

  box-sizing: border-box;
  background-color: ${(props) => props.theme.COLOURS.WHITE};
  width: 100%;

  .promotion__details {
    width: 100%;
    height: 60rem;
    padding-bottom: 2%;
    @media (max-width: ${breakpoints.LARGE_DESKTOP}) {
      height: 100%;
      padding-bottom: 0;
    }
  }
`;

export default StyledPromotionModal;
