import React from "react";
import Button from "./../../Button";
import { ButtonGroup } from "./../index";

function ButtonGroupComponent({
  onCancelButtonClick,
  onClaimUpdateOrSubmit,
  isSaveEnabled,
  isSubmitEnabled,
  disabled,
}) {
  return (
    <ButtonGroup>
      <Button primaryAlt action={onCancelButtonClick} content="Cancel" />
      <Button
        primary
        content="Save & Update"
        disabled={!isSaveEnabled}
        action={() => onClaimUpdateOrSubmit("update")}
      />
      {!disabled && (
        <Button
          primary
          content="Submit"
          disabled={!isSubmitEnabled}
          action={() => onClaimUpdateOrSubmit("submit")}
        />
      )}
    </ButtonGroup>
  );
}

export default ButtonGroupComponent;
