import React, { Fragment, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  openModal,
  toggleTermsAndConditions,
} from "../../../state/reducers/global";
import { setBidPrice } from "../../../state/reducers/bid";
import ModalInsert from "../../components/ModalInsert";
import Panel from "../../components/Panel";
import BidPricingBlockDefault from "../BidPricingBlockDefault";
import BidPricingBlockOutbid from "../BidPricingBlockOutbid";
import BidPricingBlockWinning from "../BidPricingBlockWinning";
import BidPricingBlockCurrent from "../BidPricingBlockCurrent";
import { getBidIncrement } from "../../../shared/utility";
import SkeletonBidModal from "../../components/Skeletons/SkeletonBidModal";
import GroupBuyerSelect from "../GroupBuyerSelect";
import BidForm from "../../components/BidForm";
import { ThemeContext } from "styled-components";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../theme/breakpoints";
import { pushAddToCartEventToGAMotability } from "../../../shared/utilityGA";

const BidModal = () => {
  // Do not allow an illegal bid to be placed
  const [isLegalBid, setLegalBid] = useState(true);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.credentials);
  const vendorId = useSelector(
    (state) => state.authentication.meta.userDefaults.vendorId
  );
  const activeBidVehicleId = useSelector(
    (state) => state.global.bids.activeBidVehicleId
  );
  const bidDetails = useSelector(
    (state) => state.bid.bids.byVehicleId[activeBidVehicleId]
  );
  const hasReadTermsAndConditions = useSelector(
    (state) => state.global.purchases.hasReadTermsAndConditions
  );
  const maximumBidPrice = useSelector((state) => state.bid.value);

  let data = null; // gathering the data either from search or vehicle details
  const vehicleCardData = useSelector(
    (state) => state.search.results.byId[activeBidVehicleId]
  );
  const vehicleDetailsData = useSelector((state) => state.vehicle.data);

  if (vehicleCardData) {
    data = vehicleCardData;
  } else {
    data = vehicleDetailsData;
  }

  const onPlaceBid = () => {
    dispatch(openModal("confirm-bid"));

    pushAddToCartEventToGAMotability(
      theme.name,
      data,
      token,
      vendorId,
      maximumBidPrice,
      !!vehicleCardData
    );
  };

  const onCancelBid = () => {
    if (hasReadTermsAndConditions) {
      dispatch(toggleTermsAndConditions());
    }
    dispatch(closeModal());
  };
  const handleBidInputChange = (value, bidStatus) => {
    setLegalBid(bidStatus);
    dispatch(setBidPrice(value));
  };

  /**
   * TODO: This is the logic for the initial value presented in the user input field.
   * On this (first) pass we will keep it quite imperative, we may want to at least package
   * some of these rules into functions
   */

  let currentBidAmount = 0;
  let previousMaxBidAmount = 0;
  let bidCount = 0;
  let isDeclined = false;

  // We need to guard against nulls here
  if (bidDetails) {
    if (bidDetails.bidCount) {
      bidCount = bidDetails.bidCount;
    }
    if (bidDetails.currentBidAmount) {
      currentBidAmount = bidDetails.currentBidAmount;
    }
    if (bidDetails.previousMaxBidAmount) {
      previousMaxBidAmount = bidDetails.previousMaxBidAmount;
    }
    if (bidDetails.bidStatus === "Declined") {
      isDeclined = true;
    }
  }
  const openingBid = !bidCount;

  /**
   *
   * @param {number} currentBidAmount
   * @param {number} previousMaxBidAmount
   * @param {boolean} isOpeningBid
   * @param {boolean} isDeclined
   */
  const getInitialBidValue = (
    currentBidAmount,
    previousMaxBidAmount,
    isOpeningBid,
    isDeclined
  ) => {
    const increment = getBidIncrement();
    if (isOpeningBid && currentBidAmount !== 0) {
      return currentBidAmount;
    }
    if (previousMaxBidAmount && !isDeclined) {
      return previousMaxBidAmount < currentBidAmount
        ? currentBidAmount + increment
        : previousMaxBidAmount + increment;
    }
    return currentBidAmount + increment;
  };

  /**
   * Bidding logic ends
   */
  const theme = useContext(ThemeContext);
  const notification =
    bidDetails && bidDetails.vatQualified === "Commercial"
      ? {
          size: "medium",
          overrideIcon: {
            type: "alert-circle",
            strokeColour: theme.COLOURS.ACCENT.base,
          },
          title: "Please note",
          text:
            "This is a commercial vehicle and as such VAT will be added on to the winning bid.",
        }
      : null;

  const bidData = {
    getInitialBidValue: getInitialBidValue,
    currentBidAmount: currentBidAmount,
    previousMaxBidAmount: previousMaxBidAmount,
    openingBid: openingBid,
    isDeclined: isDeclined,
    handleBidInputChange: handleBidInputChange,
  };

  const termsData = {
    isChecked: hasReadTermsAndConditions,
    onInputChange: () => dispatch(toggleTermsAndConditions()),
    activeBidVehicleId: activeBidVehicleId,
  };

  const isInitialBid =
    !previousMaxBidAmount || (previousMaxBidAmount && isDeclined);
  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  return (
    <Fragment>
      {!bidDetails && <SkeletonBidModal />}
      {bidDetails && (
        <ModalInsert layout="flex" width={isDesktop ? "96rem" : "100%"}>
          <Panel
            flex="0"
            flexDesktop="1.5"
            type="grey"
            notification={notification}
            mobileFlex
          >
            <BidPricingBlockDefault />
            <BidPricingBlockCurrent />
            <BidPricingBlockOutbid />
            <BidPricingBlockWinning />
          </Panel>
          <Panel
            flex="1"
            flexDesktop="2"
            actions={[
              {
                content: "Close",
                action: onCancelBid,
                type: "ghost",
              },
              {
                content: "Place bid",
                action: onPlaceBid,
                type: "primary",
                disabled: !hasReadTermsAndConditions || !isLegalBid,
              },
            ]}
          >
            <BidForm
              bidData={bidData}
              termsData={termsData}
              GroupBuyerSelect={
                <GroupBuyerSelect
                  vehicleId={activeBidVehicleId}
                  isInitialBid={isInitialBid}
                />
              }
            />
          </Panel>
        </ModalInsert>
      )}
    </Fragment>
  );
};

export default BidModal;
