import styled, { css } from "styled-components";
import Modal from "./Modal";
import breakpoints from "../../theme/breakpoints";

const StyledModal = styled(Modal)`
  position: fixed;
  top: 0;
  left: 0;
  transform: translateZ(0);
  width: 100%;
  height: 100%;
  z-index: 900; /* TODO: add to styling constants */
  background: ${props => props.theme.COLOURS.TRANSPARENCIES.primaryDark};

  .modal__body {
    border-radius: 0;
    top: 0;
    left: 0;
    min-height: 0;
    min-width: 0;
    height: 100%;
    width: 100vw;
    transform: none;

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      border-radius: ${props => !props.fullScreen && "0.5rem"};
      border-radius: 1rem;
      height: auto;
      min-height: 28rem;
      min-width: 55rem;
      left: 50%;
      position: fixed;
      top: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      z-index: 1000;

      ${props =>
        props.bgColor &&
        css`
          background-color: ${props.bgColor};
        `}
    }
  }

  .modal__content {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    overflow: auto;

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      border-radius: ${props => !props.fullScreen && "0.5rem"};
      height: auto;
    }

    ${props =>
      props.contentOverflow &&
      css`
        @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
          overflow: ${props.contentOverflow};
        }
      `}

    > * {
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);

      body.android & {
        min-height: 60rem;
      }

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        height: auto;
      }
    }
  }

  .modal__close {
    position: absolute;
    right: -1.8rem;
    top: -5rem;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    cursor: pointer;
    &:before {
      background-color: ${props => props.theme.COLOURS.WHITE};
      content: "";
      position: absolute;
      width: 0.3rem;
      height: 3.5rem;
      transform: rotate(45deg);
    }
    &:after {
      background-color: ${props => props.theme.COLOURS.WHITE};
      content: "";
      position: absolute;
      width: 0.3rem;
      height: 3.5rem;
      transform: rotate(-45deg);
    }
    &:hover {
      &:before,
      &:after {
        background-color: ${props => props.theme.COLOURS.ACCENT.base};
      }
    }
  }
`;

export default StyledModal;
