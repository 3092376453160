import React from "react";
import PropTypes from "prop-types";

const SkeletonRows = props => {
  return (
    <div className={props.className}>
      {[...Array(props.nrOfRows)].map((skeleton, key) => (
        <span key={key}> </span>
      ))}
      <div className="action-btn"></div>
    </div>
  );
};

SkeletonRows.propTypes = {
  className: PropTypes.string,
  nrOfRows: PropTypes.number
};

export default SkeletonRows;
