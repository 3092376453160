import React, { useState, useContext, useEffect } from "react";
import { subHeaderText, paragraphText, resolutionDropdown } from "../../consts";
import SubHeaderText from "../../../SubHeaderText";
import ParagraphText from "../../../ParagraphText";
import Dropdown from "../../../Dropdown";
import {
  StyledResolutionCostBreakdown,
  StyledResolutionTotalCost,
} from "./index";
import {
  ItemContainer,
  ItemLabel,
  ItemContent,
  ParagraphTextContainer,
} from "../../index";
import { getResolutionsOptions } from "./../../utils";
import { ClaimResolutionContext } from "./../../context/context";
import {resolutionCostType} from '../../consts'

const Resolution = ({
  options: claimOptions,
  claimResolutionId,
  claimCostType,
  claimTotalCost,
  className,
  disabled,
} = {}) => {
  const [resolutionId, setResolutionId] = useState(null);
  const {
    setResolutionIdCtx,
    setClaimCostTypeCtx,
    setResolutionCostLinesCtx,
    setTotalCostCtx,
  } = useContext(ClaimResolutionContext);

  const resolutions = claimOptions?.resolutions;
  const costLines = claimOptions?.costLines;

  const options = getResolutionsOptions(resolutions);

  let selectedOption, costType, content;

  if (resolutionId) {
    selectedOption = options?.filter((option) => option.value === resolutionId);
  }

  if (selectedOption) {
    costType = selectedOption[0]?.costType;
    content =
      costType === resolutionCostType.COST_BREAKDOWN ? (
        <StyledResolutionCostBreakdown
          costLines={costLines}
          claimTotalCost={claimTotalCost}
          disabled={disabled}
        />
      ) : costType === resolutionCostType.TOTAL_COST ? (
        <StyledResolutionTotalCost
          costLines={costLines}
          claimTotalCost={claimTotalCost}
          disabled={disabled}
        />
      ) : null;
  }

  const onInputChange = (e) => {
    setResolutionId(e.value);
    setResolutionCostLinesCtx([]);
    setTotalCostCtx(null);
  };

  useEffect(() => {
    setResolutionId(claimResolutionId);
  }, [claimResolutionId]);

  useEffect(() => {
    setResolutionIdCtx(resolutionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolutionId]);

  useEffect(() => {
    setClaimCostTypeCtx(costType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costType]);

  return (
    <div className={className}>
      <ItemContainer>
        <ItemLabel>
          <SubHeaderText>{subHeaderText.RESOLUTION}:</SubHeaderText>
          <ParagraphTextContainer>
            <ParagraphText className="info-text">
              {paragraphText.RESOLUTION}
            </ParagraphText>
          </ParagraphTextContainer>
        </ItemLabel>
        <ItemContent>
          <Dropdown
            options={options || []}
            selectedOption={resolutionId || claimResolutionId}
            placeholder={resolutionDropdown.PLACEHOLDER}
            onInputChange={(e) => onInputChange(e)}
            disabled={disabled}
          />
        </ItemContent>
      </ItemContainer>

      {content && (
        <div className="resolution-container">
          <ItemContainer>{content}</ItemContainer>
        </div>
      )}
    </div>
  );
};

export default Resolution;
