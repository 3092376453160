import React from "react";
import { CONFIRMATION_TEXT, CONFIRMATION_TEXT_MESSAGE } from "./const";
import GenericTransitionModal from "../../GenericTransitionModal";

const VendorDeclinedConfirmation = ({ className }) => {
  return (
    <div className={className}>
      <GenericTransitionModal
        headerText={CONFIRMATION_TEXT}
        bodyText={CONFIRMATION_TEXT_MESSAGE}
      />
    </div>
  );
};

export default VendorDeclinedConfirmation;
