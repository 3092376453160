import OrdersApi, {
  DeliveryAddressApi,
  LogisticsStatusApi,
} from "../../api/Orders";
import { getAddressWithoutLocationName } from "../../shared/utility.js";
import { openModal } from "../../state/reducers/global";
// Action constants
export const FETCH_ORDER_DETAILS = "@orders/FETCH_ORDER_DETAILS";
export const FETCH_ORDER_DETAILS_SUCCESS =
  "@orders/FETCH_ORDER_DETAILS_SUCCESS";
export const FETCH_ORDER_DETAILS_FAILURE =
  "@orders/FETCH_ORDER_DETAILS_FAILURE";
export const UPDATE_DELIVERY_ADDRESS = "@orders/UPDATE_DELIVERY_ADDRESS";
export const UPDATE_DELIVERY_ADDRESS_SUCCESS =
  "@orders/UPDATE_DELIVERY_ADDRESS_SUCCESS";
export const UPDATE_DELIVERY_ADDRESS_FAILURE =
  "@orders/UPDATE_DELIVERY_ADDRESS_FAILURE";
export const FETCH_LOGISTICS_STATUS = "@orders/FETCH_LOGISTICS_STATUS";
export const FETCH_LOGISTICS_STATUS_SUCCESS =
  "@orders/FETCH_LOGISTICS_STATUS_SUCCESS";
export const FETCH_LOGISTICS_STATUS_FAILURE =
  "@orders/FETCH_LOGISTICS_STATUS_FAILURE";
export const CLEAR_ORDER_DETAILS = "@orders/CLEAR_ORDER_DETAILS";

// Action creators
const fetchOrderDetailsFailure = (error) => {
  return {
    type: FETCH_ORDER_DETAILS_FAILURE,
    payload: error,
  };
};

const fetchOrderDetailsSuccess = (response) => {
  return {
    type: FETCH_ORDER_DETAILS_SUCCESS,
    payload: response,
  };
};

export const fetchOrderDetails = (id) => (dispatch, getState) => {
  dispatch({ type: FETCH_ORDER_DETAILS });

  const orderInstance = new OrdersApi({
    params: { id },
    credentials: getState().authentication.credentials,
  });

  return orderInstance.call().then(
    (response) => dispatch(fetchOrderDetailsSuccess(response)),
    (err) => {
      dispatch(fetchOrderDetailsFailure(err.message));
    }
  );
};

const updateDeliveryAddressFailure = (error) => {
  return {
    type: UPDATE_DELIVERY_ADDRESS_FAILURE,
    payload: error,
  };
};

const updateDeliveryAddressSuccess = (deliveryLocation) => {
  return {
    type: UPDATE_DELIVERY_ADDRESS_SUCCESS,
    payload: {
      title: deliveryLocation.locationName,
      text: deliveryLocation.contactAddress,
      deliveryTbc: false,
    },
  };
};

export const updateDeliveryAddress = (
  vehicleId,
  vehiclePurchaseId,
  location
) => (dispatch, getState) => {
  dispatch({ type: UPDATE_DELIVERY_ADDRESS });
  const deliveryLocation = {
    locationName: location.companyName,
    contactAddress: getAddressWithoutLocationName(location).split("\n"),
    postCode: location.postCode,
  };

  const params = {
    vehicleId: vehicleId,
    vehiclePurchaseId: vehiclePurchaseId,
    deliverylocation: deliveryLocation,
  };
  const deliveryInstance = new DeliveryAddressApi({
    params,
    credentials: getState().authentication.credentials,
  });
  return deliveryInstance.call().then(
    (response) => dispatch(updateDeliveryAddressSuccess(deliveryLocation)),
    (err) => {
      dispatch(updateDeliveryAddressFailure(err.message));
    }
  );
};

export const fetchLogisticsStatus = (id) => (dispatch, getState) => {
  dispatch(openModal("logistics-status-loader"));
  dispatch({ type: FETCH_LOGISTICS_STATUS });

  const logisticsStatus = new LogisticsStatusApi({
    params: { id },
    credentials: getState().authentication.credentials,
  });

  return logisticsStatus.call().then(
    (response) => {
      dispatch(fetchLogisticsStatusSuccess(response));
      return dispatch(openModal("logistics-status"));
    },
    (err) => {
      dispatch(openModal("generic-error"));
      return dispatch(fetchLogisticsStatusFailure(err.message));
    }
  );
};

const fetchLogisticsStatusFailure = (error) => {
  return {
    type: FETCH_LOGISTICS_STATUS_FAILURE,
    payload: error,
  };
};

const fetchLogisticsStatusSuccess = (response) => {
  return {
    type: FETCH_LOGISTICS_STATUS_SUCCESS,
    payload: response,
  };
};

export const clearOrderDetails = () => {
  return {
    type: CLEAR_ORDER_DETAILS,
  };
};

export const initialState = {
  isFetching: false,
  isUpdating: false,
  error: null,
  orderDetails: null,
  logisticsStatus: {
    isFetching: false,
    error: null,
    data: null,
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDER_DETAILS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        orderDetails: action.payload,
      };
    case FETCH_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        orderDetails: null,
        isFetching: false,
        error: action.payload,
      };
    case UPDATE_DELIVERY_ADDRESS:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_DELIVERY_ADDRESS_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        error: null,
        orderDetails: {
          ...state.orderDetails,
          deliveryDetails: {
            ...state.orderDetails.deliveryDetails,
            ...action.payload,
          },
        },
      };
    case UPDATE_DELIVERY_ADDRESS_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.payload,
      };
    case FETCH_LOGISTICS_STATUS:
      return {
        ...state,
        logisticsStatus: {
          ...state.logisticsStatus,
          isFetching: true,
          error: null,
        },
      };
    case FETCH_LOGISTICS_STATUS_SUCCESS:
      return {
        ...state,
        logisticsStatus: {
          ...state.logisticsStatus,
          isFetching: false,
          data: action.payload,
          error: null,
        },
      };
    case FETCH_LOGISTICS_STATUS_FAILURE:
      return {
        ...state,
        logisticsStatus: {
          ...state.logisticsStatus,
          isFetching: false,
          error: action.payload,
        },
      };

    case CLEAR_ORDER_DETAILS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
