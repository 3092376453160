import React, { useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { useSelector } from "react-redux";
import appSettings from "../../../clientappsettings";
import { themes } from "../../theme";
import Modal from "../Modal";
import Routes from "../Routes";
import ScrollToTop from "../../components/ScrollToTop";
import { BrowserRouter } from "react-router-dom";
import HelmetConfig from "../../theme/HelmetConfig";
import HelmetConfigGTM from "../../theme/HelmetConfigGTM";
import clientSettings from "../../../clientappsettings.json";
import useIsLoggedInUserType from "../../../shared/hooks/useIsLoggedInUserType";
import { USER_TYPE_MAP } from "../../../shared/utility";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import KeyboardShortcutsContainer from "../../containers/KeyboardShortcutsContainer/KeyboardShortcutsContainer";

const App = ({ className }) => {
  // Check that meta is set in persisted storage
  const { SUB_PATH } = appSettings;

  const themeReference = useSelector(
    (state) =>
      state.authentication.meta && state.authentication.meta.theme.value
  );

  const showTheme = useIsLoggedInUserType(USER_TYPE_MAP.Vendor)
    ? "Vendor"
    : themeReference;
  const theme = themes[showTheme] || themes["TradeBuyer"];

  const appleIconMap = {
    TradeBuyer: "tradeBuyer.png",
    Volkswagen: "volkswagen.png",
    Audi: "audi.png",
    Seat: "seat.png",
    Skoda: "skoda.png",
    VolkswagenCommercial: "volkswagen.png",
    Motability: "mfl.png",
  };

  useEffect(() => {
    const linkElement = document.querySelector('link[rel="apple-touch-icon"]');
    if (linkElement) {
      const themeIcon = appleIconMap[themeReference] || "tradeBuyer.png"; // Default to 'tradeBuyer.png' if theme not found
      linkElement.href = `${clientSettings.SUB_PATH}/${themeIcon}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeReference]);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter basename={SUB_PATH}>
        <div className={className}>
          <HelmetConfig />
          {clientSettings.TRADE_BUYER_GTM_ID && (
            <HelmetConfigGTM
              gtmId={clientSettings.TRADE_BUYER_GTM_ID}
              dataLayer="dataLayerTB"
            />
          )}
          {clientSettings.MOTABILITY_GTM_ID && theme.name === "Motability" && (
            <HelmetConfigGTM
              gtmId={clientSettings.MOTABILITY_GTM_ID}
              dataLayer="dataLayerMotability"
            />
          )}
          <ScrollToTop>
            <Routes />
          </ScrollToTop>
        </div>
        <ToastContainer
          position="bottom-right"
          rtl={false}
          theme="light"
          stacked
        />
        <Modal />
        <KeyboardShortcutsContainer />
      </BrowserRouter>
    </ThemeProvider>
  );
};

const StyledApp = styled(App)`
  // TODO: these styles are from the beginning of the build, and may be redundant now...
  text-align: center;
  min-height: 100vh;
`;

export default StyledApp;
