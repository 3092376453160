import styled from "styled-components";
import AboutSession from "./AboutSession";
import breakpoints from "../../theme/breakpoints";

export const StyledAboutSession = styled(AboutSession)`
  background-color: ${(props) => props.theme.COLOURS.WHITE};
  height: 100%;
  width: 100%;

  @media (min-width: ${breakpoints.TABLET}) {
    box-sizing: border-box;
    border: 0.2rem solid ${(props) => props.theme.COLOURS.ACCENT.base};
    padding: ${(props) => props.theme.SPACING.MEDIUM};
    border-radius: 0.9rem;
    height: auto;
  }

  .about-session-content {
    max-height: 34rem;
    overflow: auto;
    overflow-x: hidden;
    
    @media (max-width: ${breakpoints.TABLET}) {
      max-height: calc(100vh - 340px);// 340px is consistently the height of the header and footer
    }
  }

  .row,
  .mixed-rozzw {
    margin-top: ${props => props.theme.SPACING.REGULAR};

    @media (min-width: ${breakpoints.TABLET}) {
      display: flex;
      align-items: baseline;
    }

    p {
      margin: 0;
    }
  }

  .row {
    > div {
      flex: 1;
      margin-bottom: ${props => props.theme.SPACING.SMALL};

      @media (min-width: ${breakpoints.TABLET}) {
        margin-bottom: 0;
      }
    }
  }

  .modal-header {
    > * {
      margin-bottom: ${(props) => props.theme.SPACING.REGULAR};
    }
  }

  .header-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .copy-campaign {
    cursor: pointer;
    width: fit-content;
  }    
`;

export default StyledAboutSession;
