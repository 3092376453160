import ClaimActivityLogApi from "../../api/ClaimActivityLog";
import { openModal } from "./global";
import { updateObject } from "../../shared/utility";

export const CLAIM_ACTIVITY_LOG = "@claimActivityLog/CLAIM_ACTIVITY_LOG";
export const CLAIM_ACTIVITY_LOG_SUCCESS =
  "@claimActivityLog/CLAIM_ACTIVITY_LOG_SUCCESS";
export const CLAIM_ACTIVITY_LOG_FAILURE =
  "@claimActivityLog/CLAIM_ACTIVITY_LOG_FAILURE";

export const claimActivityLog = (claimId) => (dispatch, getState) => {
  dispatch({ type: CLAIM_ACTIVITY_LOG });

  const claimInstance = new ClaimActivityLogApi({
    params: {
      claimId: claimId,
    },
    credentials: getState().authentication.credentials,
  });

  claimInstance.call().then(
    (response) => {
      dispatch(claimActivityLogSuccess(response));
    },
    (err) => {
      dispatch(claimActivityLogFailure(err));
      return dispatch(openModal("generic-error"));
    }
  );
};

export const claimActivityLogSuccess = (data) => {
  return {
    type: CLAIM_ACTIVITY_LOG_SUCCESS,
    payload: data,
  };
};

export const claimActivityLogFailure = (error) => {
  return {
    type: CLAIM_ACTIVITY_LOG_FAILURE,
    payload: error,
  };
};

export const initialState = {
  isFetching: false,
  error: false,
  results: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLAIM_ACTIVITY_LOG:
      return updateObject(state, {
        isFetching: true,
      });
    case CLAIM_ACTIVITY_LOG_SUCCESS:
    case CLAIM_ACTIVITY_LOG_FAILURE:
      return updateObject(state, {
        isFetching: false,
        results: action.payload,
      });
    default:
      return state;
  }
};

export default reducer;
