import Api from "./Base";
import clientSettings from "../clientappsettings.json";
import get from "lodash.get";
import { getResponseObjectProperties } from "../shared/utility";

export default class DetailsAPI extends Api {
  baseUrl = clientSettings.API_URL;
  requestUrl = `/Vehicle/Showroom/${this.getParams().vehicleId}`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatResponseData(response) {
    const data = get(response, "data", {});
    const titleFields = ["make", "range", "model", "derivative"];
    const detailFields = [
      "dateOfRegistration",
      "odometer",
      "odometerType",
      "fuelType",
      "transmission",
      "colour",
      "interiorColour",
      "dateOfMileageReading",
      "regNo",
      "numberOfDoors",
      "numberOfSeats",
      "bodyStyle",
      "formerKeepers",
      "engineSize",
      "emissions",
      "vehicleType",
      "additionalEquipment",
    ];
    const vehicleId = get(data, "vehicleId", 0);
    
    return {
      vehicleId: vehicleId,
      titles: getResponseObjectProperties(data, titleFields, true),
      details: getResponseObjectProperties(data, detailFields, true),
      galleryInfo: get(data, "vehicleBlobUrls", []),
      vehicle360Url: get(data, "vehicle360Url", ""),
    };
  }
}