import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "./../../../views/components/ModalInsert";
import breakpoints from "./../../../views/theme/breakpoints";
import { useMediaQuery } from "react-responsive";
import {
  setSubscribed,
  unsubscribePushNotification,
} from "../../../state/reducers/notificationPreference";

import GenericConfirmInsert from "./../../../views/components/GenericConfirmInsert";

const UnsubscribeNotificationsModalInsert = () => {
  const dispatch = useDispatch();

  const onUnsubscribeNotifications = () => {
    dispatch(setSubscribed(false));
    dispatch(unsubscribePushNotification(null, null, true));
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  return (
    <Fragment>
      <ModalInsert width={isDesktop ? "55rem" : "100%"}>
        <GenericConfirmInsert
          headerText="Are you sure?"
          bodyText="You are about to remove all active device subscriptions, are you sure?"
          onCancel={onCancel}
          onConfirm={onUnsubscribeNotifications}
        />
      </ModalInsert>
    </Fragment>
  );
};

export default UnsubscribeNotificationsModalInsert;
