import React, { useContext } from "react";
import { HeaderContainer, HeaderPrimaryRow } from "../index";
import HeaderText from "../../../../../views/components/HeaderText";
import { ThemeContext } from "styled-components";
import { getCampaignStatus } from "../../../../../shared/utility";

export const HeaderDetails = ({ campaignId, data }) => {
  const theme = useContext(ThemeContext);

  return (
    <HeaderContainer
      borderBottomColor={theme.COLOURS.PRIMARY.shades[5]}
      paddingBottom={theme.SPACING.REGULAR}
    >
      <HeaderPrimaryRow>
        <HeaderText large>{getCampaignStatus(data?.campaignStatus)}</HeaderText>
      </HeaderPrimaryRow>
    </HeaderContainer>
  );
};

export default HeaderDetails;
