import React, { Fragment, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../components/ModalInsert";
import Panel from "../../components/Panel";
import VendorDeclineClaimMessage from "../../components/VendorDeclineClaim/VendorDeclineClaimMessage/VendorDeclineClaimMessage";
import VendorDeclineClaimDecision from "../VendorDeclineClaimDecision";
import {
  DeclineClaimReasonContext,
  DeclineClaimReasonValidationContext,
} from "../../components/VendorDeclineClaim/VendorDeclineClaimMessage/context/context";

const VendorDeclineModalInsert = () => {
  const dispatch = useDispatch();
  const activeClaimId = useSelector(
    (state) => state.global.claims.activeClaimId
  );

  const data = useSelector(
    (state) => state.claimDetailsVendor.data?.declineReasons || []
  );
  const dropdownOptions = data.map((item) => ({
    value: item.claimDeclineReasonId,
    optionName: item.declineReasonName,
    key: item.claimDeclineReasonId.toString(),
  }));

  const [claimDeclinedDecision, setClaimDeclinedDecision] = useState(false);

  const { declineClaimReason } = useContext(DeclineClaimReasonContext);

  const { validClaimDeclineReason } = useContext(
    DeclineClaimReasonValidationContext
  );

  const onCancel = () => {
    dispatch(closeModal());
  };

  const onClickDeclineDecision = () => {
    if (validClaimDeclineReason && declineReasonType) {
      setClaimDeclinedDecision(true);
    }
  };

  const [declineReasonType, setDeclineReasonType] = useState("");

  function onCategoryChange(value) {
    setDeclineReasonType(value);
  }

  const claimData = {
    claimId: activeClaimId,
    vendorNotes: declineClaimReason,
    claimDeclineReasonId: declineReasonType,
  };

  return (
    <Fragment>
      <ModalInsert>
        {claimDeclinedDecision ? (
          <VendorDeclineClaimDecision claimData={claimData} />
        ) : (
          <Panel
            mobileFlex
            style={{ height: "100%", borderRadius: "0.5rem" }}
            actions={[
              {
                content: "Close",
                action: onCancel,
                type: "ghost",
              },
              {
                content: "Decline Claim",
                action: onClickDeclineDecision,
                type: "errorPrimary",
                disabled: !(
                  validClaimDeclineReason &&
                  declineReasonType &&
                  declineClaimReason.length >= 10
                ),
              },
            ]}
          >
            <VendorDeclineClaimMessage
              declineReasonType={declineReasonType}
              onCategoryChange={onCategoryChange}
              dropdownOptions={dropdownOptions}
            />
          </Panel>
        )}
      </ModalInsert>
    </Fragment>
  );
};

export default VendorDeclineModalInsert;
