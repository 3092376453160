import React from "react";
import { OFFER_MADE_CONFIRMATION, OFFER_MADE_CONFIRMATION_MESSAGE } from "./const";
import GenericTransitionModal from "../../../../views/components/GenericTransitionModal";

const MakeOfferConfirmation = ({ className }) => {
  return (
    <div className={className}>
    <GenericTransitionModal
      headerText={OFFER_MADE_CONFIRMATION}
      bodyText={OFFER_MADE_CONFIRMATION_MESSAGE}
    />
  </div>
  );
};

export default MakeOfferConfirmation;
