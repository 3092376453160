import React from "react";

const HeaderText = React.forwardRef(({ children, className }, ref) => {
  return (
    <h2 className={className} ref={ref}>
      {children}
    </h2>
  );
});

export default HeaderText;
