import Api from "./Base";
import clientSettings from "../clientappsettings.json";

class InspectionApi extends Api {
  baseUrl = clientSettings.IMAGES_API_URL;
  requestUrl = `/Inspection/Data/${this.getParams().id}`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatResponseData(response) {
    if (response.data) {
      return response.data;
    }
    return response;
  }
}

export default InspectionApi;
