import React, { Fragment, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../components/ModalInsert";
import Panel from "../../components/Panel";
import VendorReviewCompleteMessage from "../../components/VendorReviewComplete/VendorReviewCompleteMessage/VendorReviewCompleteMessage";
import VendorReviewCompleteDecision from "../VendorReviewCompleteDecision"
import { ReviewCompleteReasonContext } from "../../components/VendorReviewComplete/VendorReviewCompleteMessage/context/context";

const VendorReviewCompleteModalInsert = () => {
  const dispatch = useDispatch();
  const activeClaimId = useSelector(
    (state) => state.global.claims.activeClaimId
  );

  const [reviewCompleteDecision, setReviewCompleteDecision] = useState(
    false
  );

  const { reviewCompleteReason } = useContext(
    ReviewCompleteReasonContext
  );

  const claimData = {
    vendorNotes: reviewCompleteReason,
    claimId: activeClaimId,
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  const onClickReviewCompleteDecision = () => {
    setReviewCompleteDecision(true);
  };

  return (
    <Fragment>
      <ModalInsert>
        {reviewCompleteDecision ? (
          <VendorReviewCompleteDecision claimData={claimData} />
        ) : (
          <Panel
            mobileFlex
            style={{ height: "100%", borderRadius: "0.5rem" }}
            actions={[
              {
                content: "Close",
                action: onCancel,
                type: "ghost",
              },
              {
                content: "Submit",
                action: onClickReviewCompleteDecision,
                type: "primary",
              },
            ]}
          >
            <VendorReviewCompleteMessage />
          </Panel>
        )}
      </ModalInsert>
    </Fragment>
  );
};

export default VendorReviewCompleteModalInsert;
