import styled, { css } from "styled-components";

import VehiclePillDetails from "./VehiclePillDetails";
import breakpoints from "../../theme/breakpoints";

export const StyledVehiclePillDetails = styled(VehiclePillDetails)`
  text-align: left;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    flex-wrap: nowrap;
  }

  .more {
    background-color: ${props => props.theme.COLOURS.PRIMARY.base};
    padding: 0.3rem 1.3rem;
    border-radius: 2rem;
    display: inline-block;
    margin-left: 0.5rem;
    ${props =>
      props.hasLink &&
      css`
        cursor: pointer;
      `};

    ${props =>
      props.showRemainingCount &&
      css`
        background-color: transparent;
        border: 0.1rem solid ${props => props.theme.COLOURS.PRIMARY.shades[5]};
        color: ${props => props.theme.COLOURS.PRIMARY.base};
        font-family: ${props => props.theme.FONTS.families.lato};
        font-size: ${props => props.theme.FONTS.sizes[10]};
        font-weight: ${props => props.theme.FONTS.weights[70]};
        letter-spacing: 0.075rem;
        line-height: ${props => props.theme.FONTS.lineHeights[20]};
      `};
  }
  .dot {
    height: 0.5rem;
    width: 0.5rem;
    background-color: ${props => props.theme.COLOURS.WHITE};
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.2rem;
    vertical-align: middle;
  }
`;

export default StyledVehiclePillDetails;
