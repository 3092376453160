import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from 'styled-components';
import ParagraphText from "../ParagraphText";
import HeaderText from "../HeaderText";
import Icon from "../Icon";
import Button from "../Button";

const AlertInsert = ({
  className,
  action,
  headerText,
  bodyText,
  buttonText
}) => {
  const theme = useContext(ThemeContext);

  return (
    <div className={className}>
      <Icon
        type="alert-circle"
        strokeColour={theme.COLOURS.RED.base}
        height="52px"
        width="52px"
      />
      <HeaderText>{headerText}</HeaderText>
      <ParagraphText>{bodyText}</ParagraphText>
      <div className="alert-insert__actions">
        <Button action={action} content={buttonText} primary />
      </div>
    </div>
  );
};

AlertInsert.propTypes = {
  className: PropTypes.string,
  action: PropTypes.func,
  headerText: PropTypes.string,
  bodyText: PropTypes.string,
  buttonText: PropTypes.string
};

export default AlertInsert;
