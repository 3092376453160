import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import DealerFundingRequest from "../../components/DealerFundingRequest";
import ModalInsert from "../../components/ModalInsert";
import {
  fetchFundingProviders,
  fetchCreditUtilisation,
  applyForFunding,
} from "./../../../state/reducers/funding";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../theme/breakpoints";

const DealerFundingRequestModalInsert = () => {
  const priceBreakdown = useSelector(
    (state) => state.orders.orderDetails.priceBreakdown
  );
  const vehiclePurchaseId = useSelector(
    (state) => state.orders.orderDetails.vehiclePurchaseId
  );
  const fundingProvidersData = useSelector(
    (state) => state.funding.fundingProviders
  );

  const {
    results: fundingProviders,
    isFetching: isFetchingFundingProviders,
    isFetchingBalance,
  } = fundingProvidersData;

  const jwtToken = useSelector((state) => state.authentication.credentials);

  const dispatch = useDispatch();

  const onClose = () => dispatch(closeModal());

  const onCheckBalance = (providerName) =>
    dispatch(fetchCreditUtilisation(providerName));

  const onProceed = (providerName) => {
    dispatch(applyForFunding(vehiclePurchaseId, providerName));
  };

  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  useEffect(() => {
    dispatch(fetchFundingProviders(vehiclePurchaseId));
  }, [dispatch, vehiclePurchaseId]);

  return (
    <ModalInsert layout="flex" width={isDesktop ? "96rem" : "100%"}>
      <DealerFundingRequest
        priceBreakdown={priceBreakdown}
        fundingProviders={fundingProviders}
        isFetchingBalance={isFetchingBalance}
        isFetchingFundingProviders={isFetchingFundingProviders}
        jwtToken={jwtToken}
        onCheckBalance={onCheckBalance}
        onProceed={onProceed}
        onClose={onClose}
      />
    </ModalInsert>
  );
};

export default DealerFundingRequestModalInsert;
