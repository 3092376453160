import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import HeaderText from "../../HeaderText";
import ParagraphText from "../../ParagraphText";
import { informationRequestReasonLabel, errorMessages } from "./consts";
import TextArea from "../../TextArea";
import BidInputError from "../../BidInput/child-components/BidInputError";
import {
  InformationRequestReasonContext,
  InformationRequestReasonValidationContext,
} from "../VendorInformationRequestMessage/context/context";

const VendorInformationRequestMessage = () => {
  const { informationRequestReason, setInformationRequestReason } = useContext(
    InformationRequestReasonContext
  );
  const {
    validInformationRequestReason,
    setValidInformationRequestReason,
    hasFocus,
    setHasFocus,
  } = useContext(InformationRequestReasonValidationContext);

  function onChangeInput(e) {
    const { value } = e.target;
    setInformationRequestReason(value);
    if (value.length >= 10 && value.length <= 2000) {
      setValidInformationRequestReason(true);
    } else {
      setValidInformationRequestReason(false);
    }
  }

  useEffect(() => {
    validInformationRequestReason && setValidInformationRequestReason(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [informationRequestReason]);

  return (
    <div style={{width: "90rem"}}>
      <HeaderText>{informationRequestReasonLabel.HEADER_TEXT}</HeaderText>
      <ParagraphText>
        {informationRequestReasonLabel.SUB_HEADER_TEXT}
      </ParagraphText>
      <TextArea
        value={informationRequestReason}
        className="text-area__input"
        onChange={onChangeInput}
        validValue={validInformationRequestReason}
        resize="none"
        maximumCharactersBottom="2000"
        rows="7"
        cols="50"
        onFocus={(e) => {
          e.target.select();
          onChangeInput(e);
          setHasFocus(true);
        }}
        onBlur={(e) => {
          setHasFocus(false);
        }}
      ></TextArea>
      {!validInformationRequestReason &&
        !hasFocus &&
        (informationRequestReason.length <= 0 ? (
          <BidInputError errorMessage={errorMessages.FIELD_REQUIRED} />
        ) : (
          <BidInputError errorMessage={errorMessages.MIN_LENGTH} />
        ))}
    </div>
  );
};

VendorInformationRequestMessage.propTypes = {
  setNotesToBuyer: PropTypes.func,
};

export default VendorInformationRequestMessage;
