import {
  GetEpyxTerms,
  GetVendorTerms,
  GetNewUpdatesText,
  MarkAsReadTerms
} from "../../api/TermsAndConditions";

// Action constants
export const FETCH_EPYX_TERMS = "@termsAndConditions/FETCH_EPYX_TERMS";
export const FETCH_EPYX_TERMS_SUCCESS =
  "@termsAndConditions/FETCH_EPYX_TERMS_SUCCESS";
export const FETCH_EPYX_TERMS_FAILURE =
  "@termsAndConditions/FETCH_EPYX_TERMS_FAILURE";

export const FETCH_VENDOR_TERMS = "@termsAndConditions/FETCH_VENDOR_TERMS";
export const FETCH_VENDOR_TERMS_SUCCESS =
  "@termsAndConditions/FETCH_VENDOR_TERMS_SUCCESS";
export const FETCH_VENDOR_TERMS_FAILURE =
  "@termsAndConditions/FETCH_VENDOR_TERMS_FAILURE";

export const FETCH_EPYX_TERMS_UPDATES =
  "@termsAndConditions/FETCH_EPYX_TERMS_UPDATES";
export const FETCH_EPYX_TERMS_UPDATES_SUCCESS =
  "@termsAndConditions/FETCH_EPYX_TERMS_UPDATES_SUCCESS";
export const FETCH_EPYX_TERMS_UPDATES_FAILURE =
  "@termsAndConditions/FETCH_EPYX_TERMS_UPDATES_FAILURE";

export const MARK_EPYX_TERMS_AS_READ =
  "@termsAndConditions/MARK_EPYX_TERMS_AS_READ";
export const MARK_EPYX_TERMS_AS_READ_SUCCESS =
  "@termsAndConditions/MARK_EPYX_TERMS_AS_READ_SUCCESS";
export const MARK_EPYX_TERMS_AS_READ_FAILURE =
  "@termsAndConditions/MARK_EPYX_TERMS_AS_READ_FAILURE";

export const CLEAR_ALL_TERMS = "@termsAndConditions/CLEAR_ALL_TERMS";

// Action creators
const fetchEpyxTermsSuccess = data => {
  return {
    type: FETCH_EPYX_TERMS_SUCCESS,
    payload: data
  };
};

const fetchEpyxTermsFailure = error => {
  return {
    type: FETCH_EPYX_TERMS_FAILURE,
    payload: error
  };
};

export const fetchEpyxTerms = () => (dispatch, getState) => {
  dispatch({ type: FETCH_EPYX_TERMS });

  const termsInstance = new GetEpyxTerms({
    credentials: getState().authentication.credentials
  });

  termsInstance.call().then(
    response => {
      dispatch(fetchEpyxTermsSuccess(response.data));
    },
    err => {
      // TODO: error logging
      return dispatch(fetchEpyxTermsFailure(err));
    }
  );
};

const fetchVendorTermsSuccess = data => {
  return {
    type: FETCH_VENDOR_TERMS_SUCCESS,
    payload: data
  };
};

const fetchVendorTermsFailure = error => {
  return {
    type: FETCH_VENDOR_TERMS_FAILURE,
    payload: error
  };
};

export const fetchVendorTerms = vehicleId => (dispatch, getState) => {
  dispatch({ type: FETCH_VENDOR_TERMS });

  const termsInstance = new GetVendorTerms({
    params: { vehicleId: vehicleId },
    credentials: getState().authentication.credentials
  });

  termsInstance.call().then(
    response => {
      dispatch(fetchVendorTermsSuccess(response.data));
    },
    err => {
      return dispatch(fetchVendorTermsFailure(err));
    }
  );
};

const fetchEpyxTermsUpdatesSuccess = data => {
  return {
    type: FETCH_EPYX_TERMS_UPDATES_SUCCESS,
    payload: data
  };
};

const fetchEpyxTermsUpdatesFailure = error => {
  return {
    type: FETCH_EPYX_TERMS_UPDATES_FAILURE,
    payload: error
  };
};

export const fetchEpyxTermsUpdates = () => (dispatch, getState) => {
  dispatch({ type: FETCH_EPYX_TERMS_UPDATES });

  const termsInstance = new GetNewUpdatesText({
    credentials: getState().authentication.credentials
  });

  termsInstance.call().then(
    response => {
      dispatch(fetchEpyxTermsUpdatesSuccess(response.data));
    },
    err => {
      // TODO: error logging
      return dispatch(fetchEpyxTermsUpdatesFailure(err));
    }
  );
};

const markEpyxTermsAsReadSuccess = () => {
  return {
    type: MARK_EPYX_TERMS_AS_READ_SUCCESS
  };
};

const markEpyxTermsAsReadFailure = error => {
  return {
    type: MARK_EPYX_TERMS_AS_READ_FAILURE,
    payload: error
  };
};

export const markEpyxTermsAsRead = () => (dispatch, getState) => {
  dispatch({ type: MARK_EPYX_TERMS_AS_READ });

  const instance = new MarkAsReadTerms({
    credentials: getState().authentication.credentials
  });

  instance.call().then(
    response => {
      dispatch(markEpyxTermsAsReadSuccess());
    },
    err => {
      // TODO: error logging
      return dispatch(markEpyxTermsAsReadFailure(err));
    }
  );
};

export const clearAllTerms = () => dispatch => {
  dispatch({
    type: CLEAR_ALL_TERMS
  });
};

const initialState = {
  isFetchingEpyxTerms: false,
  isFetchingEpyxTermsUpdates: false,
  isFetchingVendorTerms: false,
  isUpdating: false,
  error: false,
  epyxTerms: [],
  vendorTerms: [],
  epyxTermsUpdates: []
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    //EPYX
    case FETCH_EPYX_TERMS:
      return {
        ...state,
        isFetchingEpyxTerms: true
      };
    case FETCH_EPYX_TERMS_FAILURE:
      return {
        ...state,
        isFetchingEpyxTerms: false,
        epyxTerms: ["No epyx terms and conditions found!"],
        error: action.payload
      };
    case FETCH_EPYX_TERMS_SUCCESS:
      return {
        ...state,
        isFetchingEpyxTerms: false,
        epyxTerms: action.payload
      };
    //Vendor
    case FETCH_VENDOR_TERMS:
      return {
        ...state,
        isFetchingVendorTerms: true
      };
    case FETCH_VENDOR_TERMS_FAILURE:
      return {
        ...state,
        isFetchingVendorTerms: false,
        vendorTerms: ["No vendor terms and conditions found!"],
        error: action.payload
      };
    case FETCH_VENDOR_TERMS_SUCCESS:
      return {
        ...state,
        isFetchingVendorTerms: false,
        vendorTerms: action.payload
      };

    //EPYX_TERMS_UPDATES
    case FETCH_EPYX_TERMS_UPDATES:
      return {
        ...state,
        isFetchingEpyxTermsUpdates: true
      };

    case FETCH_EPYX_TERMS_UPDATES_FAILURE:
      return {
        ...state,
        isFetchingEpyxTermsUpdates: false,
        error: action.payload
      };
    case FETCH_EPYX_TERMS_UPDATES_SUCCESS:
      return {
        ...state,
        isFetchingEpyxTermsUpdates: false,
        epyxTermsUpdates: action.payload
      };

    case MARK_EPYX_TERMS_AS_READ:
      return {
        ...state,
        isUpdating: true
      };

    case MARK_EPYX_TERMS_AS_READ_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.payload
      };
    case MARK_EPYX_TERMS_AS_READ_SUCCESS:
      return {
        ...state,
        isUpdating: false
      };

    case CLEAR_ALL_TERMS:
      return {
        ...initialState,
        activeVehicleId: state.activeVehicleId
      };
    default:
      return state;
  }
};

export default reducer;
