import styled from "styled-components";
import ModalGallery from "./ModalGallery";

const StyledModalGallery = styled(ModalGallery)`
  img {
    display: block;
    max-height: 100%;
    max-width: 100%;
    opacity: 1;
    transition: opacity linear 0.2s;

    &.loading {
      opacity: 0;
    }
  }

  .modal-gallery {
    &__body {
      align-items: center;
      background-color: ${props => props.theme.COLOURS.BLACK};
      display: flex;
      height: calc(100vh - 7.5rem);
      justify-content: center;
      overflow: hidden;
      position: relative;
    }
  }
`;

export default StyledModalGallery;
