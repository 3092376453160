import React, { useState, useEffect } from "react";
import HeaderText from "../../../../views/components/HeaderText";
import ParagraphText from "../../../../views/components/ParagraphText";
import { addVehiclesLabel } from "./const";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "../../../../views/components/Checkbox";
import breakpoints from "../../../../views/theme/breakpoints";
import { useMediaQuery } from "react-responsive";
import PopoverCheckbox from "../../../../views/components/PopoverFilter/child-components/Checkbox";
import AccordionFilter from "../../../../views/components/AccordionFilter";
import PopoverFilter from "../../../../views/components/PopoverFilter";
import {
  fetchCapMake,
  fetchCapRange,
  fetchCapTrim,
} from "../../../../state/reducers/campaignManagement";
import SkeletonCheckboxCampaignManagement from "../../../../views/components/Skeletons/SkeletonCheckboxCampaignManagement";

const CampaignAddVehicles = ({
  campaignId,
  data,
  selectedMakes,
  setSelectedMakes,
  selectedMakeKeys,
  selectedMakeValues,
  selectedMakeValuesAsString,
  selectedRanges,
  setSelectedRanges,
  selectedRangeKeys,
  selectedRangeValues,
  selectedRangeValuesAsString,
  selectedTrims,
  setSelectedTrims,
  selectedTrimKeys,
  seledctedTrimValues,
  selectedTrimValuesAsString,
  specificMakeSelection,
  specificRangeSelection,
  specificTrimSelection,
}) => {
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  const CheckboxType = isTabletOrMobile ? Checkbox : PopoverCheckbox;
  const FilterType = isTabletOrMobile ? AccordionFilter : PopoverFilter;
  const [openMake, setOpenMake] = useState(false);
  const [openRange, setOpenRange] = useState(false);
  const [openTrim, setOpenTrim] = useState(false);

  const toggleMakeFilter = () => {
    const campaignRangeId = data?.campaignRangeId;
    dispatch(fetchCapMake(campaignRangeId));
    setOpenMake(!openMake);
  };

  const toggleRangeFilter = () => {
    const campaignRangeId = data?.campaignRangeId;
    const key = selectedMakeKeys.length > 0 ? selectedMakeKeys[0] : null;
    dispatch(fetchCapRange(campaignRangeId, key));
    setOpenRange(!openRange);
  };

  const toggleTrimFilter = () => {
    const campaignRangeId = data?.campaignRangeId;
    const rangeIds = selectedRangeKeys.length > 0 ? selectedRangeKeys : null;
    const makeId = selectedMakeKeys.length > 0 ? selectedMakeKeys[0] : null;
    dispatch(fetchCapTrim(campaignRangeId, makeId, rangeIds));
    setOpenTrim(!openTrim);
  };

  const onMakeCheckboxClick = (option) => {
    setSelectedMakes((prevSelected) => {
      if (option.value === "SELECT ALL") {
        const newSelectAllChecked = !selectAllCheckedMakes;
        setSelectAllCheckedMakes(newSelectAllChecked);

        if (newSelectAllChecked) {
          const selectedMakes = capMakeOptions
            .filter((makeOption) => makeOption.value !== "SELECT ALL")
            .map((makeOption) => ({
              key: makeOption.key,
              value: makeOption.value,
              included: true,
            }));
          return selectedMakes;
        } else {
          return [];
        }

      } else {
      const existingSelectedIndex = prevSelected.findIndex(
        (selected) => selected.key === option.key
      );

      if (existingSelectedIndex !== -1) {
        prevSelected.splice(existingSelectedIndex, 1);
        return [...prevSelected];
      } else {
        prevSelected.push({
          key: option.key,
          value: option.value,
          included: true,
        });
      }

      if (prevSelected.length === capMakeOptions.length - 1) {
        setSelectAllCheckedMakes(true);
      } else {
        setSelectAllCheckedMakes(false);
      }
      
      return [...prevSelected];
    }
    });
  };

  useEffect(() => {
    if (selectedMakes?.length === 2 || selectedMakes?.length === 0) {
      setSelectedRanges([]);
      setSelectedTrims([]);
    }
  }, [selectedMakes, setSelectedRanges, setSelectedTrims]);

  useEffect(() => {
    if (selectedRanges?.length === 0) {
      setSelectedTrims([]);
    }
  }, [selectedRanges, setSelectedTrims]);

  const onRangeCheckboxClick = (option) => {
    setSelectedRanges((prevSelected) => {
      if (option.value === "SELECT ALL") {
        const newSelectAllChecked = !selectAllCheckedRanges;
        setSelectAllCheckedRanges(newSelectAllChecked);

        if (newSelectAllChecked) {
          const selectedRanges = capRangeOptions
            .filter((rangeOption) => rangeOption.value !== "SELECT ALL")
            .map((rangeOption) => ({
              key: rangeOption.key,
              value: rangeOption.value,
              included: true,
            }));
          return selectedRanges;
        } else {
          return [];
        }
      } else {
      const existingSelectedIndex = prevSelected.findIndex(
        (selected) => selected.key === option.key
      );

      if (existingSelectedIndex !== -1) {
        prevSelected.splice(existingSelectedIndex, 1);
      } else {
        prevSelected.push({
          key: option.key,
          value: option.value,
          included: true,
        });
      }
      if (prevSelected.length === capRangeOptions.length - 1) {
        setSelectAllCheckedRanges(true);
      } else {
        setSelectAllCheckedRanges(false);
      }
      

      return [...prevSelected];
    }
    });
  };

  const onTrimCheckboxClick = (option) => {
    setSelectedTrims((prevSelected) => {
      if (option.value === "SELECT ALL") {
        const newSelectAllChecked = !selectAllCheckedTrims;
        setSelectAllCheckedTrims(newSelectAllChecked);
  
        if (newSelectAllChecked) {
          const selectedTrims = capTrimOptions
            .filter((trimOption) => trimOption.value !== "SELECT ALL")
            .map((trimOption) => ({
              key: trimOption.key,
              value: trimOption.value,
              included: true,
            }));
          return selectedTrims;
        } else {
          return [];
        }
      } else {
        const existingSelectedIndex = prevSelected.findIndex(
          (selected) => selected.key === option.key
        );
  
        if (existingSelectedIndex !== -1) {
          prevSelected.splice(existingSelectedIndex, 1);
        } else {
          prevSelected.push({
            key: option.key,
            value: option.value,
            included: true,
          });
        }
  
        if (prevSelected.length === capTrimOptions.length - 1) {
          setSelectAllCheckedTrims(true);
        } else {
          setSelectAllCheckedTrims(false);
        }
  
        return [...prevSelected];
      }
    });
  };
  
  

  const { capMake, capRange, capTrim } = useSelector(
    (state) => state.campaignManagement.activeCampaign
  );

  const selectAllOptionTrims = {
    key: "selectAll",
    value: "SELECT ALL",
    included:
      selectedTrims.length > 0 && selectedTrims.length === capTrim.length,
  };

  const selectAllOptionRanges = {
    key: "selectAll",
    value: "SELECT ALL",
    included:
      selectedRanges.length > 0 && selectedRanges.length === capRange.length,
  };

  const selectAllOptionMakes = {
    key: "selectAll",
    value: "SELECT ALL",
    included:
      selectedMakes.length > 0 && selectedMakes.length === capMake.length,
  };

  const capTrimOptions = [
    selectAllOptionTrims,
    ...capTrim.map((option) => ({
      key: option.key,
      value: option.value,
      included: selectedTrims.some(
        (selected) => selected.value === option.value
      ),
    })),
  ];

  const capRangeOptions = [
    selectAllOptionRanges,
    ...capRange.map((option) => ({
      key: option.key,
      value: option.value,
      included: selectedRanges.some(
        (selected) => selected.value === option.value
      ),
    })),
  ];

  const capMakeOptions = [
    selectAllOptionMakes,
    ...capMake.map((option) => ({
      key: option.key,
      value: option.value,
      included: selectedMakes.some(
        (selected) => selected.value === option.value
      ),
    })),
  ];

  useEffect(() => {
    if (
      selectedRanges?.length !== specificRangeSelection?.length || specificRangeSelection?.length !== selectedRanges?.length
    ) {
      setSelectAllCheckedTrims(false);
      setSelectedTrims([])
    }
  }, [selectedRanges, specificRangeSelection, setSelectedTrims]);

  useEffect(() => {
    if (
      selectedMakes?.length !== specificMakeSelection?.length || specificMakeSelection?.length !== selectedMakes?.length
    ) {
      setSelectAllCheckedTrims(false);
      setSelectAllCheckedRanges(false)
      setSelectedTrims([])
      setSelectedRanges([])
    }
  }, [selectedMakes, specificMakeSelection, setSelectedTrims, setSelectedRanges]);
  
  const [selectAllCheckedMakes, setSelectAllCheckedMakes] = useState(
    specificMakeSelection?.length ===
      capMakeOptions.filter((option) => option.value !== "SELECT ALL").length
  );

  const [selectAllCheckedRanges, setSelectAllCheckedRanges] = useState(
    specificRangeSelection?.length ===
      capRangeOptions.filter((option) => option.value !== "SELECT ALL").length
  );

  const [selectAllCheckedTrims, setSelectAllCheckedTrims] = useState(
    specificTrimSelection?.length ===
      capTrimOptions.filter((option) => option.value !== "SELECT ALL").length
  );

  const {
    isFetchingCapMake,
    isFetchingCapRange,
    isFetchingCapTrim,
  } = useSelector((state) => state.campaignManagement);

  const displayClass = isTabletOrMobile ? "select-wrapper" : "filter-wrapper";
  const displayStyle = isTabletOrMobile
    ? {height: "35rem", overflowY: "auto"}
    : { display: "flex", paddingTop: "1rem" };

  return (
    <div style={{ width: "90rem", height: "30rem" }}>
      <HeaderText>{addVehiclesLabel.HEADER_TEXT}</HeaderText>
      <ParagraphText>{addVehiclesLabel.SUB_HEADER_TEXT}</ParagraphText>
      <div className={displayClass} style={displayStyle}>
        <FilterType
          isOpen={openMake}
          onDropdownSelect={() => toggleMakeFilter()}
          label="Make"
          accordionAction={toggleMakeFilter}
          selectedLabel={selectedMakeValuesAsString}
          selectedCount={selectedMakes?.length}
          position="left"
          campaign={true}
          campaignFlex={true}
          campaignPopoverLabel={true}
        >
          {!isFetchingCapMake ? (
            !!capMakeOptions?.length ? (
              capMakeOptions.map((option) => (
                <div className="trimMap-container">
                <CheckboxType
                  key={option.key}
                  {...option}
                  isChecked={
                    option.value === "SELECT ALL"
                      ? selectAllCheckedMakes
                      : selectedMakes.some(
                          (selected) => selected.value === option.value
                        )
                  }
                  onInputChange={() => onMakeCheckboxClick(option)}
                  hideCount={true}
                >
                  {option.value}
                </CheckboxType>
                </div>
              ))
            ) : (
              <ParagraphText>
                There are no results for your selected filters.
              </ParagraphText>
            )
          ) : (
            <SkeletonCheckboxCampaignManagement />
          )}
        </FilterType>

        <FilterType
          isOpen={openRange}
          onDropdownSelect={() => toggleRangeFilter()}
          accordionAction={toggleRangeFilter}
          isDisabled={selectedMakes?.length === 0 || selectedMakes?.length > 1}
          label="Range"
          selectedLabel={selectedRangeValuesAsString}
          selectedCount={selectedRanges?.length}
          position="left"
          campaign={true}
          campaignCentre={true}
          campaignPopoverLabel={true}
        >
          {!isFetchingCapRange ? (
            !!capRangeOptions?.length ? (
              capRangeOptions.map((option) => (
                <div className="trimMap-container">
                <CheckboxType
                  key={option.value}
                  {...option}
                    isChecked={
                      option.value === "SELECT ALL"
                      ? selectAllCheckedRanges
                      : selectedRanges.some(
                        (selected) => selected.value === option.value
                        )
                      }
                      onInputChange={() => onRangeCheckboxClick(option)}
                      hideCount={true}
                      >
                  {option.value}
                </CheckboxType>
                  </div>
              ))
            ) : (
              <ParagraphText>
                There are no results for your selected filters.
              </ParagraphText>
            )
          ) : (
            <SkeletonCheckboxCampaignManagement />
          )}
        </FilterType>

        <FilterType
          isOpen={openTrim}
          onDropdownSelect={() => toggleTrimFilter()}
          accordionAction={toggleTrimFilter}
          isDisabled={selectedRanges?.length === 0}
          label="Trim"
          selectedLabel={selectedTrimValuesAsString}
          selectedCount={selectedTrims?.length}
          position="right"
          campaign={true}
          campaignPopoverLabel={true}
        >
          {!isFetchingCapTrim ? (
            !!capTrimOptions.length ? (
              capTrimOptions.map((option) => (
                <div className="trimMap-container">
                <CheckboxType
                  key={option.key}
                  {...option}
                  isChecked={
                    option.value === "SELECT ALL"
                      ? selectAllCheckedTrims
                      : selectedTrims.some(
                          (selected) => selected.value === option.value
                        )
                  }
                  onInputChange={() => onTrimCheckboxClick(option)}
                  hideCount={true}
                >
                  {option.value}
                </CheckboxType>
                </div>
              ))
            ) : (
              <ParagraphText>
                There are no results for your selected filters.
              </ParagraphText>
            )
          ) : (
            <SkeletonCheckboxCampaignManagement />
          )}
        </FilterType>
      </div>
    </div>
  );
};

export default CampaignAddVehicles;
