import Api from "./Base";
import settings from "../clientappsettings.json";
import get from "lodash.get";
import { getResponseObjectProperties } from "../shared/utility";

class ClaimDetailsVendor extends Api {
  baseUrl = settings.VENDORS_URL;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class FetchClaim extends ClaimDetailsVendor {
  requestUrl = `/Claims/${this.getParams().claimId}`;

  formatResponseData(response) {
    const data = get(response.data, "claim", {});

    const titleFields = ["make", "range", "model", "derivative"];
    const detailFields = [
      "dateOfRegistration",
      "odometer",
      "odometerType",
      "fuelType",
      "transmission",
      "colour",
      "interiorColour",
      "regNo",
      "bodyStyle",
      "engineSize",
      "mostRecentInspectionId",
      "reviewComplete",
      "residualValue",
      "capValue",
      "hasFees",
      "hasAutoLogistics",
      "anotherVehicleActive",
    ];
    const keyInfoFields = [
      "v5",
      "mot",
      "motExpiryDate",
      "auctionGrade",
      "condition",
      "damagePercentage",
    ];
    const saleInfoFields = [
      "tradeBuyer",
      "saleStatus",
      "dateOfSale",
      "purchasePrice",
    ];
    const claimDetailsFields = [
      "dateFirstSubmitted",
      "claimCategory",
      "claimSubCategory",
      "claimResolution",
      "contactName",
      "telNo",
      "email",
      "claimTotalCost",
      "claimCostType",
      "vehicleAddress",
      "vehicleBlobUrls",
      "options",
      "claimReason",
      "previousOffer",
      "claimant",
      "tradeBuyer",
      "claimDeclineReasonId",
    ];

    return {
      vehicleId: get(data, "vehicleId", 0),
      claimId: get(data, "claimId", 0),
      status: get(data, "status", 0),
      keyInfo: getResponseObjectProperties(data, keyInfoFields, true),
      claimDetails: getResponseObjectProperties(data, claimDetailsFields, true),
      titles: getResponseObjectProperties(data, titleFields, true),
      details: getResponseObjectProperties(data, detailFields, true),
      saleInfo: getResponseObjectProperties(data, saleInfoFields, true),
      galleryInfo: get(data, "vehicleBlobUrls", []),
      vehicle360Url: get(data, "vehicle360Url", ""),
      declineReasons: get(data, "declineReasons", ""),
    };
  }
}

export class DeclineClaim extends ClaimDetailsVendor {
  requestUrl = `/Claims/Decline`;
  method = "POST";
}

export class InformationRequest extends ClaimDetailsVendor {
  requestUrl = `/Claims/InformationRequest`;
  method = "POST";
}

export class AddNote extends ClaimDetailsVendor {
  requestUrl = `/Claims/AddNote`;
  method = "POST";
}

export class ReviewComplete extends ClaimDetailsVendor {
  requestUrl = `/Claims/ReviewComplete`;
  method = "POST";
}

export class FetchVendorClaimActivityLog extends ClaimDetailsVendor {
  requestUrl = `/Claims/ActivityLog/${this.getParams().claimId}`;
  method = "GET";
  formatResponseData(response) {
    if (response.data?.activityLog) {
      return response.data.activityLog;
    }
    return response;
  }
}

export class FetchPurchaseInformation extends ClaimDetailsVendor {
  requestUrl = `/Claims/PurchaseDetails/${this.getParams().claimId}`;

  formatResponseData(response) {
    if (response.data?.purchaseDetails) {
      return response.data.purchaseDetails;
    }
    return response;
  }
}

export class MakeOffer extends ClaimDetailsVendor {
  requestUrl = `/Claims/MakeOffer`;
  method = "POST";
}

export class CancelSale extends ClaimDetailsVendor {
  requestUrl = `/Claims/CancelSale`;
  method = "POST";
}

export class FetchBuyerClaimHistory extends ClaimDetailsVendor {
  requestUrl = `/Claims/BuyerClaimHistory/${this.getParams().claimId}`;
  method = "GET";

  formatResponseData(response) {
    if (response.data?.buyerClaimHistory) {
      return response.data.buyerClaimHistory;
    }
    return response;
  }
}

export class FetchBuyerClaimHistoryBreakdown extends ClaimDetailsVendor {
  requestUrl = "/Claims/BuyerClaimHistoryBreakdown";
  method = "POST";

  formatResponseData(response) {
    if (response.data?.buyerClaimHistoryBreakdown) {
      return response.data.buyerClaimHistoryBreakdown;
    }
    return response;
  }
}

export class FetchSupportingDocuments extends Api {
  baseUrl = settings.AZURE_VEHICLE_URL;
  requestUrl = `/api/ClaimBlobList?code=${settings.AZURE_VEHICLE_API_KEY}`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  formatResponseData(response) {
    if (response.data?.supportingDocuments) {
      return response.data.supportingDocuments;
    }
    return response;
  }
}
