import styled from "styled-components";

// import SkeletonNewClaims from "./SkeletonNewClaims/SkeletonNewClaims.jsx";
import SkeletonNewClaims from "./SkeletonNewClaims";

const StyledSkeletonNewClaims = styled(SkeletonNewClaims)`
  display: flex;
  flex-wrap: wrap;
  padding: ${(props) => (props.paddingNone ? "0" : "4rem")};

  span {
    width: 100%;
    height: 4rem;
    margin: 1rem;
    background-color: ${(props) => props.theme.COLOURS.PRIMARY.shades[10]};
    ${(props) => props.theme.ANIMATIONS["shimmer"]};
    display: block;
    margin-bottom: 0.3rem;
  }
`;

export default StyledSkeletonNewClaims;
