import styled from "styled-components";
import SkeletonPromotionModal from "./SkeletonPromotionModal.jsx";
import breakpoints from "../../../theme/breakpoints";

const StyledSkeletonPromotionModal = styled(SkeletonPromotionModal)`
  height: 100%;

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    width: 100%;
    height: 60rem;
  }

  .promotion-blocks {
    display: flex;

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      display: block;
    }
  }
  .panel__content > div {
    animation: shimmer 3s infinite linear;
    background: linear-gradient(
      to right,
      ${(props) => props.theme.COLOURS.PRIMARY.shades[5]} 4%,
      ${(props) => props.theme.COLOURS.SECONDARY.shades[10]} 25%,
      ${(props) => props.theme.COLOURS.PRIMARY.shades[10]} 36%
    );
    background-size: 100rem 100%;
  }

  .promotion-block__description {
    height: 50rem;
    margin-bottom: ${(props) => props.theme.SPACING.SMALL};
    width: 70%;
  }

  .promotion-block__logo_and_date {
    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      height: 3rem;
      margin-bottom: ${(props) => props.theme.SPACING.SMALL};
    }
  }
  .promotion-block__additional {
    height: 30rem;
    margin-bottom: ${(props) => props.theme.SPACING.MEDIUM};
  }

  @keyframes shimmer {
    0% {
      background-position: -100rem 0;
    }
    100% {
      background-position: 100rem 0;
    }
  }
`;

export default StyledSkeletonPromotionModal;
