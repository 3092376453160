import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddressPicker from "../../components/AddressPicker";
import { closeModal } from "../../../state/reducers/global";
import {
  find,
  capture,
  clearSelectedAddress
} from "../../../state/reducers/addressLookup";
import { updateDeliveryAddress } from "./../../../state/reducers/orders";

const UpdateDeliveryAddressModalInsert = () => {
  const addressLookup = useSelector(state => state.addressLookup);
  const vehicleId = useSelector(
    state =>
      state.orders &&
      state.orders.orderDetails &&
      state.orders.orderDetails.vehicleDetails &&
      state.orders.orderDetails.vehicleDetails.vehicleId
  );

  const vehiclePurchaseId = useSelector(
    state =>
      state.orders &&
      state.orders.orderDetails &&
      state.orders.orderDetails.vehiclePurchaseId
  );

  const dispatch = useDispatch();
  const onFind = (text, container) => dispatch(find(text, container));
  const onCapture = id => dispatch(capture(id));
  const onCancel = () => dispatch(closeModal());
  const onConfirmLocation = location => {
    dispatch(updateDeliveryAddress(vehicleId, vehiclePurchaseId, location));
    dispatch(closeModal());
  };

  useEffect(() => {
    dispatch(clearSelectedAddress());
  }, [dispatch]);

  return (
    <AddressPicker
      onFind={onFind}
      onCapture={onCapture}
      foundLocations={
        addressLookup.searchResult && addressLookup.searchResult.Items
      }
      selectedLocation={
        addressLookup.selectedAddress && addressLookup.selectedAddress.Items
      }
      onCancel={onCancel}
      onConfirmLocation={onConfirmLocation}
    />
  );
};

export default UpdateDeliveryAddressModalInsert;
