import {
  Create,
  Save,
  DeleteSupportingDocument,
  SaveSupportingBlockDocument,
  FetchSupportingDocument,
  FetchVendorCampaigns,
  DeleteCampaign,
  FetchCampaign,
  ApproveCampaign,
  FetchCampaignGroups,
  FetchAuctionCondition,
  FetchAuctionGrade,
  FetchVendorApproveCampaignRights,
  ExpireCampaign,
  CopyCampaign,
  DownloadCampaigns,
  Makes,
  Ranges,
  Trims,
  GetCampaignRangeLines,
  CopySupportingDocumentsDirectory,
} from "../../api/CampaignManagement";

import { updateObject } from "../../shared/utility";
import { openModal } from "./global";
import { USER_TYPE_MAP } from "../../shared/utility";

// Action constants
export const CREATE_CAMPAIGN = "@campaign/CREATE_CAMPAIGN";
export const CREATE_CAMPAIGN_SUCCESS = "@campaign/CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_FAILURE = "@campaign/CREATE_CAMPAIGN_FAILURE";

export const SAVE_CAMPAIGN = "@campaign/SAVE_CAMPAIGN";
export const SAVE_CAMPAIGN_SUCCESS = "@campaign/SAVE_CAMPAIGN_SUCCESS";
export const SAVE_CAMPAIGN_FAILURE = "@campaign/SAVE_CAMPAIGN_FAILURE";

export const DELETE_SUPPORTING_DOCUMENT =
  "@campaign/DELETE_SUPPORTING_DOCUMENT";
export const DELETE_SUPPORTING_DOCUMENT_SUCCESS =
  "@campaign/DELETE_SUPPORTING_DOCUMENT_SUCCESS";
export const DELETE_SUPPORTING_DOCUMENT_FAILURE =
  "@campaign/DELETE_SUPPORTING_DOCUMENT_FAILURE";

export const COPY_SUPPORTING_DOCUMENTS_DIRECTORY =
  "@campaign/COPY_SUPPORTING_DOCUMENTS_DIRECTORY";
export const COPY_SUPPORTING_DOCUMENTS_DIRECTORY_SUCCESS =
  "@campaign/COPY_SUPPORTING_DOCUMENTS_DIRECTORY_SUCCESS";
export const COPY_SUPPORTING_DOCUMENTS_DIRECTORY_FAILURE =
  "@campaign/COPY_SUPPORTING_DOCUMENTS_DIRECTORY_FAILURE";

export const SAVE_SUPPORTING_DOCUMENT = "@campaign/SAVE_SUPPORTING_DOCUMENT";
export const SAVE_SUPPORTING_BLOCK_DOCUMENT =
  "@campaign/SAVE_SUPPORTING_BLOCK_DOCUMENT";
export const SAVE_SUPPORTING_BLOCK_DOCUMENT_SUCCESS =
  "@campaign/SAVE_SUPPORTING_BLOCK_DOCUMENT_SUCCESS";
export const SAVE_SUPPORTING_BLOCK_DOCUMENT_FAILURE =
  "@campaign/SAVE_SUPPORTING_BLOCK_DOCUMENT_FAILURE";

export const FETCH_SUPPORTING_DOCUMENT = "@campaign/FETCH_SUPPORTING_DOCUMENT";
export const FETCH_SUPPORTING_DOCUMENT_FAILURE =
  "@campaign/FETCH_SUPPORTING_DOCUMENT_FAILURE";
export const FETCH_SUPPORTING_DOCUMENT_SUCCESS =
  "@campaign/FETCH_SUPPORTING_DOCUMENT_SUCCESS";
export const SET_FILE_ERROR = "@campaign/SET_FILE_ERROR";

export const FETCH_VENDOR_CAMPAIGNS = "@campaign/FETCH_VENDOR_CAMPAIGNS";
export const FETCH_VENDOR_CAMPAIGNS_SUCCESS =
  "@campaign/FETCH_VENDOR_CAMPAIGNS_SUCCESS";
export const FETCH_VENDOR_CAMPAIGNS_ERROR =
  "@campaign/FETCH_VENDOR_CAMPAIGNS_ERROR";

export const FETCH_CAMPAIGN_GROUPS = "@campaign/FETCH_CAMPAIGN_GROUPS";
export const FETCH_CAMPAIGN_GROUPS_SUCCESS =
  "@campaign/FETCH_CAMPAIGN_GROUPS_SUCCESS";
export const FETCH_CAMPAIGN_GROUPS_ERROR =
  "@campaign/FETCH_CAMPAIGN_GROUPS_ERROR";

export const FETCH_AUCTION_CONDITION = "@campaign/FETCH_AUCTION_CONDITION";
export const FETCH_AUCTION_CONDITION_SUCCESS =
  "@campaign/FETCH_AUCTION_CONDITION_SUCCESS";
export const FETCH_AUCTION_CONDITION_ERROR =
  "@campaign/FETCH_AUCTION_CONDITION_ERROR";

export const FETCH_AUCTION_GRADE = "@campaign/FETCH_AUCTION_GRADE";
export const FETCH_AUCTION_GRADE_SUCCESS =
  "@campaign/FETCH_AUCTION_GRADE_SUCCESS";
export const FETCH_AUCTION_GRADE_ERROR = "@campaign/FETCH_AUCTION_GRADE_ERROR";

export const DELETE_CAMPAIGN = "@campaign/DELETE_CAMPAIGN";
export const DELETE_CAMPAIGN_ERROR = "@campaign/DELETE_CAMPAIGN_ERROR";
export const DELETE_CAMPAIGN_SUCCESS = "@campaign/DELETE_CAMPAIGN_SUCCESS";

export const FETCH_CAMPAIGN = "@campaign/FETCH_CAMPAIGN";
export const FETCH_CAMPAIGN_SUCCESS = "@campaign/FETCH_CAMPAIGN_SUCCESS";
export const FETCH_CAMPAIGN_ERROR = "@campaign/FETCH_CAMPAIGN_ERROR";

export const CLEAR_ACTIVE_CAMPAIGN = "@campaign/CLEAR_ACTIVE_CAMPAIGN";

export const APPROVE_CAMPAIGN = "@campaign/APPROVE_CAMPAIGN";
export const APPROVE_CAMPAIGN_SUCCESS = "@campaign/APPROVE_CAMPAIGN_SUCCESS";
export const APPROVE_CAMPAIGN_ERROR = "@campaign/APPROVE_CAMPAIGN_ERROR";

export const VENDOR_ALLOWED_TO_APPROVE_CAMPAIGN =
  "@campaign/VENDOR_ALLOWED_TO_APPROVE_CAMPAIGN";
export const VENDOR_ALLOWED_TO_APPROVE_CAMPAIGN_SUCCESS =
  "@campaign/VENDOR_ALLOWED_TO_APPROVE_CAMPAIGN_SUCCESS";
export const VENDOR_ALLOWED_TO_APPROVE_CAMPAIGN_ERROR =
  "@campaign/VENDOR_ALLOWED_TO_APPROVE_CAMPAIGN_ERROR";

export const EXPIRE_LIVE_CAMPAIGN = "@campaign/EXPIRE_LIVE_CAMPAIGN";
export const EXPIRE_LIVE_CAMPAIGN_SUCCESS =
  "@campaign/EXPIRE_LIVE_CAMPAIGN_SUCCESS";
export const EXPIRE_LIVE_CAMPAIGN_ERROR =
  "@campaign/EXPIRE_LIVE_CAMPAIGN_ERROR";

export const COPY_CAMPAIGN = "@campaign/COPY_CAMPAIGN";
export const COPY_CAMPAIGN_SUCCESS = "@campaign/COPY_CAMPAIGN_SUCCESS";
export const COPY_CAMPAIGN_ERROR = "@campaign/COPY_CAMPAIGN_ERROR";

export const DOWNLOAD_CAMPAIGNS = "@campaign/DOWNLOAD_CAMPAIGNS";
export const DOWNLOAD_CAMPAIGNS_SUCCESS =
  "@campaign/DOWNLOAD_CAMPAIGNS_SUCCESS";
export const DOWNLOAD_CAMPAIGNS_ERROR = "@campaign/DOWNLOAD_CAMPAIGNS_ERROR";

export const FETCH_CAP = "@cap/FETCH_CAP";
export const FETCH_CAP_SUCCESS = "@cap/FETCH_CAP_SUCCESS";
export const FETCH_CAP_FAILURE = "@cap/FETCH_CAP_FAILURE";

export const FETCH_CAP_MAKE = "@campaign/FETCH_CAP_MAKE";
export const FETCH_CAP_MAKE_SUCCESS = "@campaign/FETCH_CAP_MAKE_SUCCESS";
export const FETCH_CAP_MAKE_FAILURE = "@campaign/FETCH_CAP_MAKE_FAILURE";

export const FETCH_CAP_RANGE = "@campaign/FETCH_CAP_RANGE";
export const FETCH_CAP_RANGE_SUCCESS = "@campaign/FETCH_CAP_RANGE_SUCCESS";
export const FETCH_CAP_RANGE_FAILURE = "@campaign/FETCH_CAP_RANGE_FAILURE";

export const FETCH_CAP_TRIM = "@campaign/FETCH_CAP_TRIM";
export const FETCH_CAP_TRIM_SUCCESS = "@campaign/FETCH_CAP_TRIM_SUCCESS";
export const FETCH_CAP_TRIM_FAILURE = "@campaign/FETCH_CAP_TRIM_FAILURE";

export const SAVE_VEHICLE_SELECTION = "@campaign/SAVE_VEHICLE_SELECTION";
export const SAVE_VEHICLE_SELECTION_SUCCESS =
  "@campaign/SAVE_VEHICLE_SELECTION_SUCCESS";
export const SAVE_VEHICLE_SELECTION_FAILURE =
  "@campaign/SAVE_VEHICLE_SELECTION_FAILURE";

export const GET_CAMPAIGN_RANGE_LINES = "@campaign/GET_CAMPAIGN_RANGE_LINES";
export const GET_CAMPAIGN_RANGE_LINES_SUCCESS =
  "@campaign/GET_CAMPAIGN_RANGE_LINES_SUCCCESS";
export const GET_CAMPAIGN_RANGE_LINES_FAILURE =
  "@campaign/GET_CAMPAIGN_RANGE_LINES_FAILURE";

export const SAVE_SPECIFIC_VEHICLE_SELECTION =
  "@campaign/SAVE_SPECIFIC_VEHICLE_SELECTION";
export const SAVE_SPECIFIC_VEHICLE_SELECTION_SUCCESS =
  "@campaign/SAVE_SPECIFIC_VEHICLE_SELECTION_SUCCCESS";
export const SAVE_SPECIFIC_VEHICLE_SELECTION_FAILURE =
  "@campaign/SAVE_SPECIFIC_VEHICLE_SELECTION_FAILURE";
export const CLEAR_SPECIFIC_VEHICLE_SELECTION =
  "@campaign/CLEAR_SPECIFIC_VEHICLE_SELECTION";

export const SAVE_SPECIFIC_VEHICLE_SELECTION_COPY =
  "@campaign/SAVE_SPECIFIC_VEHICLE_SELECTION_COPY";
export const SAVE_SPECIFIC_VEHICLE_SELECTION_COPY_SUCCESS =
  "@campaign/SAVE_SPECIFIC_VEHICLE_SELECTION_COPY_SUCCCESS";
export const SAVE_SPECIFIC_VEHICLE_SELECTION_COPY_FAILURE =
  "@campaign/SAVE_SPECIFIC_VEHICLE_SELECTION_COPY_FAILURE";

export const createCampaign = () => (dispatch, getState) => {
  dispatch({ type: CREATE_CAMPAIGN });

  const createCampaignInstance = new Create({
    params: {},
    credentials: getState().authentication.credentials,
  });

  createCampaignInstance.call().then(
    (response) => {
      dispatch(createCampaignSuccess(response.data));
    },
    (err) => {
      dispatch(createCampaignFailure(err));
      return dispatch(openModal("generic-error"));
    }
  );
};

export const createCampaignSuccess = (data) => {
  return {
    type: CREATE_CAMPAIGN_SUCCESS,
    payload: data,
  };
};

export const createCampaignFailure = (error) => {
  return {
    type: CREATE_CAMPAIGN_FAILURE,
    payload: error,
  };
};

export const saveCampaign = (data) => (dispatch, getState) => {
  dispatch({ type: SAVE_CAMPAIGN });

  const saveCampaignInstance = new Save({
    params: data,
    credentials: getState().authentication.credentials,
  });

  saveCampaignInstance.call().then(
    (response) => {
      dispatch(
        copySupportingDocumentsDirectory(
          "Campaign",
          USER_TYPE_MAP.Vendor,
          response?.data?.campaignId,
          response?.data?.uniqueSessionIdentifier,
          response?.data?.versionNo,
          true,
          "delete"
        )
      );
      dispatch(saveCampaignSuccess(response));
    },
    (err) => {
      dispatch(saveCampaignFailure(err));
      return dispatch(openModal("generic-error"));
    }
  );
};

export const saveCampaignSuccess = (data) => {
  return {
    type: SAVE_CAMPAIGN_SUCCESS,
    payload: data,
  };
};

export const saveCampaignFailure = (error) => {
  return {
    type: SAVE_CAMPAIGN_FAILURE,
    payload: error,
  };
};

export const deleteSupportingDocument =
  (Id, fileName, userType, Type, sourceFolder) => (dispatch, getState) => {
    dispatch({ type: DELETE_SUPPORTING_DOCUMENT });

    const instance = new DeleteSupportingDocument({
      params: { Id, fileName, userType, Type, sourceFolder },
      credentials: getState().authentication.credentials,
    });

    instance.call().then(
      (response) => {
        dispatch(deleteSupportingDocumentSuccess(response, fileName));
      },
      (err) => {
        return dispatch(deleteSupportingDocumentFailure(err));
      }
    );
  };

export const deleteSupportingDocumentSuccess = (data, fileName) => {
  return {
    type: DELETE_SUPPORTING_DOCUMENT_SUCCESS,
    payload: { data, fileName },
  };
};

export const deleteSupportingDocumentFailure = (error) => {
  return {
    type: DELETE_SUPPORTING_DOCUMENT_FAILURE,
    payload: error,
  };
};

export const saveSupportingDocument = (file, keyValue) => {
  const extendedData = extendSupportingDocument(file, keyValue);
  return {
    type: SAVE_SUPPORTING_DOCUMENT,
    payload: extendedData,
  };
};

export const saveSupportingBlockDocument =
  (data, id, sourceFolder) => (dispatch, getState) => {
    dispatch({ type: SAVE_SUPPORTING_BLOCK_DOCUMENT });
    const instance = new SaveSupportingBlockDocument({
      params: {
        ...data,
        id,
        sourceFolder,
      },
      credentials: getState().authentication.credentials,
    });

    instance.call().then(
      (response) => {
        dispatch(saveSupportingBlockDocumentSuccess(response, data));
      },
      (err) => {
        if (err.toString().includes("403")) {
          dispatch({
            type: SET_FILE_ERROR,
            payload: { fileName: data.fileName, error: err },
          });
        } else {
          return dispatch(saveSupportingBlockDocumentFailure(err));
        }
      }
    );
  };

export const saveSupportingBlockDocumentSuccess = (
  response,
  data,
  fileName
) => {
  return {
    type: SAVE_SUPPORTING_BLOCK_DOCUMENT_SUCCESS,
    payload: { response, data, fileName },
  };
};

export const saveSupportingBlockDocumentFailure = (error) => {
  return {
    type: SAVE_SUPPORTING_BLOCK_DOCUMENT_FAILURE,
    payload: { error },
  };
};

export const fetchSupportingDocuments =
  (Id, userType, type, sourceFolder) => (dispatch, getState) => {
    dispatch({ type: FETCH_SUPPORTING_DOCUMENT });

    const campaignInstance = new FetchSupportingDocument({
      params: {
        Id,
        userType,
        type,
        sourceFolder,
      },
      credentials: getState().authentication.credentials,
    });

    campaignInstance.call().then(
      (response) => {
        response.data.forEach((keyValuePair) => {
          dispatch(saveSupportingDocument(null, keyValuePair));
        });
        dispatch({ type: FETCH_SUPPORTING_DOCUMENT_SUCCESS });
      },
      (err) => {
        dispatch(fetchSupportingDocumentFailure(err));
        return dispatch(openModal("generic-error"));
      }
    );
  };

export const fetchSupportingDocumentFailure = (error) => {
  return {
    type: FETCH_SUPPORTING_DOCUMENT_FAILURE,
    payload: error,
  };
};

const extendSupportingDocument = (file, keyValue) => {
  const blockSize = 1048576 * 4; //it's 4MB
  const addForRemainder = file?.size % blockSize === 0 ? 0 : 1;
  const noOfBlocks = Math.floor(file?.size / blockSize) + addForRemainder;

  return {
    file: file,
    fileName: file?.name ?? keyValue?.key,
    progress: 0,
    beginingOfTheBlock: 0,
    endOfTheBlock: blockSize,
    noOfBlocks: noOfBlocks || 1,
    blockNo: 1,
    uploaded: !!keyValue?.value,
    url: keyValue?.value,
  };
};

export const fetchVendorCampaigns = (bucket) => (dispatch, getState) => {
  dispatch({ type: FETCH_VENDOR_CAMPAIGNS });

  const toolbar = getState().toolbar;
  const pageNumber = getState().pagination.pageNumber;
  const { pageSize } = toolbar;

  if (!bucket) {
    bucket = getState().campaignManagement.currentBucket;
  }

  let params = {
    pageSize,
    pageNumber,
    bucket,
  };

  const campaignsInstance = new FetchVendorCampaigns({
    params,
    credentials: getState().authentication.credentials,
  });

  campaignsInstance.call().then(
    (response) => {
      dispatch(fetchVendorCampaignsSuccess(response));
    },
    (err) => {
      dispatch(fetchVendorCampaignsError(err));
      return dispatch(openModal("generic-error"));
    }
  );
};

export const fetchVendorCampaignsSuccess = (response) => {
  return {
    type: FETCH_VENDOR_CAMPAIGNS_SUCCESS,
    payload: response,
  };
};

export const fetchVendorCampaignsError = (data) => {
  return {
    type: FETCH_VENDOR_CAMPAIGNS_ERROR,
    payload: data,
  };
};

export const deleteCampaign = (campaignId) => (dispatch, getState) => {
  dispatch({ type: DELETE_CAMPAIGN });
  const deleteCampaignsInstance = new DeleteCampaign({
    credentials: getState().authentication.credentials,
    params: {
      campaignId,
    },
  });

  deleteCampaignsInstance
    .call()
    .then((response) => {
      dispatch(deleteCampaignSuccess(response, campaignId));
    })
    .catch((err) => {
      dispatch(deleteCampaignError(err));
      return dispatch(openModal("generic-error"));
    });
};

export const deleteCampaignSuccess = (data, campaignId) => {
  return {
    type: DELETE_CAMPAIGN_SUCCESS,
    payload: { data, campaignId },
  };
};

export const deleteCampaignError = (data) => {
  return {
    type: DELETE_CAMPAIGN_ERROR,
    payload: data,
  };
};

export const fetchCampaignGroups = (campaignId) => (dispatch, getState) => {
  dispatch({ type: FETCH_CAMPAIGN_GROUPS });

  const fetchCampaignGroupsInstance = new FetchCampaignGroups({
    params: {
      campaignId,
    },
    credentials: getState().authentication.credentials,
  });

  fetchCampaignGroupsInstance.call().then(
    (response) => {
      dispatch(fetchCampaignGroupsSuccess(response));
    },
    (err) => {
      dispatch(fetchCampaignGroupsError(err));
      return dispatch(openModal("generic-error"));
    }
  );
};

export const fetchCampaignGroupsSuccess = (data) => {
  return {
    type: FETCH_CAMPAIGN_GROUPS_SUCCESS,
    payload: data,
  };
};

export const fetchCampaignGroupsError = (data) => {
  return {
    type: FETCH_CAMPAIGN_GROUPS_ERROR,
    payload: data,
  };
};

export const fetchAuctionCondition = (campaignId) => (dispatch, getState) => {
  dispatch({ type: FETCH_AUCTION_CONDITION });

  const fetchAuctionConditionInstance = new FetchAuctionCondition({
    params: {
      campaignId,
    },
    credentials: getState().authentication.credentials,
  });

  fetchAuctionConditionInstance.call().then(
    (response) => {
      dispatch(fetchAuctionConditionSuccess(response));
    },
    (err) => {
      dispatch(fetchAuctionConditionError(err));
      return dispatch(openModal("generic-error"));
    }
  );
};

export const fetchAuctionConditionSuccess = (data) => {
  return {
    type: FETCH_AUCTION_CONDITION_SUCCESS,
    payload: data,
  };
};

export const fetchAuctionConditionError = (data) => {
  return {
    type: FETCH_AUCTION_CONDITION_ERROR,
    payload: data,
  };
};

export const fetchAuctionGrade = (campaignId) => (dispatch, getState) => {
  dispatch({ type: FETCH_AUCTION_GRADE });

  const fetchAuctionGradeInstance = new FetchAuctionGrade({
    params: {
      campaignId,
    },
    credentials: getState().authentication.credentials,
  });

  fetchAuctionGradeInstance.call().then(
    (response) => {
      dispatch(fetchAuctionGradeSuccess(response));
    },
    (err) => {
      dispatch(fetchAuctionGradeError(err));
      return dispatch(openModal("generic-error"));
    }
  );
};

export const fetchAuctionGradeSuccess = (data) => {
  return {
    type: FETCH_AUCTION_GRADE_SUCCESS,
    payload: data,
  };
};

export const fetchAuctionGradeError = (data) => {
  return {
    type: FETCH_AUCTION_GRADE_ERROR,
    payload: data,
  };
};

export const fetchCampaign = (campaignId) => (dispatch, getState) => {
  dispatch({ type: FETCH_CAMPAIGN });

  const fetchCampaignsInstance = new FetchCampaign({
    params: {
      campaignId,
    },
    credentials: getState().authentication.credentials,
  });

  fetchCampaignsInstance.call().then(
    (response) => {
      dispatch(fetchCampaignSuccess(response));
      dispatch(
        copySupportingDocumentsDirectory(
          "Campaign",
          USER_TYPE_MAP.Vendor,
          campaignId,
          response.data?.versionNo,
          response.data?.uniqueSessionIdentifier,
          false,
          "fetch"
        )
      );
    },
    (err) => {
      dispatch(fetchCampaignError(err));
      return dispatch(openModal("generic-error"));
    }
  );
};

export const fetchCampaignSuccess = (data) => {
  return {
    type: FETCH_CAMPAIGN_SUCCESS,
    payload: data,
  };
};

export const fetchCampaignError = (data) => {
  return {
    type: FETCH_CAMPAIGN_ERROR,
    payload: data,
  };
};

export const clearActiveCampaign = (Id, sourceFolder) => (dispatch) => {
  if (Id && sourceFolder) {
    dispatch(
      deleteSupportingDocument(
        Id,
        "",
        USER_TYPE_MAP.Vendor,
        "Campaign",
        sourceFolder
      )
    );
  }

  return dispatch({ type: CLEAR_ACTIVE_CAMPAIGN });
};

export const approveCampaign = (campaignId) => (dispatch, getState) => {
  dispatch({ type: APPROVE_CAMPAIGN });

  const approveCampaignInstance = new ApproveCampaign({
    params: {
      campaignId,
    },
    credentials: getState().authentication.credentials,
  });

  approveCampaignInstance.call().then(
    (response) => {
      dispatch(approveCampaignSuccess(response));
    },
    (err) => {
      dispatch(approveCampaignError(err));
      return dispatch(openModal("generic-error"));
    }
  );
};

export const approveCampaignSuccess = (data) => {
  return {
    type: APPROVE_CAMPAIGN_SUCCESS,
    payload: data,
  };
};

export const approveCampaignError = (data) => {
  return {
    type: APPROVE_CAMPAIGN_ERROR,
    payload: data,
  };
};

export const fetchVendorApproveCampaignRights = () => (dispatch, getState) => {
  dispatch({ type: VENDOR_ALLOWED_TO_APPROVE_CAMPAIGN });

  const fetchVendorApproveCampaignRightsInstance =
    new FetchVendorApproveCampaignRights({
      credentials: getState().authentication.credentials,
    });

  fetchVendorApproveCampaignRightsInstance.call().then(
    (response) => {
      return dispatch(fetchVendorApproveCampaignRightsSuccess(response.data));
    },
    (err) => {
      return dispatch(fetchVendorApproveCampaignRightsError(err));
    }
  );
};

export const fetchVendorApproveCampaignRightsSuccess = (data) => {
  return {
    type: VENDOR_ALLOWED_TO_APPROVE_CAMPAIGN_SUCCESS,
    payload: data,
  };
};

export const fetchVendorApproveCampaignRightsError = (error) => {
  return {
    type: VENDOR_ALLOWED_TO_APPROVE_CAMPAIGN_ERROR,
    payload: error,
  };
};

export const expireCampaign = (campaignId) => (dispatch, getState) => {
  dispatch({ type: EXPIRE_LIVE_CAMPAIGN });
  const expireCampaignsInstance = new ExpireCampaign({
    credentials: getState().authentication.credentials,
    params: {
      campaignId,
    },
  });

  expireCampaignsInstance
    .call()
    .then((response) => {
      dispatch(expireCampaignSuccess(response, campaignId));
    })
    .catch((err) => {
      dispatch(expireCampaignError(err));
      return dispatch(openModal("generic-error"));
    });
};

export const expireCampaignSuccess = (data, campaignId) => {
  return {
    type: EXPIRE_LIVE_CAMPAIGN_SUCCESS,
    payload: { data, campaignId },
  };
};

export const expireCampaignError = (data) => {
  return {
    type: EXPIRE_LIVE_CAMPAIGN_ERROR,
    payload: data,
  };
};

export const copyCampaign = (campaignId) => (dispatch, getState) => {
  dispatch({ type: COPY_CAMPAIGN });
  const copyCampaignsInstance = new CopyCampaign({
    credentials: getState().authentication.credentials,
    params: {
      campaignId,
    },
  });

  copyCampaignsInstance
    .call()
    .then((response) => {
      dispatch(copyCampaignSuccess(response, campaignId));
    })
    .catch((err) => {
      dispatch(copyCampaignError(err));
      return dispatch(openModal("generic-error"));
    });
};

export const copyCampaignSuccess = (data, campaignId) => {
  return {
    type: COPY_CAMPAIGN_SUCCESS,
    payload: { data, campaignId },
  };
};

export const copyCampaignError = (data) => {
  return {
    type: COPY_CAMPAIGN_ERROR,
    payload: data,
  };
};

export const downloadCampaigns = () => (dispatch, getState) => {
  dispatch({ type: DOWNLOAD_CAMPAIGNS });
  const downloadInstance = new DownloadCampaigns({
    credentials: getState().authentication.credentials,
  });
  downloadInstance.call().then(
    (response) => {
      dispatch(downloadCampaignsSuccess());
    },
    (err) => {
      return dispatch(downloadCampaignsFailure(err));
    }
  );
};
export const downloadCampaignsSuccess = () => {
  return {
    type: DOWNLOAD_CAMPAIGNS_SUCCESS,
    payload: true,
  };
};
export const downloadCampaignsFailure = (error) => {
  return {
    type: DOWNLOAD_CAMPAIGNS_ERROR,
    payload: error,
  };
};

export const fetchCapMake = (campaignRangeId, key) => (dispatch, getState) => {
  dispatch({ type: FETCH_CAP_MAKE });

  const createCapMakeInstance = new Makes({
    params: { campaignRangeId, key },
    credentials: getState().authentication.credentials,
  });

  createCapMakeInstance.call().then(
    (response) => {
      dispatch(fetchCapMakeSuccess(response));
    },
    (err) => {
      dispatch(fetchCapMakeFailure(err));
      return dispatch(openModal("generic-error"));
    }
  );
};

export const fetchCapMakeSuccess = (data) => {
  return {
    type: FETCH_CAP_MAKE_SUCCESS,
    payload: data,
  };
};

export const fetchCapMakeFailure = (error) => {
  return {
    type: FETCH_CAP_MAKE_FAILURE,
    payload: error,
  };
};

export const fetchCapRange = (campaignRangeId, key) => (dispatch, getState) => {
  dispatch({ type: FETCH_CAP_RANGE });

  const createCapRangeInstance = new Ranges({
    params: { campaignRangeId, key },
    credentials: getState().authentication.credentials,
  });

  createCapRangeInstance.call().then(
    (response) => {
      dispatch(fetchCapRangeSuccess(response));
    },
    (err) => {
      dispatch(fetchCapRangeFailure(err));
      return dispatch(openModal("generic-error"));
    }
  );
};

export const fetchCapRangeSuccess = (data) => {
  return {
    type: FETCH_CAP_RANGE_SUCCESS,
    payload: data,
  };
};

export const fetchCapRangeFailure = (error) => {
  return {
    type: FETCH_CAP_RANGE_FAILURE,
    payload: error,
  };
};

export const fetchCapTrim =
  (campaignRangeId, makeId, rangeIds) => (dispatch, getState) => {
    dispatch({ type: FETCH_CAP_TRIM });

    const createCapTrimInstance = new Trims({
      params: { campaignRangeId, makeId, rangeIds },
      credentials: getState().authentication.credentials,
    });

    createCapTrimInstance.call().then(
      (response) => {
        dispatch(fetchCapTrimSuccess(response));
      },
      (err) => {
        dispatch(fetchCapTrimFailure(err));
        return dispatch(openModal("generic-error"));
      }
    );
  };

export const fetchCapTrimSuccess = (data) => {
  return {
    type: FETCH_CAP_TRIM_SUCCESS,
    payload: data,
  };
};

export const fetchCapTrimFailure = (error) => {
  return {
    type: FETCH_CAP_TRIM_FAILURE,
    payload: error,
  };
};

export const saveVehicleSelection = (data) => (dispatch) => {
  dispatch({ type: SAVE_VEHICLE_SELECTION });
  dispatch(saveVehicleSelectionSuccess(data));
};

export const saveVehicleSelectionSuccess = (data) => {
  return {
    type: SAVE_VEHICLE_SELECTION_SUCCESS,
    payload: data,
  };
};

export const saveVehicleSelectionFailure = (error) => {
  return {
    type: SAVE_VEHICLE_SELECTION_FAILURE,
    payload: error,
  };
};

export const getCampaignRangeLines = (campaignId) => (dispatch, getState) => {
  dispatch({ type: GET_CAMPAIGN_RANGE_LINES });

  const createCapTrimInstance = new GetCampaignRangeLines({
    params: { campaignId },
    credentials: getState().authentication.credentials,
  });

  createCapTrimInstance.call().then(
    (response) => {
      dispatch(getCampaignRangeLinesSuccess(response));
    },
    (err) => {
      dispatch(getCampaignRangeLinesFailure(err));
      return dispatch(openModal("generic-error"));
    }
  );
};

export const getCampaignRangeLinesSuccess = (data) => {
  return {
    type: GET_CAMPAIGN_RANGE_LINES_SUCCESS,
    payload: data,
  };
};

export const getCampaignRangeLinesFailure = (error) => {
  return {
    type: GET_CAMPAIGN_RANGE_LINES_FAILURE,
    payload: error,
  };
};

export const saveSpecificVehicleSelection = (data) => (dispatch) => {
  dispatch({ type: SAVE_SPECIFIC_VEHICLE_SELECTION });
  dispatch(saveSpecificVehicleSelectionSuccess(data));
};

export const saveSpecificVehicleSelectionSuccess = (data) => {
  return {
    type: SAVE_SPECIFIC_VEHICLE_SELECTION_SUCCESS,
    payload: data,
  };
};

export const saveSpecificVehicleSelectionFailure = (error) => {
  return {
    type: SAVE_SPECIFIC_VEHICLE_SELECTION_FAILURE,
    payload: error,
  };
};

export const saveSpecificVehicleSelectionCopy = (data) => (dispatch) => {
  dispatch({ type: SAVE_SPECIFIC_VEHICLE_SELECTION_COPY });
  dispatch(saveSpecificVehicleSelectionCopySuccess(data));
};

export const saveSpecificVehicleSelectionCopySuccess = (data) => {
  return {
    type: SAVE_SPECIFIC_VEHICLE_SELECTION_COPY_SUCCESS,
    payload: data,
  };
};

export const saveSpecificVehicleSelectionCopyFailure = (error) => {
  return {
    type: SAVE_SPECIFIC_VEHICLE_SELECTION_COPY_FAILURE,
    payload: error,
  };
};

export const clearSpecificVehicleSelection = () => {
  return {
    type: CLEAR_SPECIFIC_VEHICLE_SELECTION,
  };
};

export const copySupportingDocumentsDirectory =
  (
    Type,
    UserType,
    Id,
    SourceFolder,
    DestinationFolder,
    ShouldSetHasSupportingDocuments,
    FetchOrDelete
  ) =>
  (dispatch, getState) => {
    dispatch({ type: COPY_SUPPORTING_DOCUMENTS_DIRECTORY });

    const instance = new CopySupportingDocumentsDirectory({
      params: {
        Type,
        UserType,
        Id,
        SourceFolder,
        DestinationFolder,
        ShouldSetHasSupportingDocuments,
      },
      credentials: getState().authentication.credentials,
    });

    instance.call().then(
      (response) => {
        dispatch(copySupportingDocumentsDirectorySuccess(response));
        if (FetchOrDelete === "fetch") {
          dispatch(
            fetchSupportingDocuments(Id, UserType, Type, DestinationFolder)
          );
        } else if (FetchOrDelete === "delete") {
          dispatch(
            deleteSupportingDocument(Id, "", UserType, Type, SourceFolder)
          );
        }
      },
      (err) => {
        return dispatch(copySupportingDocumentsDirectoryFailure(err));
      }
    );
  };

export const copySupportingDocumentsDirectorySuccess = (data) => {
  return {
    type: COPY_SUPPORTING_DOCUMENTS_DIRECTORY_SUCCESS,
    payload: { data },
  };
};

export const copySupportingDocumentsDirectoryFailure = (error) => {
  return {
    type: COPY_SUPPORTING_DOCUMENTS_DIRECTORY_FAILURE,
    payload: error,
  };
};

const initialState = {
  isFetching: false,
  isUpdating: false,
  isDeletingCampaign: false,
  isApprovingCampaign: false,
  isFetchingVendorApprovalStatus: false,
  isExpiringCampaign: false,
  isCopyingCampaign: false,
  isDownloading: false,
  isFetchingCapMake: false,
  isFetchingCapRange: false,
  isFetchingCapTrim: false,
  isFetchingCampaignRangeLines: false,
  error: false,
  currentBucket: "new",
  totalCount: 0,
  currentPage: 1,
  pageSize: 25,
  pageCount: 0,
  data: {
    campaigns: [],
  },
  activeCampaign: {
    isFetching: false,
    isUpdating: false,
    isDeletingSupportingDocument: false,
    isCopyingSupportingDocumentDirectory: false,
    isFetchingSupportingDocument: false,
    isFetchingCampaignGroups: false,
    isFetchingAuctionGrade: false,
    isFetchingAuctionCondition: false,
    error: false,
    campaignGroups: [],
    auctionGrades: [],
    auctionConditions: [],
    campaignUrls: [],
    capMake: [],
    capRange: [],
    capTrim: [],
    chosenMake: [],
    chosenRange: [],
    chosenTrim: [],
    campaignRangeLines: [],
    specificVehicleSelection: [],
    specificVehicleSelectionCopy: [],
    data: {
      vendorId: null,
      campaignId: null,
      campaignName: "",
      campaignDescription: "",
      campaignReference: "",
      campaignStatus: "",
      fromDate: null,
      toDate: null,
      fromDay: null,
      toDay: null,
      datesVisible: null,
      dateModified: null,
      modifiedBy: null,
      fromVehicleAgeMonths: null,
      toVehicleAgeMonths: null,
      toMileage: null,
      fromMileage: null,
      approvedDate: null,
      approvedBy: null,
      versionNo: null,
      uniqueSessionIdentifier: "",
    },
    options: {
      campaignDocuments: [],
    },
  },
  meta: {
    isVendorAllowedToApproveCampaign: false,
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CAMPAIGN:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isFetching: true,
        },
      };
    case CREATE_CAMPAIGN_SUCCESS:
      const uniqueSessionIdentifier = action.payload.uniqueSessionIdentifier;
      const campaignId = action.payload.campaignId;
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isFetching: false,
          data: {
            ...state.activeCampaign.data,
            campaignId,
            uniqueSessionIdentifier,
          },
        },
        data:
          state.data?.length > 0
            ? [...state.data.campaign, action.payload.campaign]
            : action.payload.campaign,
      };
    case CREATE_CAMPAIGN_FAILURE:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isFetching: false,
          error: action.payload,
        },
      };
    case SAVE_CAMPAIGN:
      return {
        ...state,
        isUpdating: true,
        activeCampaign: {
          ...state.activeCampaign,
          data: action.payload,
          campaignGroups: [],
          isUpdating: true,
        },
      };
    case SAVE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        activeCampaign: {
          ...state.activeCampaign,
          data: action.payload,
          isUpdating: false,
        },
      };
    case SAVE_CAMPAIGN_FAILURE:
      return {
        ...state,
        isUpdating: false,
        activeCampaign: {
          ...state.activeCampaign,
          isUpdating: false,
          error: action.payload,
        },
      };
    case SAVE_SUPPORTING_DOCUMENT:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          options: {
            ...state.activeCampaign?.options,
            campaignDocuments: [
              ...(state.activeCampaign?.options?.campaignDocuments || []),
              action.payload,
            ],
          },
        },
      };
    case DELETE_SUPPORTING_DOCUMENT:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isDeletingSupportingDocument: true,
        },
      };
    case DELETE_SUPPORTING_DOCUMENT_SUCCESS:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isDeletingSupportingDocument: false,
          options: {
            ...state.activeCampaign.options,
            campaignDocuments:
              state.activeCampaign.options?.campaignDocuments?.filter(
                (document) => document.fileName !== action.payload.fileName
              ),
          },
        },
      };
    case DELETE_SUPPORTING_DOCUMENT_FAILURE:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isDeletingSupportingDocument: false,
        },
      };
    case SAVE_SUPPORTING_BLOCK_DOCUMENT:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isUpdating: true,
        },
      };
    case SAVE_SUPPORTING_BLOCK_DOCUMENT_SUCCESS:
      return {
        ...state,
        error: false,
        activeCampaign: {
          ...state.activeCampaign,
          isUpdating: false,
          options: {
            ...state.activeCampaign.options,
            campaignDocuments:
              state.activeCampaign.options?.campaignDocuments?.map((document) =>
                document.fileName === action.payload.data.fileName
                  ? {
                      ...document,
                      beginingOfTheBlock:
                        document.beginingOfTheBlock +
                        action.payload.data.blockSize,
                      endOfTheBlock:
                        document.endOfTheBlock + action.payload.data.blockSize,
                      blockNo: document.blockNo + 1,
                      progress: action.payload.data.percentage,
                      uploaded: action.payload.data.finalBlock,
                      url: action.payload.response?.data?.value,
                      duplicateDocument: false,
                    }
                  : document
              ),
          },
        },
      };
    case SET_FILE_ERROR:
      return {
        ...state,
        error: false,
        activeCampaign: {
          ...state.activeCampaign,
          isUpdating: false,
          options: {
            ...state.activeCampaign.options,
            campaignDocuments:
              state.activeCampaign.options?.campaignDocuments?.map((document) =>
                document.fileName === action.payload.fileName
                  ? {
                      ...document,
                      duplicateDocument: true,
                    }
                  : document
              ),
          },
        },
      };
    case SAVE_SUPPORTING_BLOCK_DOCUMENT_FAILURE:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isUpdating: false,
        },
      };
    case FETCH_SUPPORTING_DOCUMENT:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isFetchingSupportingDocument: true,
        },
      };
    case FETCH_SUPPORTING_DOCUMENT_FAILURE:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isFetchingSupportingDocument: false,
        },
      };
    case FETCH_SUPPORTING_DOCUMENT_SUCCESS:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isFetchingSupportingDocument: false,
        },
      };
    case FETCH_VENDOR_CAMPAIGNS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_VENDOR_CAMPAIGNS_SUCCESS:
      return updateObject(state, {
        isFetching: false,
        data: {
          ...state.data,
          campaigns: action.payload.campaigns,
        },
        totalCount: action.payload.totalCount,
        currentPage: action.payload.currentPage,
        pageSize: action.payload.pageSize,
        pageCount: action.payload.pageCount,
        currentBucket: action.payload.currentBucket,
      });
    case FETCH_VENDOR_CAMPAIGNS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };

    case DELETE_CAMPAIGN:
      return {
        ...state,
        isDeletingCampaign: true,
      };
    case DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isDeletingCampaign: false,
        data: {
          ...state.data,
          campaigns: [
            ...state.data.campaigns.filter(
              (campaign) => campaign.campaignId !== action.payload.campaignId
            ),
          ],
        },
      };
    case DELETE_CAMPAIGN_ERROR:
      return {
        ...state,
        isDeletingCampaign: false,
        error: action.payload,
      };
    case FETCH_CAMPAIGN:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          campaignGroups: [],
          isFetching: true,
        },
      };
    case FETCH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isFetching: false,
          data: action.payload?.data,
        },
      };
    case FETCH_CAMPAIGN_ERROR:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isFetching: false,
          error: action.payload,
        },
      };
    case CLEAR_ACTIVE_CAMPAIGN:
      return {
        ...state,
        isFetching: false,
        isUpdating: false,
        isDeletingCampaign: false,
        isApprovingCampaign: false,
        isFetchingVendorApprovalStatus: false,
        isExpiringCampaign: false,
        isCopyingCampaign: false,
        isDownloading: false,
        activeCampaign: {
          ...state.activeCampaign,
          chosenMake: [],
          chosenRange: [],
          chosenTrim: [],
          campaignRangeLines: [],
          specificVehicleSelection: [],
          data: [],
          isFetching: false,
          options: {
            campaignDocuments: [],
          },
        },
      };

    case FETCH_CAMPAIGN_GROUPS:
      return {
        ...state,
        isFetchingCampaignGroups: true,
      };
    case FETCH_CAMPAIGN_GROUPS_SUCCESS:
      return {
        ...state,
        isFetchingCampaignGroups: false,
        activeCampaign: {
          ...state.activeCampaign,
          campaignGroups: action.payload?.data,
        },
      };
    case FETCH_CAMPAIGN_GROUPS_ERROR:
      return {
        ...state,
        isFetchingCampaignGroups: false,
        activeCampaign: {
          ...state.activeCampaign,
          error: action.payload,
        },
      };

    case FETCH_AUCTION_GRADE:
      return {
        ...state,
        isFetchingAuctionGrade: true,
      };
    case FETCH_AUCTION_GRADE_SUCCESS:
      return {
        ...state,
        isFetchingAuctionGrade: false,
        activeCampaign: {
          ...state.activeCampaign,
          auctionGrades: action.payload?.data,
        },
      };
    case FETCH_AUCTION_GRADE_ERROR:
      return {
        ...state,
        isFetchingAuctionGrade: false,
        activeCampaign: {
          ...state.activeCampaign,
          error: action.payload,
        },
      };

    case FETCH_AUCTION_CONDITION:
      return {
        ...state,
        isFetchingAuctionCondition: true,
      };
    case FETCH_AUCTION_CONDITION_SUCCESS:
      return {
        ...state,
        isFetchingAuctionCondition: false,
        activeCampaign: {
          ...state.activeCampaign,
          auctionConditions: action.payload?.data,
        },
      };
    case FETCH_AUCTION_CONDITION_ERROR:
      return {
        ...state,
        isFetchingAuctionCondition: false,
        activeCampaign: {
          ...state.activeCampaign,
          error: action.payload,
        },
      };

    case APPROVE_CAMPAIGN:
      return {
        ...state,
        isApprovingCampaign: true,
      };
    case APPROVE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isApprovingCampaign: false,
        data: {
          ...state.data,
          campaigns: [
            ...state.data.campaigns.filter(
              (campaign) => campaign.campaignId !== action.payload.campaignId
            ),
          ],
        },
      };
    case APPROVE_CAMPAIGN_ERROR:
      return {
        ...state,
        isApprovingCampaign: false,
        error: action.payload,
      };
    case VENDOR_ALLOWED_TO_APPROVE_CAMPAIGN:
      return {
        ...state,
        isFetchingVendorApprovalStatus: true,
      };
    case VENDOR_ALLOWED_TO_APPROVE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isFetchingVendorApprovalStatus: false,
        meta: {
          ...state.meta,
          isVendorAllowedToApproveCampaign: action.payload,
        },
      };
    case VENDOR_ALLOWED_TO_APPROVE_CAMPAIGN_ERROR:
      return {
        ...state,
        isFetchingVendorApprovalStatus: false,
        error: action.payload,
      };

    case EXPIRE_LIVE_CAMPAIGN:
      return {
        ...state,
        isExpiringCampaign: true,
      };
    case EXPIRE_LIVE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isExpiringCampaign: false,
        data: {
          ...state.data,
          campaigns: [
            ...state.data.campaigns.filter(
              (campaign) => campaign.campaignId !== action.payload.campaignId
            ),
          ],
        },
      };
    case EXPIRE_LIVE_CAMPAIGN_ERROR:
      return {
        ...state,
        isExpiringCampaign: false,
        error: action.payload,
      };

    case COPY_CAMPAIGN:
      return {
        ...state,
        isCopyingCampaign: true,
        activeCampaign: {
          ...state.activeCampaign,
          campaignGroups: [],
          isFetching: true,
        },
      };
    case COPY_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isCopyingCampaign: false,
        activeCampaign: {
          ...state.activeCampaign,
          isFetching: false,
          data: action.payload?.data,
        },
      };
    case COPY_CAMPAIGN_ERROR:
      return {
        ...state,
        isCopyingCampaign: false,
        activeCampaign: {
          ...state.activeCampaign,
          isFetching: false,
          error: action.payload,
        },
      };
    case DOWNLOAD_CAMPAIGNS:
      return {
        ...state,
        isDownloading: true,
      };
    case DOWNLOAD_CAMPAIGNS_ERROR:
      return {
        ...state,
        isDownloading: false,
        error: action.payload,
      };
    case DOWNLOAD_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        isDownloading: false,
        success: action.payload,
      };

    case FETCH_CAP_MAKE:
      return {
        ...state,
        isFetchingCapMake: true,
      };
    case FETCH_CAP_MAKE_SUCCESS:
      return {
        ...state,
        isFetchingCapMake: false,
        activeCampaign: {
          ...state.activeCampaign,
          capMake: action.payload?.data,
        },
      };
    case FETCH_CAP_MAKE_FAILURE:
      return {
        ...state,
        isFetchingCapMake: false,
        activeCampaign: {
          ...state.activeCampaign,
          error: action.payload,
        },
      };

    case FETCH_CAP_RANGE:
      return {
        ...state,
        isFetchingCapRange: true,
      };
    case FETCH_CAP_RANGE_SUCCESS:
      return {
        ...state,
        isFetchingCapRange: false,
        activeCampaign: {
          ...state.activeCampaign,
          capRange: action.payload?.data,
        },
      };
    case FETCH_CAP_RANGE_FAILURE:
      return {
        ...state,
        isFetchingCapRange: false,
        activeCampaign: {
          ...state.activeCampaign,
          error: action.payload,
        },
      };

    case FETCH_CAP_TRIM:
      return {
        ...state,
        isFetchingCapTrim: true,
      };
    case FETCH_CAP_TRIM_SUCCESS:
      return {
        ...state,
        isFetchingCapTrim: false,
        activeCampaign: {
          ...state.activeCampaign,
          capTrim: action.payload?.data,
        },
      };
    case FETCH_CAP_TRIM_FAILURE:
      return {
        ...state,
        isFetchingCapTrim: false,
        activeCampaign: {
          ...state.activeCampaign,
          error: action.payload,
        },
      };
    case SAVE_VEHICLE_SELECTION:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isUpdating: true,
        },
      };
    case SAVE_VEHICLE_SELECTION_SUCCESS:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isUpdating: false,
          chosenMake: action.payload.chosenMake,
          chosenRange: action.payload.chosenRange,
          chosenTrim: action.payload.chosenTrim,
        },
      };
    case SAVE_VEHICLE_SELECTION_FAILURE:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isUpdating: false,
          error: action.payload,
        },
      };
    case GET_CAMPAIGN_RANGE_LINES:
      return {
        ...state,
        isFetchingCampaignRangeLines: true,
      };
    case GET_CAMPAIGN_RANGE_LINES_SUCCESS:
      return {
        ...state,
        isFetchingCampaignRangeLines: false,
        activeCampaign: {
          ...state.activeCampaign,
          campaignRangeLines: action.payload?.data,
        },
      };
    case GET_CAMPAIGN_RANGE_LINES_FAILURE:
      return {
        ...state,
        isFetchingCampaignRangeLines: false,
        activeCampaign: {
          ...state.activeCampaign,
          error: action.payload,
        },
      };

    case SAVE_SPECIFIC_VEHICLE_SELECTION:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isUpdating: true,
        },
      };
    case SAVE_SPECIFIC_VEHICLE_SELECTION_SUCCESS:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isUpdating: false,
          specificVehicleSelection: action.payload,
        },
      };
    case SAVE_SPECIFIC_VEHICLE_SELECTION_FAILURE:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isUpdating: false,
          error: action.payload,
        },
      };
    case CLEAR_SPECIFIC_VEHICLE_SELECTION:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          specificVehicleSelection: [],
        },
      };

    case SAVE_SPECIFIC_VEHICLE_SELECTION_COPY:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isUpdating: true,
        },
      };
    case SAVE_SPECIFIC_VEHICLE_SELECTION_COPY_SUCCESS:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isUpdating: false,
          specificVehicleSelectionCopy: action.payload,
        },
      };
    case SAVE_SPECIFIC_VEHICLE_SELECTION_COPY_FAILURE:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isUpdating: false,
          error: action.payload,
        },
      };
    case COPY_SUPPORTING_DOCUMENTS_DIRECTORY:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isCopyingSupportingDocumentDirectory: true,
        },
      };
    case COPY_SUPPORTING_DOCUMENTS_DIRECTORY_SUCCESS:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isCopyingSupportingDocumentDirectory: false,
        },
      };
    case COPY_SUPPORTING_DOCUMENTS_DIRECTORY_FAILURE:
      return {
        ...state,
        activeCampaign: {
          ...state.activeCampaign,
          isCopyingSupportingDocumentDirectory: false,
        },
      };
    default:
      return state;
  }
};

export default reducer;
