import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import { useNavigate } from "react-router";
import ModalInsert from "../../components/ModalInsert";
import Panel from "../../components/Panel";
import ClaimSubmissionForm from "../../components/ClaimSubmissionForm";
import { submitClaim } from "./../../../state/reducers/claims";

const ClaimSubmissionModalInsert = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onCancel = () => dispatch(closeModal());
  const [buyerNotes, setBuyerNotes] = useState("");

  const claim = useSelector((state) => state.claims.activeClaim.data);

  const claimData = {
    buyerNotes,
    claimDetails: { ...claim },
  };
  const onSubmitClaim = () => {
    dispatch(submitClaim(claimData));
    navigate(`/claims`);
  };

  return (
    <Fragment>
      <ModalInsert>
        <Panel
          mobileFlex
          style={{ height: "100%", borderRadius: "0.5rem" }}
          actions={[
            {
              content: "Close",
              action: onCancel,
              type: "ghost",
            },
            {
              content: "Submit",
              action: onSubmitClaim,
              type: "primary",
            },
          ]}
        >
          <ClaimSubmissionForm
            buyerNotes={buyerNotes}
            setBuyerNotes={setBuyerNotes}
          />
        </Panel>
      </ModalInsert>
    </Fragment>
  );
};

export default ClaimSubmissionModalInsert;
