import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import HeaderText from "../HeaderText";
import ParagraphText from "../ParagraphText";
import Panel from "../Panel";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../theme/breakpoints";
import useIsLoggedInUserType from "../../../shared/hooks/useIsLoggedInUserType";
import { USER_TYPE_MAP } from "../../../shared/utility";
import * as CONST from "./const";

const KeyboardShortcuts = ({ className, qaClassName, onClose }) => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  const isUserBuyer = useIsLoggedInUserType(USER_TYPE_MAP.Buyer);
  const isUserVendor = useIsLoggedInUserType(USER_TYPE_MAP.Vendor);

  const mapOfBuyerShortcuts = {
    f: CONST.KEYBOARD_SHORTCUTS.FIND_VEHICLE,
    m: CONST.KEYBOARD_SHORTCUTS.MESSAGES,
    s: CONST.KEYBOARD_SHORTCUTS.SHORTLIST,
    b: CONST.KEYBOARD_SHORTCUTS.BIDS_REQUESTS,
    p: CONST.KEYBOARD_SHORTCUTS.PURCHASES,
    c: CONST.KEYBOARD_SHORTCUTS.CLAIMS,
    a: CONST.KEYBOARD_SHORTCUTS.ACCOUNT_ADMIN,
    n: CONST.KEYBOARD_SHORTCUTS.NOTIFICATION_PREFERENCES,
    d: CONST.KEYBOARD_SHORTCUTS.STOCKLIST_DOWNLOAD,
    i: CONST.KEYBOARD_SHORTCUTS.INVOICES_CREDITS,
    v: CONST.KEYBOARD_SHORTCUTS.ACTIVITY_LOG,
    h: CONST.KEYBOARD_SHORTCUTS.HELP_CENTER,
    w: CONST.KEYBOARD_SHORTCUTS.CHANGE_PASSWORD,
  };

  const mapOfVendorShortcuts = {
    // none yet
  };

  const mapOfGenericShortcuts = {
    z: CONST.KEYBOARD_SHORTCUTS.ABOUT,
    l: CONST.KEYBOARD_SHORTCUTS.LOGOUT,
  };

  const mapOfShortcuts = isUserBuyer
    ? { ...mapOfBuyerShortcuts, ...mapOfGenericShortcuts }
    : isUserVendor
      ? { ...mapOfVendorShortcuts, ...mapOfGenericShortcuts }
      : {};

  const shortcutsAvailableToUser = isUserBuyer || isUserVendor;

  return (
    <div className={classnames(className, qaClassName)}>
      <Panel flex="1" padding={isDesktop ? "NONE" : "REGULAR"}>
        <div className="modal-header">
          <HeaderText>{CONST.HEADER_TEXT}</HeaderText>
        </div>
      </Panel>
      {shortcutsAvailableToUser && (
        <Panel flex="1" padding={isDesktop ? "NONE" : "REGULAR"}>
          <div className="modal-content keyboard-shortcuts-content">
            <ParagraphText>
              {CONST.KEYBOARD_SHORTCUTS.OPEN_SHORTCUTS}
              <span className="keyboard-key">?</span>
              <span className="float-text">{"or"}</span>
              <span className="keyboard-key">/</span>
            </ParagraphText>
            {Object.entries(mapOfShortcuts).map(([key, value]) => (
              <ParagraphText key={key}>
                {value}
                <span className="keyboard-key">{key}</span>
              </ParagraphText>
            ))}
          </div>
        </Panel>
      )}
      {isUserBuyer && (
        <Panel
          flex="1"
          padding={isDesktop ? "NONE" : "REGULAR"}
          style={{ paddingTop: "1rem" }}
        >
          <div className="modal-content keyboard-shortcuts-content">
            <ParagraphText>{CONST.IN_GALLERY}</ParagraphText>
            <span></span>
            <ParagraphText>
              {CONST.PREVIOUS_IMAGE}
              <span className="keyboard-key">←</span>
            </ParagraphText>
            <ParagraphText>
              {CONST.NEXT_IMAGE}
              <span className="keyboard-key">→</span>
            </ParagraphText>
          </div>
        </Panel>
      )}
      {isUserVendor && (
        <Panel flex="1" padding={isDesktop ? "NONE" : "REGULAR"}>
          <div className="modal-content keyboard-shortcuts-content">
            {Object.entries(mapOfVendorShortcuts).map(([key, value]) => (
              <ParagraphText key={key}>
                {value}
                <span className="keyboard-key">{key}</span>
              </ParagraphText>
            ))}
          </div>
        </Panel>
      )}
      <Panel
        flex="1"
        padding={isDesktop ? "NONE" : "REGULAR"}
        actions={[
          {
            content: "Close",
            action: onClose,
            type: "ghost",
          },
        ]}
      />
    </div>
  );
};

KeyboardShortcuts.propTypes = {
  className: PropTypes.string,
  qaClassName: PropTypes.string,
  onClose: PropTypes.func,
};

export default KeyboardShortcuts;
