import React from "react";
import Button from "../Button";
import Icon from "../Icon";

const IframeCam = props => {
  const { className, closeAction, src } = props;
  return (
    <div className={className}>
      <div className="modal-360__body">
        <iframe
          className="modal-360__iframe"
          frameBorder="0"
          title="iframe360"
          src={src}
          allowFullScreen={true}
        />
      </div>
      <div className="modal-360__footer">
        <div className="modal-360__footer-label">
          <Icon type="cam360" />
          <p>360</p>
        </div>
        <Button action={() => closeAction()} content="Close" primary />
      </div>
    </div>
  );
};

export default IframeCam;
