import styled from "styled-components";
import CampaignAddVehicles from "./CampaignAddVehicles";
import breakpoints from "../../../../views/theme/breakpoints";

const StyledCampaignAddVehicles = styled(CampaignAddVehicles)`
  .select-wrapper {
    display: flex;
    justify-content: flex-start;
    position: relative;
    width: 100%;

    @media screen and (max-width: ${breakpoints.LARGE_DESKTOP}) {
      display: block;
      overflow: scroll;
    }
  }
`;

export default StyledCampaignAddVehicles;
