import {
  FetchVendorClaims,
  TakeOwnership,
  ClearOwnership,
  CompleteCallback,
  CompleteClaim,
  FetchCallback,
} from "../../api/ClaimsVendor";
import { clearActiveClaimId } from "./global";
import { updateObject } from "../../shared/utility";

export const FETCH_VENDOR_CLAIMS = "@claimsVendor/FETCH_VENDOR_CLAIMS";
export const FETCH_VENDOR_CLAIMS_SUCCESS =
  "@claimsVendor/FETCH_VENDOR_CLAIMS_SUCCESS";
export const FETCH_VENDOR_CLAIMS_ERROR =
  "@claimsVendor/FETCH_VENDOR_CLAIMS_ERROR";

export const TAKE_OWNERSHIP = "@claimsVendor/TAKE_OWNERSHIP";
export const TAKE_OWNERSHIP_ERROR = "@claimsVendor/TAKE_OWNERSHIP_ERROR";
export const TAKE_OWNERSHIP_SUCCESS = "@claimsVendor/TAKE_OWNERSHIP_SUCCESS";
export const CLEAR_OWNERSHIP = "@claimsVendor/CLEAR_OWNERSHIP";
export const CLEAR_OWNERSHIP_ERROR = "@claimsVendor/CLEAR_OWNERSHIP_ERROR";
export const CLEAR_OWNERSHIP_SUCCESS = "@claimsVendor/CLEAR_OWNERSHIP_SUCCESS";
export const COMPLETE_CALLBACK = "@claimsVendor/COMPLETE_CALLBACK";
export const COMPLETE_CALLBACK_ERROR = "@claimsVendor/COMPLETE_CALLBACK_ERROR";
export const FETCH_CALLBACK = "@claimsVendor/FETCH_CALLBACK";
export const FETCH_CALLBACK_ERROR = "@claimsVendor/FETCH_CALLBACK_ERROR";
export const FETCH_CALLBACK_SUCCESS = "@claimsVendor/FETCH_CALLBACK_SUCCESS";

export const COMPLETE_CLAIM = "@claimsVendor/COMPLETE_CLAIM";
export const COMPLETE_CLAIM_ERROR = "@claimsVendor/COMPLETE_CLAIM_ERROR";
export const COMPLETE_CLAIM_SUCCESS = "@claimsVendor/COMPLETE_CLAIM_SUCCESS";

const claimsWithNewOwnerships = (claims, claimId, userFullName) => {
  const claimIdx = claims.findIndex((el) => el.claimId === claimId);
  let newClaimsArr = [...claims];
  newClaimsArr[claimIdx] = {
    ...newClaimsArr[claimIdx],
    managedBy: userFullName ? userFullName : null,
  };
  return newClaimsArr;
};

const claimsWithCallbackDetails = (claims, claimId, callbackData) => {
  const claimIdx = claims.findIndex((el) => el.claimId === claimId);
  let newClaimsArr = [...claims];
  newClaimsArr[claimIdx] = {
    ...newClaimsArr[claimIdx],
    callback: callbackData,
  };
  return newClaimsArr;
};

export const fetchVendorClaims = () => (dispatch, getState) => {
  dispatch({ type: FETCH_VENDOR_CLAIMS });

  const toolbar = getState().toolbar;
  const pageNumber = getState().pagination.pageNumber;
  const registration = getState().regSearch.value;
  const { pageSize, sort } = toolbar;
  const managedBy = toolbar.bucketType;
  const primaryFilter = toolbar.filterType;
  const filters = getState().filter.filters;

  let params = {
    pageSize,
    pageNumber,
    registration,
    managedBy,
    primaryFilter,
    filters,
  };

  if (Object.keys(sort).length) {
    params = {
      ...params,
      sort,
    };
  }

  const claimsInstance = new FetchVendorClaims({
    params,
    credentials: getState().authentication.credentials,
  });

  claimsInstance.call().then(
    (response) => {
      dispatch(fetchVendorClaimsSuccess(response));
    },
    (err) => {
      dispatch(fetchVendorClaimsError(err));
    }
  );
};

export const fetchVendorClaimsSuccess = (data) => {
  return {
    type: FETCH_VENDOR_CLAIMS_SUCCESS,
    payload: data,
  };
};

export const fetchVendorClaimsError = (data) => {
  return {
    type: FETCH_VENDOR_CLAIMS_ERROR,
    payload: data,
  };
};

export const takeOwnership = (claimId, userFullName) => (
  dispatch,
  getState
) => {
  dispatch({ type: TAKE_OWNERSHIP });
  const claimsInstance = new TakeOwnership({
    credentials: getState().authentication.credentials,
    params: {
      claimId,
    },
  });

  claimsInstance
    .call()
    .then((response) => {
      dispatch(takeOwnershipSuccess(response, claimId, userFullName));
    })
    .catch((err) => {
      dispatch(takeOwnershipError(err));
    });
};

export const takeOwnershipSuccess = (data, claimId, userFullName) => {
  return {
    type: TAKE_OWNERSHIP_SUCCESS,
    payload: {
      ...data,
      claimId,
      userFullName,
    },
  };
};

export const takeOwnershipError = (data) => {
  return {
    type: TAKE_OWNERSHIP_ERROR,
    payload: data,
  };
};

export const clearOwnership = (claimId) => (dispatch, getState) => {
  dispatch({ type: CLEAR_OWNERSHIP });
  const claimsInstance = new ClearOwnership({
    credentials: getState().authentication.credentials,
    params: {
      claimId,
    },
  });

  dispatch(clearActiveClaimId());
  claimsInstance
    .call()
    .then((response) => {
      dispatch(clearOwnershipSuccess(response, claimId));
    })
    .catch((err) => {
      dispatch(clearOwnershipError(err));
    });
};

export const clearOwnershipSuccess = (data, claimId) => {
  return {
    type: CLEAR_OWNERSHIP_SUCCESS,
    payload: {
      ...data,
      claimId,
    },
  };
};

export const clearOwnershipError = (data) => {
  return {
    type: CLEAR_OWNERSHIP_ERROR,
    payload: {
      ...data,
    },
  };
};

export const completeCallback = (callbackData) => (dispatch, getState) => {
  dispatch({ type: COMPLETE_CALLBACK });
  const claimsInstance = new CompleteCallback({
    credentials: getState().authentication.credentials,
    params: callbackData,
  });

  claimsInstance
    .call()
    .then((response) => {
      dispatch(fetchVendorClaims());
    })
    .catch((err) => {
      dispatch(completeCallbackError(err));
    });
};

export const completeCallbackError = (data) => {
  return {
    type: COMPLETE_CALLBACK_ERROR,
    payload: data,
  };
};

export const fetchCallbackDetails = (claimId) => (dispatch, getState) => {
  dispatch({ type: FETCH_CALLBACK });

  const claimsInstance = new FetchCallback({
    credentials: getState().authentication.credentials,
    params: {
      claimId,
    },
  });

  claimsInstance.call().then(
    (response) => {
      dispatch(fetchCallbackSuccess(response, claimId));
    },
    (err) => {
      dispatch(fetchCallbackError(err));
    }
  );
};

export const fetchCallbackSuccess = (data, claimId) => {
  return {
    type: FETCH_CALLBACK_SUCCESS,
    payload: {
      ...data,
      claimId,
    },
  };
};

export const fetchCallbackError = (data) => {
  return {
    type: FETCH_CALLBACK_ERROR,
    payload: data,
  };
};

export const completeClaim = (claimId) => (dispatch, getState) => {
  dispatch({ type: COMPLETE_CLAIM });
  const claimsInstance = new CompleteClaim({
    credentials: getState().authentication.credentials,
    params: {
      claimId,
    },
  });

  claimsInstance
    .call()
    .then((response) => {
      dispatch(completeClaimSuccess(response, claimId));
      dispatch(fetchVendorClaims());
    })
    .catch((err) => {
      dispatch(completeClaimError(err));
    });
};

export const completeClaimSuccess = (data, claimId) => {
  return {
    type: COMPLETE_CLAIM_SUCCESS,
    payload: {
      ...data,
      claimId,
    },
  };
};

export const completeClaimError = (data) => {
  return {
    type: COMPLETE_CLAIM_ERROR,
    payload: data,
  };
};

const initialState = {
  isFetching: false,
  error: false,
  data: null,
  totalCount: 0,
  currentPage: 1,
  pageSize: 25,
  pageCount: 0,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VENDOR_CLAIMS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_VENDOR_CLAIMS_SUCCESS:
      return updateObject(state, {
        isFetching: false,
        totalCount: action.payload.totalCount,
        pageSize: action.payload.pageSize,
        pageCount: action.payload.pageCount,
        data: action.payload.claims,
      });
    case FETCH_VENDOR_CLAIMS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case TAKE_OWNERSHIP:
      return {
        ...state,
      };
    case TAKE_OWNERSHIP_SUCCESS:
      return {
        ...state,
        data: claimsWithNewOwnerships(
          state.data,
          action.payload.claimId,
          action.payload.userFullName
        ),
      };
    case TAKE_OWNERSHIP_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_OWNERSHIP:
      return {
        ...state,
      };
    case CLEAR_OWNERSHIP_SUCCESS:
      return {
        ...state,
        data: claimsWithNewOwnerships(state.data, action.payload.claimId, null),
      };
    case CLEAR_OWNERSHIP_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case COMPLETE_CALLBACK:
      return {
        ...state,
      };
    case COMPLETE_CALLBACK_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_CALLBACK:
      return {
        ...state,
      };
    case FETCH_CALLBACK_SUCCESS:
      return {
        ...state,
        data: claimsWithCallbackDetails(
          state.data,
          action.payload.claimId,
          action.payload
        ),
      };
    case FETCH_CALLBACK_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case COMPLETE_CLAIM:
      return {
        ...state,
      };
    case COMPLETE_CLAIM_SUCCESS:
      return {
        ...state,
      };
    case COMPLETE_CLAIM_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
