import React, { useContext, useEffect, useState } from "react";
import { ItemContainer, ItemLabel, ItemContent } from "../index";
import { useSelector } from "react-redux";
import {
  headerText,
  paragraphText,
  subHeaderText,
  errorMessages,
} from "../const";
import { fileUploadConst } from "../../../../../views/containers/FileUpload/const";
import HeaderText from "../../../../../views/components/HeaderText";
import ParagraphText from "../../../../../views/components/ParagraphText";

import SubHeaderText from "../../../../../views/components/SubHeaderText";
import TextArea from "../../../../../views/components/TextArea";
import BidInputError from "../../../../../views/components/BidInput/child-components/BidInputError";
import {
  CampaignDetailsContext,
  CampaignDetailsValidationContext,
} from "../context/context";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../../../views/theme/breakpoints";

import DragDrop from "../../../../../views/components/DragDrop";
import { DragDropItemContent } from "../index";
import SkeletonRows from "../../../../../views/components/Skeletons/SkeletonRows";
import FileUpload from "../../../../../views/containers/FileUpload";
import { USER_TYPE_MAP } from "../../../../../shared/utility";
import Icon from "../../../../../views/components/Icon";
import InputText from "../../../../../views/components/InputText";
import InputError from "../../../../../views/components/BidInput/child-components/BidInputError";
import { SUPPORTINGLINK_REGEX } from "../../../../../shared/utility";
import ButtonLink from "../../../../../views/components/ButtonLink";
import Button from "../../../../../views/components/Button";

const CampaignDetails = ({
  campaignDetailsProps,
  disabled,
  isFetchingCampaignDocuments,
  name,
  reference,
  description,
  data,
  disableForm,
  setApproveButtonDisabled,
}) => {
  const {
    primaryParagraphText,
    secondaryParagraphText,
    acceptedTypes,
    maxFileSize,
    onDropRejected,
    handleBlockChange,
    validFileSize,
    validFileType,
    maxNoOfMb,
    campaignDocuments,
    setName,
    setReference,
    setDescription,
  } = campaignDetailsProps;

  const {
    setCampaignName: setNameCtx,
    setCampaignReference: setReferenceCtx,
    setCampaignDescription: setDescriptionCtx,
    supportingLinkCtx,
    setSupportingLinkCtx,
  } = useContext(CampaignDetailsContext);

  const {
    validName,
    setValidName,
    validReference,
    setValidReference,
    validDescription,
    setValidDescription,
    validSupportingLink,
    setValidSupportingLink,
    validUrlName,
    setValidUrlName,
    hasFocus,
    setHasFocus,
  } = useContext(CampaignDetailsValidationContext);

  const [campaignUrlName, setCampaignUrlName] = useState("");
  const [supportingDocumentLink, setSupportingDocumentLink] = useState("");

  const type = "Campaign";
  const userType = USER_TYPE_MAP.Vendor;

  const uniqueSessionIdentifier = useSelector(
    (state) =>
      state.campaignManagement.activeCampaign?.data?.uniqueSessionIdentifier
  );

  const campaignId = useSelector(
    (state) => state.campaignManagement.activeCampaign?.data?.campaignId
  );

  function onChangeName({ e, edited = false }) {
    const { value } = e.target;
    setName(value);
    if (value.length >= 1 && value.length <= 50) {
      setValidName(true);
    } else {
      setValidName(false);
    }
    if (edited) {
      setApproveButtonDisabled(true);
    }
  }

  function onChangeReference({ e, edited = false }) {
    const { value } = e.target;
    setReference(value);
    if (value.length >= 1 && value.length <= 20) {
      setValidReference(true);
    } else {
      setValidReference(false);
    }
    if (edited) {
      setApproveButtonDisabled(true);
    }
  }

  function onChangeDescription({ e, edited = false }) {
    const { value } = e.target;
    setDescription(value);
    if (value.length >= 1 && value.length <= 1000) {
      setValidDescription(true);
    } else {
      setValidDescription(false);
    }
    if (edited) {
      setApproveButtonDisabled(true);
    }
  }

  function onChangeCampaignUrlName({ e, edited = false }) {
    if (e && e.target) {
      const { value } = e.target;
      setCampaignUrlName(value);
      if (value.length >= 5 && value.length <= 50) {
        setValidUrlName(true);
      } else {
        setValidUrlName(false);
      }
      if (edited) {
        setApproveButtonDisabled(true);
      }
    }
  }

  useEffect(() => {
    validName && setNameCtx(name);
    validReference && setReferenceCtx(reference);
    validDescription && setDescriptionCtx(description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    validName,
    reference,
    validReference,
    description,
    validDescription,
  ]);

  const isMobileOrTablet = useMediaQuery({
    query: `(max-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  useEffect(() => {
    setSupportingLinkCtx((prevLinks) => [
      ...(prevLinks || []),
      ...(data?.campaignUrls || []),
    ]);
  }, [data?.campaignUrls, setSupportingLinkCtx]);

  const alreadyHasDocOrLink =
    supportingLinkCtx?.length || data?.campaignUrls?.length;

  const onChangeLink = (value) => {
    const passesRegex = value && SUPPORTINGLINK_REGEX.test(value);
    setSupportingDocumentLink(value);
    if (passesRegex || (alreadyHasDocOrLink && !value)) {
      setValidSupportingLink(true);
    } else {
      setValidSupportingLink(false);
    }
  };

  const onAddLine = () => {
    const newLink = {
      key: campaignUrlName,
      value: supportingDocumentLink,
    };

    setSupportingLinkCtx((prevLinks) => [...prevLinks, newLink]);
    setSupportingDocumentLink("");
    setCampaignUrlName("");
  };

  const deleteSupportingLink = (link) => {
    setSupportingLinkCtx((prevLinks) =>
      prevLinks.filter((item) => item.key !== link.key)
    );
    setApproveButtonDisabled(true);
  };

  return (
    <div className="campaignDetails-container">
      <HeaderText className="subtitle">{headerText.TITLE_DETAILS}</HeaderText>
      <ItemContainer>
        <ItemLabel>
          <SubHeaderText>{subHeaderText.CAMPAIGN_NAME}</SubHeaderText>
          <div style={{ width: "80%" }}>
            <ParagraphText className="info-text">
              {paragraphText.NAME_DESCRIP}
            </ParagraphText>
          </div>
        </ItemLabel>
        <ItemContent>
          <TextArea
            value={name || data?.campaignName}
            onChange={(e) => {
              onChangeName({ e: e, edited: true });
            }}
            maximumCharactersBottom={50}
            validValue={validName}
            resize={"none"}
            maxLength="50"
            rows="1"
            cols="50"
            onFocus={(e) => {
              e.target.select();
              onChangeName({ e: e });
              setHasFocus(true);
            }}
            onBlur={(e) => {
              setHasFocus(false);
            }}
            disabled={disableForm}
          ></TextArea>
          {!validName &&
            !hasFocus &&
            (name?.length <= 0 ? (
              <BidInputError
                errorMessage={
                  !isMobileOrTablet
                    ? errorMessages.FIELD_REQUIRED
                    : errorMessages.MOBILE_FIELD_REQUIRED
                }
              />
            ) : (
              <BidInputError errorMessage={errorMessages.MIN_LENGTH} />
            ))}
        </ItemContent>
      </ItemContainer>

      <ItemContainer>
        <ItemLabel>
          <SubHeaderText>{subHeaderText.CAMPAIGN_REF}</SubHeaderText>
          <div style={{ width: "80%" }}>
            <ParagraphText className="info-text">
              {paragraphText.CAMPAIGN_REF_DESCRIP}
            </ParagraphText>
          </div>
        </ItemLabel>
        <ItemContent>
          <TextArea
            value={reference || data?.campaignReference}
            onChange={(e) => {
              onChangeReference({ e: e, edited: true });
            }}
            maximumCharactersBottom={20}
            validValue={validReference}
            resize={"none"}
            maxLength="20"
            rows="1"
            cols="50"
            onFocus={(e) => {
              e.target.select();
              onChangeReference({ e: e });
              setHasFocus(true);
            }}
            onBlur={(e) => {
              setHasFocus(false);
            }}
            disabled={disableForm}
          ></TextArea>
          {!validReference &&
            !hasFocus &&
            (reference?.length <= 0 ? (
              <BidInputError
                errorMessage={
                  !isMobileOrTablet
                    ? errorMessages.FIELD_REQUIRED
                    : errorMessages.MOBILE_FIELD_REQUIRED
                }
              />
            ) : (
              <BidInputError errorMessage={errorMessages.MIN_LENGTH} />
            ))}
        </ItemContent>
      </ItemContainer>

      <ItemContainer>
        <ItemLabel>
          <SubHeaderText>{subHeaderText.DESCRIPTION}</SubHeaderText>
          <div style={{ width: "80%" }}>
            <ParagraphText className="info-text">
              {paragraphText.CAMPAIGN_DESCRIP}
            </ParagraphText>
          </div>
        </ItemLabel>
        <ItemContent>
          <TextArea
            value={description || data?.campaignDescription}
            onChange={(e) => {
              onChangeDescription({ e: e, edited: true });
            }}
            maximumCharactersBottom={1000}
            validValue={validDescription}
            resize={"none"}
            maxLength="1000"
            rows="7"
            cols="50"
            onFocus={(e) => {
              e.target.select();
              onChangeDescription({ e: e });
              setHasFocus(true);
            }}
            onBlur={(e) => {
              setHasFocus(false);
            }}
            disabled={disableForm}
          ></TextArea>
          {!validDescription &&
            !hasFocus &&
            (description?.length <= 0 ? (
              <BidInputError
                errorMessage={
                  !isMobileOrTablet
                    ? errorMessages.FIELD_REQUIRED
                    : errorMessages.MOBILE_FIELD_REQUIRED
                }
              />
            ) : (
              <BidInputError errorMessage={errorMessages.MIN_LENGTH} />
            ))}
        </ItemContent>
      </ItemContainer>

      <ItemContainer>
        <ItemLabel>
          <SubHeaderText>{subHeaderText.SUPPORTING_DOCUMENTS}</SubHeaderText>
          <div style={{ width: "80%" }}>
            <ParagraphText className="info-text">
              {paragraphText.SUPPORTING_DOC_DESCRIP}
            </ParagraphText>
          </div>
        </ItemLabel>
        <ItemContent>
          <DragDrop
            onDropRejected={onDropRejected}
            onDrop={handleBlockChange}
            maxFileSize={maxFileSize}
            maxFiles={100}
            allowMultiple={true}
            acceptedTypes={acceptedTypes}
            primaryParagraphText={primaryParagraphText}
            secondaryParagraphText={secondaryParagraphText}
            disabled={disableForm}
          />
          {!validFileType && (
            <BidInputError errorMessage={errorMessages.INVALID_FILE_FORMAT} />
          )}
          {!validFileSize && (
            <BidInputError
              errorMessage={errorMessages.FILE_TOO_LARGE.replace(
                "{0}",
                maxNoOfMb
              )}
            />
          )}
          <DragDropItemContent>
            {!isFetchingCampaignDocuments ? (
              campaignDocuments &&
              campaignDocuments.map((item, index) => {
                return (
                  <FileUpload
                    key={index}
                    documentOrImage={item}
                    callingArea={fileUploadConst.CAMPAIGNMANAGEMENT}
                    id={campaignId}
                    type={type}
                    userType={userType}
                    disabled={disableForm}
                    disabledTrashCan={disableForm}
                    sourceFolder={uniqueSessionIdentifier}
                    setParentFormButtonDisabled={setApproveButtonDisabled}
                  />
                );
              })
            ) : (
              <SkeletonRows
                nrOfRows={1}
                paddingNone
                className="skeleton-rows"
              />
            )}
          </DragDropItemContent>
        </ItemContent>
      </ItemContainer>

      <ItemContainer>
        <ItemLabel>
          <SubHeaderText>{subHeaderText.LINKS}</SubHeaderText>
          <div style={{ width: "80%" }}>
            <ParagraphText className="info-text">
              {paragraphText.LINKS_DESCRIP}
            </ParagraphText>
          </div>
        </ItemLabel>
        <ItemContent>
          <div className="document-links-container">
            <div className="document-links-lines-container">
              <InputText
                className="campaign-url-text-box"
                medium
                name="campaignUrlName"
                maxLength="50"
                placeholder="Link Name"
                value={campaignUrlName}
                onChange={(e) => {
                  onChangeCampaignUrlName({ e: e, edited: true });
                }}
                validValue={validUrlName}
                onBlur={(e) => {
                  onChangeCampaignUrlName(e.target.value);
                  setHasFocus(false);
                }}
                onFocus={(e) => {
                  e.target.select();
                  setHasFocus(true);
                }}
                disabled={disableForm}
              />

              <InputText
                className="document-link-text-box"
                medium
                name="supportingLink"
                maxLength="500"
                placeholder="URL or Link"
                value={supportingDocumentLink}
                onChange={(e) => onChangeLink(e.target.value)}
                validValue={validSupportingLink}
                onBlur={(e) => {
                  onChangeLink(e.target.value);
                  setHasFocus(false);
                }}
                onFocus={(e) => {
                  e.target.select();
                  setHasFocus(true);
                }}
                disabled={disableForm}
              />

              <div className="document-link-controls-group">
                <Button
                  secondary
                  content={"Add Link"}
                  action={() => onAddLine()}
                  disabled={
                    supportingDocumentLink.length === 0 ||
                    campaignUrlName.length === 0 ||
                    validUrlName === false ||
                    validSupportingLink === false ||
                    disableForm
                  }
                />
              </div>
            </div>
            {!validSupportingLink &&
              !hasFocus &&
              supportingDocumentLink?.length === 0 &&
              supportingLinkCtx?.length === 0 && (
                <InputError errorMessage={errorMessages.LINK_MANDATORY} />
              )}
            {!validSupportingLink &&
              !hasFocus &&
              supportingDocumentLink?.length > 0 && (
                <InputError errorMessage={errorMessages.VALID_LINK_REQUIRED} />
              )}
            {!validUrlName && !hasFocus && (
              <InputError errorMessage={errorMessages.VALID_LINK_NAME} />
            )}
            <div className="submittedLinks">
              {supportingLinkCtx?.length
                ? supportingLinkCtx.map((link, index) => (
                    <div key={index} className="link-item">
                      <ButtonLink
                        key={link.value}
                        listItem
                        primary
                        name={link.key}
                        action={() => {
                          window.open(link.value, "_blank");
                        }}
                      />
                      {!disableForm && (
                        <div
                          className="trash-container"
                          onClick={() => deleteSupportingLink(link)}
                        >
                          <Icon type="trash" width="23" height="23" />
                        </div>
                      )}
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </ItemContent>
      </ItemContainer>
    </div>
  );
};

export default CampaignDetails;
