import { HelpCentreApi } from "../../api/HelpCentre";

// Action constants
export const FETCH_HELP_CENTRE = "@helpCentre/FETCH_HELP_CENTRE";
export const FETCH_HELP_CENTRE_SUCCESS =
  "@helpCentre/FETCH_HELP_CENTRE_SUCCESS";
export const FETCH_HELP_CENTRE_FAILURE =
  "@helpCentre/FETCH_HELP_CENTRE_FAILURE";
export const SET_ACTIVE_FAQ = "@helpCentre/SET_ACTIVE_FAQ";
export const CLEAR_ACTIVE_FAQ = "@helpCentre/CLEAR_ACTIVE_FAQ";

export const fetchHelpCentreSuccess = response => {
  return {
    type: FETCH_HELP_CENTRE_SUCCESS,
    payload: response
  };
};

export const fetchHelpCentreFailure = error => {
  return {
    type: FETCH_HELP_CENTRE_FAILURE,
    payload: error
  };
};

export const fetchHelpCentre = () => (dispatch, getState) => {
  dispatch({ type: FETCH_HELP_CENTRE });

  const instance = new HelpCentreApi({
    credentials: getState().authentication.credentials
  });

  instance.call().then(
    response => {
      dispatch(fetchHelpCentreSuccess(response.data));
    },
    err => {
      return dispatch(fetchHelpCentreFailure(err));
    }
  );
};

export const setActiveFaq = faqId => {
  return {
    type: SET_ACTIVE_FAQ,
    payload: faqId
  };
};

export const clearActiveFaq = () => {
  return {
    type: CLEAR_ACTIVE_FAQ
  };
};

const initialState = {
  isFetching: false,
  error: false,
  data: null
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HELP_CENTRE:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_HELP_CENTRE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case FETCH_HELP_CENTRE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload
      };
    case SET_ACTIVE_FAQ:
      return {
        ...state,
        data: {
          ...state.data,
          faqs: state.data.faqs.map(faq => ({
            ...faq,
            isOpen: faq.faqId === action.payload
          }))
        }
      };
    case CLEAR_ACTIVE_FAQ:
      return {
        ...state,
        data: {
          ...state.data,
          faqs: state.data.faqs.map(faq => ({
            ...faq,
            isOpen: false
          }))
        }
      };
    default:
      return state;
  }
};

export default reducer;
