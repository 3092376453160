import React from "react";
import PropTypes from "prop-types";
import HeaderText from "../HeaderText";
import SubHeaderText from "../SubHeaderText";
import ParagraphText from "../ParagraphText";
import {
  HEADER_TEXT,
  PARAGRAPH_TEXT,
  SUBHEADER_TEXT,
  errorMessages,
  regSearchStatusType,
} from "./consts";
import InputError from "../BidInput/child-components/BidInputError";

const ClaimVehicleSearchModal = ({
  make,
  model,
  derivative,
  children,
  registrationNotFound,
  regSearchStatus,
  helpCentreTelNo,
  className,
}) => (
  <div className={className}>
    <HeaderText>{HEADER_TEXT}</HeaderText>
    <ParagraphText> {PARAGRAPH_TEXT}</ParagraphText>
    <div className="subheader-text__container">
      <SubHeaderText colour="ACCENT">{SUBHEADER_TEXT}</SubHeaderText>
    </div>

    <div className="search__container">
      <div className="search-input">{children}</div>
      {make && model && derivative && (
        <div className="search-results">
          <SubHeaderText small>
            {make} {model}
          </SubHeaderText>
          <ParagraphText> {derivative}</ParagraphText>
        </div>
      )}
    </div>
    {registrationNotFound && (
      <InputError errorMessage={errorMessages.REGISTRATION_NOT_FOUND} />
    )}
    {regSearchStatus === regSearchStatusType.CLAIMS_NOT_ENABLED && (
      <InputError errorMessage={errorMessages.VENDOR_NOT_ENABLED_FOR_CLAIMS} />
    )}
    {regSearchStatus === regSearchStatusType.HAS_ACTIVE_CLAIM && (
      <InputError errorMessage={errorMessages.CLAIM_ALREADY_EXISTS} />
    )}
    {regSearchStatus === regSearchStatusType.CATEGORIES_EXPIRED && (
      <InputError
        errorMessage={errorMessages.CLAIM_CATEGORIES_EXPIRED.replace(
          "##HELP_CENTRE_TEL_NO##",
          helpCentreTelNo
        )}
      />
    )}
  </div>
);

export default ClaimVehicleSearchModal;

ClaimVehicleSearchModal.propTypes = {
  make: PropTypes.string,
  model: PropTypes.string,
  derivative: PropTypes.string,
  children: PropTypes.node.isRequired,
  registrationNotFound: PropTypes.bool,
  regSearchStatus: PropTypes.string,
  helpCentreTelNo: PropTypes.string,
};
