import React from "react";
import PropTypes from "prop-types";
import KeyInformationLabel from "../KeyInformationLabel";
import InfoTag from "../InfoTag";
import classnames from "classnames";
import {
  getConditionDisplayValue,
  getDamageDisplayValue,
  USER_TYPE_MAP,
} from "./../../../shared/utility";
import useIsLoggedInUserType from "./../../../shared/hooks/useIsLoggedInUserType";

const VehicleGrade = ({
  grade,
  condition,
  showDamagePercentage,
  damagePercentage,
  qaClassName,
  className,
  small,
}) => {
  const gradeIconType = (() => {
    switch (grade) {
      case null:
        return "dash";
      default:
        return null;
    }
  })();

  const isBuyerOnly = useIsLoggedInUserType(USER_TYPE_MAP.Buyer);

  const label = isBuyerOnly
    ? showDamagePercentage
      ? "Damage/Condition"
      : "Grade / Other"
    : "";
  const iconText = showDamagePercentage ? "" : grade;
  const iconType = showDamagePercentage ? "dash" : gradeIconType;

  return (
    <div className={classnames(className, qaClassName, "grade-condition")}>
      <KeyInformationLabel small>{label}</KeyInformationLabel>
      {showDamagePercentage && damagePercentage ? (
        <p>{`${getDamageDisplayValue(damagePercentage)} / ${getConditionDisplayValue(condition)}`}</p>
      ) : (
        <InfoTag
          iconText={iconText}
          info
          iconType={iconType}
          defaultIconColor
          small={small}
          content={` / ${getConditionDisplayValue(condition)}`}
        />
      )}
    </div>
  );
};

VehicleGrade.propTypes = {
  grade: PropTypes.string,
  condition: PropTypes.oneOf(["Unknown", "Average", "Clean", "BelowAverage"]),
  className: PropTypes.string,
};

export default VehicleGrade;