import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import DealerFundingRequestError from "../../components/DealerFundingRequest/DealerFundingRequestError";
import ModalInsert from "../../components/ModalInsert";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../theme/breakpoints";

const DealerFundingRequestModalInsert = () => {
  const fundingApplicationData = useSelector(
    (state) => state.funding.fundingApplication.data
  );

  const dispatch = useDispatch();

  const onClose = () => dispatch(closeModal());

  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  return (
    <ModalInsert layout="flex" width={isDesktop ? "96rem" : "100%"}>
      <DealerFundingRequestError
        className={"dealer-funding-request-error"}
        fundingApplicationData={fundingApplicationData}
        onClose={onClose}
      />
    </ModalInsert>
  );
};

export default DealerFundingRequestModalInsert;
