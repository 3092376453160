import {
  FetchVehicleRecordPrimaryDetails,
  FetchVehicleRecordMoreInfoDetails,
  FetchVehicleRecordSalesInfo,
  FetchVehicleRecordEventHistory,
  FetchVehicleRecordProfiles,
  FetchVehicleRecordAuctionSales,
  FetchVehicleRecordPurchaseInfo,
  FetchVehicleRecordBiddingInfo,
  FetchVehicleRecordInspections,
} from "../../api/VehicleDetailsVendor";
import { openModal } from "./global";
import { VENDOR_VEHICLE_NAVIGATION_OPTIONS } from "../../shared/utility";
import {
  getStandardEquipment,
  getTechnicalSpecification,
  fetchServiceHistory,
  fetchDocuments,
} from "./vehicle";

export const FETCH_VEHICLE_PRIMARY_DETAILS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_PRIMARY_DETAILS";
export const FETCH_VEHICLE_PRIMARY_DETAILS_SUCCESS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_PRIMARY_DETAILS_SUCCESS";
export const FETCH_VEHICLE_PRIMARY_DETAILS_ERROR =
  "@vehicleDetailsVendor/FETCH_VEHICLE_PRIMARY_DETAILS_ERROR";
export const FETCH_VEHICLE_MORE_INFO_DETAILS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_MORE_INFO_DETAILS";
export const FETCH_VEHICLE_MORE_INFO_DETAILS_SUCCESS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_MORE_INFO_DETAILS_SUCCESS";
export const FETCH_VEHICLE_MORE_INFO_DETAILS_ERROR =
  "@vehicleDetailsVendor/FETCH_VEHICLE_MORE_INFO_DETAILS_ERROR";
export const FETCH_VEHICLE_SALES_INFO_DETAILS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_SALES_INFO_DETAILS";
export const FETCH_VEHICLE_SALES_INFO_DETAILS_SUCCESS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_SALES_INFO_DETAILS_SUCCESS";
export const FETCH_VEHICLE_SALES_INFO_DETAILS_ERROR =
  "@vehicleDetailsVendor/FETCH_VEHICLE_SALES_INFO_DETAILS_ERROR";
export const FETCH_VEHICLE_EVENT_HISTORY =
  "@vehicleDetailsVendor/FETCH_VEHICLE_EVENT_HISTORY";
export const FETCH_VEHICLE_EVENT_HISTORY_SUCCESS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_EVENT_HISTORY_SUCCESS";
export const FETCH_VEHICLE_EVENT_HISTORY_ERROR =
  "@vehicleDetailsVendor/FETCH_VEHICLE_EVENT_HISTORY_ERROR";
export const FETCH_VEHICLE_RECORD_AUCTION_SALES =
  "@vehicleDetailsVendor/FETCH_VEHICLE_RECORD_AUCTION_SALES";
export const FETCH_VEHICLE_RECORD_AUCTION_SALES_SUCCESS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_RECORD_AUCTION_SALES_SUCCESS";
export const FETCH_VEHICLE_RECORD_AUCTION_SALES_ERROR =
  "@vehicleDetailsVendor/FETCH_VEHICLE_RECORD_AUCTION_SALES_ERROR";
export const FETCH_VEHICLE_RECORD_PROFILES =
  "@vehicleDetailsVendor/FETCH_VEHICLE_RECORD_PROFILES";
export const FETCH_VEHICLE_RECORD_PROFILES_SUCCESS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_RECORD_PROFILES_SUCCESS";
export const FETCH_VEHICLE_RECORD_PROFILES_ERROR =
  "@vehicleDetailsVendor/FETCH_VEHICLE_RECORD_PROFILES_ERROR";
export const FETCH_VEHICLE_PURCHASE_INFO_DETAILS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_PURCHASE_INFO_DETAILS";
export const FETCH_VEHICLE_PURCHASE_INFO_DETAILS_SUCCESS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_PURCHASE_INFO_DETAILS_SUCCESS";
export const FETCH_VEHICLE_PURCHASE_INFO_DETAILS_ERROR =
  "@vehicleDetailsVendor/FETCH_VEHICLE_PURCHASE_INFO_DETAILS_ERROR";
export const FETCH_VEHICLE_BIDDING_INFO_DETAILS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_BIDDING_INFO_DETAILS";
export const FETCH_VEHICLE_BIDDING_INFO_DETAILS_SUCCESS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_BIDDING_INFO_DETAILS_SUCCESS";
export const FETCH_VEHICLE_BIDDING_INFO_DETAILS_ERROR =
  "@vehicleDetailsVendor/FETCH_VEHICLE_BIDDING_INFO_DETAILS_ERROR";
export const FETCH_VEHICLE_INSPECTION_DETAILS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_INSPECTION_DETAILS";
export const FETCH_VEHICLE_INSPECTION_DETAILS_SUCCESS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_INSPECTION_DETAILS_SUCCESS";
export const FETCH_VEHICLE_INSPECTION_DETAILS_ERROR =
  "@vehicleDetailsVendor/FETCH_VEHICLE_INSPECTION_DETAILS_ERROR";
export const SET_ACTIVE_NAVIGATION_OPTION =
  "@vehicleDetailsVendor/SET_ACTIVE_NAVIGATION_OPTION";
export const CLEAR_ACTIVE_NAVIGATION_OPTION =
  "@vehicleDetailsVendor/CLEAR_ACTIVE_NAVIGATION_OPTION";
export const SET_ACTIVE_INSPECTION_ID =
  "@vehicleDetailsVendor/SET_ACTIVE_INSPECTION_ID";
export const CLEAR_ACTIVE_INSPECTION_ID =
  "@vehicleDetailsVendor/CLEAR_ACTIVE_INSPECTION_ID";

export const fetchVehiclePrimaryDetails =
  (vehicleId) => (dispatch, getState) => {
    dispatch({ type: FETCH_VEHICLE_PRIMARY_DETAILS });

    const vehicleDetailsInstance = new FetchVehicleRecordPrimaryDetails({
      params: {
        vehicleId: vehicleId,
      },
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        if (
          response.titleInfo.regNo === null ||
          response.titleInfo.regNo === ""
        ) {
          dispatch(openModal("generic-error"));
        } else {
          dispatch(fetchVehiclePrimaryDetailsSuccess(response));
          let defaultActiveNavOption =
            VENDOR_VEHICLE_NAVIGATION_OPTIONS.SALES_INFO;
          if (response?.statusInfo?.currentStatus === "Disposed") {
            defaultActiveNavOption =
              VENDOR_VEHICLE_NAVIGATION_OPTIONS.PURCHASE_INFO;
          }
          dispatch(
            genericFetchEventForNavigation(defaultActiveNavOption, vehicleId)
          );
        }
      },
      (err) => {
        dispatch(fetchVehiclePrimaryDetailsError(err));
        dispatch(openModal("generic-error"));
      }
    );
  };

export const fetchVehiclePrimaryDetailsSuccess = (data) => {
  return {
    type: FETCH_VEHICLE_PRIMARY_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchVehiclePrimaryDetailsError = (error) => {
  return {
    type: FETCH_VEHICLE_PRIMARY_DETAILS_ERROR,
    payload: error,
  };
};

export const fetchVehicleMoreInfoDetails =
  (vehicleId) => (dispatch, getState) => {
    dispatch({ type: FETCH_VEHICLE_MORE_INFO_DETAILS });

    const vehicleDetailsInstance = new FetchVehicleRecordMoreInfoDetails({
      params: {
        vehicleId: vehicleId,
      },
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(fetchVehicleMoreInfoDetailsSuccess(response));
      },
      (err) => {
        dispatch(fetchVehicleMoreInfoDetailsError(err));
      }
    );
  };

export const fetchVehicleMoreInfoDetailsSuccess = (data) => {
  return {
    type: FETCH_VEHICLE_MORE_INFO_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchVehicleMoreInfoDetailsError = (error) => {
  return {
    type: FETCH_VEHICLE_MORE_INFO_DETAILS_ERROR,
    payload: error,
  };
};

export const fetchVehicleSalesInfo = (vehicleId) => (dispatch, getState) => {
  dispatch({ type: FETCH_VEHICLE_SALES_INFO_DETAILS });

  const vehicleDetailsInstance = new FetchVehicleRecordSalesInfo({
    params: {
      vehicleId: vehicleId,
    },
    credentials: getState().authentication.credentials,
  });

  vehicleDetailsInstance.call().then(
    (response) => {
      dispatch(fetchVehicleSalesInfoSuccess(response));
    },
    (err) => {
      dispatch(fetchVehicleSalesInfoError(err));
    }
  );
};

export const fetchVehicleSalesInfoSuccess = (data) => {
  return {
    type: FETCH_VEHICLE_SALES_INFO_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchVehicleSalesInfoError = (error) => {
  return {
    type: FETCH_VEHICLE_SALES_INFO_DETAILS_ERROR,
    payload: error,
  };
};

export const fetchVehicleEventHistory = (vehicleId) => (dispatch, getState) => {
  dispatch({ type: FETCH_VEHICLE_EVENT_HISTORY });

  const vehicleDetailsInstance = new FetchVehicleRecordEventHistory({
    params: {
      vehicleId: vehicleId,
    },
    credentials: getState().authentication.credentials,
  });

  vehicleDetailsInstance.call().then(
    (response) => {
      dispatch(fetchVehicleEventHistorySuccess(response));
    },
    (err) => {
      dispatch(fetchVehicleEventHistoryError(err));
    }
  );
};

export const fetchVehicleEventHistorySuccess = (data) => {
  return {
    type: FETCH_VEHICLE_EVENT_HISTORY_SUCCESS,
    payload: data,
  };
};

export const fetchVehicleEventHistoryError = (error) => {
  return {
    type: FETCH_VEHICLE_EVENT_HISTORY_ERROR,
    payload: error,
  };
};

export const fetchVehiclePurchaseInfo = (vehicleId) => (dispatch, getState) => {
  dispatch({ type: FETCH_VEHICLE_PURCHASE_INFO_DETAILS });

  const vehicleDetailsInstance = new FetchVehicleRecordPurchaseInfo({
    params: {
      vehicleId: vehicleId,
    },
    credentials: getState().authentication.credentials,
  });

  vehicleDetailsInstance.call().then(
    (response) => {
      dispatch(fetchVehiclePurchaseInfoSuccess(response));
    },
    (err) => {
      dispatch(fetchVehiclePurchaseInfoError(err));
    }
  );
};

export const fetchVehiclePurchaseInfoSuccess = (data) => {
  return {
    type: FETCH_VEHICLE_PURCHASE_INFO_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchVehiclePurchaseInfoError = (error) => {
  return {
    type: FETCH_VEHICLE_PURCHASE_INFO_DETAILS_ERROR,
    payload: error,
  };
};


export const fetchVehicleRecordProfiles =
  (vehicleId) => (dispatch, getState) => {
    dispatch({ type: FETCH_VEHICLE_RECORD_PROFILES });

    const vehicleDetailsInstance = new FetchVehicleRecordProfiles({
      params: {
        vehicleId: vehicleId,
      },
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(fetchVehicleRecordProfilesSuccess(response));
      },
      (err) => {
        dispatch(fetchVehicleRecordProfilesError(err));
      }
    );
  };

export const fetchVehicleRecordProfilesSuccess = (data) => {
  return {
    type: FETCH_VEHICLE_RECORD_PROFILES_SUCCESS,
    payload: data,
  };
};

export const fetchVehicleRecordProfilesError = (error) => {
  return {
    type: FETCH_VEHICLE_RECORD_PROFILES_ERROR,
    payload: error,
  };
};

export const fetchVehicleRecordAuctionSales =
  (vehicleId) => (dispatch, getState) => {
    dispatch({ type: FETCH_VEHICLE_RECORD_PROFILES });

    const vehicleDetailsInstance = new FetchVehicleRecordAuctionSales({
      params: {
        vehicleId: vehicleId,
      },
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(fetchVehicleRecordAuctionSalesSuccess(response));
      },
      (err) => {
        dispatch(fetchVehicleRecordAuctionSalesError(err));
      }
    );
  };

export const fetchVehicleRecordAuctionSalesSuccess = (data) => {
  return {
    type: FETCH_VEHICLE_RECORD_AUCTION_SALES_SUCCESS,
    payload: data,
  };
};

export const fetchVehicleRecordAuctionSalesError = (error) => {
  return {
    type: FETCH_VEHICLE_RECORD_AUCTION_SALES_ERROR,
    payload: error,
  };
};

export const fetchVehicleBiddingInfo = (vehicleId, isAllBids=false) => (dispatch, getState) => {
  dispatch({ type: FETCH_VEHICLE_BIDDING_INFO_DETAILS });

  const vehicleDetailsInstance = new FetchVehicleRecordBiddingInfo({
    params: {
      vehicleId: vehicleId,
      isAllBids : isAllBids,
    },
    credentials: getState().authentication.credentials,
  });

  vehicleDetailsInstance.call().then(
    (response) => {
      dispatch(fetchVehicleBiddingInfoSuccess(response));
    },
    (err) => {
      dispatch(fetchVehicleBiddingInfoError(err));
    }
  );
};

export const fetchVehicleBiddingInfoSuccess = (data) => {
  return {
    type: FETCH_VEHICLE_BIDDING_INFO_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchVehicleBiddingInfoError = (error) => {
  return {
    type: FETCH_VEHICLE_BIDDING_INFO_DETAILS_ERROR,
    payload: error,
  };
};

export const fetchVehicleInspections = (vehicleId) => (dispatch, getState) => {
  dispatch({ type: FETCH_VEHICLE_INSPECTION_DETAILS });

  const vehicleDetailsInstance = new FetchVehicleRecordInspections({
    params: {
      vehicleId: vehicleId,
    },
    credentials: getState().authentication.credentials,
  });

  vehicleDetailsInstance.call().then(
    (response) => {
      dispatch(fetchVehicleInspectionsSuccess(response));
    },
    (err) => {
      dispatch(fetchVehicleInspectionsError(err));
    }
  );
};

export const fetchVehicleInspectionsSuccess = (data) => {
  return {
    type: FETCH_VEHICLE_INSPECTION_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchVehicleInspectionsError = (error) => {
  return {
    type: FETCH_VEHICLE_INSPECTION_DETAILS_ERROR,
    payload: error,
  };
};

export const setActiveNavigationOption = (activeNavOption) => {
  return {
    type: SET_ACTIVE_NAVIGATION_OPTION,
    payload: activeNavOption,
  };
};

export const clearActiveNavigationOption = () => {
  return {
    type: CLEAR_ACTIVE_NAVIGATION_OPTION,
  };
};

export const setActiveInspectionId = (activeInspectionId) => {
  return {
    type: SET_ACTIVE_INSPECTION_ID,
    payload: activeInspectionId,
  };
};

export const clearActiveInspectionId = () => {
  return {
    type: CLEAR_ACTIVE_INSPECTION_ID,
  };
};

export const genericFetchEventForNavigation =
  (activeNavOption, vehicleId) => (dispatch, getState) => {
    switch (activeNavOption) {
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.SALES_INFO:
        return dispatch(fetchVehicleSalesInfo(vehicleId));
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.PURCHASE_INFO:
        return dispatch(fetchVehiclePurchaseInfo(vehicleId));
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.EVENT_HISTORY:
        return dispatch(fetchVehicleEventHistory(vehicleId));
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.PROFILES:
        return dispatch(fetchVehicleRecordProfiles(vehicleId));
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.STANDARD_EQUIPMENT:
        const hasStandardEquipment =
          !!getState().vehicle.standardEquipmentData.standardEquipment;
        if (!hasStandardEquipment) {
          return dispatch(getStandardEquipment(vehicleId));
        }
        break;
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.TECHNICAL_SPECIFICATION:
        const hasTechnicalSpecification =
          !!getState().vehicle.technicalSpecificationData.data;
        if (!hasTechnicalSpecification) {
          return dispatch(getTechnicalSpecification(vehicleId));
        }
        break;
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.MAINTENANCE_HISTORY:
        const hasServiceHistory =
          !!getState().vehicle.serviceHistoryData.serviceHistory;
        if (!hasServiceHistory) {
          return dispatch(fetchServiceHistory(vehicleId));
        }
        break;
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.AUCTION_SALES:
        return dispatch(fetchVehicleRecordAuctionSales(vehicleId));
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.BIDDING_INFO:
        return dispatch(fetchVehicleBiddingInfo(vehicleId));
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.INSPECTION_REPORT:
        return dispatch(fetchVehicleInspections(vehicleId));
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.DOCUMENTATION:
          return dispatch(fetchDocuments(vehicleId));
      default:
        return dispatch(fetchVehicleSalesInfo(vehicleId));
    }
  };

const initialState = {
    primaryDetails: {
        data: null,
        isFetching: false,
        error: null,
    },
    moreInfoDetails: {
        data: null,
        isFetching: false,
        error: null,
    },
    salesInformation: {
        data: null,
        isFetching: false,
        error: null,
    },
    eventHistory:{
        data: null,
        isFetching: false,
        error: null,
    },
    profileSummary: {
      profiles: null,
      daysOnSale: null,
      isFetching: false,
      error: null,
    },
    purchaseInformation: {
      data: null,
      isFetching: false,
      error: null,
    },
    auctionSales: {
      data: null,
      isFetching: false,
      error: null,
    },
    biddingInformation: {
      bids: null,
      acceptedBid:null,
      salesInformation: null,
      isFetching: false,
      error: null,
    },
    inspections:{
      activeInspectionId:null,
      data:null,
      isFetching:false,
      error: null,
    },
    activeNavOption: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VEHICLE_PRIMARY_DETAILS:
      return {
        ...state,
        primaryDetails: {
          ...state.primaryDetails,
          isFetching: true,
        },
      };
    case FETCH_VEHICLE_PRIMARY_DETAILS_SUCCESS:
      return {
        ...state,
        primaryDetails: {
          ...state.primaryDetails,
          data: action.payload,
          isFetching: false,
        },
        inspections:{
          ...state.inspections,
          activeInspectionId: action.payload?.gradeOtherInfo?.mostRecentInspectionId,
        }
      };
    case FETCH_VEHICLE_PRIMARY_DETAILS_ERROR:
      return {
        ...state,
        primaryDetails: {
          ...state.primaryDetails,
          isFetching: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_MORE_INFO_DETAILS:
      return {
        ...state,
        moreInfoDetails: {
          ...state.moreInfoDetails,
          isFetching: true,
        },
      };
    case FETCH_VEHICLE_MORE_INFO_DETAILS_SUCCESS:
      return {
        ...state,
        moreInfoDetails: {
          ...state.moreInfoDetails,
          data: action.payload,
          isFetching: false,
        },
      };
    case FETCH_VEHICLE_MORE_INFO_DETAILS_ERROR:
      return {
        ...state,
        moreInfoDetails: {
          ...state.moreInfoDetails,
          error: action.payload,
          isFetching: false,
        },
      };
    case FETCH_VEHICLE_SALES_INFO_DETAILS:
      return {
        ...state,
        salesInformation: {
          ...state.salesInformation,
          isFetching: true,
        },
      };
    case FETCH_VEHICLE_SALES_INFO_DETAILS_SUCCESS:
      return {
        ...state,
        salesInformation: {
          ...state.salesInformation,
          data: action.payload,
          isFetching: false,
        },
      };
    case FETCH_VEHICLE_SALES_INFO_DETAILS_ERROR:
      return {
        ...state,
        salesInformation: {
          ...state.salesInformation,
          isFetching: false,
          error: action.payload,
        },
      };

    case FETCH_VEHICLE_EVENT_HISTORY:
      return {
        ...state,
        eventHistory: {
          ...state.eventHistory,
          isFetching: true,
        },
      };
    case FETCH_VEHICLE_EVENT_HISTORY_SUCCESS:
      return {
        ...state,
        eventHistory: {
          ...state.eventHistory,
          data: action.payload,
          isFetching: false,
        },
      };
    case FETCH_VEHICLE_EVENT_HISTORY_ERROR:
      return {
        ...state,
        eventHistory: {
          ...state.eventHistory,
          isFetching: false,
          error: action.payload,
        },
      };
    case SET_ACTIVE_NAVIGATION_OPTION:
      return {
        ...state,
        activeNavOption: action.payload,
      };
    case FETCH_VEHICLE_RECORD_PROFILES:
      return {
        ...state,
        profileSummary: {
          ...state.profileSummary,
          isFetching: true,
        },
      };
    case FETCH_VEHICLE_RECORD_PROFILES_SUCCESS:
      return {
        ...state,
        profileSummary: {
          ...state.profileSummary,
          profiles: action.payload?.profiles,
          daysOnSale: action.payload?.daysOnSale,
          isFetching: false,
        },
      };
    case FETCH_VEHICLE_RECORD_PROFILES_ERROR:
      return {
        ...state,
        profileSummary: {
          ...state.profileSummary,
          isFetching: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_RECORD_AUCTION_SALES:
      return {
        ...state,
        auctionSales: {
          ...state.auctionSales,
          isFetching: true,
        },
      };
    case FETCH_VEHICLE_RECORD_AUCTION_SALES_SUCCESS:
      return {
        ...state,
        auctionSales: {
          ...state.auctionSales,
          data: action.payload,
          isFetching: false,
        },
      };
    case FETCH_VEHICLE_RECORD_AUCTION_SALES_ERROR:
      return {
        ...state,
        auctionSales: {
          ...state.auctionSales,
          isFetching: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_PURCHASE_INFO_DETAILS:
      return {
        ...state,
        purchaseInformation: {
          ...state.purchaseInformation,
          isFetching: true,
        },
      };
    case FETCH_VEHICLE_PURCHASE_INFO_DETAILS_SUCCESS:
      return {
        ...state,
        purchaseInformation: {
          ...state.purchaseInformation,
          data: action.payload,
          isFetching: false,
        },
      };
    case FETCH_VEHICLE_PURCHASE_INFO_DETAILS_ERROR:
      return {
        ...state,
        purchaseInformation: {
          ...state.purchaseInformation,
          isFetching: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_BIDDING_INFO_DETAILS:
      return {
        ...state,
        biddingInformation: {
          ...state.biddingInformation,
          isFetching: true,
        },
      };
    case FETCH_VEHICLE_BIDDING_INFO_DETAILS_SUCCESS:
      return {
        ...state,
        biddingInformation: {
          ...state.biddingInformation,
          bids: action.payload?.bids,
          acceptedBid:action.payload?.acceptedBid,
          salesInformation: action.payload?.salesInfo,
          isFetching: false,
        },
      };
    case FETCH_VEHICLE_BIDDING_INFO_DETAILS_ERROR:
      return {
        ...state,
        biddingInformation: {
          ...state.biddingInformation,
          isFetching: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_INSPECTION_DETAILS:
      return {
        ...state,
        inspections: {
          ...state.inspections,
          isFetching: true,
        },
      };
    case FETCH_VEHICLE_INSPECTION_DETAILS_SUCCESS:
      return {
        ...state,
        inspections: {
          ...state.inspections,
          data: action.payload,
          isFetching: false,
        },
      };
    case FETCH_VEHICLE_INSPECTION_DETAILS_ERROR:
      return {
        ...state,
        inspections: {
          ...state.inspections,
          isFetching: false,
          error: action.payload,
        },
      };
    case SET_ACTIVE_INSPECTION_ID:
      return {
        ...state,
        inspections: {
          ...state.inspections,
          activeInspectionId: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
