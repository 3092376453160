import React from "react";
import { formatPrice } from "../../../shared/utility";

const PriceText = ({ content, className }) => (
  <p className={className}>
    <span>£</span>
    {formatPrice(content)}
  </p>
);

export default PriceText;
