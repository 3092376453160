import styled from "styled-components";
import BidInput from "./BidInput";
import breakpoints from "../../theme/breakpoints";

const StyledBidInput = styled(BidInput)`
  .bid-input {
    &__fields {
      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        display: flex;
      }
    }

    &__input {
      margin-bottom: ${props => props.theme.SPACING.SMALL};
      position: relative;
      width: 100%;

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        margin-bottom: 0;
      }
    }
  }
`;

export default StyledBidInput;
