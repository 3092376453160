import MakeOfferConfirmation from "./MakeOfferConfirmation";
import styled from "styled-components";

const StyledMakeOfferConfirmation = styled(MakeOfferConfirmation)`
  .confirmation-container {
    min-height: 45vh;
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }
`;

export default StyledMakeOfferConfirmation;
