import {
  FetchVendorDashboardInfo,
  FetchVendorStats,
  FetchVendorResponseTimeSla,
} from "../../api/ClaimVendorDashboard";
import { updateObject } from "../../shared/utility";

export const FETCH_VENDOR_CLAIMS_DASHBOARD_INFO =
  "@claimsVendorDashboard/FETCH_VENDOR_CLAIMS_DASHBOARD_INFO";
export const FETCH_VENDOR_CLAIMS_DASHBOARD_INFO_SUCCESS =
  "@claimsVendorDashboard/FETCH_VENDOR_CLAIMS_DASHBOARD_INFO_SUCCESS";
export const FETCH_VENDOR_CLAIMS_DASHBOARD_INFO_ERROR =
  "@claimsVendorDashboard/FETCH_VENDOR_CLAIMS_DASHBOARD_INFO_ERROR";
export const FETCH_VENDOR_STATS = "@claimsVendorDashboard/FETCH_VENDOR_STATS";
export const FETCH_VENDOR_STATS_SUCCESS =
  "@claimsVendorDashboard/FETCH_VENDOR_STATS_SUCCESS";
export const FETCH_VENDOR_STATS_ERROR =
  "@claimsVendorDashboard/FETCH_VENDOR_STATS_ERROR";
export const FETCH_VENDOR_RESPONSE_TIME_SLA =
  "@claimsVendorDashboard/FETCH_VENDOR_RESPONSE_TIME_SLA";
export const FETCH_VENDOR_RESPONSE_TIME_SLA_SUCCESS =
  "@claimsVendorDashboard/FETCH_VENDOR_RESPONSE_TIME_SLA_SUCCESS";
export const FETCH_VENDOR_RESPONSE_TIME_SLA_ERROR =
  "@claimsVendorDashboard/FETCH_VENDOR_RESPONSE_TIME_SLA_ERROR";

export const fetchVendorClaimsDashboardInfo = () => (dispatch, getState) => {
  dispatch({ type: FETCH_VENDOR_CLAIMS_DASHBOARD_INFO });
  const registration = getState().regSearch.value;
  let params = { registration };

  const dashboardInfoInstance = new FetchVendorDashboardInfo({
    params,
    credentials: getState().authentication.credentials,
  });

  dashboardInfoInstance.call().then(
    (response) => {
      dispatch(fetchVendorClaimsDashboardInfoSuccess(response));
    },
    (err) => {
      dispatch(fetchVendorClaimsDashboardInfoError(err));
    }
  );
};

export const fetchVendorClaimsDashboardInfoSuccess = (data) => {
  return {
    type: FETCH_VENDOR_CLAIMS_DASHBOARD_INFO_SUCCESS,
    payload: data,
  };
};

export const fetchVendorClaimsDashboardInfoError = (error) => {
  return {
    type: FETCH_VENDOR_CLAIMS_DASHBOARD_INFO_ERROR,
    payload: error,
  };
};

export const fetchVendorStats = (statType) => (dispatch, getState) => {
  dispatch({ type: FETCH_VENDOR_STATS, statType: statType });

  const vendorStatsInstance = new FetchVendorStats({
    params: {
      statType: statType,
    },
    credentials: getState().authentication.credentials,
  });

  vendorStatsInstance.call().then(
    (response) => {
      dispatch(fetchVendorStatsSuccess(response, statType));
    },
    (err) => {
      dispatch(fetchVendorStatsError(err));
    }
  );
};

export const fetchVendorStatsSuccess = (data, statType) => {
  return {
    type: FETCH_VENDOR_STATS_SUCCESS,
    payload: { data, statType },
  };
};

export const fetchVendorStatsError = (error, statType) => {
  return {
    type: FETCH_VENDOR_STATS_ERROR,
    payload: { error, statType },
  };
};

export const fetchVendorResponseTimeSla = () => (dispatch, getState) => {
  dispatch({ type: FETCH_VENDOR_RESPONSE_TIME_SLA });

  const vendorStatsInstance = new FetchVendorResponseTimeSla({
    credentials: getState().authentication.credentials,
  });

  vendorStatsInstance.call().then(
    (response) => {
      dispatch(fetchVendorResponseTimeSlaSuccess(response));
    },
    (err) => {
      dispatch(fetchVendorResponseTimeSlaError(err));
    }
  );
};

export const fetchVendorResponseTimeSlaSuccess = (data) => {
  return {
    type: FETCH_VENDOR_RESPONSE_TIME_SLA_SUCCESS,
    payload: data,
  };
};

export const fetchVendorResponseTimeSlaError = (error) => {
  return {
    type: FETCH_VENDOR_RESPONSE_TIME_SLA_ERROR,
    payload: error,
  };
};

const initialState = {
  isFetching: false,
  data: {
    newClaimsActionRequiredCount: 0,
    newClaimsTodayCount: 0,
    newClaimsTotalCount: 0,
    unallocatedCallBacksRequestedCount: 0,
    claimsInProgressTotalCount: 0,
    claimsInProgressActionRequiredCount: 0,
    callBacksRequestedCount: 0,
    recentClosedClaimsCount: 0,
    recentDays: 0,
    offerActionClaimsCount: 0,
    unallocatedOfferActionClaimsCount: 0,
  },
  error: false,
  fusionCharts: {
    managedBy: {
      isFetching: false,
      data: {
        chart: {
          caption: "Managed By and Status",
          yAxisName: "Status",
          theme: "fusion",
        },
        categories: [],
        dataset: [],
      },
      error: false,
    },
    categoryVsMake: {
      isFetching: false,
      data: {
        chart: {
          caption: "Make vs Category",
          yAxisName: "Claim Category",
          theme: "fusion",
          plotSpacePercent: "1.2rem",
          showLabels: "1",
          labelFontSize: "1.2rem",
          legendItemFontSize: "1rem",
          showTooltip: 0,
        },
        categories: [],
        dataset: [],
      },
      error: false,
    },
    claimCategory: {
      isFetching: false,
      data: {
        chart: {
          caption: "Claim Category and Status",
          yAxisName: "Status",
          theme: "fusion",
        },
        categories: [],
        dataset: [],
      },
      error: false,
    },
    responseTimeSla: {
      isFetching: false,
      error: false,
      data: {
        chart: {
          caption: "Claims Response SLA",
          lowerlimit: 95,
          upperlimit: 100,
          theme: "fusion",
          showValue: 1,
          valueBelowPivot: 1,
          numbersuffix: "%",
        },
        colorrange: {
          color: [
            {
              minvalue: 79,
              maxvalue: 96.4,
              code: "#E81A0E",
            },
            {
              minvalue: 96.4,
              maxvalue: 98.8,
              code: "#F39200",
            },
            {
              minvalue: 98.8,
              maxvalue: 100,
              code: "#A5C847",
            },
          ],
        },
        dials: {
          dial: [
            {
              value: null,
            },
          ],
        },
      },
    },
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VENDOR_CLAIMS_DASHBOARD_INFO:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_VENDOR_CLAIMS_DASHBOARD_INFO_SUCCESS:
      return updateObject(state, {
        isFetching: false,
        data: action.payload,
      });
    case FETCH_VENDOR_CLAIMS_DASHBOARD_INFO_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case FETCH_VENDOR_STATS:
      return {
        ...state,
        fusionCharts: {
          ...state.fusionCharts,
          [action.statType]: {
            ...state.fusionCharts[action.statType],
            isFetching: true,
          },
        },
      };
    case FETCH_VENDOR_STATS_SUCCESS:
      return {
        ...state,
        fusionCharts: {
          ...state.fusionCharts,
          [action.payload.statType]: {
            ...state.fusionCharts[action.payload.statType],
            isFetching: false,
            data: {
              ...state.fusionCharts[action.payload.statType].data,
              categories: action.payload.data.categories,
              dataSet: action.payload.data.dataset,
            },
          },
        },
      };
    case FETCH_VENDOR_STATS_ERROR:
      return {
        ...state,
        fusionCharts: {
          ...state.fusionCharts,
          [action.payload.statType]: {
            isFetching: false,
            error: action.payload,
          },
        },
      };
    case FETCH_VENDOR_RESPONSE_TIME_SLA:
      return {
        ...state,
        fusionCharts: {
          ...state.fusionCharts,
          responseTimeSla: {
            ...state.fusionCharts.responseTimeSla,
            isFetching: true,
          },
        },
      };
    case FETCH_VENDOR_RESPONSE_TIME_SLA_SUCCESS:
      return {
        ...state,
        fusionCharts: {
          ...state.fusionCharts,
          responseTimeSla: {
            ...state.fusionCharts.responseTimeSla,
            isFetching: false,
            data: {
              ...state.fusionCharts.responseTimeSla.data,
              dials: {
                ...state.fusionCharts.responseTimeSla.data.dials,
                dial: [{ value: action.payload }],
              },
            },
          },
        },
      };
    case FETCH_VENDOR_RESPONSE_TIME_SLA_ERROR:
      return {
        ...state,
        fusionCharts: {
          ...state.fusionCharts,
          responseTimeSla: {
            isFetching: false,
            error: action.payload,
          },
        },
      };

    default:
      return state;
  }
};

export default reducer;
