import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import PropTypes from "prop-types";
import Button from "./../Button";
import Icon from "./../Icon";
import ParagraphText from "./../ParagraphText";

const Logout = ({ className, onLogout, onCancel }) => {
  const theme = useContext(ThemeContext);
  return (
    <div className={className}>
      <div className="content">
        <div className="icon">
          <Icon
            type="lock"
            width="48"
            height="64"
            strokeColour={theme.COLOURS.ACCENT.base}
            strokeWidth="1"
          />
        </div>
        <div className="content__title">Log out</div>
        <div className="content__text">
          <ParagraphText primary>
            Click Log out below to sign out of your account.
          </ParagraphText>
          <ParagraphText primary>We hope to see you back soon!</ParagraphText>
        </div>
        <div className="content__actions">
          <Button action={onCancel} secondary ghost content="Cancel" />
          <Button action={onLogout} primary content="Log out" />
        </div>
      </div>
    </div>
  );
};

Logout.propTypes = {
  className: PropTypes.string,
  onLogout: PropTypes.func,
  onCancel: PropTypes.func,
};

export default Logout;
