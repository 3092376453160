import React, { useContext } from "react";
import Dropdown from "../../Dropdown";
import { ItemContainer, ItemLabel, ItemContent } from "../index";
import { subHeaderText, categoryDropdown, errorMessages } from "../consts";
import SubHeaderText from "../../SubHeaderText";
import {
  ClaimCategoryContext,
  ClaimSubCategoryContext,
} from "./../context/context";
import BidInputError from "../../BidInput/child-components/BidInputError";

export const ClaimCategory = ({ options, disabled }) => {
  const { claimCategory, setClaimCategory } = useContext(ClaimCategoryContext);
  const { setClaimSubCategory } = useContext(ClaimSubCategoryContext);

  function onCategoryChange(value) {
    setClaimCategory(value);
    setClaimSubCategory("");
  }

  const isValidCategory = !options?.categories?.find(
    (x) => x.value === claimCategory
  )?.expired;

  const hasValidCategories =
    options?.categories?.filter((cat) => !cat.expired).length > 0;

  return (
    <ItemContainer>
      <ItemLabel>
        <SubHeaderText>{subHeaderText.CLAIM_CATEGORY}:</SubHeaderText>
      </ItemLabel>
      <ItemContent>
        <Dropdown
          options={options?.categories || []}
          selectedOption={claimCategory}
          placeholder={categoryDropdown.PLACEHOLDER}
          noResultsText={categoryDropdown.NO_RESULTS_TEXT}
          onInputChange={(e) => onCategoryChange(e.value)}
          disabled={disabled}
          isValid={isValidCategory && hasValidCategories}
        />
        {(!isValidCategory || !hasValidCategories) && (
          <BidInputError
            errorMessage={
              hasValidCategories
                ? errorMessages.SELECT_VALID_CATEGORY
                : errorMessages.ALL_CATEGORIES_EXPIRED
            }
          />
        )}
      </ItemContent>
    </ItemContainer>
  );
};

export default ClaimCategory;
