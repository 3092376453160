import styled, { css } from "styled-components";
import Header from "./Header";
import breakpoints from "../../theme/breakpoints";

export const StyledHeader = styled(Header)`
  background-color: ${props =>
    props.backgroundColour
      ? props.backgroundColour
      : props.theme.COLOURS.SECONDARY.base};
    position: relative;
    z-index: 10;

    &[data-open="true"] {
      z-index: 100;
    }

  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: url("${backgroundImage}");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    `}

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    position: static;
  }
`;

export default StyledHeader;
