import Base from "./Base";
import clientSettings from "../clientappsettings";

export class FundingProvidersApi extends Base {
  baseUrl = clientSettings.BUYERS_URL;
  requestUrl = `/Funding/Providers/${this.getParams().vehiclePurchaseId}`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class IsFundingAllowedApi extends FundingProvidersApi {
  requestUrl = `/Funding/isFundingAllowed/${this.getParams().vehiclePurchaseId}`;
}

export class CreditUtilisationApi extends FundingProvidersApi {
  requestUrl = `/Funding/CreditUtilisation`;
  method = "POST";
}

export class CreditFundingApi extends FundingProvidersApi {
  requestUrl = `/Funding/CreditFunding`;
  method = "POST";
}
