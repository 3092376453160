import Base from "./Base";
import clientSettings from "../clientappsettings";
import { formatFilterOptions } from "../shared/filter";

class ActivityLogs extends Base {
  baseUrl = clientSettings.BUYERS_URL;
  requestUrl = "/Activity";
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatRequestData(params) {
    if (params) {
      const { filters, sort, pageSize, registration, pageNumber } = params;
      return {
        sort,
        pageSize,
        registration,
        pageNumber,
        ...formatFilterOptions({
          filterObject: filters,
          pageType: "activityLogs",
        }),
      };
    }
  }

  formatResponseData(response) {
    if (response.data) {
      return response.data;
    }
    return response;
  }
}

export default ActivityLogs;
