import React from "react";

const SubHeaderText = React.forwardRef(({ children, className }, ref) => {
  return (
    <h3 className={className} ref={ref}>
      {children}
    </h3>
  );
});

export default SubHeaderText;
