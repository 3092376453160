import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const AddNote = ({ width, height, fill }) => {
  const theme = useContext(ThemeContext);
  const fillColour = fill ? fill : theme.COLOURS.ACCENT.base;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1"
      viewBox="0 0 361.29 366.41"
    >
      <defs>
        <style>{`.cls-2{fill: ${fillColour}}.cls-3{fill:#fff}`}</style>
        <clipPath id="clip-path" transform="translate(0 -.55)">
          <path fill="none" d="M0 0H360.96V367.92H0z" />
        </clipPath>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path className="cls-2" d="M11.26 19.34H259.62V349.71H11.26z" />
          <path
            className="cls-3"
            d="M129.93 217.79L305 46.34l19.1 4.4 16.9 12 3 25.47-5.63 20.09S319 127.89 318.5 128.62s-132 132-132 132l-68.33 11z"
            transform="translate(0 -.55)"
          />
          <path
            className="cls-2"
            d="M243 341.14a2.25 2.25 0 01-2.25 2.25h-215a2.26 2.26 0 01-2.25-2.25V26.37a2.26 2.26 0 012.25-2.25h215a2.25 2.25 0 012.25 2.25v59.76l23.3-26.63.3-33.93a25.86 25.86 0 00-25.81-25h-215A25.85 25.85 0 000 26.37v314.77A25.86 25.86 0 0025.82 367h215a25.86 25.86 0 0025.83-25.82V194.5L243 208.83z"
            transform="translate(0 -.55)"
          />
          <path
            className="cls-3"
            d="M41.19 277A11.79 11.79 0 0053 288.74h41.2a35.22 35.22 0 01-5.06-23.57H53A11.78 11.78 0 0041.19 277zM101.84 206H53a11.79 11.79 0 100 23.57h43.74zM144.49 143.85H53a11.79 11.79 0 100 23.58h67.94z"
            transform="translate(0 -.55)"
          />
          <g clipPath="url(#clip-path)">
            <path
              className="cls-3"
              d="M203.65 87.69H53a11.79 11.79 0 000 23.58h127.07z"
              transform="translate(0 -.55)"
            />
            <path
              d="M346.38 48.24a49 49 0 00-70-.53l-146 146a11.81 11.81 0 00-3.18 5.84l-14.6 67.39a11.78 11.78 0 0014 14l67.4-14.6a11.94 11.94 0 005.84-3.19l146-146a49 49 0 00.5-68.94zm-206.82 205.8l6.15-28.39L168 247.89zm189.65-153.53L191.56 238.16l-36.12-36.12L293.09 64.38a25.544 25.544 0 1136.12 36.13z"
              stroke="#fff"
              strokeWidth="2px"
              fill={fillColour}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

AddNote.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
};

AddNote.defaultProps = {
  width: "33",
  height: "30"
};

export default AddNote;
