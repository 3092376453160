import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { PopoverFilterContext } from "../../../shared/context";
import Trigger from "./child-components/Trigger";
import Panel from "./child-components/Panel";

const PopoverFilter = ({
  className,
  type,
  label,
  isFetching,
  isSearching,
  selectedLabel,
  selectedCount,
  isOpen,
  isMultiSelect,
  isDisabled,
  onDropdownSelect,
  children,
  position,
  campaignPopoverLabel,
}) => {
  const primaryFilterIsActive = isOpen ? "-active" : "";

  return (
    <div
      className={classnames(
        `qa-primary-filter__option-${type}${primaryFilterIsActive}`,
        className,
        position,
        type
      )}
    >
      {!campaignPopoverLabel && (
      <span
        className={classnames("filter-label", {
          "filter-label--disabled": isDisabled
        })}
      >
        {label}
      </span>
      )}
      <PopoverFilterContext.Provider
        value={{
          label,
          type,
          isMultiSelect,
          selectedCount,
          selectedLabel,
          isOpen,
          isDisabled,
          onDropdownSelect,
          isFetching,
          isSearching
        }}
      >
        <Trigger />
        {isOpen && <Panel>{children}</Panel>}
      </PopoverFilterContext.Provider>
    </div>
  );
};

PopoverFilter.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  isFetching: PropTypes.bool,
  isSearching: PropTypes.bool,
  selectedLabel: PropTypes.string,
  selectedCount: PropTypes.number,
  isOpen: PropTypes.bool,
  onDropdownSelect: PropTypes.func,
  children: PropTypes.node,
  isMultiSelect: PropTypes.bool
};

PopoverFilter.defaultProps = {
  onDropdownSelect: () => {},
  isDisabled: false
};

export default PopoverFilter;
