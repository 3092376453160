import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../../views/components/ModalInsert";
import Panel from "../../../views/components/Panel";
import VendorCancelSaleDecision from "../../../views/containers/VendorCancelSaleDecision";
import CancelSaleForm from "../../components/CancelSaleForm";
import SkeletonRows from "../../../views/components/Skeletons/SkeletonRows";

const CancelSaleModalInsert = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.claimDetailsVendor.data);
  const activeClaimId = useSelector(
    (state) => state.global.claims.activeClaimId
  );

  const radioOptions = [
    { value: 0, label: "FullInvoiceValue", optionName: "Full Invoice Value" },
    {
      value: 1,
      label: "VehicleProceedsOnly",
      optionName: "Vehicle Purchase Price Only",
    },
  ];

  const dropdownOptions = [
    { value: 0, label: "IncorrectAccount", optionName: "Incorrect Account" },
    { value: 1, label: "NoQuibble", optionName: "No Quibble" },
    { value: 2, label: "Misdescription", optionName: "Misdescription" },
    { value: 3, label: "NoneSpecified", optionName: "None Specified" },
    { value: 4, label: "OverdueCollection", optionName: "Overdue Collection" },
    { value: 5, label: "IncorrectPrice", optionName: "Incorrect Price" },
    { value: 6, label: "QualityOfVehicle", optionName: "Quality of Vehicle" },
    { value: 7, label: "Other", optionName: "Other" },
  ];

  const [cancelSaleRefundType, setCancelSaleRefundType] = useState(
    radioOptions[0].value
  );
  const [cancelSaleDecision, setCancelSaleDecision] = useState(false);
  const [cancelNotes, setCancelNotes] = useState("");

  const [cancellationReasonType, setCancellationReasonType] = useState(
    dropdownOptions[3].value
  );

  const formData = {
    claimId: activeClaimId,
    cancelSaleRefundType: cancelSaleRefundType,
    cancellationReasonType: cancellationReasonType,
    cancelNotes: cancelNotes,
  };

  const saleStatusMap = {
    PaymentPending: "Payment Pending",
    PaymentReceived: "Payment Received",
    Dispatched: "Dispatched",
    FundingApproved: "Funding Approved",
  };

  function onCategoryChange(value) {
    setCancellationReasonType(value);
  }

  const onCancel = () => {
    dispatch(closeModal());
  };

  const onClickCancelSaleDecision = () => {
    setCancelSaleDecision(true);
  };

  const CancelSaleFormContent = (
    <Panel
      mobileFlex
      style={{ height: "100%", borderRadius: "0.5rem" }}
      actions={[
        {
          content: "Close",
          action: onCancel,
          type: "ghost",
        },
        {
          content: "Cancel Sale",
          action: onClickCancelSaleDecision,
          type: "errorPrimary",
          disabled: data?.details?.anotherVehicleActive,
        },
      ]}
    >
      {!!data ? (
        <CancelSaleForm
          claimId={data.claimId}
          tradeBuyer={data.saleInfo.tradeBuyer}
          regNo={data.details.regNo}
          purchasePrice={data.saleInfo.purchasePrice}
          residualValue={data.details.residualValue}
          capValue={data.details.capValue}
          saleStatus={data.saleInfo.saleStatus}
          hasFees={data.details.hasFees}
          hasAutoLogistics={data.details.hasAutoLogistics}
          setCancelNotes={setCancelNotes}
          cancelSaleRefundType={cancelSaleRefundType}
          setCancelSaleRefundType={setCancelSaleRefundType}
          dropdownOptions={dropdownOptions}
          radioOptions={radioOptions}
          cancellationReasonType={cancellationReasonType}
          setCancellationReasonType={setCancellationReasonType}
          saleStatusMap={saleStatusMap}
          onCategoryChange={onCategoryChange}
          cancelNotes={cancelNotes}
          anotherVehicleActive={data.details.anotherVehicleActive}
        />
      ) : (
        <SkeletonRows nrOfRows={5} />
      )}
    </Panel>
  );

  return (
    <Fragment>
      <ModalInsert>
        {cancelSaleDecision ? (
          <VendorCancelSaleDecision
            formData={formData}
            buyerName={data.saleInfo.tradeBuyer}
          />
        ) : (
          CancelSaleFormContent
        )}
      </ModalInsert>
    </Fragment>
  );
};

export default CancelSaleModalInsert;
