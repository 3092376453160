// import BACKGROUND from "./assets/images/background.jpg";
import FAVICON from "./assets/images/favicons/favicon.ico";
import FAVICON_APPLE from "./assets/images/favicons/apple-touch-icon.png";

const IMAGES = {
  //   BACKGROUND,
  FAVICON,
  FAVICON_APPLE
};

export default IMAGES;
