import { GetSalesBrochure } from "../../api/SalesBrochure";

// Action constants
export const FETCH_SALES_BROCHURE = "@salesBrochure/FETCH_SALES_BROCHURE";
export const FETCH_SALES_BROCHURE_SUCCESS =
  "@salesBrochure/FETCH_SALES_BROCHURE_SUCCESS";
export const FETCH_SALES_BROCHURE_FAILURE =
  "@salesBrochure/FETCH_SALES_BROCHURE_FAILURE";

// Action creators
export const fetchSalesBrochureSuccess = () => {
  return {
    type: FETCH_SALES_BROCHURE_SUCCESS,
    payload: true
  };
};

export const fetchSalesBrochureFailure = error => {
  return {
    type: FETCH_SALES_BROCHURE_FAILURE,
    payload: error
  };
};

export const fetchSalesBrochure = data => (dispatch, getState) => {
  dispatch({ type: FETCH_SALES_BROCHURE });

  const instance = new GetSalesBrochure({
    params: data,
    credentials: getState().authentication.credentials
  });

  instance.call().then(
    response => {
      dispatch(fetchSalesBrochureSuccess());
    },
    err => {
      return dispatch(fetchSalesBrochureFailure(err));
    }
  );
};

const initialState = {
  isFetching: false,
  error: false,
  success: null
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SALES_BROCHURE:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_SALES_BROCHURE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case FETCH_SALES_BROCHURE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
