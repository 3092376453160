import React from "react";
import propTypes from "prop-types";

const InputText = (props) => {
  const {
    className,
    validValue,
    small,
    medium,
    withoutBorder,
    ...rest
  } = props;

  return <input type="text" className={className} {...rest} />;
};

InputText.propTypes = {
  className: propTypes.string,
  validValue: propTypes.bool,
  small: propTypes.bool,
  medium: propTypes.bool,
  withoutBorder: propTypes.bool,
};

export default InputText;
