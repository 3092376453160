import styled from "styled-components";

import InputLabel from "./InputLabel.jsx";
import breakpoints from "../../theme/breakpoints.js";

const StyledInputLabel = styled(InputLabel)`
  color: ${props => props.theme.COLOURS.PRIMARY.base};
  ${props => props.theme.FONTS.Text["inputLabelBold"]}
  font-size: 1.6rem;
  line-height: 1.6rem;

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    ${props => props.theme.FONTS.Text["inputLabelBold"]}
  }
`;

export default StyledInputLabel;