import Api from "./Base";
import settings from "../clientappsettings.json";

export class GetSalesBrochure extends Api {
  baseUrl = settings.IMAGES_API_URL;
  requestUrl = `/SalesBrochure`;
  method = "POST";
  responseType = "arraybuffer";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };

  formatRequestData(params) {
    const { pdfBrowserTab, ...rest } = params;

    return rest;
  }

  formatResponseData(response) {
    if (response.data) {
      const { pdfBrowserTab } = this.getParams();
      const blob = new Blob([response.data], { type: "application/pdf" });
      const fileURL = window.URL.createObjectURL(blob);
      pdfBrowserTab.location = fileURL;
    }

    return response;
  }
}
