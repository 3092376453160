import Api from "./Base";
import settings from "../clientappsettings.json";
import { formatFilterOptions } from "../shared/filter";

class FilterApi extends Api {
  baseUrl = settings.API_URL;
  requestUrl = "/Filter";
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatRequestData(params) {
    const { filterType, isShortlisting, filters, purchaseSession } = params;
    let requestParams = {
      purchaseSession,
      filterType: filterType === "maxPrice" ? "price" : filterType,
      ...formatFilterOptions({ filterObject: filters, pageType: "search" }),
    };

    if (isShortlisting) {
      requestParams.shortlist = true;
    }

    /**
     * TODO: this change is a workaround for the vendor -> vendors mismatch
     * in the BE and should be removed as part of the BE refactor
     */
    if (requestParams.vendors) {
      requestParams.vendor = requestParams.vendors;
      delete requestParams.vendors;
    }

    return requestParams;
  }

  formatResponseData(response) {
    return {
      filterId: this.options.params.filterType,
      data: {
        options: response.data,
      },
    };
  }
}

class BidsAndRequestsSecondaryFilterApi extends FilterApi {
  requestUrl = "/BidsAndRequests/Filter";

  /**
   * TODO: the `bidsAndRequestsShowMe` filter should really be 'showMe'
   * The BE is working on bringing this into line
   * @param params
   * @returns {{filterType: *}}
   */
  formatRequestData(params) {
    const {
      filterType,
      filters: { bidsAndRequestsShowMe, groupSite },
    } = params;
    return {
      filterType,
      ...formatFilterOptions({
        filterObject: { bidsAndRequestsShowMe, groupSite },
        pageType: "bidsAndRequests",
      }),
    };
  }
}

class InvoiceFilterApi extends FilterApi {
  baseUrl = settings.BUYERS_URL;
  requestUrl = "/Invoices/Filter";

  /**
   * TODO: we are sending only what we want to here
   * When the back end can support filters across multiple endpoints
   * we can stop the renaming (as with the `vendor` filter here)
   * @param params
   * @returns {{filterType: *}}
   */
  formatRequestData(params) {
    const {
      filterType,
      filters: { vendor, invoiceType, taxPointDate, groupSite },
    } = params;
    return {
      filterType: filterType === "vendor" ? "invoiceVendor" : filterType,
      ...formatFilterOptions({
        filterObject: {
          invoiceType,
          taxPointDate,
          invoiceVendor: vendor,
          groupSite,
        },
        pageType: "invoices",
      }),
    };
  }
}

class ActivityLogsFilterApi extends FilterApi {
  baseUrl = settings.BUYERS_URL;
  requestUrl = "/Activity/Filter";

  /**
   * TODO: we are sending only what we want to here
   * When the back end can support filters across multiple endpoints
   * we can stop the renaming (as with the `vendor` filter here)
   * @param params
   * @returns {{filterType: *}}
   */
  formatRequestData(params) {
    const {
      filterType,
      filters: { activityType, activityEventDate, activitySiteName },
    } = params;
    return {
      filterType,
      ...formatFilterOptions({
        filterObject: {
          activityType,
          activityEventDate,
          activitySiteName,
        },
        pageType: "activityLogs",
      }),
    };
  }
}

class PurchasesArchiveSecondaryFilterApi extends FilterApi {
  requestUrl = "/Purchases/Filter";

  formatRequestData(params) {
    const {
      filterType,
      filters: { groupSite, purchaseStatus, purchasedDate },
    } = params;
    return {
      filterType,
      ...formatFilterOptions({
        filterObject: { groupSite, purchaseStatus, purchasedDate },
        pageType: "purchasesArchive",
      }),
    };
  }
}

class MessagesFilterApi extends FilterApi {
  baseUrl = settings.BUYERS_URL;
  requestUrl = "/Messages/Filter";

  formatRequestData(params) {
    const {
      purchaseSession,
      filterType,
      filters: { showMe, groupSite, messageTypes },
    } = params;
    return {
      filterType: filterType,
      messagesBucket:purchaseSession,
      ...formatFilterOptions({
        filterObject: {
          showMe,
          groupSite,
          messageTypes,
        },
        pageType: "messages",
      }),
    };
  }
}

class ClaimsFilterApi extends FilterApi {
  baseUrl = settings.BUYERS_URL;
  requestUrl = "/Claims/Filter";

  formatRequestData(params) {
    const {
      filterType,
      filters: { claimStatusCategory },
      registration,
    } = params;
    return {
      filterType: filterType,
      registration: registration,
      ...formatFilterOptions({
        filterObject: {
          claimStatusCategory,
        },
        pageType: "claims",
      }),
    };
  }
}

class ClaimsVendorFilterApi extends FilterApi {
  baseUrl = settings.VENDORS_URL;
  requestUrl = "/Claims/Filter";

  formatRequestData(params) {
    const {
      primaryFilter,
      filters: { newClaims },
      registration,
    } = params;
    return {
      filterType: primaryFilter,
      registration: registration,
      ...formatFilterOptions({
        filterObject: {
          newClaims,
        },
        pageType: "vendor",
      }),
    };
  }
}

export {
  FilterApi,
  BidsAndRequestsSecondaryFilterApi,
  InvoiceFilterApi,
  PurchasesArchiveSecondaryFilterApi,
  MessagesFilterApi,
  ActivityLogsFilterApi,
  ClaimsFilterApi,
  ClaimsVendorFilterApi,
};
