import React, { Fragment } from "react";
import Icon from "../Icon";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import ParagraphText from "./../ParagraphText";

const NavigationItem = ({
  className,
  children,
  url,
  notificationCount,
  action,
  icon,
  hasChildren,
}) => {
  const hasAction = action && typeof action === "function";
  return (
    <Fragment>
      {hasAction ? (
        <div className={className} onClick={() => action()}>
          {icon && <Icon {...icon} />}
          {notificationCount && notificationCount !== 0 && (
            <div className="notification">
              <ParagraphText>{notificationCount}</ParagraphText>
            </div>
          )}
          <span>{children}</span>
          {hasChildren && (
            <span className="chevron">
              <Icon type="chevron-right" width="6" height="11" />
            </span>
          )}
        </div>
      ) : (
        <NavLink className={className} to={url || "#"} activeclassname="active">
          {icon && <Icon {...icon} />}
          {notificationCount && notificationCount !== 0 && (
            <div className="notification">
              <ParagraphText>{notificationCount}</ParagraphText>
            </div>
          )}
          <span>{children}</span>
          {hasChildren && (
            <span className="chevron">
              <Icon type="chevron-right" width="6" height="11" />
            </span>
          )}
        </NavLink>
      )}
    </Fragment>
  );
};

NavigationItem.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  url: PropTypes.string,
  action: PropTypes.func,
  notificationCount: PropTypes.string,
  icon: PropTypes.object,
};

export default NavigationItem;
