import { useDispatch, useSelector } from "react-redux";
import { countSelectedItems } from "../filter";
import { resetPagination } from "../../state/reducers/pagination";
import {
  fetchFilterOptions,
  toggleCheckboxFilterOption,
  setSelectFilterOption,
  setRangeFilterOption,
  setInputFilterOption,
  setTabFilterOption,
  toggleParentFilter,
  toggleChildFilter,
} from "../../state/reducers/filter";
import {
  clearActiveFilter,
  setActiveFilter,
} from "../../state/reducers/global";
import { clearVehicleRegRequest } from "../../state/reducers/regSearch";
import { clearInvoiceNumberRequest } from "../../state/reducers/invoices";

/**
 *
 * @param {{ filterType: string, inputType: string, filterId: string, compoundId }} init
 * @returns {{toggleFilter: (*|noOp), options, count: number, activeFilter, isFetching, updateFilter: (*|noOp), isSearching, selected}}
 */
const useFilterState = (init = {}) => {
  const { inputType, filterType, filterId } = init;
  const dispatch = useDispatch();
  const resultType = useSelector((state) => state.global.results.resultType);
  const isFetching = useSelector((state) => state.filter.isFetching);
  const isSearching = useSelector((state) => state[resultType].isFetching);
  const isActiveFilter = useSelector(
    (state) => state.global.filters.activeFilter === filterId
  );
  const filter = useSelector((state) => state.filter.filters[filterId]);

  let options = [];
  let selected = {};

  if (filter) {
    ({ options, selected } = filter);
  }

  const count = countSelectedItems({
    countObject: selected,
    inputType: filterType === "range" ? filterType : inputType,
  });

  let selectedLabel = "";

  const isCheckboxType =
    inputType === "checkbox" ||
    inputType === "compoundParent" ||
    inputType === "compoundChild";

  // TODO: target checkboxes for now
  if (isCheckboxType) {
    selectedLabel = selected.values
      .map((value) => options.find((option) => option.value === value))
      .reduce(
        (previousResult, currentItem) =>
          currentItem
            ? [...previousResult, currentItem.optionName]
            : previousResult,
        []
      )
      .join(", ");
  }

  const hasRegSearchValue = useSelector((state) => state.regSearch.value);
  const hasInvoiceSearchValue = useSelector(
    (state) => state.invoices.invoiceSearchValue
  );

  const updateActions = {
    checkbox: (value) => {
      if (hasRegSearchValue) {
        dispatch(clearVehicleRegRequest());
      }
      if (hasInvoiceSearchValue) {
        dispatch(clearInvoiceNumberRequest());
      }
      dispatch(
        toggleCheckboxFilterOption({
          filterId,
          value,
          withRefresh: filterType !== "primary",
        })
      );
    },
    select: (value) => {
      if (hasRegSearchValue) {
        dispatch(clearVehicleRegRequest());
      }
      if (hasInvoiceSearchValue) {
        dispatch(clearInvoiceNumberRequest());
      }
      dispatch(
        setSelectFilterOption({
          filterId,
          value,
          withRefresh: filterType !== "primary",
        })
      );
    },
    rangeTo: (value) => {
      if (hasRegSearchValue) {
        dispatch(clearVehicleRegRequest());
      }
      if (hasInvoiceSearchValue) {
        dispatch(clearInvoiceNumberRequest());
      }
      dispatch(resetPagination());
      dispatch(
        setRangeFilterOption({
          filterId,
          value: { to: value },
          withRefresh: filterType !== "primary",
        })
      );
    },
    rangeFrom: (value) => {
      if (hasRegSearchValue) {
        dispatch(clearVehicleRegRequest());
      }
      if (hasInvoiceSearchValue) {
        dispatch(clearInvoiceNumberRequest());
      }
      dispatch(resetPagination());
      dispatch(
        setRangeFilterOption({
          filterId,
          value: { from: value },
          withRefresh: filterType !== "primary",
        })
      );
    },
    compoundParent: ({ compoundId, value }) => {
      if (hasRegSearchValue) {
        dispatch(clearVehicleRegRequest());
      }
      if (hasInvoiceSearchValue) {
        dispatch(clearInvoiceNumberRequest());
      }
      dispatch(
        toggleParentFilter({
          compoundId,
          value,
          withRefresh: filterType !== "primary",
        })
      );
    },
    compoundChild: ({ compoundId, value }) => {
      if (hasRegSearchValue) {
        dispatch(clearVehicleRegRequest());
      }
      if (hasInvoiceSearchValue) {
        dispatch(clearInvoiceNumberRequest());
      }
      dispatch(
        toggleChildFilter({
          compoundId,
          value,
          withRefresh: filterType !== "primary",
        })
      );
    },
    input: (value) => {
      if (hasRegSearchValue) {
        dispatch(clearVehicleRegRequest());
      }
      if (hasInvoiceSearchValue) {
        dispatch(clearInvoiceNumberRequest());
      }
      dispatch(resetPagination());
      dispatch(
        setInputFilterOption({
          filterId,
          value,
          withRefresh: filterType !== "primary",
        })
      );
    },
    tab: (value) => {
      dispatch(
        setTabFilterOption({
          filterId,
          value,
          withRefresh: true,
        })
      );
    },
    noOp: () => {},
  };

  const toggleActions = {
    secondary: () => {
      if (!isActiveFilter) {
        dispatch(setActiveFilter(filterId));
        dispatch(fetchFilterOptions({ filterId }));
      } else {
        dispatch(clearActiveFilter());
      }
    },
    secondaryNoFilterFetch: () =>
      !isActiveFilter
        ? dispatch(setActiveFilter(filterId))
        : dispatch(clearActiveFilter()),
    primary: () => {
      if (!isActiveFilter) {
        dispatch(setActiveFilter(filterId));
        dispatch(fetchFilterOptions({ filterId }));
      } else {
        dispatch(clearActiveFilter());
      }
    },
    noOp: () => {},
  };

  return {
    isActiveFilter,
    isFetching,
    isSearching,
    options,
    selected,
    selectedLabel,
    count,
    updateFilter: updateActions[inputType] || updateActions["noOp"],
    toggleFilter: toggleActions[filterType] || toggleActions["noOp"],
  };
};

export default useFilterState;
