import DragDrop from "./DragDrop.jsx";
import styled from "styled-components";
import colours from "../../theme/base/colours";

const StyledDragDrop = styled(DragDrop)`
  .drag-drop__file-upload-container {
    display: flex;
    max-width: 100%;
    min-height: 200px;
    border: dashed 5px ${(props) => props.theme.COLOURS.PRIMARY.base};
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .disabled {
    border: dashed 0.2rem ${colours.PRIMARY.shades[20]};
    cursor: not-allowed;
    color: ${(props) => props.theme.COLOURS.RED.base};
  }
`;

export default StyledDragDrop;
