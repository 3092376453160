import clientSettings from "../clientappsettings.json";
import Api from "./Base";

/**
 * This class is not extended from the usual Base class
 * as it resulted in a circular dependency around authentication.
 */
class AuthenticationApi extends Api {
  baseUrl = clientSettings.USERS_URL;
  requestUrl = "/token";
  headers = {
    Authorization: `Basic ${this.getParams().credentials}`
  };
}

export class UserDefaultsApi extends Api {
  baseUrl = clientSettings.BUYERS_URL;
  requestUrl = `/Buyers/UserDefaults`;
  headers = {
    Authorization: `Bearer ${this.getParams().jwtToken}`
  };
}

export class TermsAndConditionsApi extends Api {
  baseUrl = clientSettings.BUYERS_URL;
  requestUrl = `/Buyers/TermsAndConditions/GetStatus`;
  headers = {
    Authorization: `Bearer ${this.getParams().jwtToken}`
  };
}

export class GetAccountAdministrationStatus extends Api {
  baseUrl = clientSettings.USERS_URL;
  requestUrl = "/user/Account/GetAccountAdministrationStatus";
  headers = {
    Authorization: `Bearer ${this.getParams().jwtToken}`
  };
}

export default AuthenticationApi;
