import BACKGROUND from "./assets/images/background.jpg";
import FAVICON from "./assets/images/favicons/favicon.ico";
import FAVICON_APPLE from "./assets/images/favicons/apple-touch-icon.png";
import LOGO from "./assets/images/logo.png";
import LOGO_DARK from "./assets/images/logo-black.png";

const IMAGES = {
  BACKGROUND,
  LOGO,
  LOGO_DARK,
  FAVICON,
  FAVICON_APPLE
};

export default IMAGES;
