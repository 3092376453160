import Api from "./Base";
import settings from "../clientappsettings.json";

export class SubscribePushNotification extends Api {
  baseUrl = settings.AZURE_NOTIFICATION_URL;
  requestUrl = `/api/SubscribePushNotification?code=${settings.AZURE_NOTIFICATION_API_KEY}`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatResponseData(response) {
    return response;
  }
}

export class UnSubscribePushNotification extends Api {
  baseUrl = settings.AZURE_NOTIFICATION_URL;
  requestUrl = `/api/UnSubscribePushNotification?code=${settings.AZURE_NOTIFICATION_API_KEY}`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatResponseData(response) {
    return response;
  }
}

export class SendPushNotification extends Api {
  baseUrl = settings.AZURE_NOTIFICATION_URL;
  requestUrl = `/api/SendPushNotification?code=${settings.AZURE_NOTIFICATION_API_KEY}`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatResponseData(response) {
    return response;
  }
}

export class GetPushNotificationPreferences extends Api {
  baseUrl = settings.AZURE_NOTIFICATION_URL;
  requestUrl = `/api/GetPushNotificationPreferences?code=${settings.AZURE_NOTIFICATION_API_KEY}`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatResponseData(response) {
    return response;
  }
}

export class UpdatePushNotificationPreferences extends Api {
  baseUrl = settings.AZURE_NOTIFICATION_URL;
  requestUrl = `/api/UpdatePushNotificationPreferences?code=${settings.AZURE_NOTIFICATION_API_KEY}`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatResponseData(response) {
    return response;
  }
}

export class ActivateCurrentNotificationUser extends Api {
  baseUrl = settings.AZURE_NOTIFICATION_URL;
  requestUrl = `/api/ActivateCurrentNotificationUser?code=${settings.AZURE_NOTIFICATION_API_KEY}`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatResponseData(response) {
    return response;
  }
}

export class TestNotifications extends Api {
  baseUrl = settings.BUYERS_URL;
  requestUrl = `/Test/SendPushNotification`;
  method = "GET";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatResponseData(response) {
    return response;
  }
}
