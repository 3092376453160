import Api from "./Base";
import clientSettings from "../clientappsettings";
import get from "lodash.get";

class ServiceHistoryApi extends Api {
  baseUrl = clientSettings.TRADE_BUYER_URL;
  requestUrl = `/VehicleInfo/serviceHistory/${this.getParams().id}`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };

  formatResponseData(response) {
    const data = get(response, "data", {});
    return {
      ...response,
      maintenanceHistory: get(data, "maintenanceHistory", []),
      source: get(data, "source", "")
    };
  }
}

export default ServiceHistoryApi;
