import styled from "styled-components";
import breakpoints from "../../../theme/breakpoints"

import SkeletonCheckboxCampaignManagement from "./SkeletonCheckboxCampaignManagement";

const StyledSkeletonCheckboxCampaignManagement = styled(SkeletonCheckboxCampaignManagement)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  @media (max-width: ${breakpoints.LARGE_PHONE}) {
    flex-direction: column;
    }

  > span {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    padding: 1rem 1.6rem 0 0;
    width: 100%;
  }
  .skeleton-checkbox-filter__checkbox {
    border: 0.2rem solid ${props => props.theme.COLOURS.PRIMARY.shades[20]};
    border-radius: 0.5rem;
    height: 2.5rem;
    margin-right: 0.5rem;
    width: 2.5rem;
  }

  .skeleton-checkbox-filter__label {
    ${props => props.theme.ANIMATIONS["shimmerDark"]};
    height: 1rem;
    width: 19rem;
  }
`;

export default StyledSkeletonCheckboxCampaignManagement;
