import styled from "styled-components";

import SkeletonHomePage from "./SkeletonHomePage";
import breakpoints from "../../../theme/breakpoints";

const StyledSkeletonHomePage = styled(SkeletonHomePage)`
  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    .skeleton__page-heading {
      max-width: 119rem;
      height: 28rem;
      margin: 0 auto;
      padding: ${props => props.theme.SPACING.LARGE} 0;
    }
    .skeleton__page-title {
      width: 20%;
      height: 3.5rem;
      display: block;
      background-color: ${props => props.theme.COLOURS.PRIMARY.shades[80]};
      ${props => props.theme.ANIMATIONS["shimmerDarker"]};
    }
    .skeleton__page-filters {
      display: flex;
      height: 10rem;
      margin-top: ${props => props.theme.SPACING.REGULAR};
    }
    .skeleton__page-filter {
      width: 20%;
      &:not(:last-child) {
        margin-right: ${props => props.theme.SPACING.REGULAR};
      }
    }
    .skeleton__page-filter-title {
      width: 60%;
      height: 2rem;
      display: block;
      background-color: ${props => props.theme.COLOURS.PRIMARY.shades[80]};
      ${props => props.theme.ANIMATIONS["shimmerDarker"]};
      margin-bottom: ${props => props.theme.SPACING.SMALL};
    }
    .skeleton__page-filter-options {
      width: 100%;
      height: 5rem;
      display: block;
      background-color: ${props => props.theme.COLOURS.PRIMARY.shades[80]};
      ${props => props.theme.ANIMATIONS["shimmerDarker"]};
    }

    .skeleton__page-button-container {
      display: flex;
      justify-content: flex-end;
      span {
        width: 10rem;
        height: 5rem;
        display: block;
        background-color: ${props => props.theme.COLOURS.PRIMARY.shades[80]};
        ${props => props.theme.ANIMATIONS["shimmerDarker"]};
      }

      .skeleton__page-button-container {
        display: flex;
        justify-content: flex-end;
        span {
          width: 10rem;
          height: 5rem;
          display: block;
          background-color: ${props => props.theme.COLOURS.PRIMARY.shades[80]};
          ${props => props.theme.ANIMATIONS["shimmerDarker"]};
          border-radius: 0.3rem;
        }
      }
    }
  }
`;

export default StyledSkeletonHomePage;
