import ClaimCallBackConfirmation from "./ClaimCallBackConfirmation";
import styled from "styled-components";

const StyledClaimCallBackConfirmation = styled(ClaimCallBackConfirmation)`
 .confirmation-container {
    min-height: 45vh;
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
 }
`;

export default StyledClaimCallBackConfirmation;

