import React, { Fragment, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearGroupBuyerSite,
  setGroupBuyerSite
} from "../../../state/reducers/bid";
import SubHeaderText from "../../components/SubHeaderText";
import Dropdown from "../../components/Dropdown";
import Button from "../../components/Button";
import Notification from "../../components/Notification";
import { ThemeContext } from "styled-components";

const GroupBuyerSelect = ({ vehicleId, isInitialBid }) => {
  const dispatch = useDispatch();

  const isGroupBuyer = useSelector(state => state.bid.isGroupBuyer);
  const groupSites = useSelector(state => state.bid.groupSites);
  const selectedGroupSite = useSelector(
    state =>
      state.bid.bids.byVehicleId[vehicleId] &&
      state.bid.bids.byVehicleId[vehicleId].selectedGroupSite
  );

  const onInputChange = groupSiteOption => {
    const selectedGroupSite = groupSites.find(
      groupSite => groupSite.value === groupSiteOption.value
    );
    dispatch(setGroupBuyerSite(vehicleId, selectedGroupSite));
  };
  const onClearGroupBuyerSelection = () => {
    dispatch(clearGroupBuyerSite(vehicleId));
  };

  const groupBuyerData = {
    isOpen: false,
    placeholder: "My Site",
    options: groupSites,
    selectedOption: selectedGroupSite && selectedGroupSite.value,
    onInputChange: onInputChange
  };

  const theme = useContext(ThemeContext);

  return (
    <Fragment>
      {isGroupBuyer && !!groupSites.length ? (
        <div className="bid-form__group-buyer">
          {isInitialBid && (
            <Fragment>
              <SubHeaderText>
                Which trade member are you bidding for?
              </SubHeaderText>
              <div className="bid-form__group-buyer-form">
                <Dropdown {...groupBuyerData} />
                {selectedGroupSite && (
                  <Button
                    content="Clear"
                    secondary
                    action={() => onClearGroupBuyerSelection()}
                  />
                )}
              </div>
            </Fragment>
          )}
          {!isInitialBid && selectedGroupSite && (
            <Notification
              title={`You are bidding for ‘${selectedGroupSite.optionName}’`}
              size="small"
              isAlert
              overrideIcon={{
                type: "info-circle",
                strokeColour: theme.COLOURS.WHITE,
                strokeWidth: "3"
              }}
            />
          )}
        </div>
      ) : null}
    </Fragment>
  );
};

export default GroupBuyerSelect;
