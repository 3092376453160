import React from "react";
import { PropTypes } from "prop-types";
import { useDropzone } from "react-dropzone";
import ParagraphText from "../ParagraphText";
import Icon from "../Icon";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../theme/breakpoints";

const DragDrop = ({
    className,
    disabled,
    maxFileSize,
    onDropRejected,
    onDrop,
    acceptedTypes,
    primaryParagraphText,
    secondaryParagraphText,
    allowMultiple,
    maxFiles
}) => {
    const { getRootProps, getInputProps } = useDropzone({
        accept: acceptedTypes,
        multiple: allowMultiple,
        disabled: disabled,
        onDrop: onDrop,
        maxSize: maxFileSize,
        onDropRejected: onDropRejected,
        maxFiles: maxFiles
    });

    const isMobile = useMediaQuery({
        query: `(max-width: ${breakpoints.PHONE})`,
    });

    return (
        <div className={className}>
            <div
                {...getRootProps({
                    className: `drag-drop__file-upload-container ${
                        disabled ? "disabled" : ""
                        }`,
                })}
            >
                <input {...getInputProps()} />
                <div className="drag-drop__file-upload-content">
                    <Icon type="arrow-download" width="24" height="24" />
                    {!isMobile && (
                        <div>
                           <ParagraphText primary>{primaryParagraphText}</ParagraphText>
                            <ParagraphText primary>{secondaryParagraphText}</ParagraphText> 
                        </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

DragDrop.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    maxFileSize: PropTypes.number,
    onDropRejected: PropTypes.func,
    onDrop: PropTypes.func,
    acceptedTypes: PropTypes.object,
    primaryParagraphText: PropTypes.string,
    secondaryParagraphText: PropTypes.string,
    allowMultiple: PropTypes.bool,
    maxFiles: PropTypes.number,
};

DragDrop.defaultProps = {
    maxFiles: 100,
    allowMultiple: true,
    primaryParagraphText: "Drag and Drop Your Files",
    secondaryParagraphText: "Or Click Here",
    acceptedTypes: {
                    "video/*": [],
                    "image/*": [],
                    "audio/mpeg": [".mp3"],
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
                        ".xlsx",
                    ],
                    "application/vnd.ms-excel": [".xls"],
                    "application/pdf": [".pdf"],
                    "text/csv": [".csv"],
                    "text/html": [".html", ".htm"],
                    "text/plain": [".txt"],
                    "application/msword": [".doc"],
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
                        ".docx",
                    ],
                },
};

export default DragDrop;