import React, { useContext, useEffect } from "react";
import { PropTypes } from "prop-types";
import {
  ItemContainer,
  ItemLabel,
  ItemContent,
  DragDropItemContent,
} from "../index";
import { paragraphText, subHeaderText, errorMessages } from "../consts";
import { ClaimSupportingDocumentContext } from "../../ClaimItem/context/context";
import ParagraphText from "../../ParagraphText";
import SubHeaderText from "../../SubHeaderText";
import DragDrop from "../../DragDrop";
import BidInputError from "../../BidInput/child-components/BidInputError";
import SupportingDocumentContainer from "../../../containers/SupportingDocument";
import SkeletonRows from "../../Skeletons/SkeletonRows";

const SupportingDocument = ({
  supportingDocuments,
  mandateSupportingDocument,
  disabled,
  claimId,
  onSaveSupportingDocument,
  isFetchingSupportingDocument,
}) => {
  const {
    validFileType,
    setValidFileType,
    validFileSize,
    setValidFileSize,
    setValidSupportingDocument,
  } = useContext(ClaimSupportingDocumentContext);

  const maxNoOfMb = 250;
  const maxFileSize = 1048576 * maxNoOfMb;

  const handleBlockChange = (fileList) => {
    fileList.forEach((file) => {
      if (supportingDocuments?.find((x) => x.fileName === file.name)) {
        return;
      }
      onSaveSupportingDocument(file);
    });
  };

  const onDropRejected = (fileRejections) => {
    fileRejections.forEach((x) =>
      x.errors.forEach((err) => {
        if (err.code === "file-invalid-type") {
          setValidFileType(false);
          return;
        }
        if (err.code === "file-too-large") {
          setValidFileSize(false);
          return;
        }
      })
    );

    setTimeout(() => {
      setValidFileType(true);
      setValidFileSize(true);
    }, 2500);
  };

  const validDocument = supportingDocuments?.length > 0 && supportingDocuments?.some(obj => obj.uploaded);
  const isValid = !mandateSupportingDocument || validDocument;

  useEffect(() => {
    setValidSupportingDocument(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  return (
    <ItemContainer>
      <ItemLabel>
        <SubHeaderText>{subHeaderText.SUPPORTING_DOCUMENTS}:</SubHeaderText>
        <div style={{ width: "80%" }}>
          <ParagraphText className="info-text">
            {paragraphText.SUPPORTING_DOCUMENTS}
          </ParagraphText>
        </div>
      </ItemLabel>
      <ItemContent>
        <DragDrop
          disabled={disabled}
          onDropRejected={onDropRejected}
          onDrop={handleBlockChange}
          maxFileSize={maxFileSize}
        />
        {!validFileType && (
          <BidInputError errorMessage={errorMessages.INVALID_FILE_FORMAT} />
        )}
        {!validFileSize && (
          <BidInputError
            errorMessage={errorMessages.FILE_TOO_LARGE.replace(
              "{0}",
              maxNoOfMb
            )}
          />
        )}
        <DragDropItemContent>
          {!isFetchingSupportingDocument ? (
            supportingDocuments &&
            supportingDocuments.map((item, index) => {
              return (
                <SupportingDocumentContainer
                  key={index}
                  supportingDocument={item}
                  claimId={claimId}
                  disabled={disabled}
                />
              );
            })
          ) : (
            <SkeletonRows nrOfRows={1} paddingNone className="skeleton-rows" />
          )}
        </DragDropItemContent>
      </ItemContent>
    </ItemContainer>
  );
};

SupportingDocument.propTypes = {
  disabled: PropTypes.bool,
  supportingDocuments: PropTypes.array,
  mandateSupportingDocument: PropTypes.bool,
  claimId: PropTypes.number,
  onSaveSupportingDocument: PropTypes.func,
  isFetchingSupportingDocument: PropTypes.bool,
};

export default SupportingDocument;