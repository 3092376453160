import styled from "styled-components";
import PanelActions from "./PanelActions";

const StyledPanelActions = styled(PanelActions)`
  border-top: 0.2rem solid ${props => props.theme.COLOURS.PRIMARY.shades[5]};
  display: flex;
  margin-top: ${props => props.theme.SPACING.REGULAR};
  justify-content: flex-end;
  padding-top: ${props => props.theme.SPACING.REGULAR};

  > * {
    margin-left: ${props => props.theme.SPACING.SMALL};

    &:first-child {
      margin-left: 0;
    }
  }
`;

export default StyledPanelActions;
