import styled from "styled-components";
import ModalInsert from "./ModalInsert";
import BREAKPOINTS from "../../theme/breakpoints";
import PricingBlock from "../PricingBlock";

const StyledModalInsert = styled(ModalInsert)`
  display: ${(props) => props.layout && props.layout};
  @media (max-width: ${BREAKPOINTS.PHONE}) {
      display: flex;
  }

  max-width: ${(props) => props.width && props.width};
  width: ${(props) => props.width || "100%"};
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  @media (min-width: ${BREAKPOINTS.LARGE_DESKTOP}) {
    flex-direction: row;
    height: ${(props) => props.height || "auto"};
    justify-content: flex-start;
    margin: 0;
  }

  @media (max-width: ${BREAKPOINTS.SMALL_DESKTOP}) {
    ${PricingBlock} {
      margin-bottom: ${(props) => props.theme.SPACING.SMALL};
    }
  }
`;

export default StyledModalInsert;
