import styled, { css } from "styled-components";

import InputSearch from "./InputSearch";

export const StyledInputSearch = styled(InputSearch)`
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 0.1rem solid
        ${props => props.theme.COLOURS.PRIMARY.shades[10]};
      padding-bottom: ${props => props.theme.SPACING.REGULAR};
    `}
  .input-search__form {
    position: relative;
  }
  label {
    display: block;
    margin-bottom: ${props => props.theme.SPACING.REGULAR};
  }
  input {
    color: ${props => props.theme.COLOURS.PRIMARY.base};
    ${props => props.theme.FONTS.Text["paragraph"]}
  }
  .input-search__actions {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    height: 4.2rem;
    display: flex;
  }
  .input-search__button,
  .input-search__clear {
    background-color: ${props => props.theme.COLOURS.ACCENT.base};
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    padding: 1rem 1.5rem;
    &:hover {
      background-color: ${props => props.theme.COLOURS.SECONDARY_ACCENT.base};
    }
  }
  .input-search__error {
    margin-top: ${props => props.theme.SPACING.SMALL};
  }
`;

export default StyledInputSearch;
