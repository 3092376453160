import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import HeaderText from "../../HeaderText";
import ParagraphText from "../../ParagraphText";
import InputText from "../../InputText";
import InputRadio from "../../InputRadio";
import { paragraphText, callBackTimes, errorMessages } from "./consts";
import {
  ClaimCallbackNoteContext,
  ClaimCallbackNoteValidationContext,
} from "../ClaimCallBackForm/context/context";
import TextArea from "../../TextArea";
import BidInputError from "../../BidInput/child-components/BidInputError";

const ClaimCallBackForm = ({
  className,
  telNo,
  setCallBackTime,
  callBackTime,
  onChangeTelNo,
  validTelNo,
}) => {
  const { claimCallbackNote, setClaimCallbackNote } = useContext(
    ClaimCallbackNoteContext
  );
  const {
    validClaimCallbackNote,
    setValidClaimCallbackNote,
    hasFocus,
    setHasFocus,
  } = useContext(ClaimCallbackNoteValidationContext);

  function onChangeInput(e) {
    const { value } = e.target;
    setClaimCallbackNote(value);
    if (value.length >= 10 && value.length <= 500) {
      setValidClaimCallbackNote(true);
    } else {
      setValidClaimCallbackNote(false);
    }
  }

  useEffect(() => {
    validClaimCallbackNote && setValidClaimCallbackNote(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimCallbackNote]);

  return (
    <div className={className}>
      <HeaderText>{paragraphText.HEADER_TEXT}</HeaderText>
      <ParagraphText>{paragraphText.SUB_HEADER_TEXT}</ParagraphText>
      <div className="call-back__form">
        <div className="call-back">
          <div className="call-back_radio-group">
            <InputRadio
              key={callBackTimes.AM}
              value={callBackTimes.AM}
              label={callBackTimes.AM}
              isChecked={callBackTimes.AM === callBackTime}
              onInputChange={() => {
                setCallBackTime(callBackTimes.AM);
              }}
            />
            <InputRadio
              key={callBackTimes.PM}
              value={callBackTimes.PM}
              label={callBackTimes.PM}
              isChecked={callBackTimes.PM === callBackTime}
              onInputChange={() => {
                setCallBackTime(callBackTimes.PM);
              }}
            />
            <InputRadio
              key={callBackTimes.ANYTIME}
              value={callBackTimes.ANYTIME}
              label={callBackTimes.ANYTIME}
              isChecked={callBackTimes.ANYTIME === callBackTime}
              onInputChange={() => {
                setCallBackTime(callBackTimes.ANYTIME);
              }}
            />
          </div>
          <div className="call-back__tel-no">
            <ParagraphText>{paragraphText.TEL_NO}</ParagraphText>

            <InputText
              medium
              type="text"
              value={telNo || ""}
              maxLength="20"
              validValue={validTelNo}
              onChange={(e) => onChangeTelNo(e)}
              onBlur={(e) => {
                onChangeTelNo(e);
              }}
            />
          </div>
        </div>

        <ParagraphText>Notes to Vendor</ParagraphText>
        <div className="submit-claim_notes-input">
          <TextArea
            value={claimCallbackNote}
            onChange={onChangeInput}
            validValue={validClaimCallbackNote}
            resize={"none"}
            maximumCharactersBottom="500"
            rows="5"
            cols="10"
            onClick={(e) => {
              setHasFocus(false);
            }}
            onFocus={(e) => {
              e.target.select();
              onChangeInput(e);
            }}
            onBlur={(e) => {
              setHasFocus(true);
            }}
          />
          {!validClaimCallbackNote && hasFocus && (
            <BidInputError errorMessage={errorMessages.MIN_LENGTH} />
          )}
        </div>
      </div>
    </div>
  );
};

ClaimCallBackForm.propTypes = {
  className: PropTypes.string,
  telNo: PropTypes.string,
  callBackTime: PropTypes.oneOf([
    callBackTimes.AM,
    callBackTimes.PM,
    callBackTimes.ANYTIME,
  ]),
  setCallBackTime: PropTypes.func,
  onChangeTelNo: PropTypes.func,
  validTelNo: PropTypes.bool,
  setNotesToVendor: PropTypes.func,
};

export default ClaimCallBackForm;
