import styled, { css } from "styled-components";

import SummaryPriceBreakdown from "./SummaryPriceBreakdown";
import breakpoints from "../../theme/breakpoints";

const StyledSummaryPriceBreakdown = styled(SummaryPriceBreakdown)`
  width: 100%;

  .vat-status {
    margin: -1.5rem 0 -1rem 0;
    white-space: nowrap;
  }

  table {
    width: 100%;
    text-align: left;
    border-spacing: 0;
    th > label {
      font-weight: ${(props) => props.theme.FONTS.weights[70]};
      color: ${(props) => props.theme.COLOURS.PRIMARY.shades[50]};
      text-transform: uppercase;
    }
    h3,
    p {
      margin: 0;
    }
    label {
      display: inline;
      font-weight: ${(props) => props.theme.FONTS.weights[40]};
    }
    tbody td {
      width: ${(props) => (props.inModal ? "20.66667%" : "20%")};

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        width: ${(props) => (props.inModal ? "20.66667%" : "19.3%")};
      }
    }
    tbody td:first-child {
      width: ${(props) => (props.inModal ? "38%" : "42%")};
    }
  }
  .vehicle-payment-summary__subheading--grey {
    color: ${(props) => props.theme.COLOURS.PRIMARY.shades[60]};
  }
  .vehicle-payment-summary__label--bold {
    font-weight: ${(props) => props.theme.FONTS.weights[70]};
  }

  /* Collapsable */
  ${(props) =>
    props.collapsable &&
    css`
      padding: ${(props) => props.theme.SPACING.REGULAR};
      text-align: left;

      dl {
        align-items: center;
        display: flex;
        justify-content: space-between;
        text-align: left;
      }
    `}
`;

export default StyledSummaryPriceBreakdown;
