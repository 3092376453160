export const formatNumericValue = (val) => {
  let v = val.replace(/[^.\d]/g, "");
  let newVal =
    v.length > 5 && v.indexOf(".") <= 0
      ? v.substr(0, 5)
      : v.indexOf(".") >= 0
      ? v.substr(0, v.indexOf(".")) + v.substr(v.indexOf("."), 3)
      : v;

  if (newVal?.endsWith("..") || newVal?.endsWith(".00")) {
    newVal = newVal.slice(0, newVal.length - 1);
  }

  if (newVal === "00") newVal = 0;

  if (
    (newVal && newVal.charAt(newVal?.length - 1) === ".") ||
    (newVal && newVal.includes(".0")) ||
    (newVal && newVal.endsWith("0")) ||
    newVal === ""
  ) {
    return newVal;
  }

  return parseFloat(newVal);
};

export const formatStringValue = (val) => {
  return val.replace(/[^a-z0-9]/gi, "");
};

export const formatCurrency = (val) =>
  !isNaN(val)
    ? `£${val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })}`
    : `£0.00`;

export const convertToNumber = (val) => {
  if (isNaN(val)) {
    return (val = 0);
  }
  return Number(val);
};

export const getResolutionsOptions = (options) => {
  return options?.map((option) => {
    return {
      optionName: option.name,
      value: option.claimResolutionId,
      costType: option.costType,
    };
  });
};

export const getNumberOfInvalidFields = (state) => {
  const noOfInvalidDescriptions = state
    .map((itm) => !!itm.costLineText)
    .filter((desc) => desc === false).length;
  const noOfInvalidCostParts = state
    .map((itm) => !!itm.partsCost)
    .filter((part) => part === false).length;
  const total = noOfInvalidDescriptions + noOfInvalidCostParts;
  return total;
};
