import CampaignItem from "./CampaignForm";
import breakpoints from "../../../../views/theme/breakpoints";
import styled from "styled-components";
import HeaderText from "../../../../views/components/HeaderText";
import ParagraphText from "../../../../views/components/ParagraphText";
import spacing from "../../../../views/theme/base/spacing";
import ButtonLink from "../../../../views/components/ButtonLink";
import BidInputError from "../../../../views/components/BidInput/child-components/BidInputButton";
import Checkbox from "../../../../views/components/Checkbox";
import colours from "../../../../views/theme/base/colours";
import Table from "../../Table";
import Button from "../../../../views/components/Button";
import InputText from "../../../../views/components/InputText";

export const MainContainer = styled.div`
  padding: 3rem 6rem 6rem 4rem;

  ${ButtonLink} {
    text-align: left;
    word-break: break-all;
  }

  @media (max-width: ${breakpoints.LARGE_DESKTOP}) {
    padding-right: ${spacing.REGULAR};
  }

  @media (max-width: ${breakpoints.PHONE}) {
    padding: ${spacing.REGULAR} ${spacing.SMALL};
  }

  .subtitle {
    margin-top: 1rem;
  }

  .campaignCriteria-container,
  .campaignDetails-container {
    border-bottom: 0.2rem solid ${colours.PRIMARY.shades[5]};
    padding-bottom: 2rem;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  margin-top: ${(props) => props.marginTop || props.theme.SPACING.LARGE};
  margin-bottom: ${(props) => props.marginBottom};

  @media (max-width: ${breakpoints.TABLET}) {
    margin-top: ${(props) => props.marginTop || props.theme.SPACING.SMALL};
    margin-bottom: ${(props) => props.marginTabletBottom || 0};
    flex-direction: column;
    align-items: center;
  }

  .date-container,
  .mileage-container,
  .age-container {
    display: flex;
    gap: 2rem;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    @media (max-width: ${breakpoints.LARGE_PHONE}) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  .campaignAuctionGrades-container,
  .campaignAuctionConditions-container {
    display: flex;
    gap: 2rem;
    @media (max-width: ${breakpoints.LARGE_PHONE}) {
      flex-flow: wrap;
      gap: 2rem;
    }
  }

  .filter-container {
    display: flex;
    padding-top: 1rem;
  }

  .document-links-lines-container {
    ${InputText} {
      margin-bottom: 2rem;
    }
  }

  .document-link-controls-group {
    display: flex;
    justify-content: flex-end;
  }

  .link-item {
    display: flex;
    gap: 1rem;
    margin: 0.75rem;
  }
`;

export const ItemLabel = styled.div`
  width: 40%;
  text-align: left;
  align-items: ${(props) => props.alignItems};
  display: ${(props) => props.display};

  p {
    margin-top: 0;
  }

  @media (max-width: ${breakpoints.TABLET}) {
    width: 100%;
  }
`;

export const ItemContent = styled.div`
  width: 60%;
  text-align: left;
  p {
    margin-top: 0;
  }

  ${Button} {
    margin-left: 0rem;
  }

  @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
    width: 55%;
  }

  @media (max-width: ${breakpoints.TABLET}) {
    width: 100%;
    flex-direction: column;
  }

  @media (max-width: ${breakpoints.PHONE}) {
    margin: 1rem 2rem;
  }

  .checkbox__count {
    display: none;
  }

  .datePicker-container {
    display: flex;
    gap: 2rem;

    @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
      flex-direction: column;
      gap: 1rem;
    }
    ${Checkbox} {
      margin-top: 2.5rem;

      @media (max-width: ${breakpoints.LARGE_PHONE}) {
        margin-top: 0rem;
      }
    }
  }

  .accordion__header {
    justify-content: normal;
    gap: 2rem;
  }

  .campaignBuyerGroups-container {
    margin-top: 1.5rem;
    word-break: break-all;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, max-content));
    grid-gap: 13px;
    padding: initial;
    @media (max-width: ${breakpoints.TABLET}) {
      grid-gap: 8px;
    }
    @media (max-width: ${breakpoints.LARGE_PHONE}) {
      grid-template-columns: none;
      grid-gap: 5px;
    }
  }

  .view-note-container,
  .trash-container {
    cursor: pointer;
  }

  ${Table} {
    tr,
    th {
      background: ${colours.WHITE};
    }

    th:first-child {
      text-align: left;
    }

    th:nth-child(2) {
      text-align: left;
    }

    th:nth-child(3) {
      text-align: left;
    }

    tr td:first-child {
      width: 20%;
      text-align: left;
    }

    tr td:nth-child(2) {
      width: 20%;
      text-align: left;
    }

    tr td:nth-child(3) {
      width: 20%;
      text-align: left;
    }

    tr td:nth-child(4) {
      width: 5%;
    }

    tr td:nth-child(5) {
      width: 5%;
    }
  }
`;

export const ItemContentTwoColumn = styled.div`
  width: 100%;
  text-align: left;
  align-items: center;
  align-items: center p {
    margin-top: 0;
  }
  display: flex;
  margin-bottom: ${(props) => props.theme.SPACING.SMALL};
  @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    flex-direction: column;
  }
`;

export const ItemContentTwoColumnHeader = styled.div`
  width: 32%;
  display: flex;
  align-items: center;
  @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
    width: 100%;
  }
`;

export const ItemContentTwoColumnInput = styled.div`
  width: 68%;
  @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
    width: 100%;
  }
`;

export const ContactInputHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderContainer = styled.div`
  border-bottom: 0.2rem solid ${({ borderBottomColor }) => borderBottomColor};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media (max-width: ${breakpoints.TABLET}) {
    padding-bottom: 2rem;
  }

  .secondaryRow-container {
    display: flex;
    flex-direction: column;
    .secondaryRow-item {
      display: flex;
      flex-direction: row;
      margin-top: 1rem;
      gap: 0.5rem;
    }
  }
`;

export const HeaderPrimaryRow = styled.div`
  display: flex;
  width: 100%;
  ${HeaderText} {
    font-size: 4rem;
  }

  @media (max-width: ${breakpoints.TABLET}) {
    padding-bottom: 2rem;
  }
`;

export const HeaderSecondaryRow = styled.div`
  display: flex;
  width: 100%;

  ${ParagraphText} {
    font-size: 1.8rem;
  }

  @media (max-width: ${breakpoints.TABLET}) {
    flex-direction: column;
  }
`;

export const HeaderItem = styled.div`
  width: ${({ width }) => width || "50%"};
  text-align: left;
  display: flex;
  align-items: center;
  padding-top: 2rem;
  ${ParagraphText} {
    font-size: 2.1rem;
  }

  @media (min-width: ${breakpoints.TABLET}) {
    justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  }

  @media (max-width: ${breakpoints.TABLET}) {
    width: 100%;
  }
`;

export const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2rem;
  justify-content: flex-end;

  & > * {
    width: 15%;
    margin-left: 1rem;
    text-align: center;

    @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
      width: 21%;
      margin-top: 1rem;
    }
  }

  @media (max-width: ${breakpoints.TABLET}) {
    justify-content: space-between;
    margin-top: 5rem;
    margin-left: 0;
    display: flex;
    flex-wrap: wrap;

    & > * {
      margin-left: 0;
    }

    & > button:nth-of-type(1) {
      flex: 0 1 49%;
      margin-bottom: ${spacing.SMALL};
    }
    & > button:nth-of-type(2) {
      flex: 0 1 49%;
      margin-bottom: ${spacing.SMALL};
    }
    & > button:nth-of-type(3) {
      width: 100%;
    }
  }
`;

export const ParagraphTextContainer = styled.div`
  width: 100%;
  padding-top: 3rem;
  ${ParagraphText} {
    text-align: justify;
  }
`;

export const DragDropItemContent = styled.div`
  div {
    margin: 0.5rem 0;
    display: flex;
    height: min-content;

    > div {
      margin-right: 1rem;
    }
  }

  .failedItems {
    margin: 0;
    pointer-events: none;
    ${ButtonLink} {
      text-align: left;
      word-break: break-all;
      color: ${colours.RED.base};
    }
    .failedFile {
      width: 45rem;
      margin-right: 1rem;
    }
    ${BidInputError} {
      line-height: 2rem;
      height: 5rem;
      margin-right: 0rem;
    }
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
  margin-left: 0.25rem;
  align-content: center;
`;

export default CampaignItem;
