import React from "react";
import PropTypes from "prop-types";
import ClaimActivityLogTableHeader from "../../components/ClaimActivityLog/child-components/ClaimActivityLogTableHeader";
import ClaimActivityLogTableRow from "../../components/ClaimActivityLog/child-components/ClaimActivityLogTableRow";
import useIsLoggedInUserType from "../../../shared/hooks/useIsLoggedInUserType";
import { USER_TYPE_MAP } from "../../../shared/utility";

const ClaimActivityLog = ({ className, claimActivityLog, claimId, onOpenVendorNote }) => {
  const showHeader = useIsLoggedInUserType(USER_TYPE_MAP.Buyer);
  const removeMargin = useIsLoggedInUserType(USER_TYPE_MAP.Buyer) ? "4rem" : "0";
  const isVendorOnly = useIsLoggedInUserType(USER_TYPE_MAP.Vendor);

  return (
    <div className={className} style={{margin: `${removeMargin}`}}>
      <ClaimActivityLogTableHeader claimId={claimId} showHeader={showHeader} onOpenVendorNote={onOpenVendorNote} isVendorOnly={isVendorOnly}/>
      <div className="activity-log-content" >
        <table>
        {claimActivityLog &&
          claimActivityLog.map((el) => (
            <ClaimActivityLogTableRow claimActivityLog={el} key={el.claimActivityLogId}/>
            ))}
            </table>
      </div>
    </div>
  );
};

ClaimActivityLog.propTypes = {
  claimId: PropTypes.number,
  className: PropTypes.string,
  claimActivityLog: PropTypes.array,
};

export default ClaimActivityLog;
