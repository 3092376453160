import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../components/ModalInsert";
import breakpoints from "../../theme/breakpoints";
import { useMediaQuery } from "react-responsive";

import GenericConfirmInsert from "../../components/GenericConfirmInsert";
import { closeClaim } from "../../../state/reducers/claims";

const ClaimCloseModalInsert = () => {
  const dispatch = useDispatch();
  const activeClaimId = useSelector(
    (state) => state.global.claims.activeClaimId
  );

  const onCloseClaim = () => {
    dispatch(closeClaim(activeClaimId));
    dispatch(closeModal());
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  return (
    <Fragment>
      <ModalInsert width={isDesktop ? "55rem" : "100%"}>
        <GenericConfirmInsert
          headerText="Are you sure?"
          bodyText="You are about to close this claim, are you sure?"
          onCancel={onCancel}
          onConfirm={onCloseClaim}
        />
      </ModalInsert>
    </Fragment>
  );
};

export default ClaimCloseModalInsert;
