import React from "react";
import PropTypes from "prop-types";

const SkeletonShowroomPage = props => {
  return (
    <div className={props.className}>
      <div className="skeleton__page-content" />
    </div>
  );
};

SkeletonShowroomPage.propTypes = {
  className: PropTypes.string
};

export default SkeletonShowroomPage;
