import React from "react";
import styled from "styled-components";
import breakpoints from "../../../theme/breakpoints";

const NumberButton = ({ className, value, onClick }) => (
  <button className={className} value={value} onClick={onClick}>
    {value}
  </button>
);

const StyledNumberButton = styled(NumberButton)`
  background: none;
  border: none;
  color: ${props =>
    props.active
      ? props.theme.COLOURS.PRIMARY.base
      : props.theme.COLOURS.PRIMARY.shades[50]};
  pointer-events: ${props => props.value === "..." && "none"};
  cursor: ${props => (props.value === "..." ? "default" : "pointer")};
  font-family: ${props => props.theme.FONTS.families.lato};
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 3.2rem;
  padding: 0 0.5rem;

  @media (min-width: ${breakpoints.TABLET}) {
    padding: 0 0.8rem;
  }

  &:hover {
    color: ${props => props.theme.COLOURS.ACCENT.base};
  }
`;

export default StyledNumberButton;
