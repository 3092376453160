import COLOURS from "./colours";

// TODO: Move animation keyframes to global styled-components (inc reset
const shimmer = `
  animation: shimmer 3s infinite linear;
  background-color: ${COLOURS.PRIMARY.shades[10]};
  background: linear-gradient(
    to right,
    ${COLOURS.PRIMARY.shades[5]} 4%,
    ${COLOURS.SECONDARY.shades[10]} 25%,
    ${COLOURS.PRIMARY.shades[10]} 36%
  );
  background-size: 100rem 100%;

  @keyframes shimmer {
    0% {
      background-position: -100rem 0;
    }
    100% {
      background-position: 100rem 0;
    }
  }
`;

const shimmerDark = `
  animation: shimmer 3s infinite linear;
  background-color: ${COLOURS.PRIMARY.shades[20]};
  background: linear-gradient(
    to right,
    ${COLOURS.PRIMARY.shades[10]} 4%,
    ${COLOURS.SECONDARY.shades[20]} 25%,
    ${COLOURS.PRIMARY.shades[20]} 36%
  );
  background-size: 100rem 100%;

  @keyframes shimmer {
    0% {
      background-position: -100rem 0;
    }
    100% {
      background-position: 100rem 0;
    }
  }
`;

const shimmerDarker = `
  animation: shimmer 3s infinite linear;
  background-color: ${COLOURS.PRIMARY.shades[70]};
  background: linear-gradient(
    to right,
    ${COLOURS.PRIMARY.shades[70]} 4%,
    ${COLOURS.SECONDARY.shades[80]} 25%,
    ${COLOURS.PRIMARY.shades[90]} 36%
  );
  background-size: 100rem 100%;

  @keyframes shimmer {
    0% {
      background-position: -100rem 0;
    }
    100% {
      background-position: 100rem 0;
    }
  }
`;

const rotation = `
  animation: rotation 2s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const ANIMATIONS = {
  shimmer,
  shimmerDark,
  shimmerDarker,
  rotation
};

export default ANIMATIONS;
