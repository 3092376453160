import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Overlay from "./child-components/Overlay";
import ParagraphText from "../ParagraphText";
import DatePickerInput from "./child-components/DatePickerInput";

dayjs.extend(customParseFormat);

const Datepicker = (props) => {
  const {
    className,
    label,
    dateFormat,
    defaultDate,
    getDate,
    endOfTheDay,
    disabled,
  } = props;

  const formatDate = (date, format) => {
    if (endOfTheDay) return dayjs(date).endOf("day").format(format);
    return dayjs(date).startOf("day").format(format);
  };

  const displayDateFormat = "DD/MM/YYYY";

  const [value, setValue] = useState(
    defaultDate ? formatDate(defaultDate, displayDateFormat) : ""
  );

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [preformattedDate, setPreformattedDate] = useState(null);

  const inputRef = useRef();

  const parseDate = (str, format) => {
    const parsed = dayjs(str, format).toDate();
    return parsed;
  };

  const handleInputClick = () => {
    setCalendarOpen(true);
  };

  const handleDayClick = (date) => {
    setPreformattedDate(date);
    getDate(formatDate(date, dateFormat));
    setValue(formatDate(date, displayDateFormat));
    setCalendarOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (!event.target.closest(".rdp")) {
      setCalendarOpen(false);
    }
  };

  return (
    <div className={className}>
      {label && <ParagraphText>{label}</ParagraphText>}
      <div ref={inputRef}>
        <DatePickerInput
          value={value}
          onClick={handleInputClick}
          onChange={(e) => setValue(e.target.value)}
          placeholder={displayDateFormat}
          disabled={disabled}
        />

        {calendarOpen && (
          <div className="dayPicker-container" onClick={handleOutsideClick}>
            <DayPicker
              mode="single"
              format={dateFormat}
              formatDate={formatDate}
              parseDate={parseDate}
              overlayComponent={(props) => (
                <Overlay {...props} inputRef={inputRef} />
              )}
              showOutsideDays
              weekdaysShort={["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]}
              firstDayOfWeek={1}
              onDayClick={(date) => handleDayClick(date)}
              selected={preformattedDate}
              onClickOutside={handleOutsideClick}
              disabled={disabled}
            />
          </div>
        )}
      </div>
    </div>
  );
};

Datepicker.defaultProps = {
  dateFormat: "YYYY/MM/DD HH:mm:ss",
};

Datepicker.propTypes = {
  className: PropTypes.string,
  dateFormat: PropTypes.string,
  defaultDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  getDate: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Datepicker;
