import React, { useContext } from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";
import { ThemeContext } from "styled-components";

const Breadcrumb = ({ className, label, link, navigateLink }) => {
  const theme = useContext(ThemeContext);

  return (
    <div className={className}>
      <div onClick={() => navigateLink(link)} className="breadcrumb__link">
        <span className="breadcrumb__link__roundel">
          <Icon
            type="chevron-left"
            width="6px"
            height="10px"
            strokeColour={theme.COLOURS.PRIMARY.shades[10]}
          />
        </span>
        {label}
      </div>
    </div>
  );
};

Breadcrumb.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  action: PropTypes.func,
  label: PropTypes.string,
};

Breadcrumb.defaultProps = {
  link: "/",
  label: "Back to Search",
};

export default Breadcrumb;
