import axios from "axios";
import queryString from "qs";
import clientSettings from "../../clientappsettings.json";

class Api {
  isTesting = process.env.NODE_ENV === "test";
  baseUrl = "";
  requestUrl = "";
  reponseType = null;
  queryParams = null;
  method = "GET";
  headers = {};
  mock = {};
  withCredentials = clientSettings.WITH_CREDENTIALS_REQUEST_ATTRIBUTE;

  constructor(options = {}) {
    this.options = options;
  }

  getCredentials() {
    return this.options.credentials;
  }

  makeMockRequest() {
    return Promise.resolve(this.mock);
  }

  formatRequestData(params) {
    return params;
  }

  formatResponseData(data) {
    return data;
  }

  getParams() {
    return this.options.params;
  }

  getQueryParams() {
    if (this.queryParams) {
      return `?${queryString.stringify(this.queryParams)}`;
    }
    return "";
  }

  getEndpointUrl() {
    return `${this.baseUrl}${this.requestUrl}${this.getQueryParams()}`;
  }

  makeRequest() {
    const request = {
      headers: this.headers,
      method: this.method,
      url: this.getEndpointUrl(),
      responseType: this.responseType,
      withCredentials: this.withCredentials, // this is needed to be able to accept cookies
    };

    if (this.method === "POST") {
      request.data = this.formatRequestData(this.getParams());
    }

    if (this.method === "POST_BLOCK") {
      request.data = this.getParams().block;
      request.method = "POST";
    }

    return axios(request);
  }

  call() {
    if (this.isTesting) {
      return this.makeMockRequest().then((mockResult) =>
        this.formatResponseData(mockResult)
      );
    } else {
      return this.makeRequest().then(
        (result) => this.formatResponseData(result),
        (err) => {
          return Promise.reject(err);
        }
      );
    }
  }
}

export default Api;
