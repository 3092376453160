import Search from "./Search";
import {
  formatForSaleVehicleResultsResponse,
  formatPurchasedVehicleResultsResponse,
} from "../shared/utility";
import Invoices from "./Invoices";
import ActivityLogs from "./ActivityLogs";
import { FetchClaims } from "./Claim";
import { FetchVendorClaims } from "./ClaimsVendor";
import { FetchVendorDashboardInfo } from "./ClaimVendorDashboard";

const getResultEndpoint = (resultType) => {
  switch (resultType) {
    case "bidsAndRequests":
      return "/BidsAndRequests";
    case "purchasesArchive":
      return "/Purchases";
    case "claimsVendorDashboard":
    case "claims":
    case "claimsVendor":
      return "/Claims";
    default:
      return "/Search";
  }
};

export class RegSearch extends Search {
  requestUrl = getResultEndpoint(this.getParams().resultType);

  formatRequestData(params) {
    const { registration, resultType } = params;
    let requestParams = {
      registration,
    };
    if (resultType === "shortlist") {
      requestParams.shortlist = true;
    }
    return requestParams;
  }

  formatResponseData(response) {
    const isPurchasedVehicleList =
      this.getParams().resultType === "purchasesArchive";
    if (isPurchasedVehicleList) {
      return formatPurchasedVehicleResultsResponse(response);
    }
    return formatForSaleVehicleResultsResponse(response);
  }
}

export class RegSearchInvoices extends Invoices {
  formatRequestData(params) {
    const { registration } = params;
    return {
      registration,
    };
  }
}

export class RegSearchActivityLogs extends ActivityLogs {
  formatRequestData(params) {
    const { registration } = params;
    return {
      registration,
    };
  }
}

export class RegSearchClaims extends FetchClaims {
  formatRequestData(params) {
    const { registration } = params;
    return {
      registration,
    };
  }
}

export class RegSearchClaimsVendor extends FetchVendorClaims {
  formatRequestData(params) {
    const { registration, filterType } = params;
    return {
      registration,
      primaryFilter: filterType,
    };
  }
}

export class RegSearchDashboardInfo extends FetchVendorDashboardInfo {
  formatRequestData(params) {
    const { registration } = params;
    return {
      registration,
    };
  }
}
