import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ButtonLink from "../../components/ButtonLink";
import Icon from "../../components/Icon";
import ProgressBar from "../../components/ProgressBar";
import { TrashIconContainer } from "../../components/ClaimItem/index";
import {
  deleteShowroomModeSettingsLogo,
  saveShowroomModeSettingsBlockLogo,
} from "../../../state/reducers/buyerInformation";
import {
  saveSupportingBlockDocument,
  deleteSupportingDocument,
} from "../../../state/reducers/campaignManagement";
import BidInputError from "../../components/BidInput/child-components/BidInputError";
import { errorMessages } from "../../components/ShowroomModeSettings/const";
import { fileUploadConst } from "./const";

// documentOrImage - Anything can be saved, document or image
// callingArea - keeping it generic, should be a new value for every new area : 1 - ShowroomModeLogo,

const FileUpload = ({
  documentOrImage,
  callingArea,
  disabled,
  setShowLogoDisabled,
  setCampaignDocumentsDisabled,
  id,
  type,
  userType,
  disabledTrashCan,
  sourceFolder,
  setParentFormButtonDisabled,
}) => {
  const dispatch = useDispatch();
  const {
    progress,
    beginingOfTheBlock,
    endOfTheBlock,
    noOfBlocks,
    uploaded,
    duplicateDocument,
    blockNo,
    file,
    fileName,
    url,
  } = documentOrImage;

  const handleFile = () => {
    let block = file.slice(beginingOfTheBlock, endOfTheBlock);
    const percentage = (blockNo / noOfBlocks) * 100;

    const data = {
      fileName: file.name,
      blockNo: blockNo,
      blockSize: block.size,
      finalBlock: blockNo === noOfBlocks,
      block: block,
      percentage: percentage,
      duplicateDocument: false,
    };
    onSaveDocumentOrImage(data, id);
  };

  const onSaveDocumentOrImage = (data, id) => {
    if (callingArea === fileUploadConst.SHOWROOMMODE) {
      setShowLogoDisabled(true);
      dispatch(saveShowroomModeSettingsBlockLogo(data, id));
    } else {
      if (callingArea === fileUploadConst.CAMPAIGNMANAGEMENT) {
        dispatch(saveSupportingBlockDocument(data, id, sourceFolder));
      }
    }
  };

  const onDeleteDocumentOrImage = (id, fileName, userType, type) => {
    if (callingArea === fileUploadConst.SHOWROOMMODE) {
      setShowLogoDisabled(false);
      dispatch(deleteShowroomModeSettingsLogo(fileName, userType, type));
    } else {
      if (callingArea === fileUploadConst.CAMPAIGNMANAGEMENT) {
        setParentFormButtonDisabled(true);
        dispatch(
          deleteSupportingDocument(id, fileName, userType, type, sourceFolder)
        );
      }
    }
  };

  useEffect(() => {
    if (!uploaded && !duplicateDocument) {
      handleFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentOrImage]);

  return (
    <div>
      <div>
        {documentOrImage.duplicateDocument ? (
          <div className="failedItems">
            <ButtonLink
              className="failedFile"
              key={fileName}
              listItem
              name={fileName}
            />
            <BidInputError errorMessage={errorMessages.DUPLICATE_FILE} />
          </div>
        ) : (
          <ButtonLink
            key={fileName}
            listItem
            primary
            name={fileName}
            disabled={!url}
            action={() => {
              window.open(url, "_blank");
            }}
          />
        )}
      </div>
      {!documentOrImage.duplicateDocument && !disabledTrashCan && (
        <div>
          {uploaded ? (
            <TrashIconContainer
              className="drag-drop__trash"
              onClick={() =>
                onDeleteDocumentOrImage(id, fileName, userType, type)
              }
            >
              <Icon type="trash" width="24" height="24" />
            </TrashIconContainer>
          ) : (
            <ProgressBar value={progress} />
          )}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
