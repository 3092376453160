import React from "react";
import PriceText from "./../PriceText";
import KeyInformationLabel from "./../KeyInformationLabel";
import ParagraphText from "./../ParagraphText";

const PricingBlock = ({
  price,
  headerText,
  priceColor,
  footerText,
  footerAdditionalText,
  bidText,
  small,
  large,
  className,
}) => (
  <div className={className}>
    <KeyInformationLabel className="pricing-block__header">
      {" "}
      {headerText}
    </KeyInformationLabel>
    <div className="pricing-block__content">
      <div className="pricing-block__content__top">
        {price && (
          <PriceText
            small={small}
            large={large}
            content={price}
            priceColor={priceColor}
          />
        )}
        <ParagraphText className="pricing-block__content__side-text">
          {bidText}
        </ParagraphText>
      </div>
      <ParagraphText className="pricing-block__content__bottom" small>
        {footerText}{" "}
        <span className="pricing-block__content__bottom__additional-text">
          {footerAdditionalText}
        </span>
      </ParagraphText>
    </div>
  </div>
);

export default PricingBlock;
