import styled from "styled-components";
import ShowroomModalComponent from "./ShowroomModalComponent";
import InputRadio from "./../InputRadio";
import Checkbox from "./../Checkbox";
import breakpoints from "../../theme/breakpoints";

const StyledShowroomModalComponent = styled(ShowroomModalComponent)`
  box-sizing: border-box;
  background-color: ${(props) => props.theme.COLOURS.WHITE};
  border: 0.2rem solid ${(props) => props.theme.COLOURS.ACCENT.base};
  padding: ${(props) => props.theme.SPACING.REGULAR};
  border-radius: 0.9rem;
  width: 100%;
  height: auto;
  
  @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
    padding: ${(props) => props.theme.SPACING.SMALL};
    height: auto;
  }

  @media (max-width: ${breakpoints.LARGE_PHONE}) {
    margin-top: 30rem;
  }

  @media (max-width: ${breakpoints.PHONE}) {
    padding: 0.8rem;
  }

  .showroom-modal__inputs {
    margin-top: ${(props) => props.theme.SPACING.SMALL};
    display: flex;
    justify-content: space-between;

    @media (max-width: ${breakpoints.LARGE_PHONE}) {
      display: block;
    }

    .showroom-modal__inputs__details {
      flex: 0 0 30%;
      > input {
        margin-top: 2.5rem;
      }

      ${Checkbox} {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      .radio-button-container {
        display: flex;
        ${InputRadio} {
          margin-top: 0.5rem;
          padding: 0.5rem;
        }
      }
    }

    .showroom-modal__inputs__textarea {
      width: 60%;

      @media (max-width: ${breakpoints.LARGE_PHONE}) {
        width: auto;
      }
    }
  }
`;

export default StyledShowroomModalComponent;
