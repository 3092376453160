import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";
import Button from "./../Button";
import Icon from "./../Icon";
import ParagraphText from "./../ParagraphText";

const StocklistDownload = ({ className, onDownload, onCancel }) => {
  const theme = useContext(ThemeContext);
  return (
    <div className={className}>
      <div className="icon">
        <Icon
          type="arrow-download"
          width="48"
          height="48"
          strokeColour={theme.COLOURS.ACCENT.base}
        />
      </div>
      <div className="content">
        <div className="content__title"> Download a Stocklist</div>
        <div className="content__text">
          {theme.name === "Motability" ? (
            <ParagraphText primary>
              Please select 'Download' to review a live list of vehicles
              available for you to purchase. This will be generated as a CSV
              file.
            </ParagraphText>
          ) : (
            <ParagraphText primary>
              <span>Please note: </span>
              The prices shown are representative of the live platform and
              prices are subject to change. If you have any queries regarding
              any vehicles please speak to a 1link representative on 01676
              571098.
            </ParagraphText>
          )}
          <ParagraphText primary>
            This information is confidential and cannot be shared outside of the
            intended recipient.
          </ParagraphText>
        </div>
        <div className="content__actions">
          <Button
            action={onDownload}
            primary
            className="gaEvent-download"
            content="Download"
          />
          <Button action={onCancel} secondary ghost content="Cancel" />
        </div>
      </div>
    </div>
  );
};

StocklistDownload.propTypes = {
  className: PropTypes.string,
  onDownload: PropTypes.func,
  onCancel: PropTypes.func
};

export default StocklistDownload;
