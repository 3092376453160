import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * As we scroll down a page and then make a transition, we are moved to the point in the page when
 * the transition was made. This is a solution for that, see:
 * https://reacttraining.com/react-router/web/guides/scroll-restoration
 */
const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return children;
};

export default ScrollToTop;
