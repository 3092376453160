import {
  RegSearch,
  RegSearchInvoices,
  RegSearchActivityLogs,
  RegSearchClaims,
  RegSearchClaimsVendor,
  RegSearchDashboardInfo,
} from "../../api/RegSearch";
import { clearFilters } from "./filter";
import { SEARCH_REQUEST, searchRequestSuccess } from "./search";
import {
  FETCH_BIDS_AND_REQUESTS,
  fetchBidsAndRequestsSuccess,
} from "./bidsAndRequests";
import { FETCH_SHORTLIST, fetchShortlistSuccess } from "./shortlist";
import { clearActiveFilter, setActiveFilter, openModal } from "./global";
import {
  FETCH_PURCHASES_ARCHIVE,
  fetchPurchasesArchiveSuccess,
} from "./purchasesArchive";
import { FETCH_INVOICES, fetchInvoicesSuccess } from "./invoices";
import { FETCH_ACTIVITY_LOGS, fetchActivityLogsSuccess } from "./activityLogs";
import { FETCH_CLAIMS, fetchClaimsSuccess } from "./claims";
import { FETCH_VENDOR_CLAIMS, fetchVendorClaimsSuccess } from "./claimsVendor";
import {
  FETCH_VENDOR_CLAIMS_DASHBOARD_INFO,
  fetchVendorClaimsDashboardInfoSuccess,
} from "./claimsVendorDashboard";

export const VEHICLE_REG_REQUEST = "@regSearch/VEHICLE_REG_REQUEST";
export const VEHICLE_REG_REQUEST_SUCCESS =
  "@regSearch/VEHICLE_REG_REQUEST_SUCCESS";
export const VEHICLE_REG_REQUEST_FAILURE =
  "@regSearch/VEHICLE_REG_REQUEST_FAILURE";
export const CLEAR_VEHICLE_REG_REQUEST = "@regSearch/CLEAR_VEHICLE_REG_REQUEST";

// Action creators

const vehicleRegistrationRequestSuccess = () => {
  return {
    type: VEHICLE_REG_REQUEST_SUCCESS,
  };
};

const vehicleRegistrationRequestFailure = (error) => {
  return {
    type: VEHICLE_REG_REQUEST_FAILURE,
    payload: error,
  };
};

const getResultTypeFetchingAction = (resultType) => {
  switch (resultType) {
    case "bidsAndRequests":
      return FETCH_BIDS_AND_REQUESTS;
    case "purchasesArchive":
      return FETCH_PURCHASES_ARCHIVE;
    case "shortlist":
      return FETCH_SHORTLIST;
    case "invoices":
      return FETCH_INVOICES;
    case "activityLogs":
      return FETCH_ACTIVITY_LOGS;
    case "claims":
      return FETCH_CLAIMS;
    case "claimsVendor":
      return FETCH_VENDOR_CLAIMS;
    case "claimsVendorDashboard":
      return FETCH_VENDOR_CLAIMS_DASHBOARD_INFO;
    default:
      return SEARCH_REQUEST;
  }
};

export const clearVehicleRegRequest = () => {
  return {
    type: CLEAR_VEHICLE_REG_REQUEST,
  };
};

/**
 *
 * @param {string} registration
 * @param {string} resultType
 * @returns {function(...[*]=)}
 */
export const vehicleRegistrationRequest =
  (registration, resultType, filterType) => (dispatch, getState) => {
    dispatch({
      type: VEHICLE_REG_REQUEST,
      payload: registration,
    });
    dispatch(setActiveFilter("registration"));
    dispatch({
      type: getResultTypeFetchingAction(resultType),
    });

    const params = { registration, resultType, filterType };

    let regSearchApiInstance;

    switch (resultType) {
      case "invoices":
        regSearchApiInstance = new RegSearchInvoices({
          params,
          credentials: getState().authentication.credentials,
        });
        break;
      case "activityLogs":
        regSearchApiInstance = new RegSearchActivityLogs({
          params,
          credentials: getState().authentication.credentials,
        });
        break;

      case "claims":
        regSearchApiInstance = new RegSearchClaims({
          params,
          credentials: getState().authentication.credentials,
        });
        break;

      case "claimsVendor":
        regSearchApiInstance = new RegSearchClaimsVendor({
          params,
          credentials: getState().authentication.credentials,
        });
        break;

      case "claimsVendorDashboard":
        regSearchApiInstance = new RegSearchDashboardInfo({
          params,
          credentials: getState().authentication.credentials,
        });
        break;

      default:
        regSearchApiInstance = new RegSearch({
          params,
          credentials: getState().authentication.credentials,
        });
        break;
    }

    regSearchApiInstance.call().then(
      (response) => {
        dispatch(vehicleRegistrationRequestSuccess());
        if (resultType !== "claims" && resultType !== "invoices") {
          dispatch(clearFilters(false));
        }
        switch (resultType) {
          case "search":
            return dispatch(searchRequestSuccess(response));
          case "bidsAndRequests":
            return dispatch(fetchBidsAndRequestsSuccess(response));
          case "shortlist":
            return dispatch(fetchShortlistSuccess(response));
          case "purchasesArchive":
            return dispatch(fetchPurchasesArchiveSuccess(response));
          case "invoices":
            return dispatch(fetchInvoicesSuccess(response));
          case "activityLogs":
            return dispatch(fetchActivityLogsSuccess(response));
          case "claims":
            return dispatch(fetchClaimsSuccess(response));
          case "claimsVendor":
            return dispatch(fetchVendorClaimsSuccess(response));
          case "claimsVendorDashboard":
            return dispatch(fetchVendorClaimsDashboardInfoSuccess(response));
          default:
            return;
        }
      },
      (error) => {
        dispatch(vehicleRegistrationRequestFailure(error));
        dispatch(clearActiveFilter());
        dispatch(openModal("generic-error"));
      },
    );
  };

export const initialState = {
  isFetching: false,
  value: null,
  error: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case VEHICLE_REG_REQUEST:
      return {
        ...state,
        isFetching: true,
        value: action.payload,
      };
    case VEHICLE_REG_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case VEHICLE_REG_REQUEST_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case CLEAR_VEHICLE_REG_REQUEST:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
