import React, { useContext } from "react";
import { ThemeContext } from 'styled-components';
import PropTypes from "prop-types";
import ParagraphText from "../ParagraphText";
import HeaderText from "../HeaderText";
import Icon from "../Icon";
import Button from "../Button";

const PendingPurchaseInsert = ({
  className,
  onViewVehicleClick,
  onFindVehiclesClick
}) => {
  const theme = useContext(ThemeContext);
  return (
    <div className={className}>
      <Icon
        type="stopwatch"
        strokeColour={theme.COLOURS.ORANGE.base}
        height="52px"
        width="46px"
      />
      <HeaderText>Order Pending</HeaderText>
      <ParagraphText>
        The vendor just needs to verify the sale of this vehicle. They have
        received a message regarding your purchase and you will be notified
        regarding any updates via your Messages
      </ParagraphText>
      <div className="pending-purchase__actions">
        <Button action={onViewVehicleClick} content="View Vehicle" primary />
        <Button action={onFindVehiclesClick} content="Find Vehicles" ghost />
      </div>
    </div>
  );
};

PendingPurchaseInsert.propTypes = {
  className: PropTypes.string,
  onViewVehicleClick: PropTypes.func,
  onFindVehiclesClick: PropTypes.func
};

export default PendingPurchaseInsert;
