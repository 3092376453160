import React from "react";
import PropTypes from "prop-types";
import HeaderText from "../HeaderText";
import SubHeaderText from "../SubHeaderText";
import ParagraphText from "../ParagraphText";
import { HEADER_TEXT, PARAGRAPH_TEXT, SUBHEADER_TEXT } from "./consts";

const ClaimSubmissionForm = ({ className, buyerNotes, setBuyerNotes }) => {
  return (
    <div className={className}>
      <HeaderText>{HEADER_TEXT}</HeaderText>
      <ParagraphText> {PARAGRAPH_TEXT}</ParagraphText>
      <div className="subheader-text__container">
        <SubHeaderText small colour="ACCENT">
          {SUBHEADER_TEXT}
        </SubHeaderText>
      </div>

      <div className="submit-claim_notes-input">
        <textarea
          className="text-area__input"
          type="text"
          resize={"none"}
          maxLength="1000"
          rows="3"
          cols="75"
          value={buyerNotes}
          onChange={(e) => setBuyerNotes(e.currentTarget.value)}
        />
      </div>
    </div>
  );
};

export default ClaimSubmissionForm;

ClaimSubmissionForm.propTypes = {
  className: PropTypes.string,
  buyerNotes: PropTypes.string,
  setBuyerNotes: PropTypes.func,
};
