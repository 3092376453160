import get from "lodash.get";
import {
  GetPurchaseDetails,
  PostPurchaseConfirmation,
} from "../../api/Purchase";
import { fetchPricingDetails } from "./pricing";
import { openModal } from "./global";
import {
  getContactAddressFromDeliveryOption,
  getPostCodeFromDeliveryOption,
} from "./../../shared/utility";

// Action constants
export const FETCH_PURCHASE_DETAILS = "@purchase/FETCH_PURCHASE_DETAILS";
export const FETCH_PURCHASE_DETAILS_SUCCESS =
  "@purchase/FETCH_PURCHASE_DETAILS_SUCCESS";
export const FETCH_PURCHASE_DETAILS_FAILURE =
  "@purchase/FETCH_PURCHASE_DETAILS_FAILURE";
export const SET_SELECTED_DELIVERY_OPTION =
  "@purchase/SET_SELECTED_DELIVERY_OPTION";
export const SET_SELECTED_PAYMENT_OPTION =
  "@purchase/SET_SELECTED_PAYMENT_OPTION";
export const CLEAR_PURCHASE_DETAILS = "@purchase/CLEAR_PURCHASE_DETAILS";
export const ORDER_CONFIRMATION_REQUEST =
  "@purchase/ORDER_CONFIRMATION_REQUEST";
export const ORDER_CONFIRMATION_REQUEST_SUCCESS =
  "@purchase/ORDER_CONFIRMATION_REQUEST_SUCCESS";
export const ORDER_CONFIRMATION_REQUEST_FAILURE =
  "@purchase/ORDER_CONFIRMATION_REQUEST_FAILURE";
export const ADD_NEW_DELIVERY_OPTION = "@purchase/ADD_NEW_DELIVERY_OPTION";

// Action creators

export const clearPurchaseDetails = () => {
  return {
    type: CLEAR_PURCHASE_DETAILS,
  };
};

export const fetchPurchaseDetailsSuccess = (data) => {
  return {
    type: FETCH_PURCHASE_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchPurchaseDetailsFailure = (error) => {
  return {
    type: FETCH_PURCHASE_DETAILS_FAILURE,
    payload: error,
  };
};

export const fetchPurchaseDetails =
  (vehicleId, purchaserId) => (dispatch, getState) => {
    dispatch({ type: FETCH_PURCHASE_DETAILS });

    const PurchaseDetailsInstance = new GetPurchaseDetails({
      params: { vehicleId: vehicleId, selectedGroupSiteId: purchaserId },
      credentials: getState().authentication.credentials,
    });

    PurchaseDetailsInstance.call().then(
      (response) => dispatch(fetchPurchaseDetailsSuccess(response.data)),
      (err) => {
        dispatch(fetchPurchaseDetailsFailure(err));
        /**
         * TODO: we can extend this switch for further error handling,
         * perhaps extract it out into a util
         */
        switch (get(err, "response.data.eventCode", "")) {
          case "IUP00016":
            return dispatch(openModal("vehicle-unavailable"));
          default:
            return dispatch(openModal("generic-error"));
        }
      },
    );
  };

export const setDeliveryOption = (option) => (dispatch, getState) => {
  // TODO: refactor formatResponseData to make purchaseDetails flatter
  dispatch({
    type: SET_SELECTED_DELIVERY_OPTION,
    payload: option,
  });
  const vehicleId = getState().purchase.purchaseDetails.vehicleId;
  const selectedGroupBuyer = getState().global.purchases.selectedGroupBuyer;
  if (selectedGroupBuyer) {
    return dispatch(
      fetchPricingDetails(vehicleId, option.value, selectedGroupBuyer.value),
    );
  }
  return dispatch(fetchPricingDetails(vehicleId, option.value));
};

export const addNewDeliveryOption = (option) => (dispatch) => {
  dispatch({
    type: ADD_NEW_DELIVERY_OPTION,
    payload: option,
  });
};

export const setPaymentOption = (option) => {
  return {
    type: SET_SELECTED_PAYMENT_OPTION,
    payload: option,
  };
};

export const orderConfirmationRequest = () => {
  return {
    type: ORDER_CONFIRMATION_REQUEST,
  };
};

export const orderConfirmationRequestSuccess = () => {
  return {
    type: ORDER_CONFIRMATION_REQUEST_SUCCESS,
  };
};

export const orderConfirmationRequestFailure = (error) => {
  return {
    type: ORDER_CONFIRMATION_REQUEST_FAILURE,
    payload: error,
  };
};

export const postOrderConfirmationDetails =
  (navigate) => (dispatch, getState) => {
    dispatch(openModal("confirm-purchase-loader"));
    dispatch(orderConfirmationRequest());

    const { purchase, global } = getState();

    const isDeliveryTbc = (deliveryOption) => {
      return deliveryOption && deliveryOption.title === "To Be Confirmed";
    };

    const formatDefaultLocationName = (locationName) => {
      return locationName === "Default" ? "" : locationName;
    };

    const params = {
      vehicleId: purchase.purchaseDetails.vehicleId,
      deliveryTbc: isDeliveryTbc(purchase.deliveryOptions.selectedOption),
      disposalChannel: "TradeOnline",
      sourceType: "Web",
      selectedGroupSiteId:
        global.purchases.selectedGroupBuyer &&
        global.purchases.selectedGroupBuyer.value,
      paymentType: purchase.paymentOptions.selectedOption.value,
      deliveryType: purchase.deliveryOptions.selectedOption.value,
      deliveryLocation: isDeliveryTbc(purchase.deliveryOptions.selectedOption)
        ? {}
        : {
            ...purchase.purchaseDetails.deliveryLocation,
            locationName: formatDefaultLocationName(
              purchase.deliveryOptions.selectedOption.title,
            ),
            contactAddress: getContactAddressFromDeliveryOption(
              purchase.deliveryOptions.selectedOption,
            ),
            postCode: getPostCodeFromDeliveryOption(
              purchase.deliveryOptions.selectedOption,
            ),
          },
      collectionLocation: {
        ...purchase.purchaseDetails.collectionLocation,
      },
    };

    const PurchaseConfirmationInstance = new PostPurchaseConfirmation({
      params,
      credentials: getState().authentication.credentials,
    });

    PurchaseConfirmationInstance.call().then(
      (response) => {
        const { vehiclePurchaseId, vehicleRequestId } = response.data;
        if (vehiclePurchaseId) {
          dispatch(orderConfirmationRequestSuccess());
          return navigate(
            `/purchases/purchase-confirmation/${response.data.vehiclePurchaseId}`,
            {
              state: { isSuccessfulPurchase: true },
            },
          );
        }
        if (vehicleRequestId) {
          return dispatch(openModal("pending-purchase"));
        }
      },
      (err) => {
        dispatch(orderConfirmationRequestFailure(err));
        // TODO: we can extend this switch for further error handling
        switch (get(err, "response.data.eventCode", "")) {
          case "IUP00016":
            return dispatch(openModal("vehicle-unavailable"));
          default:
            return dispatch(openModal("generic-error"));
        }
      },
    );
  };

export const initialState = {
  isFetching: false,
  error: null,
  purchaseDetails: null,
  isGroupBuyer: false,
  selectedGroupSiteId: null,
  deliveryOptions: {
    options: [],
    selectedOption: null,
  },
  paymentOptions: {
    options: [],
    selectedOption: null,
  },
  groupBuyerOptions: {
    options: [],
    selectedOption: null,
  },
  isLoadingConfirmation: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PURCHASE_DETAILS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_PURCHASE_DETAILS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case FETCH_PURCHASE_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isGroupBuyer: action.payload.isGroupBuyer,
        selectedGroupSiteId: action.payload.selectedGroupSiteId,
        purchaseDetails: action.payload.purchaseDetails,
        paymentOptions: {
          ...state.paymentOptions,
          options: action.payload.paymentOptions,
          /**
           * TODO: There is currently no real concept of a 'default option' on the backend.
           * This will come in post-MVP. As it stands, if there is a single option it should be
           * pre-selected. This drives accordion behaviour
           * selectedOption: action.payload.deliveryOptions.find(item => item.defaultOption) || null
           */
          selectedOption:
            action.payload.paymentOptions.length === 1
              ? action.payload.paymentOptions[0]
              : null,
        },
        deliveryOptions: {
          ...state.deliveryOptions,
          options: action.payload.deliveryOptions,
          /**
           * TODO: There is currently no real concept of a 'default option' on the backend.
           * This will come in post-MVP. As it stands, if there is a single option it should be
           * pre-selected. This drives accordion behaviour
           * selectedOption: action.payload.deliveryOptions.find(item => item.defaultOption) || null
           */
          selectedOption:
            action.payload.deliveryOptions.length === 1
              ? action.payload.deliveryOptions[0]
              : null,
        },
        groupBuyerOptions: {
          ...state.groupBuyerOptions,
          options: action.payload.groupBuyerOptions,
          selectedOption: null,
        },
      };
    case SET_SELECTED_PAYMENT_OPTION:
      return {
        ...state,
        paymentOptions: {
          ...state.paymentOptions,
          selectedOption: action.payload,
        },
      };
    case SET_SELECTED_DELIVERY_OPTION:
      return {
        ...state,
        deliveryOptions: {
          ...state.deliveryOptions,
          selectedOption: action.payload,
        },
      };
    case ADD_NEW_DELIVERY_OPTION:
      return {
        ...state,
        deliveryOptions: {
          ...state.deliveryOptions,
          options: [...state.deliveryOptions.options, action.payload],
        },
      };
    case CLEAR_PURCHASE_DETAILS:
      return {
        ...initialState,
        error: state.error,
        isLoadingConfirmation: state.isLoadingConfirmation,
      };
    case ORDER_CONFIRMATION_REQUEST:
      return {
        ...state,
        isLoadingConfirmation: true,
      };
    case ORDER_CONFIRMATION_REQUEST_SUCCESS:
      return {
        ...state,
        error: null,
        isLoadingConfirmation: false,
      };
    case ORDER_CONFIRMATION_REQUEST_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoadingConfirmation: false,
      };
    default:
      return state;
  }
};

export default reducer;
