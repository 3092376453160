import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Exclude = ({ width, height, strokeColour, strokeWidth }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="none"
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          transform="translate(-1497.000000, -161.000000)"
          stroke={stroke}
          strokeWidth={strokeWidth}
        >
          <g transform="translate(1498.000000, 162.000000)">
            <path d="M0.8,0.8 L15.2,15.2" />
            <path d="M0.8,15.2 L15.2,0.8" />
          </g>
        </g>
      </g>
    </svg>
  );
};

Exclude.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

Exclude.defaultProps = {
  width: "18",
  height: "18",
  strokeWidth: "2"
};

export default Exclude;
