import {
  FilterApi,
  BidsAndRequestsSecondaryFilterApi,
  InvoiceFilterApi,
  PurchasesArchiveSecondaryFilterApi,
  MessagesFilterApi,
  ActivityLogsFilterApi,
  ClaimsFilterApi,
  ClaimsVendorFilterApi,
} from "../../api/Filter";
import { makeSearchRequest } from "./search";
import { clearActiveFilter } from "./global";
import { fetchShortlist } from "./shortlist";
import { fetchInvoices } from "./invoices";
import { fetchBidsAndRequests } from "./bidsAndRequests";
import { fetchPurchasesArchive } from "./purchasesArchive";
import {
  formatFilterOptions,
  getFilters,
  FILTER_MAP,
  getAllFilters,
} from "../../shared/filter";
import { resetPagination } from "./pagination";
import { fetchMessages } from "./messages";
import { fetchActivityLogs } from "./activityLogs";
import { fetchClaims } from "./claims";
import { fetchVendorClaims } from "./claimsVendor";
import { fetchVendorClaimsDashboardInfo } from "./claimsVendorDashboard";

// Fetch
export const FETCH_FILTER_OPTIONS = "@filter/FETCH_FILTER_OPTIONS";
export const FETCH_FILTER_OPTIONS_SUCCESS =
  "@filter/FETCH_FILTER_OPTIONS_SUCCESS";
export const FETCH_FILTER_OPTIONS_FAILURE =
  "@filter/FETCH_FILTER_OPTIONS_FAILURE";

// Interact
export const TOGGLE_CHECKBOX_FILTER_OPTION =
  "@filter/TOGGLE_CHECKBOX_FILTER_OPTION";
export const SET_SELECT_FILTER_OPTION = "@filter/SET_SELECT_FILTER_OPTION";
export const SET_RANGE_FILTER_OPTION = "@filter/SET_RANGE_FILTER_OPTION";
export const SET_INPUT_FILTER_OPTION = "@filter/SET_INPUT_FILTER_OPTION";
export const SET_TAB_FILTER_OPTION = "@filter/SET_TAB_FILTER_OPTION";
export const CLEAR_FILTERS = "@filter/CLEAR_FILTERS";
export const CLEAR_FILTERS_BY_ID = "@filter/CLEAR_FILTERS_BY_ID";
export const RECONCILE_SELECTED_STATE = "@filter/RECONCILE_SELECTED_STATE";
export const SET_DEFAULT_FILTERS = "@filter/SET_DEFAULT_FILTERS";
export const SET_DEFAULT_FILTERS_SUCCESS =
  "@filter/SET_DEFAULT_FILTERS_SUCCESS";
export const SET_DEFAULT_FILTERS_FAILURE =
  "@filter/SET_DEFAULT_FILTERS_FAILURE";

const fetchFilterOptionsSuccess = (data) => (dispatch, getState) => {
  const { filterId, data: responseData } = data;
  const targetFilter = getState().filter.filters[filterId];
  /**
   * Some filters may not return options that were previously
   * selected, in that case the selected counts will be off by (n).
   * Here we check to see if we have any orphans and clear them
   * from the state.
   */
  if (getAllFilters("checkboxTypes").includes(filterId)) {
    const selectedValues = targetFilter.selected.values;
    const refreshedValues = responseData.options.map((option) => option.value);
    const orphanedList = selectedValues.filter(
      (value) => !refreshedValues.includes(value)
    );
    if (orphanedList.length) {
      dispatch({
        type: RECONCILE_SELECTED_STATE,
        payload: {
          filterId,
          values: orphanedList,
        },
      });
    }
  }
  dispatch({
    type: FETCH_FILTER_OPTIONS_SUCCESS,
    payload: data,
  });
};

const fetchFilterOptionsFailure = (error) => {
  return {
    type: FETCH_FILTER_OPTIONS_FAILURE,
    payload: error,
  };
};

const getApiInstance = ({ resultType, params, credentials }) => {
  switch (resultType) {
    case "bidsAndRequests":
      return new BidsAndRequestsSecondaryFilterApi({ params, credentials });
    case "invoices":
      return new InvoiceFilterApi({ params, credentials });
    case "activityLogs":
      return new ActivityLogsFilterApi({ params, credentials });
    case "purchasesArchive":
      return new PurchasesArchiveSecondaryFilterApi({ params, credentials });
    case "messages":
      return new MessagesFilterApi({ params, credentials });
    case "claims":
      return new ClaimsFilterApi({ params, credentials });
    case "vendor":
      return new ClaimsVendorFilterApi({ params, credentials });
    default:
      return new FilterApi({ params, credentials });
  }
};

export const setDefaultFilters = (config) => async (dispatch) => {
  const { defaultFilters, resultType } = config;

  dispatch({ type: SET_DEFAULT_FILTERS });

  let promises = [];

  for (var filterData of defaultFilters) {
    let p = await dispatch(
      toggleElementsInsideFilter({
        resultType,
        filterData,
      })
    );
    promises.push(p);
  }
  return Promise.all(promises);
};

const toggleElementsInsideFilter = (config) => (dispatch, getState) => {
  const { resultType, filterData } = config;

  const filters = getState().filter.filters;
  const toolbar = getState().toolbar;
  const { bucketType } = toolbar;
  const credentials = getState().authentication.credentials;

  dispatch({ type: FETCH_FILTER_OPTIONS, payload: filterData.filterId });

  const params = {
    filterType: filterData.filterId,
    filters,
    purchaseSession: bucketType,
    isShortlisting: resultType === "shortlist",
  };

  let filterInstance = getApiInstance({
    resultType,
    params,
    credentials: credentials,
  });

  return filterInstance.call().then(
    (response) => {
      dispatch(fetchFilterOptionsSuccess(response));

      const availableValues = response.data.options.map((el) => el.value);
      let areFiltersAvailable = true;

      if (getAllFilters("checkboxTypes").includes(filterData.filterId)) {
        filterData.values.forEach((value) => {
          if (availableValues.includes(value)) {
            dispatch(
              toggleCheckboxFilterOption({
                filterId: filterData.filterId,
                value: value,
              })
            );
          }
          else{
            areFiltersAvailable = false;
          }
        });  

        if (!areFiltersAvailable) {
          return Promise.reject(new Error("Filters not available"));
        }
      }
      else if (getAllFilters("selectTypes").includes(filterData.filterId)) {
        if (availableValues.includes(filterData.values)) {
          dispatch(
            setSelectFilterOption({
                filterId: filterData.filterId,
                value: filterData.values,
            })
          );  
        }
        else {
          areFiltersAvailable = false;
        }

        if (!areFiltersAvailable) {
          return Promise.reject(new Error("Filters not available"));
        }
      } 
    },
    (err) => {
      dispatch(fetchFilterOptionsFailure(err))
      return Promise.reject(err);
    }
  );
};

export const setDefaultFiltersSuccess = () => {
  return {
    type: SET_DEFAULT_FILTERS_SUCCESS,
  };
};
export const setDefaultFiltersFailure = (error) => {
  return {
    type: SET_DEFAULT_FILTERS_FAILURE,
  };
};
/**
 * @param {{ filterId: string }} config
 * @returns {function(...[*]=)}
 */
export const fetchFilterOptions = (config = {}) => (dispatch, getState) => {
  const { filterId } = config;
  dispatch({ type: FETCH_FILTER_OPTIONS, payload: filterId });

  const resultType = getState().global.results.resultType;
  const filters = getState().filter.filters;
  const toolbar = getState().toolbar;
  const registration = getState().regSearch.value;
  const { bucketType } = toolbar;
  const params = {
    filterType: filterId,
    filters,
    purchaseSession: bucketType,
    isShortlisting: resultType === "shortlist",
    registration,
  };

  let filterInstance = getApiInstance({
    resultType,
    params,
    credentials: getState().authentication.credentials,
  });

  filterInstance.call().then(
    (response) => dispatch(fetchFilterOptionsSuccess(response)),
    (err) => dispatch(fetchFilterOptionsFailure(err))
  );
};

/**
 * TODO: there is an argument that this could be moved
 * into the `global` reducer, as a version of it is also
 * used in the pagination container for refreshing.
 * @returns {function(...[*]=)}
 */
export const fetchResults = () => (dispatch, getState) => {
  const resultType = getState().global.results.resultType;
  switch (resultType) {
    case "shortlist":
      return dispatch(fetchShortlist());
    case "bidsAndRequests":
      return dispatch(fetchBidsAndRequests());
    case "purchasesArchive":
      return dispatch(fetchPurchasesArchive());
    case "invoices":
      return dispatch(fetchInvoices());
    case "activityLogs":
      return dispatch(fetchActivityLogs());
    case "messages":
      return dispatch(fetchMessages());
    case "claims":
      return dispatch(fetchClaims());
    case "claimsVendor":
      return dispatch(fetchVendorClaims());
    case "claimsVendorDashboard":
      return dispatch(fetchVendorClaimsDashboardInfo());
    default:
      return dispatch(makeSearchRequest());
  }
};

/**
 * @param {{ filterId: string, value: number | string, withRefresh: boolean }} config
 */
export const toggleCheckboxFilterOption = (config = {}) => (dispatch) => {
  const { filterId, value, withRefresh } = config;
  dispatch({
    type: TOGGLE_CHECKBOX_FILTER_OPTION,
    payload: {
      filterId,
      value,
    },
  });
  if (withRefresh) {
    dispatch(resetPagination());
    return dispatch(fetchResults());
  }
};

export const toggleParentFilter = ({ compoundId, value, withRefresh }) => (
  dispatch,
  getState
) => {
  const { parent, child } = compoundId;
  if (getState().filter.activeValues.includes(value)) {
    // We are toggling off, reconcile child
    dispatch(reconcileChildFilter({ parentValue: value, childId: child }));
  }
  dispatch(
    toggleCheckboxFilterOption({ filterId: parent, value, withRefresh })
  );
};

/**
 * Although this is essentially a wrapper for toggleCheckboxFilterOption,
 * I wanted to explicitly refer to the child/parent relationship in the
 * container
 */
export const toggleChildFilter = ({ compoundId, value, withRefresh }) => (
  dispatch
) => {
  const { child } = compoundId;
  dispatch(toggleCheckboxFilterOption({ filterId: child, value, withRefresh }));
};

/**
 * If a child filter has options that become orphaned (for eg, we have selected ranges
 * but the parent make has been deselected), we need to clean that state up
 */
export const reconcileChildFilter = (compoundValue) => (dispatch, getState) => {
  const { parentValue, childId } = compoundValue;
  const childOptions = getState().filter.filters[childId].options;
  const illegalValues = childOptions.reduce((previousResult, currentItem) => {
    if (currentItem.groupName === parentValue) {
      return [...previousResult, currentItem.value];
    }
    return previousResult;
  }, []);
  dispatch({
    type: RECONCILE_SELECTED_STATE,
    payload: { filterId: childId, values: illegalValues },
  });
};

/**
 *
 * @param {{ filterId: string, value: number | string, withRefresh: boolean }} config
 * @returns {function(...[*]=)}
 */
export const setSelectFilterOption = (config = {}) => (dispatch) => {
  const { filterId, value, withRefresh } = config;
  dispatch({
    type: SET_SELECT_FILTER_OPTION,
    payload: {
      filterId,
      value,
    },
  });
  if (withRefresh) {
    dispatch(resetPagination());
    dispatch(fetchResults());
  }
};

export const setTabFilterOption = (config = {}) => (dispatch) => {
  const { filterId, value, withRefresh } = config;
  dispatch({
    type: SET_TAB_FILTER_OPTION,
    payload: {
      filterId,
      value,
    },
  });
  if (withRefresh) {
    dispatch(resetPagination());
    dispatch(fetchResults());
  }
};

export const setRangeFilterOption = (props = {}) => (dispatch, getState) => {
  const { withRefresh } = props;
  dispatch({
    type: SET_RANGE_FILTER_OPTION,
    payload: props,
  });
  if (withRefresh) {
    dispatch(resetPagination());
    return dispatch(fetchResults());
  }
};

export const setInputFilterOption = (props = {}) => (dispatch, getState) => {
  const { withRefresh } = props;
  dispatch({
    type: SET_INPUT_FILTER_OPTION,
    payload: props,
  });
  if (withRefresh) {
    dispatch(resetPagination());
    return dispatch(fetchResults());
  }
};
export const clearFilters = (withRefresh = true) => (dispatch, getState) => {
  const activeFilter = getState().global.filters.activeFilter;
  if (activeFilter) {
    dispatch(clearActiveFilter());
  }
  dispatch({
    type: CLEAR_FILTERS,
  });
  if (withRefresh) {
    dispatch(fetchResults());
  }
};

export const clearFiltersById = (
  params = { filters: [], withRefresh: true }
) => (dispatch, getState) => {
  const { filters = [], withRefresh = true } = params;

  const filterState = getState().filter;
  const activeFilter = getState().global.filters.activeFilter;

  if (activeFilter) {
    dispatch(clearActiveFilter());
  }

  const { activeValues } = filterState;

  // For range/select types we put the filter id into the activeValues array
  const targetFiltersArray = activeValues.filter((value) =>
    filters.includes(value)
  );
  const targetValuesObject = filters.reduce(
    (previousResult, currentItem) => ({
      ...previousResult,
      [currentItem]: formatFilterOptions({
        filterObject: filterState.filters,
      })[FILTER_MAP[currentItem] || currentItem],
    }),
    {}
  );
  const targetValuesArray = filters.reduce((previousResult, currentItem) => {
    // We are only interested in checkbox types
    if (Array.isArray(targetValuesObject[currentItem])) {
      return [...previousResult, ...targetValuesObject[currentItem]];
    }
    return previousResult;
  }, []);

  dispatch({
    type: CLEAR_FILTERS_BY_ID,
    payload: {
      filters: filters,
      values: [...targetValuesArray, ...targetFiltersArray],
    },
  });

  if (withRefresh) {
    dispatch(fetchResults());
  }
};

export const initialState = {
  isFetching: false,
  isSettingDefaultFilters: false,
  error: null,
  filters: {
    ...getFilters(),
  },
  activeValues: [],
};

// TODO: some of these updates are very verbose, hide behind helpers
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FILTER_OPTIONS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_FILTER_OPTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case FETCH_FILTER_OPTIONS_SUCCESS: {
      const { filterId } = action.payload;
      return {
        ...state,
        isFetching: false,
        filters: {
          ...state.filters,
          [filterId]: {
            ...state.filters[filterId],
            ...action.payload.data,
          },
        },
      };
    }
    case TOGGLE_CHECKBOX_FILTER_OPTION: {
      const { filterId, value } = action.payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          [filterId]: {
            ...state.filters[filterId],
            selected: {
              byValue: {
                ...state.filters[filterId].selected.byValue,
                [value]: !state.filters[filterId].selected.byValue[value],
              },
              values: state.filters[filterId].selected.values.includes(value)
                ? state.filters[filterId].selected.values.filter(
                    (selectedValue) => selectedValue !== value
                  )
                : [...state.filters[filterId].selected.values, value],
            },
          },
        },
        activeValues: state.activeValues.includes(value)
          ? [
              ...state.activeValues.filter(
                (activeValue) => activeValue !== value
              ),
            ]
          : [...state.activeValues, value],
      };
    }
    case RECONCILE_SELECTED_STATE: {
      const { filterId, values } = action.payload;
      const reconciledState = {
        ...state,
        filters: {
          ...state.filters,
          [filterId]: {
            ...state.filters[filterId],
            selected: {
              ...state.filters[filterId].selected,
              values: state.filters[filterId].selected.values.filter(
                (selectedValue) => !values.includes(selectedValue)
              ),
            },
          },
        },
        activeValues: state.activeValues.filter(
          (activeValue) => !values.includes(activeValue)
        ),
      };
      // Reset any checkbox UI state that exists
      values.forEach(
        (selectedValue) =>
          (reconciledState.filters[filterId].selected.byValue[
            selectedValue
          ] = false)
      );
      return reconciledState;
    }
    case SET_SELECT_FILTER_OPTION: {
      const { filterId, value } = action.payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          [filterId]: {
            ...state.filters[filterId],
            selected: value,
          },
        },
        activeValues: state.filters[filterId].selected
          ? state.activeValues
          : [...state.activeValues, filterId],
      };
    }
    case SET_RANGE_FILTER_OPTION: {
      const { filterId, value } = action.payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          [filterId]: {
            selected: {
              ...state.filters[filterId].selected,
              ...value,
            },
          },
        },
        activeValues:
          state.filters[filterId].selected.to ||
          state.filters[filterId].selected.from
            ? state.activeValues
            : [...state.activeValues, filterId],
      };
    }
    case SET_INPUT_FILTER_OPTION: {
      const { filterId, value } = action.payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          [filterId]: {
            selected: {
              ...state.filters[filterId].selected,
              value,
            },
          },
        },
        activeValues: state.filters[filterId].selected.value
          ? state.activeValues
          : [...state.activeValues, filterId],
      };
    }
    case SET_TAB_FILTER_OPTION: {
      const { filterId, value } = action.payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          [filterId]: {
            ...state.filters[filterId],
            selected: value,
          },
        },
        activeValues: state.filters[filterId].selected
          ? state.activeValues
          : [...state.activeValues, filterId],
      };
    }
    case CLEAR_FILTERS_BY_ID:
      return {
        ...state,
        activeValues: state.activeValues.filter(
          (activeValue) => !action.payload.values.includes(activeValue)
        ),
        filters: {
          ...state.filters,
          ...action.payload.filters.reduce(
            (previousResult, currentItem) => ({
              ...previousResult,
              [currentItem]: initialState.filters[currentItem],
            }),
            {}
          ),
        },
      };
    case SET_DEFAULT_FILTERS:
      return {
        ...state,
        isSettingDefaultFilters: true,
      };
    case SET_DEFAULT_FILTERS_SUCCESS:
      return {
        ...state,
        isSettingDefaultFilters: false,
      };
    case SET_DEFAULT_FILTERS_FAILURE:
      return {
        ...state,
        isSettingDefaultFilters: false,
      };
    case CLEAR_FILTERS:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
