import styled from "styled-components";

import SkeletonBidModal from "./SkeletonBidModal.jsx";
import breakpoints from "../../../theme/breakpoints";

const StyledSkeletonBidModal = styled(SkeletonBidModal)`
  height: 100%;

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    height: auto;
  }

  .pricing-blocks {
    display: flex;

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      display: block;
    }
  }
  .panel__content > div {
    animation: shimmer 3s infinite linear;
    background: linear-gradient(
      to right,
      ${props => props.theme.COLOURS.PRIMARY.shades[5]} 4%,
      ${props => props.theme.COLOURS.SECONDARY.shades[10]} 25%,
      ${props => props.theme.COLOURS.PRIMARY.shades[10]} 36%
    );
    background-size: 100rem 100%;
  }
  .pricing-block__heading {
    height: 1rem;
    margin-bottom: ${props => props.theme.SPACING.SMALL};
    width: 50%;
  }
  .pricing-block__price {
    height: 4rem;
    margin-bottom: ${props => props.theme.SPACING.SMALL};
    width: 70%;
  }
  .pricing-block__desc {
    height: 2rem;
    margin-bottom: ${props => props.theme.SPACING.LARGE};
  }
  .bidding-block__heading {
    height: 4rem;
    margin-bottom: ${props => props.theme.SPACING.LARGE};
    width: 50%;
  }
  .bidding-block__desc {
    height: 7.8rem;
    margin-bottom: ${props => props.theme.SPACING.MEDIUM};
  }
  .bidding-block__form-title {
    height: 3rem;
    margin-bottom: ${props => props.theme.SPACING.REGULAR};
    width: 60%;
  }
  .bidding-block__form {
    height: 4rem;
    margin-bottom: ${props => props.theme.SPACING.LARGE};
  }

  @keyframes shimmer {
    0% {
      background-position: -100rem 0;
    }
    100% {
      background-position: 100rem 0;
    }
  }
`;

export default StyledSkeletonBidModal;
