import styled, { css } from "styled-components";

import KeyInformationLabel from "./KeyInformationLabel.jsx";
import breakpoints from "../../theme/breakpoints.js";

export const StyledKeyInformationLabel = styled(KeyInformationLabel)`
  color: ${props => props.theme.COLOURS.PRIMARY.shades[50]};
  font-family: ${props => props.theme.FONTS.families.lato};
  font-weight: ${props => props.theme.FONTS.weights[70]};
  font-size: ${props => props.theme.FONTS.sizes[10]};
  line-height: ${props => props.theme.FONTS.lineHeights[10]};
  letter-spacing: 0.1rem;

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    font-size: ${props => props.theme.FONTS.sizes[20]};
    line-height: ${props => props.theme.FONTS.lineHeights[20]};
  }
  
  ${props =>
    props.light &&
    css`
      font-size: ${props.theme.FONTS.sizes[30]};
      font-weight: ${props.theme.FONTS.weights[40]};
      letter-spacing: normal;

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        font-size: ${props.theme.FONTS.sizes[30]};
        font-weight: ${props.theme.FONTS.weights[40]};
      }
    `}

  ${props =>
    props.small &&
    css`
      font-size: ${props.theme.FONTS.sizes[10]};
      line-height: ${props.theme.FONTS.lineHeights[10]};
      letter-spacing: 0.075rem;

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        /* Specificity issue */
        font-size: ${props.theme.FONTS.sizes[10]};
        line-height: ${props.theme.FONTS.lineHeights[10]};
      }
    `}

  ${props =>
    props.large &&
    css`
      font-size: ${props.theme.FONTS.sizes[40]};
      line-height: ${props.theme.FONTS.lineHeights[40]};

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        /* Specificity issue */
        font-size: ${props.theme.FONTS.sizes[40]};
        line-height: ${props.theme.FONTS.lineHeights[40]};
      }
    `}
  text-transform: uppercase;
  display: block; /* Just for styleguide display reasons*/
`;

export default StyledKeyInformationLabel;
