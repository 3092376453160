import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ButtonLink from "../../components/ButtonLink";
import Icon from "../../components/Icon";
import ProgressBar from "../../components/ProgressBar";
import { TrashIconContainer } from "../../components/ClaimItem/index";
import {
  deleteSupportingDocument,
  saveSupportingBlockDocument,
} from "../../../state/reducers/claims";
import BidInputError from "../../components/BidInput/child-components/BidInputError";
import { errorMessages } from "../../components/ClaimItem/consts";

const SupportingDocument = ({ supportingDocument, claimId, disabled }) => {
  const dispatch = useDispatch();
  const {
    progress,
    beginingOfTheBlock,
    endOfTheBlock,
    noOfBlocks,
    uploaded,
    duplicateDocument,
    blockNo,
    file,
    fileName,
    url,
  } = supportingDocument;

  const handleFile = () => {
    let block = file.slice(beginingOfTheBlock, endOfTheBlock);
    const percentage = (blockNo / noOfBlocks) * 100;

    const data = {
      claimId: claimId,
      fileName: file.name,
      blockNo: blockNo,
      blockSize: block.size,
      finalBlock: blockNo === noOfBlocks,
      block: block,
      percentage: percentage,
      duplicateDocument: false,
    };
    onSaveSupportingBlockDocument(data);
  };

  const onSaveSupportingBlockDocument = (data) => {
    dispatch(saveSupportingBlockDocument(data));
  };

  const onDeleteSupportingDocument = (fileName) => {
    dispatch(deleteSupportingDocument(claimId, fileName));
  };

  useEffect(() => {
    if (!uploaded && !duplicateDocument) {
      handleFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportingDocument]);

  return (
    <div>
      <div>
        {supportingDocument.duplicateDocument ? (
          <div className="failedItems">
            <ButtonLink
              className="failedFile"
              key={fileName}
              listItem
              name={fileName}
            />
            <BidInputError errorMessage={errorMessages.DUPLICATE_FILE} />
          </div>
        ) : (
          <ButtonLink
            key={fileName}
            listItem
            primary
            name={fileName}
            disabled={!url}
            action={() => {
              window.open(url, "_blank");
            }}
          />
        )}
      </div>
      {!disabled && !supportingDocument.duplicateDocument && (
        <div>
          {uploaded ? (
            <TrashIconContainer
              className="drag-drop__trash"
              onClick={() => onDeleteSupportingDocument(fileName)}
            >
              <Icon type="trash" width="24" height="24" />
            </TrashIconContainer>
          ) : (
            <ProgressBar value={progress} />
          )}
        </div>
      )}
    </div>
  );
};

export default SupportingDocument;