import Invoices from "../../api/Invoices";
import { updateObject } from "../../shared/utility";
import { clearActiveFilter, openModal, setActiveFilter } from "./global";
import { clearFilters } from "./filter";

// Action Constants
export const FETCH_INVOICES = "@invoices/FETCH_INVOICES";
export const FETCH_INVOICES_SUCCESS = "@invoices/FETCH_INVOICES_SUCCESS";
export const FETCH_INVOICES_FAILURE = "@invoices/FETCH_INVOICES_FAILURE";
export const CLEAR_INVOICES = "@invoices/CLEAR_INVOICES";
export const INVOICE_NUMBER_REQUEST = "@invoices/INVOICE_NUMBER_REQUEST";
export const INVOICE_NUMBER_REQUEST_SUCCESS =
  "@invoices/INVOICE_NUMBER_REQUEST_SUCCESS";
export const INVOICE_NUMBER_REQUEST_FAILURE =
  "@invoices/INVOICE_NUMBER_REQUEST_FAILURE";
export const CLEAR_INVOICE_NUMBER_REQUEST =
  "@invoices/CLEAR_INVOICE_NUMBER_REQUEST";
export const SET_DOWNLOAD_INVOICE_ID = "@invoices/SET_DOWNLOAD_INVOICE_ID";

// Action Creators
export const fetchInvoicesSuccess = data => {
  return {
    type: FETCH_INVOICES_SUCCESS,
    payload: data
  };
};

export const fetchInvoicesFailure = error => {
  return {
    type: FETCH_INVOICES_FAILURE,
    payload: error
  };
};

export const fetchInvoices = () => (dispatch, getState) => {
  dispatch({ type: FETCH_INVOICES });
  const toolbar = getState().toolbar;

  const { pageSize, sort } = toolbar;
  const pageNumber = getState().pagination.pageNumber;
  const filters = getState().filter.filters;
  // TODO: The endpoint doesn't work if we send an empty sort object
  let params = { pageSize, pageNumber, filters };
  if (Object.keys(sort).length) {
    params = {
      ...params,
      sort
    };
  }

  const invoicesInstance = new Invoices({
    params,
    credentials: getState().authentication.credentials
  });
  invoicesInstance.call().then(
    response => {
      dispatch(fetchInvoicesSuccess(response));
    },
    err => {
      return dispatch(fetchInvoicesFailure(err));
    }
  );
};

const invoiceNumberRequestSuccess = () => {
  return {
    type: INVOICE_NUMBER_REQUEST_SUCCESS
  };
};

const invoiceNumberRequestFailure = error => {
  return {
    type: INVOICE_NUMBER_REQUEST_FAILURE,
    payload: error
  };
};

export const invoiceNumberRequest = invoiceNo => (dispatch, getState) => {
  dispatch({
    type: INVOICE_NUMBER_REQUEST,
    payload: invoiceNo
  });
  dispatch(setActiveFilter("invoiceNo"));
  dispatch({
    type: FETCH_INVOICES
  });

  const params = {
    invoiceNo
  };

  const invoicesInstance = new Invoices({
    params,
    credentials: getState().authentication.credentials
  });
  invoicesInstance.call().then(
    response => {
      dispatch(invoiceNumberRequestSuccess());
      dispatch(clearFilters(false));
      return dispatch(fetchInvoicesSuccess(response));
    },
    err => {
      dispatch(invoiceNumberRequestFailure(err));
      dispatch(clearActiveFilter());
      dispatch(openModal("generic-error"));
    }
  );
};

export const clearInvoiceNumberRequest = () => {
  return {
    type: CLEAR_INVOICE_NUMBER_REQUEST
  };
};

export const setDownloadInvoiceId = invoiceId => {
  return {
    type: SET_DOWNLOAD_INVOICE_ID,
    payload: invoiceId
  };
};

export const clearInvoices = () => {
  return {
    type: CLEAR_INVOICES
  };
};

export const initialState = {
  isFetching: false,
  error: false,
  invoiceSearchValue: null,
  downloadInvoiceId: null,
  results: {
    byId: {},
    invoiceIds: []
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INVOICES:
      return updateObject(state, { isFetching: true });
    case FETCH_INVOICES_FAILURE:
      return updateObject(state, { isFetching: false, error: action.payload });
    case FETCH_INVOICES_SUCCESS:
      return updateObject(state, {
        isFetching: false,
        totalCount: action.payload.totalCount,
        pageSize: action.payload.pageSize,
        pageCount: action.payload.pageCount,
        results: action.payload.results
      });
    case INVOICE_NUMBER_REQUEST:
      return {
        ...state,
        isFetching: true,
        invoiceSearchValue: action.payload
      };
    case INVOICE_NUMBER_REQUEST_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      };
    case INVOICE_NUMBER_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case CLEAR_INVOICE_NUMBER_REQUEST:
      return {
        ...state,
        isFetching: false,
        invoiceSearchValue: null
      };
    case SET_DOWNLOAD_INVOICE_ID:
      return {
        ...state,
        downloadInvoiceId: action.payload
      };
    case CLEAR_INVOICES:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default reducer;
