import styled from "styled-components";
import VehicleCardTitles from "./VehicleCardTitles";
import { StyledSubHeaderText } from "../SubHeaderText";
import { StyledParagraphText } from "../ParagraphText";
import breakpoints from "../../theme/breakpoints";

export const StyledVehicleCardTitles = styled(VehicleCardTitles)`
  .vehicle-card-titles {
    &__link {
      text-decoration: none;
      cursor: pointer;
      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        width: 44rem;
      }
    }
    &__link:hover {
      color: ${props => props.theme.COLOURS.PRIMARY.base};

      ${StyledSubHeaderText},
      ${StyledParagraphText} {
        text-decoration: underline;
      }
    }
    &__link:active {
      color: ${props => props.theme.COLOURS.PRIMARY.shades[90]};
    }
    &__main {
      display: flex;
      align-items: center;
      &__title {
        display: flex;
        max-width: ${props => !props.small && "78%"};
        margin-right: ${props => props.theme.SPACING.SMALL};
        &__text {
          @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    &__subtitle {
      display: flex;
      &__text {
        line-height: 2.1rem;
        @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
`;

export default StyledVehicleCardTitles;
