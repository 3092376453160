import styled from "styled-components";
import TextArea from "./TextArea";
import breakpoints from "../../theme/breakpoints";

const StyledTextArea = styled(TextArea)`
  .text-area__input {
    background-color: ${(props) => props.theme.COLOURS.WHITE};
    border-radius: 0.5rem;
    border: 0.2rem solid
      ${(props) =>
        props.validValue
          ? props.theme.COLOURS.PRIMARY.shades[20]
          : props.theme.COLOURS.RED.base};
    box-sizing: border-box;
    color: ${(props) => props.theme.COLOURS.PRIMARY.base};
    padding: ${(props) => props.theme.SPACING.SMALL};
    display: flex;
    justify-content: flex-end;
    width: 100%;
    resize: ${(props) => (props.resize ? props.resize : "initial")};
    ${(props) => props.theme.FONTS.Text["paragraph"]}

    @media (max-width: ${breakpoints.TABLET}) {
      width: 100%;
    }
  }

  .text-area__input:focus {
    @supports (-moz-appearance:none) {
    outline: 2px solid #0059DF;
    border: 2px solid #A6BBD9;
}
  }

  .invalid {
    border-color: ${(props) => props.theme.COLOURS.RED.base};
  }

  .valid {
    border-color: ${(props) => props.theme.COLOURS.PRIMARY.shades[20]};
  }

  .text-area__character-counter {
    display: flex;
    justify-content: flex-end;
    > p {
      font-size: 1.2rem;
      font-style: italic;
    }
  }
`;

export default StyledTextArea;
