import styled from "styled-components";
import { StyledHeaderText } from "../HeaderText";
import { StyledParagraphText } from "../ParagraphText";

import GenericTransitionModal from "./GenericTransitionModal";
import breakpoints from "../../theme/breakpoints";

export const StyledGenericTransitionModal = styled(GenericTransitionModal)`
  background: white;
  height: 100%;
  padding: ${(props) => props.theme.SPACING.REGULAR};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${StyledHeaderText} {
    color: ${(props) => props.theme.COLOURS.GREEN.base};
    font-size: 4rem;
  }

  ${StyledParagraphText} {
    margin-top: 1rem;
    font-size: 2rem;
  }

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    background: white;
    min-height: 28rem;
    padding: ${(props) => props.theme.SPACING.LARGE};
    border: 0.2rem solid ${(props) => props.theme.COLOURS.GREEN.base};
    border-radius: 0.5rem;
  }

  @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
    background: white;
    min-height: 28rem;
    padding: ${(props) => props.theme.SPACING.LARGE};
    border: 0.2rem solid ${(props) => props.theme.COLOURS.GREEN.base};
    border-radius: 0.5rem;
    .text-area {
      margin-top: 20rem;
    }
  }
`;

export default StyledGenericTransitionModal;
