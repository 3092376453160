import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../components/ModalInsert";
import { fetchSalesBrochure } from "./../../../state/reducers/salesBrochure";
import SalesBrochureModalComponent from "../../components/SalesBrochureModalComponent";
import { createLoadingTabForPDFDownload } from "./../../../shared/utility";

const SalesBrochureModalInsert = () => {
  const dispatch = useDispatch();

  const vehicleId = useSelector(state => state.vehicle.data.vehicleId);

  const onClose = () => {
    dispatch(closeModal());
  };

  const onCreateBrochure = (price, salestText) => {
    const pdfBrowserTab = createLoadingTabForPDFDownload(
      "Loading PDF document..."
    );
    dispatch(
      fetchSalesBrochure({
        VehicleId: vehicleId,
        SalePrice: price,
        SalesText: salestText,
        pdfBrowserTab: pdfBrowserTab
      })
    );
    dispatch(closeModal());
  };

  return (
    <ModalInsert layout="flex" width="96rem">
      <SalesBrochureModalComponent
        onClose={onClose}
        onCreateBrochure={onCreateBrochure}
      />
    </ModalInsert>
  );
};

export default SalesBrochureModalInsert;
