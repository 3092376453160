import React from "react";
import ParagraphText from "../ParagraphText";

const BurgerMenu = props => {
  const { className, onClick, notificationCount } = props;
  return (
    <button className={className} onClick={onClick}>
      <div>
        <span />
        <span />
        <span />
      </div>
      {notificationCount && notificationCount !== 0 && (
        <div className="notification">
          <ParagraphText>{notificationCount}</ParagraphText>
        </div>
      )}
    </button>
  );
};

export default BurgerMenu;
