import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";

const AddressField = ({
  className,
  addressText,
  hasError,
  onChangeHandler,
  placeholder,
  disabled
}) => {
  return (
    <div className={className}>
      <textarea
        className={hasError ? "invalid" : "valid"}
        rows="7"
        cols="50"
        spellCheck="false"
        value={addressText}
        onChange={onChangeHandler}
        disabled={disabled}
        placeholder={placeholder}
      ></textarea>
      {hasError && (
        <div className="error">
          <Icon type="danger" />
          Please enter a valid address
        </div>
      )}
    </div>
  );
};

AddressField.propTypes = {
  className: PropTypes.string,
  addressText: PropTypes.string,
  hasError: PropTypes.bool,
  onChangeHandler: PropTypes.func
};

export default AddressField;
