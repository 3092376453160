import styled, { css } from "styled-components";
import ConfirmOrder from "./ConfirmOrder";
import { StyledVehiclePillDetails } from "./../VehiclePillDetails/index";
import { StyledPill } from "./../Pill/index";
import { StyledHeaderText } from "./../HeaderText";
import { StyledParagraphText } from "./../ParagraphText";
import { StyledPriceText } from "./../PriceText";
import { StyledButton } from "./../Button";
import breakpoints from "../../theme/breakpoints";

export const StyledConfirmOrder = styled(ConfirmOrder)`
  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    display: flex;
    max-height: 90vh;
    overflow-y: auto;
  }

  .confirm-order__vehicle-info {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.COLOURS.PRIMARY.shades[5]};

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      border-bottom-left-radius: 1rem;
      width: 49rem;
    }

    .confirm-order__vehicle-info-bottom {
      background-color: ${props => props.theme.COLOURS.PRIMARY.shades[5]};
      border-bottom-left-radius: 1rem;
      box-sizing: border-box;
      height: 28.7rem;
      padding: ${props => props.theme.SPACING.REGULAR}
        ${props => props.theme.SPACING.MEDIUM};

      ${StyledVehiclePillDetails} {
        margin: ${props => props.theme.SPACING.SMALL} 0
          ${props => props.theme.SPACING.SMALL} 0;

        > ${StyledPill} {
          font-size: ${props => props.theme.FONTS.sizes[20]};
          color: ${props => props.theme.COLOURS.PRIMARY.shades[70]};
          background-color: ${props => props.theme.COLOURS.WHITE};
        }
      }

      .confirm-order__key-details {
        display: flex;

        > div {
          flex-basis: 50%;
        }
      }
    }
  }

  .confirm-order__purchase-info {
    background-color: ${props => props.theme.COLOURS.WHITE};
    box-sizing: border-box;
    width: 100%;

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      border-radius: 0 ${props => props.theme.SPACING.SMALL}
        ${props => props.theme.SPACING.SMALL} 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      width: 56rem;
    }

    ${props =>
      css`
        padding: ${props.theme.SPACING.REGULAR};
      `}

    ${StyledHeaderText} {
      margin: 0;
    }

    ${StyledParagraphText} {
      margin-top: 0;
    }

    .confirm-order__purchase-info-top {
      border-bottom: 0.2rem solid
        ${props => props.theme.COLOURS.PRIMARY.shades[5]};
      padding-bottom: ${props => props.theme.SPACING.REGULAR};

      > ${StyledParagraphText} {
        margin-top: ${props => props.theme.SPACING.SMALL};
      }
      .confirm-order__payment,
      .confirm-order__delivery,
      .confirm-order__price {
        display: flex;
        align-items: baseline;
        margin-top: ${props => props.theme.SPACING.REGULAR};

        ${StyledParagraphText} {
          /* TODO - awkward percentage*/
          flex-basis: 38%;
        }
      }

      .confirm-order__option {
        /* TODO - awkward percentage*/
        flex-basis: 62%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: left;
        min-height: 3.6rem;
        user-select: none;
      }

      .confirm-order__option-label {
        padding-right: 1.5rem;
        color: ${props => props.theme.COLOURS.PRIMARY.base};
        ${props => props.theme.FONTS.Text["paragraphCompact"]}
      }

      .confirm-order__option-title {
        color: ${props => props.theme.COLOURS.PRIMARY.base};
        font-weight: ${props => props.theme.FONTS.weights[70]};
        line-height: ${props => props.theme.FONTS.lineHeights[90]};
      }

      .confirm-order__option-text {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &-details {
          font-family: ${props => props.theme.FONTS.families.lato};
          font-size: ${props => props.theme.FONTS.sizes[25]};
          line-height: ${props => props.theme.FONTS.lineHeights[50]};
          color: ${props => props.theme.COLOURS.PRIMARY.shades[70]};
          display: block;
          margin: 0.5rem 0 0;

          @media (min-width: ${breakpoints.TABLET}) {
            white-space: nowrap;
          }

          span {
            @media (max-width: ${breakpoints.TABLET}) {
              display: block;
            }

            &:first-child {
              @media (min-width: ${breakpoints.TABLET}) {
                margin-right: 1rem;
              }
            }
          }
          &--wrap {
            white-space: normal;
          }
        }
      }

      .confirm-order__price {
        padding-top: ${props => props.theme.SPACING.REGULAR};

        @media (max-width: ${breakpoints.LARGE_DESKTOP}) {
          > * {
            padding: 0;
          }
        }
      }
      .confirm-order__price-area {
        /* TODO - awkward percentage*/
        flex-basis: 62%;
        display: flex;
        flex-wrap: wrap;

        .confirm-order__price-label {
          display: flex;
          width: 100%;
          align-items: baseline;
          > p {
            margin: 0;
          }
          > ${StyledPriceText} {
            margin-left: auto;
          }
        }
      }
    }

    .confirm-order__purchase-info-bottom {
      margin-top: ${props => props.theme.SPACING.SMALL};
      ${StyledButton} {
        margin-left: ${props => props.theme.SPACING.SMALL};
      }
    }
  }
`;

export default StyledConfirmOrder;
