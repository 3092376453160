import { updateObject } from "../../shared/utility";
import {
  PurchasesArchiveApi,
  PurchasesArchiveReportApi,
} from "../../api/PurchasesArchive";

// Action constants
export const FETCH_PURCHASES_ARCHIVE =
  "@purchasesArchive/FETCH_PURCHASES_ARCHIVE";
export const FETCH_PURCHASES_ARCHIVE_SUCCESS =
  "@purchasesArchive/FETCH_PURCHASES_ARCHIVE_SUCCESS";
export const FETCH_PURCHASES_ARCHIVE_FAILURE =
  "@purchasesArchive/FETCH_PURCHASES_ARCHIVE_FAILURE";
export const UPDATE_PAGE_NUMBER = "@purchasesArchive/UPDATE_PAGE_NUMBER";
export const CLEAR_PURCHASES_ARCHIVE =
  "@purchasesArchive/CLEAR_PURCHASES_ARCHIVE";
export const FETCH_PURCHASES_ARCHIVE_REPORT =
  "@purchasesArchive/FETCH_PURCHASES_ARCHIVE_REPORT";
export const FETCH_PURCHASES_ARCHIVE_REPORT_SUCCESS =
  "@purchasesArchive/FETCH_PURCHASES_ARCHIVE_REPORT_SUCCESS";
export const FETCH_PURCHASES_ARCHIVE_REPORT_FAILURE =
  "@purchasesArchive/FETCH_PURCHASES_ARCHIVE_REPORT_FAILURE";

// Action creators
export const fetchPurchasesArchiveSuccess = (data) => {
  return {
    type: FETCH_PURCHASES_ARCHIVE_SUCCESS,
    payload: data,
  };
};

export const fetchPurchasesArchiveFailure = (error) => {
  return {
    type: FETCH_PURCHASES_ARCHIVE_FAILURE,
    payload: error,
  };
};

export const clearPuchasesArchive = () => {
  return {
    type: CLEAR_PURCHASES_ARCHIVE,
  };
};

export const fetchPurchasesArchive = () => (dispatch, getState) => {
  dispatch({ type: FETCH_PURCHASES_ARCHIVE });

  // TODO: Sort has different options for this endpoint
  const toolbar = getState().toolbar;
  const pageNumber = getState().pagination.pageNumber;
  const filters = getState().filter.filters;
  const { pageSize } = toolbar;
  const params = {
    pageSize,
    pageNumber,
    filters,
  };

  const purchasesOverviewInstance = new PurchasesArchiveApi({
    params,
    credentials: getState().authentication.credentials,
  });

  purchasesOverviewInstance.call().then(
    (response) => dispatch(fetchPurchasesArchiveSuccess(response)),
    (err) => {
      return dispatch(fetchPurchasesArchiveFailure(err));
    }
  );
};

export const fetchPurchasesArchiveReport = () => (dispatch, getState) => {
  dispatch({ type: FETCH_PURCHASES_ARCHIVE_REPORT });

  const filters = getState().filter.filters;
  const registration = getState().regSearch.value;
  const params = {
    filters,
    registration,
  };

  const purchasesArchiveReportInstance = new PurchasesArchiveReportApi({
    params,
    credentials: getState().authentication.credentials,
  });

  purchasesArchiveReportInstance.call().then(
    (response) => dispatch(fetchPurchasesArchiveReportSuccess(response)),
    (err) => {
      return dispatch(fetchPurchasesArchiveReportFailure(err));
    }
  );
};

export const fetchPurchasesArchiveReportSuccess = () => {
  return {
    type: FETCH_PURCHASES_ARCHIVE_REPORT_SUCCESS,
    payload: true,
  };
};

export const fetchPurchasesArchiveReportFailure = (error) => {
  return {
    type: FETCH_PURCHASES_ARCHIVE_REPORT_FAILURE,
    payload: error,
  };
};

export const initialState = {
  isFetching: false,
  isFetchingReport: false,
  error: false,
  results: {
    byId: {},
    vehicleIds: [],
  },
  pageNumber: 1,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PURCHASES_ARCHIVE:
      return updateObject(state, { isFetching: true });
    case FETCH_PURCHASES_ARCHIVE_SUCCESS:
      return updateObject(state, {
        isFetching: false,
        totalCount: action.payload.totalCount,
        pageSize: action.payload.pageSize,
        pageCount: action.payload.pageCount,
        results: action.payload.results,
      });
    case FETCH_PURCHASES_ARCHIVE_FAILURE:
      return updateObject(state, {
        isFetching: false,
        results: {
          byId: {},
          vehicleIds: [],
        },
        error: true,
      });
    case CLEAR_PURCHASES_ARCHIVE:
      return {
        ...initialState,
      };
    case FETCH_PURCHASES_ARCHIVE_REPORT:
      return {
        ...state,
        isFetchingReport: true,
      };
    case FETCH_PURCHASES_ARCHIVE_REPORT_FAILURE:
      return {
        ...state,
        isFetchingReport: false,
        error: action.payload,
      };
    case FETCH_PURCHASES_ARCHIVE_REPORT_SUCCESS:
      return {
        ...state,
        isFetchingReport: false,
      };
    default:
      return state;
  }
};

export default reducer;
