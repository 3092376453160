import Api from "./Base";
import settings from "../clientappsettings.json";

class ClaimActivityLogApi extends Api {
  baseUrl = settings.BUYERS_URL;
  requestUrl = `/Claims/ActivityLog/${this.getParams().claimId}`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatResponseData(response) {
    if (response.data.activityLog) {
      return response.data.activityLog;
    }
    return response;
  }
}

export default ClaimActivityLogApi;
