import BaseApi from "./Base";
import clientSettings from "../clientappsettings.json";
import get from "lodash.get";
import { getResponseObjectProperties } from "../shared/utility";

class DetailsApi extends BaseApi {
  baseUrl = clientSettings.API_URL;
  requestUrl = this.options.params.pathname;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatResponseData(response) {
    const data = get(response, "data", {});
    const titleFields = ["make", "range", "model", "derivative"];
    const detailFields = [
      "dateOfRegistration",
      "odometer",
      "odometerType",
      "fuelType",
      "transmission",
      "colour",
      "interiorColour",
      "capValuation",
      "capValuationDate",
      "salesText",
      "dateOfMileageReading",
      "regNo",
      "numberOfDoors",
      "numberOfSeats",
      "bodyStyle",
      "formerKeepers",
      "engineSize",
      "emissions",
      "vehicleType",
      "additionalEquipment",
      "mostRecentInspectionId",
      "buyerNotes",
    ];
    const keyInfoFields = [
      "serviceHistory",
      "v5",
      "mot",
      "motExpiryDate",
      "auctionGrade",
      "condition",
      "damagePercentage",
    ];
    const buyingOptionFields = [
      "allowBuyNow",
      "bidCount",
      "bidCurrentPrice",
      "bidStartPrice",
      "bidStatus",
      "allowBid",
      "biddingEndDateTime",
      "buyNowPrice",
      "currentStatus",
      "hasPreviousBid",
      "purchasingStatus",
      "purchasePrice",
      "invoiceId",
      "invoiceType",
      "vehiclePurchaseId",
      "vatStatus",
      "comingSoon",
      "endDateTime",
      "saleStatus",
      "saleStatusText",
      "campaignReference",
      "campaignId",
    ];
    const vendorFields = ["vehicleLocation", "vendorName"];
    const shortlisted = get(data, "shortlisted", false);
    const vehicleId = get(data, "vehicleId", 0);

    return {
      vehicleId: vehicleId,
      shortlisted: shortlisted,
      keyInfo: getResponseObjectProperties(data, keyInfoFields, true),
      titles: getResponseObjectProperties(data, titleFields, true),
      details: getResponseObjectProperties(data, detailFields, true),
      buyingOptions: getResponseObjectProperties(data, buyingOptionFields),
      vendorInfo: getResponseObjectProperties(data, vendorFields),
      galleryInfo: get(data, "vehicleBlobUrls", []),
      vehicle360Url: get(data, "vehicle360Url", ""),
      shortlistedVehicleIds: shortlisted ? [vehicleId] : undefined,
    };
  }
}

export default DetailsApi;
