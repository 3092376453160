import styled, { css } from "styled-components";
import breakpoints from "../../../../theme/breakpoints";
import spacing from "../../../../theme/base/spacing";
import fonts from "../../../../theme/base/fonts";
import Resolution from "./Resolution";
import ResolutionCostBreakdown from "./ResolutionCostBreakdown";
import ResolutionTotalCost from "./ResolutionTotalCost";
import Button from "../../../Button";
import InputLabel from "../../../InputLabel";
import SubHeaderText from "../../../SubHeaderText";
import InputError from "./../../../BidInput/child-components/BidInputError";

const StyledResolution = styled(Resolution)`
  .resolution-container {
    width: 100%;
    display: flex;
    justify-content: right;

    @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
      width: 95%;
    }

    ${(props) =>
      props.disabled &&
      css`
        @media (max-width: ${breakpoints.TABLET}) {
          width: 95%;
        }
      `}

    @media (max-width: ${breakpoints.TABLET}) {
      width: 100%;
      padding-left: 0;
    }

    & > div {
      @media (min-width: ${breakpoints.SMALL_DESKTOP}) {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      ${(props) =>
        props.disabled &&
        css`
          @media (min-width: ${breakpoints.SMALL_DESKTOP}) {
            width: 100%;
            display: flex;
            justify-content: center;
          }
        `}
      & > * {
        width: 100%;
      }
    }
  }
`;

export const StyledResolutionCostBreakdown = styled(ResolutionCostBreakdown)`
  .cost-breakdown {
    &__page-container {
     
        &-disabled {
          width: 100%;
        }
        @media (max-width: ${breakpoints.PHONE}) {
            width: 90%;
        }
    }

    &__line-container {
      display: flex;
      align-items: end;
      margin-bottom: ${spacing.SMALL};

      @media ( ${breakpoints.LARGE_PHONE}) {
        flex: 3;
      }

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
       &-disabled {
        justify-content: flex-end;
       }
      }
    
      ${InputLabel} {
          font-weight: 500;
          @media (max-width: ${breakpoints.LARGE_PHONE}) {
           flex: 3;
        }
      }

      @media (max-width: ${breakpoints.LARGE_DESKTOP}) {
        justify-content: flex-end;
      }
      @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
        flex: 0 1 55rem;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-bottom: ${spacing.REGULAR};
      }
    }

    &__total-cost {
        &_container { 
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
            margin-top: ${spacing.MEDIUM};
          }
    
          &_label {
             @media (min-width: ${breakpoints.SMALL_DESKTOP}) {
              flex: 4.6;
             }

             @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
              flex: 3;
             }

             ${(props) =>
               props.disabled &&
               css`
                 @media (min-width: ${breakpoints.SMALL_DESKTOP}) {
                   flex: 7;
                 }
               `}

             @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
                margin-left: ${spacing.LARGE};
                text-align: left;
             }
             @media (max-width: ${breakpoints.LARGE_PHONE}) {
                margin-left: 0;
             }
             @media (max-width: ${breakpoints.TABLET}) {
              &-disabled {
                margin-left: 9.5rem;
              }
            }
            @media (min-width: ${breakpoints.SMALL_DESKTOP}) {
              &-disabled {
                margin-left: 0;
              }
            }
          }
          
          &_value {
             flex: 0.91;
             text-align: left;
             padding-right: ${spacing.SMALL};

             ${(props) =>
               props.disabled &&
               css`
                 @media (min-width: ${breakpoints.SMALL_DESKTOP}) {
                   padding-right: 0;
                 }
               `}

             > * {
                color: ${(props) => props.theme.COLOURS.BLACK};
             }

             ${SubHeaderText} {
               font-size: ${fonts.sizes[60]}
             }
             
             @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
              display: flex;
              justify-content: end;
              padding-right: 0;
             }   
             @media (max-width: ${breakpoints.PHONE}) {
               margin-right: 0;
             }

             &-disabled {
               display: flex;
               justify-content: flex-end;
            }
       }
    }

    &__description { 
      &-container {
        flex: 0 1 56rem;

        @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
            flex: 0 1 41.5rem;
            margin-right: 6rem;
            margin-bottom: 0.75rem;
        }
        @media (max-width: ${breakpoints.LARGE_PHONE}) {
            margin-left: ${spacing.SMALL};

            & > label {
                margin-left: 0.5rem;
            }
        }
        @media (max-width: ${breakpoints.PHONE}) {
            margin-right: ${spacing.LARGE};
        }

        &-disabled {
          margin-right: 0;
        }
      }
    }

    &__cost {
      flex: 1;

      &-group {
        display: flex;
        flex: 0 1 40rem;
        
        ${(props) =>
          props.disabled &&
          css`
            @media (min-width: ${breakpoints.SMALL_DESKTOP}) {
              flex: 0 1 54rem;
            }
          `}

        ${(props) =>
          props.disabled &&
          css`
            @media (min-width: ${breakpoints.SMALL_DESKTOP}) {
              & div:last-child {
                flex: 0 0 8rem;

                & > div {
                  display: flex;
                  justify-content: end;
                }
              }
            }
          `}
 

        & div:last-child label {
            color: ${(props) => props.theme.COLOURS.BLACK};
        }
    
        @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
            flex: 0 1 43rem;
        }
        @media (max-width: ${breakpoints.TABLET}) {
            flex-wrap: wrap;
        }
        @media (max-width: ${breakpoints.LARGE_PHONE}) {
            flex: 0 1 18rem;
            flex-direction: column;
            flex: 1;
        }

        & > * {
            flex-basis: 100%;  

            @media (max-width: ${breakpoints.LARGE_PHONE}) {
              margin-bottom: 0.75rem;
            }
        }
      }

      &-container {
        margin-left: 1.5rem;

        ${(props) =>
          props.disabled &&
          css`
            @media (min-width: ${breakpoints.SMALL_DESKTOP}) {
              text-align: center;

              &:last-child {
                text-align: end;
              }
            }
          `}

        @media (max-width: ${breakpoints.TABLET}) {
           flex: 1;
           justify-content: flex-end;
        }
        @media (max-width: ${breakpoints.LARGE_PHONE}) {
           display: flex;
           align-items: center;
        }
        &:last-child {
          @media (max-width: ${breakpoints.LARGE_PHONE}) {
           margin-top: ${spacing.SMALL};
         }
        }
      }

      & label {
        flex: 6.1;
        @media (max-width: ${breakpoints.LARGE_PHONE}) {
            flex: 3;
        }
      }

      &-input {
        @media (max-width: ${breakpoints.LARGE_PHONE}) {
            flex: 2;
        }
        @media (max-width: ${breakpoints.PHONE}) {
          flex: 0 0 12rem;
        }
      }

      &-line-total {
        color: ${(props) => props.theme.COLOURS.BLACK};
        padding-top: 0.8rem;

        ${SubHeaderText} {
          color: ${(props) => props.theme.COLOURS.BLACK};
          font-size: ${fonts.sizes[60]};
        }

        @media (max-width: ${breakpoints.LARGE_DESKTOP}) {
          padding-top: 1.25rem;
        }
        @media (max-width: ${breakpoints.LARGE_PHONE}) {
          flex: 2;
          padding-left: 2.5rem;
          padding-top: 0;
        }
        @media (max-width: ${breakpoints.PHONE}) {
          flex: 0 0 13.5rem;
        }

        ${(props) =>
          props.disabled &&
          css`
            @media (min-width: ${breakpoints.SMALL_DESKTOP}) {
              & h3 {
                text-align: end;
              }
            }
          `}
      }
    }

    &__controls {
      display: flex;
      flex: 0.3;

      &-group {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 4.9rem;
        width: 15rem;

        @media (max-width: ${breakpoints.LARGE_DESKTOP}) {
            width: 6rem;
         }

         @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
            padding-bottom: 7rem;
         }

         @media (max-width: ${breakpoints.PHONE}) {
            width: ${spacing.LARGE};
        }
      }

      &_remove-line {
        padding: 0 1.75rem;

        @media (max-width: ${breakpoints.LARGE_PHONE}) {
           margin-bottom: 22rem;
        }
      }

      &_add-line {
        @media (max-width: ${breakpoints.LARGE_DESKTOP}) {
            display: flex;
            justify-content: end;
         }

        ${Button} {
            display: flex;
            padding: 1rem;
            height: 4.9rem;
            align-items: center;
            margin: auto;
        }
      }
    }
  
    &__error-message {
       &-container {

        @media (min-width: ${breakpoints.LARGE_PHONE}) and (max-width: ${breakpoints.SMALL_DESKTOP}) {
          display: flex;
          justify-content: end;
         }
       }
       
       ${InputError} {
        @media (max-width: ${breakpoints.PHONE}) {
          font-size: 1.5rem;
          line-height: 1.5rem;
         }
       }

       @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
        flex: 0 0 47.3rem;
     }
    }
  }
`;

export const StyledResolutionTotalCost = styled(ResolutionTotalCost)`
   .total-cost {
       &__container {
           display: flex;
           justify-content: end;
       }
       &__label {
        display: flex;
        align-items: center;
        margin-right: ${spacing.MEDIUM};
       }

       &__input {
         width: 13rem;
       }
     }
`;

export default StyledResolution;
