import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import clientSettings from "../../../clientappsettings";
import ModalInsert from "../../components/ModalInsert";
import InvoiceDownload from "./../../components/InvoiceDownload";

const InvoiceDownloadModalInsert = () => {
  const dispatch = useDispatch();

  const invoiceId = useSelector(state => state.invoices.downloadInvoiceId);
  const auth = useSelector(state => state.authentication.credentials);

  const reportURL = `${clientSettings.IMAGES_API_URL}/invoice/breakdown/${invoiceId}?jwtToken=${auth}`;
  const invoiceURL = `${clientSettings.IMAGES_API_URL}/invoice/${invoiceId}?jwtToken=${auth}`;

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <ModalInsert layout="flex" width="55rem">
      <InvoiceDownload
        reportURL={reportURL}
        invoiceURL={invoiceURL}
        onClose={onClose}
      />
    </ModalInsert>
  );
};

export default InvoiceDownloadModalInsert;
