import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Icon from "../Icon";
import ParagraphText from "../ParagraphText";
import SkeletonPopoverSingleSelect from "../Skeletons/SkeletonPopoverSingleSelect";

const Dropdown = ({
  className,
  label,
  placeholder,
  options,
  selectedOption,
  defaultOption,
  onInputChange,
  isFetching,
  noResultsText,
  inFilter,
  disabled,
  isValid,
  hideNumberCount,
  allowOverflow
}) => {

  const [isOpen, setOpen] = useState(false);
  const toggleDropdown = () => {
    setOpen(!isOpen);
  };
  // TODO: X-Browser concern, check we are polyfilling this
  const selectedOptionData = options.find(
    (option) => option.value === selectedOption
  );

  const hideOverflow = allowOverflow ? "hidden" : "";
  const overflowMargin = allowOverflow ? "2rem" : "";

  const getTriggerLabel = () => {
    if (selectedOption && selectedOptionData) {
      return (
        <div style={{overflow: `${hideOverflow}`, marginRight: `${overflowMargin}`}}>
          <ParagraphText primary>{selectedOptionData.optionName}</ParagraphText>
        </div>
      );
    }
    if (defaultOption) {
      return (
      <div style={{overflow: `${hideOverflow}`, marginRight: `${overflowMargin}`}}>
        <ParagraphText primary>{defaultOption.optionName}</ParagraphText>;
      </div>
      )
    }
    return <span className="dropdown_placeholder">{placeholder}</span>;
  };

  const triggerLabel = getTriggerLabel();

  const dropdownWrapperRef = useRef(null); // used for closing the dropdown on click outside

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownWrapperRef.current &&
        !dropdownWrapperRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownWrapperRef]);

  return (
    <div className={classnames(className, "dropdown")}>
      {label && (
        <ParagraphText className="dropdown__label" primary>
          {label}
        </ParagraphText>
      )}
      <button
        className={
          "dropdown__trigger" + (isValid ? "" : " dropdown__trigger_error")
        }
        disabled={disabled}
        onClick={() => toggleDropdown()}
      >
        {triggerLabel}
        <Icon
          className={classnames("dropdown__trigger-icon", {
            open: isOpen,
          })}
          type="chevron-down"
          width="13"
          height="10"
          strokeWidth="2.5"
        />
      </button>
      {isOpen && (
        <div className="dropdown__wrapper" ref={dropdownWrapperRef}>
          <div className="arrow" />
          <div className="dropdown__container">
            {!isFetching ? (
              options && !!options.length ? (
                options.map((option) => (
                  <div
                    className={classnames("dropdown__option", {
                      selected: option.value === selectedOption,
                      disabled: (inFilter && !option.count) || option.expired,
                    })}
                    key={option.optionName}
                    onClick={() => {
                      onInputChange(option);
                      toggleDropdown();
                    }}
                  >
                    <span className="dropdown__option-name">
                      {option.optionName}
                    </span>
                    {inFilter && hideNumberCount && (
                      <span className="dropdown__option-count">{`(${option.count})`}</span>
                    )}
                  </div>
                ))
              ) : (
                <ParagraphText>{noResultsText}</ParagraphText>
              )
            ) : (
              <SkeletonPopoverSingleSelect />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultOption: PropTypes.object,
  onInputChange: PropTypes.func,
  isFetching: PropTypes.bool,
  noResultsText: PropTypes.string,
  inFilter: PropTypes.bool,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
};

Dropdown.defaultProps = {
  options: [],
  onInputChange: () => {},
  noResultsText: "There are no options for your selected filter.",
  inFilter: false,
  disabled: false,
  isValid: true,
};

export default Dropdown;
