import React, { Fragment, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../components/ModalInsert";
import Panel from "../../components/Panel";
import { VendorAddNoteReasonContext } from "../../components/VendorAddNote/context/context";
import VendorAddNote from "../../components/VendorAddNote";
import { vendorAddNote } from "../../../state/reducers/claimDetailsVendor";

const VendorAddNoteModalInsert = () => {
  const dispatch = useDispatch();
  const activeClaimId = useSelector(
    (state) => state.global.claims.activeClaimId
  );

  const { vendorAddNoteMessage } = useContext(
    VendorAddNoteReasonContext
  );

  const [disabledButton, setDisabledButton] = useState(false)

  const claimData = {
    Notes: vendorAddNoteMessage,
    ClaimId: activeClaimId,
  };

  const disabledSubmit = vendorAddNoteMessage.length === 0; 

  const onCancel = () => {
    dispatch(closeModal());
  };

  const onClickSubmitVendorAddNote = () => {
   setDisabledButton(true);
   dispatch(vendorAddNote(claimData));
  };

  return (
    <Fragment>
      <ModalInsert>
          <Panel
            mobileFlex
            style={{ height: "100%", borderRadius: "0.5rem" }}
            actions={[
              {
                content: "Close",
                action: onCancel,
                type: "ghost",
              },
              {
                content: "Submit",
                action: onClickSubmitVendorAddNote,
                type: "primary",
                disabled: disabledSubmit || disabledButton,
              },
            ]}
          >
            <VendorAddNote />
          </Panel>
      </ModalInsert>
    </Fragment>
  );
};

export default VendorAddNoteModalInsert;
