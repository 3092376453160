import React from "react";
import PropTypes from "prop-types";
import COLOURS from "../../../theme/base/colours";

const Calendar = ({ width, height, strokeColour, fillColour }) => {
  const stroke = strokeColour ? strokeColour : COLOURS.WHITE;
  const fill = fillColour ? fillColour : COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <g fill="none" fillRule="evenodd">
        <rect fill={fill} x="0" y="0" width="50" height="50" rx="5"></rect>
        <g
          transform="translate(11.000000, 11.000000)"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <line
            x1="31.3333333"
            y1="31.3308"
            x2="26.5626667"
            y2="26.5601333"
          ></line>
          <path d="M22.6666667,28 C25.98,28 28.6666667,25.3133333 28.6666667,22 C28.6666667,18.6866667 25.98,16 22.6666667,16 C19.3533333,16 16.6666667,18.6866667 16.6666667,22 C16.6666667,25.3133333 19.3533333,28 22.6666667,28 Z"></path>
          <path d="M12.6666667,24.6666667 L2,24.6666667 C1.264,24.6666667 0.666666667,24.0693333 0.666666667,23.3333333 L0.666666667,4.66666667 C0.666666667,3.93066667 1.264,3.33333333 2,3.33333333 L26,3.33333333 C26.736,3.33333333 27.3333333,3.93066667 27.3333333,4.66666667 L27.3333333,13.3333333"></path>
          <line
            x1="7.33333333"
            y1="0.666666667"
            x2="7.33333333"
            y2="7.33333333"
          ></line>
          <line
            x1="20.6666667"
            y1="0.666666667"
            x2="20.6666667"
            y2="7.33333333"
          ></line>
          <line x1="0.666666667" y1="10" x2="27.3333333" y2="10"></line>
        </g>
      </g>
    </svg>
  );
};

Calendar.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

Calendar.defaultProps = {
  width: "50",
  height: "50",
  strokeWidth: "2"
};

export default Calendar;
