import styled from "styled-components";
import IframeCam from "./Iframe";

const StyledIframeCam = styled(IframeCam)`
  .modal-360 {
    &__body {
      align-items: center;
      background-color: ${props => props.theme.COLOURS.BLACK};
      display: flex;
      height: calc(100vh - 7.5rem);
      justify-content: center;
      overflow: hidden;
      position: relative;
    }
    &__iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &__footer {
      border-top: 0.1rem solid
        ${props => props.theme.COLOURS.PRIMARY.shades[40]};
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: ${props => props.theme.SPACING.REGULAR};
      &-label {
        display: flex;
        span {
          display: flex;
          align-items: center;
          margin-right: 0.5rem;
        }
        p {
          font-family: ${props => props.theme.FONTS.families.lato};
          font-size: ${props => props.theme.FONTS.sizes[40]};
          font-weight: ${props => props.theme.FONTS.weights[70]};
          line-height: ${props => props.theme.FONTS.lineHeights[20]};
          letter-spacing: 0.075rem;
          color: ${props => props.theme.COLOURS.PRIMARY.base};
        }
      }
    }
  }
`;

export default StyledIframeCam;
