import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import HeaderText from "../../HeaderText";
import ParagraphText from "../../ParagraphText";
import {
  declineReasonLabel,
  errorMessages,
  dropdownPlaceholder,
} from "./consts";
import TextArea from "../../TextArea";
import BidInputError from "../../BidInput/child-components/BidInputError";
import {
  DeclineClaimReasonContext,
  DeclineClaimReasonValidationContext,
} from "../VendorDeclineClaimMessage/context/context";
import Dropdown from "../../Dropdown";

const VendorDeclineClaimMessage = ({
  onCategoryChange,
  dropdownOptions,
  declineReasonType,
}) => {
  const { declineClaimReason, setDeclineClaimReason } = useContext(
    DeclineClaimReasonContext
  );
  const {
    validClaimDeclineReason,
    setValidClaimDeclineReason,
    hasFocus,
    setHasFocus,
  } = useContext(DeclineClaimReasonValidationContext);

  function onChangeInput(e) {
    const { value } = e.target;
    setDeclineClaimReason(value);
    if (value.length >= 10 && value.length <= 2000) {
      setValidClaimDeclineReason(true);
    } else {
      setValidClaimDeclineReason(false);
    }
  }

  useEffect(() => {
    validClaimDeclineReason && setValidClaimDeclineReason(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [declineClaimReason]);

  return (
    <div style={{ width: "90rem" }}>
      <HeaderText>{declineReasonLabel.HEADER_TEXT}</HeaderText>
      <ParagraphText>{declineReasonLabel.DROPDOWN_HEADER}</ParagraphText>
      <div style={{ width: "40%", marginBottom: "1.2rem" }}>
        <Dropdown
          key={dropdownOptions}
          options={dropdownOptions || []}
          selectedOption={declineReasonType}
          placeholder={dropdownPlaceholder.PLACEHOLDER}
          onInputChange={(e) => onCategoryChange(e.value)}
          required
        />
      </div>
      <ParagraphText>{declineReasonLabel.SUB_HEADER_TEXT}</ParagraphText>
      <TextArea
        value={declineClaimReason}
        className="text-area__input"
        onChange={onChangeInput}
        validValue={validClaimDeclineReason}
        resize="none"
        maximumCharactersBottom="2000"
        rows="7"
        cols="50"
        onFocus={(e) => {
          e.target.select();
          onChangeInput(e);
          setHasFocus(true);
        }}
        onBlur={(e) => {
          setHasFocus(false);
        }}
      ></TextArea>
      {!validClaimDeclineReason &&
        !hasFocus &&
        (declineClaimReason.length <= 0 ? (
          <BidInputError errorMessage={errorMessages.FIELD_REQUIRED} />
        ) : (
          <BidInputError errorMessage={errorMessages.MIN_LENGTH} />
        ))}
    </div>
  );
};

VendorDeclineClaimMessage.propTypes = {
  setNotesToBuyer: PropTypes.func,
};

export default VendorDeclineClaimMessage;
