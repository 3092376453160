import Search from "./Search";
import { formatFilterOptions } from "../shared/filter";

const getSearchRequestDataObject = (selectedFilterOptions = {}) => {
  const { pageSize = 25, pageNumber = 1 } = selectedFilterOptions;
  return {
    pageSize,
    pageNumber
  };
};

class BidsAndRequestsApi extends Search {
  requestUrl = "/BidsAndRequests";

  formatRequestData(params) {
    if (params) {
      const { filters, ...rest } = params;
      const requestData = getSearchRequestDataObject(rest);
      return {
        ...requestData,
        ...formatFilterOptions({
          filterObject: filters,
          pageType: "bidsAndRequests"
        })
      };
    }
    return {};
  }
}

export default BidsAndRequestsApi;
