import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./state/store";
import "./views/theme/fonts.css";
import StylesReset from "./stylesReset";
import App from "./views/containers/App";
import {
  setViewportHeight,
  getMobileOperatingSystem,
  viewHeightReset,
} from "./shared/utility";

import { registerServiceWorker } from "./shared/utility";

const mobileOperatingSystem = getMobileOperatingSystem();
viewHeightReset();
setViewportHeight();
registerServiceWorker();

document.body.classList.add(mobileOperatingSystem);

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <StylesReset />
      <App />
    </PersistGate>
  </Provider>
);
