import React, { Fragment, useContext, useRef } from "react";
import classnames from "classnames";
import Button from "../../Button";
import SkeletonPopoverMultiSelect from "../../Skeletons/SkeletonPopoverMultiSelect";
import SkeletonPopoverSingleSelect from "../../Skeletons/SkeletonPopoverSingleSelect";
import { PopoverFilterContext } from "../../../../shared/context";
import useClickOutside from "../../../../shared/hooks/useClickOutside";

const Panel = ({ children }) => {
  const {
    isSearching,
    isFetching,
    type,
    isMultiSelect = true,
    onDropdownSelect
  } = useContext(PopoverFilterContext);
  const filterRef = useRef(null);

  useClickOutside(filterRef, () => {
        onDropdownSelect();
      });
    
  return isMultiSelect ? (
    <Fragment>
      <div className="arrow" />
      <div
        className={classnames("filter__container", {
          loading: isFetching || isSearching
        })}
        ref={filterRef}
      >
        {!isFetching && (
          <Fragment>
            <div className="filter__container__panel">{children}</div>
            <Button
              className="filter__container__close-btn"
              content="Close"
              action={() => {
                onDropdownSelect();
              }}
              ghost
            />
          </Fragment>
        )}
        {isFetching && <SkeletonPopoverMultiSelect />}
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <div className="arrow" />

      <div
        className={classnames("filter__container", type, {
          loading: isFetching || isSearching
        })}
        ref={filterRef}
      >
        {!isFetching && children}
        {isFetching && <SkeletonPopoverSingleSelect />}
      </div>
    </Fragment>
  );
};

export default Panel;
