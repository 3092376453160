import clientSettings from "../clientappsettings.json";
import Api from "./Base";

class PasswordChangeApi extends Api {
  baseUrl = clientSettings.USERS_URL;
  requestUrl = "/user/changePassword";
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };
}

export default PasswordChangeApi;
