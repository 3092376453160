import { updateObject } from "../../shared/utility";
import SearchApi from "../../api/Search";
import { clearBidData } from "./bid";
import { addShortlistFromPageLoad } from "./shortlist";
import { FavouriteRecentSearch, RecentSearches } from "../../api/RecentSearches";
export const SEARCH_REQUEST = "@search/SEARCH_REQUEST";
export const SEARCH_REQUEST_SUCCESS = "@search/SEARCH_REQUEST_SUCCESS";
export const SEARCH_REQUEST_FAILURE = "@search/SEARCH_REQUEST_FAILURE";
export const CLEAR_SEARCH = "@search/CLEAR_SEARCH";
export const FETCH_RECENT_SEARCHES = "@search/FETCH_RECENT_SEARCHES";
export const FETCH_RECENT_SEARCHES_SUCCESS = "@search/FETCH_RECENT_SEARCHES_SUCCESS";
export const FETCH_RECENT_SEARCHES_FAILURE = "@search/FETCH_RECENT_SEARCHES_FAILURE";
export const UPDATE_FAVOURITE_RECENT_SEARCH = "@search/UPDATE_FAVOURITE_RECENT_SEARCH";
export const UPDATE_FAVOURITE_RECENT_SEARCH_SUCCESS = "@search/UPDATE_FAVOURITE_RECENT_SEARCH_SUCCESS";
export const UPDATE_FAVOURITE_RECENT_SEARCH_FAILURE = "@search/UPDATE_FAVOURITE_RECENT_SEARCH_FAILURE";

export const searchRequestSuccess = (data) => {
  return {
    type: SEARCH_REQUEST_SUCCESS,
    payload: data,
  };
};

export const searchRequestFailure = (filtersNotAvailable = false) => {
  return {
    type: SEARCH_REQUEST_FAILURE,
    payload: {filtersNotAvailable: filtersNotAvailable}
  };
};

/**
 * @returns {Function}
 */

export const makeSearchRequest = () => (dispatch, getState) => {
  dispatch({ type: SEARCH_REQUEST });

  const bidState = getState().bid.bids.byVehicleId;
  if (Object.keys(bidState).length) {
    dispatch(clearBidData());
  }

  const filters = getState().filter.filters;
  const toolbar = getState().toolbar;
  const pageNumber = getState().pagination.pageNumber;

  const { pageSize, sort, bucketType } = toolbar;

  const params = {
    filters,
    pageSize,
    sort,
    purchaseSession: bucketType,
    pageNumber,
  };
  const searchInstance = new SearchApi({
    params,
    credentials: getState().authentication.credentials,
  });

  searchInstance.call().then(
    (response) => {
      const {
        results: { shortlistedVehicleIds },
      } = response;
      if (shortlistedVehicleIds) {
        dispatch(addShortlistFromPageLoad(shortlistedVehicleIds));
      }
      dispatch(searchRequestSuccess(response));
    },
    (err) => {
      return dispatch(searchRequestFailure());
    }
  );
};

const fetchRecentSearchesSuccess = (response) => {
  return {
    type: FETCH_RECENT_SEARCHES_SUCCESS,
    payload: response,
  };
};

const fetchRecentSearchesFailure = (error) => {
  return {
    type: FETCH_RECENT_SEARCHES_FAILURE,
    payload: error,
  };
};

export const fetchRecentSearches = () => (dispatch, getState) => {
  dispatch({ type: FETCH_RECENT_SEARCHES });

  const detailsInstance = new RecentSearches({
    credentials: getState().authentication.credentials,
  });
  return detailsInstance.call().then(
    (response) => {
      return dispatch(fetchRecentSearchesSuccess(response));
    },
    (err) => {
      return dispatch(fetchRecentSearchesFailure(err));
    }
  );
};

const updateFavouriteRecentSearchSuccess = (hashId, isFavourite, FavouriteName) => {
  return {
    type: UPDATE_FAVOURITE_RECENT_SEARCH_SUCCESS,
    payload: {hashId, isFavourite, FavouriteName},
  };
};

const updateFavouriteRecentSearchFailure = (error) => {
  return {
    type: UPDATE_FAVOURITE_RECENT_SEARCH_FAILURE,
    payload: error,
  };
};

export const updateRecentSearchFavourite = (hashId, isFavourite, FavouriteName) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FAVOURITE_RECENT_SEARCH });

  const params = {
    hashId,
    isFavourite,
    FavouriteName,
  };

  const detailsInstance = new FavouriteRecentSearch({
    credentials: getState().authentication.credentials,
    params,
  });
  
  return detailsInstance.call().then(
    (response) => {
      return dispatch(updateFavouriteRecentSearchSuccess(hashId,isFavourite, FavouriteName ));
    },
    (err) => {
      return dispatch(updateFavouriteRecentSearchFailure(err));
    }
  );
};

export const clearSearch = () => {
  return {
    type: CLEAR_SEARCH,
  };
};

const initialState = {
  isFetching: false,
  isFetchingRecentSearches: false,
  isUpdatingFavouriteRecentSearch: false,
  error: false,
  recentSearchesResults: null,
  results: {
    byId: {},
    vehicleIds: [],
  },
  filtersNotAvailable: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_REQUEST:
      return updateObject(state, { isFetching: true });
    case SEARCH_REQUEST_SUCCESS:
      return updateObject(state, {
        isFetching: false,
        totalCount: action.payload.totalCount,
        pageSize: action.payload.pageSize,
        pageCount: action.payload.pageCount,
        results: action.payload.results,
        filtersNotAvailable: false,
      });
    case SEARCH_REQUEST_FAILURE:
      return updateObject(state, {
        isFetching: false,
        // TODO: we may not want to clear the results...
        results: {
          byId: {},
          vehicleIds: [],
        },
        error: true,
        filtersNotAvailable: action.payload.filtersNotAvailable,
      });
    case CLEAR_SEARCH:
      return {
        ...initialState,
      };
    case FETCH_RECENT_SEARCHES:
      return {
        ...state,
        isFetchingRecentSearches: true,
      }
      case FETCH_RECENT_SEARCHES_FAILURE: 
      return {
        ...state, 
        isFetchingRecentSearches: false,
        recentSearchesResults: null,
        error: action.payload,
      }
      case FETCH_RECENT_SEARCHES_SUCCESS: 
      return {
        ...state, 
        isFetchingRecentSearches: false,
        recentSearchesResults: action.payload?.data,
      }
      case UPDATE_FAVOURITE_RECENT_SEARCH:
        return {
          ...state,
          isUpdatingFavouriteRecentSearch: true,
        }
        case UPDATE_FAVOURITE_RECENT_SEARCH_FAILURE: 
        return {
          ...state, 
          isUpdatingFavouriteRecentSearch: false,
          error: action.payload,
        }
        case UPDATE_FAVOURITE_RECENT_SEARCH_SUCCESS: 
        return {
          ...state, 
          isUpdatingFavouriteRecentSearch: false,
          recentSearchesResults: state.recentSearchesResults?.map((recentSearch) =>
            recentSearch.hashId === action.payload.hashId
              ? {
                  ...recentSearch,
                  isFavourite:action.payload.isFavourite,
                }
              : recentSearch
          ),
        }        
    default:
      return state;
  }
};

export default reducer;
