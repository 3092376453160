import React, { useReducer, useEffect, useContext, useState } from "react";
import InputText from "../../../InputText";
import InputLabel from "../../../InputLabel";
import SubHeaderText from "../../../SubHeaderText";
import Icon from "../../../Icon";
import Button from "../../../Button";
import { ClaimResolutionContext } from "./../../context/context";
import breakpoints from "../../../../theme/breakpoints";
import { useMediaQuery } from "react-responsive";
import {
  formatCurrency,
  formatNumericValue,
  convertToNumber,
  getNumberOfInvalidFields,
} from "../../utils";
import { resolutionHeaders, errorMessages } from "../../consts";
import classnames from "classnames";
import InputError from "./../../../BidInput/child-components/BidInputError";

const ResolutionCostBreakdown = ({ className, costLines, disabled }) => {
  const [focusedInput, setFocusedInput] = useState(false);
  const [focusedLabourCostInput, setFocusedLabourCostInput] = useState(false);
  const {
    setResolutionCostLinesCtx,
    setTotalCostCtx,
    setResolutionIsValidCtx,
    shouldCheckResolutionValidityCtx,
    setShouldCheckResolutionValidityCtx,
  } = useContext(ClaimResolutionContext);
  const isMaxLargeDesktop = useMediaQuery({
    query: `(max-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  const isMaxSmallDesktop = useMediaQuery({
    query: `(max-width: ${breakpoints.SMALL_DESKTOP})`,
  });

  const initialState = [
    {
      costLineText: "",
      labourCost: {
        value: 0,
        hasFocus: false,
      },
      partsCost: {
        value: 0,
        hasFocus: false,
      },
      lineTotalCost: 0,
    },
  ];

  const costLinesCopy = [...costLines];
  const extendedCostLines = costLinesCopy.map((line) => {
    return {
      ...line,
      partsCost: {
        value: line.partsCost,
        hasFocus: false,
      },
      labourCost: {
        value: line.labourCost,
        hasFocus: false,
      },
    };
  });

  const reducer = (state, action) => {
    switch (action.type) {
      case "add":
        return [...state, initialState[0]];
      case "remove":
        const updatedState = [...state];
        if (updatedState.length > 1) {
          updatedState.splice(action.payload.index, 1);
        }
        return updatedState;
      case "update":
        const copiedState = [...state];
        copiedState[action.payload.index][action.payload.name] =
          action.payload.value;
        copiedState[action.payload.index]["lineTotalCost"] =
          convertToNumber(
            copiedState[action.payload.index]["labourCost"]["value"]
          ) +
          convertToNumber(
            copiedState[action.payload.index]["partsCost"]["value"]
          );
        return copiedState;
      case "remove-focus":
        const newState = [
          ...state.map((line) => {
            return {
              ...line,
              partsCost: {
                ...line.partsCost,
                hasFocus: false,
              },
              labourCost: {
                ...line.labourCost,
                hasFocus: false,
              },
            };
          }),
        ];
        return newState;
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(
    reducer,
    costLines.length > 0 ? extendedCostLines : initialState
  );

  const addLine = () => {
    dispatch({ type: "add" });
  };

  const updateLine = (index, e) => {
    const { name, value } = e?.target;

    if (name === "costLineText") {
      dispatch({
        type: "update",
        payload: { index, name, value },
      });
    } else {
      dispatch({
        type: "update",
        payload: {
          index,
          name,
          value: {
            value: formatNumericValue(value),
            hasFocus: true,
          },
        },
      });
    }
  };

  useEffect(() => {
    const focusedPartsCostInput = focusedInput;
    !focusedPartsCostInput &&
      !focusedLabourCostInput &&
      dispatch({ type: "remove-focus" });
  }, [focusedInput, focusedLabourCostInput]);

  const removeLine = (index) => {
    dispatch({ type: "remove", payload: { index } });
  };

  const linesTotal = state
    .map((line) => line.lineTotalCost)
    .reduce((acc, val) => acc + val);

  const checkValitity = (value) => {
    if (!focusedInput && shouldCheckResolutionValidityCtx) {
      return !!value;
    }
    return true;
  };

  const numberOfInvalidFields = getNumberOfInvalidFields(state);

  useEffect(() => {
    setResolutionIsValidCtx(numberOfInvalidFields === 0 ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfInvalidFields]);

  useEffect(() => {
    if (shouldCheckResolutionValidityCtx && focusedInput) {
      setShouldCheckResolutionValidityCtx(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldCheckResolutionValidityCtx, focusedInput]);

  useEffect(() => {
    const stateCopy = [...state];
    const formattedState = stateCopy.map((line) => {
      return {
        ...line,
        labourCost: line.labourCost.value,
        partsCost: line.partsCost.value,
      };
    });

    setResolutionCostLinesCtx(formattedState);
    setTotalCostCtx(linesTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const addLineButton = (index) => (
    <div className="cost-breakdown__controls_add-line">
      {state.length === index + 1 && (
        <Button secondary content="Add Line" action={() => addLine()}></Button>
      )}
    </div>
  );

  return (
    <div className={classnames(className, "cost-breakdown__page-container")}>
      <div className="cost-breakdown__lines-container">
        {state.map((item, index) => (
          <>
            <div
              className={classnames(
                "cost-breakdown__line-container",
                disabled && "cost-breakdown__line-container-disabled"
              )}
              key={index}
            >
              <div
                className={classnames(
                  "cost-breakdown__description-container",
                  disabled && "cost-breakdown__description-container-disabled"
                )}
              >
                {(index === 0 || isMaxSmallDesktop) && (
                  <InputLabel>{resolutionHeaders.COST_DESCRIPTION}</InputLabel>
                )}
                <div className="cost-breakdown__description-input">
                  <InputText
                    medium
                    name="costLineText"
                    maxLength="50"
                    value={item.costLineText}
                    onChange={(e) => updateLine(index, e)}
                    validValue={checkValitity(item.costLineText)}
                    onFocus={() => setFocusedInput(true)}
                    onBlur={() => setFocusedInput(false)}
                    autoComplete="off"
                    disabled={disabled}
                  />
                </div>
              </div>

              <div className="cost-breakdown__cost-group">
                <div className="cost-breakdown__cost-container">
                  {(index === 0 || isMaxSmallDesktop) && (
                    <InputLabel small>
                      {resolutionHeaders.LABOUR_COST}
                    </InputLabel>
                  )}
                  <div className="cost-breakdown__cost-input">
                    <InputText
                      medium
                      onChange={(e) => updateLine(index, e)}
                      name="labourCost"
                      value={
                        item.labourCost.hasFocus
                          ? item.labourCost.value
                          : formatCurrency(
                              parseFloat(item.labourCost.value).toFixed(2)
                            )
                      }
                      onFocus={() => setFocusedLabourCostInput(true)}
                      onBlur={() => setFocusedLabourCostInput(false)}
                      validValue={true}
                      autoComplete="off"
                      disabled={disabled}
                    />
                  </div>
                </div>

                <div className="cost-breakdown__cost-container">
                  {(index === 0 || isMaxSmallDesktop) && (
                    <InputLabel small>
                      {resolutionHeaders.PARTS_COST}
                    </InputLabel>
                  )}
                  <div className="cost-breakdown__cost-input">
                    <InputText
                      medium
                      name="partsCost"
                      onChange={(e) => updateLine(index, e)}
                      value={
                        item.partsCost.hasFocus
                          ? item.partsCost.value
                          : formatCurrency(
                              parseFloat(item.partsCost.value).toFixed(2)
                            )
                      }
                      validValue={checkValitity(item.partsCost.value)}
                      onFocus={() => setFocusedInput(true)}
                      onBlur={() => setFocusedInput(false)}
                      autoComplete="off"
                      disabled={disabled}
                    />
                  </div>
                </div>

                <div className="cost-breakdown__cost-container">
                  {(index === 0 || isMaxSmallDesktop) && (
                    <InputLabel>{resolutionHeaders.LINE_TOTAL}</InputLabel>
                  )}
                  <div className={"cost-breakdown__cost-line-total"}>
                    <SubHeaderText medium>
                      {formatCurrency(
                        parseFloat(item.lineTotalCost).toFixed(2)
                      )}
                    </SubHeaderText>
                  </div>
                </div>
              </div>

              {!disabled && (
                <div className="cost-breakdown__controls-group">
                  <div
                    className="cost-breakdown__controls_remove-line"
                    onClick={() => removeLine(index)}
                  >
                    {state.length >= 2 && <Icon type="trash" />}
                  </div>
                  {!isMaxLargeDesktop && addLineButton(index)}
                </div>
              )}
            </div>
            {!disabled && isMaxLargeDesktop && addLineButton(index)}
          </>
        ))}
        <div className={"cost-breakdown__error-message-container"}>
          <div className={"cost-breakdown__error-message"}>
            {shouldCheckResolutionValidityCtx &&
              !focusedInput &&
              numberOfInvalidFields > 0 && (
                <InputError
                  errorMessage={
                    errorMessages.RESOLUTION_REQUIRED_FIELD +
                    `${numberOfInvalidFields > 1 ? "s" : ""}`
                  }
                />
              )}
          </div>
        </div>
      </div>

      <div className="cost-breakdown__total-cost_container">
        <br />
        <div
          className={classnames(
            "cost-breakdown__total-cost_label",
            disabled && "cost-breakdown__total-cost_label-disabled"
          )}
        >
          <SubHeaderText small>
            {resolutionHeaders.TOTAL_NET_COST}
          </SubHeaderText>
        </div>
        <div
          className={classnames(
            "cost-breakdown__total-cost_value",
            disabled && "cost-breakdown__total-cost_value-disabled"
          )}
        >
          <SubHeaderText>
            {" "}
            {formatCurrency(parseFloat(linesTotal).toFixed(2))}
          </SubHeaderText>
        </div>
      </div>
    </div>
  );
};

export default ResolutionCostBreakdown;
