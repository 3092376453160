import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import classnames from "classnames";

const Chevron = props => {
  const { isOpen } = props;
  const theme = useContext(ThemeContext);
  return (
    <span
      className={classnames("filter__trigger-icon", {
        "is-open": isOpen
      })}
    >
      <svg viewBox="0 0 21 40" width="6px" height="10px">
        <path
          fill={theme.COLOURS.PRIMARY.base}
          d="M3.953 38.855l16.03-17.523c.346-.38.517-.854.517-1.332 0-.478-.17-.953-.516-1.332L3.954 1.145a1.995 1.995 0 0 0-2.807-.13 1.977 1.977 0 0 0-.131 2.795L15.827 20 1.016 36.19a1.977 1.977 0 0 0 .13 2.795c.81.735 2.065.676 2.807-.13"
        />
      </svg>
    </span>
  );
};

export default Chevron;
