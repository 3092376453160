import React, { useState, useContext, useEffect } from "react";
import InputText from "../../InputText";
import Icon from "../../Icon";
import Button from "../../Button";
import { ClaimSupportingLinkContext } from "../../ClaimItem/context/context";
import ButtonLink from "../../ButtonLink";
import { SUPPORTINGLINK_REGEX } from "./../../../../shared/utility";
import InputError from "./../../BidInput/child-components/BidInputError";
import { errorMessages } from "../consts";

const SupportingDocumentLinks = ({ disabled, options }) => {
  const [supportingDocumentLink, setSupportingDocumentLink] = useState("");
  const {
    validSupportingLink,
    setValidSupportingLink,
    supportingLinkCtx,
    setSupportingLinkCtx,
  } = useContext(ClaimSupportingLinkContext);

  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {
    setSupportingLinkCtx((prevLinks) => [
      ...prevLinks,
      ...(options?.urls || []),
    ]);
  }, [options?.urls, setSupportingLinkCtx]);

  const alreadyHasDocOrLink =
    supportingLinkCtx?.length || options?.supportingDocuments?.length;

  const onChangeLink = (value) => {
    const passesRegex = value && SUPPORTINGLINK_REGEX.test(value);
    setSupportingDocumentLink(value);
    if (passesRegex || (alreadyHasDocOrLink && !value)) {
      setValidSupportingLink(true);
    } else {
      setValidSupportingLink(false);
    }
  };

  const onAddLine = () => {
    setSupportingLinkCtx((prevLinks) => [...prevLinks, supportingDocumentLink]);
    setSupportingDocumentLink("");
  };

  const deleteSupportingLink = (link) => {
    setSupportingLinkCtx((prevLinks) =>
      prevLinks.filter((item) => item !== link)
    );
  };

  return (
    <div className="document-links-container">
      {!disabled && (
        <div className="document-links-lines-container">
          <InputText
            className="document-link-text-box"
            medium
            name="supportingLink"
            maxLength="500"
            value={supportingDocumentLink}
            onChange={(e) => onChangeLink(e.target.value)}
            validValue={validSupportingLink}
            onBlur={(e) => {
              onChangeLink(e.target.value);
              setHasFocus(false);
            }}
            onFocus={(e) => {
              e.target.select();
              setHasFocus(true);
            }}
          />

          <div className="document-link-controls-group">
            <Button
              secondary
              content={"Add Link"}
              action={() => onAddLine()}
              disabled={
                supportingDocumentLink.length === 0 ||
                validSupportingLink === false
              }
            />
          </div>
        </div>
      )}
      {!validSupportingLink &&
        !hasFocus &&
        supportingDocumentLink?.length === 0 &&
        supportingLinkCtx?.length === 0 && (
          <InputError errorMessage={errorMessages.LINK_MANDATORY} />
        )}
      {!validSupportingLink &&
        !hasFocus &&
        supportingDocumentLink?.length > 0 && (
          <InputError errorMessage={errorMessages.VALID_LINK_REQUIRED} />
        )}
      <div className="submittedLinks">
        {supportingLinkCtx?.length
          ? supportingLinkCtx.map((link, index) => (
              <div key={index} className="link-item">
                <ButtonLink
                  key={link}
                  listItem
                  primary
                  name={link}
                  action={() => {
                    window.open(link, "_blank");
                  }}
                />
                {!disabled && (
                  <div
                    className="trashcan-container"
                    onClick={() => deleteSupportingLink(link)}
                  >
                    <Icon type="trash" width="23" height="23" />
                  </div>
                )}
              </div>
            ))
          : ""}
      </div>
    </div>
  );
};

export default SupportingDocumentLinks;
