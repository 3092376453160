import React, { useState, useContext, useEffect, memo } from "react";
import {
  ItemContainer,
  ItemLabel,
  ItemContent,
  ParagraphTextContainer,
} from "../index";
import { subHeaderText, errorMessages } from "../consts";
import ParagraphText from "../../ParagraphText";
import SubHeaderText from "../../SubHeaderText";
import TextArea from "../../TextArea";
import InputError from "./../../BidInput/child-components/BidInputError";
import {
  ClaimAdditionalInformationContext,
  ClaimValidationContext,
} from "./../context/context";
import { useMediaQuery } from "react-responsive";
import breakpoints from "./../../../theme/breakpoints";

const AdditionalInformation = ({ options, disabled }) => {
  const {
    setClaimAdditionalInformation: setAdditionalInfoCtx,
    setValidAdditionalInformation,
  } = useContext(ClaimAdditionalInformationContext);
  const { hasFocus, setHasFocus } = useContext(ClaimValidationContext);
  const [additionalInformation, setAdditionalInformation] = useState(
    options?.questionAnswers || []
  );
  const [
    additionalInformationExtended,
    setAdditionalInformationExtended,
  ] = useState([]);

  useEffect(() => {
    const copiedAdditonalInformation = [...additionalInformation];
    const additionalInformationWithValidProp = copiedAdditonalInformation.map(
      (item) => {
        return {
          ...item,
          valid:
            !item.mandatory ||
            (item.answer?.length >= 2 && item.answer?.length <= 500),
          hadFocus: false,
        };
      }
    );
    setAdditionalInformationExtended(additionalInformationWithValidProp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.questionAnswers]);

  useEffect(() => {
    setAdditionalInfoCtx(additionalInformation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalInformation]);

  const additionalInfoValidityStatus = [
    ...additionalInformationExtended,
  ].reduce((sum, next) => sum && next.valid, true);

  const onQuestionsAnswersChange = (e) => {
    const { id, value } = e.target;
    const copiedAdditionalInformationExtended = [
      ...additionalInformationExtended,
    ];
    const updatedAdditionalInformation = copiedAdditionalInformationExtended.map(
      (item) => {
        if (item.claimQuestionId === Number(id)) {
          return { ...item, answer: value };
        }
        return item;
      }
    );
    setAdditionalInformationExtended(updatedAdditionalInformation);
    checkAdditionalInformationValidity(e);
    const additionalInfoCopy = [...additionalInformationExtended];
    const additionalInformation = additionalInfoCopy.map((qA) => {
      return {
        answer: qA.answer,
        claimQuestionId: qA.claimQuestionId,
        mandatory: qA.mandatory,
        question: qA.question,
      };
    });
    setAdditionalInformation(additionalInformation);
  };

  const checkAdditionalInformationValidity = (e) => {
    const { value, id } = e.target;

    if (value.length >= 2 && value.length <= 500) {
      setAdditionalInformationExtended((prevState) =>
        [...prevState].map((item) =>
          item.claimQuestionId === Number(id)
            ? { ...item, valid: true, hadFocus: true }
            : item
        )
      );
    } else {
      setAdditionalInformationExtended((prevState) =>
        [...prevState].map((item) =>
          item.claimQuestionId === Number(id) && item.mandatory
            ? { ...item, valid: false, hadFocus: true }
            : item
        )
      );
    }
  };

  useEffect(() => {
    setValidAdditionalInformation(additionalInfoValidityStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalInfoValidityStatus]);

  const isMobileOrTablet = useMediaQuery({
    query: `(max-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  return (
    <>
      {additionalInformationExtended.length > 0 && (
        <ItemContainer marginBottom={"-6.2rem"} marginTop={"3.5rem"}>
          <ItemLabel>
            <SubHeaderText>
              {subHeaderText.ADDITIONAL_INFORMATION}:
            </SubHeaderText>
          </ItemLabel>
        </ItemContainer>
      )}
      {additionalInformationExtended.length > 0 &&
        additionalInformationExtended.map((item, i) => (
          <ItemContainer key={i}>
            <ItemLabel display={"flex"} alignItems={"center"}>
              <ParagraphTextContainer>
                <ParagraphText className="info-text">
                  {item.question}
                </ParagraphText>
              </ParagraphTextContainer>
            </ItemLabel>
            <ItemContent>
              <TextArea
                value={item.answer || ""}
                name={"name"}
                id={item.claimQuestionId}
                onChange={(e) => onQuestionsAnswersChange(e)}
                validValue={item.valid || !item.hadFocus}
                resize={"none"}
                maxLength="500"
                rows="7"
                cols="50"
                onFocus={(e) => {
                  e.target.select();
                  setHasFocus(true);
                }}
                onBlur={(e) => {
                  onQuestionsAnswersChange(e);
                  setHasFocus(false);
                }}
                disabled={disabled}
              ></TextArea>
              {!item.valid &&
                item.hadFocus &&
                !hasFocus &&
                (item.answer?.length <= 0 ? (
                  <InputError
                    errorMessage={
                      !isMobileOrTablet
                        ? errorMessages.FIELD_REQUIRED
                        : errorMessages.MOBILE_FIELD_REQUIRED
                    }
                  />
                ) : (
                  <InputError errorMessage={errorMessages.MIN_LENGTH} />
                ))}
            </ItemContent>
          </ItemContainer>
        ))}
    </>
  );
};

export default memo(AdditionalInformation);
