export const errorMessages = {
    INVALID_FILE_FORMAT: "Invalid file format selected",
    FILE_TOO_LARGE: "File too large. Max file size of {0}MB",
    DUPLICATE_FILE: "This file has been used previously. File has been removed."
  };

export const showroomModeSettingsText = {
  COMPANY_LOGO_TEXT: "Company Logo:",
  COMPANY_LOGO_PARAGRAPH_TEXT: "Please provide your desired logo file which will be displayed in the Showroom window.",
  COLOUR_SCHEME_TEXT: "Colour Scheme:",
  COLOUR_SCHEME_PARAGRAPH_TEXT: "Please select the desired colour from the picker or provide the HEX/RGBA code.",
  PRIMARY_COLOUR_TEXT: "Primary Colour:",
  TEXT_COLOUR_TEXT: "Text Colour:"
};