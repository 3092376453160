import React, { useMemo, useEffect } from "react";
import { ItemContainer, ItemLabel, ItemContent } from "../index";
import { useDispatch, useSelector } from "react-redux";
import {
  headerText,
  paragraphText,
  subHeaderText,
  errorMessages,
  comparisonValues,
  addVehiclesTable,
} from "../const";
import HeaderText from "../../../../../views/components/HeaderText";
import ParagraphText from "../../../../../views/components/ParagraphText";

import SubHeaderText from "../../../../../views/components/SubHeaderText";
import BidInputError from "../../../../../views/components/BidInput/child-components/BidInputError";
import breakpoints from "../../../../../views/theme/breakpoints";
import { useMediaQuery } from "react-responsive";

import {
  fetchAuctionCondition,
  fetchAuctionGrade,
  saveSpecificVehicleSelection,
  clearSpecificVehicleSelection,
} from "../../../../../state/reducers/campaignManagement";

import InputText from "../../../../../views/components/InputText";
import Checkbox from "../../../../../views/components/Checkbox";
import SkeletonRows from "../../../../../views/components/Skeletons/SkeletonRows";
import Button from "../../../../../views/components/Button";
import Table from "../../../Table";
import Icon from "../../../../../views/components/Icon";
import colours from "../../../../../views/theme/base/colours";
import { openModal } from "../../../../../state/reducers/global";

const VehicleCriteria = ({
  vehicleCriteriaProps,
  campaignId,
  disableForm,
  setDisabledSaveButton,
  setApproveButtonDisabled,
}) => {
  const {
    fromMileage,
    setFromMileage,
    toMileage,
    setToMileage,
    fromVehicleAgeMonths,
    setFromVehicleAgeMonths,
    toVehicleAgeMonths,
    setToVehicleAgeMonths,
    validFromVehicleAgeMonths,
    setValidFromVehicleAgeMonths,
    validToVehicleAgeMonths,
    setValidToVehicleAgeMonths,
    validFromMileage,
    setValidFromMileage,
    validToMileage,
    setValidToMileage,
    campaignAuctionGrade,
    setCampaignAuctionGrade,
    campaignAuctionCondition,
    setCampaignAuctionCondition,
    campaignAuctionConditionsData,
    campaignAuctionGradesData,
    validAgeLength,
    setValidAgeLength,
    validAgeNumber,
    setValidAgeNumber,
    validMileageNumber,
    setValidMileageNumber,
    validMileageLength,
    setValidMileageLength,
    onClickOpenAddVehicles,
    makeFilterChoice,
    rangeFilterChoice,
    trimFilterChoice,
    setMakeFilterChoice,
    setRangeFilterChoice,
    setTrimFilterChoice,
  } = vehicleCriteriaProps;
  const dispatch = useDispatch();

  const { isFetchingAuctionGrades, isFetchingAuctionConditions } = useSelector(
    (state) => state.campaignManagement.activeCampaign
  );

  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  useEffect(() => {
    dispatch(fetchAuctionGrade(campaignId));
    dispatch(fetchAuctionCondition(campaignId));
  }, [campaignId, dispatch]);

  const onChangeFromVehicleAgeMonths = (e) => {
    const inputValue = e.target.value.trim();
    setFromVehicleAgeMonths(inputValue);

    if (inputValue === "") {
      setFromVehicleAgeMonths("");
      setValidFromVehicleAgeMonths(true);
      setValidToVehicleAgeMonths(true);
      setValidAgeLength(true);
      setValidAgeNumber(true);
      setDisabledSaveButton(false);
    } else {
      const numericValue = parseInt(inputValue, 10);

      if (
        !isNaN(numericValue) &&
        numericValue > parseInt(toVehicleAgeMonths, 10) &&
        toVehicleAgeMonths
      ) {
        setValidFromVehicleAgeMonths(false);
        setValidAgeNumber(false);
        setDisabledSaveButton(true);
      } else {
        setValidFromVehicleAgeMonths(true);
        setValidAgeNumber(true);
        setValidAgeLength(true);
        setValidToVehicleAgeMonths(true);
        setDisabledSaveButton(false);
      }
    }
    setApproveButtonDisabled(true);
  };

  const onChangeToVehicleAgeMonths = (e) => {
    const inputValue = e.target.value.trim();
    setToVehicleAgeMonths(inputValue);
    if (inputValue === "") {
      setToVehicleAgeMonths("");
      setValidFromVehicleAgeMonths(true);
      setValidToVehicleAgeMonths(true);
      setValidAgeLength(true);
      setValidAgeNumber(true);
      setDisabledSaveButton(false);
    } else {
      const numericValue = parseInt(inputValue, 10);

      if (
        !isNaN(numericValue) &&
        numericValue < parseInt(fromVehicleAgeMonths, 10) &&
        fromVehicleAgeMonths
      ) {
        setValidToVehicleAgeMonths(false);
        setValidAgeNumber(false);
        setDisabledSaveButton(true);
      } else {
        setValidToVehicleAgeMonths(true);
        setValidAgeLength(true);
        setValidAgeNumber(true);
        setValidFromVehicleAgeMonths(true);
        setDisabledSaveButton(false);
        setApproveButtonDisabled(true);
      }
    }
    setApproveButtonDisabled(true);
  };

  const onChangeFromMileage = (e) => {
    const inputValue = e.target.value.trim();
    setFromMileage(inputValue);

    if (inputValue === "") {
      setFromMileage("");
      setValidFromMileage(true);
      setValidToMileage(true);
      setValidMileageLength(true);
      setValidMileageNumber(true);
      setDisabledSaveButton(false);
    } else {
      const numericValue = parseInt(inputValue, 10);

      if (
        !isNaN(numericValue) &&
        numericValue > parseInt(toMileage, 10) &&
        toMileage
      ) {
        setValidFromMileage(false);
        setValidMileageNumber(false);
        setDisabledSaveButton(true);
      } else {
        setValidFromMileage(true);
        setValidMileageNumber(true);
        setValidMileageLength(true);
        setValidToMileage(true);
        setDisabledSaveButton(false);
      }
    }
    setApproveButtonDisabled(true);
  };

  const onChangeToMileage = (e) => {
    const inputValue = e.target.value.trim();
    setToMileage(inputValue);
    if (inputValue === "") {
      setToMileage("");
      setValidFromMileage(true);
      setValidToMileage(true);
      setValidMileageLength(true);
      setValidMileageNumber(true);
      setDisabledSaveButton(false);
    } else {
      const numericValue = parseInt(inputValue, 10);

      if (
        !isNaN(numericValue) &&
        numericValue < parseInt(fromMileage, 10) &&
        fromMileage
      ) {
        setValidToMileage(false);
        setValidMileageNumber(false);
        setDisabledSaveButton(true);
      } else {
        setValidToMileage(true);
        setValidMileageLength(true);
        setValidMileageNumber(true);
        setValidFromMileage(true);
        setDisabledSaveButton(false);
      }
    }
    setApproveButtonDisabled(true);
  };

  const onInputAuctionGradeChange = (option) => {
    setCampaignAuctionGrade((prevSelected) => {
      const existingIndex = prevSelected.findIndex(
        (item) => item.key === option.key
      );

      if (existingIndex !== -1) {
        return prevSelected.map((item, index) => {
          if (index === existingIndex) {
            return {
              ...item,
              value: !item.value,
            };
          }
          return item;
        });
      } else {
        return [
          ...prevSelected,
          {
            key: option.key,
            value: true,
          },
        ];
      }
    });
    setApproveButtonDisabled(true);
  };

  const onInputAuctionConditionChange = (option) => {
    setCampaignAuctionCondition((prevSelected) => {
      const existingIndex = prevSelected.findIndex(
        (item) => item.key === option.key
      );
      if (existingIndex !== -1) {
        return prevSelected.map((item, index) => {
          if (index === existingIndex) {
            return {
              ...item,
              value: !item.value,
            };
          }
          return item;
        });
      } else {
        return [
          ...prevSelected,
          {
            key: option.key,
            value: true,
          },
        ];
      }
    });
    setApproveButtonDisabled(true);
  };

  const fixedColumns = useMemo(
    () => [
      {
        Header: addVehiclesTable.MAKE,
        accessor: "vehicleMake",
      },
      {
        Header: addVehiclesTable.RANGE,
        accessor: "vehicleRange",
      },
      {
        Header: addVehiclesTable.TRIM,
        accessor: "vehicleTrim",
      },
      {
        Header: "",
        accessor: "viewCampaign",
        Cell: ({ cell: { value } }) => (
          <div
            className="view-note-container"
            onClick={() => onClickOpenAddVehiclesFromIcon(value)}
          >
            <Icon
              type="view-note"
              width={"2.3rem"}
              height={"2.3rem"}
              fill={`${colours.PRIMARY.shades[40]}`}
            />
          </div>
        ),
      },
      {
        Header: "",
        accessor: "trashCan",
        Cell: ({ cell: { value } }) => (
          <div className="trash-container" onClick={() => removeItem(value)}>
            <Icon type="trash" />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onClickOpenAddVehiclesFromIcon = ({
    index,
    makeFilterChoice,
    rangeFilterChoice,
    trimFilterChoice,
  }) => {
    const makeData = makeFilterChoice[index];
    const rangeData = rangeFilterChoice[index];
    const trimData = trimFilterChoice[index];

    const data = {
      index,
      makeData,
      rangeData,
      trimData,
    };

    dispatch(clearSpecificVehicleSelection());

    dispatch(saveSpecificVehicleSelection(data));
    dispatch(openModal("campaign-add-vehicles"));
    setApproveButtonDisabled(true);
  };

  const removeItem = ({
    index,
    makeFilterChoice,
    rangeFilterChoice,
    trimFilterChoice,
  }) => {
    const updatedMakeFilterChoice = [...makeFilterChoice];
    const updatedRangeFilterChoice = [...rangeFilterChoice];
    const updatedTrimFilterChoice = [...trimFilterChoice];

    updatedMakeFilterChoice.splice(index, 1);
    updatedRangeFilterChoice.splice(index, 1);
    updatedTrimFilterChoice.splice(index, 1);

    setMakeFilterChoice(updatedMakeFilterChoice);
    setRangeFilterChoice(updatedRangeFilterChoice);
    setTrimFilterChoice(updatedTrimFilterChoice);
    setApproveButtonDisabled(true);
  };

  const data = useMemo(() => {
    // Helper function to join values from nested arrays
    const joinNestedValues = (filterChoice) => {
      return filterChoice?.map((nestedArray) => {
        if (Array.isArray(nestedArray)) {
          return nestedArray.map((choice) => choice.value).join(", ");
        }
        return nestedArray;
      });
    };

    const combinedMakeValues = joinNestedValues(makeFilterChoice);
    const combinedRangeValues = joinNestedValues(rangeFilterChoice);
    const combinedTrimValues = joinNestedValues(trimFilterChoice);

    const wordsCount = (str) => {
      const words = str?.split(", ");
      return words?.length;
    };

    const mergedData = [];
    const limit = (isTabletOrMobile ? 10 : 20) + 3;

    const limitAndEllipsis = (text, maxLength) => {
      if (text.length > maxLength) {
        return text.substring(0, maxLength - 3) + "...";
      }
      return text;
    };

    for (
      let index = 0;
      index <
      Math.max(
        combinedMakeValues.length,
        combinedRangeValues.length,
        combinedTrimValues.length
      );
      index++
    ) {
      if (combinedMakeValues[index]) {
        const rowData = {
          vehicleMake: limitAndEllipsis(
            `${wordsCount(combinedMakeValues[index])} - ${
              combinedMakeValues[index] || "-"
            }`,
            limit
          ),
          vehicleRange: combinedRangeValues[index]
            ? limitAndEllipsis(
                `${wordsCount(combinedRangeValues[index])} - ${
                  combinedRangeValues[index] || "-"
                }`,
                limit
              )
            : "-",
          vehicleTrim: combinedTrimValues[index]
            ? limitAndEllipsis(
                `${wordsCount(combinedTrimValues[index])} - ${
                  combinedTrimValues[index] || "-"
                }`,
                limit
              )
            : "-",
          trashCan: {
            index,
            makeFilterChoice,
            rangeFilterChoice,
            trimFilterChoice,
          },
          viewCampaign: {
            index,
            makeFilterChoice,
            rangeFilterChoice,
            trimFilterChoice,
          },
        };
        mergedData.push(rowData);
      }
    }

    return mergedData;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makeFilterChoice, rangeFilterChoice, trimFilterChoice]);

  return (
    <div>
      <HeaderText className="subtitle">{headerText.TITLE_VEHICLE}</HeaderText>
      <ParagraphText className="info-text">
        {paragraphText.VEHICLE_INCLUSIONS_DESCRIP}
      </ParagraphText>
      <ItemContainer>
        <ItemLabel alignItems={"center"} display={"flex"}>
          <SubHeaderText>{subHeaderText.AGE_CRITERIA}</SubHeaderText>
        </ItemLabel>
        <ItemContent>
          <div className="age-container">
            <div className="age-container-item">
              <ParagraphText>{comparisonValues.BETWEEN}</ParagraphText>
              <InputText
                medium
                type="number"
                value={fromVehicleAgeMonths}
                min={0}
                max={100}
                validValue={validFromVehicleAgeMonths}
                onChange={(e) => onChangeFromVehicleAgeMonths(e)}
                disabled={disableForm}
              />
            </div>
            <div className="age-container-item">
              <ParagraphText>{comparisonValues.AND}</ParagraphText>
              <InputText
                medium
                type="number"
                value={toVehicleAgeMonths}
                min={0}
                max={100}
                validValue={validToVehicleAgeMonths}
                onChange={(e) => onChangeToVehicleAgeMonths(e)}
                disabled={disableForm}
              />
            </div>
          </div>
          {!validAgeNumber && (
            <BidInputError errorMessage={errorMessages.INVALID_AGE} />
          )}
          {!validAgeLength && (
            <BidInputError errorMessage={errorMessages.INVALID_NUMBER_LENGTH} />
          )}
        </ItemContent>
      </ItemContainer>

      <ItemContainer>
        <ItemLabel alignItems={"center"} display={"flex"}>
          <SubHeaderText>{subHeaderText.MILEAGE_CRITERIA}</SubHeaderText>
          <div style={{ width: "80%" }}></div>
        </ItemLabel>
        <ItemContent>
          <div className="mileage-container">
            <div className="mileage-container-item">
              <ParagraphText>{comparisonValues.BETWEEN}</ParagraphText>
              <InputText
                medium
                type="number"
                value={fromMileage}
                min={0}
                max={1000000}
                validValue={validFromMileage}
                onChange={(e) => onChangeFromMileage(e)}
                disabled={disableForm}
              />
            </div>
            <div className="mileage-container-item">
              <ParagraphText>{comparisonValues.AND}</ParagraphText>
              <InputText
                medium
                type="number"
                value={toMileage}
                min={0}
                max={1000000}
                validValue={validToMileage}
                onChange={(e) => onChangeToMileage(e)}
                disabled={disableForm}
              />
            </div>
          </div>
          {!validMileageNumber && (
            <BidInputError errorMessage={errorMessages.INAVLID_MILEAGE} />
          )}
          {!validMileageLength && (
            <BidInputError errorMessage={errorMessages.INVALID_NUMBER_LENGTH} />
          )}
        </ItemContent>
      </ItemContainer>

      <ItemContainer>
        <ItemLabel>
          <SubHeaderText>{subHeaderText.AUCTION_GRADE}</SubHeaderText>
        </ItemLabel>
        <ItemContent>
          <div className="campaignAuctionGrades-container">
            {!isFetchingAuctionGrades &&
            campaignAuctionGradesData &&
            !!campaignAuctionGradesData.length ? (
              campaignAuctionGradesData
                .filter((option) => option.key !== null && option.key !== "T")
                .sort((a, b) => parseInt(a.key) - parseInt(b.key))
                .map((option) => (
                  <Checkbox
                    key={option.key}
                    count={option.count}
                    isChecked={
                      Array.isArray(campaignAuctionGrade) &&
                      campaignAuctionGrade.some(
                        (item) => item.key === option.key && item.value
                      )
                    }
                    onInputChange={() => onInputAuctionGradeChange(option)}
                    isDisabled={disableForm}
                  >
                    {option.key}
                  </Checkbox>
                ))
            ) : (
              <SkeletonRows nrOfRows={1} />
            )}
          </div>
        </ItemContent>
      </ItemContainer>

      <ItemContainer>
        <ItemLabel>
          <SubHeaderText>{subHeaderText.AUCTION_CONDITION}</SubHeaderText>
        </ItemLabel>
        <ItemContent>
          <div className="campaignAuctionConditions-container">
            {!isFetchingAuctionConditions &&
            campaignAuctionConditionsData &&
            !!campaignAuctionConditionsData.length ? (
              campaignAuctionConditionsData
                .slice()
                .sort((a, b) => {
                  const order = ["Clean", "Average", "BelowAverage", "Unknown"];
                  return order.indexOf(a.key) - order.indexOf(b.key);
                })
                .map((option) => {
                  const displayKey =
                    option.key === "BelowAverage"
                      ? "Below Average"
                      : option.key;

                  return (
                    <Checkbox
                      key={option.key}
                      count={option.count}
                      isChecked={
                        Array.isArray(campaignAuctionCondition) &&
                        campaignAuctionCondition.some(
                          (item) => item.key === option.key && item.value
                        )
                      }
                      onInputChange={() =>
                        onInputAuctionConditionChange(option)
                      }
                      isDisabled={disableForm}
                    >
                      {displayKey}
                    </Checkbox>
                  );
                })
            ) : (
              <SkeletonRows nrOfRows={1} />
            )}
          </div>
        </ItemContent>
      </ItemContainer>

      <ItemContainer>
        <ItemLabel>
          <SubHeaderText>{subHeaderText.VEHICLE_SELECTION}</SubHeaderText>
          <div style={{ width: "80%" }}>
            <ParagraphText className="info-text">
              {paragraphText.VEHICLE_SELECTION_DESCRIP}
            </ParagraphText>
          </div>
        </ItemLabel>
        <ItemContent>
          <Button
            content="Add Vehicles"
            secondary
            action={() => onClickOpenAddVehicles()}
            disabled={disableForm}
          />

          {makeFilterChoice !== null &&
            (makeFilterChoice[0]?.length > 0 ||
              makeFilterChoice[1]?.length > 0 ||
              makeFilterChoice[2]?.length > 0) && (
              <Table columns={fixedColumns} data={data} />
            )}
        </ItemContent>
      </ItemContainer>
    </div>
  );
};

export default VehicleCriteria;
