import styled from "styled-components";
import StyledButton from "../Button";
import StyledSubHeaderText from "../SubHeaderText";

import BidForm from "./BidForm";
import HeaderText from "../HeaderText";
import SubHeaderText from "../SubHeaderText";
import breakpoints from "../../theme/breakpoints";

const StyledBidForm = styled(BidForm)`
  .bid-form__group-buyer {
    margin-top: ${props => props.theme.SPACING.REGULAR};
  }
  .bid-form__group-buyer-form {
    display: flex;
    .dropdown {
      width: 100%;
    }
    ${StyledButton} {
      margin-left: ${props => props.theme.SPACING.SMALL};
    }
  }
  .bid-form__form,
  .bid-form__terms {
    margin-top: ${props => props.theme.SPACING.MEDIUM};
    ${StyledSubHeaderText} {
      margin-bottom: ${props => props.theme.SPACING.SMALL};
    }
  }

  ${HeaderText},
  ${SubHeaderText} {
    /* Have to set max width as HeaderText size variants aren't consistent throughout designs */
    @media (max-width: ${breakpoints.LARGE_DESKTOP}) {
      font-size: ${props => props.theme.FONTS.sizes[60]};
      line-height: ${props => props.theme.FONTS.lineHeights[60]};
      margin-bottom: ${props => props.theme.SPACING.SMALL};
    }
  }
`;

export default StyledBidForm;
