import styled, { css } from "styled-components";

import SettingsMenu from "./SettingsMenu.jsx";
import breakpoints from "../../theme/breakpoints.js";

export const StyledSettingsMenu = styled(SettingsMenu)`
  display: none;

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    border-radius: 0 0 4.8rem 0;
    height: 8.2rem;
    background-color: ${props => props.theme.COLOURS.PRIMARY.shades[90]};
    display: flex;
    align-items: center;
    cursor: pointer;

    > span {
      margin-right: 2.8rem;
      margin-left: 2.8rem;
      color: ${props => props.theme.COLOURS.WHITE};
    }

    &:hover,
    &.active {
      background-color: ${props => props.theme.COLOURS.PRIMARY.shades[70]};
    }

    .popover {
      position: relative;
      cursor: default;
    }

    .popover__arrow {
      height: 0;
      width: 0;
      position: absolute;
      transform: translateX(100%);
      z-index: 3;
      pointer-events: none;
      bottom: -4.5rem;
      left: -5rem;
      &:after,
      &:before {
        bottom: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &:after {
        border-color: transparent;
        border-bottom-color: ${props => props.theme.COLOURS.WHITE};
        border-width: 0.8rem;
        margin-left: -0.8rem;
        top: -0.6rem;
      }

      &::before {
        border-color: transparent;
        border-bottom-color: ${props =>
          props.theme.COLOURS.SECONDARY.shades[20]};
        border-width: 1rem;
        margin-left: -1rem;
        top: -1.1rem;
      }
    }

    .popover__container {
      box-sizing: border-box;
      border: 0.2rem solid
        ${props => props.theme.COLOURS.SECONDARY.shades[20]};
      border-radius: 0.5rem;
      padding: ${props => props.theme.SPACING.LARGE};
      background-color: ${props => props.theme.COLOURS.WHITE};
      position: absolute;
      right: 0;
      min-width: 32rem;
      max-width: 40rem;
      width: fit-content;
      bottom: -4.5rem;
      transform: translateY(100%) translateY(0.77rem);
      z-index: 2;
      text-align: right;

      & > .popover__container__panel {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        text-align: left;
        @media screen and (-ms-high-contrast: active),
          screen and (-ms-high-contrast: none) {
          /* IE10+ specific styles go here */
          /*A temporary fix for the display issue in IE11*/
          display: inline-block;
        }
      }
    }

    .settings__menu {
      width: 100%;

      hr {
        margin: 0;
      }

      div {
        padding-top: ${props => props.theme.SPACING.REGULAR};
        padding-bottom: ${props => props.theme.SPACING.REGULAR};
        text-align: center;
        :hover {
          background-color: blue;
        }
      }
    }
  }

  ${props =>
    props.inline &&
    css`
      display: block;
    `}
`;

export default StyledSettingsMenu;
