import React, { useContext } from "react";
import { PopoverFilterContext } from "../../../../shared/context";
import Label from "./Label";

const Trigger = () => {
  const { onDropdownSelect = () => {}, isDisabled = false } = useContext(
    PopoverFilterContext
  );
  return (
    <button
      className="filter__trigger"
      onClick={() => onDropdownSelect()}
      disabled={isDisabled}
    >
      <Label />
    </button>
  );
};

export default Trigger;
