import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import TariffInformation from "../../components/TariffInformation";
import ModalInsert from "../../components/ModalInsert";

const TariffModalInsert = () => {
  const tariffData = useSelector((state) => state.tariff.fees);
  const isFetching = useSelector((state) => state.tariff.isFetching);

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <ModalInsert layout="flex" width="72rem">
      <TariffInformation
        tariffData={tariffData}
        isFetching={isFetching}
        onClose={onClose}
      />
    </ModalInsert>
  );
};

export default TariffModalInsert;
