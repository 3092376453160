import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../components/ModalInsert";
import { useNavigate } from "react-router";
import GenericConfirmInsert from "../../components/GenericConfirmInsert";
import { declineClaim } from "../../../state/reducers/claimDetailsVendor";
import VendorDeclineConfirmation from "../../components/VendorDeclineClaim/VendorDeclineConfirmation";
import { clearVehicleRegRequest } from "../../../state/reducers/regSearch";
import { setBucketType } from "./../../../state/reducers/toolbar";

const VendorDeclineClaimDecision = ({ claimData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [claimDeclined, setClaimDeclined] = useState(false);

  const onDeclineClaim = () => {
    dispatch(declineClaim(claimData));
    dispatch(setBucketType("all"));
    dispatch(clearVehicleRegRequest());
    setTimeout(() => {
      dispatch(closeModal());
      navigate(-1);
    }, 2500);
    setClaimDeclined(true);
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  return (
    <Fragment>
      <ModalInsert>
        {claimDeclined ? (
          <VendorDeclineConfirmation />
        ) : (
          <GenericConfirmInsert
            headerText="Are you sure?"
            bodyText="Are you sure you wish to decline this claim?"
            onCancel={onCancel}
            onConfirm={onDeclineClaim}
          />
        )}
      </ModalInsert>
    </Fragment>
  );
};

export default VendorDeclineClaimDecision;
