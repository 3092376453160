import VehicleNoteApi from "../../api/VehicleNote";
import { updateShortlistNote } from "./shortlist";

// Action constants
export const ADD_VEHICLE_NOTE = "@vehicleNote/ADD_VEHICLE_NOTE";
export const ADD_VEHICLE_NOTE_SUCCESS = "@vehicleNote/ADD_VEHICLE_NOTE_SUCCESS";
export const ADD_VEHICLE_NOTE_FAILURE = "@vehicleNote/ADD_VEHICLE_NOTE_FAILURE";

// Action creators
const addVehicleNoteSuccess = (vehicleId, vehicleNote) => (
  dispatch,
  getState
) => {
  const isVehicleAvailableForUpdate = getState().shortlist.results.byId[
    vehicleId
  ];

  if (isVehicleAvailableForUpdate) {
    dispatch(updateShortlistNote(vehicleId, vehicleNote));
  }

  return {
    type: ADD_VEHICLE_NOTE_SUCCESS,
  };
};
const addVehicleNoteFailure = (error) => {
  return {
    type: ADD_VEHICLE_NOTE_FAILURE,
    payload: error,
  };
};

export const addVehicleNote = (vehicleId, vehicleNote) => (
  dispatch,
  getState
) => {
  dispatch({ type: ADD_VEHICLE_NOTE });

  const instance = new VehicleNoteApi({
    params: { VehicleId: vehicleId, Notes: vehicleNote },
    credentials: getState().authentication.credentials,
  });

  instance.call().then(
    (response) => {
      dispatch(addVehicleNoteSuccess(vehicleId, vehicleNote));
    },
    (err) => {
      return dispatch(addVehicleNoteFailure(err));
    }
  );
};

const initialState = {
  error: false,
  isUpdating: false,
  isSuccessful: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_VEHICLE_NOTE:
      return {
        ...state,
        isUpdating: true,
        isSuccessful: false,
        error: false,
      };
    case ADD_VEHICLE_NOTE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isSuccessful: true,
      };
    case ADD_VEHICLE_NOTE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        isSuccessful: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
