import styled, { css } from "styled-components";
import Panel from "./Panel";
import BREAKPOINTS from "../../theme/breakpoints";
import { StyledHeaderText } from "../HeaderText";
import { StyledSubHeaderText } from "../SubHeaderText";
import { StyledParagraphText } from "../ParagraphText";
import breakpoints from "../../theme/breakpoints";
import PricingBlock from "../PricingBlock";
import Button from "../Button";

// TODO: We may need to revisit this as more "themes" appear.
// Created like this to allow default font colours etc to be set
// if neccessary
const panelThemes = {
  default: {
    backgroundColor: props => props.theme.COLOURS.WHITE
  },
  grey: {
    backgroundColor: props => props.theme.COLOURS.PRIMARY.shades[5]
  }
};

const StyledPanel = styled(Panel)`
  background-color: ${props =>
    props.type
      ? panelThemes[props.type].backgroundColor
      : panelThemes.default.backgroundColor};
  box-sizing: border-box;
  flex: ${props => props.flex && props.flex};
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.LARGE_DESKTOP}) {
    flex: ${props => props.flexDesktop && props.flexDesktop};
  }

  .panel {
    &__wrapper {
      align-items: stretch;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      padding: ${props =>
        props.padding
          ? props.theme.SPACING[props.padding]
          : props.theme.SPACING.REGULAR};

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        padding: ${props =>
          props.padding
            ? props.theme.SPACING[props.padding]
            : props.theme.SPACING.LARGE};
      }

      + * {
        margin-top: ${props => props.theme.SPACING.REGULAR};
      }
    }

    &__content {
      ${props =>
        props.mobileFlex &&
        css`
          @media (max-width: ${breakpoints.LARGE_DESKTOP}) {
            display: flex;

            > ${PricingBlock} {
              width: 50%;

              @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
                width: auto;
              }
            }
          }
        `}
    }
  }

  img {
    display: block;
    max-width: 100%;
  }

  /* TODO: We should implement a css reset to avoid having to remove these margins everywhere */
  ${StyledHeaderText} {
    margin-bottom: ${props => props.theme.SPACING.SMALL};
    margin-top: 0;
  }

  ${StyledSubHeaderText} {
    margin-top: 0;
  }

  ${StyledParagraphText} {
    margin-top: 0;
  }

  ${Button} {
    @media (max-width: ${BREAKPOINTS.LARGE_DESKTOP}) {
      width: 50%;
    }
  }
`;

export default StyledPanel;
