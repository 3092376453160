import Api from "./Base";
import settings from "../clientappsettings.json";

export class GetBuyerInformation extends Api {
  baseUrl = settings.BUYERS_URL;
  requestUrl = `/Buyers`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class GetAccountInformation extends GetBuyerInformation {
  requestUrl = `/Buyers/Account`;
}

export class UpdateBuyerInformation extends GetBuyerInformation {
  requestUrl = `/Buyers/Update`;
  method = "POST";
}

export class UpdateAccountInformation extends GetBuyerInformation {
  requestUrl = `/Buyers/Account/Update`;
  method = "POST";
}

export class GetSiteUsers extends GetBuyerInformation {
  requestUrl = `/Buyers/Account/GetSiteUsers`;
}

export class UpdateSiteUser extends Api {
  baseUrl = settings.USERS_URL;
  requestUrl = `/user/Account/SiteUser/Update`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class AddNewUser extends Api {
  baseUrl = settings.USERS_URL;
  requestUrl = `/user/Account/SiteUser/AddNewUser`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class GetDefaultPurchaseSettings extends Api {
  baseUrl = settings.BUYERS_URL;
  requestUrl = "/Buyers/Account/GetDefaultPurchaseSettings";
  method = "GET";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}
export class UpdateDefaultPurchaseSettings extends Api {
  baseUrl = settings.BUYERS_URL;
  requestUrl = "/Buyers/Account/UpdateDefaultPurchaseSettings";
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class GetShowroomModeSettings extends Api {
  baseUrl = settings.BUYERS_URL;
  requestUrl = "/Buyers/Account/GetShowroomModeSettings";
  method = "GET";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}
export class UpdateShowroomModeSettings extends Api {
  baseUrl = settings.BUYERS_URL;
  requestUrl = "/Buyers/Account/UpdateShowroomModeSettings";
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class DeleteShowroomModeSettingsLogo extends Api {
  baseUrl = settings.AZURE_VEHICLE_URL;
  requestUrl = `/api/BlobDelete?code=${settings.AZURE_VEHICLE_API_KEY}`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class SaveShowroomModeSettingsBlockLogo extends Api {
  baseUrl = settings.AZURE_VEHICLE_URL;
  requestUrl = `/api/BlobBlockUpload?type=ShowroomBuyerLogo&fileName=${
    this.getParams().fileName
  }&blockNo=${this.getParams().blockNo}&blockSize=${
    this.getParams().blockSize
  }&finalBlock=${this.getParams().finalBlock}&userType=Buyer&code=${
    settings.AZURE_VEHICLE_API_KEY
  }`;
  method = "POST_BLOCK";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class FetchSupportingDocumentsForBuyer extends Api {
  baseUrl = settings.AZURE_VEHICLE_URL;
  requestUrl = `/api/BlobList?code=${settings.AZURE_VEHICLE_API_KEY}`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  formatResponseData(response) {
    if (response.data?.showroomModeLogo) {
      return response.data.showroomModeLogo;
    }
    return response;
  }
}
export class GetPromotionDetailsForBuyer extends Api {
  baseUrl = settings.BUYERS_URL;
  requestUrl = "/Campaign/PromotionDetails";
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}
