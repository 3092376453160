import React from "react";
import dayjs from "dayjs";
import { subHeaderText } from "../const";
import ParagraphText from "../../../../../views/components/ParagraphText";
import { ParagraphTextContainer } from "..";

export const AuditDetails = ({
  dateModified,
  modifiedBy,
  approvedDate,
  approvedBy,
  campaignStatus,
}) => {
  return dateModified && modifiedBy ? (
    <div className="audit_details">
      <ParagraphTextContainer>
        <hr />
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <ParagraphText>
              {subHeaderText.LAST_SAVED_BY}
              {modifiedBy}
            </ParagraphText>
            <ParagraphText>
              {subHeaderText.LAST_SAVED_DATE}
              {dayjs(dateModified).format("DD/MM/YYYY HH:mm")}
            </ParagraphText>
          </div>
          {approvedDate && approvedBy && campaignStatus !== "New" && (
            <div style={{ flex: 1 }}>
              <ParagraphText>
                {subHeaderText.APPROVED_BY}
                {approvedBy}
              </ParagraphText>
              <ParagraphText>
                {subHeaderText.APPROVED_DATE}
                {dayjs(approvedDate).format("DD/MM/YYYY HH:mm")}
              </ParagraphText>
            </div>
          )}
        </div>
      </ParagraphTextContainer>
    </div>
  ) : null;
};

export default AuditDetails;
