export const HEADER_TEXT = "Raising a Claim";

export const PARAGRAPH_TEXT =
  "Please enter a registration number of the vehicle you wish to raise a claim against. Please note: you will only be able to raise a claim against an active purchase.";
export const SUBHEADER_TEXT = "Enter the registration";

export const errorMessages = {
  REGISTRATION_NOT_FOUND:
    "Sorry, that registration cannot be found, please try again with an alternative registration.",
  VENDOR_NOT_ENABLED_FOR_CLAIMS:
    "Sorry, we are unable to proceed against this registration, please contact the vehicle seller directly to raise a claim.",
  CLAIM_ALREADY_EXISTS:
    "A claim already exists for the entered registration number.",
  CLAIM_CATEGORIES_EXPIRED:
    "The period to raise a claim against this vehicle has now expired. Please call ##HELP_CENTRE_TEL_NO## if you wish to speak to someone about this.",
};

export const regSearchStatusType = {
  CLAIMS_ENABLED: "ClaimsEnabled",
  CLAIMS_NOT_ENABLED: "ClaimsNotEnabled",
  HAS_ACTIVE_CLAIM: "HasActiveClaim",
  CATEGORIES_EXPIRED: "CategoriesExpired",
};
