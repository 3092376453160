import React from "react";
import PropTypes from "prop-types";
import HeaderText from "../../../views/components/HeaderText";
import ParagraphText from "../../../views/components/ParagraphText";
import { paragraphText } from "./const";

const AcceptOfferConfirmation = ({
  className,
  vendorName,
  radioOptionChoice,
}) => {
  return (
    <div className={className}>
      <div className="confirmation-title">
        <HeaderText>
          {radioOptionChoice === "AmountAccepted"
            ? paragraphText.HEADER_TEXT_OFFER
            : paragraphText.HEADER_TEXT_CANCELLATION}
        </HeaderText>
      </div>
      <div className="confirmation-text">
        <ParagraphText>
          {radioOptionChoice === "AmountAccepted"
            ? `Confirmation of the accepted offer has now been sent to ${vendorName}.
          This claim will be automatically updated to completed once the vendor
          commences the credit process.`
            : `Confirmation of the Sale Cancellation request has been submitted to ${vendorName}. 
          Once processed, confirmation of the sale cancellation will appear within 
          your messages area and the claim will be automatically updated to completed.`}
        </ParagraphText>
      </div>
      <div className="confirmation-text">
        <ParagraphText>
          {radioOptionChoice === "AmountAccepted" ? paragraphText.LINE_TWO : ""}
        </ParagraphText>
      </div>
    </div>
  );
};

AcceptOfferConfirmation.propTypes = {
  className: PropTypes.string,
  vendorName: PropTypes.string,
};

export default AcceptOfferConfirmation;
