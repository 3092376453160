import styled from "styled-components";

import AddressField from "./AddressField.jsx";

export const StyledAddressField = styled(AddressField)`
  width: 100%;

  textarea {
    box-sizing: border-box;
    padding: ${props => props.theme.SPACING.SMALL};
    margin: ${props => props.theme.SPACING.SMALL} 0;
    border-width: 0.1rem;
    border-style: solid;
    border-radius: 0.4rem;
    width: 100%;
    resize: none;
    ${props => props.theme.FONTS.Text["paragraph"]}
    color: ${props => props.textAreaColor};
  }

  .error {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: ${props => props.theme.SPACING.SMALL} 1.5rem
      ${props => props.theme.SPACING.SMALL} 1.7rem;
    background: ${props => props.theme.COLOURS.RED.base};
    border-radius: 0.5rem;
    color: ${props => props.theme.COLOURS.WHITE};
    ${props => props.theme.FONTS.Text["paragraphSemiBold"]}
    span {
      margin-right: ${props => props.theme.SPACING.SMALL};
    }
  }

  .invalid {
    border-color: ${props => props.theme.COLOURS.RED.base};
  }

  .valid {
    border-color: ${props => props.theme.COLOURS.PRIMARY.shades[20]};
  }
`;

export default StyledAddressField;
