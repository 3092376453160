import React, { useState } from "react";

export const ClaimCallbackNoteContext = React.createContext({});
export const ClaimCallbackNoteValidationContext = React.createContext({});

export const ClaimCallbackNoteContextProvider = ({ children }) => {
  const [claimCallbackNote, setClaimCallbackNote] = useState("");

  return (
    <ClaimCallbackNoteContext.Provider
      value={{
        claimCallbackNote,
        setClaimCallbackNote,
      }}
    >
      {children}
    </ClaimCallbackNoteContext.Provider>
  );
};

export const ClaimCallbackNoteValidationContextProvider = ({ children }) => {
  const [validClaimCallbackNote, setValidClaimCallbackNote] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <ClaimCallbackNoteValidationContext.Provider
      value={{
        validClaimCallbackNote,
        setValidClaimCallbackNote,
        hasFocus,
        setHasFocus,
      }}
    >
      {children}
    </ClaimCallbackNoteValidationContext.Provider>
  );
};
