import PasswordChangeApi from "../../api/PasswordChange";

export const CLEAR_DETAILS = "@passwordChangeRequest/CLEAR_DETAILS";
export const PASSWORD_CHANGE_REQUEST = "@passwordChangeRequest/PASSWORD_CHANGE_ REQUEST";
export const PASSWORD_CHANGE_REQUEST_SUCCESS = "@passwordChangeRequest/PASSWORD_CHANGE_ REQUEST_SUCCESS";
export const PASSWORD_CHANGE_REQUEST_FAILURE = "@passwordChangeRequest/PASSWORD_CHANGE_ REQUEST_FAILURE";

export const clearDetails = () => {
  return {
    type: CLEAR_DETAILS
  };
};

const passwordChangeRequestSuccess = () => {
  return {
    type: PASSWORD_CHANGE_REQUEST_SUCCESS
  };
};
const passwordChangeRequestFailure = error => {
  return {
    type: PASSWORD_CHANGE_REQUEST_FAILURE,
    payload: error
  };
};

export const passwordChangeRequest = (oldPassword, newPassword) => (dispatch, getState) => {
  dispatch({ type: PASSWORD_CHANGE_REQUEST });
  const params = {
    oldPassword: oldPassword,
    newPassword: newPassword
  };

  const instance = new PasswordChangeApi({
    params: params,
    credentials: getState().authentication.credentials
  });
  instance.call().then(
    response => {
      dispatch(passwordChangeRequestSuccess());
    },
    err => {
      return dispatch(passwordChangeRequestFailure(err));
    }
  );
};

const initialState = {
  isFetching: false,
  error: false,
  success: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_DETAILS:
      return {
        initialState
      };
    case PASSWORD_CHANGE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        success: false
      };
    case PASSWORD_CHANGE_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success: true,
        error: false
      };
    case PASSWORD_CHANGE_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        success: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
