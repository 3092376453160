import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Printer = ({ width, height, strokeColour, strokeWidth }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 33"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(1 1)"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10 24.933h12M10 21.097h12M7.333 21.099h-4C1.863 21.094.671 19.952.667 18.54V10.87c.004-1.41 1.195-2.553 2.666-2.557h25.334c1.47.004 2.662 1.146 2.666 2.557v7.672c-.004 1.41-1.195 2.553-2.666 2.558h-4M7.333 5.754V.64h12.782c.353 0 .692.135.942.375l3.219 3.087c.25.24.39.565.39.904v.75" />
        <path d="M24.667 28.77c0 .707-.597 1.28-1.334 1.28H8.667c-.737 0-1.334-.573-1.334-1.28V17.264h17.334V28.77z" />
        <ellipse cx="4.667" cy="12.146" rx="1.333" ry="1.279" />
        <path d="M19.333.638v5.115h5.334" />
      </g>
    </svg>
  );
};

Printer.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

Printer.defaultProps = {
  width: "34",
  height: "33",
  strokeWidth: "2"
};

export default Printer;
