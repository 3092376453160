import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import HeaderText from "../../HeaderText";
import ParagraphText from "../../ParagraphText";
import { paragraphText, tableHeaderText } from "./../consts";
import Icon from "../../Icon";
import { ThemeContext } from "styled-components";

const ClaimActivityLogTableHeader = ({
  claimId,
  showHeader,
  onOpenVendorNote,
  isVendorOnly,
}) => {

  const theme = useContext(ThemeContext);
  return (
    <div>
      {showHeader && (
        <Fragment>
          <div className={"main-text"}>
            <HeaderText>{paragraphText.HEADER_TEXT}</HeaderText>
            <ParagraphText className="case-ref">
              {`${paragraphText.CASE_REF_TEXT}: ${claimId}`}
            </ParagraphText>
          </div>
          <div className="sub-text">
            <ParagraphText>{paragraphText.SUB_HEADER_TEXT}</ParagraphText>
          </div>
        </Fragment>
      )}

      <div className="activity-log_reference-container">
        <div className="activity-log__table">
          <table>
            <thead>
              <tr>
                <th className="header-date">
                  <ParagraphText className="table-headings">
                    {tableHeaderText.HEADER_DATE}
                  </ParagraphText>
                </th>
                <th className="header-user">
                  <ParagraphText className="table-headings">
                    {tableHeaderText.HEADER_USER}
                  </ParagraphText>
                </th>
                <th className="header-status">
                  <ParagraphText className="table-headings">
                    {tableHeaderText.HEADER_STATUS}
                  </ParagraphText>
                </th>
                <th
                  className="header-response"
                  style={{ paddingLeft: "1.2rem" }}
                >
                  <div className="responseHeaderContainer">
                    <ParagraphText className="table-headings">
                      {tableHeaderText.HEADER_RESPONSE}
                    </ParagraphText>
                    {isVendorOnly && (
                      <span
                        className="iconAddNoteContainer"
                        onClick={() => onOpenVendorNote()}
                      >
                        <Icon
                          type="add-note"
                          fill={theme.COLOURS.WHITE}
                          width="22"
                          height="22"
                        />
                      </span>
                    )}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

ClaimActivityLogTableHeader.propTypes = {
  claimId: PropTypes.number,
};

export default ClaimActivityLogTableHeader;
