import styled, { css } from "styled-components";

import VehicleKeyInformation from "./VehicleKeyInformation";
import { StyledInfoTag } from "../InfoTag";
import { StyledKeyInformationLabel } from "./../KeyInformationLabel";

const StyledVehicleKeyInformation = styled(VehicleKeyInformation)`
  ${props =>
    !props.small &&
    css`
      ${StyledKeyInformationLabel} {
        font-weight: ${props.theme.FONTS.weights[70]};
        font-size: ${props.theme.FONTS.sizes[30]};
        line-height: ${props.theme.FONTS.lineHeights[20]};
        margin-bottom: 0.5rem;
      }
    `};
  ${props =>
    props.row &&
    css`
      display: flex;
      flex-direction: column;
      text-align: left;

      ${StyledKeyInformationLabel} {
        margin-bottom: 0.5rem;
      }

      > .key-information__tags {
        display: flex;

        ${StyledInfoTag} {
          margin-right: 1.5rem;
        }
      }
    `};

  ${props =>
    props.column &&
    css`
      display: flex;
      flex-wrap: wrap;

      ${StyledKeyInformationLabel} {
        width: 100%;
        margin-bottom: 0.5rem;
      }

      ${StyledInfoTag} {
        width: 100%;
      }
    `};
`;

export default StyledVehicleKeyInformation;
