import Api from "./Base";
import clientSettings from "../clientappsettings.json";
import {
  formatPurchaseOptions,
  formatPricingDetailData
} from "../shared/utility";

class PricingApi extends Api {
  baseUrl = clientSettings.API_URL;
  requestUrl = "/Vehicle/Pricing";
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };

  formatRequestData(params) {
    return formatPurchaseOptions(params);
  }

  formatResponseData(response) {
    return formatPricingDetailData(response.data);
  }
}

export default PricingApi;
