import styled from "styled-components";

import HeaderText from "./HeaderText.jsx";
import breakpoints from "../../theme/breakpoints.js";

export const StyledHeaderText = styled(HeaderText)`
  color: ${props => props.color || props.theme.COLOURS.PRIMARY.base};
  ${props => props.theme.FONTS.Text["mobileHeader"]}

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    ${props => props.theme.FONTS.Text["header"]}
  }

  /* Overwrite size */
  font-size: ${props =>
    props.fontSize && props.theme.FONTS.sizes[props.fontSize]};
  line-height: ${props =>
    props.fontSize && props.theme.FONTS.lineHeights[props.fontSize]};

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    font-size: ${props =>
      props.fontSize && props.theme.FONTS.sizes[props.fontSize]};
    line-height: ${props =>
      props.fontSize && props.theme.FONTS.lineHeights[props.fontSize]};
  }
`;

export default StyledHeaderText;
