import React from "react";
import { ItemContainer, ItemLabel, ItemContent } from "../index";
import { subHeaderText, paragraphText } from "../consts";
import ParagraphText from "../../ParagraphText";
import SubHeaderText from "../../SubHeaderText";
import Button from "../../Button";
import AddressField from "../../AddressField";

const Vehicle = ({ addressLookup, vehicleAddress, disabled }) => {
  const vehicleAddressText = vehicleAddress && vehicleAddress.join("\n");

  return (
    <>
      <ItemContainer>
        <ItemLabel>
          <SubHeaderText>
            {subHeaderText.VEHICLE_CURRENT_LOCATION}:
          </SubHeaderText>{" "}
          <div style={{ width: "80%" }}>
            <ParagraphText>{paragraphText.VEHICLE}</ParagraphText>
          </div>
        </ItemLabel>
        <ItemContent>
          <Button
            ghost
            content="Lookup Address"
            action={() => addressLookup()}
            disabled={disabled}
          />
        </ItemContent>
      </ItemContainer>
      <ItemContainer marginTop={"0"}>
        <ItemLabel />
        <ItemContent>
          <AddressField addressText={vehicleAddressText || ""} disabled />
        </ItemContent>
      </ItemContainer>
    </>
  );
};

export default Vehicle;
