import styled, { css } from "styled-components";

import PriceText from "./PriceText.jsx";
import breakpoints from "../../theme/breakpoints.js";

export const StyledPriceText = styled(PriceText)`
  color: ${(props) => props.theme.COLOURS.PRIMARY.base};
  font-family: ${(props) => props.theme.FONTS.families["lato"]};
  font-weight: ${(props) => props.theme.FONTS.weights[70]};
  font-size: ${(props) => props.theme.FONTS.sizes[60]};
  line-height: ${(props) => props.theme.FONTS.lineHeights[70]};

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    font-size: ${(props) => props.theme.FONTS.sizes[80]};
    line-height: ${(props) => props.theme.FONTS.lineHeights[90]};
  }

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    font-size: ${(props) => props.theme.FONTS.sizes[100]};
    line-height: ${(props) => props.theme.FONTS.lineHeights[110]};
  }

  span {
    font-size: ${(props) => props.theme.FONTS.sizes[30]};

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      font-size: ${(props) => props.theme.FONTS.sizes[30]};
      line-height: ${(props) => props.theme.FONTS.lineHeights[40]};
    }

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      font-size: ${(props) => props.theme.FONTS.sizes[90]};
    }
  }

  ${(props) =>
    props.tiny &&
    css`
      font-size: ${props.theme.FONTS.sizes[40]};
      line-height: ${props.theme.FONTS.lineHeights[100]};
      color: ${props.theme.COLOURS.PRIMARY.shades[60]};

      span {
        font-size: ${props.theme.FONTS.sizes[20]};
      }

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        font-size: ${props.theme.FONTS.sizes[40]};

        span {
          font-size: ${props.theme.FONTS.sizes[20]};
        }
      }
    `}

  ${(props) =>
    props.extrasmall &&
    css`
      line-height: ${props.theme.FONTS.lineHeights[100]};
      font-size: ${props.theme.FONTS.sizes[40]};

      span {
        font-size: ${props.theme.FONTS.sizes[40]};
      }

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        font-size: ${props.theme.FONTS.sizes[40]};

        span {
          font-size: ${props.theme.FONTS.sizes[40]};
        }
      }
    `}

  ${(props) =>
    props.small &&
    css`
      font-size: ${props.theme.FONTS.sizes[70]};

      span {
        font-size: ${props.theme.FONTS.sizes[60]};
      }

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        font-size: ${props.theme.FONTS.sizes[70]};

        span {
          font-size: ${props.theme.FONTS.sizes[60]};
        }
      }
    `}

  ${(props) =>
    props.large &&
    css`
      font-size: ${(props) => props.theme.FONTS.sizes[60]};
      line-height: ${(props) => props.theme.FONTS.lineHeights[70]};

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        font-size: ${props.theme.FONTS.sizes[130]};
        line-height: ${props.theme.FONTS.lineHeights[130]};
      }

      span {
        font-size: ${props.theme.FONTS.sizes[40]};

        @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
          font-size: ${props.theme.FONTS.sizes[120]};
        }
      }
    `}

  ${(props) =>
    props.priceColor &&
    css`
      color: ${props.priceColor};
    `};
`;

export default StyledPriceText;
