import styled, { css } from "styled-components";

import Checkbox from "./Checkbox.jsx";
import breakpoints from "../../theme/breakpoints.js";

const StyledCheckbox = styled(Checkbox)`
  position: relative;
  display: flex;
  align-items: center;
  label {
    color: ${(props) => props.theme.COLOURS.PRIMARY.base};
    display: inline-block;
    cursor: pointer;
    ${(props) => props.theme.FONTS.Text["paragraphCompact"]}
  }
  .checkbox__input {
    border: 0.2rem solid ${(props) => props.theme.COLOURS.SECONDARY.shades[20]};
    border-radius: 0.5rem;
    background-color: ${(props) => props.theme.COLOURS.WHITE};
    width: 2.9rem;
    height: 2.9rem;
    display: flex;
    margin-right: ${(props) => props.theme.SPACING.SMALL};
    position: relative;
  }
  input {
    width: 2.5rem;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    &:checked + .checkbox__indicator {
      opacity: 1;
    }
    &[type="checkbox"] {
      visibility: visible;
    }
    &:hover + .checkbox__indicator {
      background-color: transparent;

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        opacity: 1;
      }
    }
    &:hover + .checkbox__indicator:after {
      border-color: ${(props) => props.theme.COLOURS.PRIMARY.shades[20]};
    }
  }
  .checkbox__indicator {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    width: 2.9rem;
    height: 2.9rem;
    top: -0.2rem;
    left: -0.2rem;
    background-color: ${(props) => props.theme.COLOURS.PRIMARY.base};
    border-radius: 0.5rem;

    &:after {
      content: "";
      display: block;
      width: 0.5rem;
      height: 1.5rem;
      margin-bottom: 0.2rem;
      border-color: ${(props) => props.theme.COLOURS.WHITE};
      border-width: 0 0.2rem 0.2rem 0;
      border-style: solid;
      transform: rotate(42deg);
    }
  }
  &.checkbox--disabled {
    pointer-events: none;

    .checkbox__label {
      color: ${(props) => props.theme.COLOURS.PRIMARY.shades[70]};
    }

    input:hover + .checkbox__indicator:after {
      border-color: transparent;
    }
  }

  &.checkbox--checked {
    input {
      &:hover + .checkbox__indicator {
        opacity: 1;
        background-color: ${(props) => props.theme.COLOURS.PRIMARY.base};
      }
      &:hover + .checkbox__indicator:after {
        border-color: ${(props) => props.theme.COLOURS.WHITE};
      }
    }
  }

  &.checkbox--checked.checkbox--disabled {
    input + .checkbox__indicator {
      opacity: 0.5;
    }
  }

  .checkbox__count {
    margin: 0 0 0 0.5rem;
  }

  .checkbox__icon {
    margin: 0 1rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .colourCheck__icon {
    margin: 0rem 1rem 0 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid ${(props) => props.theme.COLOURS.SECONDARY.shades[20]};
    border-radius: 16px;
    position: absolute;
    right: 0;
  }

  ${(props) =>
    props.inverted &&
    css`
      label {
        color: ${(props) => props.checkboxLabelColour || props.theme.COLOURS.WHITE};
      }
    `}
`;

export default StyledCheckbox;
