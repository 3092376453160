import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const StylesReset = createGlobalStyle`
  // TODO: Update the fonts dynamically to support white labelling
  ${reset}
  * {
    box-sizing: border-box;
  }
  html {
    font-size: 10px;
    font-family: 'Lato', Arial, Helvetica, sans-serif;
  }
  body {
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
    line-height: 2.6rem;

    &.android.modal--open,
    &.ios.modal--open {
      height: 100%;
      height: calc(var(--vh, 1vh) * 100);
      position: fixed;
      width: 100%
    }
  }
  input:focus,
  button:focus {
    outline-color: #27345F;
  }
`;
export default StylesReset;
