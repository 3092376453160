import {
  GetBuyerInformation,
  GetAccountInformation,
  GetSiteUsers,
  UpdateBuyerInformation,
  UpdateAccountInformation,
  UpdateSiteUser,
  AddNewUser,
  GetDefaultPurchaseSettings,
  UpdateDefaultPurchaseSettings,
  GetShowroomModeSettings,
  UpdateShowroomModeSettings,
  DeleteShowroomModeSettingsLogo,
  SaveShowroomModeSettingsBlockLogo,
  FetchSupportingDocumentsForBuyer,
} from "../../api/BuyerInformation";
import { USER_TYPE_MAP } from "../../shared/utility";

import { fetchAccountAdministratorStatus } from "./authentication";
import { unsubscribePushNotification } from "./notificationPreference";

// Action constants
export const FETCH_BUYER_INFO = "@buyerInformation/FETCH_BUYER_INFO";
export const FETCH_BUYER_INFO_SUCCESS =
  "@buyerInformation/FETCH_BUYER_INFO_SUCCESS";
export const FETCH_BUYER_INFO_FAILURE =
  "@buyerInformation/FETCH_BUYER_INFO_FAILURE";

export const FETCH_ACCOUNT_INFO = "@buyerInformation/FETCH_ACCOUNT_INFO";
export const FETCH_ACCOUNT_INFO_SUCCESS =
  "@buyerInformation/FETCH_ACCOUNT_INFO_SUCCESS";
export const FETCH_ACCOUNT_INFO_FAILURE =
  "@buyerInformation/FETCH_ACCOUNT_INFO_FAILURE";

export const UPDATE_BUYER_INFO = "@buyerInformation/UPDATE_BUYER_INFO";
export const UPDATE_BUYER_INFO_SUCCESS =
  "@buyerInformation/UPDATE_BUYER_INFO_SUCCESS";
export const UPDATE_BUYER_INFO_FAILURE =
  "@buyerInformation/UPDATE_BUYER_INFO_FAILURE";

export const UPDATE_ACCOUNT_INFO = "@buyerInformation/UPDATE_ACCOUNT_INFO";
export const UPDATE_ACCOUNT_INFO_SUCCESS =
  "@buyerInformation/UPDATE_ACCOUNT_INFO_SUCCESS";
export const UPDATE_ACCOUNT_INFO_FAILURE =
  "@buyerInformation/UPDATE_ACCOUNT_INFO_FAILURE";

export const SET_ADDRESS_SELECTION = "@buyerInformation/SET_ADDRESS_SELECTION";

export const SET_BUYER_ADDRESS = "@buyerInformation/SET_BUYER_ADDRESS";
export const SET_ACCOUNT_ADDRESS = "@buyerInformation/SET_ACCOUNT_ADDRESS";
export const SET_BILLING_ADDRESS = "@buyerInformation/SET_BILLING_ADDRESS";
export const SET_DEFAULT_PURCHASE_ADDRESS =
  "@buyerInformation/SET_DEFAULT_PURCHASE_ADDRESS";
export const SET_SITE_USER_TO_BE_UPDATED =
  "@buyerInformation/SET_SITE_USER_TO_BE_UPDATED";

export const FETCH_SITE_USERS = "@buyerInformation/FETCH_SITE_USERS";
export const FETCH_SITE_USERS_SUCCESS =
  "@buyerInformation/FETCH_SITE_USERS_SUCCESS";
export const FETCH_SITE_USERS_FAILURE =
  "@buyerInformation/FETCH_SITE_USERS_FAILURE";

export const UPDATE_SITE_USER_INFO = "@buyerInformation/UPDATE_SITE_USER_INFO";
export const UPDATE_SITE_USER_INFO_SUCCESS =
  "@buyerInformation/UPDATE_SITE_USER_INFO_SUCCESS";
export const UPDATE_SITE_USER_INFO_FAILURE =
  "@buyerInformation/UPDATE_SITE_USER_INFO_FAILURE";

export const ADD_NEW_SITE_USER = "@buyerInformation/ADD_NEW_SITE_USER";
export const ADD_NEW_SITE_USER_SUCCESS =
  "@buyerInformation/ADD_NEW_SITE_USER_SUCCESS";
export const ADD_NEW_SITE_USER_FAILURE =
  "@buyerInformation/ADD_NEW_SITE_USER_FAILURE";

export const CLEAR_SITE_USERS_STATUS =
  "@buyerInformation/CLEAR_SITE_USERS_STATUS";

export const CLEAR_SITE_USER_TO_BE_UPDATED_DATA =
  "@buyerInformation/CLEAR_SITE_USER_TO_BE_UPDATED_DATA";

export const FETCH_DEFAULT_PURCHASE_SETTINGS =
  "@buyerInformation/FETCH_DEFAULT_PURCHASE_SETTINGS";
export const FETCH_DEFAULT_PURCHASE_SETTINGS_SUCCESS =
  "@buyerInformation/FETCH_DEFAULT_PURCHASE_SETTINGS_SUCCESS";
export const FETCH_DEFAULT_PURCHASE_SETTINGS_FAILURE =
  "@buyerInformation/FETCH_DEFAULT_PURCHASE_SETTINGS_FAILURE";

export const UPDATE_DEFAULT_PURCHASE_SETTINGS =
  "@buyerInformation/UPDATE_DEFAULT_PURCHASE_SETTINGS";
export const UPDATE_DEFAULT_PURCHASE_SETTINGS_SUCCESS =
  "@buyerInformation/UPDATE_DEFAULT_PURCHASE_SETTINGS_SUCCESS";
export const UPDATE_DEFAULT_PURCHASE_SETTINGS_FAILURE =
  "@buyerInformation/UPDATE_DEFAULT_PURCHASE_SETTINGS_FAILURE";

export const FETCH_SHOWROOM_MODE_SETTINGS =
  "@buyerInformation/FETCH_SHOWROOM_MODE_SETTINGS";
export const FETCH_SHOWROOM_MODE_SETTINGS_SUCCESS =
  "@buyerInformation/FETCH_SHOWROOM_MODE_SETTINGS_SUCCESS";
export const FETCH_SHOWROOM_MODE_SETTINGS_FAILURE =
  "@buyerInformation/FFETCH_SHOWROOM_MODE_SETTINGS_FAILURE";
export const UPDATE_SHOWROOM_MODE_SETTINGS =
  "@buyerInformation/UPDATE_SHOWROOM_MODE_SETTINGS";
export const UPDATE_SHOWROOM_MODE_SETTINGS_SUCCESS =
  "@buyerInformation/UPDATE_SHOWROOM_MODE_SETTINGS_SUCCESS";
export const UPDATE_SHOWROOM_MODE_SETTINGS_FAILURE =
  "@buyerInformation/UPDATE_SHOWROOM_MODE_SETTINGS_FAILURE";

export const FETCH_SHOWROOM_MODE_SETTINGS_LOGO =
  "@buyerInformation/FETCH_SHOWROOM_MODE_SETTINGS_LOGO";
export const FETCH_SHOWROOM_MODE_SETTINGS_LOGO_SUCCESS =
  "@buyerInformation/FETCH_SHOWROOM_MODE_SETTINGS_LOGO_SUCCESS";
export const FETCH_SHOWROOM_MODE_SETTINGS_LOGO_FAILURE =
  "@buyerInformation/FETCH_SHOWROOM_MODE_LOGO_SETTINGS_FAILURE";

export const SAVE_SHOWROOM_MODE_SETTINGS_BLOCK_LOGO =
  "@buyerInformation/SAVE_SHOWROOM_MODE_SETTINGS_BLOCK_LOGO";
export const SAVE_SHOWROOM_MODE_SETTINGS_BLOCK_LOGO_SUCCESS =
  "@buyerInformation/SAVE_SHOWROOM_MODE_SETTINGS_BLOCK_LOGO_SUCCESS";
export const SAVE_SHOWROOM_MODE_SETTINGS_BLOCK_LOGO_FAILURE =
  "@buyerInformation/SAVE_SHOWROOM_MODE_SETTINGS_BLOCK_LOGO_FAILURE";

export const DELETE_SHOWROOM_MODE_SETTINGS_LOGO =
  "@buyerInformation/DELETE_SHOWROOM_MODE_SETTINGS_LOGO";
export const DELETE_SHOWROOM_MODE_SETTINGS_LOGO_SUCCESS =
  "@buyerInformation/DELETE_SHOWROOM_MODE_SETTINGS_LOGO_SUCCESS";
export const DELETE_SHOWROOM_MODE_SETTINGS_LOGO_FAILURE =
  "@buyerInformation/DELETE_SHOWROOM_MODE_SETTINGS_LOGO_FAILURE";

export const SET_FILE_ERROR = "@buyerInformation/SET_FILE_ERROR";
export const SAVE_SHOWROOM_MODE_SETTINGS_LOGO =
  "@buyerInformation/SAVE_SHOWROOM_MODE_SETTINGS_LOGO";

// Action creators
const fetchBuyerInfoSuccess = (data) => {
  return {
    type: FETCH_BUYER_INFO_SUCCESS,
    payload: data,
  };
};
const fetchBuyerInfoFailure = (error) => {
  return {
    type: FETCH_BUYER_INFO_FAILURE,
    payload: error,
  };
};

export const fetchBuyerInfo = () => (dispatch, getState) => {
  dispatch({ type: FETCH_BUYER_INFO });
  const instance = new GetBuyerInformation({
    credentials: getState().authentication.credentials,
  });
  instance.call().then(
    (response) => {
      dispatch(fetchBuyerInfoSuccess(response.data));
    },
    (err) => {
      return dispatch(fetchBuyerInfoFailure(err));
    },
  );
};

const fetchAccountInfoSuccess = (data) => {
  return {
    type: FETCH_ACCOUNT_INFO_SUCCESS,
    payload: data,
  };
};
const fetchAccountInfoFailure = (error) => {
  return {
    type: FETCH_ACCOUNT_INFO_FAILURE,
    payload: error,
  };
};

export const fetchAccountInfo = () => (dispatch, getState) => {
  dispatch({ type: FETCH_ACCOUNT_INFO });

  const instance = new GetAccountInformation({
    credentials: getState().authentication.credentials,
  });
  instance.call().then(
    (response) => {
      dispatch(fetchAccountInfoSuccess(response.data));
    },
    (err) => {
      return dispatch(fetchAccountInfoFailure(err));
    },
  );
};

export const updateBuyerInfoSuccess = (data) => {
  return {
    type: UPDATE_BUYER_INFO_SUCCESS,
  };
};

export const updateBuyerInfoFailure = (error) => {
  return {
    type: UPDATE_BUYER_INFO_FAILURE,
    payload: error,
  };
};

export const updateBuyerInfo = (data) => (dispatch, getState) => {
  dispatch({ type: UPDATE_BUYER_INFO });

  const instance = new UpdateBuyerInformation({
    params: data,
    credentials: getState().authentication.credentials,
  });

  instance.call().then(
    (response) => {
      dispatch(updateBuyerInfoSuccess(response.data));
    },
    (err) => {
      return dispatch(updateBuyerInfoFailure(err));
    },
  );
};

export const updateAccountInfoSuccess = (data) => {
  return {
    type: UPDATE_ACCOUNT_INFO_SUCCESS,
  };
};

export const updateAccountInfoFailure = (error) => {
  return {
    type: UPDATE_ACCOUNT_INFO_FAILURE,
    payload: error,
  };
};

export const updateAccountInfo = (data) => (dispatch, getState) => {
  dispatch({ type: UPDATE_ACCOUNT_INFO });

  const instance = new UpdateAccountInformation({
    params: data,
    credentials: getState().authentication.credentials,
  });

  instance.call().then(
    (response) => {
      dispatch(updateAccountInfoSuccess(response.data));
    },
    (err) => {
      return dispatch(updateAccountInfoFailure(err));
    },
  );
};

const fetchSiteUsersSuccess = (data) => {
  return {
    type: FETCH_SITE_USERS_SUCCESS,
    payload: data,
  };
};
const fetchSiteUsersFailure = (error) => {
  return {
    type: FETCH_SITE_USERS_FAILURE,
    payload: error,
  };
};

export const fetchSiteUsers = () => (dispatch, getState) => {
  dispatch({ type: FETCH_SITE_USERS });
  const instance = new GetSiteUsers({
    credentials: getState().authentication.credentials,
  });
  instance.call().then(
    (response) => {
      dispatch(fetchSiteUsersSuccess(response.data));
    },
    (err) => {
      return dispatch(fetchSiteUsersFailure(err));
    },
  );
};

export const updateSiteUserSuccess = (data) => {
  return {
    type: UPDATE_SITE_USER_INFO_SUCCESS,
    payload: data,
  };
};

export const updateSiteUserFailure = (error) => {
  return {
    type: UPDATE_SITE_USER_INFO_FAILURE,
    payload: error,
  };
};

export const updateSiteUser =
  (data, hasRemovedOwnAdminRight, navigate, token) => (dispatch, getState) => {
    dispatch({ type: UPDATE_SITE_USER_INFO });

    const instance = new UpdateSiteUser({
      params: data,
      credentials: getState().authentication.credentials,
    });

    instance.call().then(
      (response) => {
        dispatch(updateSiteUserSuccess(response.data));

        if (!data?.isActive) {
          dispatch(unsubscribePushNotification(null, null, true, data.userId));
        }

        if (!hasRemovedOwnAdminRight) {
          dispatch(fetchSiteUsers());
        } else {
          dispatch(fetchAccountAdministratorStatus(token));
          navigate("/");
        }
      },
      (err) => {
        return dispatch(updateSiteUserFailure(err));
      },
    );
  };

export const addNewSiteUserSuccess = (data) => {
  return {
    type: ADD_NEW_SITE_USER_SUCCESS,
    payload: data,
  };
};

export const addNewSiteUserFailure = (error) => {
  return {
    type: ADD_NEW_SITE_USER_FAILURE,
    payload: error,
  };
};

export const addNewSiteUser = (data) => (dispatch, getState) => {
  dispatch({ type: ADD_NEW_SITE_USER });

  const instance = new AddNewUser({
    params: data,
    credentials: getState().authentication.credentials,
  });

  instance.call().then(
    (response) => {
      dispatch(fetchSiteUsers());
      dispatch(addNewSiteUserSuccess(response.data));
    },
    (err) => {
      return dispatch(addNewSiteUserFailure(err));
    },
  );
};

export const fetchDefaultPurchaseSettings = (data) => (dispatch, getState) => {
  dispatch({ type: FETCH_DEFAULT_PURCHASE_SETTINGS });

  const instance = new GetDefaultPurchaseSettings({
    params: data,
    credentials: getState().authentication.credentials,
  });

  instance.call().then(
    (response) => {
      dispatch(fetchDefaultPurchaseSettingsSuccess(response.data));
    },
    (err) => {
      return dispatch(fetchDefaultPurchaseSettingsFailure(err));
    },
  );
};

export const fetchDefaultPurchaseSettingsSuccess = (data) => {
  return {
    type: FETCH_DEFAULT_PURCHASE_SETTINGS_SUCCESS,
    payload: data,
  };
};

export const fetchDefaultPurchaseSettingsFailure = (error) => {
  return {
    type: FETCH_DEFAULT_PURCHASE_SETTINGS_FAILURE,
    payload: error,
  };
};

export const updateDefaultPurchaseSettings = (data) => (dispatch, getState) => {
  dispatch({ type: UPDATE_DEFAULT_PURCHASE_SETTINGS });

  const instance = new UpdateDefaultPurchaseSettings({
    params: data,
    credentials: getState().authentication.credentials,
  });

  instance.call().then(
    (response) => {
      dispatch(updateDefaultPurchaseSettingsSuccess(response.data));
    },
    (err) => {
      return dispatch(updateDefaultPurchaseSettingsFailure(err));
    },
  );
};

export const updateDefaultPurchaseSettingsSuccess = (data) => {
  return {
    type: UPDATE_DEFAULT_PURCHASE_SETTINGS_SUCCESS,
  };
};

export const updateDefaultPurchaseSettingsFailure = (error) => {
  return {
    type: UPDATE_DEFAULT_PURCHASE_SETTINGS_FAILURE,
    payload: error,
  };
};

export const setAddressSelection = (selection) => (dispatch) => {
  dispatch({ type: SET_ADDRESS_SELECTION, payload: selection });
};

export const setBuyerAddress = (address) => (dispatch) => {
  dispatch({ type: SET_BUYER_ADDRESS, payload: address });
};

export const setAccountAddress = (address) => (dispatch) => {
  dispatch({ type: SET_ACCOUNT_ADDRESS, payload: address });
};

export const setBillingAddress = (address) => (dispatch) => {
  dispatch({ type: SET_BILLING_ADDRESS, payload: address });
};

export const setDefaultPurchaseAddress = (address) => (dispatch) => {
  dispatch({ type: SET_DEFAULT_PURCHASE_ADDRESS, payload: address });
};

export const setSiteUserToBeUpdated = (data) => (dispatch) => {
  dispatch({ type: SET_SITE_USER_TO_BE_UPDATED, payload: data });
};

export const clearSiteUserToBeUpdatedData = () => {
  return {
    type: CLEAR_SITE_USER_TO_BE_UPDATED_DATA,
  };
};

export const clearSiteUsersStatus = () => {
  return {
    type: CLEAR_SITE_USERS_STATUS,
  };
};

export const fetchShowroomModeSettings = (data) => (dispatch, getState) => {
  dispatch({ type: FETCH_SHOWROOM_MODE_SETTINGS });

  const instance = new GetShowroomModeSettings({
    params: data,
    credentials: getState().authentication.credentials,
  });

  instance.call().then(
    (response) => {
      dispatch(fetchShowroomModeSettingsSuccess(response.data));
      dispatch(fetchShowroomModeSettingsLogo(USER_TYPE_MAP.Buyer));
    },
    (err) => {
      return dispatch(fetchShowroomModeSettingsFailure(err));
    },
  );
};

export const fetchShowroomModeSettingsSuccess = (data) => {
  return {
    type: FETCH_SHOWROOM_MODE_SETTINGS_SUCCESS,
    payload: data,
  };
};

export const fetchShowroomModeSettingsFailure = (error) => {
  return {
    type: FETCH_SHOWROOM_MODE_SETTINGS_FAILURE,
    payload: error,
  };
};

export const updateShowroomModeSettings = (data) => (dispatch, getState) => {
  dispatch({ type: UPDATE_SHOWROOM_MODE_SETTINGS });
  const instance = new UpdateShowroomModeSettings({
    params: data,
    credentials: getState().authentication.credentials,
  });

  instance.call().then(
    (response) => {
      dispatch(updateShowroomModeSettingsSuccess(response.data));
    },
    (err) => {
      return dispatch(updateShowroomModeSettingsFailure(err));
    },
  );
};

export const updateShowroomModeSettingsSuccess = (data) => {
  return {
    type: UPDATE_SHOWROOM_MODE_SETTINGS_SUCCESS,
  };
};

export const updateShowroomModeSettingsFailure = (error) => {
  return {
    type: UPDATE_SHOWROOM_MODE_SETTINGS_FAILURE,
    payload: error,
  };
};

export const deleteShowroomModeSettingsLogo =
  (fileName, userType, Type) => (dispatch, getState) => {
    dispatch({ type: DELETE_SHOWROOM_MODE_SETTINGS_LOGO });

    const instance = new DeleteShowroomModeSettingsLogo({
      params: { fileName, userType, Type },
      credentials: getState().authentication.credentials,
    });

    instance.call().then(
      (response) => {
        dispatch(deleteShowroomModeSettingsLogoSuccess(response, fileName));
      },
      (err) => {
        return dispatch(deleteShowroomModeSettingsLogoFailure(err));
      },
    );
  };

export const deleteShowroomModeSettingsLogoSuccess = (data, fileName) => {
  return {
    type: DELETE_SHOWROOM_MODE_SETTINGS_LOGO_SUCCESS,
    payload: { data, fileName },
  };
};

export const deleteShowroomModeSettingsLogoFailure = (error) => {
  return {
    type: DELETE_SHOWROOM_MODE_SETTINGS_LOGO_FAILURE,
    payload: error,
  };
};

export const saveShowroomModeSettingsLogo = (file, keyValue) => {
  const extendedData = extendShowroomModeSettingsLogo(file, keyValue);
  return {
    type: SAVE_SHOWROOM_MODE_SETTINGS_LOGO,
    payload: extendedData,
  };
};

const extendShowroomModeSettingsLogo = (file, keyValue) => {
  const blockSize = 1048576 * 4; //it's 4MB
  const addForRemainder = file?.size % blockSize === 0 ? 0 : 1;
  const noOfBlocks = Math.floor(file?.size / blockSize) + addForRemainder;

  return {
    file: file,
    fileName: file?.name ?? keyValue?.key,
    progress: 0,
    beginingOfTheBlock: 0,
    endOfTheBlock: blockSize,
    noOfBlocks: noOfBlocks || 1,
    blockNo: 1,
    uploaded: !!keyValue?.value,
    url: keyValue?.value,
  };
};

export const saveShowroomModeSettingsBlockLogo =
  (data) => (dispatch, getState) => {
    dispatch({ type: SAVE_SHOWROOM_MODE_SETTINGS_BLOCK_LOGO });

    const instance = new SaveShowroomModeSettingsBlockLogo({
      params: data,
      credentials: getState().authentication.credentials,
    });

    instance.call().then(
      (response) => {
        dispatch(saveShowroomModeSettingsBlockLogoSuccess(response, data));
      },
      (err) => {
        if (err.toString().includes("403")) {
          dispatch({
            type: SET_FILE_ERROR,
            payload: { fileName: data.fileName, error: err },
          });
        } else {
          return dispatch(saveShowroomModeSettingsBlockLogoFailure(err));
        }
      },
    );
  };

export const saveShowroomModeSettingsBlockLogoSuccess = (
  response,
  data,
  fileName,
) => {
  return {
    type: SAVE_SHOWROOM_MODE_SETTINGS_BLOCK_LOGO_SUCCESS,
    payload: { response, data, fileName },
  };
};

export const saveShowroomModeSettingsBlockLogoFailure = (error) => {
  return {
    type: SAVE_SHOWROOM_MODE_SETTINGS_BLOCK_LOGO_FAILURE,
    payload: { error },
  };
};

export const fetchShowroomModeSettingsLogo =
  (userType) => (dispatch, getState) => {
    dispatch({ type: FETCH_SHOWROOM_MODE_SETTINGS_LOGO });
    const buyerInstance = new FetchSupportingDocumentsForBuyer({
      params: { userType },
      credentials: getState().authentication.credentials,
    });
    buyerInstance.call().then(
      (response) => {
        response.data.forEach((keyValuePair) => {
          dispatch(saveShowroomModeSettingsLogo(null, keyValuePair));
        });
        dispatch({ type: FETCH_SHOWROOM_MODE_SETTINGS_LOGO_SUCCESS });
      },
      (err) => {
        dispatch(fetchShowroomModeSettingsLogoFailure(err));
      },
    );
  };

export const fetchShowroomModeSettingsLogoFailure = (error) => {
  return {
    type: FETCH_SHOWROOM_MODE_SETTINGS_LOGO_FAILURE,
    payload: error,
  };
};

const initialState = {
  buyerInfo: {
    isFetching: false,
    isUpdating: false,
    error: false,
    data: {},
  },
  accountInfo: {
    isFetching: false,
    isUpdating: false,
    error: false,
    data: {},
  },
  siteUsers: {
    isFetching: false,
    isUpdating: false,
    isInserting: false,
    isSiteUserUpdateSuccessful: null,
    isSiteUserInsertSuccessful: null,
    siteUserToBeUpdated: {},
    preselectedUserId: null,
    error: false,
    data: {},
  },
  defaultPurchaseSettings: {
    isFetching: false,
    isUpdating: false,
    error: false,
    data: {},
  },
  showroomModeSettings: {
    isFetching: false,
    isUpdating: false,
    isDeletingShowroomModeLogo: false,
    isFetchingShowroomModeLogo: false,
    error: false,
    data: {
      showroomModeLogo: [],
    },
  },

  addressSelection: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BUYER_INFO:
      return {
        ...state,
        buyerInfo: {
          ...state.buyerInfo,
          isFetching: true,
        },
      };
    case FETCH_BUYER_INFO_SUCCESS:
      return {
        ...state,
        buyerInfo: {
          ...state.buyerInfo,
          isFetching: false,
          data: action.payload,
        },
      };
    case FETCH_BUYER_INFO_FAILURE:
      return {
        ...state,
        buyerInfo: {
          ...state.buyerInfo,
          isFetching: false,
          error: action.payload,
        },
      };
    case FETCH_ACCOUNT_INFO:
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          isFetching: true,
        },
      };
    case FETCH_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          isFetching: false,
          data: action.payload,
        },
      };
    case FETCH_ACCOUNT_INFO_FAILURE:
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          isFetching: false,
          error: action.payload,
        },
      };

    case UPDATE_BUYER_INFO:
      return {
        ...state,
        buyerInfo: {
          ...state.buyerInfo,
          isUpdating: true,
        },
      };
    case UPDATE_BUYER_INFO_SUCCESS:
      return {
        ...state,
        buyerInfo: {
          ...state.buyerInfo,
          isUpdating: false,
        },
      };
    case UPDATE_BUYER_INFO_FAILURE:
      return {
        ...state,
        buyerInfo: {
          ...state.buyerInfo,
          isUpdating: false,
          error: action.payload,
        },
      };
    case UPDATE_ACCOUNT_INFO:
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          isUpdating: true,
        },
      };
    case UPDATE_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          isUpdating: false,
        },
      };
    case UPDATE_ACCOUNT_INFO_FAILURE:
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          isUpdating: false,
          error: action.payload,
        },
      };
    case FETCH_SITE_USERS:
      return {
        ...state,
        siteUsers: {
          ...state.siteUsers,
          isFetching: true,
        },
      };
    case FETCH_SITE_USERS_SUCCESS:
      return {
        ...state,
        siteUsers: {
          ...state.siteUsers,
          isFetching: false,
          data: action.payload,
        },
      };
    case FETCH_SITE_USERS_FAILURE:
      return {
        ...state,
        siteUsers: {
          ...state.siteUsers,
          isFetching: false,
          error: action.payload,
        },
      };

    case UPDATE_SITE_USER_INFO:
      return {
        ...state,
        siteUsers: {
          ...state.siteUsers,
          isUpdating: true,
        },
      };
    case UPDATE_SITE_USER_INFO_SUCCESS:
      return {
        ...state,
        siteUsers: {
          ...state.siteUsers,
          isSiteUserUpdateSuccessful: true,
          preselectedUserId: action.payload,
          isUpdating: false,
        },
      };
    case UPDATE_SITE_USER_INFO_FAILURE:
      return {
        ...state,
        siteUsers: {
          ...state.siteUsers,
          isUpdating: false,
          isSiteUserUpdateSuccessful: false,
          error: action.payload,
        },
      };
    case ADD_NEW_SITE_USER:
      return {
        ...state,
        siteUsers: {
          ...state.siteUsers,
          isInserting: true,
        },
      };
    case ADD_NEW_SITE_USER_SUCCESS:
      return {
        ...state,
        siteUsers: {
          ...state.siteUsers,
          isSiteUserInsertSuccessful: true,
          preselectedUserId: action.payload,
          isInserting: false,
        },
      };
    case ADD_NEW_SITE_USER_FAILURE:
      return {
        ...state,
        siteUsers: {
          ...state.siteUsers,
          isInserting: false,
          isSiteUserInsertSuccessful: false,
          error: action.payload,
        },
      };
    case CLEAR_SITE_USERS_STATUS:
      return {
        ...state,
        siteUsers: {
          ...state.siteUsers,
          isSiteUserUpdateSuccessful: null,
          isSiteUserInsertSuccessful: null,
        },
      };
    case CLEAR_SITE_USER_TO_BE_UPDATED_DATA:
      return {
        ...state,
        siteUsers: {
          ...state.siteUsers,
          siteUserToBeUpdated: {},
        },
      };
    case SET_ADDRESS_SELECTION:
      return {
        ...state,
        addressSelection: action.payload,
      };
    case SET_BUYER_ADDRESS:
      return {
        ...state,
        buyerInfo: {
          ...state.buyerInfo,
          data: {
            ...state.buyerInfo.data,
            postalAddress: action.payload.postalAddress,
            postCode: action.payload.postCode,
          },
        },
      };
    case SET_ACCOUNT_ADDRESS:
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          data: {
            ...state.accountInfo.data,
            postalAddress: action.payload,
          },
        },
      };
    case SET_BILLING_ADDRESS:
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          data: {
            ...state.accountInfo.data,
            invoiceAddress: action.payload,
          },
        },
      };
    case SET_DEFAULT_PURCHASE_ADDRESS:
      return {
        ...state,
        defaultPurchaseSettings: {
          ...state.defaultPurchaseSettings,
          data: {
            ...state.defaultPurchaseSettings.data,
            defaultAddress: action.payload,
          },
        },
      };
    case SET_SITE_USER_TO_BE_UPDATED:
      return {
        ...state,
        siteUsers: {
          ...state.siteUsers,
          siteUserToBeUpdated: action.payload,
        },
      };
    case FETCH_DEFAULT_PURCHASE_SETTINGS:
      return {
        ...state,
        defaultPurchaseSettings: {
          ...state.defaultPurchaseSettings,
          isFetching: true,
        },
      };
    case FETCH_DEFAULT_PURCHASE_SETTINGS_SUCCESS:
      return {
        ...state,
        defaultPurchaseSettings: {
          ...state.defaultPurchaseSettings,
          isFetching: false,
          data: action.payload,
        },
      };
    case FETCH_DEFAULT_PURCHASE_SETTINGS_FAILURE:
      return {
        ...state,
        defaultPurchaseSettings: {
          ...state.defaultPurchaseSettings,
          isFetching: false,
          error: action.payload,
        },
      };
    case UPDATE_DEFAULT_PURCHASE_SETTINGS:
      return {
        ...state,
        defaultPurchaseSettings: {
          ...state.defaultPurchaseSettings,
          isUpdating: true,
        },
      };
    case UPDATE_DEFAULT_PURCHASE_SETTINGS_SUCCESS:
      return {
        ...state,
        defaultPurchaseSettings: {
          ...state.defaultPurchaseSettings,
          isUpdating: false,
        },
      };
    case UPDATE_DEFAULT_PURCHASE_SETTINGS_FAILURE:
      return {
        ...state,
        defaultPurchaseSettings: {
          ...state.defaultPurchaseSettings,
          isUpdating: false,
          error: action.payload,
        },
      };
    case FETCH_SHOWROOM_MODE_SETTINGS:
      return {
        ...state,
        showroomModeSettings: {
          ...state.showroomModeSettings,
          isFetching: true,
        },
      };
    case FETCH_SHOWROOM_MODE_SETTINGS_SUCCESS:
      return {
        ...state,
        showroomModeSettings: {
          ...state.showroomModeSettings,
          isFetching: false,
          data: action.payload,
        },
      };
    case FETCH_SHOWROOM_MODE_SETTINGS_FAILURE:
      return {
        ...state,
        showroomModeSettings: {
          ...state.showroomModeSettings,
          isFetching: false,
          error: action.payload,
        },
      };
    case UPDATE_SHOWROOM_MODE_SETTINGS:
      return {
        ...state,
        showroomModeSettings: {
          ...state.showroomModeSettings,
          isUpdating: true,
        },
      };
    case UPDATE_SHOWROOM_MODE_SETTINGS_SUCCESS:
      return {
        ...state,
        showroomModeSettings: {
          ...state.showroomModeSettings,
          isUpdating: false,
        },
      };
    case UPDATE_SHOWROOM_MODE_SETTINGS_FAILURE:
      return {
        ...state,
        showroomModeSettings: {
          ...state.showroomModeSettings,
          isUpdating: false,
          error: action.payload,
        },
      };
    case SAVE_SHOWROOM_MODE_SETTINGS_LOGO:
      return {
        ...state,
        showroomModeSettings: {
          ...state.showroomModeSettings,
          data: {
            ...state.showroomModeSettings.data,
            showroomModeLogo: [
              ...(state.showroomModeSettings.data.showroomModeLogo || []),
              action.payload,
            ],
          },
        },
      };
    case DELETE_SHOWROOM_MODE_SETTINGS_LOGO:
      return {
        ...state,
        showroomModeSettings: {
          ...state.showroomModeSettings,
          isDeletingShowroomModeLogo: true,
        },
      };
    case DELETE_SHOWROOM_MODE_SETTINGS_LOGO_SUCCESS:
      return {
        ...state,
        showroomModeSettings: {
          ...state.showroomModeSettings,
          isDeletingShowroomModeLogo: false,
          data: {
            ...state.showroomModeSettings.data,
            showroomModeLogo:
              state.showroomModeSettings.data.showroomModeLogo.filter(
                (document) => document.fileName !== action.payload.fileName,
              ),
          },
        },
      };
    case DELETE_SHOWROOM_MODE_SETTINGS_LOGO_FAILURE:
      return {
        ...state,
        showroomModeSettings: {
          ...state.showroomModeSettings,
          isDeletingShowroomModeLogo: false,
        },
      };
    case SAVE_SHOWROOM_MODE_SETTINGS_BLOCK_LOGO:
      return {
        ...state,
        showroomModeSettings: {
          ...state.showroomModeSettings,
          isUpdating: true,
        },
      };
    case SAVE_SHOWROOM_MODE_SETTINGS_BLOCK_LOGO_SUCCESS:
      return {
        ...state,
        error: false,
        showroomModeSettings: {
          ...state.showroomModeSettings,
          isUpdating: false,
          data: {
            ...state.showroomModeSettings.data,
            showroomModeLogo:
              state.showroomModeSettings.data.showroomModeLogo.map(
                (document) =>
                  document.fileName === action.payload.data.fileName
                    ? {
                        ...document,
                        beginingOfTheBlock:
                          document.beginingOfTheBlock +
                          action.payload.data.blockSize,
                        endOfTheBlock:
                          document.endOfTheBlock +
                          action.payload.data.blockSize,
                        blockNo: document.blockNo + 1,
                        progress: action.payload.data.percentage,
                        uploaded: action.payload.data.finalBlock,
                        url: action.payload.response?.data?.value,
                        duplicateDocument: false,
                      }
                    : document,
              ),
          },
        },
      };
    case SET_FILE_ERROR:
      return {
        ...state,
        error: false,
        showroomModeSettings: {
          ...state.showroomModeSettings,
          isUpdating: false,
          data: {
            ...state.showroomModeSettings.data,
            showroomModeLogo:
              state.showroomModeSettings.data.showroomModeLogo.map(
                (document) =>
                  document.fileName === action.payload.fileName
                    ? {
                        ...document,
                        duplicateDocument: true,
                      }
                    : document,
              ),
          },
        },
      };
    case SAVE_SHOWROOM_MODE_SETTINGS_BLOCK_LOGO_FAILURE:
      return {
        ...state,
        showroomModeSettings: {
          ...state.showroomModeSettings,
          isUpdating: false,
        },
      };
    case FETCH_SHOWROOM_MODE_SETTINGS_LOGO:
      return {
        ...state,
        showroomModeSettings: {
          ...state.showroomModeSettings,
          isFetchingShowroomModeLogo: true,
        },
      };
    case FETCH_SHOWROOM_MODE_SETTINGS_LOGO_FAILURE:
      return {
        ...state,
        showroomModeSettings: {
          ...state.showroomModeSettings,
          isFetchingShowroomModeLogo: false,
        },
      };
    case FETCH_SHOWROOM_MODE_SETTINGS_LOGO_SUCCESS:
      return {
        ...state,
        showroomModeSettings: {
          ...state.showroomModeSettings,
          isFetchingShowroomModeLogo: false,
        },
      };
    default:
      return state;
  }
};

export default reducer;
