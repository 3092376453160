import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import Button from "../../components/Button";
import ModalInsert from "../../components/ModalInsert";
import ClaimActivityLog from "../../components/ClaimActivityLog";
import breakpoints from "../../theme/breakpoints";
import { useMediaQuery } from "react-responsive";
import SkeletonRows from "../../components/Skeletons/SkeletonRows";

const ClaimActivityLogModalInsert = ({className}) => {
  const claimActivityLog = useSelector(
    (state) => state.claimActivityLog.results
  );

  const claimId = useSelector((state) => state.claims.activeClaim.data.claimId);

  const dispatch = useDispatch();

  const onCancel = () => {
    dispatch(closeModal());
  };

  const isFetching = useSelector((state) => state.claimActivityLog.isFetching);

  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  return (
    <div className={className}>
      <ModalInsert width={isDesktop ? "96rem" : "100%"}>
        {isFetching ? (
          <SkeletonRows nrOfRows={10} />
        ) : (
          <ClaimActivityLog
            claimId={claimId}
            claimActivityLog={claimActivityLog}
          ></ClaimActivityLog>
        )}
        <div className="button__wrapper">
          <Button
            action={() => onCancel()}
            secondary
            content={"Close"}
          ></Button>
        </div>
      </ModalInsert>
    </div>
  );
};

export default ClaimActivityLogModalInsert;
