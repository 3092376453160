import React, { useContext, useState, useEffect } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

import Icon from "../Icon";
import Row from "../Row";
import NavigationItem from "../NavigationItem";
import SettingsMenu from "../SettingsMenu";
import BurgerMenu from "../BurgerMenu";
import { useSelector } from "react-redux";

const Navigation = ({
  className,
  messagesCount,
  onStockListDownload,
  onAboutSession,
  onLogout,
  isAccountAdministrator,
  navIsOpen,
  buyerOnly,
}) => {
  const theme = useContext(ThemeContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const allowPushNotifications = useSelector(
    (state) => state.authentication.meta.userDefaults.allowPushNotifications
  );

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    const html = document.getElementsByTagName("html")[0];

    if (menuOpen) {
      body.classList.toggle("modal--open");
      html.style.overflow = "hidden";
    } else {
      html.style.overflow = "initial";
    }

    return () => {
      if (menuOpen) {
        body.classList.toggle("modal--open");
        html.style.overflow = "hidden";
        setMenuOpen(false);
      } else {
        html.style.overflow = "initial";
      }
    };
  }, [menuOpen]);

  useEffect(() => {
    if (navIsOpen !== undefined) {
      navIsOpen(menuOpen);
    }
  }, [menuOpen, navIsOpen]);

  const logosMap = {
    TradeBuyer: {
      type: "logo-white",
    },
    Seat: {
      type: "logo-seat",
    },
    Volkswagen: {
      type: "logo-vw",
    },
    VolkswagenCommercial: {
      type: "logo-vwcv",
    },
    Motability: {
      type: "logo-mfl",
    },
  };

  const hasIconLogo = theme.name !== "Audi" && theme.name !== "Skoda";

  return (
    <section className={classnames("qa-page-header", className)}>
      <div className="navigation__container">
        <Row>
          <div className="navigation__wrapper">
            {hasIconLogo && (
              <span className="navigation__logo qa-page-header-logo">
                <Icon {...logosMap[theme.name]} />
              </span>
            )}
            {!hasIconLogo && (
              <span className="navigation__logo__image">
                <img src={theme.IMAGES.LOGO} alt={`${theme.name} logo`} />
              </span>
            )}
            <div
              className="navigation__wrapper__nav-items"
              data-open={menuOpen}
            >
              <NavigationItem
                icon={{
                  type: "search",
                  strokeColour: theme.COLOURS.WHITE,
                  strokeWidth: "3",
                  width: "20",
                  height: "20",
                }}
                url="/"
              >
                Find Vehicles
              </NavigationItem>
              <NavigationItem
                icon={{
                  type: "speech-bubble",
                  strokeColour: theme.COLOURS.WHITE,
                  strokeWidth: "3",
                  width: "20",
                  height: "20",
                }}
                notificationCount={messagesCount}
                url="/messages"
              >
                Messages
              </NavigationItem>
              <NavigationItem
                icon={{
                  type: "star",
                  strokeColour: theme.COLOURS.WHITE,
                  strokeWidth: "3",
                  width: "20",
                  height: "20",
                }}
                url="/shortlist"
              >
                Shortlist
              </NavigationItem>
              <NavigationItem
                icon={{
                  type: "hammer",
                }}
                url="/bids-and-requests"
              >
                Bids & Requests
              </NavigationItem>
              <NavigationItem
                icon={{
                  type: "purchase",
                }}
                url="/purchases/archive"
              >
                Purchases
              </NavigationItem>
              {/* Settings menu on mobile */}
              <NavigationItem
                icon={{
                  type: "settings",
                  strokeColour: theme.COLOURS.WHITE,
                  strokeWidth: "3",
                  width: "20",
                  height: "20",
                }}
                action={() => setSettingsOpen(!settingsOpen)}
                hasChildren
                mobile
              >
                Settings
              </NavigationItem>
              {settingsOpen && (
                <SettingsMenu
                  className="settings-menu"
                  onStockListDownload={onStockListDownload}
                  onAboutSession={onAboutSession}
                  onLogout={onLogout}
                  isAccountAdministrator={isAccountAdministrator}
                  allowPushNotifications={allowPushNotifications}
                  inline
                  buyerOnly={buyerOnly}
                />
              )}
            </div>
            <SettingsMenu
              className="settings-menu"
              onStockListDownload={onStockListDownload}
              onAboutSession={onAboutSession}
              onLogout={onLogout}
              isAccountAdministrator={isAccountAdministrator}
              buyerOnly={buyerOnly}
              allowPushNotifications={allowPushNotifications}
            />
            <BurgerMenu
              className={classnames(
                "settings-burger-menu",
                "qa-settings-burger-menu"
              )}
              open={menuOpen}
              onClick={() => setMenuOpen(!menuOpen)}
              notificationCount={messagesCount}
            />
          </div>
        </Row>
      </div>
    </section>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
  messageCount: PropTypes.string,
  onStockListDownload: PropTypes.func,
  onAboutSession: PropTypes.func,
  onLogout: PropTypes.func,
  isAccountAdministrator: PropTypes.bool,
};

export default Navigation;
