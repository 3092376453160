import React from "react";
import ModalInsert from "../../components/ModalInsert";
import NewClaimRegistrationSearch from "../NewClaimRegistrationSearch";
import Panel from "../../components/Panel";
import ClaimVehicleSearchModal from "../../components/ClaimVehicleSearchModal";
import { closeModal } from "../../../state/reducers/global";
import { clearRegRequest } from "../../../state/reducers/claims";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import breakpoints from "../../theme/breakpoints";
import { useMediaQuery } from "react-responsive";

const ClaimsAdminRegSearchModalInsert = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isFetching, value } = useSelector((state) => state.claims.regSearch);
  const vehicleDetails = useSelector((state) => state.claims.regSearch.data);

  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  let make,
    model,
    derivative,
    vehiclePurchaseId,
    claimId,
    registrationNotFound,
    helpCentreTelNo,
    regSearchStatus;

  if (!isFetching && value) {
    if (vehicleDetails) {
      vehiclePurchaseId = vehicleDetails.vehiclePurchaseId;
      claimId = vehicleDetails.claimId;
      make = vehicleDetails.make;
      model = vehicleDetails.model;
      derivative = vehicleDetails.derivative;
      regSearchStatus = vehicleDetails.regSearchStatus;
      helpCentreTelNo = vehicleDetails.helpCentreTelNo;
    }

    if (!vehicleDetails) {
      registrationNotFound = true;
    }
  }

  const onCancel = () => {
    dispatch(clearRegRequest());
    dispatch(closeModal());
  };

  const onContinue = (id) => {
    dispatch(clearRegRequest());
    if (id) {
      navigate(`/claim/${id}`, {
        state: claimId ? { claimId, id } : null
      });
    }
  };

  const disabled = claimId
    ? false
    : vehiclePurchaseId && regSearchStatus === "ClaimsEnabled"
      ? false
      : true;

  return (
    <ModalInsert width={isDesktop ? "96rem" : "100%"}>
      <Panel
        mobileFlex
        style={{ height: "100%", borderRadius: "0.5rem" }}
        actions={[
          {
            content: "Cancel",
            action: onCancel,
            type: "ghost",
          },
          {
            content: claimId ? "View Claim" : "Raise Claim",
            action: () => onContinue(claimId || vehiclePurchaseId),
            type: "primary",
            disabled: disabled,
          },
        ]}
      >
        <ClaimVehicleSearchModal
          make={make}
          model={model}
          derivative={derivative}
          onCancel={onCancel}
          onContinue={() => onContinue(vehiclePurchaseId)}
          registrationNotFound={registrationNotFound}
          regSearchStatus={regSearchStatus}
          helpCentreTelNo={helpCentreTelNo}
        >
          <NewClaimRegistrationSearch />
        </ClaimVehicleSearchModal>
      </Panel>
    </ModalInsert>
  );
};

export default ClaimsAdminRegSearchModalInsert;
