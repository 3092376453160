import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const ExclamationMark = ({ width, height, strokeColour }) => {
  const theme = useContext(ThemeContext);
  const fillColour = strokeColour ? strokeColour : theme.COLOURS.ACCENT.base;
  const fontSize = width;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
      <text
        x="50%"
        y="65%"
        dominantBaseline="middle"
        textAnchor="middle"
        fill={fillColour}
        fontFamily="'Lato',Arial,Helvetica,sans-serif;"
        fontSize={fontSize * 1.3}
        fontWeight="700"
        xmlSpace="preserve"
      >
        !
      </text>
    </svg>
  );
};

ExclamationMark.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

ExclamationMark.defaultProps = {
  width: "30",
  height: "30",
};

export default ExclamationMark;
