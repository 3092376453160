export const claimTableHeaders = {
  REG_NUMBER: "Reg Number",
  OPENED: "Opened",
  REQUESTED: "Requested",
  TIME: "Time",
  LAST_STATUS_UPDATE: "Last Status Update",
  REVIEW_COMPLETED_BY: "Review Completed by",
  TRADE_BUYER: "Trade Buyer",
  CLAIM_CATEGORY: "Claim Category",
  MANAGED_BY: "Managed By",
  RESPONSE_DUE: "Response Due",
  STATUS: "Status",
  ACTION: "Action",
  REVIEW_COMPLETE: "Review Complete",
  COMPLETED: "Completed",
  RESOLUTION: "Resolution",
  AMOUNT: "Amount",
  ACCEPTED_OFFER: "Accepted Offer",
  ACCEPTED_DATE: "Accepted Date",
};

export const claimOptionText = (value) => {
  return value ? value : "Take Ownership";
};

export const campaignTableHeaders = {
  CAMPAIGN_REFERENCE: "Reference",
  CAMPAIGN_NAME: "Name",
  START_DATE: "Start Date",
  END_DATE: "End Date",
  SALE_DAYS_FROM: "Days From",
  SALE_DAYS_TO: "Days To",
  ACTIONS: "Actions",
  ACTION: "Action",
};
