import Api from "./Base";
import qs from "qs";
import clientSettings from "../clientappsettings.json";

class AddressLookupApi extends Api {
  baseUrl = "https://services.postcodeanywhere.co.uk/Capture/Interactive/";
  withCredentials = false;

  getQueryParams() {
    let data = null;
    if (this.options.type === "FIND_REQUEST") {
      data = {
        Key: clientSettings.LOQATE_KEY,
        Text: this.options.params.text,
        Container: this.options.params.container,
        Countries: "GBR",
        Limit: 10,
        Language: "en-gb"
      };
    }
    if (this.options.type === "CAPTURE_REQUEST") {
      data = {
        Key: clientSettings.LOQATE_KEY,
        Id: this.options.params.id
      };
    }
    return qs.stringify(data);
  }

  getEndpointUrl() {
    if (this.options.type === "FIND_REQUEST") {
      this.requestUrl = "Find/v1.10/json3ex.ws?";
    }
    if (this.options.type === "CAPTURE_REQUEST") {
      this.requestUrl = "Retrieve/v1.00/json3ex.ws?";
    }
    return `${this.baseUrl}${this.requestUrl}${this.getQueryParams()}`;
  }

  formatResponseData(response) {
    return response.data ? response.data : response;
  }
}

export default AddressLookupApi;
