import Api from "./Base";
import settings from "../clientappsettings.json";
import { getStocklistFilename } from "../shared/utility";

export class GetStocklist extends Api {
  baseUrl = settings.API_URL;
  requestUrl = `/search/stocklist`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };

  formatResponseData(response) {
    if (response.data) {
      //downloading the stocklist.csv
      //TODO - is there a cleaner solution to handle this?
      const blob = new Blob([response.data], { type: "application/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = getStocklistFilename();
      link.click();
    }

    return response;
  }
}
