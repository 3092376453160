import VendorReviewCompleteConfirmation from "./VendorReviewCompleteConfirmation";
import styled from "styled-components";

const StyledReviewCompleteConfirmation = styled(VendorReviewCompleteConfirmation)`
  .confirmation-container {
    min-height: 40vh;
    max-height: 45vh;
    width: 75rem;
    display: flex;
    margin: 0 3rem 0 3rem;
    justify-content: center;
    align-items: center
  }
`;

export default StyledReviewCompleteConfirmation;
