import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Dash = ({ width, height, strokeColour, strokeWidth }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.ORANGE.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 3"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="none"
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-935.000000, -308.000000)"
          stroke={stroke}
          strokeWidth={strokeWidth}
        >
          <line x1="956" y1="309.888889" x2="936" y2="309.888889" />
        </g>
      </g>
    </svg>
  );
};

Dash.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

Dash.defaultProps = {
  width: "18",
  height: "18",
  strokeWidth: "2"
};

export default Dash;
