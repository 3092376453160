// Utility file for google analytics

import dayjs from "dayjs";
import { parseToken } from "./utility";

export const isMotabilityTheme = (themeName) => {
  return themeName === "Motability";
};

export const gADataLayerPushMotability = (themeName, object) => {
  if (isMotabilityTheme(themeName)) {
    window.dataLayerMotability = window.dataLayerMotability || [];
    window.dataLayerMotability.push(object);
  }
};

export const getDealerCodeFromToken = (token) => {
  if (token) {
    const { DealerCode } = parseToken(token);
    return DealerCode ? `${DealerCode}` : null;
  }
  return null;
};

export const getBuyingTypeStringForGAMotability = (
  allowBid,
  bidStartPrice,
  allowBuyNow,
  buyNowPrice
) => {
  if (allowBid && bidStartPrice && allowBuyNow && buyNowPrice) {
    return "Bid|Buy";
  }
  if (allowBid && bidStartPrice) {
    return "Bid";
  }
  if (allowBuyNow && buyNowPrice) {
    return "Buy";
  }
  return null;
};

export const getMileageBandForGAMotability = (odometer) => {
  if (odometer) {
    if (odometer < 5000) {
      return "Under 5000";
    }
    if (odometer >= 5000 && odometer < 10000) {
      return "5000-9999";
    }
    if (odometer >= 10000 && odometer < 20000) {
      return "10000-19999";
    }
    if (odometer >= 20000 && odometer < 30000) {
      return "20000-29999";
    }
    if (odometer >= 30000 && odometer < 40000) {
      return "30000-39999";
    }
    if (odometer >= 40000) {
      return "40000 Or Over";
    }
  }
  return null;
};

export const pushAddToCartEventToGAMotability = (
  themeName,
  data,
  token,
  vendorId,
  bidPrice,
  isVehicleCard
) => {
  if (isMotabilityTheme(themeName) && data) {
    const dataLayerObject = {
      event: "addToCart",
      ecommerce: {
        currencyCode: "GBP",
        add: {
          products: [
            {
              name: `${data.titles.make} ${data.titles.range} ${data.titles.derivative}`,
              id: null,
              price: bidPrice
                ? `${bidPrice}`
                : data.buyingOptions.buyNowPrice
                ? `${data.buyingOptions.buyNowPrice}`
                : null,
              category: data.titles.make,
              variant: data.details.colour,
              quantity: "1",
              dimension1: data.details.dateOfRegistration
                ? `${dayjs(data.details.dateOfRegistration).format(
                    "DD/MM/YYYY"
                  )}`
                : null,
              dimension2: isVehicleCard
                ? data.titles.regNo
                : data.details.regNo,
              dimension3: getMileageBandForGAMotability(data.details.odometer),
              dimension4: data.details.transmission,
              dimension5: data.details.fuelType,
              dimension6: data.keyInfo.damagePercentage
                ? `${data.keyInfo.damagePercentage}`
                : null,
              dimension7: data.vendorInfo && data.vendorInfo.vehicleLocation,
              dimension8: getDealerCodeFromToken(token),
              dimension11: getBuyingTypeStringForGAMotability(
                data.buyingOptions.allowBid,
                data.buyingOptions.bidStartPrice,
                data.buyingOptions.allowBuyNow,
                data.buyingOptions.buyNowPrice
              ),
              dimension12: `${vendorId}${dayjs(
                data.buyingOptions.endDateTime
              ).format("YYYYMMDDHHmm")}`,
              metric1:
                data.buyingOptions.allowBuyNow && data.buyingOptions.buyNowPrice
                  ? `${data.buyingOptions.buyNowPrice}`
                  : null,
            },
          ],
        },
      },
    };

    window.dataLayerMotability = window.dataLayerMotability || [];
    window.dataLayerMotability.push(dataLayerObject);
  }
};

export const pushCheckoutEventToGAMotability = (
  themeName,
  data,
  token,
  vendorId,
  stepNumber
) => {
  if (isMotabilityTheme(themeName) && data) {
    const dataLayerObject = {
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: {
            step: `${stepNumber}`,
            option: `${data.saleType}`,
          },
          products: [
            {
              name: `${data.make} ${data.range} ${data.derivative}`,
              id: null,
              price: data.buyNowPrice ? `${data.buyNowPrice}` : null,
              category: data.make,
              variant: data.colour,
              quantity: "1",
              dimension1: data.dateOfRegistration
                ? `${dayjs(data.dateOfRegistration).format("DD/MM/YYYY")}`
                : null,
              dimension2: data.regNo,
              dimension3: getMileageBandForGAMotability(data.odometer),
              dimension4: data.transmission,
              dimension5: data.fuelType,
              dimension6: data.damagePercentage
                ? `${data.damagePercentage}`
                : null,
              dimension7: null, //TODO
              dimension8: getDealerCodeFromToken(token),
              dimension11: data.saleType === "BuyNow" ? "Buy" : "Bid",
              dimension12: `${vendorId}${dayjs(data.endDateTime).format(
                "YYYYMMDDHHmm"
              )}`,
              metric1:
                data.buyNowPrice && data.saleType === "BuyNow"
                  ? `${data.buyNowPrice}`
                  : null,
            },
          ],
        },
      },
    };

    window.dataLayerMotability = window.dataLayerMotability || [];
    window.dataLayerMotability.push(dataLayerObject);
  }
};
