import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const RegistrationPlate = ({ className, registrationNumber }) => (
  <span className={className}>{registrationNumber}</span>
);

const StyledRegistrationPlate = styled(RegistrationPlate)`
  background-color: ${(props) => props.theme.COLOURS["YELLOW"]};
  border-radius: 0.2rem;
  color: ${(props) => props.theme.COLOURS["BLACK"]};
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.4rem;
  font-family: ${(props) => props.theme.FONTS.families.lato};
  padding: 0.5rem;
  text-transform: uppercase;
`;

RegistrationPlate.propTypes = {
  className: PropTypes.string,
  registrationNumber: PropTypes.string,
};

export default StyledRegistrationPlate;
