export const HEADER_TEXT = "Showroom Mode Administration";

export const DESCRIPTION_TEXT = "Please enter the price you would like to appear on the Showroom page as well as a paragraph of your own text. Once you have selected 'Enter Showroom' a new window will be generated displaying the selected vehicle in Showroom Mode. Please note that the vehicle details are provided by the vendor."

export const subheaderText = {
    PRICE:
      "Your Price",
    FINANCE:
      "Finance Per Month",
    SALES_TEXT:
      "Your Sales Text",
  };

  export const financeType = {
    HP: "HP",
    PCP: "PCP",
    OTHER: "Other",
  };

  export const CHECKBOX_TEXT = "Display Finance Options";