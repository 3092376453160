import styled from "styled-components";
import CancelSaleForm from "./CancelSaleForm";
import spacing from "../../../views/theme/base/spacing";
import colours from "../../../views/theme/base/colours";
import ParagraphText from "../../../views/components/ParagraphText";
import InputRadio from "../../../views/components/InputRadio/InputRadio";

const StyledCancelSaleForm = styled(CancelSaleForm)`
  width: 90rem;

  .cancel-sale__content {
    margin-top: ${(props) => props.theme.SPACING.MEDIUM};
    display: flex;
  }

  .cancel-sale__content-container {
    display: flex;
    gap: 2rem;
    width: 45%;

    ${ParagraphText} {
      margin-bottom: 1rem;
    }

    .submit-claim_notes-input {
      width: 300px;
      height: 150px;
    }
  }

  .content {
    color: ${colours.PRIMARY.base};
  }

  .cancel_radio-group {
    width: 55%;
    display: flex;
    flex-direction: column;
  }

  .text-area__input {
    background-color: ${colours.WHITE};
    border-radius: 0.5rem;
    border: 0.2rem solid ${colours.PRIMARY.shades[20]};
    box-sizing: border-box;
    color: ${colours.PRIMARY.base};
    padding: ${spacing.SMALL};
    display: flex;
    justify-content: flex-end;
    width: 100%;
    resize: initial;
    ${(props) => props.theme.FONTS.Text["paragraph"]}
  }

  .radio-options{
      display: flex;
      margin-top: 2rem;

      ${InputRadio} {
        > * {
          padding-left: 1rem;
        }
      }
    }

  .dropdown-options {
    margin-bottom: 1.5rem;

    ${ParagraphText} {
      margin-bottom: 1rem;
      color: ${colours.PRIMARY.base};
    }

    .dropdown-container {
      width: 35rem;

      p {
        padding-top: 0.8rem;
      }
    }
  }  

  .warning-container {
    ${ParagraphText} {
      color: ${colours.RED.base};
    }
  }
`;

export default StyledCancelSaleForm;
