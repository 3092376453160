import Api from "./Base";
import settings from "../clientappsettings.json";

export class GetEpyxTerms extends Api {
  baseUrl = settings.IMAGES_API_URL;
  requestUrl = `/terms/epyx`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };
}

export class GetVendorTerms extends GetEpyxTerms {
  requestUrl = `/terms/vehicle/${this.getParams().vehicleId}`;
}

export class GetNewUpdatesText extends GetEpyxTerms {
  requestUrl = "/terms/newUpdates";
}

export class MarkAsReadTerms extends GetEpyxTerms {
  baseUrl = settings.BUYERS_URL;
  requestUrl = `/Buyers/TermsAndConditions/MarkAsRead`;
  method = "POST";
}
