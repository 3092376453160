const families = {
  lato: "'Lato', Arial, Helvetica, sans-serif;",
  seatBcn: "'SeatBcn-Medium', Arial, Helvetica, sans-serif;"
};

const sizes = {
  10: "0.9rem",
  20: "1.2rem",
  25: "1.3rem",
  30: "1.4rem",
  40: "1.6rem",
  50: "1.7rem",
  60: "1.8rem",
  70: "2rem",
  80: "2.2rem",
  90: "2.4rem",
  100: "2.8rem",
  110: "3.2rem",
  120: "3.4rem",
  130: "4rem"
};

const lineHeights = {
  10: "1.2rem",
  20: "1.4rem",
  30: "1.6rem",
  40: "1.9rem",
  50: "2rem",
  60: "2.1rem",
  70: "2.2rem",
  80: "2.6rem",
  90: "2.9rem",
  100: "3rem",
  110: "3.4rem",
  120: "4rem",
  130: "4.8rem"
};
const weights = {
  10: "100",
  20: "200",
  30: "300",
  40: "400",
  50: "500",
  60: "600",
  70: "700",
  80: "800",
  90: "900"
};

const Text = {
  button: `
    line-height: ${lineHeights[30]};
    font-family: ${families["lato"]};
    font-weight: ${weights[70]};
    text-decoration: none;
    cursor: pointer;
  `,
  header: `
    font-family: ${families["lato"]};
    font-size: ${sizes[110]};
    line-height: ${lineHeights[120]};
    font-weight: ${weights[40]};
  `,
  mobileHeader: `
    font-family: ${families["lato"]};
    font-size: ${sizes[90]};
    line-height: ${lineHeights[120]};
    font-weight: ${weights[40]};
  `,
  subHeader: `
    font-family: ${families["lato"]};
    font-size: ${sizes[80]};
    line-height: ${lineHeights[100]};
    font-weight: ${weights[40]};
  `,
  paragraph: `
    font-family: ${families["lato"]};
    font-size: ${sizes[40]};
    line-height: ${lineHeights[80]};
    font-weight: ${weights[40]};
  `,
  paragraphCompact: `
    font-family: ${families["lato"]};
    font-size: ${sizes[40]};
    line-height: ${lineHeights[40]};
    font-weight: ${weights[40]};
  `,
  paragraphSemiBold: `
    font-family: ${families["lato"]};
    font-size: ${sizes[40]};
    line-height: ${lineHeights[40]};
    font-weight: ${weights[60]};
  `,
  paragraphBold: `
    font-family: ${families["lato"]};
    font-size: ${sizes[40]};
    line-height: ${lineHeights[40]};
    font-weight: ${weights[70]};
  `,
  inputLabelSemiBold: `
    font-family: ${families["lato"]};
    font-size: ${sizes[60]};
    line-height: ${lineHeights[70]};
    font-weight: ${weights[60]};
  `,
  inputLabelBold: `
    font-family: ${families["lato"]};
    font-size: ${sizes[60]};
    line-height: ${lineHeights[70]};
    font-weight: ${weights[70]};
  `,
  info: `
    font-family: ${families["lato"]};
    font-size: ${sizes[30]};
    line-height: ${lineHeights[50]};
    font-weight: ${weights[40]};
  `,
  infoBold: `
    font-family: ${families["lato"]};
    font-size: ${sizes[30]};
    line-height: ${lineHeights[50]};
    font-weight: ${weights[70]};
  `,
  title: `
    font-family: ${families["lato"]};
    font-size: ${sizes[100]};
    line-height: ${lineHeights[110]};
    font-weight: ${weights[40]};
  `,
  calendarTitle: `
    font-family: ${families["lato"]};
    font-size: ${sizes[40]};
    line-height: ${lineHeights[50]};
    font-weight: ${weights[60]};
  `,
  calendarDays: `
   font-family: ${families["lato"]};
    font-size: ${sizes[20]};
    line-height: ${lineHeights[10]};
    font-weight: ${weights[40]};
  `,
  heading: `
    font-family: ${families["seatBcn"]};
    font-weight: ${weights[50]};
    font-size: ${sizes[100]};
    line-height: ${lineHeights[50]};
  `
};

const FONTS = {
  families,
  sizes,
  lineHeights,
  weights,
  Text
};

export default FONTS;
