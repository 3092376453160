import React, { useState } from "react";
import propTypes from "prop-types";
import classnames from "classnames";
import HeaderText from "../HeaderText";
import InputText from "../InputText";
import ParagraphText from "../ParagraphText";
import Panel from "../../components/Panel";
import SubHeaderText from "./../SubHeaderText";
import TextArea from "../TextArea";

const SalesBrochureModalComponent = ({
  className,
  qaClassName,
  onCreateBrochure,
  onClose
}) => {
  const [price, setPrice] = useState({
    value: 0,
    isValid: null,
    isTouched: false,
    errors: []
  });

  const [salesText, setSalesText] = useState({
    value: "",
    isValid: true,
    isTouched: false,
    errors: []
  });

  const treatValue = val => parseInt(val.replace(/\D/g, ""));

  const formatCurrency = val =>
    !isNaN(val) ? `£${val.toLocaleString()}` : `£0`;

  const updateValidFormElState = (updateFunction, value) => {
    updateFunction(state => {
      return {
        ...state,
        value: value,
        isValid: true,
        isTouched: true,
        errors: []
      };
    });
  };

  const updateInvalidFormElState = (updateFunction, value, errors) => {
    updateFunction(state => {
      return {
        ...state,
        value: value,
        isValid: false,
        isTouched: true,
        errors: errors
      };
    });
  };

  const onPriceChange = value => {
    const treatedValue = treatValue(value);
    if (treatedValue > 0) {
      updateValidFormElState(setPrice, treatedValue);
    } else {
      updateInvalidFormElState(setPrice, treatedValue, [
        "Price should not be lower than 0."
      ]);
    }
  };

  const onSalesTextChange = e => {
    const {value} = e.target
      updateValidFormElState(setSalesText, value);
  };

  return (
    <div className={classnames(className, qaClassName)}>
      <Panel flex="1" padding="NONE">
        <HeaderText>Sales Brochure Administration</HeaderText>
        <ParagraphText>
          Please enter the price you would like to appear on the brochure as
          well as a paragraph of your own text. Once you have selected 'Create
          Brochure' a PDF will be generated with a selection of key details that
          are available and your customisations.
        </ParagraphText>
        <ParagraphText>
          Please note that the vehicle details are provided by the vendor.
        </ParagraphText>
      </Panel>
      <Panel flex="1" padding="NONE">
        <div className="sales-brochure-modal__inputs">
          <div className="sales-brochure-modal__inputs__price">
            <SubHeaderText colour="ACCENT">Your Price</SubHeaderText>
            <InputText
              type="text"
              value={formatCurrency(price.value)}
              onChange={e => onPriceChange(e.target.value)}
              maxLength="9"
              validValue={price.isValid || !price.isTouched}
            />
          </div>
          <div className="sales-brochure-modal__inputs__textarea">
            <SubHeaderText colour="ACCENT">Your Sales Text</SubHeaderText>
            <TextArea
              maximumCharactersTop={500}
              resize={"none"}
              value={salesText.value}
              onChange={onSalesTextChange}
              validValue
            />
          </div>
        </div>
      </Panel>
      <Panel
        flex="1"
        padding="NONE"
        actions={[
          {
            content: "Close",
            action: onClose,
            type: "ghost"
          },
          {
            content: "Create Brochure",
            action: () => onCreateBrochure(price.value, salesText.value),
            disabled: !price.isValid,
            type: "primary"
          }
        ]}
      />
    </div>
  );
};

SalesBrochureModalComponent.propTypes = {
  className: propTypes.string,
  qaClassName: propTypes.string,
  onCreateBrochure: propTypes.func,
  onClose: propTypes.func
};

export default SalesBrochureModalComponent;
