import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  openModal,
  toggleTermsAndConditions,
} from "../../../state/reducers/global";
import { postBidConfirmationDetails } from "../../../state/reducers/bid";
import HeaderText from "../../components/HeaderText";
import ModalInsert from "../../components/ModalInsert";
import Panel from "../../components/Panel";
import VehicleOverviewCard from "../../components/VehicleOverviewCard";
import ParagraphText from "../../components/ParagraphText";
import PricingBlock from "../../components/PricingBlock";
import { getBidIncrement, getDefaultImage } from "../../../shared/utility";
import styled, { ThemeContext } from "styled-components";
import { Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../theme/breakpoints";
import CountdownInsert from "../../components/CountdownInsert/CountdownInsert"; //doubled as there is no index.js

const BidModal = () => {
  const dispatch = useDispatch();
  const vehicleId = useSelector(
    (state) => state.global.bids.activeBidVehicleId
  );
  const bidDetails = useSelector(
    (state) => state.bid.bids.byVehicleId[vehicleId]
  );
  const inputBidPrice = useSelector((state) => state.bid.value);
  const bidPrice = useSelector((state) => state.bid.value);
  // TODO: This needs a cleaner solution
  const vehicleData = useSelector(
    (state) =>
      state.vehicle.data ||
      state.search.results.byId[vehicleId] ||
      state.bidsAndRequests.results.byId[vehicleId] ||
      state.shortlist.results.byId[vehicleId]
  );

  const showDamagePercentage = useSelector(
    (state) => state.authentication.meta.themeOptions.showDamagePercentage
  );

  const onPlaceBid = () => {
    dispatch(postBidConfirmationDetails(vehicleData.vehicleId, inputBidPrice));
    dispatch(openModal("confirm-bid-loader"));
    dispatch(toggleTermsAndConditions());
  };
  const onCancelBid = () => {
    dispatch(toggleTermsAndConditions());
    dispatch(openModal("bid"));
  };

  const {
    currentBidAmount = 0,
    biddingEndDate,
    bidCount = 0,
    highestBidder = false,
  } = bidDetails;

  const increment = getBidIncrement();

  const yourCurrentBidAmount = () => {
    if (bidCount === 0 || highestBidder) {
      return currentBidAmount;
    }
    return currentBidAmount + increment;
  };

  const vehicleImage = () => {
    if (vehicleData.thumbnail) {
      return vehicleData.thumbnail;
    }
    if (vehicleData.galleryInfo && !!vehicleData.galleryInfo.length) {
      return vehicleData.galleryInfo[0];
    }
    return getDefaultImage();
  };

  const theme = useContext(ThemeContext);
  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });
  const content = () => (
    <Fragment>
      <HeaderText fontSize={!isDesktop && 60}>Confirm your bid</HeaderText>
      <ParagraphText>
        Please check your bidding details are correct and then click confirm to
        place your bid on this vehicle.
      </ParagraphText>
    </Fragment>
  );

  return (
    <ModalInsert layout={isDesktop ? "flex" : "block"}>
      {!isDesktop && (
        <Panel type="grey" flex="1">
          {content()}
        </Panel>
      )}
      <Panel flex="1" padding="NONE">
        <VehicleOverviewCard
          {...{
            vehicleId: vehicleId,
            ...vehicleData,
            thumbnail: vehicleImage(),
            showDamagePercentage: showDamagePercentage,
          }}
        />
      </Panel>
      <Panel
        flex="1"
        actions={[
          {
            content: "Cancel",
            action: onCancelBid,
            type: "ghost",
          },
          {
            content: "Confirm bid",
            action: onPlaceBid,
            type: "primary",
          },
        ]}
      >
        {isDesktop && content()}
        <div style={{ display: "block", marginBottom: "2rem" }} />
        <PricingBlock
          headerText={
            biddingEndDate ? (
              <CountdownInsert endDateTime={biddingEndDate} />
            ) : (
              "Open Ended Bidding"
            )
          }
          large
          inline
        />
        <PricingBlock
          price={currentBidAmount}
          headerText={bidCount === 0 ? "Starting bid" : "Current bid"}
          inline
          small
        />
        <PricingBlock
          price={yourCurrentBidAmount()}
          priceColor={theme.COLOURS.ACCENT.base}
          headerText="Your current bid"
          inline
          small
        />
        <PricingBlock
          price={bidPrice}
          headerText="Your maximum bid"
          inline
          small
        />
      </Panel>
    </ModalInsert>
  );
};

export default styled(BidModal)`
  @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
    ${PricingBlock} {
      margin-bottom: ${(props) => props.theme.SPACING.SMALL};
    }
  }
`;
