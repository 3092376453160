import Base from "./Base";
import clientSettings from "../clientappsettings";

class ShortlistUpdate extends Base {
  baseUrl = clientSettings.API_URL;
  requestUrl = "/Vehicle/Shortlist";
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };
}
export default ShortlistUpdate;
