import Api from "./Base";
import settings from "../clientappsettings.json";
import { formatFilterOptions } from "../shared/filter";

export class IndexedDetails extends Api {
    baseUrl = settings.API_URL;
    requestUrl = "/Vehicle/IndexedDetails";
    method = "POST";
    headers = {
      Authorization: `Bearer ${this.getCredentials()}`
    };
  
    formatRequestData(params) {
      let formattedData = {
        makeRanges: []
      };
      if (params) {
        const {
          filters,
          isShortlisting,
          pageNumber,
          pageSize,
          sort,
          registration,
          purchaseSession
        } = params;
        formattedData = {
          pageNumber,
          pageSize,
          sort,
          registration,
          purchaseSession,
          ...formatFilterOptions({ filterObject: filters, pageType: "search" })
        };
        if (isShortlisting) {
          formattedData.shortlist = true;
        }
        return formattedData;
      }
      return formattedData;
    }
  
    formatResponseData(response) {
      if (response.data) {
        return response.data;
      }
      return response;
    }
}