import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../components/ModalInsert";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../theme/breakpoints";
import PromotionModalComponent from "../../components/PromotionModalComponent";
import SkeletonPromotionModal from "../../components/Skeletons/SkeletonPromotionModal";
import { useSelector } from "react-redux";

const PromotionModal = ({ className }) => {
  const dispatch = useDispatch();

  const { isFetching } = useSelector(
    (state) => state.vehicle?.promotionDetails
  );

  const onCloseClick = () => {
    dispatch(closeModal());
  };

  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  return (
    <Fragment>
      {isFetching && (
        <SkeletonPromotionModal
          {...{
            onCloseClick: onCloseClick,
          }}
        />
      )}
      {!isFetching && (
        <div
          className={className}
          style={{ height: isDesktop ? "60rem" : "100%" }}
        >
          <ModalInsert layout={isDesktop ? "flex" : "block"}>
            <div className="promotion__details">
              <PromotionModalComponent
                {...{
                  onCloseClick: onCloseClick,
                }}
              />
            </div>
          </ModalInsert>
        </div>
      )}
    </Fragment>
  );
};

export default PromotionModal;
