import React from "react";
import Button from "../../../../../views/components/Button";
import { ButtonGroup } from "./../index";
import { buttonText } from "../const";

function ButtonGroupComponent({
  buttonGroupProps,
  disabledSaveButton,
  name,
  reference,
  hideApprove,
  description,
  disableApproveButton,
}) {
  const {
    onCancelButtonClick,
    onCampaignSave,
    onCampaignApprove,
  } = buttonGroupProps;
  const disabledSaveButtonEmptyValues = !name || !reference || !description;

  return (
    <ButtonGroup>
      <Button
        primaryAlt
        action={onCancelButtonClick}
        content={buttonText.CANCEL}
      />
      <Button
        primary
        content={buttonText.SAVE}
        disabled={disabledSaveButton || disabledSaveButtonEmptyValues}
        action={() => onCampaignSave()}
      />
      {!hideApprove && (
        <Button
          primary
          content={buttonText.APPROVE}
          disabled={disableApproveButton}
          action={() => onCampaignApprove()}
        />
      )}
    </ButtonGroup>
  );
}

export default ButtonGroupComponent;
