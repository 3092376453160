import React from "react";
import PropTypes from "prop-types";
import HeaderText from "../../../views/components/HeaderText";
import ParagraphText from "../../../views/components/ParagraphText";
import InputRadio from "../../../views/components/InputRadio";
import Dropdown from "../../../views/components/Dropdown";
import { paragraphText, categoryDropdown } from "./const";
import { formatPrice } from "../../../shared/utility";
import TextArea from "../../../views/components/TextArea";

const CancelSaleForm = ({
  className,
  claimId,
  tradeBuyer,
  regNo,
  purchasePrice,
  residualValue,
  capValue,
  saleStatus,
  cancelSaleRefundType,
  setCancelSaleRefundType,
  dropdownOptions,
  radioOptions,
  cancellationReasonType,
  setCancelNotes,
  saleStatusMap,
  onCategoryChange,
  hasFees,
  hasAutoLogistics,
  cancelNotes,
  anotherVehicleActive,
}) => {
  return (
    <div className={className}>
      <HeaderText>{paragraphText.HEADER_TEXT}</HeaderText>
      <ParagraphText>{paragraphText.SUB_HEADER_TEXT}</ParagraphText>
      <div className="cancel-sale__form">
        <div className="cancel-sale__content">
          <div className="cancel-sale__content-container">
            <div className="cancel-sale__header-container">
              <ParagraphText>{paragraphText.REGISTRATION}</ParagraphText>
              <ParagraphText>{paragraphText.TRADE_BUYER_NAME}</ParagraphText>
              <ParagraphText>{paragraphText.CASE_REFERENCE}</ParagraphText>
              <ParagraphText>{paragraphText.PURCHASE_PRICE}</ParagraphText>
              <ParagraphText>{paragraphText.RESIDUAL_VALUE}</ParagraphText>
              <ParagraphText>{paragraphText.CAP_VALUE}</ParagraphText>
              <ParagraphText>{paragraphText.PAYMENT_STATUS}</ParagraphText>
            </div>
            <div className="cancel-sale__values-container">
              <ParagraphText className="content">{regNo || "-"}</ParagraphText>
              <ParagraphText className="content">
                {tradeBuyer || "-"}
              </ParagraphText>
              <ParagraphText className="content">
                {claimId || "-"}
              </ParagraphText>
              <ParagraphText className="content">
                £{formatPrice(purchasePrice) || "-"}
              </ParagraphText>
              <ParagraphText className="content">
                £{formatPrice(residualValue) || "-"}
              </ParagraphText>
              <ParagraphText className="content">
                £{formatPrice(capValue) || "-"}
              </ParagraphText>
              <ParagraphText className="content">
                {saleStatusMap[saleStatus] || "-"}
              </ParagraphText>
            </div>
          </div>
          <div className="cancel_radio-group">
            <div className="dropdown-options">
              <ParagraphText>{paragraphText.DROPDOWN_TEXT}</ParagraphText>
              <div className="dropdown-container">
                <Dropdown
                  key={dropdownOptions.value}
                  options={dropdownOptions}
                  selectedOption={cancellationReasonType}
                  defaultOption={dropdownOptions[0]}
                  placeholder={categoryDropdown.PLACEHOLDER}
                  onInputChange={(e) => onCategoryChange(e.value)}
                  required
                />
              </div>
            </div>
            {hasFees && (
              <ParagraphText>{paragraphText.CONTENT_TEXT}</ParagraphText>
            )}
            {hasFees && (
              <div className="radio-options">
                <InputRadio
                  key={radioOptions[0].optionName}
                  value={radioOptions[0].value}
                  label={radioOptions[0].optionName}
                  isChecked={radioOptions[0].value === cancelSaleRefundType}
                  onInputChange={() => {
                    setCancelSaleRefundType(radioOptions[0].value);
                  }}
                />
                <InputRadio
                  key={radioOptions[1].optionName}
                  value={radioOptions[1].value}
                  label={radioOptions[1].optionName}
                  isChecked={radioOptions[1].value === cancelSaleRefundType}
                  onInputChange={() => {
                    setCancelSaleRefundType(radioOptions[1].value);
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <ParagraphText>{paragraphText.NOTES}</ParagraphText>
        <div className="submit-claim_notes-input">
          <TextArea
            resize={"none"}
            maximumCharactersBottom="250"
            rows="5"
            cols="10"
            value={cancelNotes.value || ""}
            onChange={(e) => setCancelNotes(e.target.value)}
          />
        </div>
        <div className="warning-container">
          {hasAutoLogistics && (
            <ParagraphText>{paragraphText.WARNING}</ParagraphText>
          )}
          {anotherVehicleActive && (
            <ParagraphText>{paragraphText.CANCEL_NOT_POSSIBLE}</ParagraphText>
          )}
        </div>
      </div>
    </div>
  );
};

CancelSaleForm.propTypes = {
  className: PropTypes.string,
  claimId: PropTypes.number,
  tradeBuyer: PropTypes.string,
  regNo: PropTypes.string,
  purchasePrice: PropTypes.number,
  residualValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  capValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  saleStatus: PropTypes.string,
  saleStatusMap: PropTypes.object,
  setCancelNotes: PropTypes.func,
  cancelNotes: PropTypes.string,
  setCreditChoice: PropTypes.func,
  radioOptions: PropTypes.array,
  dropdownOptions: PropTypes.array,
  onCategoryChange: PropTypes.func,
  claimCategory: PropTypes.func,
};

export default CancelSaleForm;
