import React from "react";
import PropTypes from "prop-types";

const SkeletonNewClaims = (props) => {
  return (
    <div className={props.className}>
      {[...Array(props.noOfRows)].map((skeleton, key) => (
        <span key={key}> </span>
      ))}
    </div>
  );
};

SkeletonNewClaims.propTypes = {
  className: PropTypes.string,
  nrOfRows: PropTypes.number,
};

export default SkeletonNewClaims;
