import { TradeBuyer } from "./tradeBuyer";
import { Vendor } from "./vendor";
import { Motability } from "./motability";
import { Audi } from "./audi";
import { Seat } from "./seat";
import { Skoda } from "./skoda";
import { Volkswagen } from "./volkswagen";
import { VolkswagenCommercial } from "./volkswagenCommercial";
import { VWFSPortal } from "./VWFSPortal";

export const themes = {
  TradeBuyer,
  Vendor,
  Motability,
  Audi,
  Seat,
  Skoda,
  Volkswagen,
  VolkswagenCommercial,
  VWFSPortal
};
