import React from "react";
import PropTypes from "prop-types";

import Panel from "../../Panel";
import ModalInsert from "../../ModalInsert";

const SkeletonBidModal = props => {
  return (
    <div className={props.className}>
      <ModalInsert layout="flex" width="96rem">
        <Panel flex="0" flexDesktop="1.5" type="grey">
          <div className="pricing-blocks">
            <div className="pricing-block__heading" />
            <div className="pricing-block__price" />
            <div className="pricing-block__desc" />
            <div className="pricing-block__heading" />
            <div className="pricing-block__price" />
          </div>
        </Panel>
        <Panel
          flex="2"
          flexTablet="1"
          actions={[
            {
              content: "Close",
              action: () => {},
              type: "ghost"
            },
            {
              content: "Place bid",
              action: () => {},
              type: "primary",
              disabled: true
            }
          ]}
        >
          <div className="bidding-block__heading" />
          <div className="bidding-block__desc" />
          <div className="bidding-block__form-title" />
          <div className="bidding-block__form" />
        </Panel>
      </ModalInsert>
    </div>
  );
};

SkeletonBidModal.propTypes = {
  className: PropTypes.string
};

export default SkeletonBidModal;
