import React, { useState, useContext, useEffect } from "react";
import { ItemContainer, ItemLabel, ItemContent } from "../index";
import {
  paragraphText,
  subHeaderText,
  infoMessages,
  errorMessages,
} from "../consts";
import ParagraphText from "../../ParagraphText";
import SubHeaderText from "../../SubHeaderText";
import TextArea from "../../TextArea";
import BidInputError from "../../BidInput/child-components/BidInputError";
import {
  ClaimReasonContext,
  ClaimValidationContext,
} from "../../ClaimItem/context/context";
import { useMediaQuery } from "react-responsive";
import breakpoints from "./../../../theme/breakpoints";

const ClaimReason = ({ claimReason, disabled }) => {
  const { setClaimReason: setClaimReasonCtx } = useContext(ClaimReasonContext);
  const [reason, setReason] = useState(claimReason || "");
  const {
    validClaimReason,
    setValidClaimReason,
    hasFocus,
    setHasFocus,
  } = useContext(ClaimValidationContext);

  function onChangeReason(e) {
    const { value } = e.target;
    setReason(value);
    if (value.length >= 20 && value.length <= 1000) {
      setValidClaimReason(true);
    } else {
      setValidClaimReason(false);
    }
  }

  useEffect(() => {
    validClaimReason && setClaimReasonCtx(reason);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reason, validClaimReason]);

  const isMobileOrTablet = useMediaQuery({
    query: `(max-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  return (
    <ItemContainer>
      <ItemLabel>
        <SubHeaderText>{subHeaderText.REASON_FOR_CLAIM}:</SubHeaderText>
        <div style={{ width: "80%" }}>
          <ParagraphText className="info-text">
            {paragraphText.REASON_FOR_CLAIM}
          </ParagraphText>
        </div>
      </ItemLabel>
      <ItemContent>
        <TextArea
          value={reason}
          onChange={onChangeReason}
          placeholder={infoMessages.TEXT_AREA_PLACEHOLDER}
          validValue={validClaimReason}
          resize={"none"}
          maxLength="1000"
          rows="7"
          cols="50"
          onFocus={(e) => {
            e.target.select();
            onChangeReason(e);
            setHasFocus(true);
          }}
          onBlur={(e) => {
            setHasFocus(false);
          }}
          disabled={disabled}
        ></TextArea>
        {!validClaimReason &&
          !hasFocus &&
          (reason.length <= 0 ? (
            <BidInputError
              errorMessage={
                !isMobileOrTablet
                  ? errorMessages.FIELD_REQUIRED
                  : errorMessages.MOBILE_FIELD_REQUIRED
              }
            />
          ) : (
            <BidInputError errorMessage={errorMessages.MIN_LENGTH} />
          ))}
      </ItemContent>
    </ItemContainer>
  );
};

export default ClaimReason;
