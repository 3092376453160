import Base from "./Base";
import clientSettings from "../clientappsettings";
import { formatInvoicesResponse } from "../shared/utility";
import { formatFilterOptions } from "../shared/filter";

class Invoices extends Base {
  baseUrl = clientSettings.BUYERS_URL;
  requestUrl = "/Invoices";
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };

  formatRequestData(params) {
    if (params) {
      const {
        filters,
        invoiceNo,
        sort,
        pageSize,
        registration,
        pageNumber
      } = params;
      return {
        sort,
        pageSize,
        registration,
        pageNumber,
        invoiceNo,
        ...formatFilterOptions({ filterObject: filters, pageType: "invoices" })
      };
    }
  }

  formatResponseData(response) {
    return formatInvoicesResponse(response);
  }
}

export default Invoices;
