import React, { useContext } from "react";
import HeaderText from "../HeaderText";
import ParagraphText from "../ParagraphText";
import { vendorAddNote } from "./consts";
import TextArea from "../TextArea";
import { VendorAddNoteReasonContext } from "./context/context";

const VendorAddNote = () => {
  const { vendorAddNoteMessage, setVendorAddNoteMessage } = useContext(
    VendorAddNoteReasonContext
  );

  function onChangeInput(e) {
    const { value } = e.target;
    setVendorAddNoteMessage(value);
  }

  return (
    <div style={{width: "90rem" }} className = "addNoteModal">
      <HeaderText>{vendorAddNote.HEADER_TEXT}</HeaderText>
      <ParagraphText>
        {vendorAddNote.SUB_HEADER_TEXT}
      </ParagraphText>
      <TextArea
        value={vendorAddNoteMessage}
        className="text-area__input"
        onChange={onChangeInput
        }
        validValue={true}
        resize="none"
        maximumCharactersBottom="1000"
        rows="7"
        cols="50"
        onFocus={(e) => {
          e.target.select();
        }}
        onBlur={(e) => {
        }}
      ></TextArea>
    </div>
  );
};

export default VendorAddNote;
