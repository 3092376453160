import {
  FundingProvidersApi,
  IsFundingAllowedApi,
  CreditUtilisationApi,
  CreditFundingApi,
} from "../../api/Funding";
import { openModal } from "./global";

// Action Constants
export const FETCH_FUNDING_PROVIDERS = "@funding/FETCH_FUNDING_PROVIDERS";
export const FETCH_FUNDING_PROVIDERS_SUCCESS =
  "@funding/FETCH_FUNDING_PROVIDERS_SUCCESS";
export const FETCH_FUNDING_PROVIDERS_FAILURE =
  "@funding/FETCH_FUNDING_PROVIDERS_FAILURE";
export const IS_FUNDING_ALLOWED = "@funding/IS_FUNDING_ALLOWED";
export const IS_FUNDING_ALLOWED_SUCCESS = "@funding/IS_FUNDING_ALLOWED_SUCCESS";
export const IS_FUNDING_ALLOWED_FAILURE = "@funding/IS_FUNDING_ALLOWED_FAILURE";
export const FETCH_CREDIT_UTILISATION = "@funding/FETCH_CREDIT_UTILISATION";
export const FETCH_CREDIT_UTILISATION_SUCCESS =
  "@funding/FETCH_CREDIT_UTILISATION_SUCCESS";
export const FETCH_CREDIT_UTILISATION_FAILURE =
  "@funding/FETCH_CREDIT_UTILISATION_FAILURE";
export const APPLY_FOR_FUNDING = "@funding/APPLY_FOR_FUNDING";
export const APPLY_FOR_FUNDING_SUCCESS = "@funding/APPLY_FOR_FUNDING_SUCCESS";
export const APPLY_FOR_FUNDING_FAILURE = "@funding/APPLY_FOR_FUNDING_FAILURE";
export const CLEAR_FUNDING_STATE = "@funding/CLEAR_FUNDING_STATE";

// Action Creators
export const fetchFundingProvidersSuccess = (data) => {
  return {
    type: FETCH_FUNDING_PROVIDERS_SUCCESS,
    payload: data,
  };
};

export const fetchFundingProvidersFailure = (error) => {
  return {
    type: FETCH_FUNDING_PROVIDERS_FAILURE,
    payload: error,
  };
};

export const fetchFundingProviders = (vehiclePurchaseId) => (dispatch, getState) => {
  dispatch({ type: FETCH_FUNDING_PROVIDERS });
  const params = {
    vehiclePurchaseId: vehiclePurchaseId,
  };
  const instance = new FundingProvidersApi({
    params,
    credentials: getState().authentication.credentials,
  });
  instance.call().then(
    (response) => {
      dispatch(fetchFundingProvidersSuccess(response.data));
    },
    (err) => {
      return dispatch(fetchFundingProvidersFailure(err));
    }
  );
};

export const fetchCreditUtilisationSuccess = (providerName, data) => {
  const balance = data && data.balance ? data.balance : null;
  return {
    type: FETCH_CREDIT_UTILISATION_SUCCESS,
    payload: { providerName, balance: balance },
  };
};

export const fetchCreditUtilisationFailure = (providerName, error) => {
  return {
    type: FETCH_CREDIT_UTILISATION_FAILURE,
    payload: { providerName, error },
  };
};

export const fetchCreditUtilisation = (providerName) => (
  dispatch,
  getState
) => {
  dispatch({ type: FETCH_CREDIT_UTILISATION });
  const params = {
    providerName: providerName,
  };
  const instance = new CreditUtilisationApi({
    params,
    credentials: getState().authentication.credentials,
  });
  instance.call().then(
    (response) => {
      dispatch(fetchCreditUtilisationSuccess(providerName, response.data));
    },
    (err) => {
      return dispatch(fetchCreditUtilisationFailure(providerName, err));
    }
  );
};

export const fetchIsFundingAllowedSuccess = (data) => {
  return {
    type: IS_FUNDING_ALLOWED_SUCCESS,
    payload: data,
  };
};

export const fetchIsFundingAllowedFailure = (error) => {
  return {
    type: IS_FUNDING_ALLOWED_FAILURE,
    payload: error,
  };
};

export const fetchIsFundingAllowed = (vehiclePurchaseId) => (dispatch, getState) => {
  dispatch({ type: IS_FUNDING_ALLOWED });

  const params = {
    vehiclePurchaseId: vehiclePurchaseId,
  };
  const instance = new IsFundingAllowedApi({
    params,
    credentials: getState().authentication.credentials,
  });
  instance.call().then(
    (response) => {
      dispatch(fetchIsFundingAllowedSuccess(response.data));
    },
    (err) => {
      return dispatch(fetchIsFundingAllowedFailure(err));
    }
  );
};

export const applyForFundingSuccess = (data) => {
  return {
    type: APPLY_FOR_FUNDING_SUCCESS,
    payload: data,
  };
};

export const applyForFundingFailure = (error) => {
  return {
    type: APPLY_FOR_FUNDING_FAILURE,
    payload: error,
  };
};

export const applyForFunding = (vehiclePurchaseId, providerName) => (
  dispatch,
  getState
) => {
  dispatch(openModal("dealer-funding-request-loader"));
  dispatch({ type: APPLY_FOR_FUNDING });
  const params = {
    vehiclePurchaseId: vehiclePurchaseId,
    providerName: providerName,
  };
  const instance = new CreditFundingApi({
    params,
    credentials: getState().authentication.credentials,
  });
  instance.call().then(
    (response) => {
      if (response.data && response.data.creditFundingResponse) {
        dispatch(applyForFundingSuccess(response.data.creditFundingResponse));

        if (response.data.creditFundingResponse.isSuccessful)
          dispatch(openModal("dealer-funding-request-success"));
        else {
          dispatch(openModal("dealer-funding-request-error"));
        }
      } else {
        dispatch(openModal("generic-error"));
        return dispatch(
          applyForFundingFailure("Data error on Successful response")
        );
      }
    },
    (err) => {
      dispatch(openModal("generic-error"));
      return dispatch(applyForFundingFailure(err));
    }
  );
};

export const clearFundingState = () => {
  return {
    type: CLEAR_FUNDING_STATE,
  };
};

export const initialState = {
  fundingProviders: {
    isFetching: false,
    error: false,
    isFetchingBalance: false,
    balanceError: false,
    results: [],
  },
  isFundingAllowed: {
    isFetching: false,
    error: false,
    status: "NotAvailable",
  },
  fundingApplication: {
    isFetching: false,
    data: null,
    error: false,
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FUNDING_PROVIDERS:
      return {
        ...state,
        fundingProviders: {
          ...state.fundingProviders,
          isFetching: true,
        },
      };
    case FETCH_FUNDING_PROVIDERS_FAILURE:
      return {
        ...state,
        fundingProviders: {
          ...state.fundingProviders,
          isFetching: false,
          error: action.payload,
        },
      };
    case FETCH_FUNDING_PROVIDERS_SUCCESS:
      return {
        ...state,
        fundingProviders: {
          ...state.fundingProviders,
          isFetching: false,
          results: action.payload,
        },
      };
    case FETCH_CREDIT_UTILISATION:
      return {
        ...state,
        fundingProviders: {
          ...state.fundingProviders,
          isFetchingBalance: true,
        },
      };
    case FETCH_CREDIT_UTILISATION_FAILURE:
      return {
        ...state,
        fundingProviders: {
          ...state.fundingProviders,
          isFetchingBalance: false,
          errorBalance: action.payload.error,
          results: state.fundingProviders.results.map((fp) =>
            fp.providerName === action.payload.providerName
              ? { ...fp, balance: null }
              : fp
          ),
        },
      };
    case FETCH_CREDIT_UTILISATION_SUCCESS:
      return {
        ...state,
        fundingProviders: {
          ...state.fundingProviders,
          isFetchingBalance: false,
          results: state.fundingProviders.results.map((fp) =>
            fp.providerName === action.payload.providerName
              ? { ...fp, balance: action.payload.balance }
              : fp
          ),
        },
      };
    case IS_FUNDING_ALLOWED:
      return {
        ...state,
        isFundingAllowed: {
          ...state.isFundingAllowed,
          isFetching: true,
        },
      };
    case IS_FUNDING_ALLOWED_FAILURE:
      return {
        ...state,
        isFundingAllowed: {
          ...state.isFundingAllowed,
          isFetching: false,
          error: action.payload,
        },
      };
    case IS_FUNDING_ALLOWED_SUCCESS:
      return {
        ...state,
        isFundingAllowed: {
          ...state.isFundingAllowed,
          isFetching: false,
          status: action.payload,
        },
      };
    case CLEAR_FUNDING_STATE:
      return {
        ...initialState,
      };
    case APPLY_FOR_FUNDING:
      return {
        ...state,
        fundingApplication: {
          ...state.fundingApplication,
          isFetching: true,
        },
      };
    case APPLY_FOR_FUNDING_FAILURE:
      return {
        ...state,
        fundingApplication: {
          ...state.fundingApplication,
          isFetching: false,
          error: action.payload,
        },
      };

    case APPLY_FOR_FUNDING_SUCCESS:
      return {
        ...state,
        fundingApplication: {
          ...state.fundingApplication,
          isFetching: false,
          data: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
