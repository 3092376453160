import styled from "styled-components";

import SkeletonDefaultPageVendor from "./SkeletonDefaultPageVendor";
import breakpoints from "../../../theme/breakpoints";

const StyledSkeletonDefaultPageVendor = styled(SkeletonDefaultPageVendor)`
  .skeleton__page-heading {
    max-width: 119rem;
    height: 11rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 60rem;

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      padding: ${(props) => props.theme.SPACING.LARGE} 0;
    }

    .skeleton__page-title {
      width: 20%;
      height: 5rem;
      display: block;
      background-color: ${(props) => props.theme.COLOURS.PRIMARY.shades[80]};
      ${(props) => props.theme.ANIMATIONS["shimmerDarker"]};
    }
    .skeleton__page-user {
      width: 20%;
      height: 5rem;
      background-color: ${(props) => props.theme.COLOURS.PRIMARY.shades[80]};
      ${(props) => props.theme.ANIMATIONS["shimmerDarker"]};
    }
  }
  .skeleton__page-content {
    height: 100vh;
    background-color: ${(props) => props.theme.COLOURS.PRIMARY.shades[5]};
  }
`;

export default StyledSkeletonDefaultPageVendor;
