const NONE = "0";
const XSMALL = "0.5rem";
const SMALL = "1rem";
const REGULAR = "2rem";
const MEDIUM = "3rem";
const LARGE = "4rem";
const XLARGE = "8rem";

const SPACING = {
  NONE,
  XSMALL,
  SMALL,
  REGULAR,
  MEDIUM,
  LARGE,
  XLARGE,
};

export default SPACING;
