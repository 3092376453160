import styled from "styled-components";

import SkeletonDefaultPage from "./SkeletonDefaultPage";
import breakpoints from "../../../theme/breakpoints";

const StyledSkeletonDefaultPage = styled(SkeletonDefaultPage)`
  .skeleton__page-heading {
    max-width: 119rem;
    height: 23rem;
    margin: 0 auto;
    padding: ${props => `${props.theme.SPACING.REGULAR}`};

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      padding: ${props => props.theme.SPACING.LARGE} 0;
    }

    .skeleton__page-title {
      width: 20%;
      height: 3rem;
      display: block;
      background-color: ${props => props.theme.COLOURS.PRIMARY.shades[80]};
      ${props => props.theme.ANIMATIONS["shimmerDarker"]};
    }
    .skeleton__page-description {
      width: 60%;
      height: 5rem;
      margin-top: ${props => props.theme.SPACING.REGULAR};
      display: block;
      background-color: ${props => props.theme.COLOURS.PRIMARY.shades[80]};
      ${props => props.theme.ANIMATIONS["shimmerDarker"]};
    }
  }
  .skeleton__page-content {
    height: 100vh;
    background-color: ${props => props.theme.COLOURS.PRIMARY.shades[5]};
  }
`;

export default StyledSkeletonDefaultPage;
