import React, { useContext, useEffect, useState } from "react";
import InputText from "../../../InputText";
import InputLabel from "../../../InputLabel";
import { ClaimResolutionContext } from "./../../context/context";
import { formatCurrency, formatNumericValue } from "../../utils";
import classnames from "classnames";
import { resolutionHeaders } from "../../consts";

const ResolutionTotalCost = ({ className, claimTotalCost, costLines, disabled }) => {
  const { setTotalCostCtx } = useContext(ClaimResolutionContext);
  const [totalCost, setTotalCost] = useState(0);

  useEffect(() => {
    costLines.length < 1 && setTotalCost(claimTotalCost);
  }, [claimTotalCost, costLines]);

  useEffect(() => {
    setTotalCostCtx(totalCost);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCost]);

  return (
    <div className={classnames(className, "total-cost__page-container")}>
      <div style={{ width: "35%" }} />
      <div className="total-cost__container">
        <div className="total-cost__label">
          <InputLabel>{resolutionHeaders.PROPOSED_AMOUNT}</InputLabel>
        </div>

        <div className="total-cost__input">
          <InputText
            medium
            validValue={true}
            onChange={(e) => setTotalCost(formatNumericValue(e.target.value))}
            value={formatCurrency(totalCost)}
            disabled={disabled}
          ></InputText>
        </div>
      </div>
    </div>
  );
};

export default ResolutionTotalCost;
