import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const EnvelopeOpen = ({ width, height, strokeColour, strokeWidth }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 215 207"
      enableBackground="new 0 0 215 207"
      xmlSpace="preserve"
    >
      <path
        fill="none"
        opacity={1}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d=" M105.750008,58.500000  C105.833336,58.833332 106.059479,59.419174 105.979790,59.464249  C94.681297,65.854195 85.070656,74.598251 74.512413,82.017662  C69.440384,85.581833 64.392235,89.196999 59.531200,93.039474  C54.939610,96.668945 52.328751,101.304802 52.435730,107.501106  C52.651482,119.997383 52.708939,132.504700 52.427864,144.998383  C52.234673,153.585770 56.390766,156.156357 63.500805,156.097824  C92.165527,155.861755 120.833336,156.001495 149.500000,155.998825  C155.846069,155.998230 158.996918,152.846054 158.998840,146.500000  C159.002701,133.666672 158.964630,120.833145 159.033264,108.000175  C159.045334,105.743057 158.723480,103.618507 158.013763,101.495399  C156.566299,97.165283 153.904175,94.120247 150.084625,91.381966  C137.030472,82.023315 124.352707,72.140686 111.473404,62.535656  C110.003044,61.439106 108.166664,60.833332 106.500000,60.000000  "
      />
      <path
        fill="none"
        opacity={1}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d=" M143.500000,140.500000  C143.361908,139.588577 143.725250,138.750839 142.543213,137.937225  C135.953262,133.401215 129.469604,128.708008 123.023300,123.968315  C121.575188,122.903572 120.068283,123.004959 118.500000,123.003540  C110.166664,122.996010 101.833122,123.031700 93.500160,122.975685  C91.628967,122.963112 90.073929,123.301743 88.464378,124.450073  C82.218582,128.906097 75.787949,133.103134 69.544884,137.562836  C68.510162,138.301987 67.633415,138.566910 66.500000,138.500000  "
      />
      <path
        fill="none"
        opacity={1}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d=" M55.499996,99.000000  C63.333332,104.166672 71.172722,109.324188 78.995193,114.507263  C80.679901,115.623535 82.259293,116.953613 84.500000,116.500000  "
      />
      <path
        fill="none"
        opacity={1}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d=" M156.500000,99.500000  C148.509827,103.572731 141.427475,109.053055 133.951996,113.926376  C132.322174,114.988869 130.879929,116.869164 128.500000,116.500000  "
      />
    </svg>
  );
};

EnvelopeOpen.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

EnvelopeOpen.defaultProps = {
  width: "45px",
  height: "45px",
  strokeWidth: "8"
};

export default EnvelopeOpen;
