import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import ModalInsert from "../../components/ModalInsert";
import GenericConfirmInsert from "../../components/GenericConfirmInsert";
import AcceptSaleDecision from "../../containers/AcceptSaleDecision";
import InputRadio from "../InputRadio";

const AcceptSaleForm = ({
  formData,
  vendorName,
  radioOptions,
  radioOptionChoice,
  setRadioOptionChoice,
  monetaryOffer,
  onCancel,
}) => {
  const [acceptSaleDecision, setAcceptSaleDecision] = useState(false);

  const onAcceptDecision = () => {
    setAcceptSaleDecision(true);
  };

  const radioOptionsContainer = radioOptions.map((el) => (
    <InputRadio
      className="radio-input--in-accordion"
      key={el.optionName}
      value={el.value}
      label={el.optionName}
      isChecked={el.value === radioOptionChoice}
      onInputChange={() => {
        setRadioOptionChoice(el.value);
      }}
    />
  ));

  return (
    <Fragment>
      <ModalInsert>
        {acceptSaleDecision ? (
          <AcceptSaleDecision
            formData={formData}
            radioOptionChoice={radioOptionChoice}
            monetaryOffer={monetaryOffer}
            vendorName={vendorName}
            radioOptions={radioOptions}
          />
        ) : (
          <GenericConfirmInsert
            headerText="Select your preferred option:"
            additionalText={radioOptionsContainer}
            onCancel={onCancel}
            onConfirm={onAcceptDecision}
          />
        )}
      </ModalInsert>
    </Fragment>
  );
};

AcceptSaleForm.propTypes = {
  monetaryOffer: PropTypes.string,
  formData: PropTypes.object,
  radioOptions: PropTypes.array,
  radioOptionChoice: PropTypes.string,
  setRadioOptionChoice: PropTypes.func,
  vendorName: PropTypes.string,
  onCancel: PropTypes.func,
};

export default AcceptSaleForm;
