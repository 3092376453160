import styled from "styled-components";

import Navigation from "./Navigation";
import breakpoints from "../../theme/breakpoints";

const NAV_HEIGHT = {
  LARGE_DESKTOP: "8.2rem",
  MOBILE: "6rem"
};

const StyledNavigation = styled(Navigation)`
  font-family: ${props => props.theme.FONTS.families.lato};
  background-color: transparent;
  box-sizing: border-box;

  .navigation__container {
    display: flex;
    justify-content: flex-start;
    height: 100%;

    > div {
      width: 100%;
      padding: 0;
      background-color: ${props => props.theme.COLOURS.PRIMARY.base};
      z-index: 1;

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        border-radius: 0 0 4.8rem 0;
      }

      &:before {
        content: "";
        background-color: ${props => props.theme.COLOURS.PRIMARY.base};
        position: absolute;
        height: ${NAV_HEIGHT.MOBILE};
        top: 0;
        width: 150vw;
        left: -100vw;
        z-index: -1;

        @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
          border-radius: 0 0 4.8rem 0;
          height: ${NAV_HEIGHT.LARGE_DESKTOP};
        }
      }
    }
  }

  .navigation__wrapper {
    display: flex;
    align-items: center;
    height: ${NAV_HEIGHT.MOBILE};
    justify-content: space-between;
    padding-left: 2rem;

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      height: ${NAV_HEIGHT.LARGE_DESKTOP};
      justify-content: initial;
      padding-left: 0;
    }

    .navigation__logo svg {
      display: flex;

      ${props => {
        switch (props.theme.name) {
          case "Motability":
            return {
              width: "30%"
            };
          case "Volkswagen":
            return {
              width: "24rem"
            };
          case "VolkswagenCommercial":
            return {
              width: "18rem"
            };
          case "Seat":
            return {
              width: "24rem"
            };
          default:
            return {
              width: "20rem"
            };
        }
      }}

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        ${props => {
          switch (props.theme.name) {
            case "Tradebuyer":
              return {
                width: "20rem"
              };
            case "Motability":
              return {
                width: "80%"
              };
            case "Volkswagen":
              return {
                width: "30rem"
              };
            case "Skoda":
              return {
                width: "28rem"
              };
            case "Seat":
              return {
                width: "28rem"
              };
            default:
              return {
                width: "20rem"
              };
          }
        }}
      }
    }
  }

  .navigation__logo__image {
    display: flex;
    
    > img {
      position: relative;
      
      ${props => {
        switch (props.theme.name) {
          case "Audi":
            return {
              width: "20.3rem",
              height: "7.4rem",
              top: "-0.5rem"
            };
          case "Skoda":
            return {
              width: "22.7rem",
              height: "4.5rem",
              top: "-0.5rem"
            };
          default:
            return {
              width: "20.3rem",
              height: "7.4rem"
            };
        }
      }}
      
      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        ${props => {
          switch (props.theme.name) {
            case "Audi":
              return {
                width: "30.3rem",
                height: "11rem",
                top: "-0.75rem"
              };
            case "Skoda":
              return {
                width: "30.7rem",
                height: "6rem"
              };
            default:
              return {
                width: "30.3rem",
                height: "11rem"
              };
          }
        }}
      }
    }
  }

  .navigation__wrapper__nav-items {
    background-color: ${props => props.theme.COLOURS.SECONDARY.base};
    display: block;
    height: calc(100vh - 6rem);
    left: 0;
    opacity: 0;
    position: fixed;
    top: 6rem;
    transform: translateX(100%);
    transition: all cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.2s;
    width: 100%;

    &[data-open="true"] {
      opacity: 1;
      overflow: auto;
      transform: translateX(0);
    }

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      align-items: center;
      background-color: transparent;
      display: flex;
      height: auto;
      justify-content: flex-end;
      margin-left: auto;
      opacity: 1;
      position: static;
      transform: none;
    }
  }

  .navigation__mobile-launch {
    align-items: center;
    background-color: ${props => props.theme.COLOURS.SECONDARY.base};
    color: ${props => props.theme.COLOURS.WHITE};
    display: flex;
    height: 100%;
    justify-content: center;
    width: ${NAV_HEIGHT.MOBILE};
  }
`;

export default StyledNavigation;
