import Api from "./Base";
import settings from "../clientappsettings.json";

export class GetStandardEquipment extends Api {
  baseUrl = settings.TRADE_BUYER_URL;
  requestUrl = `/VehicleInfo/standardEquipment/${this.getParams().id}`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };

  formatResponseData(response) {
    if (response.data) {
      return response.data;
    }
    return response;
  }
}
