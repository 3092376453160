import React from "react";
import PropTypes from "prop-types";
import Imgix from "react-imgix";
import classnames from "classnames";
import PriceText from "../PriceText";
import ParagraphText from "../ParagraphText";

const InputRadio = ({
  className,
  qaClassName,
  value,
  label,
  note,
  image,
  price,
  isChecked,
  isDisabled,
  onInputChange,
  inAccordion,
  count
}) => {
  return (
    <div
      className={classnames(className, qaClassName, "radio-input", {
        "radio-input--in-accordion": inAccordion,
        "radio-input--checked": isChecked,
        "radio-input--disabled": isDisabled
      })}
    >
      <label className="radio-input__label">
        <div>
          <span className="radio-input__title">{label}</span>
          {note && <span className="radio-input__note">{note}</span>}
        </div>
        <input
          className="radio-input__input"
          type="radio"
          value={value}
          name={label}
          disabled={isDisabled}
          checked={isChecked}
          onChange={onInputChange}
        />
        <span className="radio-input__indicator" />
      </label>
      {count ? (
        <ParagraphText className="radio-input__count">({count}) </ParagraphText>
      ) : null}
      {image && (
        <div className="radio-input__image">
          <Imgix src={image} height={36} />
        </div>
      )}
      {price && (
        <div className="radio-input__cost">
          <PriceText className="radio-input__price" tiny content={price} />
        </div>
      )}
    </div>
  );
};

InputRadio.propTypes = {
  className: PropTypes.string,
  qaClassname: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  note: PropTypes.string,
  image: PropTypes.string,
  price: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onInputChange: PropTypes.func,
  inAccordion: PropTypes.bool
};

InputRadio.defaultProps = {
  isChecked: false,
  isDisabled: false,
  inAccordion: false,
  onInputChange: () => {}
};

export default InputRadio;
