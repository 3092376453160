import styled from "styled-components";
import Row from "./Row";
import breakpoints from "../../theme/breakpoints";

const StyledRow = styled(Row)`
  max-width: 119rem;
  text-align: left;
  margin: 0 auto;
  padding-top: ${props => props.theme.SPACING.REGULAR};
  padding-bottom: ${props => props.theme.SPACING.REGULAR};
  border-bottom: ${props =>
    props.hasDivider
      ? `0.2rem solid ${props.theme.COLOURS.PRIMARY.shades[10]}`
      : "none"};
  padding-top: ${props => props.flatTop && 0};

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    padding-top: ${props =>
      props.tight ? props.theme.SPACING.REGULAR : props.theme.SPACING.LARGE};
    padding-bottom: ${props =>
      props.tight ? props.theme.SPACING.REGULAR : props.theme.SPACING.LARGE};
  }
`;

export default StyledRow;
