import Api from "./Base";
import settings from "../clientappsettings.json";
import { getDownloadCampaignsFilename } from "../shared/utility";

class CampaignManagement extends Api {
  baseUrl = settings.VENDORS_URL;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class Create extends CampaignManagement {
  requestUrl = `/Campaign/create`;
  method = "GET";
}

export class Save extends CampaignManagement {
  requestUrl = `/Campaign/save`;
  method = "POST";
}

export class SaveSupportingDocument extends CampaignManagement {
  baseUrl = settings.AZURE_VEHICLE_URL;
  requestUrl = `/api/BlobUpload?code=${settings.AZURE_VEHICLE_API_KEY}`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
    "Content-Type": "multipart/form-data",
  };
}

export class DeleteSupportingDocument extends CampaignManagement {
  baseUrl = settings.AZURE_VEHICLE_URL;
  requestUrl = `/api/BlobDelete?code=${settings.AZURE_VEHICLE_API_KEY}`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class SaveSupportingBlockDocument extends CampaignManagement {
  baseUrl = settings.AZURE_VEHICLE_URL;
  requestUrl = `/api/BlobBlockUpload?type=Campaign&sourceFolder=${
    this.getParams().sourceFolder
  }&fileName=${this.getParams().fileName}&blockNo=${
    this.getParams().blockNo
  }&blockSize=${this.getParams().blockSize}&finalBlock=${
    this.getParams().finalBlock
  }&id=${this.getParams().id}&userType=Vendor&code=${
    settings.AZURE_VEHICLE_API_KEY
  }`;
  method = "POST_BLOCK";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class FetchSupportingDocument extends CampaignManagement {
  baseUrl = settings.AZURE_VEHICLE_URL;
  requestUrl = `/api/BlobList?code=${settings.AZURE_VEHICLE_API_KEY}`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  formatResponseData(response) {
    if (response.data?.supportingDocuments) {
      return response.data.supportingDocuments;
    }
    return response;
  }
}

export class FetchVendorCampaigns extends CampaignManagement {
  requestUrl = `/Campaign/list`;
  method = "POST";

  formatRequestData(params) {
    const { pageSize, pageNumber, bucket } = params;
    return {
      pageSize,
      pageNumber,
      bucket,
    };
  }

  formatResponseData(response) {
    if (response.data) {
      return response.data;
    }
    return response;
  }
}

export class DeleteCampaign extends CampaignManagement {
  requestUrl = `/Campaign/Delete/${this.getParams().campaignId}`;
  method = "GET";
  formatResponseData(response) {
    if (response.data) {
      return response.data;
    }
    return response;
  }
}

export class FetchCampaign extends CampaignManagement {
  requestUrl = `/Campaign/${this.getParams().campaignId}`;
  method = "GET";

  formatResponseData(response) {
    if (response.data?.campaigns) {
      return response.data.campaigns;
    }
    return response;
  }
}

export class FetchCampaignGroups extends CampaignManagement {
  requestUrl = `/Campaign/Groups/${this.getParams().campaignId}`;
  method = "GET";

  formatResponseData(response) {
    if (response.data?.campaignGroups) {
      return response.data.campaignGroups;
    }
    return response;
  }
}

export class FetchAuctionCondition extends CampaignManagement {
  requestUrl = `/Campaign/Conditions/${this.getParams().campaignId}`;
  method = "GET";

  formatResponseData(response) {
    if (response.data?.auctionConditions) {
      return response.data.auctionConditions;
    }
    return response;
  }
}

export class FetchAuctionGrade extends CampaignManagement {
  requestUrl = `/Campaign/Grades/${this.getParams().campaignId}`;
  method = "GET";

  formatResponseData(response) {
    if (response.data?.auctionGrades) {
      return response.data.auctionGrades;
    }
    return response;
  }
}

export class ApproveCampaign extends CampaignManagement {
  requestUrl = `/Campaign/Approve/${this.getParams().campaignId}`;
  method = "GET";

  formatResponseData(response) {
    if (response.data?.campaigns) {
      return response.data.campaigns;
    }
    return response;
  }
}

export class FetchVendorApproveCampaignRights extends CampaignManagement {
  requestUrl = `/Campaign/ApprovalRights`;
  method = "GET";
}
export class ExpireCampaign extends CampaignManagement {
  requestUrl = `/Campaign/Expire/${this.getParams().campaignId}`;
  method = "GET";
  formatResponseData(response) {
    if (response.data) {
      return response.data;
    }
    return response;
  }
}
export class CopyCampaign extends CampaignManagement {
  requestUrl = `/Campaign/Copy/${this.getParams().campaignId}`;
  method = "GET";
  formatResponseData(response) {
    if (response.data) {
      return response.data;
    }
    return response;
  }
}

export class DownloadCampaigns extends CampaignManagement {
  method = "GET";
  requestUrl = `/Campaign/Download`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  formatResponseData(response) {
    if (response.data) {
      //downloading the Campaigns_DD_MM_YYYY_HH_mm.csv
      const blob = new Blob([response.data], { type: "application/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = getDownloadCampaignsFilename();
      link.click();
    }
    return response;
  }
}

export class Makes extends CampaignManagement {
  requestUrl = `/Campaign/Makes`;
  method = "POST";

  formatRequestData(params) {
    const { campaignRangeId, key } = params;
    return {
      campaignRangeId,
      key,
    };
  }
}

export class Ranges extends CampaignManagement {
  requestUrl = `/Campaign/ranges`;
  method = "POST";

  formatRequestData(params) {
    const { campaignRangeId, key } = params;
    return {
      campaignRangeId,
      key,
    };
  }
}

export class Trims extends CampaignManagement {
  requestUrl = `/Campaign/trims`;
  method = "POST";

  formatRequestData(params) {
    const { campaignRangeId, makeId, rangeIds } = params;
    return {
      campaignRangeId,
      makeId,
      rangeIds,
    };
  }
}

export class GetCampaignRangeLines extends CampaignManagement {
  requestUrl = `/Campaign/rangeLines/${this.getParams().campaignId}`;
  method = "GET";
}

export class CopySupportingDocumentsDirectory extends CampaignManagement {
  baseUrl = settings.AZURE_VEHICLE_URL;
  requestUrl = `/api/BlobCopyDirectory?code=${settings.AZURE_VEHICLE_API_KEY}`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}
