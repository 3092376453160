import React, { useContext } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

import SummaryPriceBreakdown from "../SummaryPriceBreakdown";
import HeaderText from "../HeaderText";
import ParagraphText from "../ParagraphText";
import Panel from "../Panel";
import Button from "../Button";
import clientSettings from "./../../../clientappsettings";
import SkeletonRows from "./../Skeletons/SkeletonRows";
import PriceText from "./../PriceText";
import { useMediaQuery } from "react-responsive";
import breakpoints from "./../../theme/breakpoints";
import ButtonLink from "./../ButtonLink";

const DealerFundingRequest = ({
  className,
  onCheckBalance,
  onProceed,
  onClose,
  priceBreakdown,
  fundingProviders,
  isFetchingFundingProviders,
  isFetchingBalance,
  jwtToken,
}) => {
  const theme = useContext(ThemeContext);

  const isPhone = useMediaQuery({
    query: `(max-width: ${breakpoints.PHONE})`,
  });

  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  const getFundingProviderImage = (blobId) => {
    return `${clientSettings.IMAGES_API_URL}/funding/fundingProvider/${blobId}?jwtToken=${jwtToken}`;
  };

  return (
    <div className={classnames(className, "qa-dealer-funding-request")}>
      <Panel flex="1" padding={isDesktop ? "NONE" : "REGULAR"}>
        <div className="modal-header">
          <HeaderText>Allocate Purchase to Funding Plan</HeaderText>
          <ParagraphText>
            Please find the below funding plans which have been enabled for your
            site. Please select 'Proceed' next to the applicable funding
            provider and once approved, this vehicle purchase will be
            automatically allocated against your existing funding plan for the
            full invoice value.
          </ParagraphText>
        </div>
      </Panel>

      <Panel flex="1" padding={isDesktop ? "NONE" : "REGULAR"}>
        <div className="modal-content">
          <div className="summary-price-breakdown">
            {priceBreakdown && (
              <SummaryPriceBreakdown
                data={priceBreakdown.data}
                labels={priceBreakdown.labels}
                inModal
                collapsable={isPhone}
              />
            )}
          </div>
          <div className="funding-provider__list">
            {!isFetchingFundingProviders && fundingProviders ? (
              fundingProviders.map((el) => (
                <div
                  className="funding-provider__row"
                  key={el.fundingProviderId}
                >
                  <div className="funding-provider__logo">
                    <img
                      src={getFundingProviderImage(el.logoBlobId)}
                      alt={el.providerName}
                    ></img>
                  </div>
                  <div className="funding-provider__actions">
                    <div className="funding-provider__check-balance">
                    {el.allowCreditCheck ? (
                      el.balance ? (
                        <PriceText content={el.balance} />
                      ) : el.balance === undefined ? (
                          <ButtonLink
                            className={"btn-link"}
                            name="Check Balance"
                            secondary
                            disabled={isFetchingBalance}
                            action={() => onCheckBalance(el.providerName)}
                            iconData={{
                              type: "arrow-forward",
                              width: "22",
                              height: "22",
                            }}
                            underlineColor={theme.COLOURS.GREEN.base}
                          />
                        ) : (
                          <ParagraphText>
                            Failed to retrieve balance
                          </ParagraphText>
                        )
                      ): null}
                    </div>
                    <Button
                      primary
                      content="Proceed"
                      action={() => onProceed(el.providerName)}
                    />
                  </div>
                </div>
              ))
            ) : (
              <SkeletonRows nrOfRows={7} paddingNone />
            )}
          </div>
        </div>
      </Panel>

      <Panel
        flex="1"
        padding={isDesktop ? "NONE" : "REGULAR"}
        actions={[
          {
            content: "Close",
            action: onClose,
            type: "ghost",
          },
        ]}
      />
    </div>
  );
};

const pricingShape = PropTypes.shape({
  net: PropTypes.number,
  vat: PropTypes.number,
  gross: PropTypes.number,
  vatCode: PropTypes.oneOf(["OutOfScope", "Exempt", "Standard", "Zero"]),
  vatQualified: PropTypes.oneOf([
    "Unknown",
    "Export",
    "Commercial",
    "Qualified",
    "NonQual",
    "NonQualified",
  ]),
});

const fundingProviderShape = PropTypes.shape({
  daysToApplyForFunding: PropTypes.number,
  epyxIdOnProviderPlatform: PropTypes.string,
  fundingProviderId: PropTypes.number,
  logoBlobId: PropTypes.number,
  providerName: PropTypes.string,
  telNo: PropTypes.string,
  webserviceBaseUrl: PropTypes.string,
});

DealerFundingRequest.propTypes = {
  className: PropTypes.string,
  fundingProviders: PropTypes.arrayOf(fundingProviderShape),
  isFetchingFundingProviders: PropTypes.bool,
  isFetchingBalance: PropTypes.bool,
  pricingDetails: PropTypes.shape({
    vehicle: pricingShape,
    adminCharge: pricingShape,
    deliveryCost: pricingShape,
    total: pricingShape,
  }),
  jwtToken: PropTypes.string,
  onCheckBalance: PropTypes.func,
  onClose: PropTypes.func,
  onProceed: PropTypes.func,
};

export default DealerFundingRequest;
