import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../components/ModalInsert";
import Panel from "../../components/Panel";
import AcceptOfferConfirmation from "../../components/AcceptOfferConfirmation";

const AcceptOfferConfirmationContainer = ({
  vendorName,
  radioOptionChoice,
}) => {
  const dispatch = useDispatch();

  const onCancel = () => {
    dispatch(closeModal());
  };

  return (
    <Fragment>
      <ModalInsert>
        <Panel
          mobileFlex
          style={{ height: "100%", borderRadius: "0.5rem" }}
          actions={[
            {
              content: "Close",
              action: onCancel,
              type: "ghost",
            },
          ]}
        >
          {
            <AcceptOfferConfirmation
              vendorName={vendorName}
              radioOptionChoice={radioOptionChoice}
            />
          }
        </Panel>
      </ModalInsert>
    </Fragment>
  );
};

export default AcceptOfferConfirmationContainer;
