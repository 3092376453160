import styled from "styled-components";

import SkeletonPopoverMultiSelect from "./SkeletonPopoverMultiSelect";

const StyledSkeletonPopoverMultiSelect = styled(SkeletonPopoverMultiSelect)`
  .skeleton-popover-multi-select__options {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding: 2.8rem 0 1rem 0;

    > span {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      padding: 1rem 1.6rem 0;
      width: 30%;
    }
  }

  .skeleton-popover-multi-select__actions {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    padding: ${props => props.theme.SPACING.REGULAR};
    width: 100%;

    span {
      ${props => props.theme.ANIMATIONS["shimmer"]};
      border-radius: 0.5rem;
      height: 5rem;
      width: 10rem;
    }
  }

  .skeleton-popover-multi-select__checkbox {
    border: 0.2rem solid ${props => props.theme.COLOURS.PRIMARY.shades[10]};
    border-radius: 0.5rem;
    height: 2.5rem;
    margin-right: 0.5rem;
    width: 2.5rem;
  }

  .skeleton-popover-multi-select__label {
    ${props => props.theme.ANIMATIONS["shimmer"]};
    height: 1rem;
    width: 90%;
  }
`;

export default StyledSkeletonPopoverMultiSelect;
