import React from "react";

const Checkbox = ({
  onInputChange,
  value,
  optionName,
  isChecked,
  isDisabled,
  count,
  hideCount,
}) => {
  return (
    <div className="checkbox">
      <span className="checkbox__input">
        {/*  TODO: pass this in as a container (can toggle) */}
        <input
          type="checkbox"
          id={value}
          name={value}
          checked={isChecked}
          disabled={isDisabled}
          onChange={() => {
            onInputChange(value);
          }}
        />
        <span className="checkbox__indicator" />
      </span>
      <label htmlFor={value}>
        <span className="checkbox__label">{optionName || value}</span>
        {!hideCount && (
        <span className="checkbox__count">({count})</span>
        )}
      </label>
    </div>
  );
};

export default Checkbox;
