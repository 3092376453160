import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../components/ModalInsert";
import breakpoints from "../../theme/breakpoints";
import { useMediaQuery } from "react-responsive";
import { clearVehicleRegRequest } from "../../../state/reducers/regSearch";
import useFilterState from "../../../shared/hooks/useFilterState";

import GenericConfirmInsert from "../../components/GenericConfirmInsert";
import { deleteClaim } from "../../../state/reducers/claims";

const ClaimDeleteModalInsert = () => {
  const dispatch = useDispatch();
  const activeClaimId = useSelector(
    (state) => state.global.claims.activeClaimId
  );

  const { updateFilter } = useFilterState({
    filterType: "primary",
    inputType: "tab",
    filterId: "claimStatusCategory",
  });

  const onDeleteClaim = () => {
    dispatch(deleteClaim(activeClaimId));
    dispatch(closeModal());
    dispatch(clearVehicleRegRequest());
    updateFilter("Completed");
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  return (
    <Fragment>
      <ModalInsert width={isDesktop ? "55rem" : "100%"}>
        <GenericConfirmInsert
          headerText="Are you sure?"
          bodyText="You are about to delete this claim, are you sure?"
          onCancel={onCancel}
          onConfirm={onDeleteClaim}
        />
      </ModalInsert>
    </Fragment>
  );
};

export default ClaimDeleteModalInsert;
