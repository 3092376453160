import React from "react";
import PropTypes from "prop-types";
import { MainContainer } from "./index";
import HeaderDetails from "./child-components/HeaderDetails";
import CampaignDetails from "./child-components/CampaignDetails";
import ButtonGroup from "./child-components/ButtonGroup";
import CampaignCriteria from "./child-components/CampaignCriteria";
import AuditDetails from "./child-components/AuditDetails";
import VehicleCriteria from "./child-components/VehicleCriteria";

const CampaignForm = ({
  data,
  campaignId,
  disabled,
  campaignDetailsProps,
  campaignCriteriaProps,
  vehicleCriteriaProps,
  buttonGroupProps,
  name,
  reference,
  description,
  disabledSaveButton,
  setDisabledSaveButton,
  hideApprove,
  disableForm,
  disableApproveButton,
  setApproveButtonDisabled,
}) => {
  return (
    <div>
      <MainContainer>
        <HeaderDetails campaignId={campaignId} data={data} />
        <CampaignDetails
          campaignDetailsProps={campaignDetailsProps}
          disabled={disabled}
          name={name}
          reference={reference}
          description={description}
          data={data}
          disableForm={disableForm}
          setApproveButtonDisabled={setApproveButtonDisabled}
        />
        <CampaignCriteria
          campaignCriteriaProps={campaignCriteriaProps}
          setDisabledSaveButton={setDisabledSaveButton}
          data={data}
          disableForm={disableForm}
          campaignId={campaignId}
          setApproveButtonDisabled={setApproveButtonDisabled}
        />
        <VehicleCriteria
          vehicleCriteriaProps={vehicleCriteriaProps}
          campaignId={campaignId}
          disableForm={disableForm}
          setDisabledSaveButton={setDisabledSaveButton}
          setApproveButtonDisabled={setApproveButtonDisabled}
        />
        <AuditDetails
          dateModified={data?.dateModified}
          modifiedBy={data?.modifiedBy}
          approvedDate={data?.approvedDate}
          approvedBy={data?.approvedBy}
          campaignStatus={data?.campaignStatus}
        />
        <div>
          <ButtonGroup
            buttonGroupProps={buttonGroupProps}
            disabledSaveButton={disabledSaveButton}
            hideApprove={hideApprove}
            name={name}
            description={description}
            reference={reference}
            disableApproveButton={disableApproveButton}
          />
        </div>
      </MainContainer>
    </div>
  );
};

export default CampaignForm;

CampaignForm.propTypes = {
  campaignId: PropTypes.number,
  name: PropTypes.string,
  setName: PropTypes.func,
  reference: PropTypes.string,
  setReference: PropTypes.func,
  description: PropTypes.string,
  setDescription: PropTypes.func,
  onCancelButtonClick: PropTypes.func,
  onCampaignSave: PropTypes.func,
  onCampaignApprove: PropTypes.func,
  onSaveSupportingDocument: PropTypes.func,
  isFetchingSupportingDocument: PropTypes.bool,
  maxNoOfMb: PropTypes.number,
  primaryParagraphText: PropTypes.string,
  secondaryParagraphText: PropTypes.string,
  handleBlockChange: PropTypes.func,
  fromDays: PropTypes.string,
  setFromDays: PropTypes.func,
  toDays: PropTypes.string,
  setToDays: PropTypes.func,
  handleFromDateChange: PropTypes.func,
  handleToDateChange: PropTypes.func,
  hideApprove: PropTypes.bool,
  disableForm: PropTypes.bool,
  vehicleCriteriaProps: PropTypes.shape({
    fromMileage: PropTypes.string,
    setFromMileage: PropTypes.func,
    toMileage: PropTypes.string,
    setToMileage: PropTypes.func,
    fromVehicleAgeMonths: PropTypes.string,
    setFromVehicleAgeMonths: PropTypes.func,
    toVehicleAgeMonths: PropTypes.string,
    setToVehicleAgeMonths: PropTypes.func,
    validFromVehicleAgeMonths: PropTypes.bool,
    setValidFromVehicleAgeMonths: PropTypes.func,
    validToVehicleAgeMonths: PropTypes.bool,
    setValidToVehicleAgeMonths: PropTypes.func,
    validFromMileage: PropTypes.bool,
    setValidFromMileage: PropTypes.func,
    validToMileage: PropTypes.bool,
    setValidToMileage: PropTypes.func,
    campaignAuctionGrade: PropTypes.array,
    setCampaignAuctionGrade: PropTypes.func,
    campaignAuctionCondition: PropTypes.array,
    setCampaignAuctionCondition: PropTypes.func,
    campaignAuctionConditionsData: PropTypes.array,
    campaignAuctionGradesData: PropTypes.array,
    validAgeLength: PropTypes.bool,
    setValidAgeLength: PropTypes.func,
    validAgeNumber: PropTypes.bool,
    setValidAgeNumber: PropTypes.func,
    validMileageNumber: PropTypes.bool,
    setValidMileageNumber: PropTypes.func,
    validMileageLength: PropTypes.bool,
    setValidMileageLength: PropTypes.func,
  }),
  campaignDetailsProps: PropTypes.shape({
    primaryParagraphText: PropTypes.string,
    secondaryParagraphText: PropTypes.string,
    maxFileSize: PropTypes.number,
    acceptedTypes: PropTypes.object,
    onDropRejected: PropTypes.func,
    handleBlockChange: PropTypes.func,
    validFileSize: PropTypes.bool,
    validFileType: PropTypes.bool,
    maxNoOfMb: PropTypes.number,
    isFetchingSupportingDocument: PropTypes.bool,
    onSaveSupportingDocument: PropTypes.func,
    setName: PropTypes.func,
    setReference: PropTypes.func,
    setDescription: PropTypes.func,
    campaignDocuments: PropTypes.array,
  }),
  campaignCriteriaProps: PropTypes.shape({
    fromDays: PropTypes.string,
    setFromDays: PropTypes.func,
    toDays: PropTypes.string,
    setToDays: PropTypes.func,
    validDate: PropTypes.bool,
    validStartDate: PropTypes.bool,
    setDatesVisible: PropTypes.func,
    datesVisible: PropTypes.bool,
    validToDays: PropTypes.bool,
    setValidToDays: PropTypes.func,
    validFromDays: PropTypes.bool,
    setValidFromDays: PropTypes.func,
    validLength: PropTypes.bool,
    setValidLength: PropTypes.func,
    validNumber: PropTypes.bool,
    setValidNumber: PropTypes.func,
    toDate: PropTypes.string,
    setToDate: PropTypes.func,
    fromDate: PropTypes.string,
    setFromDate: PropTypes.func,
    setValidDate: PropTypes.func,
    setValidStartDate: PropTypes.func,
    setDisabledSaveButton: PropTypes.func,
    campaignBuyerGroup: PropTypes.array,
    setCampaignBuyerGroup: PropTypes.func,
    campaignBuyerGroupsData: PropTypes.array,
  }),
  buttonGroupProps: PropTypes.shape({
    onCancelButtonClick: PropTypes.func,
    onCampaignSave: PropTypes.func,
    onCampaignApprove: PropTypes.func,
  }),
};
