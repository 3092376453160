import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const LogoWhite = ({ width, height, fill }) => {
  const theme = useContext(ThemeContext);
  const fillColour = fill ? fill : theme.COLOURS.WHITE;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 429.39 53.37"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={fillColour} fillRule="evenodd">
        <path
          className="b"
          d="M135,41.41V16.89h10.47c3.63,0,6.41,1.06,8.35,3.17,1.94,2.11,2.9,5.15,2.9,9.11s-1,7.16-3,9.19c-2,2.03-5.03,3.05-9.06,3.05h-9.66Zm6.31-5.14h3.41c1.94,0,3.37-.57,4.27-1.72,.9-1.14,1.36-2.94,1.36-5.38s-.45-4.25-1.36-5.39c-.9-1.14-2.33-1.71-4.27-1.71h-3.41v14.18Z"
        />
        <path
          className="b"
          d="M160.01,21.66v-4.78h5.89v4.78h-5.89Zm0,19.74V23.31h5.89v18.1h-5.89Z"
        />
        <path
          className="b"
          d="M168.62,35.88h5.93c.07,.8,.36,1.4,.89,1.8s1.29,.6,2.29,.6c.83,0,1.49-.16,1.96-.48,.48-.32,.72-.75,.72-1.3,0-.7-.93-1.29-2.8-1.76-1-.26-1.8-.48-2.41-.68-2.26-.74-3.81-1.52-4.64-2.32-.83-.8-1.25-1.86-1.25-3.17,0-1.83,.7-3.27,2.1-4.32,1.4-1.05,3.32-1.57,5.78-1.57,2.62,0,4.64,.45,6.06,1.34,1.42,.89,2.22,2.23,2.4,4h-5.69c-.12-.61-.4-1.06-.83-1.36-.43-.29-1.03-.44-1.8-.44-.71,0-1.26,.14-1.64,.41-.38,.27-.57,.66-.57,1.16,0,.71,.97,1.33,2.9,1.85,.47,.12,.83,.22,1.08,.3,1.8,.52,3.05,.91,3.75,1.17,.7,.26,1.2,.51,1.51,.74,.65,.48,1.14,1.03,1.45,1.66,.31,.63,.47,1.37,.47,2.21,0,1.96-.77,3.5-2.3,4.61s-3.67,1.66-6.41,1.66-4.81-.53-6.36-1.6c-1.55-1.07-2.41-2.57-2.56-4.51Z"
        />
        <path
          className="b"
          d="M189.08,48.66V23.31h5.93v2.41c.67-.99,1.43-1.72,2.31-2.19,.87-.47,1.89-.71,3.05-.71,2.26,0,4.1,.87,5.52,2.62s2.12,4.03,2.12,6.85-.71,5.18-2.13,6.98-3.24,2.7-5.44,2.7c-1.19,0-2.24-.24-3.17-.72-.93-.48-1.69-1.18-2.29-2.11v9.5h-5.89Zm12.79-16.08c0-1.63-.3-2.87-.91-3.73-.61-.85-1.47-1.28-2.61-1.28s-1.99,.42-2.57,1.26c-.58,.84-.87,2.09-.87,3.75,0,1.51,.31,2.67,.93,3.5,.62,.82,1.49,1.23,2.61,1.23s1.9-.42,2.51-1.26c.6-.84,.91-2,.91-3.46Z"
        />
        <path
          className="b"
          d="M209.84,32.33c0-3.03,.85-5.4,2.55-7.11,1.7-1.71,4.05-2.56,7.04-2.56s5.36,.85,7.07,2.56c1.7,1.71,2.56,4.08,2.56,7.11s-.85,5.4-2.56,7.12c-1.7,1.71-4.06,2.57-7.07,2.57s-5.37-.85-7.06-2.56c-1.69-1.71-2.53-4.08-2.53-7.13Zm6.08,0c0,1.65,.31,2.92,.92,3.81,.61,.89,1.48,1.33,2.6,1.33s2.01-.45,2.62-1.34c.62-.89,.92-2.16,.92-3.8s-.31-2.89-.92-3.78c-.62-.89-1.49-1.33-2.62-1.33s-2,.44-2.61,1.32c-.61,.88-.91,2.14-.91,3.79Z"
        />
        <path
          className="b"
          d="M230.72,35.88h5.93c.07,.8,.36,1.4,.89,1.8s1.29,.6,2.29,.6c.83,0,1.49-.16,1.96-.48,.48-.32,.72-.75,.72-1.3,0-.7-.93-1.29-2.8-1.76-1-.26-1.8-.48-2.41-.68-2.26-.74-3.81-1.52-4.64-2.32-.83-.8-1.25-1.86-1.25-3.17,0-1.83,.7-3.27,2.1-4.32,1.4-1.05,3.32-1.57,5.78-1.57,2.62,0,4.64,.45,6.06,1.34,1.42,.89,2.22,2.23,2.4,4h-5.69c-.12-.61-.4-1.06-.83-1.36-.43-.29-1.03-.44-1.8-.44-.71,0-1.26,.14-1.64,.41-.38,.27-.57,.66-.57,1.16,0,.71,.97,1.33,2.9,1.85,.47,.12,.83,.22,1.08,.3,1.8,.52,3.05,.91,3.75,1.17,.7,.26,1.2,.51,1.51,.74,.65,.48,1.14,1.03,1.45,1.66,.31,.63,.47,1.37,.47,2.21,0,1.96-.77,3.5-2.3,4.61s-3.67,1.66-6.41,1.66-4.81-.53-6.36-1.6c-1.55-1.07-2.41-2.57-2.56-4.51Z"
        />
        <path
          className="b"
          d="M267.95,41.41h-6.04c-.07-.29-.12-.59-.18-.9-.05-.31-.1-.63-.14-.97-.83,.83-1.72,1.45-2.66,1.85-.94,.4-1.97,.6-3.09,.6-1.74,0-3.12-.48-4.14-1.44s-1.52-2.26-1.52-3.89c0-1.5,.4-2.72,1.19-3.65,.79-.94,1.99-1.6,3.6-1.97,.89-.2,2.01-.42,3.38-.65,2.04-.33,3.06-.82,3.06-1.47v-.43c0-.52-.2-.92-.59-1.19-.39-.27-.98-.41-1.76-.41-.84,0-1.51,.17-2.01,.52-.49,.34-.8,.85-.92,1.51h-5.46c.29-2.04,1.13-3.56,2.54-4.56,1.4-1,3.4-1.5,5.98-1.5,1.61,0,2.97,.19,4.07,.58,1.1,.39,1.97,.98,2.59,1.76,.43,.57,.74,1.24,.92,2.02,.18,.78,.27,2.04,.27,3.77v6.86c0,.82,.07,1.47,.22,1.94s.37,.78,.67,.92v.68Zm-6.53-8.52c-.46,.29-1.25,.56-2.4,.8-.55,.11-.98,.21-1.26,.28-.71,.2-1.21,.46-1.51,.77s-.44,.73-.44,1.25c0,.64,.2,1.16,.6,1.56,.4,.39,.93,.59,1.58,.59,1.01,0,1.83-.29,2.47-.87,.64-.58,.96-1.35,.96-2.29v-2.08Z"
        />
        <path className="b" d="M270.62,41.41V16.89h5.93v24.52h-5.93Z" />
        <path
          className="b"
          d="M291.06,41.41V16.89h6.79l4.63,8.06c.75,1.31,1.47,2.61,2.15,3.89,.68,1.29,1.31,2.56,1.91,3.81-.09-1.85-.16-3.59-.2-5.21-.04-1.62-.07-3.05-.07-4.28v-6.28h6.06v24.52h-6.78l-4.81-8.29c-.57-.98-1.18-2.07-1.85-3.29-.67-1.22-1.37-2.53-2.11-3.94,.12,1.6,.21,3.09,.27,4.46,.06,1.38,.08,2.6,.08,3.66v7.39h-6.08Z"
        />
        <path
          className="b"
          d="M327.87,35.71h5.69c-.53,1.99-1.58,3.52-3.14,4.61-1.56,1.09-3.49,1.63-5.8,1.63-2.81,0-5.03-.85-6.66-2.56-1.63-1.7-2.45-4.03-2.45-6.97s.82-5.37,2.46-7.12c1.64-1.75,3.86-2.62,6.64-2.62s5.13,.89,6.78,2.66c1.64,1.78,2.46,4.23,2.46,7.36,0,.29,0,.51,0,.66,0,.15-.01,.28-.03,.39h-12.24c0,1.28,.27,2.25,.82,2.92,.55,.67,1.36,1.01,2.42,1.01,.79,0,1.44-.17,1.95-.5,.51-.33,.87-.83,1.08-1.48Zm-6.44-5.28l6.74-.02c-.02-1.17-.33-2.08-.92-2.73-.59-.66-1.41-.98-2.44-.98-.98,0-1.76,.32-2.34,.97-.58,.64-.93,1.56-1.04,2.76Z"
        />
        <path
          className="b"
          d="M334.64,26.89v-3.58h2.43v-5.13h5.93v5.13h3.03v3.58h-3.03v7.84c0,1.2,.16,1.99,.47,2.38,.31,.39,.89,.58,1.75,.58,.19,0,.34,0,.44,0,.11,0,.21-.01,.31-.03v3.78c-.62,.12-1.23,.22-1.82,.28-.59,.07-1.17,.1-1.72,.1-2.05,0-3.46-.5-4.21-1.51-.75-1-1.13-3.14-1.13-6.42v-7.01h-2.43Z"
        />
        <path
          className="b"
          d="M352.5,41.41l-5.36-18.1h6.04l2.58,12.44,2.18-12.44h6.11l2.25,12.44,2.6-12.44h6.03l-5.34,18.1h-6.11l-2.41-11.94-2.56,11.94h-5.99Z"
        />
        <path
          className="b"
          d="M375.35,32.33c0-3.03,.85-5.4,2.55-7.11,1.7-1.71,4.05-2.56,7.04-2.56s5.36,.85,7.07,2.56c1.7,1.71,2.56,4.08,2.56,7.11s-.85,5.4-2.56,7.12c-1.7,1.71-4.06,2.57-7.07,2.57s-5.37-.85-7.06-2.56c-1.69-1.71-2.53-4.08-2.53-7.13Zm6.08,0c0,1.65,.31,2.92,.92,3.81,.61,.89,1.48,1.33,2.6,1.33s2.01-.45,2.62-1.34c.62-.89,.92-2.16,.92-3.8s-.31-2.89-.92-3.78c-.62-.89-1.49-1.33-2.62-1.33s-2,.44-2.61,1.32c-.61,.88-.91,2.14-.91,3.79Z"
        />
        <path
          className="b"
          d="M397.29,41.41V23.31h5.69v3.16c.63-1.24,1.42-2.15,2.36-2.72,.94-.57,2.14-.87,3.6-.89v5.86c-.26-.03-.51-.06-.76-.07-.25-.02-.49-.03-.71-.03-.91,0-1.66,.14-2.26,.41-.59,.27-1.07,.69-1.42,1.26-.22,.38-.38,.92-.47,1.62s-.14,1.85-.14,3.42v6.08h-5.89Z"
        />
        <path
          className="b"
          d="M410.98,41.41V16.89h5.83v12.65l5.46-6.23h6.96l-6.28,6.91,6.44,11.19h-6.58l-3.83-7.16-2.18,2.28v4.88h-5.83Z"
        />
        <path
          className="c"
          d="M95.63,53.37H26.68C11.95,53.37,0,41.42,0,26.68H0C0,11.95,11.95,0,26.68,0H122.32V26.68c0,14.74-11.95,26.68-26.68,26.68Zm-40.67-13.73V15.46h-5.84v24.18h5.84Zm9.91-19.46v-4.71h-5.81v4.71h5.81Zm0,19.46V21.8h-5.81v17.84h5.81Zm9.91,0v-9.55c0-1.5,.24-2.59,.71-3.27,.47-.68,1.22-1.03,2.25-1.03,.94,0,1.59,.29,1.94,.86s.53,1.91,.53,4.01v8.98h5.84v-11.6c0-2.36-.47-4.07-1.4-5.11-.94-1.05-2.45-1.57-4.54-1.57-1.23,0-2.28,.24-3.17,.71s-1.64,1.2-2.25,2.17v-2.45h-5.71v17.84h5.81Zm20.94,0v-4.81l2.15-2.25,3.78,7.06h6.48l-6.35-11.03,6.19-6.81h-6.86l-5.38,6.14V15.46h-5.74v24.18h5.74Zm-69.91,4.93c9.77,0,17.7-7.97,17.7-17.7s-7.92-17.7-17.7-17.7S8.16,17.14,8.16,26.87s7.93,17.7,17.65,17.7m4.75-5.24V15.16h-5.5c-.26,1.81-.97,3.08-2.03,3.87-1.06,.75-2.73,1.14-5.02,1.23v5.02h5.46v14.04h7.09Z"
        />
      </g>
    </svg>
  );
};

LogoWhite.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

LogoWhite.defaultProps = {
  width: "200",
  height: "30",
};

export default LogoWhite;
