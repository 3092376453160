import styled from "styled-components";
import BidInputError from "./BidInputError";

const StyledBidInputError = styled(BidInputError)`
  align-items: center;
  background-color: ${props => props.theme.COLOURS.RED.base};
  border-radius: ${props => props.theme.SPACING.XSMALL};
  box-sizing: border-box;
  color: white;
  display: flex;
  font-family: ${props => props.theme.FONTS.families.lato};
  font-size: ${props => props.theme.FONTS.sizes[60]};
  line-height: ${props => props.lineHight || props.theme.SPACING.XSMALL};
  margin-top: ${props => props.theme.SPACING.XSMALL};
  padding: 0.75rem;
  width: 100%; 

  svg {
    margin-right: ${props => props.theme.SPACING.SMALL};
  }

  span b,
  span span,
  span strong {
    font-weight: bold;
  }
`;

export default StyledBidInputError;
