import ClaimCallbackConfirmation from "./ClaimCallbackConfirmation";
import styled from "styled-components";

const StyledClaimCallbackConfirmation = styled(ClaimCallbackConfirmation)`
  .confirmation-container {
    min-height: 45vh;
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }
`;

export default StyledClaimCallbackConfirmation;
