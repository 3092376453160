import clientSettings from "../clientappsettings.json";
import Api from "./Base";

class VehicleNoteApi extends Api {
  baseUrl = clientSettings.API_URL;
  requestUrl = "/Vehicle/shortlist/notes";
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };
}

export default VehicleNoteApi;
