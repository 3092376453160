import React, { Fragment, useRef, useState, useEffect } from "react";
import { isAddressValid } from "../../../shared/utility";
import classnames from "classnames";
import ParagraphText from "../ParagraphText";
import Button from "./../Button";
import AddressField from "./../AddressField";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../theme/breakpoints";

const AddressPicker = ({
  onFind,
  onCapture,
  foundLocations,
  selectedLocation,
  onCancel,
  onConfirmLocation,
  qaClassName,
  className,
}) => {
  const [text, setText] = useState("");
  const [timer, setTimer] = useState(null);
  const [displayResults, setDisplayResults] = useState(false);
  const [cursor, setCursor] = useState(-1);
  const [location, setLocation] = useState(null);
  const [hasError, setHasError] = useState(false);

  const isCompanyNameValid = () => {
    const firstLine = location.address.substr(
      0,
      location.address.indexOf("\n")
    );
    return firstLine === location.companyName;
  };

  const onConfirmHandle = () => {
    if (isAddressValid(location.address)) {
      return isCompanyNameValid()
        ? onConfirmLocation(location)
        : onConfirmLocation({ ...location, companyName: "" });
    }
    setHasError(true);
  };

  const resultListRef = useRef(null);

  useEffect(() => {
    if (selectedLocation && selectedLocation.length) {
      selectedLocation.map((el) =>
        setLocation({
          address: el.Label.substr(0, el.Label.lastIndexOf("\n")),
          postCode: el.PostalCode,
          companyName: el.Company,
        })
      );
    }
  }, [selectedLocation]);

  const manualLocationHandler = (e) => {
    const address = e.target.value;
    setLocation((prev) => ({
      ...prev,
      address: address,
      postCode: address.substr(address.lastIndexOf("\n") + 1, address.length),
    }));
  };

  const textHandler = (event) => {
    clearTimeout(timer);
    setText(event.target.value);

    if (event.target.value.length) {
      setTimer(setRequestTimeout(event.target.value));
    }
  };

  const inputKeyDownHandler = (event) => {
    if (resultListRef && resultListRef.current) {
      if (event.keyCode === 40) {
        //down
        setCursor((prevCursor) => {
          if (prevCursor < resultListRef.current.children.length - 1) {
            resultListRef.current.children[prevCursor + 1].scrollIntoView(
              false
            );
            return prevCursor + 1;
          }
          resultListRef.current.children[0].scrollIntoView();
          return 0;
        });
      }

      if (event.keyCode === 38) {
        //up
        setCursor((prevCursor) => {
          if (prevCursor > 0) {
            resultListRef.current.children[prevCursor - 1].scrollIntoView();
            return prevCursor - 1;
          }
          resultListRef.current.children[
            resultListRef.current.children.length - 1
          ].scrollIntoView();
          return resultListRef.current.children.length - 1;
        });
      }

      if (event.keyCode === 13) {
        //enter
        if (cursor !== -1) {
          resultListRef.current.children[cursor].click();
        }
        setCursor(-1);
      }
    }
  };

  const setRequestTimeout = (value) => {
    return setTimeout(() => {
      onFind(value);
      setDisplayResults(true);
    }, 500);
  };

  const selectHandler = (location) => {
    if (location.Type !== "Address") {
      onFind(location.Text, location.Id);
    } else {
      setDisplayResults(false);
      onCapture(location.Id);
    }
  };

  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.PHONE})`,
  });

  const confirmText = !isMobile ? "Confirm & use this address" : "Confirm";

  return (
    <div className={classnames(className, qaClassName)}>
      <div className="title">Add an address</div>
      <ParagraphText>
        Add an address by searching for your streetname or postcode below,
        selecting the right address and hitting confirm…
      </ParagraphText>
      <div className="address-picker">
        <div className="address-input">
          <p>Find your address</p>
          <input
            type="text"
            value={text}
            onChange={textHandler}
            onKeyDown={inputKeyDownHandler}
          />
        </div>
        {displayResults && foundLocations && text && (
          <Fragment>
            <div className="popover__arrow"></div>
            <div className="result-list" ref={resultListRef}>
              {foundLocations.length ? (
                foundLocations.map((el, idx) => (
                  <div
                    className={classnames(
                      "result-list__item",
                      cursor === idx ? "active" : null
                    )}
                    key={el.Id}
                    tabIndex={idx}
                    onClick={() => selectHandler(el)}
                  >
                    <ParagraphText primary>{el.Text},</ParagraphText>
                    <ParagraphText>{el.Description}</ParagraphText>
                  </div>
                ))
              ) : (
                <div className="result-list__item">
                  <ParagraphText>No results</ParagraphText>
                </div>
              )}
            </div>
          </Fragment>
        )}
        {selectedLocation && (
          <div className="address-area">
            <p>Address</p>
            <AddressField
              addressText={location ? location.address : ""}
              onChangeHandler={manualLocationHandler}
              hasError={hasError}
            />
          </div>
        )}
        <div className="address-actions">
          <Button primary ghost action={() => onCancel()} content="Close" />
          <Button
            primary
            disabled={!selectedLocation}
            content={confirmText}
            action={() => onConfirmHandle()}
          />
        </div>
      </div>
    </div>
  );
};

export default AddressPicker;
