import ClaimCancelSaleConfirmation from "./ClaimCancelSaleConfirmation";
import styled from "styled-components";

const StyledClaimCancelSaleConfirmation = styled(ClaimCancelSaleConfirmation)`
 .confirmation-container {
    min-height: 45vh;
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
 }

    & div:first-child {
      padding-left: 2rem;
    }
`;

export default StyledClaimCancelSaleConfirmation;

