import styled from "styled-components";
import KeyboardShortcuts from "./KeyboardShortcuts";
import breakpoints from "../../theme/breakpoints";

export const StyledKeyboardShortcuts = styled(KeyboardShortcuts)`
  background-color: ${(props) => props.theme.COLOURS.WHITE};
  height: 100%;
  width: 100%;

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    box-sizing: border-box;
    border: 0.2rem solid ${(props) => props.theme.COLOURS.ACCENT.base};
    padding: ${(props) => props.theme.SPACING.MEDIUM};
    border-radius: 0.9rem;
    height: auto;
  }

  .modal-header {
    > * {
      margin-bottom: ${(props) => props.theme.SPACING.REGULAR};
    }
  }

  .keyboard-shortcuts-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem 2rem;
    min-height: 5rem;

    @media (max-width: ${breakpoints.TABLET}) {
      grid-template-columns: 1fr;
    }
  }

  .keyboard-key {
    min-width: 1.7em;
    border: 1px solid #aaa;
    border-radius: 0.2em;
    box-shadow: 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    background-image: linear-gradient(to bottom, #eee, #f9f9f9, #eee);
    color: #000;
    padding: 0.1em 0.3em;
    font-family: inherit;
    font-size: 1.1em;
    float: right;
    text-align: center;
    cursor: default;
  }

  // required to separate two keyboard keys for the same use.
  .float-text {
    float: right;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

export default StyledKeyboardShortcuts;
