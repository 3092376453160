import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const CarHatchback = ({ width, height, strokeColour, strokeWidth }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(1 1)"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="32.681" cy="20.426" r="3.404" />
        <path d="M5.447 20.426H.34" />
        <circle cx="8.851" cy="20.426" r="3.404" />
        <path d="M29.277 20.426H12.255M2.043 20.426s.614-3.703 1.322-7.955A14.912 14.912 0 0118.063 0c6.29 0 11.973 3.75 14.448 9.532l1.02 2.383c5.107 0 5.958 4.396 5.958 8.51h-3.404" />
        <path d="M17.362 3.404c2.112 0 8.284-.798 11.915 6.809H8c1.626-3.404 5.236-6.809 9.362-6.809zM17.362 3.404v6.809" />
      </g>
    </svg>
  );
};

CarHatchback.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

CarHatchback.defaultProps = {
  width: "42",
  height: "26",
  strokeWidth: "2"
};

export default CarHatchback;
