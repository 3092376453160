import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddressPicker from "../../components/AddressPicker";
import { closeModal } from "../../../state/reducers/global";
import {
  find,
  capture,
  clearSelectedAddress,
} from "../../../state/reducers/addressLookup";
import { setClaimVehicleAddress } from "../../../state/reducers/claims";

const ClaimItemAddressModalInsert = () => {
  const addressLookup = useSelector((state) => state.addressLookup);

  const dispatch = useDispatch();
  const onFind = (text, container) => dispatch(find(text, container));
  const onCapture = (id) => dispatch(capture(id));
  const onCancel = () => dispatch(closeModal());

  const onConfirmLocation = (location) => {
    const addressArray = location.address.split("\n");

    dispatch(setClaimVehicleAddress(addressArray));
    dispatch(closeModal());
  };

  useEffect(() => {
    dispatch(clearSelectedAddress());
  }, [dispatch]);

  return (
    <AddressPicker
      onFind={onFind}
      onCapture={onCapture}
      foundLocations={
        addressLookup.searchResult && addressLookup.searchResult.Items
      }
      selectedLocation={
        addressLookup.selectedAddress && addressLookup.selectedAddress.Items
      }
      onCancel={onCancel}
      onConfirmLocation={onConfirmLocation}
    />
  );
};

export default ClaimItemAddressModalInsert;
