import styled, { css } from "styled-components";
import { StyledIcon } from "./../Icon";

import InfoTag from "./InfoTag.jsx";

export const StyledInfoTag = styled(InfoTag)`
  display: inline-flex;
  align-items: center;
  ${props => props.theme.FONTS.Text["paragraphBold"]}

  ${StyledIcon} {
    position: relative;
    width: 100%;
    height: 100%;
    > svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
      }
  }
  p {
    margin: 0;
    display: inline-block;
    color: ${props => props.theme.COLOURS.PRIMARY.shades[50]};
    font-size: ${props => props.theme.FONTS.sizes[40]};
  }
  ${props =>
    props.small &&
    css`
      p,
      span {
        font-size: ${props.theme.FONTS.sizes[30]};
      }
    `}

  .info-tag__icon {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 2rem;
    margin-right: 0.4rem;
    display: inline-flex;
    position: relative;
    justify-content: center;
    align-items: center;
    
    ${props =>
      props.small &&
      css`
        width: 2.4rem;
        height: 2.4rem;
      `}
    
    svg {
      width: 14px;
      height: 12px;
      ${props =>
        props.small &&
        css`
          width: 12px;
          height: 12px;
        `}
    }

  }
  ${props =>
    props.success &&
    css`
      color: ${props.theme.COLOURS.GREEN.base};
      p,
      span {
        color: ${props.theme.COLOURS.GREEN.base};
      }
      .info-tag__icon {
        background-color: ${props.theme.COLOURS.GREEN.shades[20]};
      }
    `}
  ${props =>
    props.danger &&
    css`
      color: ${props.theme.COLOURS.RED.base};
      p,
      span {
        color: ${props.theme.COLOURS.RED.base};
      }
      .info-tag__icon {
        background-color: ${props.theme.COLOURS.RED.shades[20]};
      }
    `}
    ${props =>
      props.warning &&
      css`
        color: ${props.theme.COLOURS.ORANGE.base};
        p,
        span {
          color: ${props.theme.COLOURS.ORANGE.base};
        }
        .info-tag__icon {
          background-color: ${props.theme.COLOURS.ORANGE.shades[20]};
        }
      `}
    ${props =>
      props.info &&
      css`
        color: ${props.theme.COLOURS.PRIMARY.base};
        p,
        span {
          color: ${props.theme.COLOURS.PRIMARY.base};
        }
        .info-tag__icon {
          background-color: ${props.theme.COLOURS.PRIMARY.shades[10]};
        }
      `}
  ${props =>
    props.iconText &&
    css`
      pre {
        font-size: ${props.theme.FONTS.sizes[60]};
      }
    `}
  ${props =>
    props.additionalIconText &&
    css`
      .info-tag__additional-text {
        margin-left: 0.2rem;
      }
    `}
  ${props =>
    props.inactive &&
    css`
      color: ${props.theme.COLOURS.PRIMARY.shades[20]};
      p,
      span {
        color: ${props.theme.COLOURS.PRIMARY.shades[20]};
      }
      .info-tag__icon {
        background-color: ${props.theme.COLOURS.PRIMARY.shades[10]};
      }
    `}
    ${props =>
      props.loading &&
      css`
        color: ${props.theme.COLOURS.ACCENT.base};
        p,
        span {
          color: ${props.theme.COLOURS.ACCENT.base};
        }
        .info-tag__icon {
          background-color: ${props.theme.COLOURS.ACCENT.base};
        }
      `}
`;

export default StyledInfoTag;
