import React from "react";
import propTypes from "prop-types";

const BidInputButton = props => {
  const { className, increment = 0, incrementValue } = props;

  return (
    <button className={className} onClick={() => incrementValue(increment)}>
      +{increment}
    </button>
  );
};

BidInputButton.propTypes = {
  className: propTypes.string,
  increment: propTypes.number,
  incrementValue: propTypes.func
};

export default BidInputButton;
