import BACKGROUND from "./assets/images/background.jpg";
import FAVICON from "./assets/images/favicons/favicon.ico";
import FAVICON_APPLE from "./assets/images/favicons/apple-touch-icon.png";
import LOGO_DARK from "./assets/images/seat-logo-black.png";

const IMAGES = {
  BACKGROUND,
  FAVICON,
  FAVICON_APPLE,
  LOGO_DARK
};

export default IMAGES;
