import React from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import AboutSession from "../../components/AboutSession";
import ModalInsert from "../../components/ModalInsert";

const AboutSessionModalInsert = () => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <ModalInsert>
      <AboutSession onClose={onClose} />
    </ModalInsert>
  );
};

export default AboutSessionModalInsert;
