import styled from "styled-components";

import SkeletonPopoverSingleSelect from "./SkeletonPopoverSingleSelect";

const StyledSkeletonPopoverSingleSelect = styled(SkeletonPopoverSingleSelect)`
  width: 100%;
  > span {
    ${props => props.theme.ANIMATIONS["shimmer"]};
    display: block;
    height: 1rem;
    margin: 1.6rem 1.6rem 0;
  }
`;

export default StyledSkeletonPopoverSingleSelect;
