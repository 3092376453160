import React from "react";
import PropTypes from "prop-types";
import settings from "../../../clientappsettings";
import HeaderText from "../HeaderText";
import ParagraphText from "../ParagraphText";
import SubHeaderText from "../SubHeaderText";
import BidInput from "../BidInput";
import TermsCheckbox from "../TermsCheckbox";

const BidForm = ({ className, bidData, termsData, GroupBuyerSelect }) => {
  const {
    getInitialBidValue,
    currentBidAmount,
    previousMaxBidAmount,
    openingBid,
    isDeclined,
    handleBidInputChange
  } = bidData;
  const { isChecked, onInputChange, activeBidVehicleId } = termsData;

  return (
    <div className={className}>
      <HeaderText>Place your bid</HeaderText>
      <ParagraphText>
        Please complete the fields below to place your bid on this vehicle. A
        bid will be placed for £50 above the current price, if you are outbid we
        will automatically place a bid up to your maximum bid.
      </ParagraphText>
      {GroupBuyerSelect}
      <div className="bid-form__form">
        <SubHeaderText colour="ACCENT">
          Enter your maximum bid
        </SubHeaderText>
        <BidInput
          initialValue={getInitialBidValue(
            currentBidAmount,
            previousMaxBidAmount,
            openingBid,
            isDeclined
          )}
          increments={[50, 100, 250]}
          getValue={(val, isLegalBid) => handleBidInputChange(val, isLegalBid)}
        />
      </div>
      <div className="bid-form__terms">
        <TermsCheckbox
          value="terms"
          isChecked={isChecked}
          onInputChange={() => onInputChange()}
          chunkedLabel={[
            {
              text: "I agree to the "
            },
            {
              text: "terms & conditions",
              link: {
                url: `${settings.SUB_PATH}/vehicle/terms-and-conditions/${activeBidVehicleId}`,
                target: "_blank",
                rel: "noopener noreferrer"
              }
            },
            {
              text: " of bidding on this vehicle"
            }
          ]}
        />
      </div>
    </div>
  );
};

BidForm.propTypes = {
  className: PropTypes.string,
  bidData: PropTypes.shape({
    getInitialBidValue: PropTypes.func,
    currentBidAmount: PropTypes.number,
    previousMaxBidAmount: PropTypes.number,
    openingBid: PropTypes.bool,
    isDeclined: PropTypes.bool,
    handleBidInputChange: PropTypes.func
  }),
  termsData: PropTypes.shape({
    isChecked: PropTypes.bool,
    onInputChange: PropTypes.func,
    activeBidVehicleId: PropTypes.number
  }),
  GroupBuyerSelect: PropTypes.node
};

export default BidForm;
