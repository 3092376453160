import styled from "styled-components";
import DealerFundingRequestError from "./DealerFundingRequestError";
import breakpoints from "../../../theme/breakpoints";

export const StyledDealerFundingRequestError = styled(
  DealerFundingRequestError
)`
  background-color: ${(props) => props.theme.COLOURS.WHITE};
  height: 100%;
  display: flex;
  flex-direction: column;

  //align the modal actions at the bottom of the page
  &:last-child {
    align-self: flex-end;
  }

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    box-sizing: border-box;
    border: 0.2rem solid ${(props) => props.theme.COLOURS.ACCENT.base};
    padding: ${(props) => props.theme.SPACING.MEDIUM};
    border-radius: 0.9rem;
    width: 100%;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: ${(props) => props.theme.SPACING.REGULAR};

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      margin-top: 0;
      flex-direction: row-reverse;
    }

    > span {
      margin-left: ${(props) => props.theme.SPACING.SMALL};
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      margin-top: 0;
      flex-direction: row;
      padding: ${(props) => props.theme.SPACING.MEDIUM};
    }

    > p {
      margin-left: ${(props) => props.theme.SPACING.SMALL};
      color: ${(props) => props.theme.COLOURS.RED.base};
    }
  }
`;

export default StyledDealerFundingRequestError;
