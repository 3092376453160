import React from "react";
import PropTypes from "prop-types";
import Accordion from "../Accordion";

const AccordionFilter = ({
  className,
  label,
  labelCount,
  isOpened,
  accordionAction,
  children,
  labelDetail,
  isDisabled
}) => {
  return (
    <div className={className}>
      <Accordion
        label={label}
        labelCount={labelCount}
        action={accordionAction}
        isOpened={isOpened}
        labelDetail={labelDetail}
        isDisabled={isDisabled}
      >
        {children}
      </Accordion>
    </div>
  );
};

AccordionFilter.propTypes = {
  className: PropTypes.string
};

export default AccordionFilter;
