export const declineReasonLabel = {
  HEADER_TEXT: "Decline Claim",
  SUB_HEADER_TEXT:
    "Please enter a reason below for this claim being declined. The reason you enter will be sent to the trade buyer.",
  DROPDOWN_HEADER: "Decline reason",
};

export const errorMessages = {
  MIN_LENGTH: `Enter more than 10 characters`,
  FIELD_REQUIRED: "Required to decline the claim",
};

export const dropdownPlaceholder = {
  PLACEHOLDER: "Please select",
};
