import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Settings = ({ width, height, strokeColour, strokeWidth }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 33"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(1 1)"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M26 9.413a2.446 2.446 0 001.39 3.356l1.65.587a2.447 2.447 0 010 4.612l-1.65.587A2.446 2.446 0 0026 21.911l.754 1.58a2.447 2.447 0 01-3.263 3.263l-1.583-.752a2.446 2.446 0 00-3.355 1.39l-.588 1.65a2.447 2.447 0 01-4.61 0l-.588-1.65a2.447 2.447 0 00-3.356-1.39l-1.58.752a2.446 2.446 0 01-3.263-3.263l.751-1.583a2.446 2.446 0 00-1.39-3.355l-1.65-.588a2.447 2.447 0 010-4.612l1.65-.587a2.446 2.446 0 001.39-3.353L4.568 7.83A2.446 2.446 0 017.83 4.568l1.583.751a2.447 2.447 0 003.354-1.387l.588-1.65a2.447 2.447 0 014.61 0l.588 1.65a2.446 2.446 0 003.355 1.39l1.583-.754a2.447 2.447 0 013.263 3.262l-.755 1.583z" />
        <circle cx="15.659" cy="15.662" r="5.873" />
      </g>
    </svg>
  );
};

Settings.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

Settings.defaultProps = {
  width: "33",
  height: "33",
  strokeWidth: "2"
};

export default Settings;
