import AddressLookup from "../../api/AddressLookup";

// Action constants
export const FIND_REQUEST = "@addressLookup/FIND_REQUEST";
export const FIND_REQUEST_SUCCESS = "@addressLookup/FIND_REQUEST_SUCCESS";
export const FIND_REQUEST_FAILURE = "@addressLookup/FIND_REQUEST_FAILURE";
export const CAPTURE_REQUEST = "@addressLookup/CAPTURE_REQUEST";
export const CAPTURE_REQUEST_SUCCESS = "@addressLookup/CAPTURE_REQUEST_SUCCESS";
export const CAPTURE_REQUEST_FAILURE = "@addressLookup/CAPTURE_REQUEST_FAILURE";
export const CLEAR_SELECTED_ADDRESS = "@addressLookup/CLEAR_SELECTED_ADDRESS";

// Action creators
const findSuccess = data => {
  return {
    type: FIND_REQUEST_SUCCESS,
    payload: data
  };
};

const findFailure = error => {
  return {
    type: FIND_REQUEST_FAILURE,
    payload: error
  };
};

export const find = (text, container) => dispatch => {
  dispatch({ type: FIND_REQUEST });
  const addressLookupApiInstance = new AddressLookup({
    type: "FIND_REQUEST",
    params: {
      text: text,
      container: container
    }
  });

  addressLookupApiInstance.call().then(
    response => dispatch(findSuccess(response)),
    err => {
      // TODO: error logging
      return dispatch(findFailure(err));
    }
  );
};

// Capture
const captureSuccess = data => {
  return {
    type: CAPTURE_REQUEST_SUCCESS,
    payload: data
  };
};

const captureFailure = error => {
  return {
    type: CAPTURE_REQUEST_FAILURE,
    payload: error
  };
};

export const capture = id => dispatch => {
  dispatch({ type: CAPTURE_REQUEST });
  const addressLookupApiInstance = new AddressLookup({
    type: "CAPTURE_REQUEST",
    params: {
      id: id
    }
  });

  addressLookupApiInstance.call().then(
    response => dispatch(captureSuccess(response)),
    err => {
      // TODO: error logging
      return dispatch(captureFailure(err));
    }
  );
};

export const clearSelectedAddress = () => {
  return { type: CLEAR_SELECTED_ADDRESS };
};

const initialState = {
  isFetching: false,
  error: false,
  searchResult: null,
  selectedAddress: null
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FIND_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case FIND_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case FIND_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        searchResult: action.payload
      };
    case CAPTURE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case CAPTURE_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case CAPTURE_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        selectedAddress: action.payload
      };
    case CLEAR_SELECTED_ADDRESS:
      return {
        ...state,
        selectedAddress: null
      };
    default:
      return {
        ...state
      };
  }
};

export default reducer;
