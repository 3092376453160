import React, { useEffect, useState } from "react";
import NumberButtonWrapper from "./NumberButtonWrapper";
import NumberButton from "./NumberButton";

/**
 * Essentially there are 6 layout variations in the pagination component
 *
 * 1: ('default') - When the total amount of pages are less than 5, we want to show all the numbers
 * 2: ('left') - When the active page is 1 or 2, we want to show: 1 2 3 ... 10
 * 3: ('leftMiddle') - When the active page is 3 we want to show: 1 2 3 4 ... 10
 * 4: ('middle') - When the active page is in the middle, we want to show: 1 ... 3 4 5 ...10
 * 5: ('rightMiddle') - When the active page has an offset of 3 we want to show: 1 ... 7 8 9 10
 * 6: ('right') - When the active page has an offset greater than 3 we want to show: 1 ... 8 9 10
 */

const NumberButtonsRenderer = ({
  activePage,
  totalPages,
  callback,
  scrollToMap,
  fallbackScroll
}) => {
  const [layoutVariant, setLayoutVariant] = useState("left");
  const [layoutArray, setLayoutArray] = useState([]);

  useEffect(() => {
    if (totalPages <= 5) setLayoutVariant("default");
    if (activePage <= 2 && totalPages >= 5) setLayoutVariant("left");
    if (activePage === 3 && totalPages >= 5) setLayoutVariant("leftMiddle");
    if (activePage >= 4 && activePage < totalPages - 2)
      setLayoutVariant("middle");
    if (totalPages >= 5 && activePage === totalPages - 2)
      setLayoutVariant("rightMiddle");
    if (totalPages >= 5 && activePage > totalPages - 2)
      setLayoutVariant("right");
  }, [totalPages, activePage]);

  useEffect(() => {
    switch (layoutVariant) {
      case "left":
        setLayoutArray([1, 2, 3, "...", totalPages]);
        break;
      case "leftMiddle":
        setLayoutArray([1, 2, 3, 4, "...", totalPages]);
        break;
      case "middle":
        setLayoutArray([
          1,
          "...",
          activePage - 1,
          activePage,
          activePage + 1,
          "...",
          totalPages
        ]);
        break;
      case "rightMiddle":
        setLayoutArray([
          1,
          "...",
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages
        ]);
        break;
      case "right":
        setLayoutArray([1, "...", totalPages - 2, totalPages - 1, totalPages]);
        break;
      default:
        setLayoutArray(
          Array(totalPages)
            .fill(undefined)
            .map((_, index) => index + 1)
        );
        break;
    }
  }, [layoutVariant, activePage, totalPages]);

  const handleClick = event => {
    const { value } = event.target;
    if (value !== "...") {
      callback(value);
      window.scrollTo(scrollToMap || fallbackScroll);
    }
  };

  return (
    <NumberButtonWrapper>
      {layoutArray.map((value, index) => (
        <NumberButton
          value={value}
          key={index}
          active={value === activePage}
          onClick={handleClick}
        >
          {value}
        </NumberButton>
      ))}
    </NumberButtonWrapper>
  );
};

export default NumberButtonsRenderer;
