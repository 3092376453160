import React, { useState } from "react";

export const VendorAddNoteReasonContext = React.createContext({});

export const VendorAddNoteReasonContextProvider = ({ children }) => {
  const [vendorAddNoteMessage, setVendorAddNoteMessage] = useState("");

  return (
    <VendorAddNoteReasonContext.Provider
      value={{
        vendorAddNoteMessage,
        setVendorAddNoteMessage,
      }}
    >
      {children}
    </VendorAddNoteReasonContext.Provider>
  );
};
