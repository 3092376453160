import React from "react";
import styled from "styled-components";

const NumberButtonWrapper = ({ children, className }) => (
  <div className={className}>{children}</div>
);

const StyledNumberButtonWrapper = styled(NumberButtonWrapper)`
  display: flex;
`;

export default StyledNumberButtonWrapper;
