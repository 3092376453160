import Api from "./Base";
import settings from "../clientappsettings.json";

export class GetTariffInformation extends Api {
  baseUrl = settings.BUYERS_URL;
  requestUrl = `/Buyers/Tariff`;
  method = "GET";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatResponseData(response) {
    return response;
  }
}
