import Api from "./Base";
import settings from "../clientappsettings.json";
import get from "lodash.get";

export class GetBidDetails extends Api {
  baseUrl = settings.API_URL;
  requestUrl = `/Vehicle/Bidding/${this.getParams().id}`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };

  formatResponseData(response = {}) {
    const data = get(response, "data", {});
    const groupSites = get(data, "groupSites", []);

    if (groupSites) {
      delete data.groupSites;
    }

    return {
      isGroupBuyer: groupSites && !!groupSites.length,
      groupSites: groupSites,
      vehicleId: this.getParams().id,
      bidData: {
        ...data
      }
    };
  }
}

export class PostBidConfirmation extends GetBidDetails {
  requestUrl = `/Vehicle/Bid`;
  method = "POST";

  formatResponseData(response = {}) {
    const data = get(response, "data", {});
    const bidId = get(data, "bidId", "");
    const successfulBid = get(data, "methodResult.callSuccessful", false);
    const bidStatus = get(data, "methodResult.errorMessage", "");

    return {
      vehicleId: this.getParams().vehicleId,
      bidData: {
        bidId,
        successfulBid,
        bidStatus
      }
    };
  }
}
