import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const ListView = ({ width, height, strokeColour, strokeWidth }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <path d="M1 1h1.379M7 1h17M1 8h1.379M7 8h17" />
        <g>
          <path d="M1 15h1.379M7 15h17" />
        </g>
      </g>
    </svg>
  );
};

ListView.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

ListView.defaultProps = {
  width: "25",
  height: "16",
  strokeWidth: "2"
};

export default ListView;
