import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../theme/breakpoints";
import { Fragment } from "react";
import ParagraphText from "../ParagraphText";
import HeaderText from "../HeaderText";
import { Background } from "react-imgix";
import { useSelector } from "react-redux";
import SkeletonRows from "../../components/Skeletons/SkeletonRows";
import FileUpload from "../../containers/FileUpload";
import { DragDropItemContent } from ".";
import Icon from "../Icon";
import dayjs from "dayjs";
import Button from "../../components/Button";
import ButtonLink from "../ButtonLink";
import colours from "../../theme/base/colours";

const PromotionModalComponent = ({ className, onCloseClick }) => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  const {
    isFetchingSupportingDocument,
    options,
    promotionDetails,
  } = useSelector((state) => state.vehicle?.promotionDetails);

  const showVendorLogo = useSelector(
    (state) => state.authentication.meta.themeOptions.showVendorLogo
  );

  const content = () => (
    <Fragment>
      <HeaderText fontSize={!isDesktop && 60}>
        {promotionDetails?.campaignName}
      </HeaderText>
      <ParagraphText fontSize={!isDesktop && 40}>
        Ref: {promotionDetails?.campaignReference}
      </ParagraphText>
    </Fragment>
  );

  const promotionDescription = promotionDetails?.campaignDescription.split(
    "\n"
  );

  return (
    <div className={className} style={{ height: isDesktop ? "50rem" : "100%" }}>
      <div className="promotion-details-header">
        {!isDesktop && (
          <div className="promotion-details-title">{content()}</div>
        )}
        {isDesktop && content()}
      </div>
      {isDesktop && (
        <div className="promotion-content">
          <div
            className="promotion-description"
            style={{ fontSize: !isDesktop ? "1.4rem" : "inherit" }}
          >
            {promotionDescription && (
              <ParagraphText primary>
                {promotionDescription.map((line, index) => (
                  <Fragment key={index}>
                    {line}
                    <br />
                  </Fragment>
                ))}
              </ParagraphText>
            )}
          </div>
          <div className="promotion-additional-details">
            {promotionDetails?.vendorLogo && showVendorLogo && (
              <div className="promotion-vendor-logo">
                <Background
                  src={promotionDetails.vendorLogo}
                  imgixParams={{ fit: "clamp" }}
                />
              </div>
            )}
            {promotionDetails?.toDate && (
              <div className="promotion-expiry-date">
                <Icon
                  type="calendarDots"
                  width="23"
                  height="23"
                  strokeColour={colours.SECONDARY.shades[80]}
                />
                <ParagraphText primary>
                  Valid until:{" "}
                  {dayjs(promotionDetails?.toDate).format("DD/MM/YYYY")}
                </ParagraphText>
              </div>
            )}
            <div className="promotion-details-drag-drop">
              <div className="promotion-details-drag-drop-header">
                <Icon
                  type="downloadSquare"
                  width="23"
                  height="23"
                  strokeColour={colours.SECONDARY.shades[80]}
                />
                <ParagraphText primary>Additional files</ParagraphText>
              </div>
              <DragDropItemContent>
                {!isFetchingSupportingDocument ? (
                  options?.promotionDocuments &&
                  options?.promotionDocuments.map((item, index) => {
                    return (
                      <FileUpload
                        key={index}
                        documentOrImage={item}
                        disabled={false}
                        disabledTrashCan={true}
                      />
                    );
                  })
                ) : (
                  <SkeletonRows
                    nrOfRows={1}
                    paddingNone
                    className="skeleton-rows"
                  />
                )}
                {!isFetchingSupportingDocument &&
                promotionDetails?.campaignUrls?.length
                  ? promotionDetails?.campaignUrls.map((link, index) => (
                      <div key={index} className="promotion-urls">
                        <ButtonLink
                          key={link.value}
                          listItem
                          primary
                          name={link.key}
                          action={() => {
                            window.open(link.value, "_blank");
                          }}
                        />
                      </div>
                    ))
                  : ""}
              </DragDropItemContent>
            </div>
          </div>
        </div>
      )}
      {!isDesktop && (
        <div className="promotion-content" style={{ flexDirection: "column" }}>
          <div
            className="promotion-additional-details"
            style={{ width: "100%" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {promotionDetails?.vendorLogo && showVendorLogo && (
                <div className="promotion-vendor-logo">
                  <Background
                    src={promotionDetails.vendorLogo}
                    imgixParams={{ fit: "clamp" }}
                  />
                </div>
              )}
              {promotionDetails?.toDate && (
                <div className="promotion-expiry-date">
                  <Icon
                    type="calendarDots"
                    width="23"
                    height="23"
                    strokeColour={colours.SECONDARY.shades[80]}
                  />
                  <ParagraphText primary>
                    Valid until:{" "}
                    {dayjs(promotionDetails?.toDate).format("DD/MM/YYYY")}
                  </ParagraphText>
                </div>
              )}
            </div>
          </div>
          <div
            className="promotion-description"
            style={{
              fontSize: "1.4rem",
              width: "auto",
              height: "50%",
              overflow: "auto",
            }}
          >
            {promotionDescription && (
              <ParagraphText primary>
                {promotionDescription.map((line, index) => (
                  <Fragment key={index}>
                    {line}
                    <br />
                  </Fragment>
                ))}
              </ParagraphText>
            )}
          </div>
          <div
            className="promotion-details-drag-drop"
            style={{ display: "flex", flexDirection: "column", height: "50%" }}
          >
            <div className="promotion-details-drag-drop-header">
              <Icon
                type="downloadSquare"
                width="23"
                height="23"
                strokeColour={colours.SECONDARY.shades[80]}
              />
              <ParagraphText primary>Additional files</ParagraphText>
            </div>
            <div>
              <DragDropItemContent>
                {!isFetchingSupportingDocument ? (
                  options?.promotionDocuments &&
                  options?.promotionDocuments.map((item, index) => {
                    return (
                      <FileUpload
                        key={index}
                        documentOrImage={item}
                        disabled={false}
                        disabledTrashCan={true}
                      />
                    );
                  })
                ) : (
                  <SkeletonRows
                    nrOfRows={1}
                    paddingNone
                    className="skeleton-rows"
                  />
                )}
                {!isFetchingSupportingDocument &&
                promotionDetails?.campaignUrls?.length
                  ? promotionDetails?.campaignUrls.map((link, index) => (
                      <div key={index} className="promotion-urls">
                        <ButtonLink
                          key={link.value}
                          listItem
                          primary
                          name={link.key}
                          action={() => {
                            window.open(link.value, "_blank");
                          }}
                        />
                      </div>
                    ))
                  : ""}
              </DragDropItemContent>
            </div>
          </div>
        </div>
      )}
      <div className="actions-area">
        <Button
          style={{ width: "100%" }}
          ghost
          content="Close"
          action={onCloseClick}
        />
      </div>
    </div>
  );
};

PromotionModalComponent.propTypes = {
  className: PropTypes.string,
  onCloseClick: PropTypes.func,
};

export default PromotionModalComponent;
