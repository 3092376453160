import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Purchase = ({ width, height, strokeColour }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.WHITE;
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <g
        stroke={stroke}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M16.075 18.625H3.925a2.25 2.25 0 01-2.233-2.529l1.183-9.471h14.25l1.183 9.471a2.25 2.25 0 01-2.233 2.529h0zM5.5 4.75H4.425a.75.75 0 00-.643.364l-.907 1.511h14.25l-.907-1.51a.75.75 0 00-.643-.365H14.5"
          strokeWidth="1.5"
        />
        <path d="M13 4.375a3 3 0 00-6 0v2.25h6v-2.25z" strokeWidth="1.5" />
        <path
          d="M12.39 10.048l-3.488 3.488-1.264-1.211c-.1-.1-.263-.1-.363 0l-.923.923c-.1.1-.1.262 0 .363l2.369 2.314c.1.1.262.1.363 0l4.591-4.591c.1-.1.1-.263 0-.364l-.923-.922c-.1-.1-.262-.1-.363 0z"
          strokeWidth="1.1"
        />
      </g>
    </svg>
  );
};

Purchase.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string
};

Purchase.defaultProps = {
  width: "20",
  height: "20"
};

export default Purchase;
