/**
 * Filter helpers
 */
const SEARCH_PAGE_FILTERS = {
  /**
   * Technically all filters are dependant on each other,
   * but some are dependant from a UI perspective - this
   * implementation is specifically aimed at tying together
   * two checkbox types - ('make' and 'range' in this example)
   * into one compound state object.
   */
  compoundTypes: [{ compound: "makeRanges", parent: "make", child: "range" }],
  checkboxTypes: [
    "make",
    "range",
    "fuelType",
    "transmissionType",
    "primaryColour",
    "conditionType",
    "bodyType",
    // TODO: the `showMe` types should be consolidated in the backend
    "searchShowMe",
    // TODO: the `vendor` types should be consolidated in the backend
    "vendor",
    "grade",
    "biddingSaleEvent",
    "promotion",
  ],
  selectTypes: [
    "maxPrice",
    "engineSize",
    "odometer",
    "damagePercentage",
    "age",
  ],
  rangeTypes: [],
  inputTypes: ["modelVariant", "capId"],
  tabTypes: [],
};

const MESSAGE_PAGE_FILTERS = {
  compoundTypes: [],
  checkboxTypes: ["showMe", "groupSite", "messageTypes"],
  selectTypes: [],
  rangeTypes: [],
  inputTypes: [],
  tabTypes: [],
};

const BIDS_AND_REQUESTS_PAGE_FILTERS = {
  compoundTypes: [],
  checkboxTypes: [
    // TODO: the `showMe` types should be consolidated in the backend
    "bidsAndRequestsShowMe",
    "groupSite",
  ],
  selectTypes: [],
  rangeTypes: [],
  inputTypes: [],
  tabTypes: [],
};

const PURCHASES_PAGE_FILTERS = {
  compoundTypes: [],
  checkboxTypes: ["groupSite", "purchaseStatus"],
  selectTypes: [],
  rangeTypes: ["purchasedDate"],
  inputTypes: [],
  tabTypes: [],
};

const COMPLETED_CLAIMS_PAGE_FILTERS = {
  compoundTypes: [],
  checkboxTypes: [],
  selectTypes: [],
  rangeTypes: ["claimDate"],
  inputTypes: [],
  tabTypes: [],
};

const NEW_CLAIM_REQUESTS_PAGE_FILTERS = {
  compoundTypes: [],
  checkboxTypes: [],
  selectTypes: ["newClaimsClaimStatus"],
  rangeTypes: [],
  inputTypes: [],
  tabTypes: [],
};

const INVOICES_PAGE_FILTERS = {
  compoundTypes: [],
  checkboxTypes: [
    "invoiceType",
    // TODO: the `vendor` types should be consolidated in the backend
    "invoiceVendor",
    "groupSite",
  ],
  selectTypes: [],
  rangeTypes: ["taxPointDate"],
  inputTypes: [],
  tabTypes: [],
};

const ACTIVITY_LOG_PAGE_FILTERS = {
  compoundTypes: [],
  checkboxTypes: ["activityType", "activitySiteName"],
  selectTypes: [],
  rangeTypes: ["activityEventDate"],
  inputTypes: [],
  tabTypes: [],
};

const CLAIMS_PAGE_FILTERS = {
  compoundTypes: [],
  checkboxTypes: [],
  selectTypes: [],
  rangeTypes: [],
  inputTypes: [],
  tabTypes: ["claimStatusCategory"],
};

const IN_PROGRESS_CLAIMS_PAGE_FILTERS = {
  compoundTypes: [],
  checkboxTypes: [],
  selectTypes: ["newClaimsClaimStatus"],
  rangeTypes: [],
  inputTypes: [],
  tabTypes: [],
};

const FILTERS_BY_PAGE = {
  search: {
    ...SEARCH_PAGE_FILTERS,
  },
  messages: {
    ...MESSAGE_PAGE_FILTERS,
  },
  bidsAndRequests: {
    ...BIDS_AND_REQUESTS_PAGE_FILTERS,
  },
  purchases: {
    ...PURCHASES_PAGE_FILTERS,
  },
  invoices: {
    ...INVOICES_PAGE_FILTERS,
  },
  activityLogs: {
    ...ACTIVITY_LOG_PAGE_FILTERS,
  },
  claims: {
    ...CLAIMS_PAGE_FILTERS,
  },
  completedClaims: {
    ...COMPLETED_CLAIMS_PAGE_FILTERS,
  },
  newClaimRequests: {
    ...NEW_CLAIM_REQUESTS_PAGE_FILTERS,
  },
  inProgressClaims: {
    ...IN_PROGRESS_CLAIMS_PAGE_FILTERS,
  },
};

/**
 *
 * @param { 'selectTypes' | 'checkboxTypes' | 'rangeTypes' | 'inputTypes' | 'compoundTypes' | 'tabTypes' }type
 * @returns {string[]}
 */
export const getAllFilters = (type) => {
  const setOfAll = new Set();
  const allFilters = [
    ...FILTERS_BY_PAGE.search[type],
    ...FILTERS_BY_PAGE.messages[type],
    ...FILTERS_BY_PAGE.bidsAndRequests[type],
    ...FILTERS_BY_PAGE.purchases[type],
    ...FILTERS_BY_PAGE.invoices[type],
    ...FILTERS_BY_PAGE.activityLogs[type],
    ...FILTERS_BY_PAGE.claims[type],
    ...FILTERS_BY_PAGE.completedClaims[type],
    ...FILTERS_BY_PAGE.newClaimRequests[type],
    ...FILTERS_BY_PAGE.inProgressClaims[type],
  ];
  allFilters.forEach((filter) => setOfAll.add(filter));
  return Array.from(setOfAll);
};

const allFilters = {
  compoundTypes: getAllFilters("compoundTypes"),
  checkboxTypes: getAllFilters("checkboxTypes"),
  selectTypes: getAllFilters("selectTypes"),
  rangeTypes: getAllFilters("rangeTypes"),
  inputTypes: getAllFilters("inputTypes"),
  tabTypes: getAllFilters("tabTypes"),
};

/**
 *
 * @param {[string]} page
 * @returns {*|{checkboxTypes: string[], selectTypes: string[], rangeTypes: string[], compoundTypes: string[]}}
 */
const getFiltersByPage = (page) => FILTERS_BY_PAGE[page] || allFilters;

// Mapping the state fields to api params
export const FILTER_MAP = {
  // TODO: this is temp
  bidsAndRequestsShowMe: "bidsAndRequestsShowMeTypes",
  bodyType: "bodyTypes",
  conditionType: "conditionTypes",
  purchaseStatus: "purchaseStatus",
  engineSize: "engineSize",
  fuelType: "fuelTypes",
  grade: "grades",
  damagePercentage: "damagePercentage",
  invoiceType: "invoiceTypes",
  // TODO: this is temp
  invoiceVendor: "invoiceVendors",
  maxPrice: "maxPrice",
  range: "ranges",
  // TODO: this is temp
  searchShowMe: "showMeTypes",
  taxPointDate: {
    from: "taxPointDateFrom",
    to: "taxPointDateTo",
  },
  modelVariant: "derivative",
  transmissionType: "transmissionTypes",
  primaryColour: "primaryColour",
  vendor: "vendors",
  groupSite: "groupBuyerSites",
  showMe: "showMeTypes",
  messageTypes: "messageTypes",
  biddingSaleEvent: "biddingSaleEvents",
  capId: "capId",
  activityEventDate: {
    from: "activityEventDateFrom",
    to: "activityEventDateTo",
  },
  activityType: "activityTypes",
  activitySiteName: "activitySiteNames",
  purchasedDate: {
    from: "purchasedDateFrom",
    to: "purchasedDateTo",
  },
  claimDate: {
    from: "claimDateFrom",
    to: "claimDateTo",
  },
  claimStatusCatogory: "claimStatusCategory",
  newClaimsClaimStatus: "newClaimsClaimStatus",
  promotion: "promotions",
};
/**
 * Sets up the initial filter states for the reducer
 * @returns {{}}
 */
export const getFilters = () => {
  const {
    rangeTypes,
    selectTypes,
    checkboxTypes,
    inputTypes,
    tabTypes,
  } = allFilters;

  const checkboxReducer = (previousResult, currentItem) => ({
    ...previousResult,
    [currentItem]: { selected: { byValue: {}, values: [] }, options: [] },
  });
  const selectReducer = (previousResult, currentItem) => ({
    ...previousResult,
    [currentItem]: { selected: null, options: [] },
  });
  const rangeReducer = (previousResult, currentItem) => ({
    ...previousResult,
    [currentItem]: { selected: { from: null, to: null } },
  });
  const inputReducer = (previousResult, currentItem) => ({
    ...previousResult,
    [currentItem]: { selected: { value: null } },
  });
  const tabReducer = (previousResult, currentItem) => ({
    ...previousResult,
    [currentItem]: { selected: null, options: [] },
  });

  return {
    ...checkboxTypes.reduce(checkboxReducer, {}),
    ...selectTypes.reduce(selectReducer, {}),
    ...rangeTypes.reduce(rangeReducer, {}),
    ...inputTypes.reduce(inputReducer, {}),
    ...tabTypes.reduce(tabReducer, {}),
  };
};

/**
 * Formats the filter state shape for consumption by the API endpoint.
 * The filterObject will only contain the filters relevant to the endpoint
 * @param {{filterObject: Object, [pageType]: string, [withCompoundFilters]: boolean }} params
 * @returns {{}}
 */
const formatFilterOptions = (params = {}) => {
  const {
    filterObject = {},
    pageType = "all",
    withCompoundFilters = true,
  } = params;

  const {
    rangeTypes,
    selectTypes,
    checkboxTypes,
    compoundTypes,
    inputTypes,
    tabTypes,
  } = getFiltersByPage(pageType);

  const compoundBlacklist = {
    make: true,
    range: true,
  };

  /**
   * We are working across all of the filter types in the config,
   * but we are sometimes only interested in specific filters - so
   * we check upfront if we can bail early
   */

  const checkboxReducer = (previousResult, currentItem) => {
    // Compound types are currently only a combination of checkbox types
    return !filterObject[currentItem] ||
      (withCompoundFilters && compoundBlacklist[currentItem])
      ? previousResult
      : {
          ...previousResult,
          [FILTER_MAP[currentItem] || currentItem]: filterObject[currentItem]
            .selected.values,
        };
  };

  const selectReducer = (previousResult, currentItem) =>
    !filterObject[currentItem]
      ? previousResult
      : {
          ...previousResult,
          [FILTER_MAP[currentItem] || currentItem]: filterObject[currentItem]
            .selected,
        };

  const rangeReducer = (previousResult, currentItem) =>
    !filterObject[currentItem]
      ? previousResult
      : {
          ...previousResult,
          [FILTER_MAP[currentItem]["from"]]:
            filterObject[currentItem].selected.from,
          [FILTER_MAP[currentItem]["to"]]:
            filterObject[currentItem].selected.to,
        };

  const inputReducer = (previousResult, currentItem) =>
    !filterObject[currentItem]
      ? previousResult
      : {
          ...previousResult,
          [FILTER_MAP[currentItem] || currentItem]: filterObject[currentItem]
            .selected.value,
        };

  const compoundChildReducer = (filterName, parentValue) => (
    previousResult,
    currentItem
  ) => {
    if (
      currentItem.groupName === parentValue &&
      filterObject[filterName.child].selected.values.includes(currentItem.value)
    ) {
      return [...previousResult, currentItem.value];
    }
    return previousResult;
  };

  const compoundReducer = (previousResult, currentItem) =>
    !filterObject[currentItem.parent]
      ? previousResult
      : {
          ...previousResult,
          [FILTER_MAP[currentItem.compound] ||
          currentItem.compound]: filterObject[
            currentItem.parent
          ].selected.values.map((parentValue) => ({
            [FILTER_MAP[currentItem.parent] || currentItem.parent]: parentValue,
            [FILTER_MAP[currentItem.child] || currentItem.child]: filterObject[
              currentItem.child
            ].options.reduce(
              compoundChildReducer(currentItem, parentValue),
              []
            ),
          })),
        };

  const tabReducer = (previousResult, currentItem) =>
    !filterObject[currentItem]
      ? previousResult
      : {
          ...previousResult,
          [FILTER_MAP[currentItem] || currentItem]: filterObject[currentItem]
            .selected,
        };

  let formattedFilterOptions = {
    ...checkboxTypes.reduce(checkboxReducer, {}),
    ...selectTypes.reduce(selectReducer, {}),
    ...rangeTypes.reduce(rangeReducer, {}),
    ...inputTypes.reduce(inputReducer, {}),
    ...tabTypes.reduce(tabReducer, {}),
  };

  if (withCompoundFilters) {
    formattedFilterOptions = {
      ...formattedFilterOptions,
      ...compoundTypes.reduce(compoundReducer, {}),
    };
  }

  return formattedFilterOptions;
};

/**
 * Counts the number of selected options of a secondary filter
 * @param {{ countObject: {} | string | null, inputType: string }}
 *
 * @returns number
 */
const countSelectedItems = ({ countObject, inputType }) => {
  switch (inputType) {
    case "select":
      return countObject ? 1 : 0;
    case "range": {
      let rangeCount = 0;
      if (countObject.from) {
        rangeCount = rangeCount + 1;
      }
      if (countObject.to) {
        rangeCount = rangeCount + 1;
      }
      return rangeCount;
    }
    case "compoundParent":
    case "compoundChild":
    case "checkbox":
      return countObject.values.length;
    case "input":
      return countObject ? 1 : 0;
    default:
      return 0;
  }
};

export { getFiltersByPage, formatFilterOptions, countSelectedItems };
