import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "./../../../views/components/ModalInsert";
import { useNavigate } from "react-router";
import { makeOffer } from "../../../state/reducers/claimDetailsVendor";
import MakeOfferConfirmation from "../../components/MakeOffer/MakeOfferConfirmation";
import GenericConfirmInsert from "./../../../views/components/GenericConfirmInsert";

const MakeOfferDecision = ({ offerData, buyerName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [offerMade, setOfferMade] = useState(false);

  const onMakeOffer = () => {
    dispatch(makeOffer(offerData));

    setTimeout(() => {
      dispatch(closeModal());
      navigate(-1);
    }, 2500);
    setOfferMade(true);
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  const getOfferText = () => {
    let text = "You are about to offer ";
    text +=
      offerData.offerAmount === null
        ? ""
        : `£${offerData.offerAmount.toLocaleString()}`;
    text +=
      offerData.offerAmount !== null && offerData.offerSaleCancellation
        ? " or "
        : "";
    text += offerData.offerSaleCancellation ? "Sale Cancellation" : "";
    text += ` to ${buyerName}, are you sure?`;
    return text;
  };

  return (
    <Fragment>
      <ModalInsert width="auto">
        {offerMade ? (
          <MakeOfferConfirmation />
        ) : (
          <GenericConfirmInsert
            headerText="Are you sure?"
            bodyText={getOfferText()}
            onCancel={onCancel}
            onConfirm={onMakeOffer}
          />
        )}
      </ModalInsert>
    </Fragment>
  );
};

export default MakeOfferDecision;
