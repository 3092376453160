import {
  CreateClaim,
  FetchClaim,
  UpdateClaim,
  SubmitClaim,
  FetchSubCategories,
  FetchClaims,
  RegSearch,
  RequestCallBack,
  DeleteClaim,
  CloseClaim,
  FetchCategories,
  AcceptOffer,
  FetchClaimsReport,
} from "../../api/Claim";
import {
  DeleteSupportingDocument,
  SaveSupportingBlockDocument,
  FetchSupportingDocument,
} from "../../api/SupportingDocument";
import { openModal, closeModal } from "./global";
import { updateObject, USER_TYPE_MAP } from "../../shared/utility";
import { setTabFilterOption } from "../../state/reducers/filter";
import { fetchFilterOptions } from "../reducers/filter";

// Action constants
export const FETCH_CLAIMS = "@claims/FETCH_CLAIMS";
export const FETCH_CLAIMS_SUCCESS = "@claims/FETCH_CLAIMS_SUCCESS";
export const FETCH_CLAIMS_FAILURE = "@claims/FETCH_CLAIMS_FAILURE";
export const FETCH_CLAIMS_REPORT = "@claims/FETCH_CLAIMS_REPORT";
export const FETCH_CLAIMS_REPORT_SUCCESS =
  "@claims/FETCH_CLAIMS_REPORT_SUCCESS";
export const FETCH_CLAIMS_REPORT_FAILURE =
  "@claims/FETCH_CLAIMS_REPORT_FAILURE";
export const FETCH_CLAIM_INFO = "@claim/FETCH_CLAIM_INFO";
export const FETCH_CLAIM_INFO_SUCCESS = "@claim/FETCH_CLAIM_INFO_SUCCESS";
export const FETCH_CLAIM_INFO_FAILURE = "@claim/FETCH_CLAIM_INFO_FAILURE";
export const CREATE_CLAIM = "@claim/CREATE_CLAIM";
export const CREATE_CLAIM_SUCCESS = "@claim/CREATE_CLAIM_SUCCESS";
export const CREATE_CLAIM_FAILURE = "@claim/CREATE_CLAIM_FAILURE";
export const UPDATE_CLAIM = "@claim/UPDATE_CLAIM";
export const UPDATE_CLAIM_SUCCESS = "@claim/UPDATE_CLAIM_SUCCESS";
export const UPDATE_CLAIM_FAILURE = "@claim/UPDATE_CLAIM_FAILURE";
export const SUBMIT_CLAIM = "@claim/SUBMIT_CLAIM";
export const SUBMIT_CLAIM_SUCCESS = "@claim/SUBMIT_CLAIM_SUCCESS";
export const SUBMIT_CLAIM_FAILURE = "@claim/SUBMIT_CLAIM_FAILURE";
export const SET_VEHICLE_ADDRESS = "@claim/SET_VEHICLE_ADDRESS";
export const FETCH_SUBCATEGORIES = "@claims/FETCH_SUBCATEGORIES";
export const FETCH_SUBCATEGORIES_SUCCESS =
  "@claims/FETCH_SUBCATEGORIES_SUCCESS";
export const FETCH_SUBCATEGORIES_FAILURE =
  "@claims/FETCH_SUBCATEGORIES_FAILURE";
export const CLEAR_SUBCATEGORIES = "@claims/CLEAR_SUBCATEGORIES";
export const REG_SEARCH = "@claim/REG_SEARCH";
export const REG_SEARCH_SUCCESS = "@claim/REG_SEARCH_SUCCESS";
export const REG_SEARCH_FAILURE = "@claim/REG_SEARCH_FAILURE";
export const CLEAR_REG_SEARCH = "@claims/CLEAR_REG_SEARCH";
export const CLEAR_CLAIMS = "@claims/CLEAR_CLAIMS";
export const REQUEST_CALL_BACK = "@claim/REQUEST_CALL_BACK";
export const REQUEST_CALL_BACK_SUCCESS = "@claim/REQUEST_CALL_BACK_SUCCESS";
export const REQUEST_CALL_BACK_FAILURE = "@claim/REQUEST_CALL_BACK_FAILURE";
export const CLEAR_ACTIVE_CLAIM = "@claims/CLEAR_ACTIVE_CLAIM";
export const DELETE_CLAIM = "@claim/DELETE_CLAIM";
export const DELETE_CLAIM_SUCCESS = "@claim/DELETE_CLAIM_SUCCESS";
export const DELETE_CLAIM_FAILURE = "@claim/DELETE_CLAIM_FAILURE";
export const CLOSE_CLAIM = "@claim/CLOSE_CLAIM";
export const CLOSE_CLAIM_SUCCESS = "@claim/CLOSE_CLAIM_SUCCESS";
export const CLOSE_CLAIM_FAILURE = "@claim/CLOSE_CLAIM_FAILURE";
export const FETCH_CATEGORIES = "@claim/FETCH_CATEGORIES";
export const FETCH_CATEGORIES_SUCCESS = "@claim/FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "@claim/FETCH_CATEGORIES_FAILURE";
export const ACCEPT_OFFER = "@claim/ACCEPT_OFFER";
export const ACCEPT_OFFER_SUCCESS = "@claim/ACCEPT_OFFER_SUCCESS";
export const ACCEPT_OFFER_FAILURE = "@claim/ACCEPT_OFFER_FAILURE";
export const SAVE_SUPPORTING_DOCUMENT = "@claim/SAVE_SUPPORTING_DOCUMENT";
export const DELETE_SUPPORTING_DOCUMENT = "@claim/DELETE_SUPPORTING_DOCUMENT";
export const DELETE_SUPPORTING_DOCUMENT_SUCCESS =
  "@claim/DELETE_SUPPORTING_DOCUMENT_SUCCESS";
export const DELETE_SUPPORTING_DOCUMENT_FAILURE =
  "@claim/DELETE_SUPPORTING_DOCUMENT_FAILURE";
export const SAVE_SUPPORTING_BLOCK_DOCUMENT =
  "@claim/SAVE_SUPPORTING_BLOCK_DOCUMENT";
export const SAVE_SUPPORTING_BLOCK_DOCUMENT_SUCCESS =
  "@claim/SAVE_SUPPORTING_BLOCK_DOCUMENT_SUCCESS";
export const SAVE_SUPPORTING_BLOCK_DOCUMENT_FAILURE =
  "@claim/SAVE_SUPPORTING_BLOCK_DOCUMENT_FAILURE";
export const FETCH_SUPPORTING_DOCUMENT = "@claim/FETCH_SUPPORTING_DOCUMENT";
export const FETCH_SUPPORTING_DOCUMENT_FAILURE =
  "@claim/FETCH_SUPPORTING_DOCUMENT_FAILURE";
export const FETCH_SUPPORTING_DOCUMENT_SUCCESS =
  "@claim/FETCH_SUPPORTING_DOCUMENT_SUCCESS";
export const SET_FILE_ERROR = "@claim/SET_FILE_ERROR";

// Action creators
export const fetchClaimsSuccess = (data) => {
  return {
    type: FETCH_CLAIMS_SUCCESS,
    payload: data,
  };
};

export const fetchClaimsFailure = (error) => {
  return {
    type: FETCH_CLAIMS_FAILURE,
    payload: error,
  };
};

export const fetchClaims = () => (dispatch, getState) => {
  dispatch({ type: FETCH_CLAIMS });

  const toolbar = getState().toolbar;
  const pageNumber = getState().pagination.pageNumber;
  const registration = getState().regSearch.value;
  const { pageSize, sort } = toolbar;
  const { claimStatusCategory } = getState().filter.filters;

  let params = { pageSize, pageNumber, registration };

  if (Object.keys(sort).length) {
    params = {
      ...params,
      sort,
    };
  }

  if (claimStatusCategory.selected !== null) {
    params = {
      ...params,
      claimStatusCategory: claimStatusCategory.selected,
    };
  }

  const claimsInstance = new FetchClaims({
    params,
    credentials: getState().authentication.credentials,
  });

  claimsInstance.call().then(
    (response) => {
      dispatch(fetchClaimsSuccess(response));
    },
    (err) => {
      dispatch(fetchClaimsFailure(err));
      return dispatch(openModal("generic-error"));
    },
  );
};

export const fetchClaimSuccess = (data) => {
  return {
    type: FETCH_CLAIM_INFO_SUCCESS,
    payload: data,
  };
};

export const fetchClaimFailure = (error) => {
  return {
    type: FETCH_CLAIM_INFO_FAILURE,
    payload: error,
  };
};

export const fetchClaim = (claimId) => (dispatch, getState) => {
  dispatch({ type: FETCH_CLAIM_INFO });

  const claimInstance = new FetchClaim({
    params: {
      claimId: claimId,
    },
    credentials: getState().authentication.credentials,
  });

  claimInstance.call().then(
    (response) => {
      dispatch(fetchClaimSuccess(response.data));
      dispatch(fetchSupportingDocuments(claimId, USER_TYPE_MAP.Buyer));
    },
    (err) => {
      dispatch(fetchClaimFailure(err));
      return dispatch(openModal("generic-error"));
    },
  );
};

export const deleteClaimSuccess = (data) => {
  return {
    type: DELETE_CLAIM_SUCCESS,
    payload: data,
  };
};

export const deleteClaimFailure = (error) => {
  return {
    type: DELETE_CLAIM_FAILURE,
    payload: error,
  };
};

export const deleteClaim = (claimId) => (dispatch, getState) => {
  dispatch({ type: DELETE_CLAIM });

  const claimInstance = new DeleteClaim({
    params: {
      claimId: claimId,
    },
    credentials: getState().authentication.credentials,
  });

  claimInstance.call().then(
    (response) => {
      dispatch(deleteClaimSuccess(response.data));
      dispatch(
        fetchFilterOptions({
          filterId: getState().global.filters.activeFilter,
        }),
      );
    },
    (err) => {
      dispatch(deleteClaimFailure(err));
      return dispatch(openModal("generic-error"));
    },
  );
};

export const closeClaimSuccess = (data) => {
  return {
    type: CLOSE_CLAIM_SUCCESS,
    payload: data,
  };
};

export const closeClaimFailure = (error) => {
  return {
    type: CLOSE_CLAIM_FAILURE,
    payload: error,
  };
};

export const closeClaim = (claimId) => (dispatch, getState) => {
  dispatch({ type: CLOSE_CLAIM });

  const claimInstance = new CloseClaim({
    params: {
      claimId: claimId,
    },
    credentials: getState().authentication.credentials,
  });

  claimInstance.call().then(
    (response) => {
      dispatch(closeClaimSuccess(response.data));
      dispatch(
        fetchFilterOptions({
          filterId: getState().global.filters.activeFilter,
        }),
      );
    },
    (err) => {
      dispatch(closeClaimFailure(err));
      return dispatch(openModal("generic-error"));
    },
  );
};

export const acceptOffer = (formData) => (dispatch, getState) => {
  dispatch({ type: ACCEPT_OFFER });

  const claimInstance = new AcceptOffer({
    params: formData,
    credentials: getState().authentication.credentials,
  });

  claimInstance.call().then(
    (response) => {
      dispatch(acceptOfferSuccess(response.data));
      dispatch(
        fetchFilterOptions({
          filterId: getState().global.filters.activeFilter,
        }),
      );
    },
    (err) => {
      dispatch(acceptOfferFailure(err));
      return dispatch(openModal("generic-error"));
    },
  );
};

export const acceptOfferSuccess = (data) => {
  return {
    type: ACCEPT_OFFER_SUCCESS,
    payload: data,
  };
};

export const acceptOfferFailure = (error) => {
  return {
    type: ACCEPT_OFFER_FAILURE,
    payload: error,
  };
};

export const createClaim = (vehiclePurchaseId) => (dispatch, getState) => {
  dispatch({ type: CREATE_CLAIM });

  const newClaimInstance = new CreateClaim({
    params: {
      vehiclePurchaseId: vehiclePurchaseId,
    },
    credentials: getState().authentication.credentials,
  });

  newClaimInstance.call().then(
    (response) => {
      dispatch(createClaimSuccess(response.data));
    },
    (err) => {
      dispatch(createClaimFailure(err));
      return dispatch(openModal("generic-error"));
    },
  );
};

export const createClaimSuccess = (data) => {
  return {
    type: CREATE_CLAIM_SUCCESS,
    payload: data,
  };
};

export const createClaimFailure = (error) => {
  return {
    type: CREATE_CLAIM_FAILURE,
    payload: error,
  };
};

export const setClaimVehicleAddress = (address) => (dispatch) => {
  dispatch({ type: SET_VEHICLE_ADDRESS, payload: address });
};

export const updateClaim =
  (data, typeOfUpdate, navigate) => (dispatch, getState) => {
    dispatch({ type: UPDATE_CLAIM });

    if (typeOfUpdate === "update") {
      const claimInstance = new UpdateClaim({
        params: data,
        credentials: getState().authentication.credentials,
      });

      claimInstance.call().then(
        (response) => {
          if (
            response.data.methodResult.errorMessage === "ClaimAlreadyRaised"
          ) {
            dispatch(updateClaimFailure(response));
            return dispatch(openModal("claim-already-raised"));
          } else {
            dispatch(updateClaimSuccess(response));
            navigate(`/claims`);
          }
        },
        (err) => {
          dispatch(updateClaimFailure(err));
          return dispatch(openModal("generic-error"));
        },
      );
    }

    if (typeOfUpdate === "submit") {
      try {
        dispatch(updateClaimSuccess(data, typeOfUpdate));
        dispatch(openModal("confirm-claim"));
      } catch {
        dispatch(updateClaimFailure());
      }
    }
  };

export const updateClaimSuccess = (data, typeOfUpdate) => {
  return {
    type: UPDATE_CLAIM_SUCCESS,
    payload: data,
    typeOfUpdate,
  };
};

export const updateClaimFailure = (error) => {
  return {
    type: UPDATE_CLAIM_FAILURE,
    payload: error,
  };
};

export const submitClaim = (data) => (dispatch, getState) => {
  dispatch({ type: SUBMIT_CLAIM });

  const claimInstance = new SubmitClaim({
    params: data,
    credentials: getState().authentication.credentials,
  });

  const isModalOpen = getState().global.modals.isOpen;

  claimInstance.call().then(
    (response) => {
      if (response.data.methodResult.errorMessage === "ClaimAlreadyRaised") {
        dispatch(submitClaimFailure(response));
        return dispatch(openModal("claim-already-raised"));
      } else {
        dispatch(
          setTabFilterOption({
            filterId: "claimStatusCategory",
            value: "InProgress",
            withRefresh: false,
          }),
        );
        dispatch(submitClaimSuccess(response));
        if (isModalOpen) {
          dispatch(closeModal());
        }
        dispatch(clearActiveClaim());
      }
    },
    (err) => {
      dispatch(submitClaimFailure(err));
      return dispatch(openModal("generic-error"));
    },
  );
};

export const submitClaimSuccess = (data) => {
  return {
    type: SUBMIT_CLAIM_SUCCESS,
    payload: data,
  };
};

export const submitClaimFailure = (error) => {
  return {
    type: SUBMIT_CLAIM_FAILURE,
    payload: error,
  };
};

export const fetchSubCategories = (claimCategoryId) => (dispatch, getState) => {
  dispatch({ type: FETCH_SUBCATEGORIES });

  const subCategoryInstance = new FetchSubCategories({
    params: {
      claimCategoryId,
    },
    credentials: getState().authentication.credentials,
  });

  subCategoryInstance.call().then(
    (response) => {
      dispatch(fetchSubCategoriesSuccess(response.data));
    },
    (err) => {
      dispatch(fetchSubCategoriesFailure(err));
      return dispatch(openModal("generic-error"));
    },
  );
};

export const fetchSubCategoriesSuccess = (data) => {
  return {
    type: FETCH_SUBCATEGORIES_SUCCESS,
    payload: data,
  };
};

export const fetchSubCategoriesFailure = (error) => {
  return {
    type: FETCH_SUBCATEGORIES_FAILURE,
    payload: error,
  };
};

export const clearSubCategories = () => {
  return {
    type: CLEAR_SUBCATEGORIES,
  };
};

export const clearClaims = () => {
  return {
    type: CLEAR_CLAIMS,
  };
};

export const clearActiveClaim = () => {
  return {
    type: CLEAR_ACTIVE_CLAIM,
  };
};

export const regSearch = (regNo) => (dispatch, getState) => {
  dispatch({ type: REG_SEARCH, payload: regNo });

  const regSearchInstance = new RegSearch({
    params: {
      regNo,
    },
    credentials: getState().authentication.credentials,
  });

  regSearchInstance.call().then(
    (response) => {
      dispatch(regSearchSuccess(response.data));
    },
    (err) => {
      dispatch(regSearchFailure(err));
      return dispatch(openModal("generic-error"));
    },
  );
};

export const regSearchSuccess = (data) => {
  return {
    type: REG_SEARCH_SUCCESS,
    payload: data,
  };
};

export const regSearchFailure = (error) => {
  return {
    type: REG_SEARCH_FAILURE,
    payload: error,
  };
};

export const clearRegRequest = () => {
  return {
    type: CLEAR_REG_SEARCH,
  };
};

export const requestCallBack = (data) => (dispatch, getState) => {
  dispatch({ type: REQUEST_CALL_BACK });

  const requestCallBackInstance = new RequestCallBack({
    params: data,
    credentials: getState().authentication.credentials,
  });

  requestCallBackInstance.call().then(
    (response) => {
      dispatch(requestCallBackSuccess(response.data, data.claimId));
    },
    (err) => {
      dispatch(requestCallBackFailure(err));
      return dispatch(openModal("generic-error"));
    },
  );
};

export const requestCallBackSuccess = (data, claimId) => {
  return {
    type: REQUEST_CALL_BACK_SUCCESS,
    payload: {
      data,
      claimId,
    },
  };
};

export const requestCallBackFailure = (error) => {
  return {
    type: REQUEST_CALL_BACK_FAILURE,
    payload: error,
  };
};

export const checkCategoriesAndUpdateClaim =
  (updatedClaim, action, navigate) => (dispatch, getState) => {
    dispatch({ type: FETCH_CATEGORIES });

    const claimInstance = new FetchCategories({
      params: {
        claimId: updatedClaim.claimId,
      },
      credentials: getState().authentication.credentials,
    });

    claimInstance.call().then(
      (response) => {
        dispatch(fetchCategoriesSuccess(response));
        if (
          !response.find((x) => x.value === updatedClaim.claimCategoryId)
            ?.expired
        ) {
          dispatch(updateClaim(updatedClaim, action, navigate));
        }
      },
      (err) => {
        dispatch(fetchCategoriesFailure(err));
        return dispatch(openModal("generic-error"));
      },
    );
  };

export const fetchCategoriesSuccess = (data) => {
  return {
    type: FETCH_CATEGORIES_SUCCESS,
    payload: data,
  };
};

export const fetchCategoriesFailure = (error) => {
  return {
    type: FETCH_CATEGORIES_FAILURE,
    payload: error,
  };
};

export const deleteSupportingDocument =
  (claimId, fileName) => (dispatch, getState) => {
    dispatch({ type: DELETE_SUPPORTING_DOCUMENT });

    const instance = new DeleteSupportingDocument({
      params: { claimId, fileName },
      credentials: getState().authentication.credentials,
    });

    instance.call().then(
      (response) => {
        dispatch(deleteSupportingDocumentSuccess(response, fileName));
      },
      (err) => {
        return dispatch(deleteSupportingDocumentFailure(err));
      },
    );
  };

export const deleteSupportingDocumentSuccess = (data, fileName) => {
  return {
    type: DELETE_SUPPORTING_DOCUMENT_SUCCESS,
    payload: { data, fileName },
  };
};

export const deleteSupportingDocumentFailure = (error) => {
  return {
    type: DELETE_SUPPORTING_DOCUMENT_FAILURE,
    payload: error,
  };
};

export const saveSupportingDocument = (file, keyValue) => {
  const extendedData = extendSupportingDocument(file, keyValue);
  return {
    type: SAVE_SUPPORTING_DOCUMENT,
    payload: extendedData,
  };
};

export const saveSupportingBlockDocument = (data) => (dispatch, getState) => {
  dispatch({ type: SAVE_SUPPORTING_BLOCK_DOCUMENT });

  const instance = new SaveSupportingBlockDocument({
    params: data,
    credentials: getState().authentication.credentials,
  });

  instance.call().then(
    (response) => {
      dispatch(saveSupportingBlockDocumentSuccess(response, data));
    },
    (err) => {
      if (err.toString().includes("403")) {
        dispatch({
          type: SET_FILE_ERROR,
          payload: { fileName: data.fileName, error: err },
        });
      } else {
        return dispatch(saveSupportingBlockDocumentFailure(err));
      }
    },
  );
};

export const saveSupportingBlockDocumentSuccess = (
  response,
  data,
  fileName,
) => {
  return {
    type: SAVE_SUPPORTING_BLOCK_DOCUMENT_SUCCESS,
    payload: { response, data, fileName },
  };
};

export const saveSupportingBlockDocumentFailure = (error) => {
  return {
    type: SAVE_SUPPORTING_BLOCK_DOCUMENT_FAILURE,
    payload: { error },
  };
};

export const fetchSupportingDocuments =
  (claimId, userType) => (dispatch, getState) => {
    dispatch({ type: FETCH_SUPPORTING_DOCUMENT });

    const claimInstance = new FetchSupportingDocument({
      params: {
        claimId,
        userType,
      },
      credentials: getState().authentication.credentials,
    });

    claimInstance.call().then(
      (response) => {
        response.data.forEach((keyValuePair) => {
          dispatch(saveSupportingDocument(null, keyValuePair));
        });
        dispatch({ type: FETCH_SUPPORTING_DOCUMENT_SUCCESS });
      },
      (err) => {
        dispatch(fetchSupportingDocumentFailure(err));
      },
    );
  };

export const fetchSupportingDocumentFailure = (error) => {
  return {
    type: FETCH_SUPPORTING_DOCUMENT_FAILURE,
    payload: error,
  };
};

const disallowCallBackById = (claims, claimId) => {
  const claimIdx = claims.findIndex((el) => el.claimId === claimId);
  let newClaimsArr = [...claims];
  newClaimsArr[claimIdx] = { ...newClaimsArr[claimIdx], allowCallBack: false };
  return newClaimsArr;
};

const extendSupportingDocument = (file, keyValue) => {
  const blockSize = 1048576 * 4; //it's 4MB
  const addForRemainder = file?.size % blockSize === 0 ? 0 : 1;
  const noOfBlocks = Math.floor(file?.size / blockSize) + addForRemainder;

  return {
    file: file,
    fileName: file?.name ?? keyValue?.key,
    progress: 0,
    beginingOfTheBlock: 0,
    endOfTheBlock: blockSize,
    noOfBlocks: noOfBlocks || 1,
    blockNo: 1,
    uploaded: !!keyValue?.value,
    url: keyValue?.value,
  };
};

export const fetchClaimsReport = () => (dispatch, getState) => {
  dispatch({ type: FETCH_CLAIMS_REPORT });

  const claimsDownloadInstance = new FetchClaimsReport({
    params: null,
    credentials: getState().authentication.credentials,
  });

  claimsDownloadInstance.call().then(
    (response) => dispatch(fetchClaimsReportSuccess(response)),
    (err) => {
      return dispatch(fetchClaimsReportFailure(err));
    },
  );
};

export const fetchClaimsReportSuccess = () => {
  return {
    type: FETCH_CLAIMS_REPORT_SUCCESS,
    payload: true,
  };
};

export const fetchClaimsReportFailure = (error) => {
  return {
    type: FETCH_CLAIMS_REPORT_FAILURE,
    payload: error,
  };
};

const initialState = {
  isFetching: false,
  isUpdating: false,
  isRequestingCallBack: false,
  isFetchingReport: false,
  error: false,
  data: {
    totalCount: 0,
    CurentPage: 1,
    PageSize: 25,
    PageCount: 0,
    claims: [],
  },
  activeClaim: {
    isFetching: false,
    isUpdating: false,
    isDeletingSupportingDocument: false,
    isFetchingSupportingDocument: false,
    error: false,
    data: {
      vendorId: null,
      vehicleId: null,
      claimId: null,
      regNumber: "",
      make: "",
      model: "",
      derivative: "",
      status: "Unsubmitted",
      claimCategoryId: null,
      claimSubCategoryId: null,
      claimReason: "",
      contactName: "",
      telNo: null,
      email: "",
      vehicleAddress: [],
      options: {
        categories: [],
        questionAnswer: [],
        supportingDocuments: [],
      },
      allowCallBack: false,
    },
    methodResults: {
      callSuccessful: true,
      errorMessage: null,
      errorCode: null,
    },
  },
  subCategories: {
    isFetching: false,
    error: false,
    data: [],
  },
  regSearch: {
    isFetching: false,
    error: false,
    value: null,
    data: {},
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLAIMS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_CLAIMS_SUCCESS:
      return updateObject(state, {
        isFetching: false,
        totalCount: action.payload.totalCount,
        pageSize: action.payload.pageSize,
        pageCount: action.payload.pageCount,
        data: action.payload,
      });
    case FETCH_CLAIMS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case FETCH_CLAIM_INFO:
      return {
        ...state,
        activeClaim: {
          ...state.activeClaim,
          isFetching: true,
        },
      };
    case FETCH_CLAIM_INFO_SUCCESS:
      return {
        ...state,
        activeClaim: {
          ...state.activeClaim,
          isFetching: false,
          data: action.payload.claim,
        },
      };
    case FETCH_CLAIM_INFO_FAILURE:
      return {
        ...state,
        activeClaim: {
          ...state.activeClaim,
          isFetching: false,
          error: action.payload,
        },
      };
    case UPDATE_CLAIM:
      return {
        ...state,
        isUpdating: true,
        activeClaim: {
          ...state.activeClaim,
          isUpdating: true,
        },
      };
    case UPDATE_CLAIM_SUCCESS:
      if (action.typeOfUpdate === "submit") {
        return {
          ...state,
          isUpdating: false,
          activeClaim: {
            ...state.activeClaim,
            data: action.payload,
            isUpdating: false,
          },
        };
      } else {
        return {
          ...state,
          isUpdating: false,
          activeClaim: {
            ...state.activeClaim,
            isUpdating: false,
          },
        };
      }
    case SUBMIT_CLAIM:
      return {
        ...state,
        isUpdating: true,
        activeClaim: {
          ...state.activeClaim,
          data: action.payload,
          isUpdating: true,
        },
      };
    case SUBMIT_CLAIM_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        activeClaim: {
          ...state.activeClaim,
          data: action.payload,
          isUpdating: false,
        },
      };
    case SUBMIT_CLAIM_FAILURE:
      return {
        ...state,
        isUpdating: false,
        activeClaim: {
          ...state.activeClaim,
          isUpdating: false,
          error: action.payload,
        },
      };
    case UPDATE_CLAIM_FAILURE:
      return {
        ...state,
        activeClaim: {
          ...state.activeClaim,
          isUpdating: false,
          error: action.payload,
        },
      };
    case CREATE_CLAIM:
      return {
        ...state,
        activeClaim: {
          ...state.activeClaim,
          isFetching: true,
        },
      };
    case CREATE_CLAIM_SUCCESS:
      return {
        ...state,
        activeClaim: {
          ...state.activeClaim,
          isFetching: false,
          data: action.payload.claim,
        },
        data:
          state.data.length > 0
            ? [...state.data.claims, action.payload.claim]
            : action.payload.claim,
      };
    case SET_VEHICLE_ADDRESS:
      return {
        ...state,
        activeClaim: {
          ...state.activeClaim,
          data: {
            ...state.activeClaim.data,
            vehicleAddress: action.payload,
          },
        },
      };
    case CREATE_CLAIM_FAILURE:
      return {
        ...state,
        activeClaim: {
          ...state.activeClaim,
          isFetching: false,
          error: action.payload,
        },
      };
    case FETCH_SUBCATEGORIES:
      return {
        ...state,
        subCategories: {
          ...state.subCategories,
          isFetching: true,
        },
      };
    case FETCH_SUBCATEGORIES_SUCCESS: {
      return {
        ...state,
        subCategories: {
          isFetching: false,
          data: action.payload.subCategories,
        },
      };
    }
    case FETCH_SUBCATEGORIES_FAILURE: {
      return {
        ...state,
        subCategories: {
          isFetching: false,
          error: action.payload,
        },
      };
    }
    case CLEAR_SUBCATEGORIES: {
      return {
        ...state,
        subCategories: {
          data: [],
        },
      };
    }
    case REG_SEARCH:
      return {
        ...state,
        regSearch: {
          ...state.regSearch,
          isFetching: true,
          value: action.payload,
        },
      };
    case REG_SEARCH_SUCCESS: {
      return {
        ...state,
        regSearch: {
          ...state.regSearch,
          isFetching: false,
          data: action.payload.regSearchResponse,
        },
      };
    }
    case REG_SEARCH_FAILURE: {
      return {
        ...state,
        regSearch: {
          isFetching: false,
          error: action.payload,
        },
      };
    }
    case CLEAR_REG_SEARCH:
      return {
        ...state,
        regSearch: {
          ...initialState.regSearch,
        },
      };
    case CLEAR_CLAIMS:
      return {
        ...initialState,
      };
    case REQUEST_CALL_BACK:
      return {
        ...state,
        isRequestingCallBack: true,
      };
    case REQUEST_CALL_BACK_SUCCESS:
      return {
        ...state,
        isRequestingCallBack: false,
        data: {
          ...state.data,
          claims: disallowCallBackById(
            state.data.claims,
            action.payload.claimId,
          ),
        },
      };
    case REQUEST_CALL_BACK_FAILURE:
      return {
        ...state,
        isRequestingCallBack: false,
        error: action.payload,
      };

    case CLEAR_ACTIVE_CLAIM:
      return {
        ...state,
        activeClaim: {
          ...state.activeClaim,
          data: [],
        },
      };

    case DELETE_CLAIM:
      return {
        ...state,
        isUpdating: true,
        activeClaim: {
          ...state.activeClaim,
          isUpdating: true,
        },
      };
    case DELETE_CLAIM_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        activeClaim: {
          ...state.activeClaim,
          data: action.payload,
          isUpdating: false,
        },
      };
    case DELETE_CLAIM_FAILURE:
      return {
        ...state,
        isUpdating: false,
        activeClaim: {
          ...state.activeClaim,
          isUpdating: false,
          error: action.payload,
        },
      };
    case CLOSE_CLAIM:
      return {
        ...state,
        isUpdating: true,
        activeClaim: {
          ...state.activeClaim,
          isUpdating: true,
        },
      };
    case CLOSE_CLAIM_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        activeClaim: {
          ...state.activeClaim,
          data: action.payload,
          isUpdating: false,
        },
      };
    case CLOSE_CLAIM_FAILURE:
      return {
        ...state,
        isUpdating: false,
        activeClaim: {
          ...state.activeClaim,
          isUpdating: false,
          error: action.payload,
        },
      };
    case ACCEPT_OFFER:
      return {
        ...state,
        isUpdating: true,
      };
    case ACCEPT_OFFER_SUCCESS:
      return {
        ...state,
        isUpdating: false,
      };
    case ACCEPT_OFFER_FAILURE:
      return {
        ...state,
        isUpdating: false,
        activeClaim: {
          ...state.activeClaim,
          isUpdating: false,
          error: action.payload,
        },
      };
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        activeClaim: {
          ...state.activeClaim,
          data: {
            ...state.activeClaim.data,
            options: {
              ...state.activeClaim.options,
              categories: action.payload,
            },
          },
        },
      };
    case FETCH_CATEGORIES_FAILURE:
      return {
        ...initialState,
      };
    case SAVE_SUPPORTING_DOCUMENT:
      return {
        ...state,
        activeClaim: {
          ...state.activeClaim,
          data: {
            ...state.activeClaim.data,
            options: {
              ...state.activeClaim.data.options,
              supportingDocuments: [
                ...(state.activeClaim.data.options.supportingDocuments || []),
                action.payload,
              ],
            },
          },
        },
      };
    case DELETE_SUPPORTING_DOCUMENT:
      return {
        ...state,
        activeClaim: {
          ...state.activeClaim,
          isDeletingSupportingDocument: true,
        },
      };
    case DELETE_SUPPORTING_DOCUMENT_SUCCESS:
      return {
        ...state,
        activeClaim: {
          ...state.activeClaim,
          isDeletingSupportingDocument: false,
          data: {
            ...state.activeClaim.data,
            options: {
              ...state.activeClaim.data.options,
              supportingDocuments:
                state.activeClaim.data.options.supportingDocuments.filter(
                  (document) => document.fileName !== action.payload.fileName,
                ),
            },
          },
        },
      };
    case DELETE_SUPPORTING_DOCUMENT_FAILURE:
      return {
        ...state,
        activeClaim: {
          ...state.activeClaim,
          isDeletingSupportingDocument: false,
        },
      };
    case SAVE_SUPPORTING_BLOCK_DOCUMENT:
      return {
        ...state,
        activeClaim: {
          ...state.activeClaim,
          isUpdating: true,
        },
      };
    case SAVE_SUPPORTING_BLOCK_DOCUMENT_SUCCESS:
      return {
        ...state,
        error: false,
        activeClaim: {
          ...state.activeClaim,
          isUpdating: false,
          data: {
            ...state.activeClaim.data,
            options: {
              ...state.activeClaim.data.options,
              supportingDocuments:
                state.activeClaim.data.options.supportingDocuments.map(
                  (document) =>
                    document.fileName === action.payload.data.fileName
                      ? {
                          ...document,
                          beginingOfTheBlock:
                            document.beginingOfTheBlock +
                            action.payload.data.blockSize,
                          endOfTheBlock:
                            document.endOfTheBlock +
                            action.payload.data.blockSize,
                          blockNo: document.blockNo + 1,
                          progress: action.payload.data.percentage,
                          uploaded: action.payload.data.finalBlock,
                          url: action.payload.response?.data?.value,
                          duplicateDocument: false,
                        }
                      : document,
                ),
            },
          },
        },
      };
    case SET_FILE_ERROR:
      return {
        ...state,
        error: false,
        activeClaim: {
          ...state.activeClaim,
          isUpdating: false,
          data: {
            ...state.activeClaim.data,
            options: {
              ...state.activeClaim.data.options,
              supportingDocuments:
                state.activeClaim.data.options.supportingDocuments.map(
                  (document) =>
                    document.fileName === action.payload.fileName
                      ? {
                          ...document,
                          duplicateDocument: true,
                        }
                      : document,
                ),
            },
          },
        },
      };
    case SAVE_SUPPORTING_BLOCK_DOCUMENT_FAILURE:
      return {
        ...state,
        activeClaim: {
          ...state.activeClaim,
          isUpdating: false,
        },
      };
    case FETCH_SUPPORTING_DOCUMENT:
      return {
        ...state,
        activeClaim: {
          ...state.activeClaim,
          isFetchingSupportingDocument: true,
        },
      };
    case FETCH_SUPPORTING_DOCUMENT_FAILURE:
      return {
        ...state,
        activeClaim: {
          ...state.activeClaim,
          isFetchingSupportingDocument: false,
        },
      };
    case FETCH_SUPPORTING_DOCUMENT_SUCCESS:
      return {
        ...state,
        activeClaim: {
          ...state.activeClaim,
          isFetchingSupportingDocument: false,
        },
      };
    case FETCH_CLAIMS_REPORT:
      return {
        ...state,
        isFetchingReport: true,
      };
    case FETCH_CLAIMS_REPORT_FAILURE:
      return {
        ...state,
        isFetchingReport: false,
        error: action.payload,
      };
    case FETCH_CLAIMS_REPORT_SUCCESS:
      return {
        ...state,
        isFetchingReport: false,
      };
    default:
      return state;
  }
};

export default reducer;
