import Api from "./Base";
import settings from "../clientappsettings.json";

export class GetDocuments extends Api {
  baseUrl = settings.API_URL;
  requestUrl = `/vehicle/vehicleDocuments/${this.getParams().id}`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };
}
