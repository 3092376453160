import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const CarSaloon = ({ width, height, strokeColour, strokeWidth }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(1 1)"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="32.997" cy="15.811" r="3.437" />
        <path d="M5.499 15.811H.344" />
        <circle cx="8.937" cy="15.811" r="3.437" />
        <path d="M29.56 15.811H12.374M2.062 15.811V9.796A2.578 2.578 0 014.64 7.218h2.578l2.963-5.924c.29-.582.886-.95 1.536-.95H25.92c.65 0 1.245.368 1.536.95l2.963 5.924h6.874a2.578 2.578 0 012.578 2.578v4.296c0 .95-.77 1.719-1.718 1.719h-1.72M30.419 7.218H7.218M18.389.344v6.874" />
      </g>
    </svg>
  );
};

CarSaloon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

CarSaloon.defaultProps = {
  width: "42",
  height: "22",
  strokeWidth: "2"
};

export default CarSaloon;
