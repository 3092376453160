export const SET_PAGE_SIZE = "@toolbar/SET_PAGE_SIZE";
export const SET_SORT_OPTION = "@toolbar/SET_SORT_OPTION";
export const CLEAR_TOOLBAR_STATE = "@toolbar/CLEAR_TOOLBAR_STATE";
export const SET_BUCKET_TYPE = "@toolbar/SET_BUCKET_TYPE";
export const SET_FILTER_TYPE = "@toolbar/SET_FILTER_TYPE";

export const setPageSize = (pageSize) => {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
};

export const setBucketType = (bucketType) => {
  return {
    type: SET_BUCKET_TYPE,
    payload: bucketType,
  };
};

export const setFilterType = (filterType) => {
  return {
    type: SET_FILTER_TYPE,
    payload: filterType,
  };
};

export const setSortOption = (sortOption) => {
  return {
    type: SET_SORT_OPTION,
    payload: sortOption,
  };
};

export const clearToolbarState = () => {
  return {
    type: CLEAR_TOOLBAR_STATE,
  };
};

const initialState = {
  pageSize: 25,
  sort: {},
  bucketType: "all",
  filterType: "",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case SET_SORT_OPTION:
      return {
        ...state,
        sort: action.payload,
      };
    case CLEAR_TOOLBAR_STATE:
      return {
        ...state,
        ...initialState,
      };
    case SET_BUCKET_TYPE:
      return {
        ...state,
        bucketType: action.payload,
      };
    case SET_FILTER_TYPE:
      return {
        ...state,
        filterType: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
