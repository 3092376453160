import styled from "styled-components";

import AlertInsert from "./AlertInsert";
import { StyledHeaderText } from "../HeaderText";
import { StyledParagraphText } from "../ParagraphText";
import { StyledButton } from "../Button";

export default styled(AlertInsert)`
  max-width: 55.2rem;
  border: 0.2rem solid ${props => props.theme.COLOURS.RED.base};
  border-radius: 0.5rem;
  padding: 5.4rem 6rem 5rem;
  text-align: center;

  ${StyledHeaderText} {
    margin-top: ${props => props.theme.SPACING.MEDIUM};
    color: ${props => props.theme.COLOURS.RED.base};
  }

  ${StyledParagraphText} {
    margin: 2.7rem auto 4.3rem auto;
    max-width: 32rem;
  }

  .alert-insert__actions {
    display: flex;
    margin: 0 auto;
    max-width: 43rem;
    justify-content: center;

    ${StyledButton} + ${StyledButton} {
      margin-left: ${props => props.theme.SPACING.REGULAR};
    }
  }
`;
