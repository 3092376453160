import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { closeModalWithNavigation } from "../../../state/reducers/global";
import ModalInsert from "../../components/ModalInsert";
import Panel from "../../components/Panel";
import AlertInsert from "../../components/AlertInsert";
import useIsLoggedInUserType from "../../../shared/hooks/useIsLoggedInUserType";
import { USER_TYPE_MAP } from "../../../shared/utility";

const GenericError = ({ headerText, bodyText }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const buyerUser = useIsLoggedInUserType(USER_TYPE_MAP.Buyer);

  const onBackToSearchClick = () => {
    if (buyerUser) {
      return dispatch(closeModalWithNavigation("/", navigate));
    }
    return navigate(-1);
  };

  const changeButtonText = buyerUser
    ? "Return to search"
    : "Return to previous page";

  return (
    <ModalInsert>
      <Panel padding="NONE">
        <AlertInsert
          action={onBackToSearchClick}
          headerText={headerText}
          bodyText={bodyText}
          buttonText={changeButtonText}
        />
      </Panel>
    </ModalInsert>
  );
};

GenericError.defaultProps = {
  headerText: "It looks like something has gone wrong",
  bodyText:
    "Please click the button below to continue or if the problem persists, please contact us on 01676 571 098",
};

export default GenericError;
