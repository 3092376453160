import styled, { css } from "styled-components";

import Accordion from "./Accordion";

export const StyledAccordion = styled(Accordion)`
  opacity: ${props => props.isDisabled && 0.5};
  
  .accordion__header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .accordion__header-label {
      display: flex;
      align-items: center;
      > label {
        cursor: pointer;
      }
    }

    .accordion__header-count {
      font-family: ${props => props.theme.FONTS.families.lato};
      font-size: ${props => props.theme.FONTS.sizes[20]};
      font-weight: ${props => props.theme.FONTS.weights[60]};
      line-height: ${props => props.theme.FONTS.lineHeights[10]};
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.3rem;
      width: 2.3rem;
      border-radius: 50%;
      background-color: ${props => props.theme.COLOURS.ACCENT.base};
      color: ${props => props.theme.COLOURS.WHITE};
      margin-right: 0.8rem;
    }

    .accordion__header-chevron {
      margin-right: ${props => props.theme.SPACING.REGULAR};
    }
  }

  ${props =>
    props.labelDetail &&
    css`
      .accordion__header-label {
        max-width: 90%;
      }

      .accordion__header-detail {
        color: ${props.theme.COLOURS.PRIMARY.shades[60]};
        flex: 1;
        min-width: 0;
        padding-right: ${props.theme.SPACING.SMALL};

        span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .accordion__header-count {
        margin-left: 0.8rem;
      }
    `}
`;

export default StyledAccordion;
