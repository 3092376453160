import styled, { css } from "styled-components";

import Button from "./Button.jsx";
import breakpoints from "../../theme/breakpoints";

export const StyledButton = styled(Button)`
  display: inline-block;
  border-radius: 0.5rem;
  margin-left: ${(props) => (props.removeMarginLeft ? "0" : props.theme.SPACING.SMALL)};
  padding: ${(props) => (props.large ? "2.1rem 1.7rem" : "1.7rem 1.2rem")};
  font-size: ${(props) =>
    props.large ? props.theme.FONTS.sizes[70] : props.theme.FONTS.sizes[40]};
  ${(props) => props.theme.FONTS.Text["button"]}

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    padding: ${(props) => (props.large ? "2.1rem 3.7rem" : "1.7rem 2.7rem")};
  }

  ${(props) =>
    props.primary &&
    css`
      background-color: ${(props) => props.theme.COLOURS.ACCENT.base};
      color: ${(props) => props.theme.COLOURS.WHITE};
      border: 0.2rem solid ${(props) => props.theme.COLOURS.ACCENT.base};
    `};

  ${(props) =>
    props.primary &&
    !props.disabled &&
    css`
      &:hover {
        background-color: ${(props) =>
          props.theme.COLOURS.SECONDARY_ACCENT.base};
        border: 0.2rem solid
          ${(props) => props.theme.COLOURS.SECONDARY_ACCENT.base};
      }
    `};

  ${(props) =>
    props.primaryAlt &&
    css`
      background-color: transparent;
      color: ${(props) => props.theme.COLOURS.ACCENT.base};
      border: 0.2rem solid ${(props) => props.theme.COLOURS.ACCENT.base};
    `};

  ${(props) =>
    props.primaryAlt &&
    !props.disabled &&
    css`
      &:hover {
        background-color: ${(props) => props.theme.COLOURS.ACCENT.base};
        color: ${(props) => props.theme.COLOURS.WHITE};
      }
    `};

  ${(props) =>
    props.secondary &&
    css`
      background-color: ${(props) => props.theme.COLOURS.PRIMARY.base};
      color: ${(props) => props.theme.COLOURS.WHITE};
      border: 0.2rem solid ${(props) => props.theme.COLOURS.PRIMARY.base};
    `}

  ${(props) =>
    props.secondary &&
    !props.disabled &&
    css`
      :hover {
        background-color: ${(props) => props.theme.COLOURS.SECONDARY.base};
        border: 0.2rem solid ${(props) => props.theme.COLOURS.SECONDARY.base};
      }
    `}
  ${(props) =>
    props.ghost &&
    css`
      background-color: transparent;
      color: ${(props) => props.theme.COLOURS.PRIMARY.base};
      border: 0.2rem solid ${(props) => props.theme.COLOURS.PRIMARY.base};
      :hover {
        border: 0.2rem solid ${(props) => props.theme.COLOURS.PRIMARY.base};
      }
    `}
  
  ${(props) =>
    props.ghost &&
    !props.disabled &&
    css`
      &:hover {
        background-color: ${(props) => props.theme.COLOURS.PRIMARY.base};
        color: ${(props) => props.theme.COLOURS.WHITE};
      }
    `}
  
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: default;
    `}
  
  ${(props) =>
    props.successPrimary &&
    css`
      background-color: ${(props) => props.theme.COLOURS.GREEN.base};
      color: ${(props) => props.theme.COLOURS.WHITE};
      border: 0.2rem solid ${(props) => props.theme.COLOURS.GREEN.base};
      &:hover {
        border: 0.2rem solid ${(props) => props.theme.COLOURS.GREEN.base};
        color: ${(props) => props.theme.COLOURS.WHITE};
        background-color: ${(props) => props.theme.COLOURS.GREEN.shades[90]};
      }
    `}
  
  ${(props) =>
    props.successSecondary &&
    css`
      color: ${(props) => props.theme.COLOURS.GREEN.base};
      background-color: transparent;
      border: 0.2rem solid ${(props) => props.theme.COLOURS.GREEN.base};
      border: 0.2rem solid ${(props) => props.theme.COLOURS.GREEN.base};
      focus &:hover {
        color: ${(props) => props.theme.COLOURS.WHITE};
        background-color: ${(props) => props.theme.COLOURS.GREEN.base};
      }
    `}
  ${(props) =>
    props.cautionPrimary &&
    css`
      background-color: ${(props) => props.theme.COLOURS.ORANGE.base};
      color: ${(props) => props.theme.COLOURS.WHITE};
      border: 0.2rem solid ${(props) => props.theme.COLOURS.ORANGE.base};
      &:focus {
        outline: 0.2rem solid ${(props) => props.theme.COLOURS.ORANGE.contrast};
      }
      &:hover {
        color: ${(props) => props.theme.COLOURS.WHITE};
        background-color: ${(props) => props.theme.COLOURS.ORANGE.shades[90]};
      }
    `}
  
  ${(props) =>
    props.cautionSecondary &&
    css`
      color: ${(props) => props.theme.COLOURS.ORANGE.base};
      background-color: transparent;
      border: 0.2rem solid ${(props) => props.theme.COLOURS.ORANGE.base};
      &:hover {
        color: ${(props) => props.theme.COLOURS.WHITE};
        background-color: ${(props) => props.theme.COLOURS.ORANGE.base};
      }
    `}
  ${(props) =>
    props.errorPrimary &&
    css`
      background-color: ${(props) => props.theme.COLOURS.RED.base};
      color: ${(props) => props.theme.COLOURS.WHITE};
      border: 0.2rem solid ${(props) => props.theme.COLOURS.RED.base};
      &:focus {
        outline: 0.2rem solid ${(props) => props.theme.COLOURS.RED.contrast};
      }
      &:hover {
        color: ${(props) => props.theme.COLOURS.WHITE};
        background-color: ${(props) => props.theme.COLOURS.RED.shades[90]};
      }
    `}
  
  ${(props) =>
    props.errorSecondary &&
    css`
      color: ${(props) => props.theme.COLOURS.RED.base};
      background-color: transparent;
      border: 0.2rem solid ${(props) => props.theme.COLOURS.RED.base};
      &:hover {
        color: ${(props) => props.theme.COLOURS.WHITE};
        background-color: ${(props) => props.theme.COLOURS.RED.base};
      }
    `}

  ${(props) =>
    props.compact &&
    css`
      align-items: center;
      box-sizing: border-box;
      display: flex;
      height: 2.5rem;
      justify-content: center;
      padding: 0;
      width: 2.5rem;

      svg {
        height: 1.2rem;
        width: 0.8rem;
      }

      path,
      g {
        stroke: white;
      }

      @media (min-width: ${breakpoints.PHONE}) {
        height: 3.5rem;
        width: 3.5rem;

        svg {
          height: 1.6rem;
          width: 1rem;
        }
      }
    `}
`;

export default StyledButton;
