export const informationRequestReasonLabel = {
  HEADER_TEXT: "Information Request",
  SUB_HEADER_TEXT:
    "Please enter your information request query below. Once submitted, this will be sent directly to the trade buyer.",
};

export const errorMessages = {
  MIN_LENGTH: `Enter more than 10 characters`,
  FIELD_REQUIRED: "Required in order to request more information",
};
