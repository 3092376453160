import Table from "./Table";
import styled from "styled-components";
import colours from "../../../views/theme/base/colours";
import Pill from "../../../views/components/Pill";

const StyledTable = styled(Table)`
  * {
    vertical-align: middle;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 1rem;
    margin-top: 3rem;
  }

  td {
    padding: 0.5rem;
    font-size: 1.4rem;
  }

  th,
  tr {
    margin-bottom: 1rem;
    background: white;
    color: ${colours.PRIMARY.base};
    text-align: center;
    padding: 0.5rem;
  }

  tr th {
    background: ${colours.PRIMARY.shades[5]};
    font-weight: 600;
  }

  tr td:first-child {
    width: ${(props) => (props.width ? "4%" : "5%")};
  }
  tr td:last-child {
    width: ${(props) => (props.width ? "2%" : "5%")};
  }

  tr td:nth-child(2) {
    width: ${(props) => (props.width ? "25%" : "8rem")};
  }

  tr td:nth-child(3) {
    width: ${(props) => (props.width ? "17%" : "13rem")};
  }
  tr td:nth-child(4) {
    width: ${(props) => (props.width ? "13%" : "20rem")};
  }
  tr td:nth-child(5) {
    width: ${(props) => (props.width ? "13%" : "13rem")};
  }
  tr td:nth-child(6) {
    width: ${(props) => (props.width ? "13%" : "22rem")};
  }
  tr td:nth-child(7) {
    width: ${(props) => (props.width ? "13%" : "20rem")};
  }
  tr td:nth-child(8) {
    width: ${(props) => (props.width ? "10%" : "15rem")};
  }

  .reg-number {
    background-color: ${colours.YELLOW};
    width: 10;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .magnifying-glass {
    cursor: pointer;
  }

  .name-tag {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    &__remove {
      padding-left: 6px;
      cursor: pointer;
      transition: opacity 0.2s ease-in-out;
      :hover {
        opacity: 0.5;
      }
    }

    ${Pill} {
      color: ${colours.WHITE};
      background-color: ${colours.ORANGE.base};
      cursor: pointer;
    }
  }

  .response-due__missedsla {
    color: ${colours.RED.base};
  }

  .response-due__withinoneday {
    color: ${colours.ORANGE.base};
  }

  .response-due__ok {
    color: ${colours.PRIMARY.base};
  }
`;

export default StyledTable;
