import { openModal } from "./global";
import InspectionApi from "../../api/Inspection";

// Action constants
export const FETCH_INSPECTION_DETAILS = "@inspection/FETCH_INSPECTION_DETAILS";
export const FETCH_INSPECTION_DETAILS_SUCCESS =
  "@inspection/FETCH_INSPECTION_DETAILS_SUCCESS";
export const FETCH_INSPECTION_DETAILS_FAILURE =
  "@inspection/FETCH_INSPECTION_DETAILS_FAILURE";
export const CLEAR_INSPECTION_DETAILS = "@inspection/CLEAR_INSPECTION_DETAILS";
export const LAUNCH_MODAL_GALLERY = "@inspection/LAUNCH_MODAL_GALLERY";

// Action creators
export const fetchInspectionDetailsSuccess = (data) => {
  return {
    type: FETCH_INSPECTION_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchInspectionDetailsFailure = (error) => {
  return {
    type: FETCH_INSPECTION_DETAILS_FAILURE,
    payload: error,
  };
};

export const fetchInspectionDetails = (inspectionId) => (
  dispatch,
  getState
) => {
  dispatch({ type: FETCH_INSPECTION_DETAILS });

  const params = {
    id: inspectionId,
  };

  const inspectionDetailsInstance = new InspectionApi({
    params,
    credentials: getState().authentication.credentials,
  });

  inspectionDetailsInstance.call().then(
    (response) => {
      dispatch(fetchInspectionDetailsSuccess(response));
    },
    (err) => {
      dispatch(fetchInspectionDetailsFailure(err));
      return dispatch(openModal("generic-error"));
    }
  );
};

export const clearInspectionDetails = () => {
  return {
    type: CLEAR_INSPECTION_DETAILS,
  };
};

export const launchFaultModalGallery = (vehicleFaultId) => (dispatch) => {
  dispatch({
    type: LAUNCH_MODAL_GALLERY,
    payload: vehicleFaultId,
  });
  return dispatch(openModal("fault-gallery"));
};

const initialState = {
  isFetching: false,
  error: false,
  data: null,
  galleryVehicleFaultId: 0,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INSPECTION_DETAILS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_INSPECTION_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };
    case FETCH_INSPECTION_DETAILS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case CLEAR_INSPECTION_DETAILS:
      return {
        ...initialState,
      };
    case LAUNCH_MODAL_GALLERY:
      return {
        ...state,
        galleryVehicleFaultId: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
