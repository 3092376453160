import React from "react";
import Imgix from "react-imgix";
import classnames from "classnames";
import PropTypes from "prop-types";

import HeaderText from "./../HeaderText";
import ParagraphText from "../ParagraphText";
import Button from "./../Button";
import VehicleOverviewCard from "../VehicleOverviewCard";
import SummaryPriceBreakdown from "../SummaryPriceBreakdown";
import { getPaymentMethodLabel } from "../../../shared/utility";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../theme/breakpoints";

const ConfirmOrder = ({
  className,
  vehicleId,
  thumbnail,
  make,
  range,
  model,
  derivative,
  regNo,
  dateOfRegistration,
  odometer,
  odometerType,
  fuelType,
  transmission,
  colour,
  serviceHistory,
  v5,
  mot,
  motExpiryDate,
  auctionGrade,
  condition,
  paymentMethod,
  deliveryAddress,
  onConfirm,
  onGoBack,
  pricingDetails,
  labels,
  damagePercentage,
  showDamagePercentage,
}) => {
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.LARGE_DESKTOP})`,
  });
  const canPurchase = pricingDetails && pricingDetails.total.gross > 0;

  return (
    <div className={classnames(className, "qa-confirm-order")}>
      <div className="confirm-order__vehicle-info">
        <VehicleOverviewCard
          {...{
            titles: {
              make: make,
              range: range,
              model: model,
              regNo: regNo,
              derivative: derivative,
            },
            details: {
              dateOfRegistration: dateOfRegistration,
              odometer: odometer,
              odometerType: odometerType,
              fuelType: fuelType,
              transmission: transmission,
              colour: colour,
            },
            keyInfo: {
              serviceHistory: serviceHistory,
              v5: v5,
              mot: mot,
              motExpiryDate: motExpiryDate,
              auctionGrade: auctionGrade,
              condition: condition,
              damagePercentage: damagePercentage,
            },
            thumbnail: thumbnail,
            vehicleId: vehicleId,
            showDamagePercentage: showDamagePercentage,
          }}
        />
      </div>
      <div className="confirm-order__purchase-info">
        <div className="confirm-order__purchase-info-top qa-confirm-order__confirmation-summary">
          <HeaderText>Confirm Order</HeaderText>
          <ParagraphText>
            Please check your order details are correct and click confirm to
            secure the purchase of this vehicle
          </ParagraphText>
          <div className="confirm-order__payment">
            <ParagraphText>Payment method</ParagraphText>
            {paymentMethod && (
              <div className="confirm-order__option">
                {paymentMethod.title && (
                  <div className="confirm-order__option-label">
                    <span className="confirm-order__option-title">
                      {getPaymentMethodLabel(paymentMethod.value)}
                    </span>
                  </div>
                )}
                {paymentMethod.bankAccountNo && paymentMethod.bankSortCode && (
                  <div className="confirm-order__option-text">
                    <p className="confirm-order__option-text-details">
                      <span>Account: {paymentMethod.bankAccountNo}</span>
                      <span>Sort Code: {paymentMethod.bankSortCode}</span>
                    </p>

                    {paymentMethod.image && (
                      <Imgix src={paymentMethod.image} height={36} />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="confirm-order__delivery">
            <ParagraphText>
              {deliveryAddress.type === "Collection"
                ? "Collection address"
                : "Delivery address"}
            </ParagraphText>

            {deliveryAddress && (
              <div className="confirm-order__option">
                <div className="confirm-order__option-label">
                  {deliveryAddress.title && (
                    <span className="confirm-order__option-title">
                      {deliveryAddress.title}
                    </span>
                  )}
                  {deliveryAddress.text && (
                    <span className="confirm-order__option-text-details confirm-order__option-text-details--wrap">
                      {deliveryAddress.text}
                    </span>
                  )}
                </div>
                {deliveryAddress.image && (
                  <Imgix src={deliveryAddress.image} height={36} />
                )}
              </div>
            )}
          </div>
          <div className="confirm-order__price">
            {pricingDetails && (
              <SummaryPriceBreakdown
                data={pricingDetails}
                labels={labels}
                collapsable={isTabletOrMobile}
                inModal
              />
            )}
          </div>
          {!canPurchase && (
            <div className="confirm-order__price-warning">
              <ParagraphText red>
                Abnormal pricing detected. Please contact your account manager.
              </ParagraphText>
            </div>
          )}
        </div>
        <div className="confirm-order__purchase-info-bottom">
          <Button
            content="Go Back"
            qaClassName="qa-confirm-order__back-btn"
            action={onGoBack}
            ghost
          />
          <Button
            content="Confirm"
            qaClassName="qa-confirm-order__confirm-btn"
            action={onConfirm}
            primary
            disabled={!canPurchase}
          />
        </div>
      </div>
    </div>
  );
};

const pricingShape = PropTypes.shape({
  net: PropTypes.number,
  vat: PropTypes.number,
  gross: PropTypes.number,
  vatCode: PropTypes.oneOf(["OutOfScope", "Exempt", "Standard", "Zero"]),
  vatQualified: PropTypes.oneOf([
    "Unknown",
    "Export",
    "Commercial",
    "Qualified",
    "NonQual",
    "NonQualified",
  ]),
});

ConfirmOrder.propTypes = {
  className: PropTypes.string,
  thumbnail: PropTypes.string,
  make: PropTypes.string,
  range: PropTypes.string,
  model: PropTypes.string,
  derivative: PropTypes.string,
  regNo: PropTypes.string,
  dateOfRegistration: PropTypes.string,
  odometer: PropTypes.number,
  odometerType: PropTypes.string,
  fuelType: PropTypes.string,
  transmission: PropTypes.string,
  colour: PropTypes.string,
  serviceHistory: PropTypes.oneOf([
    "NotApplicable",
    "FullDealerServiceHistory",
    "FullServiceHistory",
    "None",
    "Partial",
    "Unknown",
  ]),
  v5: PropTypes.oneOf([
    "Yes",
    "AppliedFor",
    "PassedToTrade",
    "No",
    "Unknown",
    "DispatchedToAuction",
  ]),
  mot: PropTypes.oneOf(["Unknown", "NotApplicable", "No", "Yes"]),
  motExpiryDate: PropTypes.string,
  grade: PropTypes.string,
  condition: PropTypes.oneOf(["Unknown", "Average", "Clean", "BelowAverage"]),
  paymentMethod: PropTypes.shape({
    title: PropTypes.string,
    account: PropTypes.string,
    sortCode: PropTypes.string,
    image: PropTypes.string,
  }),
  deliveryAddress: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  }),
  totalNet: PropTypes.number,
  vat: PropTypes.number,
  finalPrice: PropTypes.number,
  onConfirm: PropTypes.func,
  onGoBack: PropTypes.func,
  pricingDetails: PropTypes.shape({
    vehicle: pricingShape,
    adminCharge: pricingShape,
    deliveryCost: pricingShape,
    total: pricingShape,
  }),
};

export default ConfirmOrder;
