import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";
import Button from "./../Button";
import Icon from "./../Icon";
import ParagraphText from "./../ParagraphText";

const InvoiceDownload = ({ className, reportURL, invoiceURL, onClose }) => {
  const theme = useContext(ThemeContext);
  return (
    <div className={className}>
      <div className="icon">
        <Icon
          type="arrow-download"
          width="48"
          height="48"
          strokeColour={theme.COLOURS.ACCENT.base}
        />
      </div>
      <div className="content">
        <div className="content__title"> Download</div>
        <div className="content__text">
          <ParagraphText primary>
            Please select whether you wish to download a PDF copy of the invoice
            or whether you would like to download a report of all transactions
            related to the invoice.
          </ParagraphText>
        </div>
        <div className="content__actions">
          <div onClick={() => onClose()}>
            <Button
              url={reportURL}
              primary
              external
              className="gaEvent-download"
              content="Download Report"
            />
          </div>
          <div onClick={() => onClose()}>
            <Button
              url={invoiceURL}
              primaryAlt
              external
              className="gaEvent-download"
              content="Download Invoice"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

InvoiceDownload.propTypes = {
  className: PropTypes.string,
  reportURL: PropTypes.string,
  invoiceURL: PropTypes.string,
  onClose: PropTypes.func
};

export default InvoiceDownload;
