export const paragraphText = {
  HEADER_TEXT: "Call Back Request",
  STATUS: "Status",
  TRADE_BUYER: "Trade Buyer",
  CONTACT_NAME: "Contact Name",
  TEL_NO: "Telephone Number",
  REQUEST_NOTES: "Request Notes",
  VENDOR_NOTES: "Vendor Notes",
  CALLBACK_TIME: "Call Back Time",
  CASE_REFERENCE: "Case Reference",
};

export const callBackTimes = {
  AM: "AM",
  PM: "PM",
  ANYTIME: "Anytime",
};
