import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import { completeCallback } from "../../../state/reducers/claimsVendor";
import ModalInsert from "../../../views/components/ModalInsert";
import Panel from "../../../views/components/Panel";
import ClaimCallbackFrom from "../../components/ClaimConfirmCallback/ClaimCallbackForm";
import ClaimCallbackConfirmation from "../../components/ClaimConfirmCallback/ClaimCallbackConfirmation";
import SkeletonRows from "./../../../views/components/Skeletons/SkeletonRows";

const CompleteCallbackRequestModalInsert = () => {
  const dispatch = useDispatch();

  const activeClaimId = useSelector(
    (state) => state.global.claims.activeClaimId
  );

  const callbackDetails = useSelector(
    (state) =>
      state.claimsVendor?.data.find((x) => x.claimId === activeClaimId)
        ?.callback
  );

  const [vendorNotes, setVendorNotes] = useState("");
  const [callBackRequested, setCallBackRequested] = useState(false);

  const formData = {
    claimCallbackId: callbackDetails?.claimCallbackId,
    claimId: callbackDetails?.claimId,
    vendorNotes: vendorNotes,
  };

  const onCompleteCallBack = () => {
    dispatch(completeCallback(formData));
    setTimeout(() => {
      dispatch(closeModal("complete-callback"));
    }, 2500);
    setCallBackRequested(true);
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  const CompleteCallBackFormContent = (
    <Panel
      mobileFlex
      style={{ height: "100%", borderRadius: "0.5rem" }}
      actions={[
        {
          content: "Close",
          action: onCancel,
          type: "ghost",
        },
        {
          content: "Complete",
          action: onCompleteCallBack,
          type: "primary",
          disabled: false,
        },
      ]}
    >
      {!!callbackDetails ? (
        <ClaimCallbackFrom
          {...callbackDetails}
          setVendorNotes={setVendorNotes}
        />
      ) : (
        <SkeletonRows nrOfRows={5} />
      )}
    </Panel>
  );

  return (
    <Fragment>
      <ModalInsert>
        {callBackRequested ? (
          <ClaimCallbackConfirmation />
        ) : (
          CompleteCallBackFormContent
        )}
      </ModalInsert>
    </Fragment>
  );
};
export default CompleteCallbackRequestModalInsert;
