import React, { useContext } from "react";
import {
  ItemContainer,
  ItemLabel,
  ItemContent,
  DerivativeRow,
  MakeModelRegistrationRow,
} from "../index";
import { subHeaderText } from "../consts";
import ParagraphText from "../../ParagraphText";
import SubHeaderText from "../../SubHeaderText";
import RegistrationPlate from "../../RegistrationPlate";
import { ThemeContext } from "styled-components";
import { removeStringFromStart } from "../../../../shared/utility";

function Vehicle({ make, model, derivative, regNo }) {
  const theme = useContext(ThemeContext);

  return (
    <ItemContainer>
      <ItemLabel>
        <SubHeaderText>{subHeaderText.VEHICLE}:</SubHeaderText>
      </ItemLabel>
      <ItemContent>
        <MakeModelRegistrationRow
          labelColor={theme.COLOURS.PRIMARY.base}
          labelFontWeight={theme.FONTS.weights[400]}
        >
          <ParagraphText primary large>
            {`${make} ${model}`}
          </ParagraphText>

          <RegistrationPlate registrationNumber={regNo} />
        </MakeModelRegistrationRow>
        <DerivativeRow>
          <ParagraphText>
            {`${removeStringFromStart(model, model)} ${derivative}`}
          </ParagraphText>
        </DerivativeRow>
      </ItemContent>
    </ItemContainer>
  );
}

export default Vehicle;
