import React from "react";
import propTypes from "prop-types";
import ParagraphText from "../ParagraphText";

const Tooltip = props => {
  const { className, show, children, content, maxCharacters } = props;
  let truncatedContent = content;

  if (truncatedContent?.length > maxCharacters) {
    truncatedContent = truncatedContent.substring(0, maxCharacters) + "...";
  }
  return (
    <div className={className}>
      {children}
      {show && (
        <ParagraphText primary small className="tooltiptext">
          {truncatedContent}
        </ParagraphText>
      )}
    </div>
  );
};

Tooltip.defaultProps = {
  className: "tooltip",
  show: true,
  maxCharacters: 500
};

Tooltip.propTypes = {
  className: propTypes.string,
  content: propTypes.string,
  show: propTypes.bool
};

export default Tooltip;
