import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { ThemeContext } from "styled-components";

export const HelmetConfig = () => {
  const theme = useContext(ThemeContext);
  return (
    <Helmet>
      <title>{theme.title}</title>
      <link rel="shortcut icon" href={theme.IMAGES.FAVICON} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={theme.IMAGES.FAVICON_APPLE}
      />
    </Helmet>
  );
};

export default HelmetConfig;
