import { updateObject } from "../../shared/utility";
import BidsAndRequestsApi from "../../api/BidsAndRequests";
import { addShortlistFromPageLoad } from "./shortlist";
// Action constants
export const FETCH_BIDS_AND_REQUESTS =
  "@bidsAndRequests/FETCH_BIDS_AND_REQUESTS";
export const FETCH_BIDS_AND_REQUESTS_SUCCESS =
  "@bidsAndRequests/FETCH_BIDS_AND_REQUESTS_SUCCESS";
export const FETCH_BIDS_AND_REQUESTS_FAILURE =
  "@bidsAndRequests/FETCH_BIDS_AND_REQUESTS_FAILURE";
export const CLEAR_BIDS_AND_REQUESTS =
  "@bidsAndRequests/CLEAR_BIDS_AND_REQUESTS";
export const UPDATE_PAGE_NUNBER = "@bidsAndRequests/UPDATE_PAGE_NUNBER";

// Action creators
export const fetchBidsAndRequests = () => (dispatch, getState) => {
  dispatch({ type: FETCH_BIDS_AND_REQUESTS });
  const filters = getState().filter.filters;
  // TODO: Sort has different options for this endpoint
  const toolbar = getState().toolbar;
  const pageNumber = getState().pagination.pageNumber;
  const { pageSize } = toolbar;
  const params = {
    filters,
    pageSize,
    pageNumber
  };

  const bidsOverviewInstance = new BidsAndRequestsApi({
    params,
    credentials: getState().authentication.credentials
  });

  bidsOverviewInstance.call().then(
    response => {
      const {
        results: { shortlistedVehicleIds }
      } = response;
      if (shortlistedVehicleIds) {
        dispatch(addShortlistFromPageLoad(shortlistedVehicleIds));
      }
      dispatch(fetchBidsAndRequestsSuccess(response));
    },
    err => {
      return dispatch(fetchBidsAndRequestsFailure(err));
    }
  );
};

export const fetchBidsAndRequestsSuccess = data => {
  return {
    type: FETCH_BIDS_AND_REQUESTS_SUCCESS,
    payload: data
  };
};

export const fetchBidsAndRequestsFailure = error => {
  return {
    type: FETCH_BIDS_AND_REQUESTS_FAILURE,
    payload: error
  };
};

export const clearBidsAndRequests = () => {
  return {
    type: CLEAR_BIDS_AND_REQUESTS
  };
};

export const initialState = {
  isFetching: false,
  error: false,
  results: {
    byId: {},
    vehicleIds: []
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BIDS_AND_REQUESTS:
      return updateObject(state, { isFetching: true });
    case FETCH_BIDS_AND_REQUESTS_SUCCESS:
      return updateObject(state, {
        isFetching: false,
        totalCount: action.payload.totalCount,
        pageSize: action.payload.pageSize,
        pageCount: action.payload.pageCount,
        results: action.payload.results
      });
    case FETCH_BIDS_AND_REQUESTS_FAILURE:
      return updateObject(state, {
        isFetching: false,
        results: {
          byId: {},
          vehicleIds: []
        },
        error: true
      });
    case CLEAR_BIDS_AND_REQUESTS:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default reducer;
