import Api from "./Base";
import settings from "../clientappsettings.json";
import { formatFilterOptions } from "../shared/filter";

class VendorClaims extends Api {
  baseUrl = settings.VENDORS_URL;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class FetchVendorClaims extends VendorClaims {
  requestUrl = `/Claims`;
  method = "POST";

  formatRequestData(params) {
    const {
      filters: { claimDate, newClaimsClaimStatus },
      pageSize,
      pageNumber,
      registration,
      managedBy,
      primaryFilter,
      sort,
    } = params;
    return {
      pageSize,
      pageNumber,
      registration,
      managedBy,
      primaryFilter,
      sort,
      ...formatFilterOptions({
        filterObject: { claimDate },
        pageType: "completedClaims",
      }),
      ...formatFilterOptions({
        filterObject: { newClaimsClaimStatus },
        pageType: "newClaims",
      }),
      ...formatFilterOptions({
        filterObject: { newClaimsClaimStatus },
        pageType: "inProgressClaims",
      })
    };
  }

  formatResponseData(response) {
    if (response.data?.claims) {
      return response.data.claims;
    }
    return response;
  }
}

export class TakeOwnership extends VendorClaims {
  requestUrl = `/Claims/TakeOwnership/${this.getParams().claimId}`;
  method = "GET";
  formatResponseData(response) {
    if (response.data) {
      return response.data;
    }
    return response;
  }
}

export class ClearOwnership extends VendorClaims {
  requestUrl = `/Claims/ClearOwnership/${this.getParams().claimId}`;
  method = "GET";
  formatResponseData(response) {
    if (response.data) {
      return response.data;
    }
    return response;
  }
}

export class CompleteCallback extends VendorClaims {
  requestUrl = `/Claims/CompleteCallback`;
  method = "POST";
}

export class FetchCallback extends VendorClaims {
  requestUrl = `/Claims/Callback/${this.getParams().claimId}`;

  formatResponseData(response) {
    if (response.data?.callbackDetails) {
      return response.data.callbackDetails;
    }
    return response;
  }
}

export class CompleteClaim extends VendorClaims {
  requestUrl = `/Claims/CompleteClaim/${this.getParams().claimId}`;
  method = "GET";
  formatResponseData(response) {
    if (response.data) {
      return response.data;
    }
    return response;
  }
}
