export const UPDATE_CURRENT_PAGE = "@pagination/UPDATE_CURRENT_PAGE";
export const CLEAR_CURRENT_PAGE = "@pagination/CLEAR_CURRENT_PAGE";

export const updatePageNumber = pageNumber => {
  return {
    type: UPDATE_CURRENT_PAGE,
    payload: pageNumber
  };
};

export const resetPagination = () => {
  return { type: CLEAR_CURRENT_PAGE };
};

const initialState = {
  pageNumber: 1
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_PAGE:
      return {
        ...state,
        pageNumber: action.payload
      };
    case CLEAR_CURRENT_PAGE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default reducer;
