import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Icon from "../../Icon";

const Loading = props => {
  const { className } = props;

  return (
    <div className={className}>
      <Icon type="loading" width="62" height="54" />
    </div>
  );
};

const StyledLoading = styled(Loading)`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);

  svg {
    ${props => props.theme.ANIMATIONS.rotation};
  }
`;

Loading.propTypes = {
  className: PropTypes.string
};

export default StyledLoading;
