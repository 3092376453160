import React, { useContext } from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";
import { getInfoTagStrokeColour } from "../../../shared/utility";
import { ThemeContext } from "styled-components";

const InfoTag = ({
  iconType,
  defaultIconColor,
  iconText,
  additionalIconText,
  content,
  className
}) => {
  const theme = useContext(ThemeContext);
  const strokeColour = getInfoTagStrokeColour(
    iconType,
    defaultIconColor,
    theme
  );

  return (
    <div className={className}>
      <div className="info-tag__icon">
        {iconType && <Icon type={iconType} strokeColour={strokeColour} />}
        {!iconType && iconText && <pre>{iconText}</pre>}
      </div>
      {content && <p> {content} </p>}
      {additionalIconText && (
        <span className="info-tag__additional-text">{additionalIconText}</span>
      )}
    </div>
  );
};

InfoTag.propTypes = {
  iconType: PropTypes.string,
  additionalIconText: PropTypes.string,
  defaultIconColor: PropTypes.bool,
  content: PropTypes.string,
  className: PropTypes.string
};

export default InfoTag;
