import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import { fetchIsFundingAllowed } from "../../../state/reducers/funding";
import { fetchOrderDetails } from "../../../state/reducers/orders";
import DealerFundingRequestSuccess from "../../components/DealerFundingRequest/DealerFundingRequestSuccess";
import ModalInsert from "../../components/ModalInsert";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../theme/breakpoints";

const DealerFundingRequestSuccessModalInsert = () => {
  const fundingApplicationData = useSelector(
    (state) => state.funding.fundingApplication.data
  );
  // const orderDetailData = useSelector((state) => state.orders.orderDetails);

  // //Id-s might come from funding response so this code might get replaced
  // const vehiclePurchaseId = orderDetailData.vehiclePurchaseId;
  // const vehicleId =
  //   orderDetailData.vehicleDetails && orderDetailData.vehicleDetails.vehicleId;

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(fetchOrderDetails(fundingApplicationData.vehiclePurchaseId));
    dispatch(fetchIsFundingAllowed(fundingApplicationData.vehiclePurchaseId));
    dispatch(closeModal());
  };

  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  return (
    <ModalInsert layout="flex" width={isDesktop ? "96rem" : "100%"}>
      <DealerFundingRequestSuccess
        className={"dealer-funding-request-success"}
        fundingApplicationData={fundingApplicationData}
        onClose={onClose}
      />
    </ModalInsert>
  );
};

export default DealerFundingRequestSuccessModalInsert;
