import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Stack = ({ width, height, strokeColour, strokeWidth }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(1 1)"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <ellipse strokeLinejoin="round" cx="9" cy="8.974" rx="9" ry="8.727" />
        <ellipse strokeLinejoin="round" cx="9" cy="5.974" rx="4" ry="2" />
        <path
          d="M5 9c0 1.105 1.79 2 4 2s4-.895 4-2M5 12c0 1.105 1.79 2 4 2s4-.895 4-2"
          strokeLinejoin="round"
        />
        <path d="M5 6v6M13 6v6" />
      </g>
    </svg>
  );
};

Stack.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

Stack.defaultProps = {
  width: "20",
  height: "20",
  strokeWidth: "2"
};

export default Stack;
