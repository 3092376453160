import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Loading = ({ width, height, strokeColour, strokeWidth }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour
    ? strokeColour
    : theme.COLOURS.SECONDARY.shades[20];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      display="block"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke={stroke}
        strokeWidth={strokeWidth}
        r="30"
        strokeDasharray="141.37166941154067 49.12388980384689"
      />
    </svg>
  );
};

Loading.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

Loading.defaultProps = {
  width: "201",
  height: "201",
  strokeWidth: "10"
};

export default Loading;
