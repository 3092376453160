import React from "react";
import { CALLBACK_CONFIRMATION, CALLBACK_CONFIRMATION_MESSAGE } from "./const";
import GenericTransitionModal from "../../GenericTransitionModal";

const ClaimCallBackConfirmation = ({ className }) => {
  return (
    <div className={className}>
    <GenericTransitionModal
      headerText={CALLBACK_CONFIRMATION}
      bodyText={CALLBACK_CONFIRMATION_MESSAGE}
    />
  </div>
  );
};

export default ClaimCallBackConfirmation;
