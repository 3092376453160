import styled from "styled-components";
import spacing from "../../../theme/base/spacing";
import ClaimCallBackForm from "./ClaimCallBackForm";
import breakpoints from "../../../theme/breakpoints";
import InputRadio from "../../InputRadio";

const StyledClaimCallBackForm = styled(ClaimCallBackForm)`
  .call-back__form {
    margin-top: ${(props) => props.theme.SPACING.MEDIUM};
    /* width: 87rem; */
  }

  .call-back {
    display: flex;
    width: 100%;
    align-items: baseline;
    margin-bottom: ${spacing.SMALL};

    @media (max-width: ${breakpoints.TABLET}) {
      margin-bottom: ${spacing.REGULAR};
    }

    &_radio-group {
      display: flex;

      ${InputRadio} {
        > * {
          padding-left: 3.8rem;
        }
      }

      & > * {
        margin-right: ${spacing.MEDIUM};
      }
      @media (max-width: ${breakpoints.TABLET}) {
        display: block;
      }
    }

    > div {
      flex: 1;

      &:last-of-type {
        flex: 4;
        display: flex;

        @media (max-width: ${breakpoints.TABLET}) {
          flex: 1;
          display: flex;
          flex-direction: column;
        }

        > * {
          flex: 1;

          &:nth-child(1) {
            display: flex;
            justify-content: end;
            align-items: center;
            padding-right: ${spacing.REGULAR};

            @media (max-width: ${breakpoints.TABLET}) {
              display: block;
              padding-left: 0;
            }
          }

          &:nth-child(2) {
            flex: 1;
          }
        }
      }
    }
  }

  .text-area__input {
    background-color: ${(props) => props.theme.COLOURS.WHITE};
    border-radius: 0.5rem;
    border: 0.2rem solid ${(props) => props.theme.COLOURS.PRIMARY.shades[20]};
    box-sizing: border-box;
    color: ${(props) => props.theme.COLOURS.PRIMARY.base};
    padding: ${spacing.SMALL};
    display: flex;
    justify-content: flex-end;
    width: 100%;
    resize: initial;
    ${(props) => props.theme.FONTS.Text["paragraph"]}
  }

  .call-back__tel-no > p {
    color: ${(props) => props.theme.COLOURS.PRIMARY.base};
  }
`;

export default StyledClaimCallBackForm;
