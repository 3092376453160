import React, { Fragment, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../components/ModalInsert";
import Panel from "../../components/Panel";
import ClaimCallBackConfirmation from "../../components/ClaimRequestCallback/ClaimCallBackConfirmation";
import ClaimCallBackForm from "../../components/ClaimRequestCallback/ClaimCallBackForm";
import { UK_TELEPHONE_REGEX } from "../../../shared/utility";
import { requestCallBack } from "../../../state/reducers/claims";
import { ClaimCallbackNoteContext, ClaimCallbackNoteValidationContext } from "../../components/ClaimRequestCallback/ClaimCallBackForm/context/context";

const ClaimCallBackInsert = () => {
  const dispatch = useDispatch();
  const activeClaimId = useSelector(
    (state) => state.global.claims.activeClaimId
  );

  const claimDetails = useSelector((state) =>
    state.claims.data.claims.find((el) => el.claimId === activeClaimId)
  );

  const { claimCallbackNote } = useContext(ClaimCallbackNoteContext);

  const { validClaimCallbackNote } = useContext(
    ClaimCallbackNoteValidationContext
  );

  const [callBackTime, setCallBackTime] = useState("Anytime");
  const [telNo, setTelNo] = useState(claimDetails?.telNo);
  const [validTelNo, setValidTelNo] = useState(true);

  const [callBackRequested, setCallBackRequested] = useState(false);

  const onChangeTelNo = ({ target: { value } }) => {
    setTelNo(value);
    if (value && UK_TELEPHONE_REGEX.test(value)) {
      setValidTelNo(true);
    } else {
      setValidTelNo(false);
    }
  };

  const formData = {
    claimId: activeClaimId,
    callBackTime: callBackTime,
    telephoneNo: telNo,
    notesToVendor: claimCallbackNote,
  };

  const onRequestCallBack = () => {
    dispatch(requestCallBack(formData));
    setTimeout(() => {
      dispatch(closeModal());
    }, 2500);
    setCallBackRequested(true);
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  const RequestCallBackFormContent = (
    <Panel
      mobileFlex
      actions={[
        {
          content: "Close",
          action: onCancel,
          type: "ghost",
        },
        {
          content: "Request Call Back",
          action: onRequestCallBack,
          type: "primary",
          disabled: !validTelNo || !telNo || !validClaimCallbackNote,
        },
      ]}
    >
      <ClaimCallBackForm
        telNo={telNo}
        callBackTime={callBackTime}
        setCallBackTime={setCallBackTime}
        onChangeTelNo={onChangeTelNo}
        validTelNo={validTelNo}
      />
    </Panel>
  );

  return (
    <Fragment>
      <ModalInsert>
        {callBackRequested ? (
          <ClaimCallBackConfirmation />
        ) : (
          RequestCallBackFormContent
        )}
      </ModalInsert>
    </Fragment>
  );
};

export default ClaimCallBackInsert;
