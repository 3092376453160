import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import PricingBlock from "../../components/PricingBlock";
import CountdownInsert from "../../components/CountdownInsert/CountdownInsert"; //doubled as there is no index.js

const BidPricingBlockDefault = (props) => {
  const vehicleId = useSelector(
    (state) => state.global.bids.activeBidVehicleId
  );
  const bidDetails = useSelector(
    (state) => state.bid.bids.byVehicleId[vehicleId]
  );
  const { bidCount = 0, biddingEndDate, currentBidAmount = 0 } = bidDetails;

  return bidCount === 0 ? (
    <Fragment>
      <PricingBlock
        price={currentBidAmount}
        headerText="Starting bid"
        bidText={`${bidCount} Bids`}
        inline={props.inline}
        large
      />
      <div style={{ display: "block", marginBottom: "1rem" }} />
      <PricingBlock
        headerText={
          biddingEndDate ? (
            <CountdownInsert endDateTime={biddingEndDate} />
          ) : (
            "Open Ended Bidding"
          )
        }
        large
        inline
      />
    </Fragment>
  ) : null;
};

export default BidPricingBlockDefault;
