import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import ParagraphText from "../../ParagraphText";
import { paragraphText } from "./../consts";

const ClaimActivityLogTableRow = ({ claimActivityLog }) => {
  const claimActivityLogTypeMap = {
    RequestCallBack: "Request Call Back",
    ClaimClosed: "Claim Closed",
    ClaimSubmitted: "Claim Submitted",
    ClaimDeclined: "Claim Declined",
    ReviewInProgress: "Review In Progress",
    InformationRequested: "Information Requested",
    CallbackCompleted: "Callback Completed",
    VendorOfferMade: "Vendor Offer Made",
    SaleCancelled: "Sale Cancelled",
    ClaimCompleted: "Claim Completed",
    VendorOfferAccepted: "Vendor Offer Accepted",
    OwnershipUpdate: "Ownership Update",
    VendorNotes: "Note",
  };

  const { dateCreated, createdBy, logType, message, notes } = claimActivityLog;

  const messageLines = message.split("\n");
  return (
    <tbody>
      <tr>
        <td className="row-date">
          {dateCreated && (
            <>
              <ParagraphText primary>
                {dayjs(dateCreated).format("DD/MM/YYYY HH:mm")}
              </ParagraphText>
            </>
          )}
        </td>
        <td className="row-user">
          {createdBy && <ParagraphText primary>{createdBy}</ParagraphText>}
        </td>
        <td className="row-status">
          {logType && (
            <ParagraphText primary>
              {claimActivityLogTypeMap[logType]}
            </ParagraphText>
          )}
        </td>
        <td className="row-response">
          {message && (
            <ParagraphText className="row-response-message">
              {messageLines.map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </ParagraphText>
          )}
          {notes && (
            <ParagraphText className="row-response-notes">
              {message && notes && <>{paragraphText.NOTES}</>}
              {notes}
            </ParagraphText>
          )}
        </td>
      </tr>
    </tbody>
  );
};

ClaimActivityLogTableRow.propTypes = {
  claimActivityLog: PropTypes.shape({
    claimActivityLogId: PropTypes.number,
    dateCreated: PropTypes.string,
    createdBy: PropTypes.string,
    logType: PropTypes.string,
    message: PropTypes.string,
    notes: PropTypes.string,
  }),
};

export default ClaimActivityLogTableRow;
