import React from "react";

const SkeletonPopoverMultiSelect = props => {
  const { className } = props;

  return (
    <div className={className}>
      <div className="skeleton-popover-multi-select__options">
        {[...Array(10)].map((item, index) => (
          <span key={`skeleton-popover-multi-select-${index}`}>
            <span className="skeleton-popover-multi-select__checkbox" />
            <span className="skeleton-popover-multi-select__label" />
          </span>
        ))}
      </div>
      <div className="skeleton-popover-multi-select__actions">
        <span />
      </div>
    </div>
  );
};

export default SkeletonPopoverMultiSelect;
