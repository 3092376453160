import { PostBidConfirmation, GetBidDetails } from "../../api/Bid";
import { closeModal, openModal, clearActiveBidVehicleId } from "./global";

// Action constants
export const BID_CONFIRMATION_REQUEST = "@bid/BID_CONFIRMATION_REQUEST";
export const BID_CONFIRMATION_REQUEST_SUCCESS =
  "@bid/BID_CONFIRMATION_REQUEST_SUCCESS";
export const BID_CONFIRMATION_REQUEST_FAILURE =
  "@bid/BID_CONFIRMATION_REQUEST_FAILURE";
export const SET_BID_PRICE = "@bid/SET_BID_PRICE";
export const FETCH_BID_DETAILS = "@bid/FETCH_BID_DETAILS";
export const FETCH_BID_DETAILS_SUCCESS = "@bid/FETCH_BID_DETAILS_SUCCESS";
export const FETCH_BID_DETAILS_FAILURE = "@bid/FETCH_BID_DETAILS_FAILURE";
export const CLEAR_BID_RESULT = "@bid/CLEAR_BID_RESULT";
export const CLEAR_BID_DATA = "@bid/CLEAR_BID_DATA";
export const SET_GROUP_BUYER_SITE = "@bid/SET_GROUP_BUYER_SITE";
export const CLEAR_GROUP_BUYER_SITE = "@bid/CLEAR_GROUP_BUYER_SITE";

// Action creators
export const bidConfirmationRequest = () => {
  return {
    type: BID_CONFIRMATION_REQUEST
  };
};

export const bidConfirmationRequestSuccess = response => {
  return {
    type: BID_CONFIRMATION_REQUEST_SUCCESS,
    payload: response
  };
};

export const bidConfirmationRequestFailure = error => {
  return {
    type: BID_CONFIRMATION_REQUEST_FAILURE,
    payload: error
  };
};

export const setBidPrice = value => {
  return {
    type: SET_BID_PRICE,
    payload: value
  };
};

export const postBidConfirmationDetails = (vehicleId, maxBidAmount) => (
  dispatch,
  getState
) => {
  dispatch(bidConfirmationRequest());

  const selectedGroupSite = getState().bid.bids.byVehicleId[vehicleId]
    .selectedGroupSite;
  const params = {
    vehicleId: vehicleId,
    maxBidAmount: maxBidAmount,
    sourceType: "Ftp",
    selectedGroupSiteId: selectedGroupSite && selectedGroupSite.value
  };
  const bidConfirmationInstance = new PostBidConfirmation({
    params,
    credentials: getState().authentication.credentials
  });

  bidConfirmationInstance.call().then(
    response => {
      if (response.bidData.bidStatus === "AnotherSiteInTheGroupIsBidding") {
        dispatch(bidConfirmationRequestFailure(response.bidData.bidStatus));
        dispatch(openModal("trade-member-already-bid"));
      } else {
        dispatch(bidConfirmationRequestSuccess(response));
        dispatch(fetchBidDetails(vehicleId));
      }
    },
    err => {
      dispatch(bidConfirmationRequestFailure(err));
      /**
       * TODO: due to the implementation of the bidding process
       * it is unlikely that this error will get thrown here, but
       * would be raised in the bid details fetching process. We probably
       * should raise a 400 if there is an error here, but this will
       * entail BE/FE investigation.
       */
      return dispatch(openModal("generic-error"));
    }
  );
};

export const fetchBidDetailsSuccess = data => {
  return {
    type: FETCH_BID_DETAILS_SUCCESS,
    payload: data
  };
};

export const fetchBidDetailsFailure = error => {
  return {
    type: FETCH_BID_DETAILS_FAILURE,
    payload: error
  };
};

export const fetchBidDetails = vehicleId => (dispatch, getState) => {
  dispatch({ type: FETCH_BID_DETAILS });

  const bidDetailsInstance = new GetBidDetails({
    params: {
      id: vehicleId
    },
    credentials: getState().authentication.credentials
  });
  const isBidModalOpen = getState().global.modals.isOpen;

  bidDetailsInstance.call().then(
    response => {
      dispatch(fetchBidDetailsSuccess(response));
      if (isBidModalOpen) {
        dispatch(closeModal());
      }
    },
    err => {
      dispatch(fetchBidDetailsFailure(err));
      return dispatch(openModal("vehicle-unavailable"));
    }
  );
};

export const clearBidData = () => dispatch => {
  dispatch(clearActiveBidVehicleId());
  dispatch({
    type: CLEAR_BID_DATA
  });
};

/**
 * @param {number} vehicleId
 * @param {object} groupSite
 * @returns {{payload: *, type: string}}
 */
export const setGroupBuyerSite = (vehicleId, groupSite) => {
  return {
    type: SET_GROUP_BUYER_SITE,
    payload: {
      vehicleId,
      groupSite
    }
  };
};

export const clearGroupBuyerSite = vehicleId => {
  return {
    type: CLEAR_GROUP_BUYER_SITE,
    payload: {
      vehicleId
    }
  };
};

const initialState = {
  isFetching: false,
  error: false,
  isGroupBuyer: false,
  groupSites: [],
  bids: {
    byVehicleId: {},
    vehicleIds: []
  },
  bidResults: {
    // TODO: there is also the concept of bidIds that we may need to normalise
    // byBidId: {},
    // bidIds: [],
    byVehicleId: {},
    vehicleIds: []
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BID_CONFIRMATION_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case BID_CONFIRMATION_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case BID_CONFIRMATION_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        bidResults: {
          byVehicleId: {
            ...state.bidResults.byVehicleId,
            [action.payload.vehicleId]: action.payload.bidData
          },
          // TODO: X-Browser concern, check we are polyfilling Array#includes
          vehicleIds: state.bidResults.vehicleIds.includes(
            action.payload.vehicleId
          )
            ? state.bidResults.vehicleIds
            : [...state.bidResults.vehicleIds, action.payload.vehicleId]
        }
      };
    case SET_BID_PRICE:
      return {
        ...state,
        value: action.payload
      };
    case FETCH_BID_DETAILS:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_BID_DETAILS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case FETCH_BID_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isGroupBuyer: action.payload.isGroupBuyer,
        groupSites: action.payload.groupSites,
        bids: {
          byVehicleId: {
            ...state.bids.byVehicleId,
            [action.payload.vehicleId]: {
              ...action.payload.bidData
            }
          },
          // TODO: X-Browser concern, check we are polyfilling Array#includes
          vehicleIds: state.bids.vehicleIds.includes(action.payload.vehicleId)
            ? state.bids.vehicleIds
            : [...state.bids.vehicleIds, action.payload.vehicleId]
        }
      };
    case CLEAR_BID_DATA:
      return {
        ...state,
        ...initialState
      };
    case SET_GROUP_BUYER_SITE:
      return {
        ...state,
        bids: {
          ...state.bids,
          byVehicleId: {
            ...state.bids.byVehicleId,
            [action.payload.vehicleId]: {
              ...state.bids.byVehicleId[action.payload.vehicleId],
              selectedGroupSite: action.payload.groupSite
            }
          }
        }
      };
    case CLEAR_GROUP_BUYER_SITE:
      return {
        ...state,
        bids: {
          ...state.bids,
          byVehicleId: {
            ...state.bids.byVehicleId,
            [action.payload.vehicleId]: {
              ...state.bids.byVehicleId[action.payload.vehicleId],
              selectedGroupSite: null
            }
          }
        }
      };
    default:
      return state;
  }
};

export default reducer;
