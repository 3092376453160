import React, { useState } from "react";

export const InformationRequestReasonContext = React.createContext({});
export const InformationRequestReasonValidationContext = React.createContext(
  {}
);

export const InformationRequestReasonContextProvider = ({ children }) => {
  const [informationRequestReason, setInformationRequestReason] = useState("");

  return (
    <InformationRequestReasonContext.Provider
      value={{
        informationRequestReason,
        setInformationRequestReason,
      }}
    >
      {children}
    </InformationRequestReasonContext.Provider>
  );
};

export const InformationRequestReasonValidationContextProvider = ({
  children,
}) => {
  const [
    validInformationRequestReason,
    setValidInformationRequestReason,
  ] = useState(true);
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <InformationRequestReasonValidationContext.Provider
      value={{
        validInformationRequestReason,
        setValidInformationRequestReason,
        hasFocus,
        setHasFocus,
      }}
    >
      {children}
    </InformationRequestReasonValidationContext.Provider>
  );
};
