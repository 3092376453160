import React from "react";
import PropTypes from "prop-types";

import Header from "../../Header";
import Navigation from "../../../components/Navigation";

const SkeletonDefaultPage = ({ className, hasNav }) => {
  return (
    <div className={className}>
      <Header>
        {hasNav && <Navigation />}
        <div className="skeleton__page-heading">
          <span className="skeleton__page-title" />
          <span className="skeleton__page-description" />
        </div>
      </Header>
      <div className="skeleton__page-content" />
    </div>
  );
};

SkeletonDefaultPage.defaultProps = {
  hasNav: true
};

SkeletonDefaultPage.propTypes = {
  className: PropTypes.string,
  NavigationContainer: PropTypes.node
};

export default SkeletonDefaultPage;
