import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ModalGallery from "../../components/ModalGallery";
import { closeModal } from "../../../state/reducers/global";

const VehicleGallery = () => {
  const dispatch = useDispatch();

  let showroomGalleryItems = useSelector((state) => state.showroom.data?.galleryInfo);
  let vehicleGalleryItems = useSelector((state) => state.vehicle.data?.galleryInfo);
  let vendorVehicleDetailsGalleryItems = useSelector((state) => state.vehicleDetailsVendor?.primaryDetails?.data?.galleryInfo);
  let galleryItemsClaimRecord = useSelector(
    (state) => state.claimDetailsVendor.data?.galleryInfo
  );
  const galleryInitialIndex =
  useSelector((state) => state.vehicle.galleryInitialIndex) || 0;

  let galleryItems = vehicleGalleryItems || showroomGalleryItems;

  return (
    <ModalGallery
      galleryItems={galleryItems || galleryItemsClaimRecord || vendorVehicleDetailsGalleryItems}
      initialIndex={galleryInitialIndex}
      closeAction={() => dispatch(closeModal())}
    />
  );
};

export default VehicleGallery;
