import React from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import KeyboardShortcuts from "../../components/KeyboardShortcuts";
import ModalInsert from "../../components/ModalInsert";

const KeyboardShortcutModalInsert = () => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <ModalInsert>
      <KeyboardShortcuts onClose={onClose} />
    </ModalInsert>
  );
};

export default KeyboardShortcutModalInsert;
