import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../components/ModalInsert";
import { useNavigate } from "react-router";
import GenericConfirmInsert from "../../components/GenericConfirmInsert";
import { cancelSale } from "../../../state/reducers/claimDetailsVendor";
import ClaimCancelSaleConfirmation from "../../components/ClaimCancelSaleConfirmation";
import { clearVehicleRegRequest } from "../../../state/reducers/regSearch";
import { setBucketType } from "../../../state/reducers/toolbar";

const VendorCancelSaleDecision = ({ formData, buyerName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resultType = useSelector((state) => state.global.results.resultType);

  const [cancelSales, setCancelSales] = useState(false);

  const refreshResults = resultType === "claimsVendor";

  const onCancelSale = () => {
    dispatch(cancelSale(formData, refreshResults));
    dispatch(setBucketType("all"));
    dispatch(clearVehicleRegRequest());
    setTimeout(() => {
      dispatch(closeModal());
      if (!refreshResults) {
        navigate(-1);
      }
    }, 2500);
    setCancelSales(true);
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  return (
    <Fragment>
      <ModalInsert>
        {cancelSales ? (
          <ClaimCancelSaleConfirmation />
        ) : (
          <GenericConfirmInsert
            headerText="Are you sure?"
            bodyText={`You are about to cancel this sale for ${buyerName}, are you sure?`}
            onCancel={onCancel}
            onConfirm={onCancelSale}
          />
        )}
      </ModalInsert>
    </Fragment>
  );
};

export default VendorCancelSaleDecision;
