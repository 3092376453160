import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const FilterConical = ({ width, height, strokeColour, strokeWidth }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.459 2.57A.982.982 0 0019.673 1H1.982a.982.982 0 00-.786 1.57l7.666 10.301V22.6a.982.982 0 001.571.785l1.964-1.472a.982.982 0 00.393-.786V12.87l7.669-10.3z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

FilterConical.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

FilterConical.defaultProps = {
  width: "22",
  height: "25",
  strokeWidth: "2"
};

export default FilterConical;
