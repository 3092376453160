import React from "react";
import PropTypes from "prop-types";
import HeaderText from "../../../../views/components/HeaderText";
import ParagraphText from "../../../../views/components/ParagraphText";
import InputText from "../../../../views/components/InputText";
import Checkbox from "../../../../views/components/Checkbox";
import BidInputError from "../../../../views/components/BidInput/child-components/BidInputError";
import { paragraphText, errorMessage } from "./consts";
import { formatPrice } from "../../../../shared/utility";
import TextArea from "../../../../views/components/TextArea";

const MakeOfferForm = ({
  className,
  claimId,
  tradeBuyer,
  regNo,
  resolution,
  requestedAmount,
  dayCount,
  purchaseValue,
  residualValue,
  capValue,
  previousOffer,
  setNotesToBuyer,
  notesToBuyer,
  setOfferAmount,
  offerAmount,
  isOfferSaleCancellationChecked,
  setOfferSaleCancellationChecked,
  isValidOffer,
}) => {
  const initialValue = "";
  const treatValue = (val) => {
    let v = val.replace(/[^.\d]/g, "");
    let newVal =
      v.length > 5 && v.indexOf(".") <= 0
        ? v.substr(0, 5)
        : v.indexOf(".") >= 0
        ? v.substr(0, v.indexOf(".")) + v.substr(v.indexOf("."), 3)
        : v;

    if (newVal?.endsWith("..") || newVal?.endsWith(".00")) {
      newVal = newVal.slice(0, newVal.length - 1);
    }

    if (newVal === "00") newVal = 0;

    if (
      (newVal && newVal.charAt(newVal?.length - 1) === ".") ||
      (newVal && newVal.includes(".0")) ||
      (newVal && newVal.endsWith("0")) ||
      newVal === ""
    ) {
      return newVal;
    }

    return parseFloat(newVal);
  };
  const formatCurrency = (val) =>
    !isNaN(val)
      ? `£${val.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })}`
      : initialValue;

  return (
    <div className={className}>
      <HeaderText>{paragraphText.HEADER_TEXT}</HeaderText>
      <div className="make-offer__form">
        <div className="make-offer__detail-list">
          <div className="make-offer-list__row">
            <ParagraphText className="make-offer-list__row__col-one">
              {paragraphText.REGISTRATION}
            </ParagraphText>
            <ParagraphText primary className="make-offer-list__row__col-two">
              {regNo || "-"}
            </ParagraphText>
          </div>
          <div className="make-offer-list__row">
            <ParagraphText className="make-offer-list__row__col-one">
              {paragraphText.TRADE_BUYER}
            </ParagraphText>
            <ParagraphText primary className="make-offer-list__row__col-two">
              {tradeBuyer || "-"}
            </ParagraphText>
          </div>
          <div className="make-offer-list__row">
            <ParagraphText className="make-offer-list__row__col-one">
              {paragraphText.CASE_REFERENCE}
            </ParagraphText>
            <ParagraphText primary className="make-offer-list__row__col-two">
              {claimId || "-"}
            </ParagraphText>
          </div>
          <div className="make-offer-list__row">
            <ParagraphText className="make-offer-list__row__col-one">
              {paragraphText.REQUESTED_RESOLUTION}
            </ParagraphText>
            <ParagraphText red className="make-offer-list__row__col-two">
              {resolution || "-"}
            </ParagraphText>
          </div>
          <div className="make-offer-list__row">
            <ParagraphText className="make-offer-list__row__col-one">
              {paragraphText.REQUESTED_AMOUNT}
            </ParagraphText>
            <ParagraphText red className="make-offer-list__row__col-two">
              £{formatPrice(requestedAmount) || "-"}
            </ParagraphText>
          </div>
          <div className="make-offer-list__row">
            <ParagraphText className="make-offer-list__row__col-one">
              {paragraphText.OFFER_AMOUNT}
            </ParagraphText>
            <div className="make-offer-list__row__col-two">
              <InputText
                className="offer-amount"
                type="text"
                validValue={isValidOffer}
                disabled={false}
                value={formatCurrency(offerAmount)}
                onChange={(e) => setOfferAmount(treatValue(e.target.value))}
              ></InputText>
              {!isValidOffer && (
                <BidInputError
                  className="offer-amount__error"
                  errorMessage={errorMessage}
                />
              )}
            </div>
          </div>
        </div>

        <div className="make-offer__detail-list">
          <div className="make-offer-list__row">
            <ParagraphText className="make-offer-list__row__col-one">
              {paragraphText.DAY_COUNT}
            </ParagraphText>
            <ParagraphText primary className="make-offer-list__row__col-two">
              {dayCount || "-"}
            </ParagraphText>
          </div>
          <div className="make-offer-list__row">
            <ParagraphText className="make-offer-list__row__col-one">
              {paragraphText.PURCHASE_VALUE}
            </ParagraphText>
            <ParagraphText primary className="make-offer-list__row__col-two">
              £{formatPrice(purchaseValue) || "-"}
            </ParagraphText>
          </div>
          <div className="make-offer-list__row">
            <ParagraphText className="make-offer-list__row__col-one">
              {paragraphText.RESIDUAL_VALUE}
            </ParagraphText>
            <ParagraphText primary className="make-offer-list__row__col-two">
              {residualValue ? `£${formatPrice(residualValue)}` : "-"}
            </ParagraphText>
          </div>
          <div className="make-offer-list__row">
            <ParagraphText className="make-offer-list__row__col-one">
              {paragraphText.CAP_VALUE}
            </ParagraphText>
            <ParagraphText primary className="make-offer-list__row__col-two">
              {capValue ? `£${formatPrice(capValue)}` : "-"}
            </ParagraphText>
          </div>
          <div className="make-offer-list__row">
            <ParagraphText className="make-offer-list__row__col-one">
              {paragraphText.PREVIOUS_OFFER}
            </ParagraphText>
            <ParagraphText primary className="make-offer-list__row__col-two">
              {previousOffer ? `£${formatPrice(previousOffer)}` : "-"}
            </ParagraphText>
          </div>
          <div className="make-offer-list__row_cancel">
            <ParagraphText className="make-offer-list__row__col-one">
              {paragraphText.OFFER_SALE_CANCELLATION}
            </ParagraphText>
            <div className="make-offer-list__row__col-two">
              <Checkbox
                isChecked={isOfferSaleCancellationChecked}
                onInputChange={(e) =>
                  setOfferSaleCancellationChecked(e.target.checked)
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="submit-claim_notes-input">
        <ParagraphText>Notes to Buyer</ParagraphText>
        <TextArea
          type="text"
          resize={"none"}
          maximumCharactersBottom="2000"
          value={notesToBuyer.value || ""}
          rows="5"
          cols="10"
          onChange={(e) => setNotesToBuyer(e.target.value)}
        />
      </div>
    </div>
  );
};

MakeOfferForm.propTypes = {
  className: PropTypes.string,
  claimId: PropTypes.number,
  tradeBuyer: PropTypes.string,
  regNo: PropTypes.string,
  resolution: PropTypes.string,
  requestedAmount: PropTypes.number,
  dayCount: PropTypes.number,
  purchaseValue: PropTypes.number,
  residualValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  capValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  previousOffer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setNotesToBuyer: PropTypes.func,
  setOfferAmount: PropTypes.func,
  offerAmount: PropTypes.string,
  isOfferSaleCancellationChecked: PropTypes.bool,
  setOfferSaleCancellationChecked: PropTypes.func,
  isValidOffer: PropTypes.bool,
};

export default MakeOfferForm;
