import COLOURS from "./colours";
import FONTS from "./fonts";
import SPACING from "../base/spacing";
import ANIMATIONS from "./animations";
import IMAGES from "./images";

export const Motability = {
  COLOURS,
  FONTS,
  SPACING,
  ANIMATIONS,
  UI: {
    NAV_HEIGHT: {
      LARGE_DESKTOP: "8.2rem",
      MOBILE: "6rem"
    }
  },
  IMAGES,
  title: "mfldirect Auction",
  name: "Motability"
};
