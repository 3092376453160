import React, { useContext } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import HeaderText from "./../../HeaderText";
import ParagraphText from "./../../ParagraphText";
import Panel from "./../../Panel";
import Icon from "./../../Icon";
import PriceText from "./../../PriceText";
import { ThemeContext } from "styled-components";
import { useMediaQuery } from "react-responsive";
import breakpoints from "./../../../theme/breakpoints";

const DealerFundingRequestSuccess = ({
  className,
  qaClassName,
  fundingApplicationData,
  onClose,
}) => {
  const theme = useContext(ThemeContext);

  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  return (
    <div className={classnames(className, qaClassName)}>
      <Panel flex="1" padding={isDesktop ? "NONE" : "REGULAR"}>
        <div className="header">
          <Icon
            type="check-circle"
            width="40"
            height="40"
            strokeColour={theme.COLOURS.GREEN.base}
          />
          <HeaderText>Successfully Allocated</HeaderText>
        </div>
        <ParagraphText>
          Your request to allocate the vehicle purchase against the funding plan
          has been approved. Please see the below details of this approval.
          Should you have any queries on this, please contact the funding
          provider directly.
        </ParagraphText>
      </Panel>

      <Panel flex="1" padding={isDesktop ? "NONE" : "REGULAR"}>
        <div className="modal-content">
          <div className="row">
            <ParagraphText accent>Funding Plan Provider:</ParagraphText>
            <ParagraphText primary>
              {fundingApplicationData.fundingProviderName}
            </ParagraphText>
          </div>
          <div className="row">
            <ParagraphText accent>Credit Amount Allocated:</ParagraphText>
            <PriceText small content={fundingApplicationData.creditAmount} />
          </div>
        </div>
      </Panel>

      <Panel
        flex="1"
        padding={isDesktop ? "NONE" : "REGULAR"}
        actions={[
          {
            content: "Close",
            action: onClose,
            type: "ghost",
          },
        ]}
      />
    </div>
  );
};

DealerFundingRequestSuccess.defaultProps = {
  qaClassName: "qa-dealer-funding-request-success",
};

DealerFundingRequestSuccess.propTypes = {
  className: PropTypes.string,
  qaClassName: PropTypes.string,
  onClose: PropTypes.func,
  fundingApplicationData: PropTypes.object,
};

export default DealerFundingRequestSuccess;
