import COLOURS from "../base/colours";
import FONTS from "../base/fonts";
import SPACING from "../base/spacing";
import ANIMATIONS from "../base/animations";
import IMAGES from "../base/images";

export const TradeBuyer = {
  COLOURS,
  FONTS,
  SPACING,
  ANIMATIONS,
  IMAGES,
  UI: {
    NAV_HEIGHT: {
      LARGE_DESKTOP: "8.2rem",
      MOBILE: "6rem"
    }
  },
  title: "1link Trade Buyer",
  name: "TradeBuyer"
};
