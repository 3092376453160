import React, { useContext, useState, useEffect, memo } from "react";
import {
  ItemContainer,
  ItemLabel,
  ItemContent,
  ItemContentTwoColumn,
  ItemContentTwoColumnHeader,
  ItemContentTwoColumnInput,
} from "../index";
import { paragraphText, subHeaderText } from "../consts";
import ParagraphText from "../../ParagraphText";
import SubHeaderText from "../../SubHeaderText";
import InputText from "../../InputText";
import { UK_TELEPHONE_REGEX, EMAIL_REGEX } from "./../../../../shared/utility";
import InputError from "./../../BidInput/child-components/BidInputError";
import { errorMessages } from "../consts";
import {
  ClaimContactInformationContext,
  ClaimValidationContext,
} from "../../ClaimItem/context/context";
import { useMediaQuery } from "react-responsive";
import breakpoints from "./../../../theme/breakpoints";

const ContactInformation = ({
  contactName: claimContactName,
  telNo: claimTelNo,
  email: claimEmail,
  disabled,
}) => {
  const [hasFocus, setHasFocus] = useState(false);
  const [contactName, setContactName] = useState(claimContactName || "");
  const [telNo, setTelNo] = useState(claimTelNo || "");
  const [email, setEmail] = useState(claimEmail || "");
  const {
    validTelNo,
    setValidTelNo,
    validContactName,
    setValidContactName,
    validEmail,
    setValidEmail,
  } = useContext(ClaimValidationContext);

  const {
    setContactName: setContactNameCtx,
    setTelNo: setTelNoCtx,
    setEmail: setEmailCtx,
  } = useContext(ClaimContactInformationContext);

  useEffect(() => {
    if (contactName && validContactName) {
      setContactNameCtx(contactName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactName]);

  useEffect(() => {
    if (telNo && validTelNo) {
      setTelNoCtx(telNo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [telNo]);

  useEffect(() => {
    if (email && validEmail) {
      setEmailCtx(email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const onChangeContactName = (value) => {
    setContactName(value);
    if (value) {
      setValidContactName(true);
    } else {
      setValidContactName(false);
    }
  };

  const onChangeTelNo = (value) => {
    setTelNo(value);
    if (value && UK_TELEPHONE_REGEX.test(value)) {
      setValidTelNo(true);
    } else {
      setValidTelNo(false);
    }
  };

  const onChangeEmail = (value) => {
    setEmail(value);
    if (value && EMAIL_REGEX.test(value)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  };

  const isMobileOrTablet = useMediaQuery({
    query: `(max-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  return (
    <ItemContainer>
      <ItemLabel>
        <SubHeaderText>{subHeaderText.CONTACT_INFORMATION}:</SubHeaderText>
        <div style={{ width: "80%" }}>
          <ParagraphText className="info-text">
            {paragraphText.CONTACT_INFORMATION}
          </ParagraphText>
        </div>
      </ItemLabel>
      <ItemContent>
        <ItemContentTwoColumn>
          <ItemContentTwoColumnHeader>
            <ParagraphText>{paragraphText.CONTACT_NAME}:</ParagraphText>
          </ItemContentTwoColumnHeader>
          <ItemContentTwoColumnInput>
            <InputText
              type="text"
              value={contactName}
              maxLength="100"
              onChange={(e) => onChangeContactName(e.target.value)}
              validValue={validContactName}
              onBlur={(e) => {
                onChangeContactName(e.target.value);
                setHasFocus(false);
              }}
              onFocus={() => setHasFocus(true)}
              disabled={disabled}
            ></InputText>
            {!validContactName && !hasFocus && (
              <InputError
                errorMessage={
                  !isMobileOrTablet
                    ? errorMessages.CONTACT_FIELD_REQUIRED
                    : errorMessages.MOBILE_FIELD_REQUIRED
                }
              />
            )}
          </ItemContentTwoColumnInput>
        </ItemContentTwoColumn>
        <ItemContentTwoColumn>
          <ItemContentTwoColumnHeader>
            <ParagraphText>
              {paragraphText.CONTACT_TELEPHONE_NUMBER}:
            </ParagraphText>
          </ItemContentTwoColumnHeader>
          <ItemContentTwoColumnInput>
            <InputText
              type="text"
              value={telNo}
              maxLength="20"
              onChange={(e) => onChangeTelNo(e.target.value)}
              validValue={validTelNo}
              onBlur={(e) => {
                onChangeTelNo(e.target.value);
                setHasFocus(false);
              }}
              onFocus={(e) => {
                e.target.select();
                setHasFocus(true);
              }}
              disabled={disabled}
            ></InputText>
            {!validTelNo &&
              !hasFocus &&
              (telNo.length === 0 ? (
                <InputError
                  errorMessage={
                    !isMobileOrTablet
                      ? errorMessages.TEL_FIELD_REQUIRED
                      : errorMessages.MOBILE_FIELD_REQUIRED
                  }
                />
              ) : (
                <InputError errorMessage={errorMessages.INCORRECT_FORMAT} />
              ))}
          </ItemContentTwoColumnInput>
        </ItemContentTwoColumn>
        <ItemContentTwoColumn>
          <ItemContentTwoColumnHeader>
            <ParagraphText>
              {paragraphText.CONTACT_EMAIL_ADDRESS}:
            </ParagraphText>
          </ItemContentTwoColumnHeader>
          <ItemContentTwoColumnInput>
            <InputText
              type="email"
              value={email}
              maxLength="100"
              onChange={(e) => onChangeEmail(e.target.value)}
              validValue={validEmail}
              onBlur={(e) => {
                onChangeEmail(e.target.value);
                setHasFocus(false);
              }}
              onFocus={() => setHasFocus(true)}
              disabled={disabled}
            ></InputText>
            {!validEmail &&
              !hasFocus &&
              (email.length <= 0 ? (
                <InputError
                  errorMessage={
                    !isMobileOrTablet
                      ? errorMessages.EMAIL_FIELD_REQUIRED
                      : errorMessages.MOBILE_FIELD_REQUIRED
                  }
                />
              ) : (
                <InputError errorMessage={errorMessages.INCORRECT_FORMAT} />
              ))}
          </ItemContentTwoColumnInput>
        </ItemContentTwoColumn>
      </ItemContent>
    </ItemContainer>
  );
};

export default memo(ContactInformation);
