import React from "react";
import { useEffect } from "react";
import { useRef } from "react";

const Overlay = props => {
  const {
    classNames,
    selectedDay,
    returnDate,
    children,
    inputRef,
    ...rest
  } = props;
  const calendarRef = useRef();
  // TODO: Added this is the other clickOutside hook didn't work for this instance. Its's probably
  // worth combining these into one and importing as a hook
  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      const listener = event => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, handler]);
  };

  useOnClickOutside(calendarRef, () => {
    inputRef.current.hideDayPicker();
  });

  return (
    <div className={classNames.overlayWrapper} {...rest}>
      <div className={classNames.overlay}>
        <div ref={calendarRef}>{children}</div>
      </div>
    </div>
  );
};

export default Overlay;
