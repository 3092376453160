import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../../views/components/ModalInsert";
import breakpoints from "../../../views/theme/breakpoints";
import { useMediaQuery } from "react-responsive";
import GenericConfirmInsert from "../../../views/components/GenericConfirmInsert";
import {
  expireCampaign,
  clearActiveCampaign,
  deleteCampaign,
} from "../../../state/reducers/campaignManagement";
import { useNavigate } from "react-router";
import { vendorPaths } from "../../shared/consts";

const CampaignModalInsert = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { activeCampaignId, activeCampaignName, action } = useSelector(
    (state) => state.global.campaigns,
  );

  const [bodyText, setBodyText] = useState("");

  const onConfirm = () => {
    if (action === "copy") {
      dispatch(closeModal());
      dispatch(clearActiveCampaign());
      navigate(vendorPaths.CREATE_CAMPAIGNS, {
        state: { campaignId: activeCampaignId, RedirectAction: "copy" },
      });
    } else if (action === "expire") {
      dispatch(expireCampaign(activeCampaignId));
      dispatch(closeModal());
    } else if (action === "delete") {
      dispatch(deleteCampaign(activeCampaignId));
      dispatch(closeModal());
    }
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  useEffect(() => {
    setBodyText(
      `You are about to ${action} the ${activeCampaignName} campaign, are you sure?`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  return (
    <Fragment>
      <ModalInsert width={isDesktop ? "55rem" : "100%"}>
        <GenericConfirmInsert
          headerText="Are you sure?"
          bodyText={bodyText}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      </ModalInsert>
    </Fragment>
  );
};

export default CampaignModalInsert;
