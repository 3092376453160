import Api from "./Base";
import settings from "../clientappsettings.json";
import { formatFilterOptions } from "../shared/filter";

export class GetMessages extends Api {
  baseUrl = settings.BUYERS_URL;
  requestUrl = `/Messages/Search`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };

  formatRequestData(params) {
    if (params) {
      const { filters, pageSize, pageNumber, receivedDays, messagesBucket } = params;
      return {
        pageSize,
        pageNumber,
        receivedDays,
        messagesBucket,
        ...formatFilterOptions({ filterObject: filters, pageType: "messages" })
      };
    }
  }

  formatResponseData(response) {
    return response;
  }
}

export class DeleteMessage extends GetMessages {
  requestUrl = `/Messages/Delete/${this.getParams().id}`;
  method = "DELETE";
}

export class FetchMessageContent extends GetMessages {
  requestUrl = `/Messages/${this.getParams().id}`;
  method = "GET";
}

export class GetNotActionedMessagesCount extends Api {
  baseUrl = settings.BUYERS_URL;
  requestUrl = `/Messages/NotActionedCount`;

  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };
}

export class UpdateMessageStatus extends Api {
  baseUrl = settings.BUYERS_URL;
  requestUrl = `/Messages/MessageStatus`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };

  formatResponseData(response) {
    return response;
  }
}