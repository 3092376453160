import DetailsApi from "../../api/ShowroomDetails";
import { openModal } from "./global";

// Action constants

export const FETCH_SHOWROOM_DETAILS = "@showroom/FETCH_SHOWROOM_DETAILS";
export const FETCH_SHOWROOM_DETAILS_SUCCESS =
  "@showroom/FETCH_SHOWROOM_DETAILS_SUCCESS";
export const FETCH_SHOWROOM_DETAILS_FAILURE =
  "@showroom/FETCH_SHOWROOM_DETAILS_FAILURE";

// Action creators

const fetchShowroomDetailsFailure = (error) => {
    return {
      type: FETCH_SHOWROOM_DETAILS_FAILURE,
      payload: error,
    };
  };
  
  const fetchShowroomDetailsSuccess = (response) => {
    return {
      type: FETCH_SHOWROOM_DETAILS_SUCCESS,
      payload: response,
    };
  };
  
  export const fetchShowroomDetails = (vehicleId) => (dispatch, getState) => {
    dispatch({ type: FETCH_SHOWROOM_DETAILS });
  
    const detailsInstance = new DetailsApi({
      params: { vehicleId },
      credentials: getState().authentication.credentials,
    });
    return detailsInstance.call().then(
      (response) => {
        return dispatch(fetchShowroomDetailsSuccess(response));
      },
      (err) => {
        dispatch(openModal("vehicle-unavailable-new-tab"));
        return dispatch(fetchShowroomDetailsFailure(err.message));
      }
    );
  };

  const initialState = {
    isFetching: false,
    error: null,
    data: null,
  };

  export const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_SHOWROOM_DETAILS:
        return {
          ...state,
          isFetching: true,
        };
      case FETCH_SHOWROOM_DETAILS_SUCCESS:
        return {
          ...state,
          isFetching: false,
          data: action.payload,
        };
      case FETCH_SHOWROOM_DETAILS_FAILURE:
        return {
          ...state,
          data: null,
          isFetching: false,
          error: action.payload,
        };
        default:
      return state;
  }
};

export default reducer;
