import styled, { css } from "styled-components";
import Tooltip from "./Tooltip";

const handleTooltipDirection = (direction, tooltipWidth) => {
  switch (direction) {
    case "top":
      return css`
        bottom: 120%;
        left: 50%;
        margin-left: -10rem; //fallback
        ${tooltipWidth &&
        css`
          margin-left: ${`-${tooltipWidth}px`};
        `}
      `;
    case "bottom":
      return css`
        top: 120%;
        left: 50%;
        margin-left: -10rem; //fallback
        ${tooltipWidth &&
        css`
          margin-left: ${`-${tooltipWidth}px`};
        `}
      `;
    case "left":
      return css`
        right: 60px;
      `;
    case "right":
      return css`
        top: -0.5rem;
        left: 130%;
      `;
    default:
      return css`
        bottom: 120%;
        left: 50%;
        margin-left: -10rem; //fallback
        ${tooltipWidth &&
        css`
          margin-left: ${`-${tooltipWidth}px`};
        `}
      `;
  }
};

const handleArrowDirection = (direction, color, borderWidth) => {
  switch (direction) {
    case "top":
      return css`
        top: 100%;
        left: 50%;
        margin-left: ${borderWidth};
        border-color: ${color} transparent transparent transparent;
      `;
    case "bottom":
      return css`
        bottom: 100%;
        left: 50%;
        margin-left: ${borderWidth};
        border-color: transparent transparent ${color} transparent;
      `;
    case "left":
      return css`
        top: 50%;
        left: 100%;
        margin-top: ${borderWidth};
        border-color: transparent transparent transparent ${color};
      `;
    case "right":
      return css`
        top: 50%;
        right: 100%;
        margin-top: ${borderWidth};
        border-color: transparent ${color} transparent transparent;
      `;
    default:
      return css`
        top: 100%;
        left: 50%;
        margin-left: ${borderWidth};
        border-color: ${color} transparent transparent transparent;
      `;
  }
};

const StyledTooltip = styled(Tooltip)`
  position: relative;
  max-width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  & .tooltiptext {
    visibility: hidden;
    word-wrap: break-word;
    user-select: none !important;
    text-decoration: none !important;
    background-color: ${(props) => props.theme.COLOURS.WHITE};
    width: 20rem; //fallback
    ${(props) =>
      props.content &&
      css`
        width: ${`${props.content.length}rem`};
      `}
    max-width: 40rem; //fallback
    ${(props) =>
      props.tooltipMaxWidth &&
      css`
        max-width: ${`${props.tooltipMaxWidth}px`};
      `}
    min-width: 5rem;
    text-align: center;
    border: 0.2rem solid ${(props) => props.theme.COLOURS.ACCENT.base};
    border-radius: 0.5rem;
    padding: 0.5rem 0;
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 1s;
    ${(props) => {
      let tooltipWidth = 200; //fallback half of the max-width
      if (props.tooltipMaxWidth) {
        tooltipWidth = props.tooltipMaxWidth / 2;
      }
      if (props.content && props.content.length * 5 < tooltipWidth) {
        tooltipWidth = props.content.length * 5;
      }
      return handleTooltipDirection(props.direction, tooltipWidth);
    }};
  }

  & .tooltiptext::after {
    content: "";
    position: absolute;
    border-width: 0.7rem;
    border-style: solid;
    ${(props) =>
      handleArrowDirection(
        props.direction,
        props.theme.COLOURS.WHITE,
        "-0.7rem"
      )};
  }

  & .tooltiptext::before {
    content: "";
    position: absolute;
    border-width: 0.9rem;
    border-style: solid;
    ${(props) =>
      handleArrowDirection(
        props.direction,
        props.theme.COLOURS.ACCENT.base,
        "-0.9rem"
      )};
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
`;

export default StyledTooltip;
