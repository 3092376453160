import React from "react";
import { CANCEL_SALE_CONFIRMATION, CANCEL_SALE_CONFIRMATION_MESSAGE } from "./const";
import GenericTransitionModal from "../GenericTransitionModal";

const ClaimCancelSaleConfirmation = ({ className }) => {
  return (
    <div className={className}>
    <GenericTransitionModal
      headerText={CANCEL_SALE_CONFIRMATION}
      bodyText={CANCEL_SALE_CONFIRMATION_MESSAGE}
    />
  </div>
  );
};

export default ClaimCancelSaleConfirmation;
