import React from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import KeyInformationLabel from "../KeyInformationLabel";
import InfoTag from "../InfoTag";
import { isDateValid } from "../../../shared/utility";
import classnames from "classnames";
import useIsLoggedInUserType from "../../../shared/hooks/useIsLoggedInUserType";
import { USER_TYPE_MAP } from "../../../shared/utility";

const VehicleKeyInformation = ({
  serviceHistory,
  v5,
  mot,
  motExpiryDate,
  small,
  qaClassName,
  inspection,
  hasVmsData,
  className,
  vendorDetailsOnly,
}) => {
  const isMotExpiryDateValid = isDateValid(motExpiryDate);

  const motIconType = (() => {
    switch (mot) {
      case "Yes":
        return "include";
      case "No":
        return "exclude";
      default:
        return "dash";
    }
  })();

  const inspectionIconType = (() => {
    switch (inspection) {
      case true:
        return "include";
      case false:
        return "exclude";
      default:
        return "dash";
    }
  })();

  const v5IconType = (() => {
    switch (v5) {
      case "Yes":
        return "include";
      case "AppliedFor":
        return "include";
      case "PassedToTrade":
        return "include";
      case "DispatchedToAuction":
        return "include";
      case "No":
        return "exclude";
      default:
        return "dash";
    }
  })();

  const serviceHistoryIconType = (() => {
    switch (serviceHistory) {
      case "FullDealerServiceHistory":
        return "include";
      case "FullServiceHistory":
        return "include";
      case "Partial":
        return "include";
      case "None":
        return "exclude";
      default:
        return null;
    }
  })();

  const vmsIconType = (() => {
    switch (hasVmsData) {
      case true:
        return "include";
      case false:
        return "exclude";
      default:
        return null;
    }
  })();
  const isBuyerOnly = useIsLoggedInUserType(USER_TYPE_MAP.Buyer);

  return (
    <div className={classnames(className, qaClassName, "key-information")}>
      {isBuyerOnly && (
        <KeyInformationLabel small>KEY INFORMATION</KeyInformationLabel>
      )}
      <div className="key-information__tags">
        {serviceHistoryIconType && (
          <InfoTag
            content="Service History"
            success={serviceHistoryIconType === "include"}
            danger={serviceHistoryIconType === "exclude"}
            iconType={serviceHistoryIconType}
            small={small}
          />
        )}
        <InfoTag
          content="V5"
          success={v5IconType === "include"}
          danger={v5IconType === "exclude"}
          warning={v5IconType === "dash"}
          iconType={v5IconType}
          small={small}
        />
        <InfoTag
          content="MOT"
          success={motIconType === "include"}
          danger={motIconType === "exclude"}
          warning={motIconType === "dash"}
          iconType={motIconType}
          additionalIconText={
            isMotExpiryDateValid
              ? `(${dayjs(motExpiryDate).format("DD/MM/YYYY")})`
              : ""
          }
          small={small}
        />

        {!isBuyerOnly && (
          <InfoTag
            content="Inspection"
            success={inspectionIconType === "include"}
            danger={inspectionIconType === "exclude"}
            warning={inspectionIconType === "dash"}
            iconType={inspectionIconType}
            small={small}
          />
        )}
        {!isBuyerOnly && vendorDetailsOnly && (
          <InfoTag
            content="VMS"
            success={vmsIconType === "include"}
            danger={vmsIconType === "exclude"}
            iconType={vmsIconType}
            small={small}
          />
        )}
      </div>
    </div>
  );
};

VehicleKeyInformation.propTypes = {
  serviceHistory: PropTypes.oneOf([
    "NotApplicable",
    "FullDealerServiceHistory",
    "FullServiceHistory",
    "None",
    "Partial",
    "Unknown",
  ]),
  v5: PropTypes.oneOf([
    "Yes",
    "AppliedFor",
    "PassedToTrade",
    "No",
    "Unknown",
    "DispatchedToAuction",
  ]),
  mot: PropTypes.oneOf(["Unknown", "NotApplicable", "No", "Yes"]),
  motExpiryDate: PropTypes.string,
  className: PropTypes.string,
};

export default VehicleKeyInformation;