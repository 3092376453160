const PRIMARY = {
  base: "#27345F",
  shades: {
    5: "#F4F5F7",
    10: "#E9EAEF",
    20: "#C9CBD4",
    30: "#B4B8C5",
    40: "#A1A6B7",
    50: "#8C92A8",
    60: "#777F99",
    70: "#636C8B",
    80: "#4F5A7C",
    90: "#3A466D",
  },
};

const ACCENT = {
  base: "#AB8624",
  shades: {
    10: "#F6F2E9",
    20: "#EEE7D3",
    30: "#E5DABD",
    40: "#DDCFA7",
    50: "#D5C291",
    60: "#CDB67C",
    70: "#C4AA65",
    80: "#BC9E50",
    90: "#B39239",
  },
};

const SECONDARY = {
  base: "#1B223B",
  shades: {
    10: "#E8E8EB",
    20: "#D1D3D8",
    30: "#BABCC4",
    40: "#A4A7B1",
    50: "#8D909D",
    60: "#767A89",
    70: "#5F6475",
    80: "#494E62",
    90: "#31384E",
  },
};

const SECONDARY_ACCENT = {
  base: "#8D7127",
  shades: {
    10: "#F3F0E9",
    20: "#E8E3D4",
    30: "#DDD4BE",
    40: "#D2C7A9",
    50: "#C6B893",
    60: "#BBAA7E",
    70: "#AF9C68",
    80: "#A58E53",
    90: "#99803D",
  },
};

const GREEN = {
  base: "#429D16",
  shades: {
    10: "#ECF5E7",
    20: "#D9EBD0",
    30: "#C6E1B9",
    40: "#B2D8A2",
    50: "#A0CE8A",
    60: "#8EC473",
    70: "#7ABA5B",
    80: "#68B145",
    90: "#54A62D",
  },
};

const RED = {
  base: "#F22B2B",
  shades: {
    10: "#FDE9E9",
    20: "#FCD5D5",
    30: "#FBBFBF",
    40: "#FAAAAA",
    50: "#F89595",
    60: "#F78080",
    70: "#F56A6A",
    80: "#F55555",
    90: "#F34040",
  },
  contrast: "#832626",
};

const ORANGE = {
  base: "#FE7214",
  shades: {
    10: "#FEF0E7",
    20: "#FFE3D0",
    30: "#FED4B8",
    40: "#FFC7A1",
    50: "#FEB889",
    60: "#FEAA72",
    70: "#FE9C5A",
    80: "#FE8E43",
    90: "#FE802B",
  },
  contrast: "#c56d33",
};

const TRANSPARENCIES = {
  backgroundImage: "rgba(0,0,0,0)",
  primary: "rgba(39,52,95,0.3)",
  primaryDark: "rgba(39,52,95,0.9)",
  green: "rgba(66,157,22,0.05)",
  white: "rgba(27, 34, 59, 0.1)",
};

const COLOURS = {
  PRIMARY,
  SECONDARY,
  ACCENT,
  SECONDARY_ACCENT,
  RED,
  GREEN,
  ORANGE,
  BLACK: "#000000",
  BLUE: "#2183CD",
  BROWN: "#964B00",
  WHITE: "#FFFFFF",
  YELLOW: "#feb40c",
  SILVER: "#C0C0C0",
  GREY: "#808080",
  CREAM: "#FFFDD0",
  GOLD: "#FFD700",
  PINK: "#FFC0CB",
  PURPLE: "#A020F0",
  BEIGE: "#F5F5DC",
  BRONZE: "#CD7F32",
  MAROON: "#800000",
  TURQUOISE: "#40e0d0",
  ACTION_GREEN:"#4D9152",
  ALERT_RED:"#C33939",
  TRANSPARENCIES,
};

export default COLOURS;
