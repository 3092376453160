import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { closeModalWithNavigation } from "../../../state/reducers/global";
import ModalInsert from "../../components/ModalInsert";
import Panel from "../../components/Panel";
import AlertInsert from "../../components/AlertInsert";

const VehicleUnavailable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onBackToSearchClick = () => {
    return dispatch(closeModalWithNavigation("/", navigate));
  };

  return (
    <ModalInsert>
      <Panel padding="NONE">
        <AlertInsert
          action={onBackToSearchClick}
          headerText="Sorry, this vehicle is unavailable"
          bodyText="The vehicle you were looking at is no longer available for purchase"
          buttonText="Return to Search"
        />
      </Panel>
    </ModalInsert>
  );
};

export default VehicleUnavailable;
