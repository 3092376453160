import React from "react";
import PropTypes from "prop-types";
import Header from "../../Header";
import Navigation from "../../Navigation";

const SkeletonHomePage = props => {
  return (
    <div className={props.className}>
      <Header>
        <Navigation />
        <div className="skeleton__page-heading">
          <span className="skeleton__page-title" />
          <div className="skeleton__page-filters">
            <div className="skeleton__page-filter">
              <span className="skeleton__page-filter-title" />
              <span className="skeleton__page-filter-options" />
            </div>
            <div className="skeleton__page-filter">
              <span className="skeleton__page-filter-title" />
              <span className="skeleton__page-filter-options" />
            </div>
            <div className="skeleton__page-filter">
              <span className="skeleton__page-filter-title" />
              <span className="skeleton__page-filter-options" />
            </div>
            <div className="skeleton__page-filter">
              <span className="skeleton__page-filter-title" />
              <span className="skeleton__page-filter-options" />
            </div>
            <div className="skeleton__page-filter">
              <span className="skeleton__page-filter-title" />
              <span className="skeleton__page-filter-options" />
            </div>
          </div>
          <div className="skeleton__page-button-container">
            <span />
          </div>
        </div>
      </Header>
      <div className="skeleton__page-content" />
    </div>
  );
};
SkeletonHomePage.propTypes = {
  className: PropTypes.string
};

export default SkeletonHomePage;
