import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../components/ModalInsert";
import LogisticsStatus from "../../components/LogisticsStatus";
import { LOGISTICS_STATUS_MAP } from "../../../shared/utility";

const LogisticsStatusModalInsert = () => {
  const dispatch = useDispatch();

  const logisticsStatus = useSelector(
    (state) => state.orders?.logisticsStatus?.data
  );

  const timelineData = () => {
    if (!logisticsStatus.steps.length) {
      return null;
    }
    return logisticsStatus.steps.map((el) => ({
      text: el.stepName,
      status: LOGISTICS_STATUS_MAP[el.stepType],
      additionalText: el.additionalText ? el.additionalText.join(" ") : null,
    }));
  };

  const getLogisitcsProps = () => {
    switch (logisticsStatus.logisticsType) {
      case "Delivery":
        return {
          headerText: "Delivery Status",
          subHeaderText:
            "Please find the below information regarding your vehicle delivery.",
          data: timelineData(),
          issueMsg: logisticsStatus.warningMessage,
          onClose: onClose,
          className: "delivery-status",
          qaClassName: "qa-delivery-status",
        };
      case "Collection":
        return {
          headerText: "Collection Status",
          subHeaderText:
            "Please find the below information regarding your vehicle collection.",
          data: timelineData(),
          onClose: onClose,
          className: "collection-status",
          qaClassName: "qa-collection-status",
        };
      default:
        break;
    }
  };

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <ModalInsert layout="flex">
      <LogisticsStatus {...getLogisitcsProps()} />
    </ModalInsert>
  );
};

export default LogisticsStatusModalInsert;
