import Api from "./Base";
import settings from "../clientappsettings.json";

export class FetchVendorDashboardInfo extends Api {
  baseUrl = settings.VENDORS_URL;
  requestUrl = `/Claims/Dashboard`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatResponseData(response) {
    if (response.data) {
      return response.data;
    }
    return response;
  }
}

export class FetchVendorStats extends Api {
  baseUrl = settings.VENDORS_URL;
  requestUrl = `/Claims/Stats/${this.getParams().statType}`;
  method = "GET";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatResponseData(response) {
    if (response.data?.fusionChartStats) {
      return response.data.fusionChartStats;
    }
    return response;
  }
}

export class FetchVendorResponseTimeSla extends Api {
  baseUrl = settings.VENDORS_URL;
  requestUrl = `/Claims/Stats/ResponseTimeSla`;
  method = "GET";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };

  formatResponseData(response) {
    return response.data?.responseTimeSla;
  }
}
