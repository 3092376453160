import styled from "styled-components";
import Breadcrumb from "./Breadcrumb";
import breakpoints from "../../theme/breakpoints";

const StyledBreadcrumb = styled(Breadcrumb)`
  padding: 0 ${props => props.theme.SPACING.REGULAR};

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    padding: 0;
  }

  .breadcrumb__link {
    color: ${props => props.theme.COLOURS.PRIMARY.shades[60]};
    font-family: ${props => props.theme.FONTS.families.lato};
    font-size: ${props => props.theme.FONTS.sizes[40]};
    line-height: ${props => props.theme.FONTS.lineHeights[50]};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      font-size: ${props => props.theme.FONTS.sizes[50]};
      line-height: ${props => props.theme.FONTS.lineHeights[60]};
    }

    span {
      display: flex;
      align-items: center;
    }

    &__roundel {
      display: inline-flex;
      background-color: ${props => props.theme.COLOURS.PRIMARY.shades[50]};
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      margin-right: ${props => props.theme.SPACING.SMALL};

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        width: 3.2rem;
        height: 3.2rem;
      }
    }
  }
`;

export default StyledBreadcrumb;
