import React from "react";
import PropTypes from "prop-types";

const SkeletonCheckboxCampaignManagement = props => {
  return (
    <div className={props.className}>
      {[...Array(3)].map((item, index) => (
        <span key={`skeleton-checkbox-filter-${index}`}>
          <span className="skeleton-checkbox-filter__checkbox" />
          <span className="skeleton-checkbox-filter__label" />
        </span>
      ))}
    </div>
  );
};

SkeletonCheckboxCampaignManagement.propTypes = {
  className: PropTypes.string
};

export default SkeletonCheckboxCampaignManagement;
