import React, { useState } from "react";

export const DeclineClaimReasonContext = React.createContext({});
export const DeclineClaimReasonValidationContext = React.createContext({});

export const DeclineClaimReasonContextProvider = ({ children }) => {
  const [declineClaimReason, setDeclineClaimReason] = useState("");

  return (
    <DeclineClaimReasonContext.Provider
      value={{
        declineClaimReason,
        setDeclineClaimReason,
      }}
    >
      {children}
    </DeclineClaimReasonContext.Provider>
  );
};

export const DeclineClaimReasonValidationContextProvider = ({ children }) => {
  const [validClaimDeclineReason, setValidClaimDeclineReason] = useState(true);
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <DeclineClaimReasonValidationContext.Provider
      value={{
        validClaimDeclineReason,
        setValidClaimDeclineReason,
        hasFocus,
        setHasFocus,
      }}
    >
      {children}
    </DeclineClaimReasonValidationContext.Provider>
  );
};
