import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const CarPickup = ({ width, height, strokeColour, strokeWidth }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 46 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(1 1)"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="36.379" cy="17.432" r="3.789" />
        <path d="M9.853 17.432H.379" />
        <circle cx="13.642" cy="17.432" r="3.789" />
        <path d="M32.589 17.432H17.432M2.274 17.432V8.905c0-.523.424-.947.947-.947h16.105V1.326c0-.523.424-.947.948-.947h7.131a3.79 3.79 0 013.39 2.096l2.742 5.483h7.579a2.842 2.842 0 012.842 2.842v5.684a.947.947 0 01-.947.948h-2.843M33.537 7.958H19.326" />
      </g>
    </svg>
  );
};

CarPickup.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

CarPickup.defaultProps = {
  width: "46",
  height: "24",
  strokeWidth: "2"
};

export default CarPickup;
