import React from "react";
import propTypes from "prop-types";
import styled from "styled-components";
import Notification from "../../Notification";

const PanelNotification = props => {
  const { className, ...notification } = props;

  return (
    <div className={className}>
      <Notification {...notification} />
    </div>
  );
};

PanelNotification.propTypes = {
  className: propTypes.string,
  notification: propTypes.object
};

const StyledPanelNotification = styled(PanelNotification)`
  margin-top: ${props => props.theme.SPACING.REGULAR};
`;

export default StyledPanelNotification;
