import React, { Fragment, useState } from "react";
import propTypes from "prop-types";
import classnames from "classnames";
import HeaderText from "../HeaderText";
import InputText from "../InputText";
import ParagraphText from "../ParagraphText";
import Panel from "../../components/Panel";
import SubHeaderText from "./../SubHeaderText";
import TextArea from "../TextArea";
import InputRadio from "../InputRadio";
import Checkbox from "../Checkbox";
import {
  HEADER_TEXT,
  DESCRIPTION_TEXT,
  CHECKBOX_TEXT,
  subheaderText,
  financeType,
} from "./const";
import { formatCurrency, formatNumericValue } from "../ClaimItem/utils";

const ShowroomModalComponent = ({
  className,
  qaClassName,
  onCreateShowroom,
  onClose,
}) => {
  const [price, setPrice] = useState({
    value: 0,
    isValid: null,
    isTouched: false,
    errors: [],
  });

  const [finance, setFinance] = useState(0);

  const [salesText, setSalesText] = useState({
    value: "",
    isValid: true,
    isTouched: false,
    errors: [],
  });

  const [financeText, setFinanceText] = useState({
    value: "",
    isValid: true,
    isTouched: false,
    errors: [],
  });

  const [financeTypes, setFinanceTypes] = useState("HP");
    
  const [checkFinanceOption, setCheckFinanceOption] = useState(false);
  
  const showroomInformation = true;

  const showFinanceOptions = useState(false);
  const treatValue = (val) => parseInt(val.replace(/\D/g, ""));

  const formatPrice = (val) =>
    !isNaN(val) ? `£${val.toLocaleString()}` : `£0`;

  const updateValidFormElState = (updateFunction, value) => {
    updateFunction((state) => {
      return {
        ...state,
        value: value,
        isValid: true,
        isTouched: true,
        errors: [],
      };
    });
  };

  const updateInvalidFormElState = (updateFunction, value, errors) => {
    updateFunction((state) => {
      return {
        ...state,
        value: value,
        isValid: false,
        isTouched: true,
        errors: errors,
      };
    });
  };

  const onPriceChange = (value) => {
    const treatedValue = treatValue(value);
    if (treatedValue > 0) {
      updateValidFormElState(setPrice, treatedValue);
    } else {
      updateInvalidFormElState(setPrice, treatedValue, [
        "Price should not be lower than 0.",
      ]);
    }
  };

  const onFinanceTextChange = (e) => {
    const { value } = e.target;
    updateValidFormElState(setFinanceText, value);
  };

  const onSalesTextChange = (e) => {
    const { value } = e.target;
    updateValidFormElState(setSalesText, value);
  };

  const enableSubmit =
    (checkFinanceOption === false && price.isValid) ||
    (checkFinanceOption === true &&
      finance > 0 &&
      price.isValid &&
      (financeTypes === "PCP" || financeTypes === "HP")) ||
    (checkFinanceOption === true &&
      finance > 0 &&
      price.isValid &&
      financeText.value &&
      financeTypes === "Other");

  return (
    <div className={classnames(className, qaClassName)}>
      <Panel flex="1" padding="NONE">
        <HeaderText>{HEADER_TEXT}</HeaderText>
        <ParagraphText>{DESCRIPTION_TEXT}</ParagraphText>
      </Panel>
      <Panel flex="1" padding="NONE">
        <div className="showroom-modal__inputs">
          <div className="showroom-modal__inputs__details">
            <SubHeaderText colour="ACCENT">{subheaderText.PRICE}</SubHeaderText>
            <InputText
              type="text"
              value={formatPrice(price.value)}
              onChange={(e) => onPriceChange(e.target.value)}
              maxLength="9"
              validValue={price.isValid || !price.isTouched}
            />
            {!showFinanceOptions && ( 
            <Checkbox
              onInputChange={() => {
                setCheckFinanceOption((state) => !state);
              }}
              isChecked={checkFinanceOption}
            >
              {CHECKBOX_TEXT}
            </Checkbox>
            )}
            {checkFinanceOption && (
              <Fragment>
                <SubHeaderText colour="ACCENT">
                  {subheaderText.FINANCE}
                </SubHeaderText>
                <InputText
                  type="text"
                  onChange={(e) => setFinance(formatNumericValue(e.target.value))}
                  value={formatCurrency(finance)}
                  validValue={finance.isValid || !finance.isTouched}
                />
                <div className="radio-button-container">
                  <InputRadio
                    key={financeType.HP}
                    value={financeType.HP}
                    label={financeType.HP}
                    isChecked={financeType.HP === financeTypes}
                    onInputChange={() => {
                      setFinanceTypes(financeType.HP);
                    }}
                  />
                  <InputRadio
                    key={financeType.PCP}
                    value={financeType.PCP}
                    label={financeType.PCP}
                    isChecked={financeType.PCP === financeTypes}
                    onInputChange={() => {
                      setFinanceTypes(financeType.PCP);
                    }}
                  />
                  <InputRadio
                    key={financeType.OTHER}
                    value={financeType.OTHER}
                    label={financeType.OTHER}
                    isChecked={financeType.OTHER === financeTypes}
                    onInputChange={() => {
                      setFinanceTypes(financeType.OTHER);
                    }}
                  />
                </div>
              </Fragment>
            )}

            {financeTypes === "Other" && checkFinanceOption && (
              <InputText
                type="text"
                value={financeText.value}
                onChange={onFinanceTextChange}
                placeholder="Other"
                maxLength="30"
                validValue
              />
            )}
          </div>
          <div className="showroom-modal__inputs__textarea">
            <SubHeaderText colour="ACCENT">
              {subheaderText.SALES_TEXT}
            </SubHeaderText>
            <TextArea
              maximumCharactersTop={500}
              resize={"none"}
              value={salesText.value}
              onChange={onSalesTextChange}
              validValue
            />
          </div>
        </div>
      </Panel>
      <Panel
        flex="1"
        padding="NONE"
        actions={[
          {
            content: "Close",
            action: onClose,
            type: "ghost",
          },
          {
            content: "Enter Showroom",
            action: () => onCreateShowroom(price.value, finance, salesText.value, financeText.value, financeTypes, checkFinanceOption, showroomInformation),
            disabled: !enableSubmit,
            type: "primary",
          },
        ]}
      />
    </div>
  );
};

ShowroomModalComponent.propTypes = {
  className: propTypes.string,
  qaClassName: propTypes.string,
  onCreateShowroom: propTypes.func,
  onClose: propTypes.func,
};

export default ShowroomModalComponent;
