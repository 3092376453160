import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "./../../../views/components/ModalInsert";
import { acceptOffer } from "../../../state/reducers/claims";
import AcceptOfferConfirmationContainer from "../AcceptOfferConfirmationContainer";
import GenericConfirmInsert from "./../../../views/components/GenericConfirmInsert";

const AcceptSaleDecision = ({
  formData,
  radioOptions,
  vendorName,
  radioOptionChoice,
}) => {
  const dispatch = useDispatch();

  const [acceptOfferConfirmation, setAcceptOfferConfirmation] = useState(false);

  const onAcceptOffer = () => {
    dispatch(acceptOffer(formData));
    setAcceptOfferConfirmation(true);
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  const offerValueMessage = radioOptions.find(
    (x) => x.value === radioOptionChoice
  )?.optionName;

  return (
    <Fragment>
      <ModalInsert width="auto">
        {acceptOfferConfirmation ? (
          <AcceptOfferConfirmationContainer
            vendorName={vendorName}
            radioOptionChoice={radioOptionChoice}
          />
        ) : (
          <GenericConfirmInsert
            headerText="Are you sure?"
            bodyText={`You are about to accept an offer for ${offerValueMessage} from ${vendorName}, are you sure?`}
            onCancel={onCancel}
            onConfirm={onAcceptOffer}
          />
        )}
      </ModalInsert>
    </Fragment>
  );
};

export default AcceptSaleDecision;
