import styled from "styled-components";
import PendingPurchaseInsert from "./PurchasePendingInsert";
import { StyledHeaderText } from "../HeaderText";
import { StyledParagraphText } from "../ParagraphText";
import { StyledButton } from "../Button";

export default styled(PendingPurchaseInsert)`
  max-width: 55.2rem;
  border: 0.2rem solid ${props => props.theme.COLOURS.ORANGE.base};
  border-radius: 0.5rem;
  padding: 5.4rem 6rem 5rem;
  text-align: center;

  ${StyledHeaderText} {
    margin-top: 3rem;
    color: ${props => props.theme.COLOURS.ORANGE.base};
  }

  ${StyledParagraphText} {
    margin-top: 2.7rem;
    margin-bottom: 4.3rem;
  }

  .pending-purchase__actions {
    display: flex;
    margin: 0 auto;
    max-width: 43rem;
    justify-content: center;

    ${StyledButton} + ${StyledButton} {
      margin-left: ${props => props.theme.SPACING.REGULAR};
    }
  }
`;
