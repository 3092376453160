import styled from "styled-components";
import SalesBrochureModalComponent from "./SalesBrochureModalComponent";

const StyledSalesBrochureModalComponent = styled(SalesBrochureModalComponent)`
  box-sizing: border-box;
  background-color: ${props => props.theme.COLOURS.WHITE};
  border: 0.2rem solid ${props => props.theme.COLOURS.ACCENT.base};
  padding: ${props => props.theme.SPACING.MEDIUM};
  border-radius: 0.9rem;
  width: 100%;

  .sales-brochure-modal__inputs {
    margin-top: ${props => props.theme.SPACING.REGULAR};
    display: flex;
    justify-content: space-between;

    .sales-brochure-modal__inputs__price {
      flex: 0 0 30%;
      > input {
        margin-top: 2.5rem;
      }
    }
  }
`;

export default StyledSalesBrochureModalComponent;
