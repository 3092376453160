import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import HeaderText from "../HeaderText";
import ParagraphText from "../ParagraphText";
import Panel from "../Panel";
import SkeletonRows from "../Skeletons/SkeletonRows";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../theme/breakpoints";
import PriceText from "../PriceText/PriceText";
import { getBillingType } from "../../../shared/utility";

const TariffInformation = ({
  className,
  qaClassName,
  onClose,
  tariffData,
  isFetching,
}) => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  return (
    <div className={classnames(className, qaClassName)}>
      <Panel flex="1" padding={isDesktop ? "NONE" : "REGULAR"}>
        <div className="modal-header">
          <HeaderText>Tariff Information</HeaderText>
          <ParagraphText>
            This shows a list of charges that are applicable to the tariff you
            are on.
          </ParagraphText>
        </div>
      </Panel>

      <Panel flex="1" padding={isDesktop ? "NONE" : "REGULAR"}>
        <div className="modal-content">
          {!isFetching && tariffData ? (
            tariffData.map((el) => (
              <div className="tariff-fees__three-col-row" key={el.feeName}>
                <div>
                  <ParagraphText primary>{el.feeName}</ParagraphText>
                </div>
                <div>
                  <ParagraphText primary>
                    <PriceText content={el.feeAmount}></PriceText>
                  </ParagraphText>
                </div>
                <div>
                  <ParagraphText primary>
                    {getBillingType(el.billingType)}
                  </ParagraphText>
                </div>
              </div>
            ))
          ) : (
            <SkeletonRows nrOfRows={2} paddingNone />
          )}
        </div>
      </Panel>

      <Panel
        flex="1"
        padding={isDesktop ? "NONE" : "REGULAR"}
        actions={[
          {
            content: "Close",
            action: onClose,
            type: "ghost",
          },
        ]}
      />
    </div>
  );
};

TariffInformation.defaultProps = {
  className: "tariff",
  qaClassName: "qa-tariff",
};

TariffInformation.propTypes = {
  className: PropTypes.string,
  qaClassName: PropTypes.string,
  isFetching: PropTypes.bool,
  tariffData: PropTypes.arrayOf(
    PropTypes.shape({
      feeName: PropTypes.string,
      feeAmount: PropTypes.number,
      billingType: PropTypes.oneOf([
        "AnnualRenewal",
        "TransactionMonthly",
        "TransactionIndividual",
        "PrePayment",
      ]),
    })
  ),
  onClose: PropTypes.func,
};

export default TariffInformation;
