import React, { useContext, useState, useEffect } from "react";
import { ItemContainer, ItemLabel, ItemContent } from "../index";
import { paragraphText, subHeaderText } from "../consts";
import ParagraphText from "../../ParagraphText";
import SubHeaderText from "../../SubHeaderText";
import {
  ClaimContactInformationContext,
  ClaimValidationContext,
} from "../../ClaimItem/context/context";
import { useMediaQuery } from "react-responsive";
import breakpoints from "./../../../theme/breakpoints";
import SupportingDocumentLinks from "./SupportingDocumentLinks";

const SupportingDocumentLink = ({
  supportingLink: claimSupportingLink,
  disabled,
  urls,
  options,
}) => {
  const [supportingLink, setSupportingLink] = useState(
    claimSupportingLink || ""
  );
  const { validSupportingLink, setValidSupportingLink } = useContext(
    ClaimValidationContext
  );

  const [lineCount, setLineCount] = useState(0);
  const [outputText, setOutputText] = useState(`Document Link ${lineCount}`);
  const addLine = () => {
    setOutputText(
      (prevOutputText) => `${prevOutputText} Document Link ${lineCount}\n`
    );
    setLineCount((prevLineCount) => prevLineCount + 1);
  };
  const removeLine = () => {
    setLineCount((prevLineCount) => prevLineCount - 1);
  };

  const onChangeSupportingDocumentLink = (value) => {
    setSupportingLink(value);
  };

  const isMobileOrTablet = useMediaQuery({
    query: `(max-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  return (
    <ItemContainer>
      <ItemLabel>
        <SubHeaderText>{subHeaderText.SUPPORTING_DOCUMENT_LINKS}</SubHeaderText>
        <div style={{ width: "80%" }}>
          <ParagraphText className="info-text">
            {paragraphText.SUPPORTING_DOCUMENT_LINKS}
          </ParagraphText>
        </div>
      </ItemLabel>
      <ItemContent>
        <SupportingDocumentLinks
          options={options}
          urls={urls}
          disabled={disabled}
        />
      </ItemContent>
    </ItemContainer>
  );
};

export default SupportingDocumentLink;
