import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const FuelPump = ({width, height, strokeColour, fillColour}) => {
    const theme = useContext(ThemeContext);
  const fill = fillColour ? fillColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      className="bi bi-fuel-pump"
      viewBox="0 0 16 16"
    >
      <path d="M3 2.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v5a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5z"></path>
      <path d="M1 2a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 012 2v.5a.5.5 0 001 0V8h-.5a.5.5 0 01-.5-.5V4.375a.5.5 0 01.5-.5h1.495c-.011-.476-.053-.894-.201-1.222a.97.97 0 00-.394-.458c-.184-.11-.464-.195-.9-.195a.5.5 0 010-1q.846-.002 1.412.336c.383.228.634.551.794.907.295.655.294 1.465.294 2.081v3.175a.5.5 0 01-.5.501H15v4.5a1.5 1.5 0 01-3 0V12a1 1 0 00-1-1v4h.5a.5.5 0 010 1H.5a.5.5 0 010-1H1zm9 0a1 1 0 00-1-1H3a1 1 0 00-1 1v13h8z"></path>
    </svg>
  );
}

FuelPump.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    strokeColour: PropTypes.string,
    fillColour: PropTypes.string,

    // strokeWidth: PropTypes.string
  };
  
  FuelPump.defaultProps = {
    width: "22",
    height: "25",
    // strokeWidth: "2"
  };

export default FuelPump;