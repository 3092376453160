import styled from "styled-components";

import AddressPicker from "./AddressPicker.jsx";
import breakpoints from "../../theme/breakpoints";

export const StyledAddressPicker = styled(AddressPicker)`
  background: ${(props) => props.theme.COLOURS.PRIMARY.shades[10]};
  height: 100%;
  padding: ${(props) => props.theme.SPACING.REGULAR};

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    background: none;
    height: auto;
    padding: ${(props) => props.theme.SPACING.LARGE};
  }

  .title {
    color: ${(props) => props.theme.COLOURS.PRIMARY.base};
    ${(props) => props.theme.FONTS.Text["title"]}
  }

  .address-picker {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    text-align: left;

    .address-input {
      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        display: flex;
        align-items: center;
      }

      p {
        flex-basis: 30%;
        color: ${(props) => props.theme.COLOURS.PRIMARY.base};
        ${(props) => props.theme.FONTS.Text["paragraphBold"]}
      }

      input {
        width: 100%;
        box-sizing: border-box;
        padding: 1rem;
        color: ${(props) => props.theme.COLOURS.PRIMARY.base};
        margin: ${(props) => props.theme.SPACING.SMALL} 0;
        display: inline-block;
        border: 0.1rem solid
          ${(props) => props.theme.COLOURS.PRIMARY.shades[20]};
        border-radius: 0.4rem;
        ${(props) => props.theme.FONTS.Text["paragraph"]}
      }
    }

    .address-area {
      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        display: flex;
      }

      p {
        flex-basis: 30%;
        color: ${(props) => props.theme.COLOURS.PRIMARY.base};
        ${(props) => props.theme.FONTS.Text["paragraphBold"]}
      }
    }

    .address-actions {
      bottom: 2rem;
      display: flex;
      left: 0;
      justify-content: space-between;
      padding: 0 ${(props) => props.theme.SPACING.REGULAR};
      padding-bottom: env(safe-area-inset-bottom);
      position: absolute;
      width: 100%;

      button {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(50% - 0.5rem);
      }

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        margin-top: ${(props) => props.theme.SPACING.SMALL};
        display: flex;
        justify-content: flex-end;
        padding: 0;
        position: static;

        button {
          overflow: visible;
          text-overflow: none;
          white-space: normal;
          width: auto;
        }

        button:first-child {
          margin-right: ${(props) => props.theme.SPACING.SMALL};
        }
      }
    }

    .popover__arrow {
      height: 0;
      width: 0;
      position: relative;
      transform: translateX(100%);
      z-index: 1101;
      pointer-events: none;
      top: -0.8rem;
      left: 22rem;

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        position: absolute;
        top: 18.6rem;
      }

      &:after,
      &:before {
        bottom: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &:after {
        border-color: transparent;
        border-bottom-color: ${(props) => props.theme.COLOURS.WHITE};
        border-width: 0.8rem;
        margin-left: -0.8rem;
        top: -0.6rem;
      }

      &::before {
        border-color: transparent;
        border-bottom-color: ${(props) =>
          props.theme.COLOURS.SECONDARY.shades[20]};
        border-width: 1rem;
        margin-left: -1rem;
        top: -1.1rem;
      }
    }

    .result-list {
      border: 0.2rem solid
        ${(props) => props.theme.COLOURS.SECONDARY.shades[20]};
      border-radius: 0.4rem;
      box-sizing: border-box;
      background-color: ${(props) => props.theme.COLOURS.WHITE};
      width: 100%;
      overflow: auto;
      max-height: 25rem;

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        position: absolute;
        z-index: 1100;
        top: 19.4rem;
        left: 19rem;
        width: 49rem;
      }

      .result-list__item {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        margin: 0;
        padding: 0.5rem;
        cursor: pointer;

        > p {
          margin: 0;

          &:first-child {
            margin-right: ${(props) => props.theme.SPACING.SMALL};
          }
        }

        &:hover,
        &.active {
          background-color: ${(props) => props.theme.COLOURS.PRIMARY.base};
          > p:first-child {
            color: ${(props) => props.theme.COLOURS.WHITE};
          }
        }
      }
    }
  }
`;

export default StyledAddressPicker;
