import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const ArrowMatches = ({ width, height, strokeColour, strokeWidth }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(1 1)"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect x="12.543" y="12.541" width="6.957" height="6.957" rx="1.391" />
        <path d="M.043 31.999L9.76 22.28M22.282 9.759L32 .04M29.238 9.759h-6.956V2.802M9.76 29.237V22.28H2.803M.032.029l9.728 9.73M22.282 22.28L32 31.999M22.282 29.237V22.28h6.956M2.803 9.759H9.76V2.802" />
      </g>
    </svg>
  );
};

ArrowMatches.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

ArrowMatches.defaultProps = {
  width: "34",
  height: "34",
  strokeWidth: "2"
};

export default ArrowMatches;
