import React from "react";
import propTypes from "prop-types";
import Checkbox from "../../components/Checkbox";
import TermsCheckboxLabel from "./child-components/TermsCheckboxLabel";

const TermsCheckbox = props => {
  const {
    className,
    isChecked,
    onInputChange,
    chunkedLabel = [],
    value = ""
  } = props;

  return (
    <div className={className}>
      <Checkbox
        value={value}
        isChecked={isChecked}
        onInputChange={onInputChange}
      >
        <span>
          {chunkedLabel.map((label, index) => (
            <TermsCheckboxLabel key={`label-chunk-${index}`} {...label} />
          ))}
        </span>
      </Checkbox>
    </div>
  );
};

TermsCheckbox.propTypes = {
  className: propTypes.string,
  isChecked: propTypes.bool,
  onInputChange: propTypes.func,
  chunkedLabel: propTypes.array,
  value: propTypes.oneOfType([propTypes.string, propTypes.number])
};

TermsCheckbox.defaultProps = {
  chunkedLabel: [
    {
      text: "I agree to the terms and conditions of bidding on this vehicle"
    }
  ]
};

export default TermsCheckbox;
