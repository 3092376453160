import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const SpeechBubble = ({ width, height, strokeColour, strokeWidth }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 1C8.532 1 1.667 6.67 1.667 13.667A11.65 11.65 0 006.2 22.655L3 30.333l8.496-4.854c1.78.566 3.636.854 5.504.854 8.468 0 15.333-5.67 15.333-12.666S25.468 1 17 1z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

SpeechBubble.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

SpeechBubble.defaultProps = {
  width: "34",
  height: "32",
  strokeWidth: "2"
};

export default SpeechBubble;
