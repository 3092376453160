import React from "react";
import PropTypes from "prop-types";
import HeaderText from "../../../../views/components/HeaderText";
import ParagraphText from "../../../../views/components/ParagraphText";
import { paragraphText, callBackTimes } from "./consts";
import { getClaimStatusText } from "../../../../shared/utility";

const ClaimCallbackForm = ({
  className,
  claimId,
  callbackTime,
  claimStatus,
  tradeBuyer,
  contactName,
  telNo,
  requestNotes,
  setVendorNotes,
}) => {
  return (
    <div className={className}>
      <HeaderText>{paragraphText.HEADER_TEXT}</HeaderText>
      <div className="callback__form">
        <div className="callback__detail-list">
          <div className="callback-detail-list__row">
            <ParagraphText className="callback-detail-list__row__col-one">
              {paragraphText.STATUS}
            </ParagraphText>
            <ParagraphText
              primary
              className="callback-detail-list__row__col-two"
            >
              {getClaimStatusText(claimStatus) || "-"}
            </ParagraphText>
          </div>
          <div className="callback-detail-list__row">
            <ParagraphText className="callback-detail-list__row__col-one">
              {paragraphText.TRADE_BUYER}
            </ParagraphText>
            <ParagraphText
              primary
              className="callback-detail-list__row__col-two"
            >
              {tradeBuyer || "-"}
            </ParagraphText>
          </div>
          <div className="callback-detail-list__row">
            <ParagraphText className="callback-detail-list__row__col-one">
              {paragraphText.CONTACT_NAME}
            </ParagraphText>
            <ParagraphText
              primary
              className="callback-detail-list__row__col-two"
            >
              {contactName || "-"}
            </ParagraphText>
          </div>
        </div>

        <div className="callback__detail-list">
          <div className="callback-detail-list__row">
            <ParagraphText className="callback-detail-list__row__col-one">
              {paragraphText.CASE_REFERENCE}
            </ParagraphText>
            <ParagraphText
              primary
              className="callback-detail-list__row__col-two"
            >
              {claimId || "-"}
            </ParagraphText>
          </div>
          <div className="callback-detail-list__row">
            <ParagraphText className="callback-detail-list__row__col-one">
              {paragraphText.TEL_NO}
            </ParagraphText>
            <ParagraphText
              primary
              className="callback-detail-list__row__col-two"
            >
              {telNo || "-"}
            </ParagraphText>
          </div>
          <div className="callback-detail-list__row">
            <ParagraphText className="callback-detail-list__row__col-one">
              {paragraphText.CALLBACK_TIME}
            </ParagraphText>
            <ParagraphText
              primary
              className="callback-detail-list__row__col-two"
            >
              {callbackTime || "-"}
            </ParagraphText>
          </div>
        </div>
      </div>

      <div className="callback-detail-request-notes">
        <ParagraphText>{paragraphText.REQUEST_NOTES}</ParagraphText>
        <ParagraphText primary>{requestNotes || "-"}</ParagraphText>
      </div>

      <div className="submit-claim_notes-input">
        <ParagraphText>Vendor Notes</ParagraphText>
        <textarea
          className="text-area__input"
          type="text"
          resize={"none"}
          maxLength="500"
          rows="5"
          cols="10"
          onChange={(e) => setVendorNotes(e.target.value)}
        />
      </div>
    </div>
  );
};

ClaimCallbackForm.propTypes = {
  className: PropTypes.string,
  claimId: PropTypes.number,
  callbackTime: PropTypes.oneOf([
    callBackTimes.AM,
    callBackTimes.PM,
    callBackTimes.ANYTIME,
  ]),
  claimStatus: PropTypes.string,
  tradeBuyer: PropTypes.string,
  contactName: PropTypes.string,
  telNo: PropTypes.string,
  requestNotes: PropTypes.string,
  setVendorNotes: PropTypes.func,
};

export default ClaimCallbackForm;
