import styled, { css } from "styled-components";

import InputRadio from "./InputRadio";
import breakpoints from "../../theme/breakpoints";

const StyledInputRadio = styled(InputRadio)`
  display: flex;
  justify-content: ${props => (props.count ? "flex-start" : "space-between")};
  align-items: center;
  .radio-input__label {
    display: flex;
    align-items: center;
    min-height: 3.6rem;
    position: relative;
    padding-left: 4.8rem;
    cursor: pointer;
    user-select: none;
    color: ${props => props.theme.COLOURS.PRIMARY.base};
    ${props => props.theme.FONTS.Text["paragraphCompact"]}

    ${props =>
      props.inverted &&
      css`
        color: ${(props) => props.inputRadioLabelColour || props.theme.COLOURS.WHITE};
      `}
  }
  .radio-input__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .radio-input__indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: 3.2rem;
    width: 3.2rem;
    background-color: ${props => props.theme.COLOURS.WHITE};
    border-radius: 50%;
    border: 0.2rem solid ${props => props.theme.COLOURS.PRIMARY.shades[20]};
  }
  .radio-input__label .radio-input__input:checked ~ .radio-input__indicator {
    background-color: ${props => props.theme.COLOURS.WHITE};
    border-color: ${props => props.theme.COLOURS.PRIMARY.base};
  }
  .radio-input__indicator:after {
    content: "";
    position: absolute;
    display: none;
    background: ${props => props.theme.COLOURS.WHITE};
    transition: all 0.2s ease;
  }

  .radio-input {
    &__label {
      .radio-input__input:checked {
        & ~ .radio-input__indicator:after {
          display: block;
        }
      }
    }
    &__label:hover {
      .radio-input__input {
        & ~ .radio-input__indicator:after {
          display: block;
        }
      }
    }
    &__label:hover {
      .radio-input__input:checked {
        & ~ .radio-input__indicator:after {
          display: block;
          background: ${props => props.theme.COLOURS.PRIMARY.base};
        }
      }
    }
  }

  .radio-input__label .radio-input__indicator:after {
    top: 0.55rem;
    left: 0.55rem;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    background: ${props => props.theme.COLOURS.PRIMARY.base};
  }
  .radio-input__label:hover .radio-input__indicator:after {
    background: ${props => props.theme.COLOURS.PRIMARY.shades[20]};
  }

  .radio-input__note {
    font-size: ${props => props.theme.FONTS.sizes[30]};
    line-height: ${props => props.theme.FONTS.lineHeights[80]};
    color: ${props => props.theme.COLOURS.PRIMARY.shades[60]};
    display: block;
  }
  &.radio-input--disabled {
    .radio-input__label {
      pointer-events: none;
    }
    .radio-input__title {
      color: ${props => props.theme.COLOURS.PRIMARY.shades[70]};
    }
    .radio-input__label:hover .radio-input__indicator:after {
      background: transparent;
    }
  }
  &.radio-input--in-accordion {
    background-color: ${props => props.theme.COLOURS.PRIMARY.shades[10]};
    margin-top: 1rem;
    border-radius: 0.5rem;
    border: 0.2rem solid ${props => props.theme.COLOURS.PRIMARY.shades[20]};
    box-sizing: border-box;
    padding: ${props => props.theme.SPACING.REGULAR};
    .radio-input__title {
      font-weight: ${props => props.theme.FONTS.weights[70]};
      font-size: ${props => props.theme.FONTS.sizes[40]};
      line-height: ${props => props.theme.FONTS.lineHeights[50]};

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        font-size: ${props => props.theme.FONTS.sizes[60]};
        line-height: ${props => props.theme.FONTS.lineHeights[70]};
      }
    }
    &.radio-input--checked {
      border-color: ${props => props.theme.COLOURS.GREEN.base};
      background-color: ${props => props.theme.COLOURS.TRANSPARENCIES.green};
      .radio-input__title {
        color: ${props => props.theme.COLOURS.PRIMARY.base};
      }
    }
  }
  .radio-input__price {
    margin: 0;
  }
  .radio-input__count {
    margin: 0 0 0 0.5rem;
  }
`;

export default StyledInputRadio;
