import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ThemeContext } from "styled-components";
import Icon from "../Icon";

const Pill = ({ content, claimId, className, action, iconType, large }) => {
  const theme = useContext(ThemeContext);

  const activeClaimId = useSelector(
    (state) => state.global.claims.activeClaimId
  );

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsDisabled(activeClaimId === claimId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeClaimId]);

  return (
    <div
      className={`pill ${className} ${
        isDisabled && content !== "View" ? "pill-disabled" : ""
      }`}
      onClick={action}
    >
      {iconType &&
        (large ? (
          <Icon
            type={iconType}
            strokeColour={theme.COLOURS.WHITE}
            height="20"
            width="25"
          />
        ) : (
          <Icon
            type={iconType}
            strokeColour={theme.COLOURS.WHITE}
            height="15"
            width="20"
          />
        ))}
      <p>{content}</p>
    </div>
  );
};

export default Pill;
