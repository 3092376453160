import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { closeModalWithNavigation } from "../../../state/reducers/global";
import ModalInsert from "../../components/ModalInsert";
import Panel from "../../components/Panel";
import PendingPurchaseInsert from "../../components/PurchasePendingInsert";

const PendingPurchase = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let vehicleId;
  const purchaseDetails = useSelector(
    (state) => state.purchase.purchaseDetails,
  );
  if (purchaseDetails) {
    vehicleId = purchaseDetails.vehicleId;
  }
  const onViewVehicleClick = () => {
    return dispatch(
      closeModalWithNavigation(`/vehicle/${vehicleId}`, navigate),
    );
  };
  const onFindVehiclesClick = () => {
    return dispatch(closeModalWithNavigation("/", navigate));
  };

  return (
    <ModalInsert>
      <Panel padding="NONE">
        <PendingPurchaseInsert
          onViewVehicleClick={onViewVehicleClick}
          onFindVehiclesClick={onFindVehiclesClick}
        />
      </Panel>
    </ModalInsert>
  );
};

export default PendingPurchase;
