import { GetStocklist } from "../../api/Stocklist";

// Action constants
export const FETCH_STOCK_LIST = "@stockList/FETCH_STOCK_LIST";
export const FETCH_STOCK_LIST_SUCCESS = "@stockList/FETCH_STOCK_LIST_SUCCESS";
export const FETCH_STOCK_LIST_FAILURE = "@stockList/FETCH_STOCK_LIST_FAILURE";

// Action creators
export const fetchStockListSuccess = () => {
  return {
    type: FETCH_STOCK_LIST_SUCCESS,
    payload: true
  };
};

export const fetchStockListFailure = error => {
  return {
    type: FETCH_STOCK_LIST_FAILURE,
    payload: error
  };
};

export const fetchStockList = () => (dispatch, getState) => {
  dispatch({ type: FETCH_STOCK_LIST });

  const stockListInstance = new GetStocklist({
    credentials: getState().authentication.credentials
  });

  stockListInstance.call().then(
    response => {
      dispatch(fetchStockListSuccess());
    },
    err => {
      return dispatch(fetchStockListFailure(err));
    }
  );
};

const initialState = {
  isFetching: false,
  error: false,
  success: null
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STOCK_LIST:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_STOCK_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case FETCH_STOCK_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
