import React from "react";
import PropTypes from "prop-types";
import Button from "../../../Button";

const PanelActions = props => {
  const { className, actions = [] } = props;

  return (
    <div className={className}>
      {actions.map((actionItem, index) => {
        const {
          action,
          content = "",
          disabled = false,
          type = "primary",
          ...rest
        } = actionItem;
        const dynamicProps = {
          [type]: true
        };

        return (
          <Button
            key={`action-${index}`}
            action={action}
            content={content}
            disabled={disabled}
            {...dynamicProps}
            {...rest}
          />
        );
      })}
    </div>
  );
};

PanelActions.propTypes = {
  className: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func,
      content: PropTypes.string,
      disabled: PropTypes.bool,
      type: PropTypes.string
    })
  )
};

export default PanelActions;
