import styled, { css } from "styled-components";

import SubHeaderText from "./SubHeaderText.jsx";
import breakpoints from "../../theme/breakpoints.js";

export const StyledSubHeaderText = styled(SubHeaderText)`
  color: ${props =>
    props.colour
      ? props.colour || props.theme.COLOURS[props.colour].base
      :  props.theme.COLOURS.PRIMARY.base };
  ${props => props.theme.FONTS.Text["subHeader"]}
  font-size: ${props => props.theme.FONTS.sizes[50]};

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    ${props => props.theme.FONTS.Text["subHeader"]}
  }
  
  ${props =>
    props.small &&
    css`
      font-size: ${props.theme.FONTS.sizes[40]};
      line-height: ${props.theme.FONTS.lineHeights[50]};

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        font-size: ${props.theme.FONTS.sizes[60]};
        line-height: ${props.theme.FONTS.lineHeights[70]};
      }
    `}

  ${props =>
    props.medium &&
    css`
      font-size: ${props.theme.FONTS.sizes[50]};
      line-height: ${props.theme.FONTS.lineHeights[60]};

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        font-size: ${props.theme.FONTS.sizes[80]};
        line-height: ${props.theme.FONTS.lineHeights[100]};
      }
    `}

  /* Overwrite size */
  font-size: ${props =>
    props.fontSize && props.theme.FONTS.sizes[props.fontSize]};
  line-height: ${props =>
    props.fontSize && props.theme.FONTS.lineHeights[props.fontSize]};

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    font-size: ${props =>
      props.fontSize && props.theme.FONTS.sizes[props.fontSize]};
    line-height: ${props =>
      props.fontSize && props.theme.FONTS.lineHeights[props.fontSize]};
  }
`;

export default StyledSubHeaderText;
