import React, { Fragment, useContext } from "react";
import { useSelector } from "react-redux";
import PricingBlock from "../../components/PricingBlock";
import { ThemeContext } from "styled-components";
import CountdownInsert from "../../components/CountdownInsert/CountdownInsert"; //doubled as there is no index.js

const BidPricingBlockWinning = () => {
  const vehicleId = useSelector(
    (state) => state.global.bids.activeBidVehicleId
  );
  const bidDetails = useSelector(
    (state) => state.bid.bids.byVehicleId[vehicleId]
  );
  const {
    bidCount = 0,
    biddingEndDate,
    currentBidAmount = 0,
    highestBidder = false,
    previousMaxBidAmount = 0,
  } = bidDetails;

  const theme = useContext(ThemeContext);
  return highestBidder ? (
    <Fragment>
      <PricingBlock
        price={currentBidAmount}
        headerText="My current bid"
        priceColor={theme.COLOURS.GREEN.base}
        bidText={`${bidCount} Bids`}
        large
      />
      <div style={{ display: "block", marginBottom: "1rem" }} />
      <PricingBlock
        headerText={
          biddingEndDate ? (
            <CountdownInsert endDateTime={biddingEndDate} />
          ) : (
            "Open Ended Bidding"
          )
        }
        large
        inline
      />
      <div style={{ display: "block", marginBottom: "2rem" }} />
      <PricingBlock
        price={previousMaxBidAmount}
        headerText="My current max bid"
        large
      />
    </Fragment>
  ) : null;
};

export default BidPricingBlockWinning;
