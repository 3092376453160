import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {thunk} from "redux-thunk";
import rootReducer from "../reducers/index";
import { isTokenValid } from "../../shared/utility";
import { CLEAR_AUTHENTICATION } from "../reducers/authentication";

const credentialCheck = (store) => (next) => (action) => {
  const thunkWhitelist = ["authenticationRequest", "authenticationSuccess"];
  if (
    /**
     * If the action is a function, we're dealing with a thunk
     * (and most likely a network call).
     * We allow the 'authenticationThunk' to proceed, however.
     */
    typeof action === "function" &&
    !thunkWhitelist.includes(action.name) &&
    /**
     * If we have previously cleared the credentials,
     * the "registerNavigationEvent" thunk will fire
     * following the redirect. Don't double-clear credentials
     * in this case
     */
    store.getState().authentication.credentials &&
    !isTokenValid(store.getState().authentication.credentials)
  ) {
    return next({ type: CLEAR_AUTHENTICATION });
  }
  return next(action);
};

/**
 * TODO: there is currently a very loose implementation for error handling in the reducers.
 * There is currently no logging implemented, only some error states are accounted for. This should
 * definitely be looked at further, preferably before MVP go-live
 */

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

// TODO: this is a very naive implementation - check time limit for credentials
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["authentication"],
};
const reducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  reducer,
  // Check that credentials are still ok before proceeding with the side-effect
  composeEnhancers(applyMiddleware(credentialCheck, thunk))
);

export const persistor = persistStore(store);
