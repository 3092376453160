import {
  ForgottenUsernameApi,
  ForgottenPasswordApi,
  ResetPasswordApi,
} from "../../api/ForgottenCredentials";

// Action constants
export const FORGOTTEN_USERNAME_REQUEST = "@auth/FORGOTTEN_USERNAME_REQUEST";
export const FORGOTTEN_USERNAME_REQUEST_SUCCESS =
  "@auth/FORGOTTEN_USERNAME_REQUEST_SUCCESS";
export const FORGOTTEN_USERNAME_REQUEST_FAILURE =
  "@auth/FORGOTTEN_USERNAME_REQUEST_FAILURE";
export const FORGOTTEN_PASSWORD_REQUEST = "@auth/FORGOTTEN_PASSWORD_REQUEST";
export const FORGOTTEN_PASSWORD_REQUEST_SUCCESS =
  "@auth/FORGOTTEN_PASSWORD_REQUEST_SUCCESS";
export const FORGOTTEN_PASSWORD_REQUEST_FAILURE =
  "@auth/FORGOTTEN_PASSWORD_REQUEST_FAILURE";
export const RESET_PASSWORD_REQUEST = "@auth/RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_REQUEST_SUCCESS =
  "@auth/RESET_PASSWORD_REQUEST_SUCCESS";
export const RESET_PASSWORD_REQUEST_FAILURE =
  "@auth/RESET_PASSWORD_REQUEST_FAILURE";
export const CLEAR_FORGOTTEN_DETAILS = "@auth/CLEAR_FORGOTTEN_DETAILS";

// Action creators
export const forgottenUsernameRequest = (
  email,
  postcode,
  surname,
  themeName
) => (dispatch) => {
  dispatch({
    type: FORGOTTEN_USERNAME_REQUEST,
  });
  const params = {
    email: email,
    postcode: postcode,
    surname: surname,
    themeName: themeName,
  };

  const instance = new ForgottenUsernameApi({
    params,
  });

  return instance.call().then(
    (result) => {
      return dispatch(forgottenUsernameRequestSuccess());
    },
    (err) => {
      return dispatch(forgottenUsernameRequestFailure(err));
    }
  );
};

const forgottenUsernameRequestSuccess = () => {
  return {
    type: FORGOTTEN_USERNAME_REQUEST_SUCCESS,
  };
};

const forgottenUsernameRequestFailure = (error) => {
  return {
    type: FORGOTTEN_USERNAME_REQUEST_FAILURE,
    payload: error,
  };
};

export const forgottenPasswordRequest = (email, username) => (dispatch) => {
  dispatch({
    type: FORGOTTEN_PASSWORD_REQUEST,
  });
  const params = {
    email: email,
    username: username,
  };

  const instance = new ForgottenPasswordApi({
    params,
  });

  return instance.call().then(
    (result) => {
      return dispatch(forgottenPasswordRequestSuccess());
    },
    (err) => {
      return dispatch(forgottenPasswordRequestFailure(err));
    }
  );
};

const forgottenPasswordRequestSuccess = () => {
  return {
    type: FORGOTTEN_PASSWORD_REQUEST_SUCCESS,
  };
};

const forgottenPasswordRequestFailure = (error) => {
  return {
    type: FORGOTTEN_PASSWORD_REQUEST_FAILURE,
    payload: error,
  };
};

export const resetPasswordRequest = (username, expiryDate, token, password) => (
  dispatch
) => {
  dispatch({
    type: RESET_PASSWORD_REQUEST,
  });
  const params = {
    username: username,
    expiryDate: expiryDate,
    token: token,
    newPassword: password,
  };

  const instance = new ResetPasswordApi({
    params,
  });

  return instance.call().then(
    (result) => {
      return dispatch(resetPasswordRequestSuccess());
    },
    (err) => {
      return dispatch(resetPasswordRequestFailure(err));
    }
  );
};

const resetPasswordRequestSuccess = () => {
  return {
    type: RESET_PASSWORD_REQUEST_SUCCESS,
  };
};

const resetPasswordRequestFailure = (error) => {
  return {
    type: RESET_PASSWORD_REQUEST_FAILURE,
    payload: error,
  };
};

export const clearForgottenDetails = () => {
  return {
    type: CLEAR_FORGOTTEN_DETAILS,
  };
};

const initialState = {
  isFetchingForgottenUsername: false,
  isFetchingForgottenPassword: false,
  isFetchingResetPassword: false,
  forgottenUsernameRequestSuccess: false,
  forgottenUsernameRequestFailure: false,
  forgottenPasswordRequestSuccess: false,
  forgottenPasswordRequestFailure: false,
  resetPasswordRequestSuccess: false,
  resetPasswordRequestFailure: false,
  error: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FORGOTTEN_USERNAME_REQUEST:
      return {
        ...state,
        isFetchingForgottenUsername: true,
        forgottenUsernameRequestSuccess: false,
        forgottenUsernameRequestFailure: false,
        error: false,
      };
    case FORGOTTEN_USERNAME_REQUEST_SUCCESS:
      return {
        ...state,
        isFetchingForgottenUsername: false,
        forgottenUsernameRequestSuccess: true,
        forgottenUsernameRequestFailure: false,
        error: false,
      };
    case FORGOTTEN_USERNAME_REQUEST_FAILURE:
      return {
        ...state,
        isFetchingForgottenUsername: false,
        error: true,
        forgottenUsernameRequestSuccess: false,
        forgottenUsernameRequestFailure: true,
      };
    case FORGOTTEN_PASSWORD_REQUEST:
      return {
        ...state,
        isFetchingForgottenPassword: true,
        forgottenPasswordRequestSuccess: false,
        forgottenPasswordRequestFailure: false,
        error: false,
      };
    case FORGOTTEN_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        isFetchingForgottenPassword: false,
        forgottenPasswordRequestSuccess: true,
        forgottenPasswordRequestFailure: false,
        error: false,
      };
    case FORGOTTEN_PASSWORD_REQUEST_FAILURE:
      return {
        ...state,
        isFetchingForgottenPassword: false,
        forgottenPasswordRequestSuccess: false,
        forgottenPasswordRequestFailure: true,
        error: true,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isFetchingResetPassword: true,
        resetPasswordRequestSuccess: false,
        resetPasswordRequestFailure: false,
        error: false,
      };
    case RESET_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        isFetchingResetPassword: false,
        resetPasswordRequestSuccess: true,
        resetPasswordRequestFailure: false,
        error: false,
      };
    case RESET_PASSWORD_REQUEST_FAILURE:
      return {
        ...state,
        isFetchingResetPassword: false,
        resetPasswordRequestSuccess: false,
        resetPasswordRequestFailure: true,
        error: true,
      };
    case CLEAR_FORGOTTEN_DETAILS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
