import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { openModal } from "../../state/reducers/global";
import useIsLoggedInUserType from "./useIsLoggedInUserType";
import { USER_TYPE_MAP } from "../utility";

export function useKeyboardShortcuts(handleKeyDown) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isUserBuyer = useIsLoggedInUserType(USER_TYPE_MAP.Buyer);
  const isUserVendor = useIsLoggedInUserType(USER_TYPE_MAP.Vendor);
  let isAccountAdministrator = useSelector(
    (state) => state.authentication.meta.isAccountAdministrator,
  );
  const { allowBuyerClaims, allowPushNotifications } = useSelector(
    (state) => state.authentication.meta.userDefaults
  );

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (
        (isUserBuyer || isUserVendor) &&
        e.target.tagName !== "INPUT" &&
        e.target.tagName !== "TEXTAREA" &&
        !e.altKey &&
        !e.ctrlKey &&
        !e.metaKey
      ) {
        //Global shortcuts
        switch (e.key) {
          case "?":
            dispatch(openModal("keyboard-shortcuts"));
            break;
          case "/":
            dispatch(openModal("keyboard-shortcuts"));
            break;
          case "z":
            dispatch(openModal("about-session"));
            break;
          case "l":
            dispatch(openModal("logout"));
            break;
          default:
            break;
        }
        //Buyer shortcuts
        if (isUserBuyer) {
          switch (e.key) {
            case "f":
              navigate("/");
              break;
            case "m":
              navigate("/messages");
              break;
            case "s":
              navigate("/shortlist");
              break;
            case "b":
              navigate("/bids-and-requests");
              break;
            case "p":
              navigate("/purchases/archive");
              break;
            case "a":
              if (isAccountAdministrator) {
                navigate("/account-administration");
              }
              break;
            case "n":
              if (allowPushNotifications) {
                navigate("/notification-preference");
              }
              break;
            case "d":
              dispatch(openModal("stocklist-download"));
              break;
            case "i":
              navigate("/invoices");
              break;
            case "v":
              navigate("/activity-log");
              break;
            case "h":
              navigate("/help-centre");
              break;
            case "w":
              navigate("/change-password");
              break;
            case "c":
              if (allowBuyerClaims) {
                navigate("/claims");
              }
              break;
            default:
              break;
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);
}

export default useKeyboardShortcuts;
