import React from "react";
import { useDispatch, useSelector } from "react-redux";
import IframeCam from "../../components/Iframe";
import { closeModal } from "../../../state/reducers/global";

const IframeCamContainer = () => {
  const dispatch = useDispatch();

  const iframeVehicle360url = useSelector(
    (state) => state.vehicle.data && state.vehicle.data.vehicle360Url
  );

  const iframeVehicleClaim360url = useSelector(
    (state) =>
      state.claimDetailsVendor.data &&
      state.claimDetailsVendor.data.vehicle360Url
  );

  const iframeVendorVehicle360url = useSelector(
    (state) =>
      state.vehicleDetailsVendor.primaryDetails.data &&
      state.vehicleDetailsVendor.primaryDetails.data.vehicle360Url
  );

  let iframeItems = iframeVehicle360url || iframeVehicleClaim360url || iframeVendorVehicle360url;

  const closeAction = () => {
    dispatch(closeModal("cam360"));
  };

  return (
    iframeItems && <IframeCam closeAction={closeAction} src={iframeItems} />
  );
};

export default IframeCamContainer;
