import React from "react";
import PropTypes from "prop-types";
import { MainContainer } from "./index";
import Header from "./child-components/Header";
import ClaimCategory from "./child-components/ClaimCategory";
import ClaimSubCategory from "./child-components/ClaimSubCategory";
import VehicleLocation from "./child-components/VehicleLocation";
import Vehicle from "./child-components/Vehicle";
import Reason from "./child-components/Reason";
import ContactInformation from "./child-components/ContactInformation";
import AdditionalInformation from "./child-components/AdditionalInformation";
import Resolution from "./child-components/Resolution";
import SupportingDocument from "./child-components/SupportingDocument";
import ButtonGroup from "./child-components/ButtonGroup";
import SupportingDocumentLink from "./child-components/SupportingDocumentLink";

const ClaimForm = ({
  data,
  subCategories,
  addressLookup,
  onClaimUpdateOrSubmit,
  onCancelButtonClick,
  isSaveEnabled,
  isSubmitEnabled,
  onClickOpenActivityLog,
  disabled,
  onSaveSupportingDocument,
  mandateSupportingDocument,
  isFetchingSupportingDocument
}) => {
  const {
    status,
    claimId,
    claimCategoryId,
    claimSubCategoryId,
    make,
    derivative,
    model,
    regNo,
    options,
    claimReason,
    contactName,
    telNo,
    email,
    vehicleAddress,
    claimCostType,
    claimResolutionId,
    claimTotalCost,
    urls
  } = data;

  const vehicleProps = {
    make,
    model,
    derivative,
    regNo
  };

  const contactInformation = {
    contactName,
    telNo,
    email
  };

  return (
    <MainContainer>
      <Header
        claimId={claimId}
        status={status}
        onClickOpenActivityLog={onClickOpenActivityLog}
      />
      <div>
        <Vehicle {...vehicleProps} disabled={disabled} />
        <ClaimCategory
          options={options}
          claimCategoryId={claimCategoryId}
          disabled={disabled}
        />
        <ClaimSubCategory
          subCategories={subCategories}
          claimSubCategoryId={claimSubCategoryId}
          disabled={disabled}
        />
        <VehicleLocation
          addressLookup={addressLookup}
          vehicleAddress={vehicleAddress}
          disabled={disabled}
        />
        <Reason claimReason={claimReason} disabled={disabled} />
        <SupportingDocument
          supportingDocuments={options?.supportingDocuments}
          mandateSupportingDocument={mandateSupportingDocument}
          disabled={disabled}
          claimId={claimId}
          onSaveSupportingDocument={onSaveSupportingDocument}
          isFetchingSupportingDocument={isFetchingSupportingDocument}
        />
        <SupportingDocumentLink
          options={options}
          urls={urls}
          disabled={disabled}
        />
        <ContactInformation {...contactInformation} disabled={disabled} />
        <AdditionalInformation options={options} disabled={disabled} />
        <Resolution
          options={options}
          claimCostType={claimCostType}
          claimResolutionId={claimResolutionId}
          claimTotalCost={claimTotalCost}
          disabled={disabled}
        />
        <ButtonGroup
          onCancelButtonClick={onCancelButtonClick}
          onClaimUpdateOrSubmit={onClaimUpdateOrSubmit}
          isSaveEnabled={isSaveEnabled}
          isSubmitEnabled={isSubmitEnabled}
          disabled={disabled}
        />
      </div>
    </MainContainer>
  );
};

export default ClaimForm;

ClaimForm.propTypes = {
  claimId: PropTypes.number,
  status: PropTypes.oneOf(["NotSaved", "Unsubmitted"]),
  make: PropTypes.string,
  model: PropTypes.string,
  derivative: PropTypes.string,
  regNo: PropTypes.string,
  contactName: PropTypes.string,
  telNo: PropTypes.string,
  email: PropTypes.string,
  options: PropTypes.shape({
    categories: PropTypes.array,
    costLines: PropTypes.array,
    questionAnswers: PropTypes.array,
    resolution: PropTypes.array,
    supportingDocuments: PropTypes.array
  }),
  claimReason: PropTypes.string,
  claimCostType: PropTypes.string,
  claimResolutionId: PropTypes.number,
  claimTotalCost: PropTypes.number,
  mandateSupportingDocument: PropTypes.bool,
  onClickOpenActivityLog: PropTypes.func,
  onSaveSupportingDocument: PropTypes.func
};
