import Api from "./Base";
import settings from "../clientappsettings.json";

export class SaveSupportingDocument extends Api {
  baseUrl = settings.AZURE_VEHICLE_URL;
  requestUrl = `/api/ClaimBlobUpload?code=${settings.AZURE_VEHICLE_API_KEY}`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
    "Content-Type": "multipart/form-data",
  };
}

export class DeleteSupportingDocument extends Api {
  baseUrl = settings.AZURE_VEHICLE_URL;
  requestUrl = `/api/ClaimBlobDelete?code=${settings.AZURE_VEHICLE_API_KEY}`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class SaveSupportingBlockDocument extends Api {
  baseUrl = settings.AZURE_VEHICLE_URL;
  requestUrl = `/api/ClaimBlobBlockUpload?claimId=${
    this.getParams().claimId
  }&fileName=${this.getParams().fileName}&blockNo=${
    this.getParams().blockNo
  }&blockSize=${this.getParams().blockSize}&finalBlock=${
    this.getParams().finalBlock
  }&code=${settings.AZURE_VEHICLE_API_KEY}`;
  method = "POST_BLOCK";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class FetchSupportingDocument extends Api {
  baseUrl = settings.AZURE_VEHICLE_URL;
  requestUrl = `/api/ClaimBlobList?code=${settings.AZURE_VEHICLE_API_KEY}`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  formatResponseData(response) {
    if (response.data?.supportingDocuments) {
      return response.data.supportingDocuments;
    }
    return response;
  }
}
