import React from "react";
import PropTypes from "prop-types";
import COLOURS from "../../../theme/base/colours";

const AlertCircleSolid = ({ width, height, fill }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <circle fill={fill} cx="9" cy="9" r="9" />
      <path
        d="M9.007 11.328c.535 0 .993.38.993.836 0 .445-.458.836-.993.836C8.43 13 8 12.61 8 12.164c0-.456.431-.836 1.007-.836zM8.993 5c.51 0 .942.239.942.662 0 1.292-.183 3.148-.183 4.44 0 .336-.445.477-.759.477-.418 0-.77-.14-.77-.477 0-1.292-.184-3.148-.184-4.44 0-.423.418-.662.954-.662z"
        fill={COLOURS.WHITE}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

AlertCircleSolid.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
};

AlertCircleSolid.defaultProps = {
  width: "18",
  height: "18",
  fill: COLOURS.ALERT_RED
};

export default AlertCircleSolid;
