import styled from "styled-components";
import { StyledButton } from "../Button";
import { StyledHeaderText } from "../HeaderText";
import { StyledParagraphText } from "../ParagraphText";

import GenericConfirmInsert from "./GenericConfirmInsert.jsx";
import breakpoints from "../../theme/breakpoints";

export const StyledGenericConfirmInsert = styled(GenericConfirmInsert)`
  background: white;
  height: 100%;
  padding: ${(props) => props.theme.SPACING.REGULAR};
  text-align: center;

  @media (max-width: ${breakpoints.PHONE}) {
    height: auto;
  }

  ${StyledHeaderText} {
    color: ${(props) => props.theme.COLOURS.ORANGE.base};
  }

  ${StyledParagraphText} {
    margin-top: 1rem;
  }

  ${StyledButton} + ${StyledButton} {
    margin-left: ${(props) => props.theme.SPACING.REGULAR};
  }

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    background: white;
    min-height: 28rem;
    padding: ${(props) => props.theme.SPACING.LARGE};
    border: 0.2rem solid ${(props) => props.theme.COLOURS.ORANGE.base};
    border-radius: 0.5rem;
  }

  @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
    background: white;
    min-height: 28rem;
    padding: ${(props) => props.theme.SPACING.LARGE};
    margin-bottom: ${(props) => props.theme.SPACING.LARGE};
    border: 0.2rem solid ${(props) => props.theme.COLOURS.ORANGE.base};
    border-radius: 0.5rem;

    @media (max-width: ${breakpoints.PHONE}) {
      padding: ${(props) => props.theme.SPACING.REGULAR};
    }

    .text-area {
      margin-top: 20rem;

      @media (max-width: ${breakpoints.PHONE}) {
        margin-top: 0;
        margin-bottom: 8rem;
      }
    }
  }

  .actions {
    bottom: 2rem;
    display: flex;
    left: 0;
    justify-content: space-between;
    padding: 0 ${(props) => props.theme.SPACING.REGULAR};
    padding-bottom: env(safe-area-inset-bottom);
    position: absolute;
    width: 100%;

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      margin-top: ${(props) => props.theme.SPACING.REGULAR};
      display: flex;
      justify-content: center;
      padding: 0;
      position: static;

      button {
        overflow: visible;
        text-overflow: none;
        white-space: normal;
        width: auto;
      }

      button:first-child {
        margin-right: ${(props) => props.theme.SPACING.SMALL};
      }
    }

    @media (max-width: ${breakpoints.PHONE}) {
      position: relative;
    }
  }
`;

export default StyledGenericConfirmInsert;
