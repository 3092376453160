import {
  SubscribePushNotification,
  UnSubscribePushNotification,
  UpdatePushNotificationPreferences,
  GetPushNotificationPreferences,
  TestNotifications,
} from "../../api/NotificationPreference";
import { closeModal } from "./global";

// Action constants
export const SUBSCRIBE_PUSH_NOTIFICATION =
  "@notification/SUBSCRIBE_PUSH_NOTIFICATION";
export const SUBSCRIBE_PUSH_NOTIFICATION_SUCCESS =
  "@notification/SUBSCRIBE_PUSH_NOTIFICATION_SUCCESS";
export const SUBSCRIBE_PUSH_NOTIFICATION_FAILURE =
  "@notification/SUBSCRIBE_PUSH_NOTIFICATION_FAILURE";

export const UNSUBSCRIBE_PUSH_NOTIFICATION =
  "@notification/UNSUBSCRIBE_PUSH_NOTIFICATION";
export const UNSUBSCRIBE_PUSH_NOTIFICATION_SUCCESS =
  "@notification/UNSUBSCRIBE_PUSH_NOTIFICATION_SUCCESS";
export const UNSUBSCRIBE_PUSH_NOTIFICATION_FAILURE =
  "@notification/UNSUBSCRIBE_PUSH_NOTIFICATION_FAILURE";

export const GET_PUSH_NOTIFICATION_PREFERENCES =
  "@notification/GET_PUSH_NOTIFICATION_PREFERENCES";
export const GET_PUSH_NOTIFICATION_PREFERENCES_SUCCESS =
  "@notification/GET_PUSH_NOTIFICATION_PREFERENCES_SUCCESS";
export const GET_PUSH_NOTIFICATION_PREFERENCES_FAILURE =
  "@notification/GET_PUSH_NOTIFICATION_PREFERENCES_FAILURE";

export const UPDATE_PUSH_NOTIFICATION_PREFERENCES =
  "@notification/UPDATE_PUSH_NOTIFICATION_PREFERENCES";
export const UPDATE_PUSH_NOTIFICATION_PREFERENCES_SUCCESS =
  "@notification/UPDATE_PUSH_NOTIFICATION_PREFERENCES_SUCCESS";
export const UPDATE_PUSH_NOTIFICATION_PREFERENCES_FAILURE =
  "@notification/UPDATE_PUSH_NOTIFICATION_PREFERENCES_FAILURE";
export const TEST_NOTIFICATIONS = "@notification/TEST_NOTIFICATIONS";
export const SET_SUBSCRIBED = "notifications/SET_SUBSCRIBED";

export const testNotifications = () => (dispatch, getState) => {
  dispatch({ type: TEST_NOTIFICATIONS });

  const testInstance = new TestNotifications({
    credentials: getState().authentication.credentials,
  });

  testInstance.call().then(
    (response) => {},
    (err) => {
      console.log("Test Notification Failed " + err);
    },
  );
};

export const subscribePushNotification =
  (subscriptionDetails, notificationPreferences) => (dispatch, getState) => {
    dispatch({ type: SUBSCRIBE_PUSH_NOTIFICATION });

    const params = {
      subscriptionDetails: subscriptionDetails,
      notificationPreferences: notificationPreferences,
    };

    const subscribeInstance = new SubscribePushNotification({
      params,
      credentials: getState().authentication.credentials,
    });

    subscribeInstance.call().then(
      (response) => {
        dispatch(subscribePushNotificationSuccess(response.data));
      },
      (err) => {
        return dispatch(subscribePushNotificationFailure(err));
      },
    );
  };

export const subscribePushNotificationSuccess = (data) => {
  return {
    type: SUBSCRIBE_PUSH_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const subscribePushNotificationFailure = (error) => {
  return {
    type: SUBSCRIBE_PUSH_NOTIFICATION_FAILURE,
    payload: error,
  };
};

export const unsubscribePushNotification =
  (subscriptionDetails, notificationPreferences, isUnSubscribeAll, userId) =>
  (dispatch, getState) => {
    dispatch({ type: UNSUBSCRIBE_PUSH_NOTIFICATION });

    const params = {
      subscriptionDetails: subscriptionDetails,
      notificationPreferences: notificationPreferences,
      isUnSubscribeAll: isUnSubscribeAll,
      userId: userId, // when site user has been set to inactive then unsubscribe that user from push notifications
    };

    const unsubscribeInstance = new UnSubscribePushNotification({
      params,
      credentials: getState().authentication.credentials,
    });

    unsubscribeInstance.call().then(
      (response) => {
        dispatch(unsubscribePushNotificationSuccess(response.data));
        dispatch(closeModal());
      },
      (err) => {
        dispatch(unsubscribePushNotificationFailure(err));
        dispatch(closeModal());
      },
    );
  };

export const unsubscribePushNotificationSuccess = (data) => {
  return {
    type: UNSUBSCRIBE_PUSH_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const unsubscribePushNotificationFailure = (error) => {
  return {
    type: UNSUBSCRIBE_PUSH_NOTIFICATION_FAILURE,
    payload: error,
  };
};

export const updatePushNotificationPreferences =
  (subscriptionDetails, notificationPreferences, groupBuyerSites) => (dispatch, getState) => {
    dispatch({ 
        type: UPDATE_PUSH_NOTIFICATION_PREFERENCES, 
        payload: {subscriptionDetails,notificationPreferences,groupBuyerSites,}, 
      });

    const params = {
      subscriptionDetails: subscriptionDetails,
      notificationPreferences: notificationPreferences,
      groupBuyerSites:groupBuyerSites,
    };

    const updatePushNotificationPreferenceInstance =
      new UpdatePushNotificationPreferences({
        params,
        credentials: getState().authentication.credentials,
      });

    updatePushNotificationPreferenceInstance.call().then(
      (response) => {
        dispatch(updatePushNotificationPreferencesSuccess(response.data));
      },
      (err) => {
        return dispatch(updatePushNotificationPreferencesFailure(err));
      },
    );
  };

export const updatePushNotificationPreferencesSuccess = (data) => {
  return {
    type: UPDATE_PUSH_NOTIFICATION_PREFERENCES_SUCCESS,
    payload: data,
  };
};

export const updatePushNotificationPreferencesFailure = (error) => {
  return {
    type: UPDATE_PUSH_NOTIFICATION_PREFERENCES_FAILURE,
    payload: error,
  };
};

export const getPushNotificationPreferences =
  (subscriptionDetails, notificationPreferences) => (dispatch, getState) => {
    dispatch({ type: GET_PUSH_NOTIFICATION_PREFERENCES });

    const params = {
      subscriptionDetails: subscriptionDetails,
      notificationPreferences: notificationPreferences,
    };

    const getPushNotificationPreferenceInstance =
      new GetPushNotificationPreferences({
        params,
        credentials: getState().authentication.credentials,
      });

    getPushNotificationPreferenceInstance.call().then(
      (response) => {
        const responseData =
          response.data && Object.keys(response.data).length > 0
            ? response.data
            : null;
        dispatch(getPushNotificationPreferencesSuccess(responseData));
      },
      (err) => {
        console.log(err);
        return dispatch(getPushNotificationPreferencesFailure(err));
      },
    );
  };

export const getPushNotificationPreferencesSuccess = (data) => {
  return {
    type: GET_PUSH_NOTIFICATION_PREFERENCES_SUCCESS,
    payload: data,
  };
};

export const getPushNotificationPreferencesFailure = (error) => {
  return {
    type: GET_PUSH_NOTIFICATION_PREFERENCES_FAILURE,
    payload: error,
  };
};

export const setSubscribed = (subscribed) => {
  return {
    type: SET_SUBSCRIBED,
    payload: subscribed,
  };
};

export const initialState = {
  isSubscribing: false,
  isUnSubscribing: false,
  isFetchingPreferences: false,
  isUpdating: false,
  error: false,
  data: null,
  subscriptionDetails: null,
  preferences: null,
  groupBuyerSites:null,
  isSubscribed: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIBE_PUSH_NOTIFICATION:
      return {
        ...state,
        isSubscribing: true,
        data: null,
        isFetchingPreferences:true,
      };
    case SUBSCRIBE_PUSH_NOTIFICATION_FAILURE:
      return {
        ...state,
        isSubscribing: false,
        data: null,
        isFetchingPreferences:false,
        error: action.payload,
      };
    case SUBSCRIBE_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isSubscribing: false,
        isFetchingPreferences:false,
        subscriptionDetails: action.payload?.subscriptionDetails,
        preferences: action.payload?.notificationPreferences,
        groupBuyerSites: action.payload?.groupBuyerSites,
      };
    case UNSUBSCRIBE_PUSH_NOTIFICATION:
      return {
        ...state,
        isUnSubscribing: true,
      };
    case UNSUBSCRIBE_PUSH_NOTIFICATION_FAILURE:
      return {
        ...state,
        isUnSubscribing: false,
        error: action.payload,
      };
    case UNSUBSCRIBE_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isUnSubscribing: false,
        data: action.payload,
        preferences: null,
      };
    case GET_PUSH_NOTIFICATION_PREFERENCES:
      return {
        ...state,
        isFetchingPreferences: true,
      };
    case GET_PUSH_NOTIFICATION_PREFERENCES_FAILURE:
      return {
        ...state,
        isFetchingPreferences: false,
        error: action.payload,
      };
    case GET_PUSH_NOTIFICATION_PREFERENCES_SUCCESS:
      return {
        ...state,
        isFetchingPreferences: false,
        subscriptionDetails: action.payload?.subscriptionDetails,
        preferences: action.payload?.notificationPreferences,
        groupBuyerSites: action.payload?.groupBuyerSites,
      };
    case SET_SUBSCRIBED:
      return {
        ...state,
        isSubscribed: action.payload,
      };
    case UPDATE_PUSH_NOTIFICATION_PREFERENCES:
      return {
        ...state,
        isUpdating: true,
        subscriptionDetails: action.payload?.subscriptionDetails,
        preferences: action.payload?.notificationPreferences,
        groupBuyerSites: action.payload?.groupBuyerSites,
      };
    case UPDATE_PUSH_NOTIFICATION_PREFERENCES_SUCCESS:
      return{
        ...state,
        isUpdating: false,
      };
    case UPDATE_PUSH_NOTIFICATION_PREFERENCES_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
