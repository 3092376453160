export const paragraphText = {
  HEADER_TEXT: "Request a Call Back",
  SUB_HEADER_TEXT:
    "Please specify the best time for us to contact you. We will endeavour to contact you at the time you request, however this might not be possible during very busy periods. Please note that call backs are only available Monday to Friday 9am - 5:30pm, excluding bank holidays.",
  TEL_NO: "Telephone Number",
};

export const callBackTimes = {
  AM: "AM",
  PM: "PM",
  ANYTIME: "Anytime",
};

export const errorMessages = {
  MIN_LENGTH: `Enter more than 10 characters`,
  FIELD_REQUIRED: "Required to request a callback",
};
