import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Icon from "./../Icon";

const ButtonLink = (props) => {
  const { className, qaClassName, action, name, disabled, iconData } = props;

  return (
    <button
      className={classnames(className, qaClassName)}
      onClick={action}
      disabled={disabled}
    >
      {iconData && <Icon {...iconData} />}
      <span>{name}</span>
    </button>
  );
};

ButtonLink.propTypes = {
  className: PropTypes.string,
  qaClassName: PropTypes.string,
  name: PropTypes.string,
  action: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ButtonLink;
