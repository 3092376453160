import styled from "styled-components";
import Logout from "./Logout";

const StyledLogout = styled(Logout)`
  box-sizing: border-box;
  background-color: ${props => props.theme.COLOURS.WHITE};
  border: 0.2rem solid ${props => props.theme.COLOURS.ACCENT.base};
  padding: ${props => props.theme.SPACING.MEDIUM};
  border-radius: 0.9rem;
  width: 100%;
  height: 30rem;
  display: flex;

  .icon {
    text-align: center;
  }
  .content {
    flex-basis: 100%;
    .content__title {
      ${props => props.theme.FONTS.Text["subHeader"]};
      color: ${props => props.theme.COLOURS.ACCENT.base};
      text-align: center;
    }
    .content__text {
      margin-top: ${props => props.theme.SPACING.SMALL};
      margin-bottom: ${props => props.theme.SPACING.REGULAR};
      text-align: center;
      p {
        margin: 0;
      }
    }
    .content__actions {
      text-align: center;
      button:first-child {
        margin-right: 1rem;
      }
    }
  }
`;

export default StyledLogout;
