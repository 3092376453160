import React, { Fragment } from "react";

const ParagraphText = React.forwardRef(
  ({ children, className, setDangerous }, ref) => {
    return (
      <Fragment>
        {setDangerous ? (
          <p
            ref={ref}
            className={className}
            dangerouslySetInnerHTML={{ __html: children }}
          />
        ) : (
          <p ref={ref} className={className}>
            {children}
          </p>
        )}
      </Fragment>
    );
  }
);

export default ParagraphText;
