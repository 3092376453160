import React from "react";
import propTypes from "prop-types";
import styled from "styled-components";

const TermsCheckboxLabel = props => {
  const { className, text, link = {}, action } = props;
  const hasLinkOrAction = !!(link.url || action);

  return (
    <span className={className}>
      {hasLinkOrAction ? (
        <a
          className="modal-trigger--terms-and-conditions"
          key={text}
          href={link.url && link.url}
          target={link.target && link.target}
          rel={link.rel}
          onClick={e => {
            e.stopPropagation();

            if (action) {
              e.preventDefault();
              action();
            }
          }}
        >
          {text}
        </a>
      ) : (
        text
      )}
    </span>
  );
};

TermsCheckboxLabel.propTypes = {
  className: propTypes.string,
  text: propTypes.string,
  link: propTypes.shape({
    url: propTypes.string,
    target: propTypes.oneOf(["_blank", "_self"]),
    rel: propTypes.string
  }),
  action: propTypes.func
};

const StyledTermsCheckboxLabel = styled(TermsCheckboxLabel)`
  a {
    color: ${props => props.theme.COLOURS.PRIMARY.base};
    text-decoration: underline;
  }
`;

export default StyledTermsCheckboxLabel;
