import React, { useState } from "react";

export const ClaimCategoryContext = React.createContext({});
export const ClaimSubCategoryContext = React.createContext({});
export const ClaimReasonContext = React.createContext({});
export const ClaimContactInformationContext = React.createContext({});
export const ClaimAdditionalInformationContext = React.createContext({});
export const ClaimValidationContext = React.createContext({});
export const ClaimResolutionContext = React.createContext({});
export const ClaimSupportingDocumentContext = React.createContext({});
export const ClaimSupportingLinkContext = React.createContext({});

export const ClaimCategoryContextProvider = ({ children }) => {
  const [claimCategory, setClaimCategory] = useState("");

  return (
    <ClaimCategoryContext.Provider value={{ claimCategory, setClaimCategory }}>
      {children}
    </ClaimCategoryContext.Provider>
  );
};

export const ClaimSubCategoryContextProvider = ({ children }) => {
  const [claimSubCategory, setClaimSubCategory] = useState("");

  return (
    <ClaimSubCategoryContext.Provider
      value={{ claimSubCategory, setClaimSubCategory }}
    >
      {children}
    </ClaimSubCategoryContext.Provider>
  );
};

export const ClaimReasonContextProvider = ({ children }) => {
  const [claimReason, setClaimReason] = useState("");
  return (
    <ClaimReasonContext.Provider value={{ claimReason, setClaimReason }}>
      {children}
    </ClaimReasonContext.Provider>
  );
};

export const ClaimContactInformationContextProvider = ({ children }) => {
  const [contactName, setContactName] = useState("");
  const [telNo, setTelNo] = useState("");
  const [email, setEmail] = useState("");

  return (
    <ClaimContactInformationContext.Provider
      value={{ contactName, setContactName, telNo, setTelNo, email, setEmail }}
    >
      {children}
    </ClaimContactInformationContext.Provider>
  );
};

export const ClaimAdditionalInformationContextProvider = ({ children }) => {
  const [claimAdditionalInformation, setClaimAdditionalInformation] = useState(
    []
  );
  const [validAdditionalInformation, setValidAdditionalInformation] =
    useState(false);

  return (
    <ClaimAdditionalInformationContext.Provider
      value={{
        claimAdditionalInformation,
        setClaimAdditionalInformation,
        validAdditionalInformation,
        setValidAdditionalInformation,
      }}
    >
      {children}
    </ClaimAdditionalInformationContext.Provider>
  );
};

export const ClaimResolutionContextProvider = ({ children }) => {
  const [resolutionIdCtx, setResolutionIdCtx] = useState(null);
  const [claimCostTypeCtx, setClaimCostTypeCtx] = useState("");
  const [resolutionCostLinesCtx, setResolutionCostLinesCtx] = useState([]);
  const [totalCostCtx, setTotalCostCtx] = useState(null);
  const [
    shouldCheckResolutionValidityCtx,
    setShouldCheckResolutionValidityCtx,
  ] = useState(false);
  const [resolutionIsValidCtx, setResolutionIsValidCtx] = useState(true);

  const value = {
    resolutionIdCtx,
    setResolutionIdCtx,
    claimCostTypeCtx,
    setClaimCostTypeCtx,
    resolutionCostLinesCtx,
    setResolutionCostLinesCtx,
    totalCostCtx,
    setTotalCostCtx,
    shouldCheckResolutionValidityCtx,
    setShouldCheckResolutionValidityCtx,
    resolutionIsValidCtx,
    setResolutionIsValidCtx,
  };

  return (
    <ClaimResolutionContext.Provider value={value}>
      {children}
    </ClaimResolutionContext.Provider>
  );
};

export const ClaimSupportingLinkContextProvider = ({ children }) => {
  const [supportingLinkCtx, setSupportingLinkCtx] = useState([]);
  const [validSupportingLink, setValidSupportingLink] = useState(true);
  const value = {
    supportingLinkCtx,
    setSupportingLinkCtx,
    validSupportingLink,
    setValidSupportingLink,
  };

  return (
    <ClaimSupportingLinkContext.Provider value={{supportingLinkCtx,
      setSupportingLinkCtx, validSupportingLink, setValidSupportingLink}}>
      {children}
    </ClaimSupportingLinkContext.Provider>
  );
};

export const ClaimValidationContextProvider = ({ children }) => {
  const [validContactName, setValidContactName] = useState(true);
  const [validTelNo, setValidTelNo] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validContactInformation, setValidContactInformation] = useState(true);
  const [validClaimReason, setValidClaimReason] = useState(true);
  const [validSupportingLink, setValidSupportingLink] = useState(true);

  const [hasFocus, setHasFocus] = useState(false);

  return (
    <ClaimValidationContext.Provider
      value={{
        validTelNo,
        setValidTelNo,
        validContactName,
        setValidContactName,
        validEmail,
        setValidEmail,
        validContactInformation,
        setValidContactInformation,
        hasFocus,
        setHasFocus,
        validClaimReason,
        setValidClaimReason,
        validSupportingLink,
        setValidSupportingLink,
      }}
    >
      {children}
    </ClaimValidationContext.Provider>
  );
};

export const ClaimSupportingDocumentContextProvider = ({ children }) => {
  const [validFileType, setValidFileType] = useState(true);
  const [validFileSize, setValidFileSize] = useState(true);
  const [validSupportingDocument, setValidSupportingDocument] = useState(true);

  return (
    <ClaimSupportingDocumentContext.Provider
      value={{
        validFileType,
        setValidFileType,
        validFileSize,
        setValidFileSize,
        validSupportingDocument,
        setValidSupportingDocument,
      }}
    >
      {children}
    </ClaimSupportingDocumentContext.Provider>
  );
};
