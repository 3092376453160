import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import FocusTrap from "focus-trap-react/dist/focus-trap-react";
import PropTypes from "prop-types";

const Modal = ({
  className,
  isOpen,
  modalId,
  children,
  onCloseModalClick,
  role,
  modalLabel,
  isDismissible,
  hasCloseBtn,
  modalWidth,
  modalHeight,
}) => {
  const backgroundRef = useRef(null);
  const contentRef = useRef(null);

  const handleModalClose = () => {
    const trigger = document.querySelector(`.modal-trigger--${modalId}`);
    if (trigger) {
      trigger.focus();
    }
    return onCloseModalClick();
  };

  const handleEscape = ({ keyCode }) => {
    keyCode === 27 && isDismissible && handleModalClose();
  };

  const handleBackgroundClick = (e) => {
    if (e.target === backgroundRef.current && isDismissible) {
      handleModalClose();
    }
  };

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    if (isOpen) {
      body.classList.toggle("modal--open");
    }
    return () => {
      if (isOpen) {
        body.classList.toggle("modal--open");
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && contentRef.current) {
      contentRef.current.focus();
    }
  }, [isOpen, contentRef]);

  return isOpen
    ? createPortal(
        <FocusTrap active={isOpen}>
          <div>
            <aside
              ref={backgroundRef}
              aria-modal={true}
              className={className}
              onClick={(e) => handleBackgroundClick(e)}
              tabIndex="-1"
              role={role}
              aria-label={modalLabel}
              onKeyDown={(e) => handleEscape(e)}
            >
              <div className="modal__body">
                {isDismissible && hasCloseBtn && (
                  <div className="modal__close" onClick={onCloseModalClick} />
                )}
                <div className="modal__content" ref={contentRef}>
                  <div
                    style={{
                      position: "relative",
                      width: modalWidth,
                      height: modalHeight,
                    }}
                  >
                    {children}
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </FocusTrap>,
        document.body
      )
    : null;
};

Modal.propTypes = {
  className: PropTypes.string,
  modalId: PropTypes.string,
  children: PropTypes.node,
  modalWidth: PropTypes.string,
  modalHeight: PropTypes.string,
  isDismissible: PropTypes.bool,
  hasCloseBtn: PropTypes.bool,
  onCloseModalClick: PropTypes.func,
};

Modal.defaultProps = {
  onCloseModalClick: () => {},
  role: "dialog",
  isDismissible: true,
  hasCloseBtn: false,
  modalLabel: "A modal element",
  modalWidth: "auto",
  modalHeight: "auto",
};

export default Modal;
