import Api from "./Base";
import settings from "../clientappsettings.json";

export class GetCapValuation extends Api {
  baseUrl = settings.TRADE_BUYER_URL;
  requestUrl = `/VehicleInfo/capValuation/${this.getParams().id}`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };

  formatResponseData(response) {
    if (response.data) {
      return {
        ...response,
        data: {
          vehicleId: this.getParams().id,
          capValuation: response.data.valuation,
          capValuationDate: response.data.valuationDate,
          isValuated: response.data.successful !== undefined
        }
      };
    }
    return response;
  }
}
