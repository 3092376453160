import React, { useContext } from "react";
import Dropdown from "../../Dropdown";
import { ItemContainer, ItemLabel, ItemContent } from "../index";
import { subHeaderText, subCategoryDropdown } from "../consts";
import SubHeaderText from "../../SubHeaderText";
import { ClaimSubCategoryContext } from "../../ClaimItem/context/context";

  const ClaimSubCategory = ({ subCategories, disabled }) => {
  const { claimSubCategory, setClaimSubCategory } = useContext(ClaimSubCategoryContext);

  const SubCategoryComponent = (
    <ItemContainer>
      <ItemLabel>
        <SubHeaderText>{subHeaderText.CLAIM_SUB_CATEGORY}:</SubHeaderText>
      </ItemLabel>
      <ItemContent>
        <Dropdown
          options={subCategories || []}
          selectedOption={ claimSubCategory && claimSubCategory  }
          placeholder={subCategoryDropdown.PLACEHOLDER}
          onInputChange={(e) => setClaimSubCategory(e.value)}
          disabled={disabled}
        />
      </ItemContent>
    </ItemContainer>
  );

  return subCategories?.length > 0 ? SubCategoryComponent : null;
};

export default ClaimSubCategory;
