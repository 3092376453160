import React from "react";

const SkeletonPopoverSingleSelect = props => {
  const { className } = props;

  return (
    <div className={className}>
      {[...Array(10)].map((item, index) => (
        <span key={`skeleton-popover-single-select-${index}`} />
      ))}
    </div>
  );
};

export default SkeletonPopoverSingleSelect;
