import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const ChevronDown = ({ width, height, strokeColour, strokeWidth }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 12"
    >
      <g
        stroke="none"
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          transform="translate(-1707.000000, -165.000000)"
          stroke={stroke}
          strokeWidth={strokeWidth}
        >
          <g transform="translate(1708.000000, 166.000000)">
            <polyline id="Path-4" points="0 0 8.07880597 9 16 0" />
          </g>
        </g>
      </g>
    </svg>
  );
};

ChevronDown.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

ChevronDown.defaultProps = {
  width: "18",
  height: "12",
  strokeWidth: "2"
};

export default ChevronDown;
