import useKeyboardShortcuts from "../../../shared/hooks/useKeyboardShortcuts";

const KeyboardShortcutsContainer = () => {
  useKeyboardShortcuts();

  return null;
  // This component does not render anything,
  //  its sole purpose is to add event listeners to the entire app
};

export default KeyboardShortcutsContainer;
