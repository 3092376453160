import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../../views/components/ModalInsert";
import Panel from "../../../views/components/Panel";
import CampaignAddVehicles from "../../components/CampaignManagement/CampaignAddVehicles";
import { useSelector } from "react-redux";
import { saveVehicleSelection, saveSpecificVehicleSelectionCopy } from "../../../state/reducers/campaignManagement";

const CampaignAddVehiclesModalInsert = () => {
  const dispatch = useDispatch();
  const specificVehicleSelection = useSelector(
    (state) => state.campaignManagement.activeCampaign?.specificVehicleSelection
  );
  
  const specificMakeSelection = specificVehicleSelection.makeData;
  const specificRangeSelection = specificVehicleSelection?.rangeData;
  const specificTrimSelection = specificVehicleSelection?.trimData;
  
  function deepCopy(obj) {
    if (obj === null || typeof obj !== 'object') {
      return obj; 
    }
  
    if (Array.isArray(obj)) {
      const copy = [];
      for (let i = 0; i < obj.length; i++) {
        copy[i] = deepCopy(obj[i]);
      }
      return copy;
    }
  
    const copy = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        copy[key] = deepCopy(obj[key]);
      }
    }
  
    return copy;
  }
  
  const specificVehicleSelectionCopy = useRef(deepCopy(specificVehicleSelection)).current;

  useEffect(() => {
    if (specificVehicleSelection) {
      dispatch(saveSpecificVehicleSelectionCopy(deepCopy(specificVehicleSelection)));
    }
  });

  const [selectedMakes, setSelectedMakes] = useState(
    specificVehicleSelectionCopy.makeData || []
  );
  
  const selectedMakeKeys = selectedMakes.map((selected) => selected.key);
  const selectedMakeValues = selectedMakes.map((selected) => selected.value);
  const selectedMakeValuesAsString = selectedMakeValues.join(", ");

  const specificRangesCollection =
    specificRangeSelection && specificRangeSelection[0]?.key === undefined
      ? []
      : specificVehicleSelectionCopy.rangeData;

  const [selectedRanges, setSelectedRanges] = useState(
    specificRangesCollection || []
  );

  const selectedRangeKeys = selectedRanges.map((selected) => selected.key);
  const selectedRangeValues = selectedRanges.map((selected) => selected.value);
  const selectedRangeValuesAsString = selectedRangeValues.join(", ");

  const specificTrimsCollection =
    specificTrimSelection && specificTrimSelection[0]?.key === undefined
      ? []
      : specificVehicleSelectionCopy.trimData;

  const [selectedTrims, setSelectedTrims] = useState(
    specificTrimsCollection || []
  );
  const selectedTrimKeys = selectedTrims.map((selected) => selected.key);
  const selectedTrimValues = selectedTrims.map((selected) => selected.value);
  const selectedTrimValuesAsString = selectedTrimValues.join(", ");

  const rangeSelection = selectedRanges.length === 0 ? [[]] : selectedRanges;
  const trimSelection = selectedTrims.length === 0 ? [[]] : selectedTrims;

  const vehicleSelectionData = {
    chosenMake: selectedMakes,
    chosenRange: rangeSelection,
    chosenTrim: trimSelection,
  };

  const onClickSaveSelection = () => {
    dispatch(saveVehicleSelection(vehicleSelectionData));
    dispatch(closeModal());
  };

  const onCancel = () => {
    const makeDataCopy = deepCopy(specificVehicleSelectionCopy.makeData);
    setSelectedMakes(makeDataCopy);
  
    const rangeDataCopy = deepCopy(specificVehicleSelectionCopy.rangeData);
    setSelectedRanges(rangeDataCopy);

    const trimDataCopy = deepCopy(specificVehicleSelectionCopy.trimData);
    setSelectedRanges(trimDataCopy);
  
    dispatch(closeModal());
  };
  
  const campaignStatus = useSelector(
    (state) => state.campaignManagement.activeCampaign?.data.campaignStatus
  );

  const campaignId = useSelector(
    (state) => state.campaignManagement.activeCampaign?.data.campaignId
  );

  const data = useSelector(
    (state) => state.campaignManagement.activeCampaign?.data
  );

  const hasMadeChanges =
    selectedMakes !== specificMakeSelection ||
    selectedRanges !== specificRangeSelection ||
    selectedTrims !== specificTrimSelection;

  const disableButton =
    campaignStatus === "Expired" ||
    selectedMakes.length === 0 ||
    !hasMadeChanges;

  return (
    <Fragment>
      <ModalInsert>
        <Panel
          mobileFlex
          style={{ height: "100%", borderRadius: "0.5rem", padding: "0rem" }}
          actions={[
            {
              content: "Close",
              action: onCancel,
              type: "ghost",
            },
            {
              content: "Add Vehicles",
              action: onClickSaveSelection,
              type: "primary",
              disabled: disableButton,
            },
          ]}
        >
          <CampaignAddVehicles
            data={data}
            campaignId={campaignId}
            selectedMakes={selectedMakes}
            setSelectedMakes={setSelectedMakes}
            selectedMakeKeys={selectedMakeKeys}
            selectedMakeValues={selectedMakeValues}
            selectedMakeValuesAsString={selectedMakeValuesAsString}
            selectedRanges={selectedRanges}
            setSelectedRanges={setSelectedRanges}
            selectedRangeKeys={selectedRangeKeys}
            selectedRangeValues={selectedRangeValues}
            selectedRangeValuesAsString={selectedRangeValuesAsString}
            selectedTrims={selectedTrims}
            setSelectedTrims={setSelectedTrims}
            selectedTrimKeys={selectedTrimKeys}
            seledctedTrimValues={selectedTrimValues}
            selectedTrimValuesAsString={selectedTrimValuesAsString}
            specificMakeSelection={specificMakeSelection}
            specificRangeSelection={specificRangeSelection}
            specificTrimSelection={specificTrimSelection}
          />
        </Panel>
      </ModalInsert>
    </Fragment>
  );
};

export default CampaignAddVehiclesModalInsert;
