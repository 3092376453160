import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import useIsLoggedInUserType from "./../../../shared/hooks/useIsLoggedInUserType";
import LoadingTransition from "../../components/LoadingTransition";
import { USER_TYPE_MAP } from "../../../shared/utility";
import { Home } from "../../pages";

const AdministratorRoute = ({ component: Component, ...rest }) => {
  
  const location = useLocation();
  const isLoggedInBuyer = useIsLoggedInUserType(USER_TYPE_MAP.Buyer);

  const isFetchingCredentials = useSelector(
    (state) => state.authentication.isFetching
  );

  let isAccountAdministrator = useSelector(
    (state) => state.authentication.meta.isAccountAdministrator
  );

  if (isFetchingCredentials) return <LoadingTransition />;
  if (isLoggedInBuyer && !isAccountAdministrator) return <Home />;

  return isLoggedInBuyer ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default AdministratorRoute;
