import ClaimVehicleSearchModal from "./ClaimVehicleSearchModal";
import RegistrationSearch from "./../InputSearch";
import styled from "styled-components";
import breakpoints from "./../../theme/breakpoints";
import InputError from "../BidInput/child-components/BidInputError";
import spacing from "../../theme/base/spacing";

const StyledClaimVehicleSearchModal = styled(ClaimVehicleSearchModal)`
.subheader-text__container {
  padding-top: ${spacing.MEDIUM};
  padding-bottom: 2.25rem;
}

.search__container {
  ${RegistrationSearch} {
    padding-bottom: 0;
  }
  display: flex;

  @media (max-width: ${breakpoints.TABLET}) {
    flex-direction: column;
  }
}

.search-input {
  width: 40%;

  @media (max-width: ${breakpoints.TABLET}) {
    width: 100%;
  }
}

.search-results {
  width: 60%;
  padding-left: ${spacing.SMALL};
  align-items: self-start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${breakpoints.TABLET}) {
    padding: ${spacing.SMALL} ${spacing.XSMALL};
    width: 100%;
  }
}

${InputError} {
  width: 40%;
  line-height: ${spacing.REGULAR};

  @media (max-width: ${breakpoints.TABLET}) {
    width: 100%;
  }
}

}`;

export default StyledClaimVehicleSearchModal;
