import styled from "styled-components";
import spacing from "../../../theme/base/spacing";
import VendorInformationRequestMessage from "./VendorInformationRequestMessage.jsx";

const StyledVendorInformationRequestMessage = styled(
  VendorInformationRequestMessage
)`
  .text-area__input {
    margin-top: ${spacing.SMALL};
    background-color: ${(props) => props.theme.COLOURS.WHITE};
    border-radius: 0.5rem;
    border: 0.2rem solid ${(props) => props.theme.COLOURS.PRIMARY.shades[20]};
    color: ${(props) => props.theme.COLOURS.PRIMARY.base};
    padding: ${spacing.SMALL};
    resize: none;
    ${(props) => props.theme.FONTS.Text["paragraph"]};
    width: 100%;
    height: 20rem;
  }
`;

export default StyledVendorInformationRequestMessage;
