import React, { useEffect } from "react";
import { ItemContainer, ItemLabel, ItemContent } from "../index";
import { useDispatch, useSelector } from "react-redux";
import {
  headerText,
  paragraphText,
  subHeaderText,
  errorMessages,
} from "../const";
import HeaderText from "../../../../../views/components/HeaderText";
import ParagraphText from "../../../../../views/components/ParagraphText";

import SubHeaderText from "../../../../../views/components/SubHeaderText";
import BidInputError from "../../../../../views/components/BidInput/child-components/BidInputError";

import { fetchCampaignGroups } from "../../../../../state/reducers/campaignManagement";

import InputText from "../../../../../views/components/InputText";
import Datepicker from "../../../../../views/components/Datepicker";
import Checkbox from "../../../../../views/components/Checkbox";
import Accordion from "../../../../../views/components/Accordion";
import dayjs from "dayjs";

const CampaignCriteria = ({
  campaignCriteriaProps,
  data,
  disableForm,
  campaignId,
  setApproveButtonDisabled,
}) => {
  const {
    fromDays,
    setFromDays,
    toDays,
    setToDays,
    validDate,
    validStartDate,
    setDatesVisible,
    datesVisible,
    validToDays,
    setValidToDays,
    validFromDays,
    setValidFromDays,
    validLength,
    setValidLength,
    validNumber,
    setValidNumber,
    toDate,
    setToDate,
    fromDate,
    setFromDate,
    setValidDate,
    setValidStartDate,
    setDisabledSaveButton,
    campaignBuyerGroup,
    setCampaignBuyerGroup,
    campaignBuyerGroupsData,
  } = campaignCriteriaProps;
  const dispatch = useDispatch();

  const { isFetchingCampaignGroups } = useSelector(
    (state) => state.campaignManagement.activeCampaign
  );

  useEffect(() => {
    dispatch(fetchCampaignGroups(campaignId));
  }, [campaignId, dispatch]);

  const handleFromDateChange = (date) => {
    const currentDate = dayjs();
    const formattedDate = currentDate.format("YYYY/MM/DD");

    if (dayjs(toDate) && dayjs(date) > dayjs(toDate)) {
      setValidDate(false);
      setDisabledSaveButton(true);
      return;
    } else if (date < formattedDate) {
      setValidStartDate(false);
      setDisabledSaveButton(true);
      return;
    }
    const finalFormattedDate = dayjs(date).format("YYYY/MM/DD 00:00");
    setValidDate(true);
    setValidStartDate(true);
    setDisabledSaveButton(false);
    setFromDate(finalFormattedDate);
    setApproveButtonDisabled(true);
  };

  const handleToDateChange = (date) => {
    if (dayjs(fromDate) && dayjs(date) < dayjs(fromDate)) {
      setValidDate(false);
      setDisabledSaveButton(true);
      return;
    }
    const finalFormattedDate = dayjs(date).format("YYYY/MM/DD 23:59");
    setValidDate(true);
    setDisabledSaveButton(false);
    setToDate(finalFormattedDate);
    setApproveButtonDisabled(true);
  };

  const onChangeFromDays = (e) => {
    const inputValue = e.target.value.trim();
    setFromDays(inputValue);

    if (inputValue === "") {
      setFromDays("");
      setValidFromDays(true);
      setValidToDays(true);
      setValidLength(true);
      setValidNumber(true);
      setDisabledSaveButton(false);
    } else {
      const numericValue = parseInt(inputValue, 10);

      if (
        !isNaN(numericValue) &&
        numericValue > parseInt(toDays, 10) &&
        toDays
      ) {
        setValidFromDays(false);
        setValidNumber(false);
        setDisabledSaveButton(true);
      } else {
        setValidFromDays(true);
        setValidNumber(true);
        setValidLength(true);
        setValidToDays(true);
        setDisabledSaveButton(false);
      }
    }
    setApproveButtonDisabled(true);
  };

  const onChangeToDays = (e) => {
    const inputValue = e.target.value.trim();
    setToDays(inputValue);
    if (inputValue === "") {
      setToDays("");
      setValidFromDays(true);
      setValidToDays(true);
      setValidLength(true);
      setValidNumber(true);
      setDisabledSaveButton(false);
    } else {
      const numericValue = parseInt(inputValue, 10);

      if (
        !isNaN(numericValue) &&
        numericValue < parseInt(fromDays, 10) &&
        fromDays
      ) {
        setValidToDays(false);
        setValidNumber(false);
        setDisabledSaveButton(true);
      } else {
        setValidToDays(true);
        setValidLength(true);
        setValidNumber(true);
        setValidFromDays(true);
        setDisabledSaveButton(false);
      }
    }
    setApproveButtonDisabled(true);
  };

  const onInputChange = (option) => {
    setCampaignBuyerGroup((prevSelected) => {
      const existingIndex = prevSelected.findIndex(
        (item) => item.groupName === option.groupName
      );

      if (existingIndex !== -1) {
        return prevSelected.map((item, index) => {
          if (index === existingIndex) {
            return {
              ...item,
              included: !item.included,
            };
          }
          return item;
        });
      } else {
        return [
          ...prevSelected,
          {
            groupId: option.groupId,
            groupName: option.groupName,
            included: true,
          },
        ];
      }
    });
    setApproveButtonDisabled(true);
  };

  return (
    <div className="campaignCriteria-container">
      <HeaderText className="subtitle">{headerText.TITLE_CRITERIA}</HeaderText>
      <ItemContainer>
        <ItemLabel>
          <SubHeaderText>{subHeaderText.DATE_AVAILABILITY}</SubHeaderText>
          <div style={{ width: "80%" }}>
            <ParagraphText className="info-text">
              {paragraphText.DATE_AVAILABILITY_DESCRIP}
            </ParagraphText>
          </div>
        </ItemLabel>
        <ItemContent>
          <div className="datePicker-container">
            <div className="datePicker-section">
              <ParagraphText>Between</ParagraphText>
              <Datepicker
                getDate={handleFromDateChange}
                defaultDate={data?.fromDate || ""}
                disabled={disableForm}
              />
            </div>
            <div className="datePicker-section">
              <ParagraphText>And</ParagraphText>
              <Datepicker
                getDate={handleToDateChange}
                defaultDate={data?.toDate || ""}
                disabled={disableForm}
              />
            </div>
            <Checkbox
              onInputChange={() => {
                setDatesVisible((state) => !state);
              }}
              isChecked={datesVisible}
              isDisabled={disableForm}
            >
              {paragraphText.CHECKBOX_TEXT}
            </Checkbox>
          </div>
          {!validDate && (
            <BidInputError errorMessage={errorMessages.INVALID_DATE} />
          )}
          {!validStartDate && (
            <BidInputError errorMessage={errorMessages.INVALID_START_DATE} />
          )}
        </ItemContent>
      </ItemContainer>

      <ItemContainer>
        <ItemLabel>
          <SubHeaderText>{subHeaderText.PUBLISHED_DAYS}</SubHeaderText>
          <div style={{ width: "80%" }}>
            <ParagraphText className="info-text">
              {paragraphText.PUBLISHED_DAYS_DESCRIP}
            </ParagraphText>
          </div>
        </ItemLabel>
        <ItemContent>
          <div className="date-container">
            <div className="date-container-item">
              <ParagraphText>From</ParagraphText>
              <InputText
                medium
                type="number"
                value={fromDays}
                min={0}
                max={100}
                validValue={validFromDays}
                onChange={(e) => onChangeFromDays(e)}
                disabled={disableForm}
              />
            </div>
            <div className="date-container-item">
              <ParagraphText>To</ParagraphText>
              <InputText
                medium
                type="number"
                value={toDays}
                min={0}
                max={100}
                validValue={validToDays}
                onChange={(e) => onChangeToDays(e)}
                disabled={disableForm}
              />
            </div>
          </div>
          {!validNumber && (
            <BidInputError errorMessage={errorMessages.INVALID_NUMBER} />
          )}
          {!validLength && (
            <BidInputError errorMessage={errorMessages.INVALID_NUMBER_LENGTH} />
          )}
        </ItemContent>
      </ItemContainer>

      <ItemContainer>
        <ItemLabel>
          <SubHeaderText>{subHeaderText.BUYER_GROUP}</SubHeaderText>
          <div style={{ width: "80%" }}>
            <ParagraphText className="info-text">
              {paragraphText.BUYER_GROUP_DESCRIP}
            </ParagraphText>
          </div>
        </ItemLabel>
        <ItemContent>
          <Accordion label={"Buyer Groups"}>
            <div className="campaignBuyerGroups-container">
              {!isFetchingCampaignGroups &&
              campaignBuyerGroupsData &&
              !!campaignBuyerGroupsData.length ? (
                campaignBuyerGroupsData.map((option) => (
                  <Checkbox
                    key={option.groupId}
                    count={option.count}
                    isChecked={
                      Array.isArray(campaignBuyerGroup) &&
                      campaignBuyerGroup.some(
                        (item) =>
                          item.groupName === option.groupName && item.included
                      )
                    }
                    onInputChange={() => onInputChange(option)}
                    isDisabled={disableForm}
                  >
                    {option.groupName}
                  </Checkbox>
                ))
              ) : (
                <ParagraphText>There are no options available.</ParagraphText>
              )}
            </div>
          </Accordion>
        </ItemContent>
      </ItemContainer>
    </div>
  );
};

export default CampaignCriteria;
