export const headerText = {
  MAIN_HEADER: "New Campaign",
  TITLE_DETAILS: "Campaign Details:",
  TITLE_CRITERIA: "Campaign Criteria",
  TITLE_VEHICLE: "Vehicle Criteria",
  ID: "Campaign ID:",
  STATUS: "Campaign Status:",
};

export const subHeaderText = {
  CAMPAIGN_NAME: "Campaign Name:",
  CAMPAIGN_REF: "Campaign Reference:",
  DESCRIPTION: "Campaign Description:",
  SUPPORTING_DOC: "Supporting Documents:",
  SUPPORTING_DOCUMENTS: "Supporting Documents:",
  LINKS: "Campaign Links/URLS:",

  DATE_AVAILABILITY: "Date Availability:",
  PUBLISHED_DAYS: "Published Days:",
  BUYER_GROUP: "Add Buyer Group:",
  VEHICLE_INCLUSIONS: "Vehicle Inclusions:",
  AGE: "Vehicle Age:",
  LAST_SAVED_BY: "Last Saved By - ",
  LAST_SAVED_DATE: "Last Saved Date - ",
  APPROVED_BY: "Approved By - ",
  APPROVED_DATE: "Approved Date - ",

  AGE_CRITERIA: "Age (months)",
  MILEAGE_CRITERIA: "Mileage",
  AUCTION_GRADE: "Auction Grade",
  AUCTION_CONDITION: "Condition",
  VEHICLE_SELECTION: "Vehicle Selection"
};

export const paragraphText = {
  NAME_DESCRIP:
    "Please provide a campaign name.",
  CAMPAIGN_DESCRIP:
    "Please provide a description that will outline details of the campaign. This description will be shown to trade buyers.",
  CAMPAIGN_REF_DESCRIP: "Please provide a campaign reference.",
  SUPPORTING_DOC_DESCRIP:
    "Please provide any supporting images, videos, or documents. These will be shown to trade buyers.",
  LINKS_DESCRIP:
    "Please provide any supporting links you want to display to trade buyers. The link name will be shown to the trade buyers to access the link.",

  CHECKBOX_TEXT: "Display dates to trade buyers",

  DATE_AVAILABILITY_DESCRIP:
    "Please specify the campaign's availability date range. Once the end date has passed it will no longer be visible to trade buyers. If no dates are set, then no date expiry will be set.",
  PUBLISHED_DAYS_DESCRIP:
    "Please specify the published day range availability. This will be linked to the profile days against applicable vehicles. Once the published day range has passed, this campaign will no longer be shown on those vehicles.",
  BUYER_GROUP_DESCRIP:
    "Please specify the buyer groups which are to be included. If no groups are selected, then all trade buyers will be set.",
  VEHICLE_INCLUSIONS_DESCRIP:
    "Please specify the criteria for vehicles to be included. If no criteria are selected, then all vehicles will be included in conjunction with any vehicle selections.",
VEHICLE_SELECTION_DESCRIP: "Please select which vehicles should be included. Multiple vehicle selections can be included.",
};

export const comparisonValues = {
  BETWEEN: "Between",
  AND: "And",
  To: "To",
  From: "From",
};

export const errorMessages = {
  MIN_LENGTH: "Please provide more information",
  FIELD_REQUIRED:
    "This information is required in order to submit the campaign.",
  INVALID_FILE_FORMAT: "Invalid file format selected",
  FILE_TOO_LARGE: "File too large. Max file size of {0}MB",
  DUPLICATE_FILE:
    "This file has been used on a previous claim. File has been removed.",
  INVALID_DATE: "Cannot have a start date that is after the future date",
  INVALID_START_DATE: "Cannot have a start date before the current date",
  INVALID_NUMBER_LENGTH: "Cannot exceed 100",
  INVALID_NUMBER: "Cannot have a greater from day than to day",
  INVALID_AGE: "Cannot have a greater end age than start age",
  INAVLID_MILEAGE: "Cannot have a greater ending mileage than starting mileage",
  LINK_MANDATORY: "A Supporting Document Link is required",
  VALID_LINK_REQUIRED: "Please ensure the link you have entered is valid",
  VALID_LINK_NAME: "Please ensure the link has a valid name over 5 characters",
  NAME_REQUIRED: "A Supporting Document Link requires a name"
};

export const infoMessages = {
  TEXT_AREA_PLACEHOLDER: "Minimum of 20 characters.",
};

export const buttonText = {
  SAVE: "Save & Update",
  CANCEL: "Cancel",
  APPROVE: "Approve",
};

export const status = {
  DEFAULT: "Unsubmitted",
};

export const addVehiclesTable = {
  MAKE: "Make",
  RANGE: "Range",
  TRIM: "Trim",
};
