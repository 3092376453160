import React from "react";
import Icon from "../Icon";
import IconButton from "./child-components/IconButton";
import NumberButtonsRenderer from "./child-components/NumberButtonsRenderer";

const Pagination = ({
  className,
  activePage,
  totalPages,
  callback,
  scrollToMap,
  isFetching
}) => {
  const fallbackScroll = { top: 0, left: 0 };
  const handleGoToStart = event => {
    callback(event.target.value);
    window.scrollTo(scrollToMap || fallbackScroll);
  };
  const handleGoToEnd = event => {
    callback(event.target.value);
    window.scrollTo(scrollToMap || fallbackScroll);
  };
  const handlePrevious = event => {
    callback(event.target.value);
    window.scrollTo(scrollToMap || fallbackScroll);
  };
  const handleNext = event => {
    callback(event.target.value);
    window.scrollTo(scrollToMap || fallbackScroll);
  };

  return (
    <div className={className}>
      <IconButton
        doublePrevious
        disabled={activePage === 1 || isFetching}
        value={1}
        onClick={handleGoToStart}
        title="First page"
      >
        <Icon type="chevron-left" />
        <Icon type="chevron-left" />
      </IconButton>
      <IconButton
        previous
        disabled={activePage === 1 || isFetching}
        value={activePage - 1 === 0 ? activePage : activePage - 1}
        onClick={handlePrevious}
        title="Previous page"
      >
        <Icon type="chevron-left" />
      </IconButton>
      <NumberButtonsRenderer
        activePage={activePage}
        totalPages={totalPages}
        callback={callback}
        scrollToMap={scrollToMap || fallbackScroll}
      />
      <IconButton
        next
        disabled={activePage === totalPages || isFetching}
        value={activePage + 1 > totalPages ? totalPages : activePage + 1}
        onClick={handleNext}
        title="Next page"
      >
        <Icon type="chevron-right" />
      </IconButton>
      <IconButton
        doubleNext
        disabled={activePage === totalPages || isFetching}
        value={totalPages}
        onClick={handleGoToEnd}
        title="Last page"
      >
        <Icon type="chevron-right" />
        <Icon type="chevron-right" />
      </IconButton>
    </div>
  );
};
export default Pagination;
