import React, { useRef } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import HeaderText from "../HeaderText";
import ParagraphText from "../ParagraphText";
import Panel from "../Panel";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../theme/breakpoints";
import { USER_TYPE_MAP } from "../../../shared/utility";
import { parseToken } from "./../../../shared/utility";
import Icon from "../Icon";
import clientSettings from "../../../clientappsettings.json";
import { toast, Bounce } from "react-toastify";
import { getOS, getBrowser } from "../../../shared/utility";

const AboutSession = ({ className, qaClassName, onClose }) => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  const userFullName = useSelector(
    (state) => state.authentication.meta.contactName
  );
  const userType = useSelector((state) => state.authentication.meta.userType);
  const whiteLabel = useSelector(
    (state) => state.authentication.meta.userDefaults.whiteLabelType
  );
  const jwtToken = useSelector((state) => state.authentication.credentials);

  const { UserId, iat, AccountId, InternalId } = parseToken(jwtToken);
  const loginDateTime = new Date(iat * 1000).toUTCString();
  const currentDateTimeUnix = Math.floor(Date.now() / 1000);
  const currentDateTime = new Date(currentDateTimeUnix * 1000).toUTCString();
  const cleansedMachineName = clientSettings.MACHINE_NAME.split('.')[0].slice(-5);
  const contentRef = useRef(null);
  const os = getOS().os;
  const osVersion = getOS().version;
  const browser = getBrowser().browserName;
  const browserVersion = getBrowser().version;

  const screenResolution = `${window.screen.width}x${window.screen.height}`;

  const informationList = [
    { key: "Contact Name", value: userFullName },
    { key: "User Type", value: USER_TYPE_MAP[userType] },
    { key: "User ID", value: UserId },
    { key: "Site ID", value: InternalId },
    { key: "Account ID", value: AccountId },
    { key: "Login Date/Time", value: loginDateTime },
    { key: "Current Date/Time", value: currentDateTime },
    { key: "Theme", value: whiteLabel },
    { key: "Server Name", value: cleansedMachineName },
    { key: "Operating System", value: os },
    { key: "Operating System Version", value: osVersion },
    { key: "Browser", value: browser },
    { key: "Browser Version", value: browserVersion },
    { key: "Screen Resolution", value: screenResolution },
  ];

  const structuredText = informationList
    .map(({ key, value }) => `${key}: ${value}`)
    .join("\n");

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast.success(`Copied to clipboard!`, {
          autoClose: 2000,
          transition: Bounce,
        });
      },
      (err) => {}
    );
  };

  return (
    <div className={classnames(className, qaClassName)}>
      <Panel flex="1" padding={isDesktop ? "NONE" : "REGULAR"}>
        <div className="modal-header">
          <HeaderText className="header-logo">
            About
            <div
              className="copy-campaign"
              onClick={() => copyToClipboard(structuredText)}
            >
              <Icon type="copy" width="24" height="24" strokeWidth="24" />
            </div>
          </HeaderText>
          <ParagraphText>
            This information may be requested when contacting support. Windows
            11 may show as Windows 10.
          </ParagraphText>
        </div>
      </Panel>
      <Panel flex="1" padding={isDesktop ? "NONE" : "REGULAR"}>
        <div className="about-session-content" ref={contentRef}>
          {informationList.map(({ key, value }) => (
            <div className="row" key={key}>
              <div>
                <ParagraphText>{key}</ParagraphText>
              </div>
              <div>
                <ParagraphText primary>{value}</ParagraphText>
              </div>
            </div>
          ))}
        </div>
      </Panel>
      <Panel
        flex="1"
        padding={isDesktop ? "NONE" : "REGULAR"}
        actions={[
          {
            content: "Close",
            action: onClose,
            type: "ghost",
          },
        ]}
      />
    </div>
  );
};

AboutSession.propTypes = {
  className: PropTypes.string,
  qaClassName: PropTypes.string,
  onClose: PropTypes.func,
};

export default AboutSession;
