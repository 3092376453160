import React from "react";
import styled, { css } from "styled-components";
import breakpoints from "../../../theme/breakpoints";

const IconButton = ({ className, children, value, onClick, title }) => (
  <button className={className} value={value} onClick={onClick} title={title}>
    {children}
  </button>
);

const StyledIconButton = styled(IconButton)`
  align-items: center;
  background-color: ${props => props.theme.COLOURS.PRIMARY.base};
  background-color: ${props =>
    (props.doubleNext || props.doublePrevious) &&
    props.theme.COLOURS.SECONDARY.base};
  background-color: ${props =>
    props.disabled && props.theme.COLOURS.SECONDARY.shades[20]};
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 3rem;
  justify-content: center;
  width: 3rem;

  @media (min-width: ${breakpoints.TABLET}) {
    height: 4rem;
    width: 4rem;
  }
  

  svg path, svg g {
    stroke: ${props => props.theme.COLOURS.WHITE};
  }

  &:hover {
    background-color: ${props => props.theme.COLOURS.ACCENT.base};
  }

  ${props =>
    (props.doublePrevious || props.doubleNext) &&
    css`
      height: 2.2rem;
      width: 2.2rem;

      @media (min-width: ${breakpoints.TABLET}) {
        height: 3.2rem;
        width: 3.2rem;
      }
    `}

  ${props =>
    props.doublePrevious &&
    css`
      margin-right: 0.5rem;
    `}

  ${props =>
    props.doubleNext &&
    css`
      margin-left: 0.5rem;
    `}

  ${props =>
    props.previous &&
    css`
      margin-right: 1rem;

      @media (min-width: ${breakpoints.TABLET}) {
        margin-right: 2rem;
      }
    `}

  ${props =>
    props.next &&
    css`
      margin-left: 1rem;

      @media (min-width: ${breakpoints.TABLET}) {
        margin-left: 2rem;
      }
    `}
 ${props =>
   props.disabled &&
   css`
     pointer-events: none;
   `}

  span {
    pointer-events: none;
  }

  svg {
    display: block;
    height: 1.1rem;
    pointer-events: none;
    width: 0.7rem;
    
    ${props =>
      (props.doublePrevious || props.doubleNext) &&
      css`
        height: 0.8rem;
        width: 0.5rem;

        &:first-child {
          margin-right: -0.1rem;
        }
      `};
  }
`;

export default StyledIconButton;
