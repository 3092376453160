import styled from "styled-components";
import ClaimActivityLogModalInsert from "./ClaimActivityLogModalInsert";

const StyledClaimActivityLogModalInsert = styled(ClaimActivityLogModalInsert)`

.button__wrapper {
    display: block;
    padding: 2rem 2rem 3rem 2rem;
    float: right;
    margin-right: 1.5rem;
    margin-top: 1rem;
  }
`;

export default StyledClaimActivityLogModalInsert;
