import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const QuestionCircle = ({ width, height, strokeColour, strokeWidth }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(1 1)"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="16" cy="16.001" r="15.644" />
        <path d="M16 23.111a.356.356 0 100 .711.356.356 0 000-.71h0M11.733 13.163a4.267 4.267 0 115.974 3.91A2.844 2.844 0 0016 19.682v.586" />
      </g>
    </svg>
  );
};

QuestionCircle.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

QuestionCircle.defaultProps = {
  width: "34",
  height: "34",
  strokeWidth: "2"
};

export default QuestionCircle;
