import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import {
  updateSiteUser,
  clearSiteUserToBeUpdatedData,
} from "../../../state/reducers/buyerInformation";
import { useNavigate } from "react-router";
import GenericConfirmInsert from "./../../components/GenericConfirmInsert";

const ConfirmSuspendedUserModalInsert = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwtToken = useSelector((state) => state.authentication.credentials);

  const siteUserToBeUpdated = useSelector(
    (state) => state.buyerInformation.siteUsers.siteUserToBeUpdated,
  );

  const onCancel = () => {
    dispatch(closeModal());
  };

  const onConfirm = () => {
    dispatch(
      updateSiteUser(
        {
          userId: siteUserToBeUpdated.userId,
          contactName: siteUserToBeUpdated.contactName,
          email: siteUserToBeUpdated.email,
          loginName: siteUserToBeUpdated.loginName,
          accountAdministration: siteUserToBeUpdated.accountAdministration,
          isActive: siteUserToBeUpdated.isActive,
        },
        false,
        navigate,
        jwtToken,
      ),
    );
    dispatch(closeModal());
    dispatch(clearSiteUserToBeUpdatedData());
  };

  return (
    <GenericConfirmInsert
      headerText="Are you sure?"
      bodyText="You are about to suspend this user, are you sure?"
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
};

export default ConfirmSuspendedUserModalInsert;
