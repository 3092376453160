import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const ChevronRight = ({ width, height, strokeColour, strokeWidth }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 17.5l9-8.079-9-7.921"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      />
    </svg>
  );
};

ChevronRight.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string
};

ChevronRight.defaultProps = {
  width: "12",
  height: "19",
  strokeWidth: "2"
};

export default ChevronRight;
