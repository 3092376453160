import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Icon from "../Icon";

const Button = ({
  content,
  action,
  disabled,
  url,
  qaClassName,
  className,
  external,
  download,
  compact,
}) => {
  const hasAction = action && typeof action === "function";
  return (
    <Fragment>
      {hasAction && (
        <button
          className={classnames(className, qaClassName)}
          onClick={() => action()}
          disabled={disabled}
        >
          {compact ? <Icon type="chevron-right" /> : content}
        </button>
      )}
      {!external && !hasAction && !download && (
        <Link
          className={classnames(className, qaClassName)}
          to={url || "#"}
          onClick={(e) => {
            if (disabled) {
              e.preventDefault();
            }
          }}
        >
          {compact ? <Icon type="chevron-right" /> : content}
        </Link>
      )}
      {(external || download) && !hasAction && (
        <a
          href={!disabled ? url : "/"}
          className={classnames(className, qaClassName)}
          target={external && "_blank"}
          download={download}
          rel={external && "noopener noreferrer"}
          onClick={(e) => {
            if (disabled) {
              e.preventDefault();
            }
          }}
        >
          {compact ? <Icon type="chevron-right" /> : content}
        </a>
      )}
    </Fragment>
  );
};

export default Button;
