import styled from "styled-components";
import BREAKPOINTS from "../../theme/breakpoints";

import PopoverFilter from "./PopoverFilter.jsx";

const StyledPopoverSelect = styled(PopoverFilter)`
  font-family: ${props => props.theme.FONTS.families.lato};
  flex: 1;
  margin-right: ${props => props.theme.SPACING.SMALL};
  position: relative;

  @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
    margin-bottom: ${props => props.theme.SPACING.SMALL};
    margin-right: 0;
  }

  &:last-child {
    margin-right: 0;

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
      margin-bottom: 0;
    }
  }

  .filter-label {
    display: block;
    margin-bottom: ${props => props.theme.SPACING.SMALL};
    text-transform: capitalize;
    color: ${props => props.labelColour || props.theme.COLOURS.WHITE};
    ${props => props.theme.FONTS.Text["inputLabelSemiBold"]}
    &--disabled {
      opacity: 0.6;
    }
  }

  .label__count {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.3rem;
    width: 2.3rem;
    border-radius: 50%;
    background-color: ${props => props.theme.COLOURS.ACCENT.base};
    color: ${props => props.theme.COLOURS.WHITE};
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.2rem;
    margin-right: 0.8rem;
  }
  .label__string {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 14rem;
    color: ${props => props.theme.COLOURS.PRIMARY.base};
    ${props => props.theme.FONTS.Text["paragraphCompact"]}
  }

  .label_placeholder {
    text-transform: capitalize;
  }

  .filter__trigger {
    position: relative;
    width: 100%;
    height: 6.2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 0.2rem solid ${props => props.theme.COLOURS.SECONDARY.shades[20]};
    border-radius: 0.5rem;
    background-color: ${props => props.theme.COLOURS.WHITE};
    padding-right: ${props => props.theme.SPACING.REGULAR};
    padding-left: ${props => props.theme.SPACING.REGULAR};
    text-align: left;
    cursor: pointer;
    color: ${props => props.theme.COLOURS.PRIMARY.shades[50]};
    ${props => props.theme.FONTS.Text["paragraphCompact"]}
    &:disabled {
      cursor: default;
      background: ${props => props.theme.COLOURS.SECONDARY.shades[20]};
    }
  }

  .filter__trigger-icon {
    display: block;
    top: 50%;
    position: absolute;
    right: 2rem;
    transform: translate(-50%, -50%) rotate(90deg);
    &.is-open {
      transform: translate(-50%, -50%) rotate(-90deg);
    }
  }

  .arrow {
    height: 0;
    width: 0;
    position: absolute;
    z-index: 3;
    pointer-events: none;
    &:after,
    &:before {
      bottom: 100%;
      left: 3rem;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: transparent;
      border-bottom-color: ${props => props.theme.COLOURS.WHITE};
      border-width: 0.8rem;
      margin-left: -0.8rem;
      top: -0.6rem;
    }
    &::before {
      border-color: transparent;
      border-bottom-color: ${props => props.theme.COLOURS.SECONDARY.shades[20]};
      border-width: 1rem;
      margin-left: -1rem;
      top: -1.1rem;
    }
  }

  .filter__container {
    position: absolute;
    background-color: ${props => props.theme.COLOURS.WHITE};
    border: 0.2rem solid ${props => props.theme.COLOURS.SECONDARY.shades[20]};
    border-radius: 0.5rem;
    z-index: 2;
    transform: translateY(0.7rem) ${(props) => (props.campaignCentre ? 'translateX(-20rem)' : '')};
    min-width: ${(props) => (props.campaign ? "80rem" : "19.6rem")};
    max-width: 60rem;
    height: ${(props) => (props.campaign ? "25rem" : "")};
    overflow-y: ${(props) => (props.campaign ? "auto" : "")};
    width: 60rem;
    text-align: right;
    &__close-btn {
      margin: 1.6rem;
    }
    &.odometer {
      display: flex;
      flex: 1;
      width: 100%;
    }
    &.damagePercentage {
      display: flex;
      flex: 1;
      width: 100%;
    }
  }
  &.left {
    .filter__container {
      left: auto;
    }
  }
  &.right {
    .filter__container {
      right: 0;
    }
  }
  &.center {
    .filter__container {
      transform: translateX(-50%) translateY(0.7rem);
    }
  }
  .filter__container__panel {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-align: left;
    padding: 3.8rem 1.6rem ${props => props.theme.SPACING.SMALL};

    .trimMap-container:first-of-type {
        min-width: -webkit-fill-available;
        min-width: -moz-available;
        min-width: fill-available;
        margin-bottom: 2rem;
        span {
          font-weight: bold;
        }
      }
      
      .trimMap-container {
        flex: 0  0 33%;
    }
    
    .checkbox  {
      position: relative;
      display: flex;
      flex: 0 0 ${(props) => (props.campaignFlex ? "23%" : "31%")};
      align-items: center;
      margin-bottom: ${props => props.theme.SPACING.SMALL};
      margin-right: ${props => props.theme.SPACING.SMALL};

      label {
        color: ${props => props.theme.COLOURS.PRIMARY.base};
        display: inline-block;
        cursor: pointer;
        ${props => props.theme.FONTS.Text["paragraphCompact"]}
      }
      &__input {
        border: 0.2rem solid
          ${props => props.theme.COLOURS.SECONDARY.shades[20]};
        border-radius: 0.5rem;
        background-color: ${props => props.theme.COLOURS.WHITE};
        width: 2.9rem;
        height: 2.9rem;
        display: flex;
        margin-right: ${props => props.theme.SPACING.SMALL};
        position: relative;
      }
      input {
        width: 2.5rem;
        height: 100%;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        &:checked + .checkbox__indicator {
          opacity: 1;
        }
        &[type="checkbox"] {
          visibility: visible;
        }
      }
      &__indicator {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        pointer-events: none;
        width: 2.9rem;
        height: 2.9rem;
        top: -0.2rem;
        left: -0.2rem;
        background-color: ${props => props.theme.COLOURS.PRIMARY.base};
        border-radius: 0.5rem;

        &:after {
          content: "";
          display: block;
          width: 0.4rem;
          height: 0.8rem;
          margin-bottom: 0.2rem;
          border-color: ${props => props.theme.COLOURS.WHITE};
          border-width: 0 0.2rem 0.2rem 0;
          border-style: solid;
          transform: rotate(42deg);
        }
      }
      &__label {
        margin-right: 0.5rem;
      }
      &__count {
        color: ${props => props.theme.COLOURS.PRIMARY.shades[60]};
      }
    }

    .rangeGroup {
      width: 100%;
      padding-bottom: ${props => props.theme.SPACING.REGULAR};
      margin-bottom: ${props => props.theme.SPACING.REGULAR};

      &:not(:last-of-type) {
        border-bottom: 0.1rem solid
          ${props => props.theme.COLOURS.SECONDARY.shades[20]};
      }
      .rangeGroup__heading {
        margin-bottom: 1.5rem;
      }
      .rangeGroup__checkboxes {
        position: relative;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        text-align: left;
      }
    }
  }
  .single-select__container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;

    > .selected {
      background-color: ${props => props.theme.COLOURS.PRIMARY.base};
      > div {
        font-weight: bold;
        color: ${props => props.theme.COLOURS.WHITE};
      }
    }

    > .disabled {
      pointer-events: none;
      p {
        color: ${props => props.theme.COLOURS.PRIMARY.shades[40]};
      }
    }

    > div {
      height: 4rem;
      cursor: pointer;
      display: flex;
      &:hover {
        background-color: ${props => props.theme.COLOURS.PRIMARY.base};
        > div {
          font-weight: bold;
          color: ${props => props.theme.COLOURS.WHITE};
        }
      }
      > div {
        display: flex;
        align-items: center;
        margin-left: 2rem;
        font-size: 1.6rem;
        line-height: 0.5rem;
        font-family: ${props => props.theme.FONTS.families.lato};
        color: ${props => props.theme.COLOURS.PRIMARY.base};

        &__option-count {
          margin-left: 0.5rem;
          color: ${props => props.theme.COLOURS.PRIMARY.shades[60]};
        }
      }
    }
  }
`;

export default StyledPopoverSelect;
