export const paragraphText = {
  HEADER_TEXT: "Make Offer",
  REGISTRATION: "Registration",
  TRADE_BUYER: "Trade Buyer",
  REQUESTED_RESOLUTION: "Requested Resolution",
  CASE_REFERENCE: "Case Reference",
  REQUESTED_AMOUNT: "Requested Amount",
  DAY_COUNT: "Day Count",
  PURCHASE_VALUE: "Purchase Value",
  RESIDUAL_VALUE: "Residual Value",
  CAP_VALUE: "CAP Value",
  PREVIOUS_OFFER: "Previous Offer",
  OFFER_AMOUNT: "Offer Amount",
  OFFER_SALE_CANCELLATION: "Offer Sale Cancellation",
};

export const errorMessage =
  "Offer Amount cannot be greater than the Purchase Value of the Vehicle";
