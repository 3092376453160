import { openModal } from "./global";
import { GetTariffInformation } from "../../api/TariffInformation";

// Action constants
export const FETCH_TARIFF_DETAILS = "@bid/FETCH_TARIFF_DETAILS";
export const FETCH_TARIFF_DETAILS_SUCCESS = "@bid/FETCH_TARIFF_DETAILS_SUCCESS";
export const FETCH_TARIFF_DETAILS_FAILURE = "@bid/FETCH_TARIFF_DETAILS_FAILURE";

// Action creators
export const fetchTariffDetailsSuccess = (data) => {
  return {
    type: FETCH_TARIFF_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchTariffDetailsFailure = (error) => {
  return {
    type: FETCH_TARIFF_DETAILS_FAILURE,
    payload: error,
  };
};

export const fetchTariffDetails = () => (dispatch, getState) => {
  dispatch({ type: FETCH_TARIFF_DETAILS });

  const tariffDetailsInstance = new GetTariffInformation({
    credentials: getState().authentication.credentials,
  });

  tariffDetailsInstance.call().then(
    (response) => {
      dispatch(fetchTariffDetailsSuccess(response));
    },
    (err) => {
      dispatch(fetchTariffDetailsFailure(err));
      return dispatch(openModal("generic-error"));
    }
  );
};

const initialState = {
  isFetching: false,
  error: false,
  fees: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TARIFF_DETAILS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_TARIFF_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fees: action.payload.data,
      };
    case FETCH_TARIFF_DETAILS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
