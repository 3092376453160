import { updateObject } from "../../shared/utility";
import ShortlistUpdate from "../../api/Shortlist";
import SearchApi from "./../../api/Search";

export const FETCH_SHORTLIST = "@shortlist/FETCH_SHORTLIST";
export const FETCH_SHORTLIST_SUCCESS = "@shortlist/FETCH_SHORTLIST_SUCCESS";
export const FETCH_SHORTLIST_FAILURE = "@shortlist/FETCH_SHORTLIST_FAILURE";
export const UPDATE_SHORTLIST = "@shortlist/UPDATE_SHORTLIST";
export const ADD_TO_SHORTLIST = "@shortlist/ADD_TO_SHORTLIST";
export const REMOVE_FROM_SHORTLIST = "@shortlist/REMOVE_FROM_SHORTLIST";
export const CLEAR_SHORTLIST = "@shortlist/CLEAR_SHORTLIST";
export const ADD_FROM_PAGE_LOAD = "@shortlist/ADD_FROM_PAGE_LOAD";
export const UPDATE_SHORTLIST_NOTE = "@shortlist/UPDATE_SHORTLIST_NOTE";

export const fetchShortlistSuccess = (data) => {
  return {
    type: FETCH_SHORTLIST_SUCCESS,
    payload: data,
  };
};

export const fetchShortlistFailure = () => {
  return {
    type: FETCH_SHORTLIST_FAILURE,
  };
};

export const clearShortlist = () => {
  return { type: CLEAR_SHORTLIST };
};
/**
 *
 * @returns {Function}
 */
export const fetchShortlist = () => (dispatch, getState) => {
  dispatch({ type: FETCH_SHORTLIST });

  const toolbar = getState().toolbar;
  const pageNumber = getState().pagination.pageNumber;
  const filters = getState().filter.filters;
  const { pageSize, sort, bucketType } = toolbar;
  const params = {
    filters,
    pageSize,
    sort,
    pageNumber,
    purchaseSession: bucketType,
    isShortlisting: true,
  };

  const shortlistInstance = new SearchApi({
    params,
    credentials: getState().authentication.credentials,
  });

  shortlistInstance.call().then(
    (response) => {
      const {
        results: { shortlistedVehicleIds },
      } = response;
      if (shortlistedVehicleIds) {
        dispatch(addShortlistFromPageLoad(shortlistedVehicleIds));
      }
      dispatch(fetchShortlistSuccess(response));
    },
    (err) => {
      return dispatch(fetchShortlistFailure(err));
    }
  );
};

export const addToShortlist = (vehicleId) => {
  return {
    type: ADD_TO_SHORTLIST,
    payload: vehicleId,
  };
};

export const removeFromShortlist = (vehicleId) => {
  return {
    type: REMOVE_FROM_SHORTLIST,
    payload: vehicleId,
  };
};

export const updateShortlistShortlist = (vehicleId) => (dispatch, getState) => {
  // TODO: why does this action not get a payload on the shortlist page?
  dispatch({ type: UPDATE_SHORTLIST, payload: vehicleId });
  const state = getState();
  const isShortlisted =
    state.shortlist.results.shortlistedVehicleIds.indexOf(vehicleId) > -1
      ? true
      : false;
  const params = {
    vehicleId,
    shortlist: !isShortlisted,
  };
  const shortlistInstance = new ShortlistUpdate({
    params,
    credentials: getState().authentication.credentials,
  });
  const toggleShortlist = isShortlisted ? removeFromShortlist : addToShortlist;

  shortlistInstance.call().then(
    (response) => dispatch(toggleShortlist(vehicleId)),
    // TODO: Error handling
    (err) => console.log(err)
  );
};

export const addShortlistFromPageLoad = (vehicleIds) => {
  return {
    type: ADD_FROM_PAGE_LOAD,
    payload: vehicleIds,
  };
};

export const updateShortlistNote = (vehicleId, note) => {
  const payloadData = { vehicleId, note };
  return {
    type: UPDATE_SHORTLIST_NOTE,
    payload: payloadData,
  };
};

export const initialState = {
  isFetching: false,
  fetchingId: null,
  error: false,
  results: {
    byId: {},
    shortlistedVehicleIds: [],
  },
  pageNumber: 1,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SHORTLIST:
      return updateObject(state, { isFetching: true });
    case FETCH_SHORTLIST_SUCCESS:
      return updateObject(state, {
        isFetching: false,
        totalCount: action.payload.totalCount,
        pageSize: action.payload.pageSize,
        pageCount: action.payload.pageCount,
        results: action.payload.results,
      });
    case FETCH_SHORTLIST_FAILURE:
      return updateObject(state, {
        isFetching: false,
        results: {
          byId: {},
          shortlistedVehicleIds: [],
        },
        error: true,
      });
    case UPDATE_SHORTLIST:
      return updateObject(state, {
        ...state,
        isFetching: true,
        fetchingId: action.payload,
      });
    case ADD_TO_SHORTLIST:
      return updateObject(state, {
        isFetching: false,
        results: {
          ...state.results,
          shortlistedVehicleIds: [
            ...state.results.shortlistedVehicleIds,
            action.payload,
          ],
        },
      });
    case CLEAR_SHORTLIST:
      return {
        ...initialState,
      };
    case REMOVE_FROM_SHORTLIST:
      const newState = updateObject(state, {
        isFetching: false,
        totalCount: state.totalCount - 1,
        results: {
          ...state.results,
          shortlistedVehicleIds: state.results.shortlistedVehicleIds.filter(
            (vehicle) => vehicle !== action.payload
          ),
        },
      });

      delete newState.results.byId[action.payload];

      return newState;
    case ADD_FROM_PAGE_LOAD:
      return {
        ...state,
        results: {
          ...state.results,
          shortlistedVehicleIds: action.payload,
        },
      };
    case UPDATE_SHORTLIST_NOTE:
      return {
        ...state,
        results: {
          ...state.results,
          byId: {
            ...state.results.byId,
            [action.payload.vehicleId]: {
              ...state.results.byId[action.payload.vehicleId],
              details: {
                ...state.results.byId[action.payload.vehicleId].details,
                buyerNotes: action.payload.note,
              },
            },
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
