import React from "react";
import propTypes from "prop-types";
import PanelActions from "./child-components/PanelActions";
import PanelNotification from "./child-components/PanelNotification";

const Panel = ({ className, style, children, actions = [], notification }) => (
  <div className={className} style={style}>
    <div className="panel__wrapper">
      <div className="panel__content">{children}</div>
      {actions.length > 0 && <PanelActions actions={actions} />}
      {notification && <PanelNotification {...notification} />}
    </div>
  </div>
);

Panel.propTypes = {
  children: propTypes.node,
  className: propTypes.string,
  style: propTypes.object,
  flex: propTypes.oneOfType([propTypes.string, propTypes.number]),
  flexTablet: propTypes.oneOfType([propTypes.string, propTypes.number]),
  notification: propTypes.object,
  padding: propTypes.oneOf(["NONE", "SMALL", "REGULAR", "LARGE", "XLARGE"]),
  type: propTypes.oneOf(["grey"])
};

export default Panel;
