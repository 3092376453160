import React, { Fragment, useRef } from "react";
import PropTypes from "prop-types";
import SubHeaderText from "../SubHeaderText";
import ParagraphText from "../ParagraphText";
import { removeStringFromStart } from "../../../shared/utility";
import RegistrationPlate from "../RegistrationPlate";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../theme/breakpoints";
import Tooltip from "./../Tooltip";
import useTextOverflow from "./../../../shared/hooks/useTextOverflow";

const VehicleCardTitles = ({
  className,
  vehicleId,
  make,
  range,
  derivative,
  model,
  regNo,
  hasTitleLink,
  onTitleClick,
  tooltipOptions
}) => {
  const isPhoneOrTablet = useMediaQuery({
    query: `(max-width: ${breakpoints.LARGE_DESKTOP})`
  });

  const vehicleTitle = `${make || ""} ${range || ""}`;
  const vehicleSubtitle =
    `${removeStringFromStart(model, range)} ${derivative}` || "";

  const titleRef = useRef();
  const subtitleRef = useRef();
  const isTitleOverflowing = useTextOverflow(titleRef);
  const isSubtitleOverflowing = useTextOverflow(subtitleRef);

  const content = (
    <Fragment>
      <div className="vehicle-card-titles__main">
        <div className="vehicle-card-titles__main__title">
          <Tooltip
            content={vehicleTitle}
            show={isTitleOverflowing}
            {...tooltipOptions.title}
          >
            <SubHeaderText
              className="vehicle-card-titles__main__title__text"
              small={isPhoneOrTablet}
              ref={titleRef}
            >
              {vehicleTitle}
            </SubHeaderText>
          </Tooltip>
        </div>
        {regNo && !isPhoneOrTablet && (
          <RegistrationPlate registrationNumber={regNo} />
        )}
      </div>
      <div className="vehicle-card-titles__subtitle">
        <Tooltip
          content={vehicleSubtitle}
          show={isSubtitleOverflowing}
          {...tooltipOptions.subtitle}
        >
          <ParagraphText
            className="vehicle-card-titles__subtitle__text"
            small={isPhoneOrTablet}
            ref={subtitleRef}
          >
            {vehicleSubtitle}
          </ParagraphText>
        </Tooltip>
      </div>
    </Fragment>
  );
  return (
    <div className={className}>
      {hasTitleLink && vehicleId && onTitleClick ? (
        <div
          className="vehicle-card-titles__link"
          onClick={() => onTitleClick(vehicleId)}
        >
          {content}
        </div>
      ) : (
        content
      )}
    </div>
  );
};

VehicleCardTitles.defaultProps = {
  tooltipOptions: {
    title: {
      direction: "top",
      tooltipMaxWidth: 400
    },
    subtitle: {
      direction: "bottom",
      tooltipMaxWidth: 400
    }
  }
};

VehicleCardTitles.propTypes = {
  className: PropTypes.string,
  vehicleId: PropTypes.number,
  make: PropTypes.string,
  range: PropTypes.string,
  derivative: PropTypes.string,
  model: PropTypes.string,
  hasTitleLink: PropTypes.bool,
  regNo: PropTypes.string,
  tooltipDirection: PropTypes.shape({
    title: PropTypes.shape({
      direction: PropTypes.string,
      tooltipMaxWidth: PropTypes.number
    }),
    subtitle: PropTypes.shape({
      direction: PropTypes.string,
      tooltipMaxWidth: PropTypes.number
    })
  })
};

export default VehicleCardTitles;
