import COLOURS from "./colours";
import FONTS from "./fonts";
import SPACING from "../base/spacing";
import ANIMATIONS from "./animations";
import IMAGES from "./images";

export const Audi = {
  COLOURS,
  FONTS,
  SPACING,
  ANIMATIONS,
  IMAGES,
  title: "Audi Dealerlink",
  name: "Audi"
};
