import styled from "styled-components";
import ClaimActivityLog from "./ClaimActivityLog";
import spacing from "../../theme/base/spacing";

const StyledClaimActivityLog = styled(ClaimActivityLog)`
  border-spacing: 0;
  margin-bottom: 0;

  .main-text {
    display: flex;
    justify-content: space-between;
  }

  .responseHeaderContainer{
    display: flex;
    justify-content: space-between;
  }

  .iconAddNoteContainer {
    cursor: pointer;
  }

  .sub-text {
    margin-top: ${spacing.SMALL};
    margin-bottom: ${spacing.SMALL};
  }

  .case-ref {
    margin: ${spacing.SMALL} ${spacing.SMALL} 0 0;
  }

  .activity-log_reference-container {
    padding-top: ${spacing.SMALL};
  }

  table {
    width: 100%;
    table-layout: fixed;
  }

  th {
    padding: ${spacing.REGULAR};
    background-color: ${(props) => props.theme.COLOURS.PRIMARY.base};
    margin-left: 0;
    padding-left: 2.5rem;
    text-align: left;
  }

  .table-headings {
    color: ${(props) => props.theme.COLOURS.WHITE};
  }

  th.header-date,
  th.header-status,
  th.header-user,
  td.row-date,
  td.row-status,
  td.row-user {
    width: 15%;
  }

  th:nth-child(1) {
    border-top-left-radius: 1rem;
  }

  th:nth-child(4) {
    border-top-right-radius: 1rem;
  }
 
  tbody:nth-child(odd) {
    background-color: ${(props) => props.theme.COLOURS.PRIMARY.shades[10]};
  }

  td {
    border: hidden;
    padding: ${spacing.REGULAR} ${spacing.SMALL} ${spacing.REGULAR} ${spacing.REGULAR};
  }

  .activity-log-content {
    max-height: 38rem;
    overflow: auto;
    overflow-x: hidden;
  }

  .row-response {
    margin-right: 2.5rem;
  }

  .row-response-message {
    color: ${(props) => props.theme.COLOURS.PRIMARY.base};
    font-weight: bold;
  }

  .row-response-notes {
    color: ${(props) => props.theme.COLOURS.RED.base};
    font-weight: bold;
    overflow-wrap: break-word;
  }
`;

export default StyledClaimActivityLog;
