import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "./../../../views/components/ModalInsert";
import breakpoints from "./../../../views/theme/breakpoints";
import { useMediaQuery } from "react-responsive";

import GenericConfirmInsert from "./../../../views/components/GenericConfirmInsert";
import { completeClaim } from "../../../state/reducers/claimsVendor";

const ClaimCompleteModalInsert = () => {
  const dispatch = useDispatch();
  const activeClaimId = useSelector(
    (state) => state.global.claims.activeClaimId
  );

  const onCompleteClaim = () => {
    dispatch(completeClaim(activeClaimId));
    dispatch(closeModal());
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  return (
    <Fragment>
      <ModalInsert width={isDesktop ? "55rem" : "100%"}>
        <GenericConfirmInsert
          headerText="Are you sure?"
          bodyText="You are about to complete this claim, are you sure?"
          onCancel={onCancel}
          onConfirm={onCompleteClaim}
        />
      </ModalInsert>
    </Fragment>
  );
};

export default ClaimCompleteModalInsert;
