import clientSettings from "../clientappsettings.json";
import Api from "./Base";

export class ForgottenUsernameApi extends Api {
  baseUrl = clientSettings.USERS_URL;
  requestUrl = `/User/forgottenUsername`;
  method = `POST`;
}

export class ForgottenPasswordApi extends ForgottenUsernameApi {
  requestUrl = `/User/forgottenPassword`;
}

export class ResetPasswordApi extends ForgottenUsernameApi {
  requestUrl = `/User/resetPassword`;
}
