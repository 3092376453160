import styled, { css } from "styled-components";
import VehicleCardKeyInfo from "./VehicleCardKeyInfo";
import breakpoints from "../../theme/breakpoints";

export const StyledInfoBlock = styled(VehicleCardKeyInfo)`
  display: flex;
  justify-content: ${props => props.column && "space-between"};

  > div {
    width: 50%;

    ${props =>
      props.column &&
      css`
        width: calc(50% - 0.5rem);
      `}
  }

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    flex-wrap: wrap;

    > div {
      max-width: ${props => (props.column ? "50%" : "100%")};
      width: auto;

      ${props =>
        props.column &&
        css`
          width: auto;
        `}
    }
  }

  .key-information {
    label {
      @media (max-width: ${breakpoints.SMALL_DESKTOP}) {
        margin-bottom: 0.5rem;
      }
    }
  }
`;

export default StyledInfoBlock;
