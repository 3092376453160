import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Pill from "../Pill";
import { hasValueString } from "../../../shared/utility";
import { Link } from "react-router-dom";
import {
  isOdometerTypeValid,
  getFormattedOdometer
} from "../../../shared/utility";
const dayjs = require("dayjs");

const VehiclePillDetails = ({
  vehicleId,
  dateOfRegistration,
  odometer,
  odometerType,
  fuelType,
  transmission,
  colour,
  className,
  trimPills,
  pillContentLimit,
  hasLink,
  testingHook,
  showRemainingCount
}) => {
  const dateOfRegistrationContent =
    dateOfRegistration && dayjs(dateOfRegistration).format("MMM YYYY");
  const odometerContent =
    (odometer || odometer === 0) &&
    isOdometerTypeValid(odometerType) &&
    getFormattedOdometer(odometer, odometerType);
  const pillsArray = [
    dateOfRegistrationContent,
    odometerContent,
    fuelType,
    transmission,
    colour
  ];

  const getPillsContentSum = targetArray =>
    targetArray.reduce((acc, curr) => {
      if (typeof curr === "string" && curr.length > 0) {
        return acc + curr.length;
      }
      return acc;
    }, 0);

  const getPillsTrimmedArray = (sourceArray, limit) =>
    sourceArray.reduce((acc, curr) => {
      if (curr && getPillsContentSum(acc) + curr.length < limit) {
        return [...acc, curr];
      }
      return acc;
    }, []);

  const pillsTrimmed =
    getPillsContentSum(pillsArray) > pillContentLimit &&
    getPillsTrimmedArray(pillsArray, pillContentLimit);

  const remainingPills = pillsTrimmed
    ? pillsArray.length -
      getPillsTrimmedArray(pillsArray, pillContentLimit).length
    : 0;

  return (
    <div className={className}>
      {trimPills && pillsTrimmed ? (
        <Fragment>
          {pillsTrimmed.map((pillTrimmed, index) => (
            <Pill key={index} light content={pillTrimmed} />
          ))}
          {hasLink ? (
            <Link className="more" to={`/vehicle/${vehicleId}`}>
              {showRemainingCount ? (
                `+ ${remainingPills}`
              ) : (
                <Fragment>
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                </Fragment>
              )}
            </Link>
          ) : (
            <span className="more">
              {showRemainingCount ? (
                `+ ${remainingPills}`
              ) : (
                <Fragment>
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                </Fragment>
              )}
            </span>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {dateOfRegistration && (
            <Pill
              light
              content={dayjs(dateOfRegistration).format("MMM YYYY")}
              className={`${testingHook}__year`}
            />
          )}
          {(odometer || odometer === 0) &&
            isOdometerTypeValid(odometerType) && (
              <Pill
                light
                content={getFormattedOdometer(odometer, odometerType)}
                className={`${testingHook}__odometer`}
              />
            )}
          {hasValueString(fuelType) && (
            <Pill
              light
              content={fuelType}
              className={`${testingHook}__fuel-type`}
            />
          )}
          {hasValueString(transmission) && (
            <Pill
              light
              content={transmission}
              className={`${testingHook}__transmission`}
            />
          )}
          {hasValueString(colour) && (
            <Pill light content={colour} className={`${testingHook}__colour`} />
          )}
        </Fragment>
      )}
    </div>
  );
};

VehiclePillDetails.propTypes = {
  vehicleId: PropTypes.number,
  dateOfRegistration: PropTypes.string,
  odometer: PropTypes.number,
  odometerType: PropTypes.string,
  fuelType: PropTypes.string,
  transmission: PropTypes.string,
  colour: PropTypes.string,
  pillContentLimit: PropTypes.number,
  trimPills: PropTypes.bool,
  hasLink: PropTypes.bool,
  className: PropTypes.string
};

VehiclePillDetails.defaultProps = {
  pillContentLimit: 60,
  trimPills: false,
  hasLink: false
};

export default VehiclePillDetails;
