export const headerText = {
  CLAIM_DETAILS: "Claim Details",
  CASE_REFERENCE: "Case Reference",
  STATUS: "Status",
};

export const subHeaderText = {
  VEHICLE: "Vehicle",
  VEHICLE_CURRENT_LOCATION: "Vehicle Current Location",
  CLAIM_CATEGORY: "Claim Category",
  REASON_FOR_CLAIM: "Reason For Claim",
  CONTACT_INFORMATION: "Contact Information",
  ADDITIONAL_INFORMATION: "Additional Information",
  CLAIM_SUB_CATEGORY: "Claim Sub-Category",
  RESOLUTION: "Proposed Claim Resolution",
  SUPPORTING_DOCUMENTS: "Supporting Documents",
  SUPPORTING_DOCUMENT_LINKS: "Supporting Document Links:",
};

export const paragraphText = {
  VEHICLE: "Please specify where the vehicle is currently located.",
  REASON_FOR_CLAIM:
    "Please give as much detail as possible as to the reason for the claim, you will also need to provide supporting pictures/documents where applicable.",
  CONTACT_INFORMATION:
    "This contact information will be used for any ongoing communication regarding this claim.",
  CONTACT_NAME: "Contact Name",
  CONTACT_TELEPHONE_NUMBER: "Telephone Number",
  CONTACT_EMAIL_ADDRESS: "Email Address",
  RESOLUTION: "Please select how would you like this claim resolving.",
  SUPPORTING_DOCUMENTS:
    "Please provide any Images, Videos or Documents as evidence for your claim.",
  SUPPORTING_DOCUMENT_LINKS: "Please provide any supporting links.",
};

export const errorMessages = {
  MIN_LENGTH: "Please provide more information",
  FIELD_REQUIRED: "This information is required in order to submit the claim.",
  CONTACT_FIELD_REQUIRED: "Contact Name required to Submit Claim",
  TEL_FIELD_REQUIRED: "Telephone Number required to Submit Claim",
  EMAIL_FIELD_REQUIRED: "Email Address required to Submit Claim",
  MOBILE_FIELD_REQUIRED: "Required to Submit Claim",
  INCORRECT_FORMAT: "Incorrect format",
  RESOLUTION_REQUIRED_FIELD: "Please fill out the mandatory field",
  SELECT_VALID_CATEGORY: "Select a valid category",
  ALL_CATEGORIES_EXPIRED: "All claim categories have expired",
  INVALID_FILE_FORMAT: "Invalid file format selected",
  FILE_TOO_LARGE: "File too large. Max file size of {0}MB",
  DUPLICATE_FILE:
    "This file has been used on a previous claim. File has been removed.",
  LINK_MANDATORY: "A Supporting Document Link is required",
  VALID_LINK_REQUIRED: "Please ensure the link you have entered is valid",
};

export const infoMessages = {
  TEXT_AREA_PLACEHOLDER: "Minimum of 20 characters.",
};

export const categoryDropdown = {
  PLACEHOLDER: "Please select a category",
  NO_RESULTS_TEXT: "There are no categories added",
};

export const subCategoryDropdown = {
  PLACEHOLDER: "Please select a sub-category",
};

export const resolutionDropdown = {
  PLACEHOLDER: "Please select a proposal",
};

export const resolutionHeaders = {
  COST_DESCRIPTION: "Cost Description",
  LABOUR_COST: "Labour Cost",
  PARTS_COST: "Parts Cost",
  LINE_TOTAL: "Line Total",
  TOTAL_NET_COST: "Total Net Cost",
  PROPOSED_AMOUNT: "Proposed Amount",
};

export const resolutionCostType = {
  COST_BREAKDOWN: "CostBreakdown",
  TOTAL_COST: "TotalCost",
  NO_COST: "NoCost",
};

export const activityButtonText = {
  PLACEHOLDER: "Claim Activity Log",
};
