import React, { useState } from "react";

export const CampaignDetailsContext = React.createContext({});
export const CampaignDetailsValidationContext = React.createContext({});

export const CampaignDetailsContextProvider = ({ children }) => {
  const [campaignName, setCampaignName] = useState("");
  const [campaignReference, setCampaignReference] = useState("");
  const [campaignDescription, setCampaignDescription] = useState("");
  const [toDays, setToDays] = useState("");
  const [supportingLinkCtx, setSupportingLinkCtx] = useState([]);
  return (
    <CampaignDetailsContext.Provider
      value={{
        campaignName,
        setCampaignName,
        campaignReference,
        setCampaignReference,
        campaignDescription,
        setCampaignDescription,
        toDays,
        setToDays,
        supportingLinkCtx,
        setSupportingLinkCtx,
      }}
    >
      {children}
    </CampaignDetailsContext.Provider>
  );
};

export const CampaignDetailsValidationContextProvider = ({ children }) => {
  const [validName, setValidName] = useState(true);
  const [validReference, setValidReference] = useState(true);
  const [validDescription, setValidDescription] = useState(true);
  const [validSupportingLink, setValidSupportingLink] = useState(true);
  const [validUrlName, setValidUrlName] = useState(true);


  const [hasFocus, setHasFocus] = useState(false);

  return (
    <CampaignDetailsValidationContext.Provider
      value={{
        validName,
        setValidName,
        validReference,
        setValidReference,
        validDescription,
        setValidDescription,
        validSupportingLink,
        setValidSupportingLink,
        validUrlName,
        setValidUrlName,
        hasFocus,
        setHasFocus,
      }}
    >
      {children}
    </CampaignDetailsValidationContext.Provider>
  );
};
