import {
  parseToken,
  updateObject,
  USER_TYPE_MAP,
  VENDOR_LANDING_PAGE_TYPE_MAP,
} from "../../shared/utility";
import AuthenticationApi, {
  UserDefaultsApi,
  TermsAndConditionsApi,
  GetAccountAdministrationStatus,
} from "../../api/Authentication";
import { ActivateCurrentNotificationUser } from "../../api/NotificationPreference";
import { setSubscribed } from "./notificationPreference";

export const AUTHENTICATION_REQUEST = "@auth/AUTHENTICATION_REQUEST";
export const AUTHENTICATION_REQUEST_SUCCESS =
  "@auth/AUTHENTICATION_REQUEST_SUCCESS";
export const AUTHENTICATION_REQUEST_FAILURE =
  "@auth/AUTHENTICATION_REQUEST_FAILURE";
export const CLEAR_AUTHENTICATION = "@auth/CLEAR_AUTHENTICATION";
export const SET_THEME_VALUE = "@auth/SET_THEME_VALUE";
export const SET_THEME_OPTIONS = "@auth/SET_THEME_OPTIONS";
export const SET_GROUP_BUYER_STATUS = "@auth/SET_GROUP_BUYER_STATUS";
export const SET_USER_TYPE = "@auth/SET_USER_TYPE";
export const SET_CONTACT_NAME = "@auth/SET_CONTACT_NAME";
export const SET_USER_ROLE_NAME = "@auth/SET_USER_ROLE_NAME";
export const SET_VENDOR_LANDING_PAGE = "@auth/SET_VENDOR_LANDING_PAGE";
export const SET_AUTHENTICATION_FROM_URL =
  "@auth/SET_AUTHENTICATICATION_FROM_URL";
export const FETCH_USER_DEFAULTS = "@auth/FETCH_USER_DEFAULTS";
export const FETCH_USER_DEFAULTS_SUCCESS = "@auth/FETCH_USER_DEFAULTS_SUCCESS";
export const FETCH_USER_DEFAULTS_FAILURE = "@auth/FETCH_USER_DEFAULTS_FAILURE";
export const FETCH_USER_TANDCS_STATUS = "@auth/FETCH_USER_TANDCS_STATUS";
export const FETCH_USER_TANDCS_STATUS_SUCCESS =
  "@auth/FETCH_USER_TANDCS_STATUS_SUCCESS";
export const FETCH_USER_TANDCS_STATUS_FAILURE =
  "@auth/FETCH_USER_TANDCS_STATUS_FAILURE";
export const FETCH_ACCOUNT_ADMINISTRATOR_STATUS =
  "@auth/FETCH_ACCOUNT_ADMINISTRATOR_STATUS";
export const FETCH_ACCOUNT_ADMINISTRATOR_STATUS_SUCCESS =
  "@auth/FETCH_ACCOUNT_ADMINISTRATOR_STATUS_SUCCESS";
export const FETCH_ACCOUNT_ADMINISTRATOR_STATUS_FAILURE =
  "@auth/FETCH_ACCOUNT_ADMINISTRATOR_STATUS_FAILURE";
export const SET_REDIRECT_URL = "@auth/SET_REDIRECT_URL";

/**
 * @param {string} theme
 */
export const setThemeValue = (theme) => {
  return {
    type: SET_THEME_VALUE,
    payload: theme,
  };
};

/**
 * @param {Object} themeOptions
 */
export const setThemeOptions = (themeOptions) => {
  return {
    type: SET_THEME_OPTIONS,
    payload: themeOptions,
  };
};

/**
 *
 * @param {Boolean} isGroupBuyer
 * @returns {{payload: Boolean, type: string}}
 */
export const setGroupBuyerStatus = (isGroupBuyer) => {
  return {
    type: SET_GROUP_BUYER_STATUS,
    payload: isGroupBuyer,
  };
};

export const setRedirectUrl = (redirectUrl) => {
  return {
    type: SET_REDIRECT_URL,
    payload: redirectUrl,
  }
}

export const setUserType = (userType) => {
  return {
    type: SET_USER_TYPE,
    payload: userType,
  };
};

export const setContactName = (contactName) => {
  return {
    type: SET_CONTACT_NAME,
    payload: contactName,
  };
};

export const setUserRole = (userRole) => {
  return {
    type: SET_USER_ROLE_NAME,
    payload: userRole,
  };
};

export const setVendorLandingPage = (vendorLandingPage) => {
  return {
    type: SET_VENDOR_LANDING_PAGE,
    payload: vendorLandingPage,
  };
};

export const setSiteMeta = (metaObject) => (dispatch) => {
  const {
    theme,
    isGroupBuyer,
    userType,
    contactName,
    userRole,
    vendorLandingPage,
    ...rest
  } = metaObject;
  if (theme) {
    dispatch(setThemeValue(theme));
  }
  dispatch(setGroupBuyerStatus(isGroupBuyer));
  dispatch(setUserType(userType));
  dispatch(setThemeOptions(rest));
  dispatch(setContactName(contactName));
  dispatch(setUserRole(userRole));
  dispatch(setVendorLandingPage(vendorLandingPage));
};

export const clearAuthentication = () => {
  return {
    type: CLEAR_AUTHENTICATION,
  };
};

/**
 * We are naming this thunk in order to whitelist it
 * in the credential check
 * @param resultObject
 * @returns {function(*)} "authenticationSuccess"
 */
export const authenticationRequestSuccess =
  (resultObject) => async (dispatch) => {
    /**
     * TODO: flags related to active filters can also be destructured here.
     */
    const {
      "1link.whiteLabel": theme,
      "1link.auctionGrade": showAuctionGrade,
      "1link.damagePercentage": showDamagePercentage,
      "1link.vendorsFilter": showVendor,
      "1link.vendorsLogo": showVendorLogo,
      "1link.salesBrochure": showSalesBrochure,
      "1link.showroomMode": showShowroomMode,
      GroupCode,
      UserType,
      ContactName,
      UserRole,
      VendorLandingPage,
    } = parseToken(resultObject.data);

    if (UserType === USER_TYPE_MAP.Buyer) {
      await dispatch(fetchUserDefaults(resultObject.data));
      await dispatch(fetchUserTandCsStatus(resultObject.data));
      await dispatch(fetchAccountAdministratorStatus(resultObject.data));
    }

    await dispatch(
      setSiteMeta({
        theme,
        showAuctionGrade,
        showDamagePercentage,
        showVendor,
        showVendorLogo,
        showSalesBrochure,
        showShowroomMode,
        isGroupBuyer: !!GroupCode,
        userType: UserType,
        contactName: ContactName,
        userRole: UserRole,
        vendorLandingPage: VendorLandingPage,
      }),
    );
    await dispatch({
      type: AUTHENTICATION_REQUEST_SUCCESS,
      payload: resultObject,
    });

    if (UserType === USER_TYPE_MAP.Buyer) {
      dispatch(activateCurrentNotifUserOnLogin());
    }

    return;
  };

export const authenticationRequestFailure = (error) => {
  return {
    type: AUTHENTICATION_REQUEST_FAILURE,
    payload: error,
  };
};

/**
 * We are naming this thunk in order to whitelist it
 * in the credential check
 * @param credentials
 * @param navigate
 * @returns {function(*)} "authenticationRequest"
 */
export const makeAuthenticationRequest =
  (credentials, navigate) => (dispatch, getState) => {


    dispatch(clearAuthentication());
    dispatch({
      type: AUTHENTICATION_REQUEST,
    });
    const authenticationInstance = new AuthenticationApi({
      params: { credentials },
    });

    return authenticationInstance.call().then(
      (result) => {
        return dispatch(authenticationRequestSuccess(result)).then(() =>
          redirectUserOnLogin(
            dispatch,
            navigate,
            getState().authentication.redirectUrl,
            getState().authentication.meta.userType,
            getState().authentication.meta.userTandCsStatus === "Y",
            getState().authentication.meta.contactName,
            getState().authentication.meta.userRole,
            getState().authentication.meta.vendorLandingPage,
          ),
        );
      },
      (err) => {
        return dispatch(authenticationRequestFailure(err));
      },
    );
  };

const redirectUserOnLogin = (
  dispatch,
  navigate,
  redirectUrl,
  userType,
  hasAcceptedTandCs,
  contactName,
  userRole,
  vendorLandingPage,
) => {
  let destinationUrl;

  if (userType === USER_TYPE_MAP.Buyer) {
    if (hasAcceptedTandCs && !redirectUrl) {
      destinationUrl = "/";
    } else if (!hasAcceptedTandCs) {
      destinationUrl = "/terms-and-conditions-updates";
    } else {
      destinationUrl = redirectUrl;
    }
  } else {
    if (redirectUrl) {
      destinationUrl = redirectUrl;
    } else if (userType === USER_TYPE_MAP.ProductSupport) {
      destinationUrl = "/product-support";
    } else if (vendorLandingPage === VENDOR_LANDING_PAGE_TYPE_MAP.CampaignManagement) {
      destinationUrl = "/vendor/campaign-management";
    } else {
      destinationUrl = "/vendor/claims";
    }
  }
  if (redirectUrl?.includes("reset-password")) {
    destinationUrl = "/";
  }
  dispatch(setRedirectUrl(null));
  navigate(destinationUrl);
};


export const activateCurrentNotifUserOnLogin = () => async (dispatch, getState) => {
  const registration = await navigator.serviceWorker.getRegistration();

  if (!registration || !registration.pushManager) {
    dispatch(setSubscribed(false));
    return;
  }

  const subscriptionDetails = await registration.pushManager.getSubscription();

  if (!subscriptionDetails) {
    dispatch(setSubscribed(false));
    return;
  }

  const params = {
    subscriptionDetails: subscriptionDetails,
    notificationPreferences: null,
  };

  const activateNotifInstance = new ActivateCurrentNotificationUser({
    params,
    credentials: getState().authentication.credentials,
  });

  activateNotifInstance.call().then(
    (response) => {
      if (response.data === true) {
        dispatch(setSubscribed(true));
      } else {
        dispatch(setSubscribed(false));
      }
    },
    (err) => {
      dispatch(setSubscribed(false));
    }
  );
};


export const setAuthenticationFromUrl =
  (jwtToken, navigate) => (dispatch, getState) => {
    dispatch({
      type: SET_AUTHENTICATION_FROM_URL,
    });

    return dispatch(authenticationRequestSuccess({ data: jwtToken })).then(() =>
      redirectUserOnLogin(
        dispatch,
        navigate,
        getState().authentication.redirectUrl,
        getState().authentication.meta.userType,
        getState().authentication.meta.userTandCsStatus === "Y",
        getState().authentication.meta.contactName,
        getState().authentication.meta.userRole,
        getState().authentication.meta.vendorLandingPage,
      ),
    );
  };

export const fetchUserDefaults = (jwtToken) => (dispatch) => {
  dispatch({
    type: FETCH_USER_DEFAULTS,
  });
  const instance = new UserDefaultsApi({
    params: { jwtToken },
  });

  return instance.call().then(
    (result) => {
      return dispatch(fetchUserDefaultsSuccess(result.data));
    },
    (err) => {
      return dispatch(fetchUserDefaultsFailure(err));
    },
  );
};

const fetchUserDefaultsSuccess = (data) => {
  return {
    type: FETCH_USER_DEFAULTS_SUCCESS,
    payload: data,
  };
};
const fetchUserDefaultsFailure = (error) => {
  return {
    type: FETCH_USER_DEFAULTS_FAILURE,
    payload: error,
  };
};

export const fetchUserTandCsStatus = (jwtToken, navigate) => (dispatch) => {
  dispatch({
    type: FETCH_USER_TANDCS_STATUS,
  });
  const instance = new TermsAndConditionsApi({
    params: { jwtToken },
  });

  return instance.call().then(
    (result) => {
      return dispatch(fetchUserTandCsStatusSuccess(result.data));
    },
    (err) => {
      return dispatch(fetchUserTandCsStatusFailure(err));
    },
  );
};

const fetchUserTandCsStatusSuccess = (data) => {
  return {
    type: FETCH_USER_TANDCS_STATUS_SUCCESS,
    payload: data.termsAndConditionsAccepted,
  };
};

const fetchUserTandCsStatusFailure = (error) => {
  return {
    type: FETCH_USER_TANDCS_STATUS_FAILURE,
    payload: error,
  };
};

export const fetchAccountAdministratorStatus = (jwtToken) => (dispatch) => {
  dispatch({
    type: FETCH_ACCOUNT_ADMINISTRATOR_STATUS,
  });
  const instance = new GetAccountAdministrationStatus({
    params: { jwtToken },
  });

  return instance.call().then(
    (result) => {
      return dispatch(fetchAccountAdministratorStatusSuccess(result.data));
    },
    (err) => {
      return dispatch(fetchAccountAdministratorStatusFailure(err));
    },
  );
};

const fetchAccountAdministratorStatusSuccess = (data) => {
  return {
    type: FETCH_ACCOUNT_ADMINISTRATOR_STATUS_SUCCESS,
    payload: data,
  };
};

const fetchAccountAdministratorStatusFailure = (error) => {
  return {
    type: FETCH_ACCOUNT_ADMINISTRATOR_STATUS_FAILURE,
    payload: error,
  };
};

const initialState = {
  isFetching: false,
  isFetchingUserDefaults: false,
  isFetchingUserTandCsStatus: false,
  isFetchingAccountAdministratorStatus: false,
  error: false,
  credentials: null,
  isLoggingOut: false,
  redirectUrl: null,
  meta: {
    theme: {
      value: "TradeBuyer",
    },
    themeOptions: {},
    userDefaults: {},
    userTandCsStatus: {},
    isGroupBuyer: false,
    userType: null,
    contactName: null,
    userRole: null,
    isAccountAdministrator: false,
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATION_REQUEST:
      return updateObject(state, { isFetching: true });
    case AUTHENTICATION_REQUEST_SUCCESS:
      return updateObject(state, {
        isFetching: false,
        credentials: action.payload.data,
        error: false,
      });
    case AUTHENTICATION_REQUEST_FAILURE:
      return updateObject(state, {
        isFetching: false,
        credentials: null,
        error: true, // we don't want to put the actual error in here
      });
    case SET_THEME_VALUE:
      return {
        ...state,
        meta: {
          ...state.meta,
          theme: {
            value: action.payload,
          },
        },
      };
    case SET_THEME_OPTIONS:
      return {
        ...state,
        meta: {
          ...state.meta,
          themeOptions: action.payload,
        },
      };
    case SET_GROUP_BUYER_STATUS:
      return {
        ...state,
        meta: {
          ...state.meta,
          isGroupBuyer: action.payload,
        },
      };
    case SET_USER_TYPE:
      return {
        ...state,
        meta: {
          ...state.meta,
          userType: action.payload,
        },
      };
    case SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.payload,
      };
    case SET_CONTACT_NAME:
      return {
        ...state,
        meta: {
          ...state.meta,
          contactName: action.payload,
        },
      };
    case SET_USER_ROLE_NAME:
      return {
        ...state,
        meta: {
          ...state.meta,
          userRole: action.payload,
        },
      };
    case SET_VENDOR_LANDING_PAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          vendorLandingPage: action.payload,
        },
      };
    case FETCH_USER_DEFAULTS:
      return {
        ...state,
        isFetchingUserDefaults: true,
      };
    case FETCH_USER_DEFAULTS_SUCCESS:
      return {
        ...state,
        isFetchingUserDefaults: false,
        meta: {
          ...state.meta,
          userDefaults: action.payload,
        },
      };
    case FETCH_USER_DEFAULTS_FAILURE:
      return {
        ...state,
        isFetchingUserDefaults: false,
      };
    case FETCH_USER_TANDCS_STATUS:
      return {
        ...state,
        isFetchingUserTandCsStatus: true,
      };
    case FETCH_USER_TANDCS_STATUS_SUCCESS:
      return {
        ...state,
        isFetchingUserTandCsStatus: false,
        meta: {
          ...state.meta,
          userTandCsStatus: action.payload,
        },
      };
    case FETCH_USER_TANDCS_STATUS_FAILURE:
      return {
        ...state,
        isFetchingUserTandCsStatus: false,
        error: action.payload,
      };
    case FETCH_ACCOUNT_ADMINISTRATOR_STATUS:
      return {
        ...state,
        isFetchingAccountAdministratorStatus: true,
      };
    case FETCH_ACCOUNT_ADMINISTRATOR_STATUS_SUCCESS:
      return {
        ...state,
        isFetchingAccountAdministratorStatus: false,
        meta: {
          ...state.meta,
          isAccountAdministrator: action.payload,
        },
      };
    case FETCH_ACCOUNT_ADMINISTRATOR_STATUS_FAILURE:
      return {
        ...state,
        isFetchingAccountAdministratorStatus: false,
        error: action.payload,
      };
    case SET_AUTHENTICATION_FROM_URL:
      return updateObject(state, { isFetching: true });
    case CLEAR_AUTHENTICATION:
      return {
        ...initialState,
        redirectUrl: state.redirectUrl,
      }
    default:
      return state;
  }
};

export default reducer;
