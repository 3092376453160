import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Icon from "../../Icon/Icon";

const NavButton = props => {
  const { className, direction = "right", onClick } = props;
  const iconType = `chevron-${direction}`;

  return (
    <button className={className} onClick={onClick}>
      <Icon type={iconType} />
    </button>
  );
};

const StyledNavButton = styled(NavButton)`
  background-color: ${props => props.theme.COLOURS.TRANSPARENCIES.white};
  border: 0;
  cursor: pointer;
  left: ${props => props.direction === "left" && 0};
  padding: 0 ${props => props.theme.SPACING.SMALL};
  position: absolute;
  right: ${props => props.direction === "right" && 0};
  top: 0;
  height: 100%;

  svg {
    height: auto;
    width: 2rem;

    path,
    g {
      stroke: white;
    }
  }

  &:focus {
    outline: none;
  }
`;

NavButton.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(["left", "right"]),
  onClick: PropTypes.func
};

export default StyledNavButton;
