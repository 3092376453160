import COLOURS from "./colours";
import FONTS from "./fonts";
import SPACING from "../base/spacing";
import ANIMATIONS from "./animations";
import IMAGES from "./images";

export const Volkswagen = {
  COLOURS,
  FONTS,
  SPACING,
  ANIMATIONS,
  IMAGES,
  title: "VW Dealerlink",
  name: "Volkswagen"
};
