import ClaimSubmissionForm from "./ClaimSubmissionForm";
import styled from "styled-components";
import spacing from "../../theme/base/spacing";
import breakpoints from "../../theme/breakpoints";

const StyledClaimSubmissionForm = styled(ClaimSubmissionForm)`
  .subheader-text__container {
    padding-top: ${spacing.MEDIUM};
  }

  .submit-claim_notes-input {
    padding-top: ${spacing.REGULAR};
  }

  .text-area__input {
      background-color: ${(props) => props.theme.COLOURS.WHITE};
      border-radius: 0.5rem;
      border: 0.2rem solid ${(props) => props.theme.COLOURS.PRIMARY.shades[20]};
      box-sizing: border-box;
      color: ${(props) => props.theme.COLOURS.PRIMARY.base};
      padding: ${spacing.SMALL};
      display: flex;
      justify-content: flex-end;
      width: 100%
      resize: initial;
      ${(props) => props.theme.FONTS.Text["paragraph"]}

      @media (max-width: ${breakpoints.PHONE}) {
        height: 20rem;
      }
    }
`;

export default StyledClaimSubmissionForm;
