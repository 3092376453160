import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Button from "../../Button";

const Footer = (props) => {
  const {
    className,
    galleryIndex,
    galleryLength,
    primaryText,
    secondaryText,
    closeAction,
  } = props;

  return (
    <div className={className}>
      <p>
        {galleryIndex + 1} of {galleryLength}
        {primaryText && (
          <>
            <span className="primary">{primaryText}</span>
            <span className="secondary">{secondaryText}</span>
          </>
        )}
      </p>
      <Button action={() => closeAction()} content="Close" primary />
    </div>
  );
};

const StyledFooter = styled(Footer)`
  align-items: center;
  background-color: ${(props) => props.theme.COLOURS.WHITE};
  border-top: 0.1rem solid ${(props) => props.theme.COLOURS.PRIMARY.shades[40]};
  color: black;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  ${(props) =>
    css`
      padding: ${props.theme.SPACING.SMALL} ${props.theme.SPACING.REGULAR};
    `}

  p {
    color: ${(props) => props.theme.COLOURS.PRIMARY.base};
    margin: 0;
    ${(props) => props.theme.FONTS.Text.infoBold};
  }

  span {
    margin-left: ${(props) => props.theme.SPACING.SMALL};
  }

  .primary {
    color: ${(props) => props.theme.COLOURS.PRIMARY.base};
  }

  .secondary {
    color: ${(props) => props.theme.COLOURS.PRIMARY.shades[60]};
  }
`;

Footer.propTypes = {
  className: PropTypes.string,
  galleryIndex: PropTypes.number,
  galleryLength: PropTypes.number,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  closeAction: PropTypes.func,
};

export default StyledFooter;
