import React from "react";
import { CALLBACK_CONFIRMATION, CALLBACK_CONFIRMATION_MESSAGE } from "./const";
import GenericTransitionModal from "../../../../views/components/GenericTransitionModal";

const ClaimCallbackConfirmation = ({ className }) => {
  return (
    <div className={className}>
      <GenericTransitionModal
        headerText={CALLBACK_CONFIRMATION}
        bodyText={CALLBACK_CONFIRMATION_MESSAGE}
      />
    </div>
  );
};

export default ClaimCallbackConfirmation;
