import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../components/ModalInsert";
import { useNavigate } from "react-router";
import GenericConfirmInsert from "../../components/GenericConfirmInsert";
import { informationRequest } from "../../../state/reducers/claimDetailsVendor";
import VendorInformationRequestConfirmation from "../../components/VendorInformationRequest/VendorInformationRequestConfirmation";
import { clearVehicleRegRequest } from "../../../state/reducers/regSearch";
import { setBucketType } from "./../../../state/reducers/toolbar";

const VendorInformationRequestDecision = ({ claimData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [requestInformation, setRequestInformation] = useState(false);

  const onRequestInformation = () => {
    dispatch(informationRequest(claimData));
    dispatch(setBucketType("all"));
    dispatch(clearVehicleRegRequest());
    setTimeout(() => {
      dispatch(closeModal());
      navigate(-1);
    }, 2500);
    setRequestInformation(true);
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  return (
    <Fragment>
      <ModalInsert>
        {requestInformation ? (
          <VendorInformationRequestConfirmation />
        ) : (
          <GenericConfirmInsert
            headerText="Are you sure?"
            bodyText="Are you sure you wish to request information against this claim?"
            onCancel={onCancel}
            onConfirm={onRequestInformation}
          />
        )}
      </ModalInsert>
    </Fragment>
  );
};

export default VendorInformationRequestDecision;
