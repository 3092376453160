import styled from "styled-components";
import StocklistDownload from "./StocklistDownload";

const StyledStocklistDownload = styled(StocklistDownload)`
  box-sizing: border-box;
  background-color: ${props => props.theme.COLOURS.WHITE};
  border: 0.2rem solid ${props => props.theme.COLOURS.ACCENT.base};
  padding: 3rem;
  border-radius: 1rem;
  width: 100%;
  height: 33.5rem;
  display: flex;

  .icon {
    flex-basis: 14%;
  }
  .content {
    flex-basis: 86%;
    .content__title {
      color: ${props => props.theme.COLOURS.ACCENT.base};
      ${props => props.theme.FONTS.Text["subHeader"]}
    }
    .content__text {
      margin-top: ${props => props.theme.SPACING.SMALL};
      margin-bottom: ${props => props.theme.SPACING.REGULAR};

      p {
        margin: 0;
      }
      span {
        font-weight: ${props => props.theme.FONTS.weights[70]};
      }
    }
    .content__actions {
      button:first-child {
        margin-right: ${props => props.theme.SPACING.SMALL};
      }
    }
  }
`;

export default StyledStocklistDownload;
