import React from "react";
import PropTypes from "prop-types";
import Panel from "../../../components/Panel";
import ModalInsert from "../../ModalInsert";

const SkeletonPromotionModal = ({ className, onCloseClick }) => {
  return (
    <div className={className} style={{ height: "60rem" }}>
      <ModalInsert layout="flex" width="96rem" height="60rem">
        <Panel flex="0" flexDesktop="2.5" type="grey" height="60rem">
          <div className="promotion-blocks">
            <div className="promotion-block__description" />
          </div>
        </Panel>
        <Panel
          flex="2"
          flexTablet="1"
          actions={[
            {
              content: "Close",
              action: () => {
                onCloseClick();
              },
              type: "ghost",
            },
          ]}
        >
          <div className="promotion-block__logo_and_date" />
          <div className="promotion-block__logo_and_date" />
          <div className="promotion-block__additional" />
        </Panel>
      </ModalInsert>
    </div>
  );
};

SkeletonPromotionModal.propTypes = {
  className: PropTypes.string,
  onCloseClick: PropTypes.func,
};

export default SkeletonPromotionModal;
