import React, { useState } from "react";
import propTypes from "prop-types";
import ParagraphText from "../ParagraphText";

const TextArea = (props) => {
  const {
    className,
    validValue,
    maximumCharactersTop,
    maximumCharactersBottom,
    value,
    onChange,
    ...rest
  } = props;

  const [text, setText] = useState(value);

  const maximumCharacters = maximumCharactersTop || maximumCharactersBottom;

  const onTextChange = (value) => {
    if (maximumCharacters) {
      if (value.length <= maximumCharacters) {
        setText(value);
      }
    } else {
      setText(value);
    }
  };

  return (
    <div className={className}>
      <div className="text-area__input-wrapper">
        {maximumCharactersTop && (
          <div className="text-area__character-counter">
            <ParagraphText>{`Character limit ${
              text?.length || 0
            }/${maximumCharactersTop}`}</ParagraphText>
          </div>
        )}
        <textarea
          className={`text-area__input ${validValue ? "valid" : "invalid"}`}
          value={text}
          onChange={(e) => {
            onTextChange(e.target.value);
            onChange(e);
          }}
          type="text"
          {...rest}
        />
      </div>
      {maximumCharactersBottom && (
        <div className="text-area__character-counter">
          <ParagraphText>{`Character limit ${
            text?.length || 0
          }/${maximumCharactersBottom}`}</ParagraphText>
        </div>
      )}
    </div>
  );
};

TextArea.defaultProps = {
  rows: 7,
  cols: 50,
};

TextArea.propTypes = {
  className: propTypes.string,
  validValue: propTypes.bool,
  maximumCharacters: propTypes.number,
  onChange: propTypes.func,
  value: propTypes.string,
};

export default TextArea;
