import styled from "styled-components";
import Pagination from "./Pagination";

const StyledPagination = styled(Pagination)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export default StyledPagination;
