import styled from "styled-components";

import SkeletonDefaultPageCampaignManagement from "./SkeletonDefaultPageCampaignManagement";
import breakpoints from "../../../theme/breakpoints";

const StyledSkeletonDefaultPageCampaignManagement = styled(
  SkeletonDefaultPageCampaignManagement
)`
  .skeleton__page-heading {
    max-width: 119rem;
    height: 11rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 60rem;

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      padding: ${(props) => props.theme.SPACING.LARGE} 0;
    }

    .skeleton__page-title {
      width: 20%;
      height: 5rem;
      display: block;
      background-color: ${(props) => props.theme.COLOURS.PRIMARY.shades[80]};
      ${(props) => props.theme.ANIMATIONS["shimmerDarker"]};
    }
    .skeleton__page-user {
      width: 20%;
      height: 5rem;
      background-color: ${(props) => props.theme.COLOURS.PRIMARY.shades[80]};
      ${(props) => props.theme.ANIMATIONS["shimmerDarker"]};
    }
    .skeleton__page-test {
      width: 20%;
      height: 5rem;
      background-color: ${(props) => props.theme.COLOURS.PRIMARY.shades[80]};
      ${(props) => props.theme.ANIMATIONS["shimmerDarker"]};
    }
  }

  .skeleton__pageContainer {
    max-width: 119rem;
    height: 14rem;
    margin: 0;
    display: flex;
    flex-direction: column;

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      padding: ${(props) => props.theme.SPACING.SMALL} 0;
    }
  }

  .skeleton__pageInfo {
    width: 40%;
    height: 7rem;
    display: block;
    margin-left: 8rem;
    background-color: ${(props) => props.theme.COLOURS.PRIMARY.shades[80]};
    ${(props) => props.theme.ANIMATIONS["shimmerDarker"]};
  }

  .skeleton__page-content {
    height: 100vh;
    background-color: ${(props) => props.theme.COLOURS.PRIMARY.shades[5]};
  }
`;

export default StyledSkeletonDefaultPageCampaignManagement;
