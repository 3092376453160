import {
  GetMessages,
  GetNotActionedMessagesCount,
  DeleteMessage,
  FetchMessageContent,
  UpdateMessageStatus,
} from "../../api/Messages";
import { fetchFilterOptions } from "../reducers/filter";

// Action constants
export const FETCH_MESSAGES = "@messages/FETCH_MESSAGES";
export const FETCH_MESSAGES_SUCCESS = "@messages/FETCH_MESSAGES_SUCCESS";
export const FETCH_MESSAGES_FAILURE = "@messages/FETCH_MESSAGES_FAILURE";
export const CLOSE_MESSAGE = "@messages/CLOSE_MESSAGE";
export const DELETE_MESSAGE_SUCCESS = "@messages/DELETE_MESSAGE_SUCCESS";
export const DELETE_MESSAGE_FAILURE = "@messages/DELETE_MESSAGE_FAILURE";
export const DELETE_MESSAGE = "@messages/DELETE_MESSAGE";
export const FETCH_NOT_ACTIONED_MESSAGES_COUNT =
  "@messages/FETCH_NOT_ACTIONED_MESSAGES_COUNT";
export const FETCH_NOT_ACTIONED_MESSAGES_COUNT_SUCCESS =
  "@messages/FETCH_NOT_ACTIONED_MESSAGES_COUNT_SUCCESS";
export const FETCH_NOT_ACTIONED_MESSAGES_COUNT_FAILURE =
  "@messages/FETCH_NOT_ACTIONED_MESSAGES_COUNT_FAILURE";
export const CLEAR_FILTERS = "@messages/CLEAR_FILTERS";
export const UPDATE_PAGE_NUMBER = "@messages/UPDATE_PAGE_NUMBER";
export const FETCH_MESSAGE_CONTENT_SUCCESS = "@messages/GET_MESSAGE_SUCCESS";
export const FETCH_MESSAGE_CONTENT_FAILURE = "@messages/GET_MESSAGE_FAILURE";
export const FETCH_MESSAGE_CONTENT = "@messages/GET_MESSAGE";

export const UPDATE_MESSAGE_STATUS = "@messages/UPDATE_MESSAGE_STATUS";
export const UPDATE_MESSAGE_STATUS_SUCCESS = "@messages/UPDATE_MESSAGE_STATUS_SUCCESS";
export const UPDATE_MESSAGE_STATUS_FAILURE = "@messages/UPDATE_MESSAGE_STATUS_FAILURE";

// Action creators
export const fetchMessagesSuccess = (data) => {
  return {
    type: FETCH_MESSAGES_SUCCESS,
    payload: data,
  };
};

export const fetchMessagesFailure = (error) => {
  return {
    type: FETCH_MESSAGES_FAILURE,
    payload: error,
  };
};

export const fetchMessages = () => (dispatch, getState) => {
  dispatch({ type: FETCH_MESSAGES });

  // TODO: Sort has different options for this endpoint
  const toolbar = getState().toolbar;
  const pageNumber = getState().pagination.pageNumber;
  const filters = getState().filter.filters;
  const { pageSize, bucketType } = toolbar;

  const params = {
    pageSize,
    pageNumber,
    messagesBucket: bucketType,
    filters,
  };

  const messagesInstance = new GetMessages({
    params,
    credentials: getState().authentication.credentials,
  });

  messagesInstance.call().then(
    (response) => {
      dispatch(fetchMessagesSuccess(response.data));
    },
    (err) => {
      return dispatch(fetchMessagesFailure(err));
    }
  );
};

export const closeMessage = (id) => {
  return {
    type: CLOSE_MESSAGE,
    payload: id,
  };
};

export const deleteMessageSuccess = (id) => {
  return {
    type: DELETE_MESSAGE_SUCCESS,
    payload: {
      messageId: id,
    },
  };
};

export const deleteMessageFailure = (error) => {
  return {
    type: DELETE_MESSAGE_FAILURE,
    payload: error,
  };
};

export const deleteMessage = (inboxId, messageId) => (dispatch, getState) => {
  dispatch({ type: DELETE_MESSAGE });

  const messagesInstance = new DeleteMessage({
    params: { id: inboxId },
    credentials: getState().authentication.credentials,
  });
  const activeFilter = getState().global.filters.activeFilter;

  messagesInstance.call().then(
    (response) => {
      dispatch(deleteMessageSuccess(messageId));
      dispatch(fetchNotActionedMessagesCount());
      if (activeFilter) {
        dispatch(
          fetchFilterOptions({
            filterId: getState().global.filters.activeFilter,
          })
        );
      }
    },
    (err) => {
      // TODO: error logging
      return dispatch(deleteMessageFailure(err));
    }
  );
};

export const fetchMessageContent = (inBoxId, messageId, isMessageRead) => (dispatch, getState) => {
  dispatch({ type: FETCH_MESSAGE_CONTENT, payload: messageId });

  const messagesInstance = new FetchMessageContent({
    params: { id: messageId },
    credentials: getState().authentication.credentials,
  });

  messagesInstance.call().then(
    (response) => {
      dispatch(fetchMessageContentSuccess(messageId, response.data));
      if (!isMessageRead)
      {
        dispatch(updateMessageStatus(inBoxId, messageId, true));
      }
    },
    (err) => {
      return dispatch(fetchMessageContentFailure(err));
    }
  );
};

export const fetchMessageContentSuccess = (id, data) => {
  return dispatch => {
    dispatch({
      type: FETCH_MESSAGE_CONTENT_SUCCESS,
      payload: {
        messageId: id,
        message: data,
      },
    });
  };
};


export const fetchMessageContentFailure = (error) => {
  return {
    type: FETCH_MESSAGE_CONTENT_FAILURE,
    payload: error,
  };
};

export const fetchNotActionedMessagesCountSuccess = (data) => {
  return {
    type: FETCH_NOT_ACTIONED_MESSAGES_COUNT_SUCCESS,
    payload: data,
  };
};

export const fetchNotActionedMessagesCountFailure = (error) => {
  return {
    type: FETCH_NOT_ACTIONED_MESSAGES_COUNT_FAILURE,
    payload: error,
  };
};

export const fetchNotActionedMessagesCount = () => (dispatch, getState) => {
  dispatch({ type: FETCH_NOT_ACTIONED_MESSAGES_COUNT });

  const messagesInstance = new GetNotActionedMessagesCount({
    credentials: getState().authentication.credentials,
  });

  messagesInstance.call().then(
    (response) => {
      dispatch(fetchNotActionedMessagesCountSuccess(response.data));
    },
    (err) => {
      return dispatch(fetchNotActionedMessagesCountFailure(err));
    }
  );
};

export const clearFilters = () => (dispatch) => {
  dispatch({
    type: CLEAR_FILTERS,
  });
};

export const updateMessageStatus = (inBoxId, messageId, isMessageRead, isNotificationClicked = false) => (dispatch, getState) => {
  dispatch({ 
    type: UPDATE_MESSAGE_STATUS,
    payload: {inBoxId, messageId, isMessageRead},
  });
  
  const params = {
    inBoxId,
    messageId,
    isMessageRead,
    isNotificationClicked,
  };

  const activeFilter = getState().global.filters.activeFilter;

  const updateMessageStatusInstance = new UpdateMessageStatus({
    params,
    credentials: getState().authentication.credentials,
  });

  updateMessageStatusInstance.call().then(
    (response) => {
      dispatch(fetchNotActionedMessagesCount());
      dispatch(updateMessageStatusSuccess(response.data));
      if (activeFilter) {
        dispatch(
          fetchFilterOptions({
            filterId: getState().global.filters.activeFilter,
          })
        );
      }},
    (err) => {
      return dispatch(updateMessageStatusFailure(err));
    }
  );
};

export const updateMessageStatusSuccess = (data) => {
  return {
    type: UPDATE_MESSAGE_STATUS_SUCCESS,
    payload: data,
  };
};

export const updateMessageStatusFailure = (error) => {
  return {
    type: UPDATE_MESSAGE_STATUS_FAILURE,
    payload: error,
  };
};

const closeMessageById = (messages, messageId) => {
  const messageIdx = messages.findIndex((el) => el.messageId === messageId);
  let newMessagesArr = [...messages];
  newMessagesArr[messageIdx] = { ...newMessagesArr[messageIdx], isOpen: false };
  return newMessagesArr;
};

export const initialState = {
  isFetching: false,
  isFetchingMessagesCount: false,
  fetchingMessageId: null,
  isDeleting: false,
  error: false,
  data: { messages: [] },
  notActionedMessagesCount: null,
  filter: [],
  pageNumber: 1,
  isUpdatingMessageStatus: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MESSAGES:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_MESSAGES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case FETCH_MESSAGES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        totalCount: action.payload.totalCount,
        pageSize: action.payload.pageSize,
        pageCount: action.payload.pageCount,
        data: action.payload,
      };
    case CLOSE_MESSAGE:
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          messages: closeMessageById(state.data.messages, action.payload),
        },
      };
    case DELETE_MESSAGE:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        data: {
          ...state.data,
          messages: state.data.messages.filter(
            (message) => message.messageId !== action.payload.messageId
          ),
        },
      };
    case DELETE_MESSAGE_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: action.payload,
      };
    case CLEAR_FILTERS:
      return {
        ...state,
        ...initialState,
      };
    case FETCH_NOT_ACTIONED_MESSAGES_COUNT:
      return {
        ...state,
        isFetchingMessagesCount: true,
      };
    case FETCH_NOT_ACTIONED_MESSAGES_COUNT_FAILURE:
      return {
        ...state,
        isFetchingMessagesCount: false,
        error: action.payload,
      };
    case FETCH_NOT_ACTIONED_MESSAGES_COUNT_SUCCESS:
      return {
        ...state,
        isFetchingMessagesCount: false,
        notActionedMessagesCount: action.payload,
      };
    case FETCH_MESSAGE_CONTENT:
      return {
        ...state,
        fetchingMessageId: action.payload,
      };
    case FETCH_MESSAGE_CONTENT_SUCCESS:
      return {
        ...state,
        fetchingMessageId: null,
        data: {
          ...state.data,
          messages: state.data.messages.map((message) => ({
            ...message,
            isOpen:
              message.messageId === action.payload.messageId ? true : false,
            content: action.payload.message.content,
            parameters: action.payload.message.parameters,
          })),
        },
      };
    case FETCH_MESSAGE_CONTENT_FAILURE:
      return {
        ...state,
        fetchingMessageId: null,
        error: action.payload,
      };
    case UPDATE_MESSAGE_STATUS:
      return {
        ...state,
        isUpdatingMessageStatus: true,
        data: {
          ...state.data,
          messages: state.data.messages.map((message) => ({
            ...message,
            isMessageRead:
              message.messageId === action.payload.messageId ? action.payload.isMessageRead : message.isMessageRead,
          })),
      },
      };
    case UPDATE_MESSAGE_STATUS_FAILURE:
      return {
        ...state,
        isUpdatingMessageStatus: false,
        error: action.payload,
      };
    case UPDATE_MESSAGE_STATUS_SUCCESS:
      return {
        ...state,
        isUpdatingMessageStatus: false,
      };
    default:
      return state;
  }
};

export default reducer;
