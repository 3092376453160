import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import PricingBlock from "../../components/PricingBlock";
import CountdownInsert from "../../components/CountdownInsert/CountdownInsert"; //doubled as there is no index.js

const BidPricingBlockCurrent = (props) => {
  const vehicleId = useSelector(
    (state) => state.global.bids.activeBidVehicleId
  );
  const bidDetails = useSelector(
    (state) => state.bid.bids.byVehicleId[vehicleId]
  );
  const {
    bidCount = 0,
    biddingEndDate,
    currentBidAmount = 0,
    previousMaxBidAmount,
    bidStatus,
  } = bidDetails;

  const hasDeclinedPreviousBid =
    previousMaxBidAmount && bidStatus === "Declined";

  return bidCount > 0 && (!previousMaxBidAmount || hasDeclinedPreviousBid) ? (
    <Fragment>
      <PricingBlock
        price={currentBidAmount}
        headerText="Current bid"
        bidText={`${bidCount} Bids`}
        inline={props.inline}
        large
      />
      <PricingBlock
        headerText={
          biddingEndDate ? (
            <CountdownInsert endDateTime={biddingEndDate} />
          ) : (
            "Open Ended Bidding"
          )
        }
        large
        inline
      />
    </Fragment>
  ) : null;
};

export default BidPricingBlockCurrent;
