import React, { useState } from "react";

export const ReviewCompleteReasonContext = React.createContext({});

export const ReviewCompleteReasonContextProvider = ({ children }) => {
  const [reviewCompleteReason, setReviewCompleteReason] = useState("");

  return (
    <ReviewCompleteReasonContext.Provider
      value={{
        reviewCompleteReason,
        setReviewCompleteReason,
      }}
    >
      {children}
    </ReviewCompleteReasonContext.Provider>
  );
};
