import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ParagraphText from "../ParagraphText";
import Icon from "./../Icon";
import MediaQuery from "react-responsive";
import breakpoints from "../../theme/breakpoints";

const SettingsMenuOptions = ({
  className,
  onStockListDownload,
  onAboutSession,
  onLogout,
  isAccountAdministrator,
  buyerOnly,
  allowPushNotifications,
}) => {
  return (
    <div className={className}>
      {isAccountAdministrator && buyerOnly && (
        <>
          <Link to={"/account-administration"} className="option">
            <Icon type="user" width="20px" height="20px" />
            <ParagraphText primary>Account Administration</ParagraphText>
          </Link>
        </>
      )}
      {allowPushNotifications && buyerOnly && (
        <>
          <Link
            to={"/notification-preference"}
            className="option notificationBellIcon"
          >
            <Icon type="notificationBell" width="20px" height="20px" />
            <ParagraphText primary>Notification Preferences</ParagraphText>
          </Link>
        </>
      )}
      {isAccountAdministrator && buyerOnly && <hr />}
      {buyerOnly && (
        <MediaQuery minWidth={breakpoints.TABLET}>
          <div
            className="option gaEvent-download"
            onClick={onStockListDownload}
          >
            <Icon
              type="arrow-download"
              width="20px"
              height="20px"
              strokeWidth="3"
            />
            <ParagraphText primary>Download a Stocklist</ParagraphText>
          </div>
        </MediaQuery>
      )}
      {buyerOnly && (
        <MediaQuery minWidth={breakpoints.TABLET}>
          <Link to={"/invoices"} className="option">
            <Icon type="stack" />
            <ParagraphText primary>Invoices & Credits</ParagraphText>
          </Link>
        </MediaQuery>
      )}
      {buyerOnly && (
        <MediaQuery minWidth={breakpoints.TABLET}>
          <Link to={"/activity-log"} className="option">
            <Icon type="list-view" width="20px" height="20px" strokeWidth="3" />
            <ParagraphText primary>Activity Log</ParagraphText>
          </Link>
        </MediaQuery>
      )}
      {buyerOnly && (
        <Link to={"/help-centre"} className="option">
          <Icon type="question-circle" width="20px" height="20px" />
          <ParagraphText primary>Help Centre</ParagraphText>
        </Link>
      )}
      <div className="option" onClick={onAboutSession}>
        <Icon type="info-circle" width="20px" height="20px" strokeWidth="3" />
        <ParagraphText primary>About</ParagraphText>
      </div>
      {buyerOnly && <hr />}
      {buyerOnly && (
        <Link to={"/change-password"} className="option">
          <Icon type="arrow-refresh" width="20px" height="20px" />
          <ParagraphText primary>Change Password</ParagraphText>
        </Link>
      )}
      <div className="option" onClick={onLogout}>
        <Icon type="lock" width="18px" height="24px" />
        <ParagraphText primary>Logout</ParagraphText>
      </div>
    </div>
  );
};

SettingsMenuOptions.propTypes = {
  className: PropTypes.string,
  onStockListDownload: PropTypes.func,
  onAboutSession: PropTypes.func,
  onLogout: PropTypes.func,
  isAccountAdministrator: PropTypes.bool,
};

export default SettingsMenuOptions;
