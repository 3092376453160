import React from "react";
import { useDispatch,useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../components/ModalInsert";
import ShowroomModalComponent from "../../components/ShowroomModalComponent";
import clientSettings from "../../../clientappsettings"

const ShowroomModalInsert = () => {
  const dispatch = useDispatch();

  const vehicleId = useSelector(state => state.vehicle.data.vehicleId);

  const onClose = () => {
    dispatch(closeModal());
  };

  const url = `${clientSettings.SUB_PATH}/vehicle/showroom/${vehicleId}`;

  const onCreateShowroom = (price, finance, salesText, financeText, financeTypes, checkFinanceOption, showroomInformation) => {
    const showroomData = {
      salePrice: price,
      financePrice: finance,
      salesText: salesText,
      financeText: financeText,
      financeTypes: financeTypes,
      checkFinanceOption: checkFinanceOption,
      showroomInformation: showroomInformation
    };
    localStorage.setItem("showroomData", JSON.stringify(showroomData));
    window.open(url, "_blank");
    dispatch(closeModal());
  };
  
  return (
    <ModalInsert layout="flex">
      <ShowroomModalComponent
        onClose={onClose}
        onCreateShowroom={onCreateShowroom}
      />
    </ModalInsert>
  );
};

export default ShowroomModalInsert;
