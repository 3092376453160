import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../../views/components/ModalInsert";
import Panel from "../../../views/components/Panel";
import MakeOfferForm from "../../components/MakeOffer/MakeOfferForm";
import MakeOfferDecision from "../../containers/MakeOfferDecision";
import SkeletonRows from "../../../views/components/Skeletons/SkeletonRows";
import dayjs from "dayjs";

const MakeOfferModalInsert = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.claimDetailsVendor?.data);

  const [notesToBuyer, setNotesToBuyer] = useState("");
  const [offerMadeDecision, setOfferMadeDecision] = useState(false);
  const [offerAmount, setOfferAmount] = useState("");
  const [
    isOfferSaleCancellationChecked,
    setOfferSaleCancellationChecked,
  ] = useState(false);

  const formData = {
    claimId: data?.claimId,
    offerAmount: offerAmount === "" || isNaN(offerAmount) ? null : offerAmount,
    offerSaleCancellation: isOfferSaleCancellationChecked,
    notesToBuyer: notesToBuyer,
  };

  const dateFirstSubmitted = dayjs(data?.claimDetails.dateFirstSubmitted);
  const dayCount = Math.floor(dayjs().diff(dateFirstSubmitted, "day", true));

  const isValidOffer =
    (parseInt(offerAmount) || 0) < data.saleInfo.purchasePrice;
  const onSubmitOffer = () => {
    setOfferMadeDecision(true);
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  const CompleteCallBackFormContent = (
    <Panel
      mobileFlex
      style={{ height: "100%", borderRadius: "0.5rem" }}
      actions={[
        {
          content: "Close",
          action: onCancel,
          type: "ghost",
        },
        {
          content: "Submit Offer",
          action: onSubmitOffer,
          type: "primary",
          disabled:
            (!offerAmount && !isOfferSaleCancellationChecked) ||
            !isValidOffer ||
            offerAmount === "0",
        },
      ]}
    >
      {!!data ? (
        <MakeOfferForm
          claimId={data.claimId}
          tradeBuyer={data.saleInfo.tradeBuyer}
          regNo={data.details.regNo}
          resolution={data.claimDetails.claimResolution}
          requestedAmount={data.claimDetails.claimTotalCost}
          dayCount={dayCount}
          purchaseValue={data.saleInfo.purchasePrice}
          residualValue={data.details.residualValue}
          capValue={data.details.capValue}
          previousOffer={data.claimDetails.previousOffer}
          setNotesToBuyer={setNotesToBuyer}
          setOfferAmount={setOfferAmount}
          offerAmount={offerAmount}
          isOfferSaleCancellationChecked={isOfferSaleCancellationChecked}
          setOfferSaleCancellationChecked={setOfferSaleCancellationChecked}
          isValidOffer={isValidOffer}
          notesToBuyer={notesToBuyer}
        />
      ) : (
        <SkeletonRows nrOfRows={5} />
      )}
    </Panel>
  );

  return (
    <Fragment>
      <ModalInsert width={offerMadeDecision ? "55rem" : "96rem"}>
        {offerMadeDecision ? (
          <MakeOfferDecision
            offerData={formData}
            buyerName={data.saleInfo.tradeBuyer}
          />
        ) : (
          CompleteCallBackFormContent
        )}
      </ModalInsert>
    </Fragment>
  );
};
export default MakeOfferModalInsert;
