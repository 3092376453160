import styled, { css } from "styled-components";
import SettingsMenuOptions from "./SettingsMenuOptions.jsx";
import { StyledParagraphText } from "../ParagraphText";
import { StyledIcon } from "../Icon";

export const StyledSettingsMenuOptions = styled(SettingsMenuOptions)`
  width: 100%;

  hr {
    margin: 0;
  }

  .option {
    display: flex;
    margin-top: ${(props) => props.theme.SPACING.REGULAR};
    margin-bottom: ${(props) => props.theme.SPACING.REGULAR};
    cursor: pointer;
    text-decoration: none;

    ${StyledParagraphText} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;
      line-height: 0;
      cursor: pointer;
      font-weight: ${(props) => props.theme.FONTS.weights[70]};
    }

    ${StyledIcon} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: ${(props) => props.theme.SPACING.SMALL};
    }

    :hover {
      ${StyledParagraphText} {
        color: ${(props) => props.theme.COLOURS.SECONDARY.base};
      }
      ${StyledIcon} {
        > svg > g {
          stroke: ${(props) => props.theme.COLOURS.SECONDARY.base};
        }
      }
    }
  }

  div.option:last-child {
    margin-bottom: 2.5rem;
  }

  ${(props) =>
    props.inline &&
    css`
      .option {
        ${(props) => props.theme.FONTS.Text["paragraphBold"]};
        align-items: center;
        border-bottom: 0.1rem solid rgba(255, 255, 255, 0.125);
        display: flex;
        margin: 0;
        opacity: 1;
        padding: 1.5rem 2rem 1.5rem 3rem;
        text-decoration: none;

        ${StyledParagraphText} {
          color: ${(props) => props.theme.COLOURS.PRIMARY.shades[60]};
        }

        ${StyledIcon} {
          > svg > g {
            stroke: ${(props) => props.theme.COLOURS.PRIMARY.shades[60]};
          }
        }

        &:hover {
          ${StyledParagraphText} {
            color: ${(props) => props.theme.COLOURS.WHITE};
          }

          ${StyledIcon} {
            > svg > g {
              stroke: ${(props) => props.theme.COLOURS.WHITE};
            }
          }
        }
      }

.notificationBellIcon {
  ${StyledIcon} {
    > svg > g {
      fill: ${(props) => props.theme.COLOURS.PRIMARY.shades[60]};
   }
}

  &:hover {
    ${StyledIcon} {
    > svg > g {
    fill: ${(props) => props.theme.COLOURS.WHITE};
    }
  }
}
      } 
}

      hr {
        display: none;
      }
    `}
`;

export default StyledSettingsMenuOptions;
