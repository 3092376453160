import Api from "./Base";
import clientSettings from "../clientappsettings.json";
import { formatForSaleVehicleResultsResponse } from "../shared/utility";
import { formatFilterOptions } from "../shared/filter";

class SearchApi extends Api {
  baseUrl = clientSettings.API_URL;
  requestUrl = "/Search";
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };

  formatRequestData(params) {
    let formattedData = {
      // TODO: Search will bork from the BE without the makeRanges field
      makeRanges: []
    };
    if (params) {
      const {
        filters,
        isShortlisting,
        pageNumber,
        pageSize,
        sort,
        registration,
        purchaseSession
      } = params;
      formattedData = {
        pageNumber,
        pageSize,
        sort,
        registration,
        purchaseSession,
        ...formatFilterOptions({ filterObject: filters, pageType: "search" })
      };
      if (isShortlisting) {
        formattedData.shortlist = true;
      }
      return formattedData;
    }
    return formattedData;
  }

  formatResponseData(response) {
    return formatForSaleVehicleResultsResponse(response);
  }
}

export default SearchApi;
