import React, { Fragment, useContext } from "react";
import { PopoverFilterContext } from "../../../../shared/context";
import Chevron from "./Chevron";

const Label = () => {
  const { isOpen, selectedCount, selectedLabel, label } = useContext(
    PopoverFilterContext
  );
  return selectedCount ? (
    <Fragment>
      <span className="label__count">{selectedCount}</span>
      <span className="label__string">{selectedLabel}</span>
      <Chevron isOpen={isOpen} />
    </Fragment>
  ) : (
    <Fragment>
      <span className="label_placeholder">{`Any ${label}`}</span>
      <Chevron isOpen={isOpen} />
    </Fragment>
  );
};

export default Label;
