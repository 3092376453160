import styled, { css } from "styled-components";

import VehicleGrade from "./VehicleGrade";
import { StyledKeyInformationLabel } from "./../KeyInformationLabel";

export const StyledVehicleGrade = styled(VehicleGrade)`
  display: flex;
  flex-direction: column;
  text-align: left;
  white-space: nowrap;

  > ${StyledKeyInformationLabel} {
    margin-bottom: 0.5rem;
  }

p {
    margin: 0;
    display: inline-block;
    color: ${props => props.theme.COLOURS.PRIMARY.base};
    font-size: ${props => props.theme.FONTS.sizes[40]};
    font-weight: ${props => props.theme.FONTS.weights[70]};
  }

  ${props =>
    props.small &&
    css`
      p,
      span {
        font-size: ${props.theme.FONTS.sizes[30]};
      }
   `};

  ${props =>
    !props.small &&
    css`
      ${StyledKeyInformationLabel} {
        font-weight: ${props.theme.FONTS.weights[70]};
        font-size: ${props.theme.FONTS.sizes[30]};
        line-height: ${props.theme.FONTS.lineHeights[20]};
      }
    `};
`;

export default StyledVehicleGrade;
