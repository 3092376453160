import styled, { css } from "styled-components";

import ButtonLink from "./ButtonLink.jsx";
import StyledIcon from "./../Icon";

export const StyledButtonLink = styled(ButtonLink)`
  background: none;
  border: none;
  outline: none;
  padding: 0;
  font-family: ${(props) => props.theme.FONTS.families.lato};
  color: ${(props) => props.mainColour || props.theme.COLOURS.ACCENT.base};
  font-weight: ${(props) => props.theme.FONTS.weights[70]};
  font-size: ${(props) => props.theme.FONTS.sizes[40]};
  cursor: pointer;
  text-decoration: ${(props) => (!props.noUnderline ? "underline" : "none")};

  ${(props) =>
    props.listItem &&
    css`
      display: block;
      margin: 0.5rem 0;
      text-decoration: none;
    `};

  :hover {
    color: ${(props) => props.hoverColour || props.theme.COLOURS.SECONDARY_ACCENT.base};
  }

  :disabled {
    color: ${(props) => props.theme.COLOURS.PRIMARY.shades[20]};
    :hover {
      color: ${(props) => props.theme.COLOURS.PRIMARY.shades[20]};
    }
    cursor: not-allowed;
  }

  ${StyledIcon} {
    vertical-align: middle;
    margin-right: 0.5rem;
  }

  text-decoration-color: ${(props) =>
    props.underlineColor && props.underlineColor};

  ${(props) =>
    props.primary &&
    css`
      color: ${(props) => props.theme.COLOURS.ACCENT.base};
      :hover {
        color: ${(props) => props.theme.COLOURS.SECONDARY_ACCENT.base};
      }
    `};

  ${(props) =>
    props.secondary &&
    css`
      color: ${(props) => props.theme.COLOURS.PRIMARY.base};
      :hover {
        color: ${(props) => props.theme.COLOURS.SECONDARY.base};
      }
    `}
`;

export default StyledButtonLink;
