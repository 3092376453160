import React, { Fragment } from "react";

const PriceBreakdown = ({ children, className }) => {
  return (
    <Fragment>
      <span className={className}> {children} </span>
    </Fragment>
  );
};

export default PriceBreakdown;
