import styled from "styled-components";
import VehicleOverviewCard from "./VehicleOverviewCard";
import { StyledPill } from "../Pill";
import { StyledVehiclePillDetails } from "../VehiclePillDetails";
import breakpoints from "../../theme/breakpoints";

const StyledVehicleOverviewCard = styled(VehicleOverviewCard)`
  display: block;
  background-color: ${props => props.theme.COLOURS.PRIMARY.shades[5]};

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    max-width: 50rem;
  }

  .vehicle-overview-card__content {
    max-width: 100%;
    padding: ${props => props.theme.SPACING.REGULAR};

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      padding: ${props => props.theme.SPACING.LARGE};
      padding-top: ${props => props.theme.SPACING.REGULAR};
    }
  }

  .vehicle-overview-card__image {
    width: 100%;
    height: 30rem;

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      width: 49rem;
    }

    > div {
      display: inline-block;
      width: 100%;
      height: 100%;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  ${StyledVehiclePillDetails} {
    margin: ${props => props.theme.SPACING.SMALL} 0
      ${props => props.theme.SPACING.REGULAR} 0;

    > ${StyledPill} {
      color: ${props => props.theme.COLOURS.PRIMARY.shades[70]};
      background-color: ${props => props.theme.COLOURS.WHITE};
    }
  }
`;

export default StyledVehicleOverviewCard;
