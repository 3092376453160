import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const DownloadSquare = ({ width, height, strokeColour, strokeWidth }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.shades[40];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 50 50"
    >
      <g
        fill={stroke}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M23.5,27c0-2.4,0-4.8,0-7.2c0-1.5,0-3.1,0-4.7c0-1.2,0.1-2.4,1.6-2.4s1.7,1.1,1.7,2.3c0,3.4,0,6.9,0,10.3
		c0,0.5,0,1.1,0,2.1c1.5-1.5,2.8-2.7,4-4c0.8-0.8,1.7-1.4,2.7-0.5c1,1,0.5,1.9-0.3,2.7c-1.9,1.9-3.8,3.8-5.7,5.6
		c-1.6,1.6-3,1.6-4.6,0c-2-1.9-3.9-3.9-5.8-5.8c-0.8-0.8-1.3-1.7-0.4-2.6c1-1,1.9-0.5,2.7,0.4c1.2,1.3,2.4,2.5,3.7,3.8
		C23.3,27.1,23.4,27,23.5,27z"
        />
        <path
          d="M25.1,38.6c-2.2,0-4.4,0-6.7,0c-1.2,0-2.1-0.3-2.1-1.6c0-1.2,0.9-1.7,2-1.7c4.6,0,9.1,0,13.7,0
		c1.2,0,2.1,0.5,2,1.7c0,1.2-1,1.6-2.1,1.6C29.6,38.6,27.3,38.6,25.1,38.6z"
        />
      </g>
      <path
        fill={"none"}
        stroke={stroke}
        strokeWidth={strokeWidth}
        d="M38.9,45.2H11.1c-3.2,0-5.8-2.6-5.8-5.8V11.8c0-3.2,2.6-5.8,5.8-5.8h27.8c3.2,0,5.8,2.6,5.8,5.8v27.6
	C44.8,42.5,42.2,45.2,38.9,45.2z"
      />
    </svg>
  );
};

DownloadSquare.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

DownloadSquare.defaultProps = {
  width: "34",
  height: "34",
};

export default DownloadSquare;
