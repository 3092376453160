import styled from "styled-components";
import spacing from "../../../../views/theme/base/spacing";
import colours from "../../../../views/theme/base/colours";
import MakeOfferForm from "./MakeOfferForm";
import ParagraphText from "../../../../views/components/ParagraphText";

const StyledMakeOfferForm = styled(MakeOfferForm)`
  .make-offer__form {
    margin-top: ${(props) => props.theme.SPACING.MEDIUM};
    display: flex;
    justify-content: space-evenly;
  }

  .make-offer__detail-list {
    width: 100%;
    display: inline-block;
    flex-direction: column;
    justify-content: space-evenly;

    .make-offer-list__row {
      width: 100%;
      display: flex;
      margin: 0 0 ${(props) => props.theme.SPACING.SMALL} 0;

      ${ParagraphText} {
        width: 100%;
      }

      &__col-one {
        min-width: 20rem;
        flex: 1;
        display: flex;
        align-items: center;
        vertical-align: baseline;
      }

      &__col-two {
        flex: 2;
        padding-right: ${(props) => props.theme.SPACING.SMALL};
      }
    }

    .make-offer-list__row_cancel {
      width: 100%;
      display: flex;
      align-items: center;
      height: 5.2rem;
      margin: 0 0 ${(props) => props.theme.SPACING.SMALL} 0;

      ${ParagraphText} {
        width: 100%;
      }

      &__col-one {
        min-width: 20rem;
        flex: 1;
        display: flex;
        align-items: center;
        vertical-align: baseline;
      }

      &__col-two {
        flex: 2;
        padding-right: ${(props) => props.theme.SPACING.SMALL};
        display: flex;
        align-items: center;
        vertical-align: baseline;
      }
    }
  }

  .make-offer-request-notes {
    margin: ${(props) => props.theme.SPACING.REGULAR} 0;
  }

  .text-area__input {
    background-color: ${colours.WHITE};
    border-radius: 0.5rem;
    border: 0.2rem solid ${colours.PRIMARY.shades[20]};
    box-sizing: border-box;
    color: ${colours.PRIMARY.base};
    padding: ${spacing.SMALL};
    display: flex;
    justify-content: flex-end;
    width: 100%;
    resize: initial;
    ${(props) => props.theme.FONTS.Text["paragraph"]}
  }

  .offer-amount {
    width: 75%;
  }

  .offer-amount__error {
    line-height: normal;
  }
`;

export default StyledMakeOfferForm;
