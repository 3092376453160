import styled from "styled-components";

import NavigationItem from "./NavigationItem.jsx";
import { StyledIcon } from "./../Icon";
import breakpoints from "../../theme/breakpoints.js";

const StyledNavigationItem = styled(NavigationItem)`
  align-items: center;
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.25);
  color: ${props => props.theme.COLOURS.WHITE};
  display: flex;
  padding: 2rem 0;
  text-decoration: none;

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    border-bottom: 0.25rem solid transparent;
    display: ${props => props.mobile && "none"};
    text-align: center;
    height: 8.2rem;
    padding: 0;
  }

  ${StyledIcon} {
    margin-right: 1rem;
    margin-left: ${props => props.theme.SPACING.REGULAR};
  }

  span {
    margin-right: ${props => props.theme.SPACING.REGULAR};
    opacity: 0.5;
  }

  ${props => props.theme.FONTS.Text["paragraphBold"]}

  &:hover,
  &.active {
    border-bottom: 0.1rem solid rgba(255, 255, 255, 0.125);
    ${props => {
      switch (props.theme.name) {
        case "VolkswagenCommercial":
          return {
            color: props.theme.COLOURS.PRIMARY.base
          };
        default:
          return {
            color: props.theme.COLOURS.WHITE
          };
      }
    }};

    span > svg > path,
    g {
      ${props => {
        switch (props.theme.name) {
          case "VolkswagenCommercial":
            return {
              stroke: props.theme.COLOURS.PRIMARY.base
            };
          default:
            return {
              stroke: props.theme.COLOURS.WHITE
            };
        }
      }};
    }
    span {
      opacity: 1;
    }

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      ${props => {
        switch (props.theme.name) {
          case "VolkswagenCommercial":
            return {
              backgroundColor: props.theme.COLOURS.WHITE
            };
          case "Skoda":
            return {
              backgroundColor: props.theme.COLOURS.BLACK
            };
          case "Motability":
            return {
              backgroundColor: "transparent"
            };
          default:
            return {
              backgroundColor: props.theme.COLOURS.SECONDARY.base
            };
        }
      }};
      border-bottom: 0.25rem solid ${props => props.theme.COLOURS.ACCENT.base};
    }
  }

  .notification {
    border-radius: 1.2rem;
    min-width: 1.5rem;
    height: 1.5rem;
    transform: translate(2.8rem, -1rem);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;
    background-color: ${props => props.theme.COLOURS.ACCENT.base};
    > p {
      color: ${props => props.theme.COLOURS.WHITE};
      font-size: 1rem;
      margin: 0;
    }

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      border-radius: 2rem;
      min-width: 2.3rem;
      height: 2.3rem;

      > p {
        ${props => props.theme.FONTS.Text.info}
      }
    }
  }

  .chevron {
    margin-left: auto;

    path {
      stroke: ${props => props.theme.COLOURS.WHITE};
    }

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      display: none;
    }
  }
`;

export default StyledNavigationItem;
