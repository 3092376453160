import styled, { css } from "styled-components";
import BurgerMenu from "./BurgerMenu";
import breakpoints from "../../theme/breakpoints";

const StyledBurgerMenu = styled(BurgerMenu)`
  background-color: ${props => props.theme.COLOURS.SECONDARY.base};
  border: 0;
  cursor: pointer;
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  transition: background-color linear 0.2s;
  width: 6rem;

  div span {
    background-color: ${props => props.theme.COLOURS.WHITE};
    height: 0.2rem;
    display: block;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    transition: all linear 0.2s;
    width: 2.2rem;

    &:nth-child(1) {
      transform: translateX(-50%) translateY(-0.5rem);
      ${props =>
        props.open &&
        css`
          background-color: ${props.theme.COLOURS.WHITE};
          transform: translateY(0.2rem) translateX(-50%) rotate(45deg);
        `}
    }

    &:nth-child(2) {
      opacity: ${props => props.open && 0};
    }

    &:nth-child(3) {
      transform: translateX(-50%) translateY(0.5rem);
      ${props =>
        props.open &&
        css`
          background-color: ${props.theme.COLOURS.WHITE};
          transform: translateY(-0.2rem) translateX(-50%) rotate(-45deg);
        `}
    }
  }

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    display: none;
  }

  .notification {
    border-radius: 1.2rem;
    box-sizing: content-box;
    min-width: 1.2rem;
    height: 1.2rem;
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;
    background-color: ${props => props.theme.COLOURS.ACCENT.base};

    > p {
      color: ${props => props.theme.COLOURS.WHITE};
      font-size: 1rem;
      margin: 0;
    }
  }
`;
export default StyledBurgerMenu;
