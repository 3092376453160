import styled from "styled-components";
import BidInputButton from "./BidInputButton";
import breakpoints from "../../../../theme/breakpoints";

const StyledBidInputButton = styled(BidInputButton)`
  background-color: ${props => props.theme.COLOURS.PRIMARY.base};
  border: 0;
  border-radius: 0.3rem;
  color: ${props => props.theme.COLOURS.PRIMARY.shades[5]};
  cursor: pointer;
  display: inline-block;
  font-family: ${props => props.theme.FONTS.families.lato};
  font-size: ${props => props.theme.FONTS.sizes[20]};
  font-weight: bold;
  height: 5.2rem;
  line-height: 1.1rem;
  margin: 0 0.5rem 0 0;
  padding: 0 1.5rem;
  text-align: center;

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    margin: 0 0 0 0.5rem;
  }
`;

export default StyledBidInputButton;
