import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ModalGallery from "../../components/ModalGallery";
import { closeModal } from "../../../state/reducers/global";
import { getFaultImage } from "../../../shared/utility";

const FaultGallery = () => {
  const dispatch = useDispatch();
  const inspectionData = useSelector((state) => state.inspection.data);
  const galleryVehicleFaultId =
    useSelector((state) => state.inspection.galleryVehicleFaultId) || 0;

  let galleryItems = [];
  inspectionData.faultInfo.forEach((fi) =>
    inspectionData.imageInfos[fi.vehicleFaultId]?.forEach((ii) =>
      galleryItems.push({
        ...ii,
        primary: fi.faultLocationDescription,
        secondary: fi.faultDescription,
        galleryVehicleFaultId: fi.vehicleFaultId,
      })
    )
  );

  const galleryInitialIndex = galleryItems.findIndex(
    (el) => el.galleryVehicleFaultId === galleryVehicleFaultId
  );

  const galleryItemUrls = galleryItems
    .filter((el) => el !== undefined)
    .map((el) => getFaultImage(el.blobId, el.imageKeyHmac));
  const galleryTexts = galleryItems.map((el) => ({
    primary: el.primary,
    secondary: el.secondary,
  }));

  return (
    <ModalGallery
      galleryItems={galleryItemUrls}
      galleryTexts={galleryTexts}
      initialIndex={galleryInitialIndex}
      closeAction={() => dispatch(closeModal())}
    />
  );
};

export default FaultGallery;
