import React from "react";
import PropTypes from "prop-types";
import iconMap from "./child-components";

const Icon = ({ className, type, ...rest }) => {
  return (
    <span className={className}>
      {iconMap[type] ? React.createElement(iconMap[type], rest) : null}
    </span>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string
};

export default Icon;
